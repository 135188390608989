import React from 'react';
import PropTypes from 'prop-types';

import Paths from '../constants/Paths';
import ModalTypes from '../constants/ModalTypes';
import FixedContainer from '../containers/FixedContainer';
import StaticContainer from '../containers/StaticContainer';
import UsersModalContainer from '../containers/UsersModalContainer';
import UserSettingsModalContainer from '../containers/UserSettingsModalContainer';
import BusinessAddModalContainer from '../containers/BusinessAddModalContainer';
import ProductAddModalContainer from '../containers/ProductAddModalContainer';
import DashboardContainer from '../containers/DashboardContainer';
import Background from './Background';

const Core = ({ path, currentModal, currentBusiness }) => (
  <>
    {currentBusiness && currentBusiness.background && (
      <Background
        type={currentBusiness.background.type}
        name={currentBusiness.background.name}
        imageUrl={currentBusiness.backgroundImage && currentBusiness.backgroundImage.url}
      />
    )}
    {path !== Paths.DASHBOARD ? (
      <>
        <FixedContainer />
        <StaticContainer />
      </>
    ) : (
      <DashboardContainer />
    )}
    {currentModal === ModalTypes.USERS && <UsersModalContainer />}
    {currentModal === ModalTypes.USER_SETTINGS && <UserSettingsModalContainer />}
    {currentModal === ModalTypes.BUSINESS_ADD && <BusinessAddModalContainer />}
    {currentModal === ModalTypes.PRODUCT_ADD && <ProductAddModalContainer />}
  </>
);

Core.propTypes = {
  path: PropTypes.string,
  currentModal: PropTypes.oneOf(Object.values(ModalTypes)),
  currentBusiness: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

Core.defaultProps = {
  path: undefined,
  currentModal: undefined,
  currentBusiness: undefined,
};

export default Core;
