import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import { Loader } from 'semantic-ui-react';

import CoreContainer from '../../containers/CoreContainer';

import styles from './CoreWrapper.module.scss';

const CoreWrapper = React.memo(({ isInitializing, isSocketDisconnected, appError, clearError }) => {
  const [t] = useTranslation();

  if (appError) {
    setTimeout(() => clearError(), 9000);
  }
  // console.log(appError);

  return (
    <>
      {isInitializing ? <Loader active size="massive" /> : <CoreContainer />}
      {isSocketDisconnected && (
        <div className={styles.message}>
          <div className={styles.messageHeader}>{t('common.noConnectionToServer')}</div>
          <div className={styles.messageContent}>
            <Trans i18nKey="common.allChangesWillBeAutomaticallySavedAfterConnectionRestored">
              All changes will be automatically saved
              <br />
              after connection restored
            </Trans>
          </div>
        </div>
      )}
      {appError && (
        <div className={styles.warningWrapper}>
          <div className={styles.warning}>
            <div className={styles.messageHeader}>{t('common.appError')}</div>
            <div className={styles.messageContent}>
              <Trans
                i18nKey="common.appErrorContent"
                values={{
                  item: t(`common.${appError.replace('_CREATE__FAILURE', '').toLowerCase()}s`),
                }}
              >
                App Error On Create {appError}
              </Trans>
            </div>
          </div>
        </div>
      )}
    </>
  );
});

CoreWrapper.propTypes = {
  isInitializing: PropTypes.bool.isRequired,
  isSocketDisconnected: PropTypes.bool.isRequired,
  appError: PropTypes.objectOf(PropTypes.any),
  clearError: PropTypes.func.isRequired,
};

CoreWrapper.defaultProps = {
  appError: null,
};

export default CoreWrapper;
