import React from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'semantic-ui-react';

import { GroupTypes } from '../constants/Enums';
import CustomersContainer from '../containers/CustomersContainer';
import SellitemsContainer from '../containers/SellitemsContainer';
import ProductsContainer from '../containers/ProductsContainer';
import ShipmentsContainer from '../containers/ShipmentsContainer';
import ShipmentPageContainer from '../containers/ShipmentPageContainer';
import Paths from '../constants/Paths';
// import GroupKanbanContainer from '../containers/GroupKanbanContainer';

const GroupWrapper = React.memo(({ type, isFetching, path }) => {
  if (isFetching) {
    return <Loader active />;
  }

  if (type === GroupTypes.COLLECTION) {
    switch (path) {
      case Paths.GROUPS:
        return <SellitemsContainer />;
      case Paths.PRODUCTS:
        return <ProductsContainer />;
      case Paths.CUSTOMERS:
        return <CustomersContainer />;
      case Paths.SHIPMENTS:
        return <ShipmentsContainer />;
      case Paths.SHIPMENTPAGE:
        return <ShipmentPageContainer />;
      default:
        return <SellitemsContainer />;
    }
  }

  // if (type === GroupTypes.KANBAN) {
  //   return <GroupKanbanContainer />;
  // }

  return null;
});

GroupWrapper.propTypes = {
  type: PropTypes.string.isRequired,
  isFetching: PropTypes.bool,
  path: PropTypes.string.isRequired,
};

GroupWrapper.defaultProps = {
  isFetching: null,
};

export default GroupWrapper;
