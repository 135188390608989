const USERS = 'USERS';
const USER_SETTINGS = 'USER_SETTINGS';
const BUSINESS_ADD = 'BUSINESS_ADD';
const BUSINESS_SETTINGS = 'BUSINESS_SETTINGS';
const PRODUCT_ADD = 'PRODUCT_ADD';
const SHIPLINE_DETAIL = 'SHIPLINE_DETAIL';

export default {
  USERS,
  USER_SETTINGS,
  BUSINESS_ADD,
  BUSINESS_SETTINGS,
  PRODUCT_ADD,
  SHIPLINE_DETAIL,
};
