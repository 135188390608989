import { connect } from 'react-redux';

import { currentModalSelector, currentBusinessSelector, pathsMatchSelector } from '../selectors';
import Core from '../components/Core';

const mapStateToProps = (state) => {
  const currentModal = currentModalSelector(state);
  const currentBusiness = currentBusinessSelector(state);
  const { path } = pathsMatchSelector(state);

  return {
    path,
    currentModal,
    currentBusiness,
  };
};

export default connect(mapStateToProps)(Core);
