import ActionTypes from '../constants/ActionTypes';

export const createBusiness = (data) => ({
  type: ActionTypes.BUSINESS_CREATE,
  payload: {
    data,
  },
});

createBusiness.success = (business, businessManagers) => ({
  type: ActionTypes.BUSINESS_CREATE__SUCCESS,
  payload: {
    business,
    businessManagers,
  },
});

createBusiness.failure = (error) => ({
  type: ActionTypes.BUSINESS_CREATE__FAILURE,
  payload: {
    error,
  },
});

export const handleBusinessCreate = (
  business,
  users,
  businessManagers,
  groups,
  groupMemberships,
) => ({
  type: ActionTypes.BUSINESS_CREATE_HANDLE,
  payload: {
    business,
    users,
    businessManagers,
    groups,
    groupMemberships,
  },
});

export const updateBusiness = (id, data) => ({
  type: ActionTypes.BUSINESS_UPDATE,
  payload: {
    id,
    data,
  },
});

updateBusiness.success = (business) => ({
  type: ActionTypes.BUSINESS_UPDATE__SUCCESS,
  payload: {
    business,
  },
});

updateBusiness.failure = (id, error) => ({
  type: ActionTypes.BUSINESS_UPDATE__FAILURE,
  payload: {
    id,
    error,
  },
});

export const handleBusinessUpdate = (business) => ({
  type: ActionTypes.BUSINESS_UPDATE_HANDLE,
  payload: {
    business,
  },
});

export const updateBusinessBackgroundImage = (id) => ({
  type: ActionTypes.BUSINESS_BACKGROUND_IMAGE_UPDATE,
  payload: {
    id,
  },
});

updateBusinessBackgroundImage.success = (business) => ({
  type: ActionTypes.BUSINESS_BACKGROUND_IMAGE_UPDATE__SUCCESS,
  payload: {
    business,
  },
});

updateBusinessBackgroundImage.failure = (id, error) => ({
  type: ActionTypes.BUSINESS_BACKGROUND_IMAGE_UPDATE__FAILURE,
  payload: {
    id,
    error,
  },
});

export const deleteBusiness = (id) => ({
  type: ActionTypes.BUSINESS_DELETE,
  payload: {
    id,
  },
});

deleteBusiness.success = (business) => ({
  type: ActionTypes.BUSINESS_DELETE__SUCCESS,
  payload: {
    business,
  },
});

deleteBusiness.failure = (id, error) => ({
  type: ActionTypes.BUSINESS_DELETE__FAILURE,
  payload: {
    id,
    error,
  },
});

export const handleBusinessDelete = (business) => ({
  type: ActionTypes.BUSINESS_DELETE_HANDLE,
  payload: {
    business,
  },
});
