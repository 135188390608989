import { createSelector } from 'redux-orm';

import orm from '../orm';

export const currentUserIdSelector = ({ auth: { userId } }) => userId;

export const currentUserSelector = createSelector(
  orm,
  (state) => currentUserIdSelector(state),
  ({ User }, id) => {
    if (!id) {
      return id;
    }

    const userModel = User.withId(id);

    if (!userModel) {
      return userModel;
    }

    return userModel.ref;
  },
);

export const isAdminSelector = createSelector(
  orm,
  (state) => currentUserIdSelector(state),
  ({ User }, id) => {
    if (!id) {
      return id;
    }

    const userModel = User.withId(id);

    if (!userModel) {
      return userModel;
    }
    return userModel.isAdmin;
  },
);

export const isOwnerSelector = createSelector(
  orm,
  (state) => currentUserIdSelector(state),
  ({ User }, id) => {
    if (!id) {
      return id;
    }

    const userModel = User.withId(id);

    if (!userModel) {
      return userModel;
    }

    return ['owner'].includes(userModel.role);
  },
);

export const isManagerSelector = createSelector(
  orm,
  (state) => currentUserIdSelector(state),
  ({ User }, id) => {
    if (!id) {
      return id;
    }

    const userModel = User.withId(id);

    if (!userModel) {
      return userModel;
    }

    return ['manager'].includes(userModel.role);
  },
);

export const isShipperSelector = createSelector(
  orm,
  (state) => currentUserIdSelector(state),
  ({ User }, id) => {
    if (!id) {
      return id;
    }

    const userModel = User.withId(id);

    if (!userModel) {
      return userModel;
    }

    return ['shipper'].includes(userModel.role);
  },
);

export const businessesForCurrentUserSelector = createSelector(
  orm,
  (state) => currentUserIdSelector(state),
  ({ User }, id) => {
    if (!id) {
      return id;
    }

    const userModel = User.withId(id);

    if (!userModel) {
      return userModel;
    }

    return userModel.getOrderedAvailableBusinessesModelArray().map((businessModel) => {
      const groupsModels = businessModel.getOrderedAvailableGroupsModelArray(userModel.id);

      let notificationsTotal = 0;
      groupsModels.forEach((groupModel) => {
        groupModel.sellitems.toModelArray().forEach((sellitemModel) => {
          notificationsTotal += sellitemModel.getUnreadNotificationsQuerySet().count();
        });
      });

      return {
        ...businessModel.ref,
        notificationsTotal,
        firstGroupId: groupsModels[0] && groupsModels[0].id,
      };
    });
  },
);

export const businessesToCustomersForCurrentUserSelector = createSelector(
  orm,
  (state) => currentUserIdSelector(state),
  ({ User }, id) => {
    if (!id) {
      return id;
    }

    const userModel = User.withId(id);

    if (!userModel) {
      return userModel;
    }

    return userModel.getOrderedAvailableBusinessesModelArray().map((businessModel) => ({
      ...businessModel.ref,
      groups: businessModel.getOrderedAvailableGroupsModelArray(id).map((groupModel) => ({
        ...groupModel.ref,
        customers: groupModel.getOrderedCustomersQuerySet().toRefArray(),
      })),
    }));
  },
);

export const notificationsForCurrentUserSelector = createSelector(
  orm,
  (state) => currentUserIdSelector(state),
  ({ User }, id) => {
    if (!id) {
      return id;
    }

    const userModel = User.withId(id);

    if (!userModel) {
      return userModel;
    }

    return userModel
      .getOrderedUnreadNotificationsQuerySet()
      .toModelArray()
      .map((notificationModel) => ({
        ...notificationModel.ref,
        action: notificationModel.action && {
          ...notificationModel.action.ref,
          user: notificationModel.action.user.ref,
        },
        sellitem: notificationModel.sellitem && notificationModel.sellitem.ref,
      }));
  },
);
