import socket from './socket';

/* Actions */

const createProductCategory = (productId, data, headers) =>
  socket.post(`/products/${productId}/categories`, data, headers);

const deleteProductCategory = (productId, categoryId, headers) =>
  socket.delete(`/products/${productId}/categories?userId=${categoryId}`, undefined, headers);

export default {
  createProductCategory,
  deleteProductCategory,
};
