import { all, call, cancel, fork, take } from 'redux-saga/effects';

import watchers from './watchers';
import { goToRootService } from './services';
// import { goToGroupService } from '../core/services/router';
import { setAccessToken } from '../../utils/access-token-storage';
import ActionTypes from '../../constants/ActionTypes';

export default function* loginSaga() {
  const watcherTasks = yield all(watchers.map((watcher) => fork(watcher)));

  const {
    payload: { accessToken },
  } = yield take(ActionTypes.AUTHENTICATE__SUCCESS);

  // const { groups } = yield take(ActionTypes.CORE_INITIALIZE);

  yield cancel(watcherTasks);

  yield call(setAccessToken, accessToken);
  // if (groups && groups.length > 1) {
  // yield call(goToGroupService, '649775577858835460');
  // }
  yield call(goToRootService);
}
