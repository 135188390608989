import { call, put, select } from 'redux-saga/effects';

import request from '../request';
import { pathSelector } from '../../../selectors';
import { clearImportError, importGroup } from '../../../actions';
import api from '../../../api';

export function* importCustomersService(data) {
  const { groupId } = yield select(pathSelector);

  yield put(importGroup(data));

  let message;
  try {
    switch (data.type) {
      case 'product':
        ({ message } = yield call(request, api.importProduct, groupId, data));
        break;
      case 'sellitem':
        ({ message } = yield call(request, api.importSellitems, groupId, data));
        break;
      case 'user':
        ({ message } = yield call(request, api.importUsers, groupId, data));
        break;
      default:
        ({ message } = yield call(request, api.importCustomers, groupId, data));
        break;
    }
  } catch (error) {
    yield put(importGroup.failure(error));
    return;
  }

  yield put(importGroup.success(message));
}

export function* clearImportErrorService() {
  yield put(clearImportError());
}
