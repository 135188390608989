export const BusinessBackgroundTypes = {
  GRADIENT: 'gradient',
  IMAGE: 'image',
};

export const GroupTypes = {
  KANBAN: 'kanban',
  COLLECTION: 'collection',
};

export const ActionTypes = {
  CREATE_SELLITEM: 'createSellitem',
  MOVE_SELLITEM: 'moveSellitem',
  COMMENT_SELLITEM: 'commentSellitem',
};

export const Statuses = {
  NEWORDER: { name: 'newOrder', color: 'pumpkin-orange', hex: '#f0982d', text: 'Cần Order' },
  DONEORDER: { name: 'doneOrder', color: 'lagoon-blue', hex: '#109dc0', text: 'Đã Order' },
  ARRIVAL: { name: 'arrival', color: 'light-mud', hex: '#c7a57b', text: 'Đã về VN' },
  ADDEDBAG: { name: 'addedBag', color: 'tank-green', hex: '#8aa177', text: 'ADD BAG' },
  CANCELED: { name: 'canceled', color: 'pink-tulip', hex: '#f97394', text: 'Hủy' },
  CANCELEDOK: { name: 'canceledOk', color: 'red-burgundy', hex: '#ad5f7d', text: 'Hủy đã báo' },
  SEND: { name: 'send', color: 'gun-metal', hex: '#355263', text: 'Đã gửi mua' },
  PENDING: { name: 'pending', color: 'light-cocoa', hex: '#87564a', text: 'Chờ chốt' },
  WEBSHIPPED: { name: 'webShipped', color: 'light-concrete', hex: '#afb0a4', text: 'Web đã ship' },
  NEEDHANDLE: { name: 'needHandle', color: 'egg-yellow', hex: '#f7d036', text: 'Cần xử lý' },
  DELIVERIED: { name: 'deliveried', color: 'lagune-blue', hex: '#00b4b1', text: 'Đã giao hàng' },
  FINISH: { name: 'finish', color: 'wet-moss', hex: '#4a8753', text: 'Hoàn thành' },
  STATUS1: { name: 'status1', color: 'light-concrete', hex: '#afb0a4', text: 'Khách đặt' },
};

export const CancelStatusNames = [Statuses.CANCELED.name, Statuses.CANCELEDOK.name];

export const PaymentTypes = {
  DEPOSIT: 'deposit',
  PAYMENT: 'payment',
};

export const StatusesShipment = {
  NOTSHIP: { name: 'notShip', color: 'pumpkin-orange', hex: '#f0982d', text: 'Chưa ship' },
  SHIPNOW: { name: 'shipNow', color: 'lagoon-blue', hex: '#109dc0', text: 'Ship ngay' },
  SHIPONSCHEDULE: { name: 'shipOnSchedule', color: 'light-mud', hex: '#c7a57b', text: 'Hẹn ship' },
  CONSOLIDATIONWAIT: {
    name: 'consolidationWait',
    color: 'tank-green',
    hex: '#8aa177',
    text: 'Chờ gom hàng',
  },
  CUSTOMERPICKUP: {
    name: 'customerPickup',
    color: 'pink-tulip',
    hex: '#f97394',
    text: 'Khách đến lấy',
  },
  SHIPPED: { name: 'shipped', color: 'wet-moss', hex: '#4a8753', text: 'Đã trả hàng' },
  NOANSWER1ST: {
    name: 'noAnswer1st',
    color: 'gun-metal',
    hex: '#355263',
    text: 'Không trả lời lần 1',
  },
  NOANSWER2ND: {
    name: 'noAnswer2nd',
    color: 'light-cocoa',
    hex: '#87564a',
    text: 'Không trả lời lần 2',
  },
  NOANSWER3RD: {
    name: 'noAnswer3rd',
    color: 'light-concrete',
    hex: '#afb0a4',
    text: 'Không trả lời lần 3',
  },
  ANOTHERREASON: { name: 'anotherReason', color: 'egg-yellow', hex: '#f7d036', text: 'Lý do khác' },
};

export const ProvinceShipment = {
  YESSHIPPROVINCE: {
    name: 'yesShipProvince',
    color: 'light-concrete',
    hex: '#afb0a4',
    text: 'Ship tỉnh',
  },
  NOSHIPPROVINCE: {
    name: 'noShipProvince',
    color: 'light-concrete',
    hex: '#afb0a4',
    text: 'Ship nội thành',
  },
};

export const Role = {
  OWNER: 'owner',
  MANAGER: 'manager',
  STAFF: 'staff',
  SHIPPER: 'shipper',
};
