/* eslint-disable no-unused-vars */
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';

import Select, { components } from 'react-select';
import AddPopup from '../Customers/AddPopup';

import styles from './SearchCustomers.module.scss';

const Option = (props) => {
  const {
    // eslint-disable-next-line react/prop-types
    data: { label, phone },
  } = props;
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.Option {...props}>
      {label} - {phone}
    </components.Option>
  );
};

// const DropdownIndicator = (props) => {
//   return (
//     // eslint-disable-next-line react/jsx-props-no-spreading
//     <components.DropdownIndicator {...props}>
//       <AddPopup onCreate={() => {}}>
//         <Icon name="user" />
//       </AddPopup>
//     </components.DropdownIndicator>
//   );
// };

// const NoOptionsMessage = (props) => {
//   return (
//     // eslint-disable-next-line react/jsx-props-no-spreading
//     <div>
//       <Input fluid name="nameTest" className={styles.field} />
//     </div>
//   );
// };

// const MenuList = (props) => {
//   return (
//     // eslint-disable-next-line react/jsx-props-no-spreading
//     <components.MenuList {...props}>
//       <AddPopup onCreate={() => {}}>
//         <Button circular size="mini" icon="user add" className={styles.addCustomerButton} />
//       </AddPopup>
//       {...props.children}
//     </components.MenuList>
//   );
// };

const SearchCustomers = React.memo(
  ({
    isMulti,
    customerSelected,
    searchResults,
    onSearchCustomers,
    onFecthCustomers,
    onCustomerSelected,
  }) => {
    const [t] = useTranslation();

    const [typing, setTyping] = useState({ isSearching: false });
    const { isSubmitting, customers } = searchResults;

    const handleInputChange = useCallback(
      (data, meta) => {
        if (meta?.action !== 'input-change') return;
        if (typing) {
          clearTimeout(typing.timeout);
        }
        setTyping({
          isSearching: true,
          timeout: setTimeout(() => {
            if (data && data.length >= 1) {
              onSearchCustomers(data);
            }
          }, 200),
        });
      },
      [onSearchCustomers, typing],
    );

    const handleChange = (value) => {
      onCustomerSelected(value);
      if (!isMulti && value && onFecthCustomers) {
        if (onFecthCustomers) onFecthCustomers(value.code);
      }
    };

    const results = customers.map(({ id, code, name, phone, addressId }) => ({
      id,
      code,
      title: name,
      phone,
      value: id,
      label: `[${code}] ${name}`,
      addressId,
    }));

    return (
      <div className={styles.wrapper}>
        <Select
          // isClearable={false}
          isMulti={isMulti}
          isLoading={isSubmitting}
          components={{ Option, DropdownIndicator: null }}
          name="customerIds"
          options={typing.isSearching ? results : []}
          onChange={handleChange}
          onInputChange={handleInputChange}
          placeholder={t('common.searchCustomer')}
          noOptionsMessage={() => (isMulti ? t('common.noResultsMessage') : null)}
          value={customerSelected}
          styles={{
            placeholder: (base) => ({
              ...base,
              fontSize: '0.9em',
              whiteSpace: 'nowrap',
            }),
          }}
        />
      </div>
    );
  },
);

SearchCustomers.propTypes = {
  isMulti: PropTypes.bool,
  customerSelected: PropTypes.arrayOf(PropTypes.any).isRequired,
  searchResults: PropTypes.objectOf(PropTypes.any).isRequired,
  onSearchCustomers: PropTypes.func.isRequired,
  onFecthCustomers: PropTypes.func,
  onCustomerSelected: PropTypes.func.isRequired,
};

SearchCustomers.defaultProps = {
  isMulti: false,
  onFecthCustomers: undefined,
};

export default SearchCustomers;
