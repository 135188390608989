import { fetch } from 'whatwg-fetch';

/* Actions */

const sendMessageFacebook = (id, data) => {
  const formData = new FormData();
  formData.append('messaging_type', 'MESSAGE_TAG');
  const dataId = JSON.stringify({
    id: data.userId,
  });
  formData.append('recipient', dataId);
  const dataMessage = JSON.stringify({
    text: data.message,
  });
  formData.append('message', dataMessage);
  formData.append('tag', 'ACCOUNT_UPDATE');

  const facebook = fetch('https://graph.facebook.com/v12.0/me/messages', {
    method: 'POST',
    headers: {
      Authorization:
        'Bearer EAAD2OHvCug0BAAOmHpHLsRAN91cOa0uRbkiQm1EldGQv0mN2UM9kAfVS5oQkqZA3vnbEiLjzZBEhDpb3P6P7yfVxawPddRGt9blaEZAbOAHNKNFMRiXLKmjmpci3tR1Xn4ZCFWzLs4mXYVersNWw7wANeWkaN7CXofHDU9bSrehVpLu1ZAspLfDucx8fUZAGezgpUonwOWJgZDZD',
    },
    body: formData,
  })
    .then((response) =>
      response.json().then((body) => ({
        body,
        isError: response.status !== 200,
      })),
    )
    .then(({ body, isError }) => {
      if (isError) {
        throw body;
      }

      return body;
    });
  return facebook;
};

export default {
  sendMessageFacebook,
};
