/* eslint-disable no-unused-vars */
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// import classNames from 'classnames';

import { Container, Header, Search } from 'semantic-ui-react';

import styles from './Search.module.scss';

const SearchBox = React.memo(({ title }) => {
  const [t] = useTranslation();
  return (
    <div className={styles.wrapper}>
      <Search
        category
        // categoryLayoutRenderer={categoryLayoutRenderer}
        // categoryRenderer={categoryRenderer}
        icon="search"
        placeholder={title}
        noResultsMessage={t('common.noResultsMessage')}
        // results={getResults()}
        // resultRenderer={resRender}
        className={styles.search}
        // onSearchChange={handleMessage}
        // onResultSelect={handleResultSelect}
        // noResultsMessage={t('common.noResultsMessage')}
        // loading={isSubmitting}
      />
    </div>
  );
});

SearchBox.propTypes = {
  title: PropTypes.string.isRequired,
  // direction: PropTypes.string,
  // visible: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  // searchResults: PropTypes.object.isRequired,
  // onFullTextSearch: PropTypes.func.isRequired,
};

// SearchBox.defaultProps = {
//   animation: 'push',
//   direction: undefined,
//   visible: true,
// };

export default SearchBox;
