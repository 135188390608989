import { Model, attr, fk } from 'redux-orm';

import ActionTypes from '../constants/ActionTypes';

export default class extends Model {
  static modelName = 'Shipline';

  static fields = {
    id: attr(),
    isFetching: attr({
      getDefault: () => null,
    }),
    customerId: fk({
      to: 'Customer',
      as: 'customer',
      relatedName: 'shiplines',
    }),
    shipmentId: fk({
      to: 'Shipment',
      as: 'shipment',
      relatedName: 'shiplines',
    }),
    addressId: fk({
      to: 'Address',
      as: 'address',
      relatedName: 'shiplines',
    }),
    status: attr(),
  };

  static reducer({ type, payload }, Shipline) {
    switch (type) {
      case ActionTypes.LOCATION_CHANGE_HANDLE__SHIPMENT_FETCH:
      case ActionTypes.SHIPMENT_FETCH:
        if (Shipline.idExists(payload.id)) {
          Shipline.withId(payload.id).update({
            isFetching: true,
          });
        }

        break;
      case ActionTypes.SHIPMENT_FETCH__SUCCESS:
      case ActionTypes.SHIPMENTS_FETCH__SUCCESS:
        if (payload.shiplines) {
          Shipline.all().delete();
          payload.shiplines.forEach((shipline) => {
            Shipline.upsert(shipline);
          });
        }

        break;
      case ActionTypes.SOCKET_RECONNECT_HANDLE:
        Shipline.all().delete();

        if (payload.shiplines) {
          payload.shiplines.forEach((shipline) => {
            Shipline.upsert(shipline);
          });
        }

        break;
      case ActionTypes.GROUP_FETCH__SUCCESS:
        payload.shipments.shiplines.forEach((shipline) => {
          Shipline.upsert(shipline);
        });

        break;
      case ActionTypes.SHIPLINE_CREATE:
      case ActionTypes.SHIPLINE_CREATE_HANDLE:
      case ActionTypes.SHIPLINE_UPDATE__SUCCESS:
      case ActionTypes.SHIPLINE_UPDATE_HANDLE:
        Shipline.upsert(payload.shipline);

        break;
      case ActionTypes.SHIPLINE_CREATE__SUCCESS:
        Shipline.withId(payload.localId).delete();
        Shipline.upsert(payload.shipline);

        break;
      case ActionTypes.SHIPLINE_UPDATE:
        Shipline.withId(payload.id).update(payload.data);

        break;
      case ActionTypes.SHIPLINE_DELETE:
        Shipline.withId(payload.id).deleteWithRelated();

        break;
      case ActionTypes.SHIPLINE_DELETE__SUCCESS:
      case ActionTypes.SHIPLINE_DELETE_HANDLE: {
        const shiplineModel = Shipline.withId(payload.shipline.id);

        if (shiplineModel) {
          shiplineModel.deleteWithRelated();
        }

        break;
      }
      default:
    }
  }

  static getOrderedQuerySet() {
    return this.orderBy('createdAt', false);
  }

  deleteWithRelated() {
    this.delete();
  }
}
