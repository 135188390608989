import { createSelector } from 'redux-orm';

import orm from '../orm';
import { pathSelector } from './router';
import { isLocalId } from '../utils/local-id';

export const makeShipmentByIdSelector = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ Shipment }, id) => {
      const shipmentModel = Shipment.withId(id);

      if (!shipmentModel) {
        return shipmentModel;
      }

      return {
        ...shipmentModel.ref,
        creator: shipmentModel?.creator?.ref,
        isPersisted: !isLocalId(id),
      };
    },
  );

export const currentShipmentSelector = createSelector(
  orm,
  (state) => pathSelector(state).shipmentId,
  ({ Shipment }, id) => {
    if (!id) {
      return id;
    }

    const shipmentModel = Shipment.withId(id);

    if (!shipmentModel) {
      return shipmentModel;
    }

    return {
      ...shipmentModel.ref,
      creator: shipmentModel.creator.ref,
    };
  },
);

export const shipmentIdsForCurrentGroupSelector = createSelector(
  orm,
  (state) => pathSelector(state).groupId,
  ({ Shipment }, id) => {
    if (!id) {
      return id;
    }

    return (
      Shipment.getOrderedQuerySet()
        .toRefArray()
        .filter((shipment) => shipment.groupId === id)
        // .sort((a, b) => {
        //   return b - a;
        // })
        .map((shipment) => shipment.id)
    );
  },
);

export const shiplinesForCurrentShipmentSelector = createSelector(
  orm,
  (state) => pathSelector(state).shipmentId,
  (state) => pathSelector(state).groupId,
  ({ Shipment, Group }, id, groupId) => {
    if (!id) {
      return id;
    }

    const shipmentModel = Shipment.withId(id);
    const groupModel = Group.withId(groupId);

    if (!shipmentModel) {
      return shipmentModel;
    }

    let shiplinesModel = shipmentModel.getOrderedShiplinesQuerySet().toModelArray();
    // console.log('shiplinesModel:', shiplinesModel);
    if (groupModel) {
      const { filterShipmentStatuses, filterShipProvinces } = groupModel;
      if (filterShipmentStatuses.length > 0) {
        shiplinesModel = shiplinesModel.filter((shiplineModel) =>
          filterShipmentStatuses.includes(shiplineModel.status),
        );
      }
      // console.log('filterShipProvinces:', filterShipProvinces);
      if (filterShipProvinces.length === 1) {
        const isShipProvince = filterShipProvinces[0] === 'yesShipProvince';
        shiplinesModel = shiplinesModel.filter(
          (shiplineModel) => shiplineModel.isShipProvince === isShipProvince,
        );
      }
    }

    return shiplinesModel.map((shiplineModel) => ({
      ...shiplineModel.ref,
      customer: shiplineModel.customer?.ref,
      address: shiplineModel.address?.ref,
    }));
  },
);

export const shiplinesForShipmentIdSelector = createSelector(
  orm,
  (_, id) => id,
  ({ Shipment }, id) => {
    if (!id) {
      return id;
    }

    const shipmentModel = Shipment.withId(id);

    if (!shipmentModel) {
      return shipmentModel;
    }

    return shipmentModel
      .getOrderedShiplinesQuerySet()
      .toModelArray()
      .map((shiplineModel) => ({
        ...shiplineModel.ref,
        shipCode: shipmentModel.code,
        customer: shiplineModel?.customer?.ref,
        address: shiplineModel?.address?.ref,
      }));
  },
);
