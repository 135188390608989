import { all, takeEvery } from 'redux-saga/effects';

import {
  createSellitemService,
  deleteSellitemService,
  deleteCurrentSellitemService,
  handleSellitemCreateService,
  handleSellitemDeleteService,
  handleSellitemUpdateService,
  moveSellitemService,
  moveCurrentSellitemService,
  transferSellitemService,
  transferCurrentSellitemService,
  updateSellitemService,
  updateCurrentSellitemService,
} from '../services';
import EntryActionTypes from '../../../constants/EntryActionTypes';

export default function* sellitemWatchers() {
  yield all([
    takeEvery(EntryActionTypes.SELLITEM_CREATE, ({ payload: { customerId, data } }) =>
      createSellitemService(customerId, data),
    ),
    takeEvery(EntryActionTypes.SELLITEM_CREATE_HANDLE, ({ payload: { sellitem, product } }) =>
      handleSellitemCreateService(sellitem, product),
    ),
    takeEvery(EntryActionTypes.SELLITEM_UPDATE, ({ payload: { id, data } }) =>
      updateSellitemService(id, data),
    ),
    takeEvery(EntryActionTypes.CURRENT_SELLITEM_UPDATE, ({ payload: { data } }) =>
      updateCurrentSellitemService(data),
    ),
    takeEvery(EntryActionTypes.SELLITEM_UPDATE_HANDLE, ({ payload: { sellitem } }) =>
      handleSellitemUpdateService(sellitem),
    ),
    takeEvery(EntryActionTypes.SELLITEM_MOVE, ({ payload: { id, customerId, index } }) =>
      moveSellitemService(id, customerId, index),
    ),
    takeEvery(EntryActionTypes.CURRENT_SELLITEM_MOVE, ({ payload: { customerId, index } }) =>
      moveCurrentSellitemService(customerId, index),
    ),
    takeEvery(
      EntryActionTypes.SELLITEM_TRANSFER,
      ({ payload: { id, groupId, customerId, index } }) =>
        transferSellitemService(id, groupId, customerId, index),
    ),
    takeEvery(
      EntryActionTypes.CURRENT_SELLITEM_TRANSFER,
      ({ payload: { groupId, customerId, index } }) =>
        transferCurrentSellitemService(groupId, customerId, index),
    ),
    takeEvery(EntryActionTypes.SELLITEM_DELETE, ({ payload: { id } }) => deleteSellitemService(id)),
    takeEvery(EntryActionTypes.CURRENT_SELLITEM_DELETE, () => deleteCurrentSellitemService()),
    takeEvery(EntryActionTypes.SELLITEM_DELETE_HANDLE, ({ payload: { sellitem } }) =>
      handleSellitemDeleteService(sellitem),
    ),
  ]);
}
