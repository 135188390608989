import { call, put, select } from 'redux-saga/effects';

import { goToCustomerService } from './router';
import request from '../request';
import {
  customerByIdSelector,
  nextCustomerPositionSelector,
  pathSelector,
} from '../../../selectors';
import {
  createCustomer,
  deleteCustomer,
  fetchCustomer,
  handleCustomerCreate,
  handleCustomerDelete,
  handleCustomerUpdate,
  updateCustomer,
} from '../../../actions';
import api from '../../../api';
import { createLocalId } from '../../../utils/local-id';

export function* createCustomerService(groupId, data, isGoto = true) {
  const nextData = {
    ...data,
    position: yield select(nextCustomerPositionSelector, groupId),
  };

  const localId = yield call(createLocalId);

  yield put(
    createCustomer({
      ...nextData,
      groupId,
      id: localId,
    }),
  );

  let customer;
  try {
    ({ item: customer } = yield call(request, api.createCustomer, groupId, nextData));
  } catch (error) {
    yield put(createCustomer.failure(localId, error));
    return;
  }

  yield put(createCustomer.success(localId, customer));
  if (isGoto) yield call(goToCustomerService, customer.id);
}

export function* createCustomerInCurrentGroupService(data) {
  const { groupId, customerId } = yield select(pathSelector);

  yield call(createCustomerService, groupId, data, customerId !== undefined);
}

export function* handleCustomerCreateService(label) {
  yield put(handleCustomerCreate(label));
}

export function* fetchCustomerService(id) {
  yield put(fetchCustomer(id));

  // let group;
  let customer;
  let customerGroup;
  // let users;
  // let businesses;
  // let groupMemberships;
  // let labels;
  // let customers;
  let addresses;
  let sellitems;
  let sellitemMemberships;
  let sellitemLabels;
  let payments;
  let attachments;

  try {
    ({
      item: customer,
      included: {
        customerGroup,
        addresses,
        sellitems,
        sellitemMemberships,
        sellitemLabels,
        payments,
        attachments,
      },
    } = yield call(request, api.getCustomer, id));
  } catch (error) {
    yield put(fetchCustomer.failure(id, error));
    return;
  }

  yield put(
    fetchCustomer.success(
      customer,
      customerGroup,
      addresses,
      sellitems,
      sellitemMemberships,
      sellitemLabels,
      payments,
      attachments,
    ),
  );
}

export function* updateCustomerService(id, data) {
  yield put(updateCustomer(id, data));

  let customer;
  try {
    ({ item: customer } = yield call(request, api.updateCustomer, id, data));
  } catch (error) {
    yield put(updateCustomer.failure(id, error));
    return;
  }

  yield put(updateCustomer.success(customer));
}

export function* moveCustomerService(id, index) {
  const { groupId } = yield select(customerByIdSelector, id);
  const position = yield select(nextCustomerPositionSelector, groupId, index, id);

  yield call(updateCustomerService, id, {
    position,
  });
}

export function* handleCustomerUpdateService(label) {
  yield put(handleCustomerUpdate(label));
}

export function* deleteCustomerService(id) {
  yield put(deleteCustomer(id));

  let customer;
  try {
    ({ item: customer } = yield call(request, api.deleteCustomer, id));
  } catch (error) {
    yield put(deleteCustomer.failure(id, error));
    return;
  }

  yield put(deleteCustomer.success(customer));
}

export function* handleCustomerDeleteService(label) {
  yield put(handleCustomerDelete(label));
}
