import ActionTypes from '../constants/ActionTypes';

const initialState = {
  shipments: [],
  isFetching: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SHIPMENTS_FETCH:
      return {
        ...initialState,
        isFetching: true,
      };
    case ActionTypes.SHIPMENTS_FETCH__SUCCESS:
      return {
        ...state,
        ...payload,
        isFetching: false,
      };
    case ActionTypes.SHIPMENTS_FETCH__FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    default:
      return state;
  }
};
