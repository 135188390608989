/* eslint-disable no-unused-vars */
import React from 'react';
import ReactDOM from 'react-dom';

import { Checkbox, Image, List } from 'semantic-ui-react';

import Handsontable from 'handsontable';

import styles from './SelectSellitemsEditor.module.scss';

class SelectSellitemsEditor extends Handsontable.editors.TextEditor {
  init() {
    this.onSelectChange = this.onSelectChange.bind(this);
    this.options = [];
    this.originalOptions = [];
    this.number = '';
  }

  setValue(value) {
    this.number = (+value).toLocaleString('en-GB');
  }

  getValue() {
    return this.number.replace(/[^0-9]/g, '');
  }

  open() {
    super.open();
    this.TEXTAREA.value = this.number;
    this.originalOptions = JSON.parse(JSON.stringify(this.options));

    this.render();
  }

  close() {
    super.close();

    if (this.isOpened() && !Handsontable.helper.isObjectEqual(this.options, this.originalOptions)) {
      const checkedOptions = this.options.filter((o) => o.checked);
      const { onUpdateShipline, instance } = this.cellProperties;
      const shiplineId = instance.getDataAtRowProp(this.row, 'id');
      onUpdateShipline(shiplineId, {
        sellitemIds: checkedOptions.map(({ id }) => id),
        total: this.getValue(),
      });
      // onUpdate({ total: totalShipment + this.getValue() });
    }
    // this.finishEditing(true, false);
    // if (this.isOpened()) this.instance.selectCell(this.row, this.col);
    this.instance.listen();
    Handsontable.dom.empty(this.TEXTAREA_PARENT);
    Handsontable.dom.addClass(this.TEXTAREA_PARENT, 'handsontableInputHolder');
    this.TEXTAREA_PARENT.appendChild(this.TEXTAREA);
  }

  onSelectChange(event) {
    const { checked, defaultValue } = event.target.previousSibling;
    this.options.find(({ id }) => id === defaultValue).checked = !checked;
    const checkedOptions = this.options.filter((o) => o.checked);
    const total = checkedOptions.reduce((sum, { finalTotal }) => sum + finalTotal, 0);
    this.setValue(total);
    this.instance.listen();
  }

  onBeforeKeyDown(event) {
    super.onBeforeKeyDown(event);

    const keys = Handsontable.helper.KEY_CODES;

    switch (event.keyCode) {
      case keys.ARROW_LEFT:
      case keys.ARROW_RIGHT:
      case keys.ARROW_UP:
      case keys.ARROW_DOWN:
        event.stopImmediatePropagation();

        break;

      case keys.ESCAPE: {
        // this.finishEditing(true, false, () => setTimeout(() => this.close()));
        this.options = this.originalOptions;
        this.finishEditing(true);
        break;
      }

      case keys.BACKSPACE:
      case keys.DELETE:
      case keys.HOME:
      case keys.END:
        event.stopImmediatePropagation(); // backspace, delete, home, end should only work locally when cell is edited (not in table context)

        break;

      default:
        break;
    }
  }

  prepare(row, col, prop, td, originalValue, cellProperties) {
    super.prepare(row, col, prop, td, originalValue, cellProperties);

    const { shiplines, sellitems } = cellProperties;
    const shipline = shiplines[row];
    const source = [];
    switch (prop) {
      case 'total':
        if (sellitems) {
          sellitems.forEach(({ id, name, finalTotal, imageUrl, customerId }) => {
            if (customerId === shipline.customerId) {
              source.push({
                id,
                value: id,
                label: `${name} - ${finalTotal.toLocaleString('en-GB')}`,
                checked: shipline.sellitemIds.includes(id),
                finalTotal,
                imageUrl,
              });
            }
          });
        }
        break;
      default:
        break;
    }
    this.options = source;
  }

  render() {
    const source = this.options;
    if (source.length > 0) {
      const div = document.createElement('div');
      div.classList.add(styles.wrapper);
      const renderResult = (
        <List selection className={styles.list}>
          {source.map(({ id, label, value, checked, imageUrl }) => (
            <List.Item key={id}>
              <Image src={imageUrl} className={styles.image} />
              <List.Content>
                <Checkbox
                  value={value}
                  defaultChecked={checked}
                  label={label}
                  onChange={this.onSelectChange}
                />
              </List.Content>
            </List.Item>
          ))}
        </List>
      );
      ReactDOM.render(renderResult, div);
      // Handsontable.dom.empty(this.TEXTAREA_PARENT);
      // Handsontable.dom.addClass(this.TEXTAREA_PARENT, 'handsontableInputHolder');
      // this.TEXTAREA_PARENT.appendChild(this.TEXTAREA);
      this.TEXTAREA.setAttribute('readOnly', true);
      this.TEXTAREA_PARENT.appendChild(div);
    }
  }
}

export default SelectSellitemsEditor;
