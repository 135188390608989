import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Form } from 'semantic-ui-react';
import { Input, Popup } from '../../../lib/custom-ui';

import { useForm } from '../../../hooks';

import styles from './EditStep.module.scss';

const EditStep = React.memo(
  ({ customerId, accounts, defaultData, title, onCreate, onUpdate, onBack, onClose }) => {
    const [t] = useTranslation();

    const [data, handleFieldChange] = useForm({
      amount: '',
      name: '',
      refNo: '',
      ...defaultData,
    });
    const [isCompleted, setIsComplete] = useState(false);

    const [error, setError] = useState({
      amount: false,
      name: false,
      refNo: false,
    });

    const accountOptions = useMemo(() => {
      return accounts
        .filter((a) => !a.isClose)
        .map((a) => ({
          key: a.id,
          value: a.id,
          text: `${a.name} - ${a.accountNumber || ''}`,
        }));
    }, [accounts]);

    const handleToggleChange = useCallback(() => {
      setIsComplete(!isCompleted);
    }, [isCompleted]);

    const handleSubmit = useCallback(() => {
      const cleanData = {
        ...data,
        amount: Number.isInteger(data.amount)
          ? data.amount
          : parseInt(data.amount.replace(/,/, ''), 10),
        name: data.name.trim() || t(title, { context: 'title' }),
        refNo: data.refNo.trim() || null,
        isCompleted,
        // type: 'deposit',
      };

      const err = {
        amount: !cleanData.amount,
        accountId: !cleanData.accountId,
      };
      setError(err);

      if (Object.values(err).some((e) => e)) {
        return;
      }

      if (title === 'action.editPayment') {
        onUpdate(cleanData);
      } else {
        cleanData.type = 'deposit';
        onCreate(customerId, cleanData);
      }

      onClose();
    }, [data, t, title, isCompleted, onClose, onUpdate, onCreate, customerId]);

    // useEffect(() => {
    //   nameField.current.select();
    // }, []);

    return (
      <>
        <Popup.Header onBack={onBack}>
          {t(title, {
            context: 'title',
          })}
        </Popup.Header>
        <Popup.Content>
          <Form onSubmit={handleSubmit}>
            <Form.Field
              required
              error={error.amount}
              onFocus={() => setError({ ...error, amount: false })}
            >
              <Input
                fluid
                name="amount"
                maxLength="12"
                value={
                  data.amount ? (+data.amount.replace(/[^0-9]/g, '')).toLocaleString('en-GB') : ''
                }
                className={styles.field}
                pattern="[0-9,]+"
                placeholder={`${t('common.amount')} *`}
                onChange={handleFieldChange}
              />
            </Form.Field>
            <Form.Dropdown
              fluid
              required
              search
              selection
              error={error.accountId}
              name="accountId"
              value={data.accountId}
              options={accountOptions}
              className={styles.field}
              placeholder={`${t('common.account')} *`}
              onFocus={() => setError({ ...error, accountId: false })}
              onChange={handleFieldChange}
            />
            <Form.Field
              required
              error={error.name}
              onFocus={() => setError({ ...error, name: false })}
            >
              <Input
                fluid
                name="name"
                value={data.name}
                className={styles.field}
                placeholder={t('common.note')}
                onChange={handleFieldChange}
              />
            </Form.Field>
            <Form.Field
              required
              error={error.refNo}
              onFocus={() => setError({ ...error, refNo: false })}
            >
              <Input
                fluid
                name="refNo"
                value={data.refNo}
                className={styles.field}
                placeholder={t('common.refNo')}
                onChange={handleFieldChange}
              />
            </Form.Field>
            <Form.Group widths="equal">
              <Form.Checkbox
                name="isCompleted"
                checked={isCompleted}
                label={t('action.isCompleted')}
                onChange={handleToggleChange}
              />
              <Button positive content={t('action.save')} />
            </Form.Group>
          </Form>
        </Popup.Content>
      </>
    );
  },
);

EditStep.propTypes = {
  customerId: PropTypes.string.isRequired,
  accounts: PropTypes.arrayOf(PropTypes.any).isRequired,
  defaultData: PropTypes.objectOf(PropTypes.any),
  title: PropTypes.string,
  onCreate: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onBack: PropTypes.func,
  onClose: PropTypes.func.isRequired,
};

EditStep.defaultProps = {
  defaultData: undefined,
  title: 'common.createPayment',
  onBack: undefined,
};

export default EditStep;
