const PREFIX = '@entry';

export default {
  PREFIX,

  /* Socket */

  SOCKET_DISCONNECT_HANDLE: `${PREFIX}/SOCKET_DISCONNECT_HANDLE`,
  SOCKET_RECONNECT_HANDLE: `${PREFIX}/SOCKET_RECONNECT_HANDLE`,

  /* Login */

  AUTHENTICATE: `${PREFIX}/AUTHENTICATE`,
  AUTHENTICATE_ERROR_CLEAR: `${PREFIX}/AUTHENTICATE_ERROR_CLEAR`,
  LOGOUT: `${PREFIX}/LOGOUT`,

  /* Core */

  CORE_INITIALIZE: `${PREFIX}/CORE_INITIALIZE`,
  APP_ERROR_CLEAR: `${PREFIX}/APP_ERROR_CLEAR`,

  /* Modal */

  MODAL_OPEN: `${PREFIX}/MODAL_OPEN`,
  MODAL_CLOSE: `${PREFIX}/MODAL_CLOSE`,

  /* User */

  USER_CREATE: `${PREFIX}/USER_CREATE`,
  USER_CREATE_HANDLE: `${PREFIX}/USER_CREATE_HANDLE`,
  USER_CREATE_ERROR_CLEAR: `${PREFIX}/USER_CREATE_ERROR_CLEAR`,
  USER_UPDATE: `${PREFIX}/USER_UPDATE`,
  CURRENT_USER_UPDATE: `${PREFIX}/CURRENT_USER_UPDATE`,
  USER_UPDATE_HANDLE: `${PREFIX}/USER_UPDATE_HANDLE`,
  CURRENT_USER_EMAIL_UPDATE: `${PREFIX}/CURRENT_USER_EMAIL_UPDATE`,
  CURRENT_USER_EMAIL_UPDATE_ERROR_CLEAR: `${PREFIX}/CURRENT_USER_EMAIL_UPDATE_ERROR_CLEAR`,
  CURRENT_USER_PASSWORD_UPDATE: `${PREFIX}/CURRENT_USER_PASSWORD_UPDATE`,
  CURRENT_USER_PASSWORD_UPDATE_ERROR_CLEAR: `${PREFIX}/CURRENT_USER_PASSWORD_UPDATE_ERROR_CLEAR`,
  CURRENT_USER_USERNAME_UPDATE: `${PREFIX}/CURRENT_USER_USERNAME_UPDATE`,
  CURRENT_USER_USERNAME_UPDATE_ERROR_CLEAR: `${PREFIX}/CURRENT_USER_USERNAME_UPDATE_ERROR_CLEAR`,
  CURRENT_USER_AVATAR_UPDATE: `${PREFIX}/CURRENT_USER_AVATAR_UPDATE`,
  USER_DELETE: `${PREFIX}/USER_DELETE`,
  USER_DELETE_HANDLE: `${PREFIX}/USER_DELETE_HANDLE`,
  USER_TO_SELLITEM_ADD: `${PREFIX}/USER_TO_SELLITEM_ADD`,
  USER_TO_CURRENT_SELLITEM_ADD: `${PREFIX}/USER_TO_CURRENT_SELLITEM_ADD`,
  USER_TO_SELLITEM_ADD_HANDLE: `${PREFIX}/USER_TO_SELLITEM_ADD_HANDLE`,
  USER_FROM_SELLITEM_REMOVE: `${PREFIX}/USER_FROM_SELLITEM_REMOVE`,
  USER_FROM_CURRENT_SELLITEM_REMOVE: `${PREFIX}/USER_FROM_CURRENT_SELLITEM_REMOVE`,
  USER_FROM_SELLITEM_REMOVE_HANDLE: `${PREFIX}/USER_FROM_SELLITEM_REMOVE_HANDLE`,
  USER_TO_FILTER_IN_CURRENT_GROUP_ADD: `${PREFIX}/USER_TO_FILTER_IN_CURRENT_GROUP_ADD`,
  USER_FROM_FILTER_IN_CURRENT_GROUP_REMOVE: `${PREFIX}/USER_FROM_FILTER_IN_CURRENT_GROUP_REMOVE`,

  /* Business */

  BUSINESS_CREATE: `${PREFIX}/BUSINESS_CREATE`,
  BUSINESS_CREATE_HANDLE: `${PREFIX}/BUSINESS_CREATE_HANDLE`,
  CURRENT_BUSINESS_UPDATE: `${PREFIX}/CURRENT_BUSINESS_UPDATE`,
  BUSINESS_UPDATE_HANDLE: `${PREFIX}/BUSINESS_UPDATE_HANDLE`,
  CURRENT_BUSINESS_BACKGROUND_IMAGE_UPDATE: `${PREFIX}/CURRENT_BUSINESS_BACKGROUND_IMAGE_UPDATE`,
  CURRENT_BUSINESS_DELETE: `${PREFIX}/CURRENT_BUSINESS_DELETE`,
  BUSINESS_DELETE_HANDLE: `${PREFIX}/BUSINESS_DELETE_HANDLE`,

  /* Business manager */

  MANAGER_IN_CURRENT_BUSINESS_CREATE: `${PREFIX}/MANAGER_IN_CURRENT_BUSINESS_CREATE`,
  BUSINESS_MANAGER_CREATE_HANDLE: `${PREFIX}/BUSINESS_MANAGER_CREATE_HANDLE`,
  BUSINESS_MANAGER_DELETE: `${PREFIX}/BUSINESS_MANAGER_DELETE`,
  BUSINESS_MANAGER_DELETE_HANDLE: `${PREFIX}/BUSINESS_MANAGER_DELETE_HANDLE`,

  /* Country */

  COUNTRY_IN_CURRENT_BUSINESS_CREATE: `${PREFIX}/COUNTRY_IN_CURRENT_BUSINESS_CREATE`,
  COUNTRY_CREATE_HANDLE: `${PREFIX}/COUNTRY_CREATE_HANDLE`,
  COUNTRY_UPDATE: `${PREFIX}/COUNTRY_UPDATE`,
  COUNTRY_UPDATE_HANDLE: `${PREFIX}/COUNTRY_UPDATE_HANDLE`,
  COUNTRY_DELETE: `${PREFIX}/COUNTRY_DELETE`,
  COUNTRY_DELETE_HANDLE: `${PREFIX}/COUNTRY_DELETE_HANDLE`,
  COUNTRY_TO_FILTER_IN_CURRENT_GROUP_ADD: `${PREFIX}/COUNTRY_TO_FILTER_IN_CURRENT_GROUP_ADD`,
  COUNTRY_FROM_FILTER_IN_CURRENT_GROUP_REMOVE: `${PREFIX}/COUNTRY_FROM_FILTER_IN_CURRENT_GROUP_REMOVE`,

  /* Brand */

  BRAND_IN_CURRENT_BUSINESS_CREATE: `${PREFIX}/BRAND_IN_CURRENT_BUSINESS_CREATE`,
  BRAND_CREATE_HANDLE: `${PREFIX}/BRAND_CREATE_HANDLE`,
  BRAND_UPDATE: `${PREFIX}/BRAND_UPDATE`,
  BRAND_UPDATE_HANDLE: `${PREFIX}/BRAND_UPDATE_HANDLE`,
  BRAND_DELETE: `${PREFIX}/BRAND_DELETE`,
  BRAND_DELETE_HANDLE: `${PREFIX}/BRAND_DELETE_HANDLE`,
  BRAND_TO_FILTER_IN_CURRENT_GROUP_ADD: `${PREFIX}/BRAND_TO_FILTER_IN_CURRENT_GROUP_ADD`,
  BRAND_FROM_FILTER_IN_CURRENT_GROUP_REMOVE: `${PREFIX}/BRAND_FROM_FILTER_IN_CURRENT_GROUP_REMOVE`,

  /* Category */

  CATEGORY_IN_CURRENT_BUSINESS_CREATE: `${PREFIX}/CATEGORY_IN_CURRENT_BUSINESS_CREATE`,
  CATEGORY_CREATE_HANDLE: `${PREFIX}/CATEGORY_CREATE_HANDLE`,
  CATEGORY_UPDATE: `${PREFIX}/CATEGORY_UPDATE`,
  CATEGORY_UPDATE_HANDLE: `${PREFIX}/CATEGORY_UPDATE_HANDLE`,
  CATEGORY_DELETE: `${PREFIX}/CATEGORY_DELETE`,
  CATEGORY_DELETE_HANDLE: `${PREFIX}/CATEGORY_DELETE_HANDLE`,
  CATEGORY_TO_FILTER_IN_CURRENT_GROUP_ADD: `${PREFIX}/CATEGORY_TO_FILTER_IN_CURRENT_GROUP_ADD`,
  CATEGORY_FROM_FILTER_IN_CURRENT_GROUP_REMOVE: `${PREFIX}/CATEGORY_FROM_FILTER_IN_CURRENT_GROUP_REMOVE`,

  /* Account */

  ACCOUNT_IN_CURRENT_BUSINESS_CREATE: `${PREFIX}/ACCOUNT_IN_CURRENT_BUSINESS_CREATE`,
  ACCOUNT_CREATE_HANDLE: `${PREFIX}/ACCOUNT_CREATE_HANDLE`,
  ACCOUNT_UPDATE: `${PREFIX}/ACCOUNT_UPDATE`,
  ACCOUNT_UPDATE_HANDLE: `${PREFIX}/ACCOUNT_UPDATE_HANDLE`,
  ACCOUNT_DELETE: `${PREFIX}/ACCOUNT_DELETE`,
  ACCOUNT_DELETE_HANDLE: `${PREFIX}/ACCOUNT_DELETE_HANDLE`,

  /* Customer group */

  CUSTOMER_GROUP_IN_CURRENT_BUSINESS_CREATE: `${PREFIX}/CUSTOMER_GROUP_IN_CURRENT_BUSINESS_CREATE`,
  CUSTOMER_GROUP_CREATE_HANDLE: `${PREFIX}/CUSTOMER_GROUP_CREATE_HANDLE`,
  CUSTOMER_GROUP_UPDATE: `${PREFIX}/CUSTOMER_GROUP_UPDATE`,
  CUSTOMER_GROUP_UPDATE_HANDLE: `${PREFIX}/CUSTOMER_GROUP_UPDATE_HANDLE`,
  CUSTOMER_GROUP_DELETE: `${PREFIX}/CUSTOMER_GROUP_DELETE`,
  CUSTOMER_GROUP_DELETE_HANDLE: `${PREFIX}/CUSTOMER_GROUP_DELETE_HANDLE`,

  /* Group */

  GROUP_IN_CURRENT_BUSINESS_CREATE: `${PREFIX}/GROUP_IN_CURRENT_BUSINESS_CREATE`,
  GROUP_CREATE_HANDLE: `${PREFIX}/GROUP_CREATE_HANDLE`,
  GROUP_FETCH: `${PREFIX}/GROUP_FETCH`,
  GROUP_UPDATE: `${PREFIX}/GROUP_UPDATE`,
  GROUP_UPDATE_HANDLE: `${PREFIX}/GROUP_UPDATE_HANDLE`,
  GROUP_MOVE: `${PREFIX}/GROUP_MOVE`,
  GROUP_DELETE: `${PREFIX}/GROUP_DELETE`,
  GROUP_DELETE_HANDLE: `${PREFIX}/GROUP_DELETE_HANDLE`,

  /* Group membership */

  MEMBERSHIP_IN_CURRENT_GROUP_CREATE: `${PREFIX}/MEMBERSHIP_IN_CURRENT_GROUP_CREATE`,
  GROUP_MEMBERSHIP_CREATE_HANDLE: `${PREFIX}/GROUP_MEMBERSHIP_CREATE_HANDLE`,
  GROUP_MEMBERSHIP_DELETE: `${PREFIX}/GROUP_MEMBERSHIP_DELETE`,
  GROUP_MEMBERSHIP_DELETE_HANDLE: `${PREFIX}/GROUP_MEMBERSHIP_DELETE_HANDLE`,

  /* Label */

  LABEL_IN_CURRENT_GROUP_CREATE: `${PREFIX}/LABEL_IN_CURRENT_GROUP_CREATE`,
  LABEL_CREATE_HANDLE: `${PREFIX}/LABEL_CREATE_HANDLE`,
  LABEL_UPDATE: `${PREFIX}/LABEL_UPDATE`,
  LABEL_UPDATE_HANDLE: `${PREFIX}/LABEL_UPDATE_HANDLE`,
  LABEL_DELETE: `${PREFIX}/LABEL_DELETE`,
  LABEL_DELETE_HANDLE: `${PREFIX}/LABEL_DELETE_HANDLE`,
  LABEL_TO_SELLITEM_ADD: `${PREFIX}/LABEL_TO_SELLITEM_ADD`,
  LABEL_TO_CURRENT_SELLITEM_ADD: `${PREFIX}/LABEL_TO_CURRENT_SELLITEM_ADD`,
  LABEL_TO_SELLITEM_ADD_HANDLE: `${PREFIX}/LABEL_TO_SELLITEM_ADD_HANDLE`,
  LABEL_FROM_SELLITEM_REMOVE: `${PREFIX}/LABEL_FROM_SELLITEM_REMOVE`,
  LABEL_FROM_CURRENT_SELLITEM_REMOVE: `${PREFIX}/LABEL_FROM_CURRENT_SELLITEM_REMOVE`,
  LABEL_FROM_SELLITEM_REMOVE_HANDLE: `${PREFIX}/LABEL_FROM_SELLITEM_REMOVE_HANDLE`,
  LABEL_TO_FILTER_IN_CURRENT_GROUP_ADD: `${PREFIX}/LABEL_TO_FILTER_IN_CURRENT_GROUP_ADD`,
  LABEL_FROM_FILTER_IN_CURRENT_GROUP_REMOVE: `${PREFIX}/LABEL_FROM_FILTER_IN_CURRENT_GROUP_REMOVE`,

  /* Customer */

  CUSTOMER_IN_CURRENT_GROUP_CREATE: `${PREFIX}/CUSTOMER_IN_CURRENT_GROUP_CREATE`,
  CUSTOMER_CREATE_HANDLE: `${PREFIX}/CUSTOMER_CREATE_HANDLE`,
  CUSTOMER_FETCH: `${PREFIX}/CUSTOMER_FETCH`,
  CUSTOMER_UPDATE: `${PREFIX}/CUSTOMER_UPDATE`,
  CUSTOMER_UPDATE_HANDLE: `${PREFIX}/CUSTOMER_UPDATE_HANDLE`,
  CUSTOMER_MOVE: `${PREFIX}/CUSTOMER_MOVE`,
  CUSTOMER_DELETE: `${PREFIX}/CUSTOMER_DELETE`,
  CUSTOMER_DELETE_HANDLE: `${PREFIX}/CUSTOMER_DELETE_HANDLE`,
  CUSTOMERS_SEARCH: `${PREFIX}/CUSTOMERS_SEARCH`,
  CUSTOMERS_FETCH: `${PREFIX}/CUSTOMERS_FETCH`,

  /* Import */

  GROUP_IMPORT: `${PREFIX}/GROUP_IMPORT`,
  GROUP_IMPORT_ERROR_CLEAR: `${PREFIX}/GROUP_IMPORT_ERROR_CLEAR`,

  /* Product */

  PRODUCT_CREATE: `${PREFIX}/PRODUCT_CREATE`,
  PRODUCT_CREATE_HANDLE: `${PREFIX}/PRODUCT_CREATE_HANDLE`,
  PRODUCT_UPDATE: `${PREFIX}/PRODUCT_UPDATE`,
  CURRENT_PRODUCT_UPDATE: `${PREFIX}/CURRENT_PRODUCT_UPDATE`,
  PRODUCT_UPDATE_HANDLE: `${PREFIX}/PRODUCT_UPDATE_HANDLE`,
  PRODUCT_DELETE: `${PREFIX}/PRODUCT_DELETE`,
  CURRENT_PRODUCT_DELETE: `${PREFIX}/CURRENT_PRODUCT_DELETE`,
  PRODUCT_DELETE_HANDLE: `${PREFIX}/PRODUCT_DELETE_HANDLE`,
  PRODUCTS_SEARCH: `${PREFIX}/PRODUCTS_SEARCH`,
  PRODUCTS_FETCH: `${PREFIX}/PRODUCTS_FETCH`,

  /* Address */

  ADDRESS_IN_CURRENT_CUSTOMER_CREATE: `${PREFIX}/ADDRESS_IN_CURRENT_CUSTOMER_CREATE`,
  ADDRESS_CREATE: `${PREFIX}/ADDRESS_CREATE`,
  ADDRESS_CREATE_HANDLE: `${PREFIX}/ADDRESS_CREATE_HANDLE`,
  ADDRESS_UPDATE: `${PREFIX}/ADDRESS_UPDATE`,
  ADDRESS_UPDATE_HANDLE: `${PREFIX}/ADDRESS_UPDATE_HANDLE`,
  ADDRESS_DELETE: `${PREFIX}/ADDRESS_DELETE`,
  ADDRESS_DELETE_HANDLE: `${PREFIX}/ADDRESS_DELETE_HANDLE`,

  /* Shipment */

  SHIPMENT_CREATE: `${PREFIX}/SHIPMENT_CREATE`,
  SHIPMENT_CREATE_HANDLE: `${PREFIX}/SHIPMENT_CREATE_HANDLE`,
  SHIPMENT_UPDATE: `${PREFIX}/SHIPMENT_UPDATE`,
  CURRENT_SHIPMENT_UPDATE: `${PREFIX}/CURRENT_SHIPMENT_UPDATE`,
  SHIPMENT_UPDATE_HANDLE: `${PREFIX}/SHIPMENT_UPDATE_HANDLE`,
  SHIPMENT_DELETE: `${PREFIX}/SHIPMENT_DELETE`,
  CURRENT_SHIPMENT_DELETE: `${PREFIX}/CURRENT_SHIPMENT_DELETE`,
  SHIPMENT_DELETE_HANDLE: `${PREFIX}/SHIPMENT_DELETE_HANDLE`,
  SHIPMENTS_SEARCH: `${PREFIX}/SHIPMENTS_SEARCH`,
  SHIPMENTS_FETCH: `${PREFIX}/SHIPMENTS_FETCH`,

  SHIPLINE_CREATE: `${PREFIX}/SHIPLINE_CREATE`,
  SHIPLINE_CREATE_HANDLE: `${PREFIX}/SHIPLINE_CREATE_HANDLE`,
  SHIPLINE_UPDATE: `${PREFIX}/SHIPLINE_UPDATE`,
  CURRENT_SHIPLINE_UPDATE: `${PREFIX}/CURRENT_SHIPLINE_UPDATE`,
  SHIPLINE_UPDATE_HANDLE: `${PREFIX}/SHIPLINE_UPDATE_HANDLE`,
  SHIPLINE_DELETE: `${PREFIX}/SHIPLINE_DELETE`,
  CURRENT_SHIPLINE_DELETE: `${PREFIX}/CURRENT_SHIPLINE_DELETE`,
  SHIPLINE_DELETE_HANDLE: `${PREFIX}/SHIPLINE_DELETE_HANDLE`,

  /* Sellitem */

  SELLITEM_CREATE: `${PREFIX}/SELLITEM_CREATE`,
  SELLITEM_CREATE_HANDLE: `${PREFIX}/SELLITEM_CREATE_HANDLE`,
  SELLITEM_UPDATE: `${PREFIX}/SELLITEM_UPDATE`,
  CURRENT_SELLITEM_UPDATE: `${PREFIX}/CURRENT_SELLITEM_UPDATE`,
  SELLITEM_UPDATE_HANDLE: `${PREFIX}/SELLITEM_UPDATE_HANDLE`,
  SELLITEMS_UPDATE: `${PREFIX}/SELLITEMS_UPDATE`,
  SELLITEMS_UPDATE_HANDLE: `${PREFIX}/SELLITEMS_UPDATE_HANDLE`,
  SELLITEM_MOVE: `${PREFIX}/SELLITEM_MOVE`,
  CURRENT_SELLITEM_MOVE: `${PREFIX}/CURRENT_SELLITEM_MOVE`,
  SELLITEM_TRANSFER: `${PREFIX}/SELLITEM_TRANSFER`,
  CURRENT_SELLITEM_TRANSFER: `${PREFIX}/CURRENT_SELLITEM_TRANSFER`,
  SELLITEM_DELETE: `${PREFIX}/SELLITEM_DELETE`,
  CURRENT_SELLITEM_DELETE: `${PREFIX}/CURRENT_SELLITEM_DELETE`,
  SELLITEM_DELETE_HANDLE: `${PREFIX}/SELLITEM_DELETE_HANDLE`,

  /* Sellitems */
  SELLITEMS_IN_CURRENT_GROUP_FETCH: `${PREFIX}/SELLITEMS_IN_CURRENT_GROUP_FETCH`,

  /* Payment */

  PAYMENT_CREATE: `${PREFIX}/PAYMENT_CREATE`,
  PAYMENT_CREATE_HANDLE: `${PREFIX}/PAYMENT_CREATE_HANDLE`,
  PAYMENT_UPDATE: `${PREFIX}/PAYMENT_UPDATE`,
  PAYMENT_UPDATE_HANDLE: `${PREFIX}/PAYMENT_UPDATE_HANDLE`,
  PAYMENT_DELETE: `${PREFIX}/PAYMENT_DELETE`,
  PAYMENT_DELETE_HANDLE: `${PREFIX}/PAYMENT_DELETE_HANDLE`,

  /* Attachment */

  ATTACHMENT_IN_CURRENT_SELLITEM_CREATE: `${PREFIX}/ATTACHMENT_IN_CURRENT_SELLITEM_CREATE`,
  ATTACHMENT_CREATE_HANDLE: `${PREFIX}/ATTACHMENT_CREATE_HANDLE`,
  ATTACHMENT_UPDATE: `${PREFIX}/ATTACHMENT_UPDATE`,
  ATTACHMENT_UPDATE_HANDLE: `${PREFIX}/ATTACHMENT_UPDATE_HANDLE`,
  ATTACHMENT_DELETE: `${PREFIX}/ATTACHMENT_DELETE`,
  ATTACHMENT_DELETE_HANDLE: `${PREFIX}/ATTACHMENT_DELETE_HANDLE`,
  ATTACHMENT_PRODUCT_CREATE: `${PREFIX}/ATTACHMENT_PRODUCT_CREATE`,

  /* Actions */

  ACTIONS_IN_CURRENT_SELLITEM_FETCH: `${PREFIX}/ACTIONS_IN_CURRENT_SELLITEM_FETCH`,

  /* Action */

  ACTION_CREATE_HANDLE: `${PREFIX}/ACTION_CREATE_HANDLE`,
  ACTION_UPDATE_HANDLE: `${PREFIX}/ACTION_UPDATE_HANDLE`,
  ACTION_DELETE_HANDLE: `${PREFIX}/ACTION_DELETE_HANDLE`,

  /* Comment action */

  COMMENT_ACTION_IN_CURRENT_SELLITEM_CREATE: `${PREFIX}/COMMENT_ACTION_IN_CURRENT_SELLITEM_CREATE`,
  COMMENT_ACTION_UPDATE: `${PREFIX}/COMMENT_ACTION_UPDATE`,
  COMMENT_ACTION_DELETE: `${PREFIX}/COMMENT_ACTION_DELETE`,

  /* Notification */

  NOTIFICATION_CREATE_HANDLE: `${PREFIX}/NOTIFICATION_CREATE_HANDLE`,
  NOTIFICATION_DELETE: `${PREFIX}/NOTIFICATION_DELETE`,
  NOTIFICATION_DELETE_HANDLE: `${PREFIX}/NOTIFICATION_DELETE_HANDLE`,

  /* Stock */
  STOCK_CREATE: `${PREFIX}/STOCK_CREATE`,
  STOCK_CREATE_HANDLE: `${PREFIX}/STOCK_CREATE_HANDLE`,
  STOCK_UPDATE: `${PREFIX}/STOCK_UPDATE`,
  STOCK_UPDATE_HANDLE: `${PREFIX}/STOCK_UPDATE_HANDLE`,

  /* Filter */
  ORDER_DATE_TO_FILTER_IN_CURRENT_GROUP_CHANGE: `${PREFIX}/ORDER_DATE_TO_FILTER_IN_CURRENT_GROUP_CHANGE`,
  ARRIVAL_DATE_TO_FILTER_IN_CURRENT_GROUP_CHANGE: `${PREFIX}/ARRIVAL_DATE_TO_FILTER_IN_CURRENT_GROUP_CHANGE`,
  WEB_ORDER_NO_TO_FILTER_IN_CURRENT_GROUP_CHANGE: `${PREFIX}/WEB_ORDER_NO_TO_FILTER_IN_CURRENT_GROUP_CHANGE`,
  ARRIVAL_DATE_FOR_SHIPMENT_TO_FILTER_IN_CURRENT_GROUP_CHANGE: `${PREFIX}/ARRIVAL_DATE_FOR_SHIPMENT_TO_FILTER_IN_CURRENT_GROUP_CHANGE`,
  STATUS_TO_FILTER_IN_CURRENT_GROUP_ADD: `${PREFIX}/STATUS_TO_FILTER_IN_CURRENT_GROUP_ADD`,
  STATUS_FROM_FILTER_IN_CURRENT_GROUP_REMOVE: `${PREFIX}/STATUS_FROM_FILTER_IN_CURRENT_GROUP_REMOVE`,
  SHIPMENT_STATUS_TO_FILTER_IN_CURRENT_GROUP_ADD: `${PREFIX}/SHIPMENT_STATUS_TO_FILTER_IN_CURRENT_GROUP_ADD`,
  SHIPMENT_STATUS_FROM_FILTER_IN_CURRENT_GROUP_REMOVE: `${PREFIX}/SHIPMENT_STATUS_FROM_FILTER_IN_CURRENT_GROUP_REMOVE`,

  SHIP_PROVINCE_TO_FILTER_IN_CURRENT_GROUP_ADD: `${PREFIX}/SHIP_PROVINCE_TO_FILTER_IN_CURRENT_GROUP_ADD`,
  SHIP_PROVINCE_FROM_FILTER_IN_CURRENT_GROUP_REMOVE: `${PREFIX}/SHIP_PROVINCE_FROM_FILTER_IN_CURRENT_GROUP_REMOVE`,

  /* Dashboard */
  DASHBOARD_FETCH: `${PREFIX}/DASHBOARD_FETCH`,

  /* Facebook */
  SEND_MESSAGE_FACEBOOK: `${PREFIX}/SEND_MESSAGE_FACEBOOK`,
  SEND_NOTIFICATION_FACEBOOK: `${PREFIX}/SEND_NOTIFICATION_FACEBOOK`,
};
