/* eslint-disable no-unused-vars */
/* eslint-disable no-unreachable */
import React from 'react';
import ReactDOM from 'react-dom';

import { List, Button, Form, Input, Tab } from 'semantic-ui-react';
import Handsontable from 'handsontable';
import AddAddressForm from './AddAddressForm';

import styles from './AddressAddEditor.module.scss';

const initialState = {
  id: null,
  customerId: null,
  value: null,
};

class PaymentAddEditor extends Handsontable.editors.TextEditor {
  init() {
    this.mainElementRef = React.createRef();
    this.props = { addresses: [], onCreate: () => {}, onUpdateShipline: () => {} };
    this.mystate = initialState;
  }

  open() {
    super.open();
    this.setState({
      ...initialState,
      id: this.instance.getDataAtRowProp(this.row, 'id'),
      customerId: this.instance.getDataAtRowProp(this.row, 'customerId'),
    });
    this.render();
    if (
      this.TEXTAREA_PARENT.offsetTop + this.TEXTAREA_PARENT.clientHeight + 20 >
      this.TEXTAREA_PARENT.offsetParent.clientHeight
    ) {
      this.TEXTAREA_PARENT.style.top = `${
        this.TEXTAREA_PARENT.offsetTop - this.TEXTAREA_PARENT.clientHeight
      }px`;
    } else {
      this.TEXTAREA_PARENT.style.top = `${this.TEXTAREA_PARENT.offsetTop + this.TD.clientHeight}px`;
    }

    if (
      this.TEXTAREA_PARENT.offsetLeft + this.TEXTAREA_PARENT.clientWidth >
      this.TEXTAREA_PARENT.offsetParent.clientWidth
    ) {
      this.TEXTAREA_PARENT.style.left = `${
        this.TEXTAREA_PARENT.offsetLeft - this.TEXTAREA_PARENT.clientWidth + this.TD.clientWidth + 1
      }px`;
    }
  }

  close() {
    super.close();
    this.instance.listen();
    Handsontable.dom.empty(this.TEXTAREA_PARENT);
    Handsontable.dom.addClass(this.TEXTAREA_PARENT, 'handsontableInputHolder');
    this.TEXTAREA_PARENT.appendChild(this.TEXTAREA);
  }

  setState(state) {
    this.mystate = { ...this.mystate, ...state };
  }

  render() {
    const { id: shiplineId, customerId } = this.mystate;
    const customerAddress = this.props.addresses?.filter((a) => a.customerId === customerId);

    const contentSelectAddress = (
      <List celled verticalAlign="middle" className={styles.addresses}>
        {customerAddress.map(({ id, name, phone, province, district, ward, address }) => (
          <List.Item
            key={id}
            className={styles.addressItem}
            onClick={(e) => this.handUpdateShiplineAddress(e, id)}
          >
            <List.Content>
              {name}&nbsp; - {phone}
              <br />
              <span className={styles.meta}>
                {address}, {ward}, {district}, {province}
              </span>
            </List.Content>
          </List.Item>
        ))}
        {customerAddress.length === 0 && <List.Item content={this.props.t('common.noAddress')} />}
      </List>
    );

    const contentAddAddress = (
      <AddAddressForm onCreate={(cleanData) => this.handleSubmit(cleanData)} />
    );

    const panes = [
      {
        menuItem: this.props.t('common.selectAddress'),
        render: () => <Tab.Pane className={styles.tabContent}>{contentSelectAddress}</Tab.Pane>,
      },
      {
        menuItem: this.props.t('common.createAddress'),
        render: () => <Tab.Pane className={styles.tabContent}>{contentAddAddress}</Tab.Pane>,
      },
    ];

    const renderResult = (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div onMouseDown={this.stopMousedownPropagation}>
        <div className={styles.wrapper} ref={this.mainElementRef} id="editorElement">
          <Tab panes={panes} />
        </div>
      </div>
    );

    Handsontable.dom.empty(this.TEXTAREA_PARENT);
    Handsontable.dom.addClass(this.TEXTAREA_PARENT, 'handsontableInputHolder');
    const div = document.createElement('div');
    ReactDOM.render(renderResult, div);

    this.TEXTAREA_PARENT.appendChild(div);
  }

  prepare(row, col, prop, td, originalValue, cellProperties) {
    super.prepare(row, col, prop, td, originalValue, cellProperties);

    const { addresses, onCreate, onUpdateShipline, instance, t } = cellProperties;
    const customerId = instance.getDataAtRowProp(this.row, 'customerId');
    this.props = { addresses, onCreate, onUpdateShipline, customerId, t };
  }

  handUpdateShiplineAddress(e, id) {
    e.preventDefault();
    const newData = {
      addressId: id,
      status: 'notShip',
    };
    this.props.onUpdateShipline(this.mystate.id, newData);
    this.finishEditing();
  }

  handleSubmit(cleanData) {
    const { id: shiplineId, customerId } = this.mystate;
    this.props.onCreate(customerId, cleanData, shiplineId);
    this.finishEditing(false, false, () => setTimeout(() => this.close()));
  }
}

export default PaymentAddEditor;
