import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  isCurrentUserMemberForCurrentGroupSelector,
  customersForCurrentGroupSelector,
  pathSelector,
  customerGroupsForCurrentBusinessSelector,
  // currentGroupSelector,
  customerByIdSelector,
} from '../selectors';
import { createCustomerInCurrentGroup, searchCustomers } from '../actions/entry';
import LeftSidebar from '../components/Customers/LeftSidebar';
// import { addTodoSellitem } from '../actions';

const mapStateToProps = (state) => {
  const { customerId } = pathSelector(state);
  const isCurrentUserMember = isCurrentUserMemberForCurrentGroupSelector(state);
  const customers = customersForCurrentGroupSelector(state);
  const customerGroups = customerGroupsForCurrentBusinessSelector(state);
  // const { customerQuery } = currentGroupSelector(state);

  const customer = customerByIdSelector(state, customerId);
  // if (customers.findIndex(({ id }) => id === customerId) < 0) {
  //   customers.push(customer);
  // }

  console.log('CustomersLeftSidebarContainer');

  return {
    customer,
    customers,
    customerGroups,
    canEdit: isCurrentUserMember,
    searchCustomerResults: { isSubmitting: false, customers: [], query: undefined },
    // searchCustomerResults: customers || { isSubmitting: false, customers: [], query: '' },
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onCustomerCreate: createCustomerInCurrentGroup,
      onSearchCustomers: (query) => searchCustomers(query),
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(LeftSidebar);
