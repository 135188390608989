import ActionTypes from '../../constants/ActionTypes';

const initialState = {
  data: {
    invoice: '',
    colorCode: '',
    sizeCode: '',
    color: '',
    size: '',
    quantity: 1,
  },
  isSubmitting: false,
  error: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SELLITEM_CREATE:
      return {
        ...state,
        data: {
          ...state.data,
          ...payload.sellitem,
        },
        isSubmitting: true,
      };
    case ActionTypes.SELLITEM_CREATE__SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        error: null,
      };
    case ActionTypes.SELLITEM_CREATE__FAILURE:
      return {
        ...state,
        isSubmitting: false,
        error: payload.error,
      };
    // case ActionTypes.SELLITEM_CREATE_ERROR_CLEAR:
    //   return {
    //     ...state,
    //     error: null,
    //   };
    case ActionTypes.CUSTOMER_CREATE__SUCCESS:
      return {
        ...initialState,
        customer: payload.customer,
      };
    default:
      return state;
  }
};
