import ActionTypes from '../constants/ActionTypes';

export const createGroupMembership = (groupMembership) => ({
  type: ActionTypes.GROUP_MEMBERSHIP_CREATE,
  payload: {
    groupMembership,
  },
});

createGroupMembership.success = (localId, groupMembership) => ({
  type: ActionTypes.GROUP_MEMBERSHIP_CREATE__SUCCESS,
  payload: {
    localId,
    groupMembership,
  },
});

createGroupMembership.failure = (localId, error) => ({
  type: ActionTypes.GROUP_MEMBERSHIP_CREATE__FAILURE,
  payload: {
    localId,
    error,
  },
});

export const handleGroupMembershipCreate = (
  groupMembership,
  business,
  group,
  users,
  businessManagers,
  groups,
  groupMemberships,
  labels,
  customers,
  addresses,
  sellitems,
  sellitemMemberships,
  sellitemLabels,
  payments,
  attachments,
  products,
  categories,
  productCategories,
  shipments,
) => ({
  type: ActionTypes.GROUP_MEMBERSHIP_CREATE_HANDLE,
  payload: {
    groupMembership,
    business,
    group,
    users,
    businessManagers,
    groups,
    groupMemberships,
    labels,
    customers,
    addresses,
    sellitems,
    sellitemMemberships,
    sellitemLabels,
    payments,
    attachments,
    products,
    categories,
    productCategories,
    shipments,
  },
});

handleGroupMembershipCreate.fetchBusiness = (id, currentUserId, currentGroupId) => ({
  type: ActionTypes.GROUP_MEMBERSHIP_CREATE_HANDLE__BUSINESS_FETCH,
  payload: {
    id,
    currentUserId,
    currentGroupId,
  },
});

export const deleteGroupMembership = (id) => ({
  type: ActionTypes.GROUP_MEMBERSHIP_DELETE,
  payload: {
    id,
  },
});

deleteGroupMembership.success = (groupMembership) => ({
  type: ActionTypes.GROUP_MEMBERSHIP_DELETE__SUCCESS,
  payload: {
    groupMembership,
  },
});

deleteGroupMembership.failure = (id, error) => ({
  type: ActionTypes.GROUP_MEMBERSHIP_DELETE__FAILURE,
  payload: {
    id,
    error,
  },
});

export const handleGroupMembershipDelete = (groupMembership) => ({
  type: ActionTypes.GROUP_MEMBERSHIP_DELETE_HANDLE,
  payload: {
    groupMembership,
  },
});
