import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { customersForCurrentGroupSelector, pathSelector, productByIdSelector } from '../selectors';
import {
  clearUserCreateError,
  createCustomerInCurrentGroup,
  createSellitem,
  searchCustomers,
} from '../actions/entry';
import AddOrder from '../components/Products/AddOrder';

const mapStateToProps = (state, { onChangeColor }) => {
  const {
    ui: {
      sellitemCreateForm: { data: defaultData, isSubmitting, error, customer },
    },
  } = state;
  // const { customers } = state;
  // console.log(state);

  const customers = customersForCurrentGroupSelector(state);

  const { productId } = pathSelector(state);

  let product;

  if (productId) {
    const {
      id,
      groupId,
      creatorUserId,
      coverAttachmentId,
      countryId,
      brandId,
      name,
      description,
      url,
      imageUrl,
      variation,
      color,
      size,
      unit,
      webCode,
      webPrice,
      webExtra,
      sellPrice,
      wholesalePrice,
      feeAmount,
      weight,
      weightAmount,
      isWithWeightAmount,
      createdAt,
      updatedAt,
    } = productByIdSelector(state, productId);

    product = {
      id,
      groupId,
      creatorUserId,
      coverAttachmentId,
      countryId,
      brandId,
      name,
      description,
      url,
      imageUrl,
      variation,
      color,
      size,
      unit,
      webCode,
      webPrice,
      webExtra,
      sellPrice,
      wholesalePrice,
      feeAmount,
      weight,
      weightAmount,
      isWithWeightAmount,
      createdAt,
      updatedAt,
    };
  }
  return {
    defaultData,
    isSubmitting,
    error,
    product,
    customer,
    onChangeColor,
    searchResults: { isSubmitting: false, customers: customers.slice(0, 50), query: undefined },
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onSearchCustomers: searchCustomers,
      onCreateCustomer: createCustomerInCurrentGroup,
      onCreateSellitem: (data) => createSellitem(data.customerId, data),
      onMessageDismiss: clearUserCreateError,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(AddOrder);
