import { all, takeEvery } from 'redux-saga/effects';

import {
  createAddressInShiplineService,
  createAddressInCurrentCustomerService,
  deleteAddressService,
  handleAddressCreateService,
  handleAddressDeleteService,
  handleAddressUpdateService,
  updateAddressService,
} from '../services';
import EntryActionTypes from '../../../constants/EntryActionTypes';

export default function* paymentWatchers() {
  yield all([
    takeEvery(EntryActionTypes.ADDRESS_IN_CURRENT_CUSTOMER_CREATE, ({ payload: { data } }) =>
      createAddressInCurrentCustomerService(data),
    ),
    takeEvery(EntryActionTypes.ADDRESS_CREATE, ({ payload: { customerId, data, shiplineId } }) =>
      createAddressInShiplineService(customerId, data, shiplineId),
    ),
    takeEvery(EntryActionTypes.ADDRESS_CREATE_HANDLE, ({ payload: { payment } }) =>
      handleAddressCreateService(payment),
    ),
    takeEvery(EntryActionTypes.ADDRESS_UPDATE, ({ payload: { id, data } }) =>
      updateAddressService(id, data),
    ),
    takeEvery(EntryActionTypes.ADDRESS_UPDATE_HANDLE, ({ payload: { payment } }) =>
      handleAddressUpdateService(payment),
    ),
    takeEvery(EntryActionTypes.ADDRESS_DELETE, ({ payload: { id } }) => deleteAddressService(id)),
    takeEvery(EntryActionTypes.ADDRESS_DELETE_HANDLE, ({ payload: { payment } }) =>
      handleAddressDeleteService(payment),
    ),
  ]);
}
