import React, { useCallback, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Form } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import { withPopup } from '../../lib/popup';
import { Input, Popup } from '../../lib/custom-ui';

import { useForm } from '../../hooks';

import styles from './AddPopup.module.scss';

const AddStep = React.memo(({ onCreate, onClose }) => {
  const [t] = useTranslation();

  const [data, handleFieldChange, setData] = useForm({
    shipper: '',
    note: '',
    status: 'notShip',
    estimatedDeliveryDate: '',
    deliveredDate: '',
  });

  const shipperField = useRef(null);
  const noteField = useRef(null);

  const handleSubmit = useCallback(() => {
    const cleanData = {
      ...data,
      shipper: data.shipper.trim(),
      note: data.note.trim(),
      estimatedDeliveryDate: data.estimatedDeliveryDate || new Date(),
      deliveredDate: data.estimatedDeliveryDate || new Date(),
    };

    if (!cleanData.shipper) {
      cleanData.shipper = undefined;
    }

    if (!cleanData.note) {
      cleanData.note = undefined;
    }

    if (!cleanData.estimatedDeliveryDate) {
      cleanData.estimatedDeliveryDate = t('format:postDate', {
        postProcess: 'formatDate',
        value: new Date(),
      });
    }

    onCreate(cleanData);
    onClose();
  }, [onCreate, onClose, t, data]);

  const estimatedDeliveryDate = useMemo(() => {
    let date = new Date();
    if (data.estimatedDeliveryDate !== '') {
      date = t('format:postDate', {
        postProcess: 'parseDate',
        value: data.estimatedDeliveryDate,
      });
    }

    if (Number.isNaN(date.getTime())) {
      return null;
    }

    return date;
  }, [data.estimatedDeliveryDate, t]);

  const handleDatePickerChange = useCallback(
    (date, type) => {
      setData((prevData) => ({
        ...prevData,
        [type]: t('format:postDate', {
          postProcess: 'formatDate',
          value: date || new Date(),
        }),
      }));
    },
    [setData, t],
  );

  useEffect(() => {
    shipperField.current.select();
  }, []);

  return (
    <>
      <Popup.Header>
        {t('common.createShipment', {
          context: 'title',
        })}
      </Popup.Header>
      <Popup.Content>
        <Form onSubmit={handleSubmit}>
          <Input
            fluid
            ref={shipperField}
            name="shipper"
            value={data.shipper}
            className={styles.field}
            placeholder={t('common.shipper')}
            onChange={handleFieldChange}
          />
          <Input
            fluid
            ref={noteField}
            name="note"
            value={data.note}
            className={styles.field}
            placeholder={t('common.note')}
            onChange={handleFieldChange}
          />
          <DatePicker
            className={styles.field}
            placeholderText={t('common.estimatedDeliveryDate')}
            selected={estimatedDeliveryDate}
            onChange={(date) => handleDatePickerChange(date, 'estimatedDeliveryDate')}
          />
          <Button positive content={t('action.createShipment')} />
        </Form>
      </Popup.Content>
    </>
  );
});

AddStep.propTypes = {
  onCreate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default withPopup(AddStep);
