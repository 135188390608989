import React, { useCallback, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Grid } from 'semantic-ui-react';
import { HotTable, HotColumn } from '@handsontable/react';
import UserAddPopupContainer from '../../containers/UserAddPopupContainer';
import styles from './SellitemInvoiceModal.module.scss';

const SellitemInvoiceModal = React.memo(({ business, sellitems, onClose, onHideInvoice }) => {
  const [t] = useTranslation();

  const wrapper = useRef(null);

  const handleClose = useCallback(() => {
    onClose();

    if (onHideInvoice) {
      onHideInvoice();
    }
  }, [onHideInvoice, onClose]);

  const colHeaders = useMemo(
    () => [
      '#',
      t('common.sellitemsInvoice.column1'),
      t('common.sellitemsInvoice.column2'),
      t('common.sellitemsInvoice.column3'),
      t('common.sellitemsInvoice.column4'),
      t('common.sellitemsInvoice.column5'),
      t('common.sellitemsInvoice.column6'),
      t('common.sellitemsInvoice.column7'),
      t('common.sellitemsInvoice.column8'),
      t('common.sellitemsInvoice.column9'),
      t('common.sellitemsInvoice.column10'),
      t('common.sellitemsInvoice.column11'),
      t('common.sellitemsInvoice.column12'),
      t('common.sellitemsInvoice.column13'),
    ],
    [t],
  );

  return (
    <Modal open closeIcon size="large" centered={false} onClose={handleClose}>
      <Modal.Header>THÔNG BÁO ĐẶT HÀNG HÀNG (Mã đặt: 123-YYYYMMDD)</Modal.Header>
      <Modal.Content>
        <Grid className={styles.grid}>
          <Grid.Row className={styles.headerPadding}>
            <Grid.Column width={4} className={styles.headerPadding}>
              <div
                className={styles.thumbnail}
                style={{
                  background: `url("/KB_Logo.png") center / cover`,
                }}
              />
            </Grid.Column>
            <Grid.Column width={5} className={styles.headerPadding}>
              <div className={styles.headerWrapper}>
                <strong>{`${t('common.sellitemsInvoice.date')}: `}</strong>
                <span>05/09/2021</span>
              </div>
              <div className={styles.headerWrapper}>
                <strong>{`${t('common.sellitemsInvoice.facebook')}: `}</strong>
                <span>Công Tằng Tôn Nữ Thị Lan</span>
              </div>
              <div className={styles.headerWrapper}>
                <strong>{`${t('common.sellitemsInvoice.customerID')}: `}</strong>
                <span>123456</span>
              </div>
              <div className={styles.headerWrapper}>
                <strong>{`${t('common.sellitemsInvoice.wallet')}: `}</strong>
                <span>609,000₫</span>
              </div>
            </Grid.Column>
            <Grid.Column width={4} className={styles.headerPadding}>
              <div className={styles.headerWrapper}>
                <strong>{`${t('common.sellitemsInvoice.address')}: `}</strong>
                <span>293 Bùi Thiện Ngộ, phường Hòa Xuân, quận Cẩm Lê, thành phố Đà Nẵng</span>
              </div>
              <div className={styles.headerWrapper}>
                <strong>{`${t('common.sellitemsInvoice.phoneNumber')}: `}</strong>
                <span>0989123456</span>
              </div>
            </Grid.Column>
            <Grid.Column width={3} className={styles.headerPadding}>
              <div className={styles.headerWrapper}>
                <br />
                <br />
              </div>
              <div className={styles.paymentLayout}>
                <div className={styles.paymentTitle}>{`${t(
                  'common.sellitemsInvoice.totalPayment',
                )}: `}</div>
                <span>1,123,000₫</span>
              </div>
              <div className={styles.paymentLayout}>
                <div className={styles.paymentTitle}>{`${t(
                  'common.sellitemsInvoice.paid',
                )}: `}</div>
                <div>123,000₫</div>
              </div>
              <div className={styles.paymentLayout}>
                <div className={styles.paymentTitle}>{`${t(
                  'common.sellitemsInvoice.debt',
                )}: `}</div>
                <div className={styles.debt}>1,000,000₫</div>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <div ref={wrapper} className={styles.wrapper}>
          <div id="hot-app" className={styles.hotWrapper}>
            <HotTable
              data={sellitems}
              colHeaders={colHeaders}
              stretchH="all"
              readOnly
              autoColumnSize={false}
              rowHeaders={false}
              rowHeights={40}
              contextMenu={['remove_row']}
              enterMoves={{ col: 1, row: 0 }}
              mergeCells={[{ row: 4, col: 0, rowspan: 1, colspan: 5 }]}
              licenseKey="non-commercial-and-evaluation"
            >
              <HotColumn width={5} data="id" />
              <HotColumn width={10} data="invoice" />
              <HotColumn width={30} data="creator" />
              <HotColumn width={10} data="customer" />
              <HotColumn width={10} data="country" />
              <HotColumn width={10} data="brand" />
              <HotColumn width={10} data="name" wordWrap={false} />
              <HotColumn width={10} renderer="html" data="image" readOnly />
              <HotColumn width={10} data="color" wordWrap={false} />
              <HotColumn width={10} data="size" />
              <HotColumn width={10} data="webCode" />
              <HotColumn width={10} data="quantity" type="numeric" />
              <HotColumn width={10} data="quantity" type="numeric" />
              <HotColumn width={10} data="quantity" type="numeric" />
            </HotTable>
          </div>
        </div>
        <Grid className={styles.grid}>
          <Grid.Row className={styles.headerPadding}>
            <Grid.Column width={8} className={styles.headerPadding}>
              <div>{business.weightAmountPolicy}</div>
              <br />
              <div>{business.weightPolicy}</div>
            </Grid.Column>
            <Grid.Column width={8} className={styles.headerPadding}>
              <div className={styles.noteHeader}>Thông tin thanh toán:</div>
              <HotTable
                data={[]}
                colHeaders={colHeaders}
                stretchH="all"
                readOnly
                autoColumnSize={false}
                rowHeaders={false}
                rowHeights={40}
                contextMenu={['remove_row']}
                enterMoves={{ col: 1, row: 0 }}
                licenseKey="non-commercial-and-evaluation"
              >
                <HotColumn width={5} data="id" />
                <HotColumn width={10} data="invoice" />
                <HotColumn width={10} data="creator" />
                <HotColumn width={10} data="customer" />
                <HotColumn width={10} data="country" />
              </HotTable>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <UserAddPopupContainer>
          <Button positive content={t('action.print')} />
        </UserAddPopupContainer>
        <UserAddPopupContainer>
          <Button positive content={t('action.close')} />
        </UserAddPopupContainer>
      </Modal.Actions>
    </Modal>
  );
});

SellitemInvoiceModal.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  business: PropTypes.object.isRequired,
  sellitems: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  onClose: PropTypes.func.isRequired,
  onHideInvoice: PropTypes.func.isRequired,
};

export default SellitemInvoiceModal;
