import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Table } from 'semantic-ui-react';
import './printShipment.css';

const ShipmentPrint = React.forwardRef(({ shiplines }, ref) => {
  const [t] = useTranslation();
  return (
    <div className="wrapperShipmentPrint" ref={ref}>
      <div className="header">
        <div className="date">
          {t('format:dateTime', {
            postProcess: 'formatDate',
            value: new Date(),
          })}
        </div>
        <div className="title">{t('common.shipments')} - KhanhBuiOrder</div>
      </div>
      <div className="content">
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>#</Table.HeaderCell>
              <Table.HeaderCell>{t('common.customer')}</Table.HeaderCell>
              <Table.HeaderCell>{t('common.receiver')}</Table.HeaderCell>
              <Table.HeaderCell>{t('common.phoneReceiver')}</Table.HeaderCell>
              <Table.HeaderCell>{t('common.totalAmount')}</Table.HeaderCell>
              <Table.HeaderCell>{t('common.shipAmount')}</Table.HeaderCell>
              <Table.HeaderCell>{t('common.finalTotal')}</Table.HeaderCell>
              <Table.HeaderCell>{t('common.remainTotal')}</Table.HeaderCell>
              <Table.HeaderCell>{t('common.note')}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {shiplines.map((shipline, index) => (
              <Table.Row key={shipline.id}>
                <Table.Cell>{index + 1}</Table.Cell>
                <Table.Cell>
                  <b>{shipline.customer?.code}</b>
                  <br />
                  {shipline.customer?.name} - {shipline.customer?.phone}
                </Table.Cell>
                <Table.Cell>
                  <b>
                    {shipline.address?.name} - {shipline.address?.phone}
                  </b>
                  <br />
                  {shipline.defaultAddress}
                </Table.Cell>
                <Table.Cell>{shipline.address?.phone}</Table.Cell>
                <Table.Cell>{(shipline.total * 1).toLocaleString('en-GB')}</Table.Cell>
                <Table.Cell>{(shipline.shippingAmount * 1).toLocaleString('en-GB')}</Table.Cell>
                <Table.Cell>{(shipline.finalTotal * 1).toLocaleString('en-GB')}</Table.Cell>
                <Table.Cell>{(shipline.remainTotal * 1).toLocaleString('en-GB')}</Table.Cell>
                <Table.Cell>{shipline.note}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    </div>
  );
});

ShipmentPrint.propTypes = {
  shiplines: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default ShipmentPrint;
