import socket from './socket';

/* Actions */

const createCustomerGroup = (businessId, data, headers) =>
  socket.post(`/businesses/${businessId}/customer-groups`, data, headers);

const updateCustomerGroup = (id, data, headers) =>
  socket.patch(`/customer-groups/${id}`, data, headers);

const deleteCustomerGroup = (id, headers) =>
  socket.delete(`/customer-groups/${id}`, undefined, headers);

export default {
  createCustomerGroup,
  updateCustomerGroup,
  deleteCustomerGroup,
};
