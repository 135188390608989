import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { currentUserSelector, businessesForCurrentUserSelector } from '../selectors';
import { openBusinessAddModal } from '../actions/entry';
import Businesses from '../components/Businesses';

const mapStateToProps = (state) => {
  const { isAdmin, email } = currentUserSelector(state);
  const business = businessesForCurrentUserSelector(state);

  return {
    items: business,
    canAdd: isAdmin && email === 'kanri@tipici.vn',
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onAdd: openBusinessAddModal,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Businesses);
