/* eslint-disable no-unused-vars */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Label from '../../Label';
import ProductsPopup from './ProductsPopup';

import styles from './Filters.module.scss';

const Filters = React.memo(({ products, onChangeFilter, onChangeSearchKey }) => {
  const [t] = useTranslation();

  const onProductRemove = useCallback(
    (id) => {
      onChangeFilter(id);
    },
    [onChangeFilter],
  );

  const onProductAdd = useCallback(
    (key) => {
      onChangeFilter(key);
    },
    [onChangeFilter],
  );
  return (
    <>
      <span className={styles.filter}>
        <ProductsPopup
          items={products}
          currentIds={products.map((product) => product.id)}
          title="common.filterByLabels_title"
          onSelect={onProductAdd}
          onDeselect={onProductRemove}
        >
          <button type="button" className={styles.filterButton}>
            <span className={styles.filterTitle}>{`${t('common.products')}:`}</span>
            {products.length === 0 && <span className={styles.filterLabel}>{t('common.all')}</span>}
          </button>
        </ProductsPopup>
        {products.map((product) => (
          <span key={product.id} className={styles.filterItem}>
            <Label name={product.name} size="small" onClick={() => onProductRemove(product.id)} />
          </span>
        ))}
      </span>
    </>
  );
});

Filters.propTypes = {
  products: PropTypes.arrayOf(PropTypes.any).isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  onChangeSearchKey: PropTypes.func.isRequired,
};

export default Filters;
