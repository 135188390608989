import XLSX from 'sheetjs-style';
import { format } from 'date-fns';

// eslint-disable-next-line import/prefer-default-export
export const exportXlsx = (shiplines, colHeaders) => {
  const s = {
    // font: { bold: false },
    border: {
      top: { style: 'thin', color: { auto: 1 } },
      right: { style: 'thin', color: { auto: 1 } },
      bottom: { style: 'thin', color: { auto: 1 } },
      left: { style: 'thin', color: { auto: 1 } },
    },
  };
  const hs = {
    ...s,
    // fill: { patternType: 'solid', bgColor: { indexed: 64 } },
    alignment: { horizontal: 'center', vertical: 'top', wrapText: true },
    font: {
      // name: 'Roboto',
      // sz: 24,
      bold: true,
      // color: { rgb: 'FFFFAA00' },
    },
  };
  const h1 = {
    alignment: { horizontal: 'center', vertical: 'top', wrapText: true },
    font: {
      sz: 12,
      bold: true,
    },
  };
  // const h2 = {
  //   ...s,
  //   alignment: { horizontal: 'center', vertical: 'center' },
  //   font: {
  //     // name: 'Roboto',
  //     sz: 14,
  //     bold: true,
  //     // color: { rgb: 'FFFFAA00' },
  //   },
  // };

  const dataHeaders = [
    {
      STT: {
        v: `KHANHBUI.VN - SHIPMENT`,
        s: h1,
      },
      customerCode: { v: '', s: h1 },
      customerName: { v: '', s: h1 },
      receiver: { v: '', s: h1 },
      phone: { v: '', s: h1 },
      totalAmount: { v: '', s: h1 },
      shipAmount: { v: '', s: h1 },
      finalTotal: { v: '', s: h1 },
      remainTotal: { v: '', s: h1 },
      status: { v: '', s: h1 },
      shipper: { v: '', s: h1 },
      isShipProvince: { v: '', s: h1 },
      note: { v: '', s: h1 },
    },
    {
      STT: { v: colHeaders[0], s: hs },
      customerCode: { v: colHeaders[1], s: hs },
      customerName: { v: colHeaders[2], s: hs },
      receiver: { v: colHeaders[3], s: hs },
      phone: { v: colHeaders[4], s: hs },
      totalAmount: { v: colHeaders[5], s: hs },
      shipAmount: { v: colHeaders[6], s: hs },
      finalTotal: { v: colHeaders[7], s: hs },
      remainTotal: { v: colHeaders[8], s: hs },
      status: { v: colHeaders[9], s: hs },
      shipper: { v: colHeaders[10], s: hs },
      isShipProvince: { v: colHeaders[11], s: hs },
      note: { v: colHeaders[12], s: hs },
    },
  ];

  // let r = dataHeaders.length;
  const data = shiplines.map(
    (
      {
        customer,
        address,
        defaultAddress,
        // phone,
        total,
        shippingAmount,
        finalTotal,
        remainTotal,
        status,
        shipper,
        isShipProvince,
        note,
      },
      index,
    ) => {
      //   r += 1;
      return {
        STT: { t: 'n', v: index + 1, z: '#,##0', s },
        customerCode: { v: customer.code || '', s },
        customerName: { v: customer.name || '', s },
        receiver: { v: address ? `${address.name}-${address.phone}-${defaultAddress}` : '', s },
        phone: { v: customer.phone || '', s },
        totalAmount: { t: 'n', v: total || '', z: '#,##0', s },
        shipAmount: { t: 'n', v: shippingAmount || '', z: '#,##0', s },
        finalTotal: { t: 'n', v: finalTotal || '', z: '#,##0', s },
        remainTotal: { t: 'n', v: remainTotal || '', z: '#,##0', s },
        status: { v: status || '', s },
        shipper: { v: shipper || '', s },
        isShipProvince: { v: isShipProvince || '', s },
        note: { v: note || '', s },
      };
    },
  );

  const ws = XLSX.utils.json_to_sheet(dataHeaders.concat(data), { skipHeader: true });
  const wscols = [
    { wch: 4 }, // stt
    { wch: 12 }, // customerCode
    { wch: 12 }, // customerName
    { wch: 45 }, // receiver
    { wch: 12 }, // phone
    { wch: 14 }, // totalAmount
    { wch: 14 }, // shipAmount
    { wch: 14 }, // finalTotal
    { wch: 14 }, // remainTotal
    { wch: 18 }, // status
    { wch: 20 }, // shipper
    { wch: 14 }, // isShipProvince
    { wch: 24 }, // note
  ];
  ws['!cols'] = wscols;
  ws['!rows'] = [{ hpx: 36 }, { hpx: 36 }];

  const merge = [
    { s: { r: 0, c: 0 }, e: { r: 0, c: 12 } },
    // { s: { r: 1, c: 0 }, e: { r: 1, c: 25 } },
  ];
  ws['!merges'] = merge;

  const wb = { Sheets: { 'Sheet 1': ws }, SheetNames: ['Sheet 1'] };
  const excelBuffer = XLSX.write(wb, {
    bookType: 'xlsx',
    cellStyles: true,
    skipHeader: true,
    type: 'array',
  });
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const blob = new Blob([excelBuffer], { type: fileType });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = `Shipment-${format(new Date(), 'ddMMyyyy')}.xlsx`;
  link.click();
};
