import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';
import { format } from 'date-fns';
import Handsontable from 'handsontable';
import { Statuses } from '../../constants/Enums';

import styles from './SellitemTable.module.scss';
import globalStyles from '../../styles.module.scss';

export const statusRenderer = (instance, td, row, col, prop, value, cellProperties) => {
  Handsontable.renderers.TextRenderer.apply(this, [
    instance,
    td,
    row,
    col,
    prop,
    value,
    cellProperties,
  ]);
  /* eslint-disable no-param-reassign */
  if (value) {
    const status = Object.values(Statuses).find(({ text }) => text === value) || {
      name: '-',
      color: 'red',
    };
    // td.innerText = status.text; // t(`status.${status.name}`);
    td.style.color = '#fff';
    td.classList.add(globalStyles[`background${upperFirst(camelCase(status.color))}`]);
  }
  /* eslint-enable no-param-reassign */
  return td;
};

export const linkRenderer = (instance, td, row, col, prop, value, cellProperties) => {
  const url = instance.getDataAtRowProp(row, 'product.url');
  Handsontable.renderers.HtmlRenderer.apply(this, [
    instance,
    td,
    row,
    col,
    prop,
    `${value} <a href="${url}" style="display:inline" title="Mở ${value}" target="_blank"><i class="cube small icon"></i></a>`,
    cellProperties,
  ]);
};

export const imageRenderer = (instance, td, row, col, prop, value) => {
  const img = document.createElement('IMG');
  const product = instance.getDataAtRowProp(row, 'product');
  // img.onerror = (e) => {
  //   e.target.src = '/img/default.png';
  // };
  let imageUrl = value;
  if (product && product.variation) {
    const color = instance.getDataAtRowProp(row, 'color');
    const size = instance.getDataAtRowProp(row, 'size');
    if (color && product.variation.colors) {
      const colors = product.variation.colors.find(({ name }) => name === color);
      if (colors) {
        // img.src = product.variation.colors.find(({ name }) => name === color)?.imageUrl;
        imageUrl = colors.sizes.find(({ name }) => name === size)?.imageUrl;
      }
    } else if (product.variation.sizes) {
      imageUrl = product.variation.sizes.find(({ name }) => name === size)?.imageUrl;
    }
    if (!imageUrl) {
      imageUrl = value || '/product.svg';
    }
  }
  img.src = imageUrl;

  Handsontable.dom.empty(td);
  td.classList.add(styles.image);
  td.appendChild(img);
};

export const quantityRenderer = (instance, td, row, col, prop, value, cellProperties) => {
  Handsontable.renderers.TextRenderer.apply(this, [
    instance,
    td,
    row,
    col,
    prop,
    value,
    cellProperties,
  ]);
  const r = Math.max(50, 255 - value * 12);
  if (value > 1) {
    /* eslint-disable no-param-reassign */
    td.style.backgroundColor = `rgba(${r}, ${r}, 50)`;
    if (value >= 8) td.style.color = '#fff';
    /* eslint-enable no-param-reassign */
  }
  return td;
};

export const weightAmountRenderer = (instance, td, row, col, prop, value, cellProperties) => {
  const isWithWeightAmount = instance.getDataAtRowProp(row, 'isWithWeightAmount');
  const weightAmount = instance.getDataAtRowProp(row, 'weightAmount');
  Handsontable.renderers.TextRenderer.apply(this, [
    instance,
    td,
    row,
    col,
    prop,
    value &&
      `${parseFloat(value, 10).toLocaleString('en-GB')} kg\n${parseInt(
        weightAmount,
        10,
      ).toLocaleString('en-GB')}`,
    cellProperties,
  ]);
  /* eslint-disable no-param-reassign */
  if (isWithWeightAmount) {
    td.style.backgroundColor = '#ddd';
    // eslint-disable-next-line prefer-destructuring
    td.innerText = cellProperties.source[0];
  }
  /* eslint-enable no-param-reassign */
  return td;
};

export const dateRenderer = (instance, td, row, col, prop, value, cellProperties) => {
  Handsontable.renderers.TextRenderer.apply(this, [
    instance,
    td,
    row,
    col,
    prop,
    value && value instanceof Date ? format(value, 'HH:mm d/M/yyyy') : '',
    cellProperties,
  ]);
  if (value) {
    /* eslint-disable no-param-reassign */
    // td.innerText = t('format:longDateTime', {
    //   postProcess: 'formatDate',
    //   value: new Date(value),
    // });
    td.style.color = '#777';
    /* eslint-enable no-param-reassign */
  }
  return td;
};

export const arrivalDateRenderer = (instance, td, row, col, prop, value, cellProperties) => {
  const status = instance.getDataAtRowProp(row, 'status');
  /* eslint-disable no-param-reassign */
  cellProperties.readOnly = status === Statuses.FINISH.name;
  Handsontable.renderers.TextRenderer.apply(this, [
    instance,
    td,
    row,
    col,
    prop,
    value,
    cellProperties,
  ]);
  if (!value) {
    const estimatedArrivalDate = instance.getDataAtRowProp(row, 'estimatedArrivalDate');
    if (estimatedArrivalDate && estimatedArrivalDate instanceof Date) {
      td.innerText = cellProperties.placeholder.replace(
        ':date',
        format(estimatedArrivalDate, 'd/M/yyyy'),
      );
    }
    td.style.color = '#ccc';
  }
  /* eslint-enable no-param-reassign */
  return td;
};

export const percentRenderer = (instance, td, row, col, prop, value, cellProperties) => {
  // const status = instance.getDataAtRowProp(row, 'status');
  // cellProperties.readOnly = readOnly || row === 0 || status === PaymentStatuses.FINISH.name;
  Handsontable.renderers.NumericRenderer.apply(this, [
    instance,
    td,
    row,
    col,
    prop,
    value !== null ? `${+value}%` : value,
    cellProperties,
  ]);
  return td;
};

// const commonRenderer = (instance, td, row, col, prop, value, cellProperties) => {
//   Handsontable.renderers.TextRenderer.apply(this, [
//     instance,
//     td,
//     row,
//     col,
//     prop,
//     value,
//     cellProperties,
//   ]);
//   if (cellProperties.type === 'numeric' && value > 0) {
//     // eslint-disable-next-line no-param-reassign
//     td.innerText = (+value).toLocaleString('en-GB');
//     // eslint-disable-next-line no-param-reassign
//     td.style.backgroundColor = '#fff';
//   }

//   // Lock FINISHED Sellitem
//   const status = cellProperties?.instance.getDataAtRowProp(row, 'status');
//   // eslint-disable-next-line no-param-reassign
//   cellProperties.readOnly = status === Statuses.FINISH.name;
//   return td;
// };
