import socket from './socket';
import http from './http';
import { transformAttachment } from './attachments';

/* Transformers */

export const transformSellitem = (sellitem) => ({
  ...sellitem,
  ...(sellitem.arrivalDate && {
    arrivalDate: new Date(sellitem.arrivalDate),
  }),
  ...(sellitem.timer && {
    timer: {
      ...sellitem.timer,
      ...(sellitem.timer.startedAt && {
        startedAt: new Date(sellitem.timer.startedAt),
      }),
    },
  }),
  createdAt: new Date(sellitem.createdAt),
});

export const transformSellitemData = (data) => ({
  ...data,
  ...(data.arrivalDate && {
    arrivalDate: data.arrivalDate.toISOString(),
  }),
  ...(data.timer && {
    timer: {
      ...data.timer,
      ...(data.timer.startedAt && {
        startedAt: data.timer.startedAt.toISOString(),
      }),
    },
  }),
});

/* Actions */

const getSellitems = (groupId, data, headers) =>
  socket.get(`/groups/${groupId}/sellitems`, data, headers).then((body) => ({
    ...body,
    items: body.items.map(transformSellitem),
    included: {
      ...body.included,
      attachments: body.included.attachments.map(transformAttachment),
    },
  }));

const createSellitem = (groupId, data, headers) =>
  socket
    .post(`/groups/${groupId}/sellitems`, transformSellitemData(data), headers)
    .then((body) => ({
      ...body,
      item: transformSellitem(body.item),
    }));

const getSellitem = (id, headers) =>
  socket.get(`/sellitems/${id}`, undefined, headers).then((body) => ({
    ...body,
    item: transformSellitem(body.item),
  }));

const updateSellitem = (id, data, headers) =>
  socket.patch(`/sellitems/${id}`, transformSellitemData(data), headers).then((body) => ({
    ...body,
    item: transformSellitem(body.item),
  }));

const updateSellitems = (id, data, headers) =>
  socket.patch(`/groups/${id}/sellitems`, data, headers).then((body) => ({
    ...body,
    items: body.items.map(transformSellitem),
  }));

const deleteSellitem = (id, headers) =>
  socket.delete(`/sellitems/${id}`, undefined, headers).then((body) => ({
    ...body,
    item: transformSellitem(body.item),
  }));

/* Event handlers */

const makeHandleSellitemCreate = (next) => (body) => {
  next({
    ...body,
    item: transformSellitem(body.item),
  });
};

const makeHandleSellitemUpdate = (next) => (body) => {
  next({
    ...body,
    item: transformSellitem(body.item),
  });
};

const makeHandleSellitemsUpdate = (next) => (body) => {
  next({
    ...body,
    items: body.items.map(transformSellitem),
  });
};

const makeHandleSellitemDelete = makeHandleSellitemUpdate;

const importSellitems = (groupId, data, headers) =>
  http.post(`/groups/${groupId}/import-sellitems`, data, headers);

export default {
  getSellitems,
  createSellitem,
  getSellitem,
  importSellitems,
  updateSellitem,
  updateSellitems,
  deleteSellitem,
  makeHandleSellitemCreate,
  makeHandleSellitemUpdate,
  makeHandleSellitemsUpdate,
  makeHandleSellitemDelete,
};
