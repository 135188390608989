import { Model, attr, fk, many, oneToOne } from 'redux-orm';

import ActionTypes from '../constants/ActionTypes';
import Config from '../constants/Config';

export default class extends Model {
  static modelName = 'Sellitem';

  static fields = {
    id: attr(),
    position: attr(),
    name: attr(),
    description: attr(),
    colorCode: attr(),
    sizeCode: attr(),
    color: attr(),
    size: attr(),
    unit: attr(),
    quantity: attr(),
    quantityCancel: attr(),
    webCode: attr(),
    webPrice: attr(),
    webExtra: attr(),
    exchangeRate: attr(),
    sellPrice: attr(),
    wholesalePrice: attr(),
    feeAmount: attr(),
    weight: attr(),
    weightAmount: attr(),
    isWithWeightAmount: attr(),
    webOrderNo: attr(),
    arrivalDate: attr(),
    status: attr(),
    timer: attr(),
    isSubscribed: attr({
      getDefault: () => false,
    }),
    isActionsFetching: attr({
      getDefault: () => false,
    }),
    isAllActionsFetched: attr({
      getDefault: () => false,
    }),
    groupId: fk({
      to: 'Group',
      as: 'group',
      relatedName: 'sellitems',
    }),
    creatorUserId: fk({
      to: 'User',
      as: 'creator',
      relatedName: 'sellitems',
    }),
    customerId: fk({
      to: 'Customer',
      as: 'customer',
      relatedName: 'sellitems',
    }),
    productId: fk({
      to: 'Product',
      as: 'product',
      relatedName: 'sellitems',
    }),
    coverAttachmentId: oneToOne({
      to: 'Attachment',
      as: 'coverAttachment',
      relatedName: 'coveredSellitem',
    }),
    // users: many('User', 'sellitems'),
    labels: many('Label', 'sellitems'),
  };

  static reducer({ type, payload }, Sellitem) {
    switch (type) {
      case ActionTypes.LOCATION_CHANGE_HANDLE:
      case ActionTypes.CORE_INITIALIZE:
      case ActionTypes.BUSINESS_MANAGER_CREATE_HANDLE:
      case ActionTypes.GROUP_MEMBERSHIP_CREATE_HANDLE:
      case ActionTypes.SELLITEMS_FETCH__SUCCESS:
        if (payload.sellitems) {
          if (type === ActionTypes.SELLITEMS_FETCH__SUCCESS) {
            Sellitem.all().delete();
          }
          payload.sellitems.forEach((sellitem) => {
            Sellitem.upsert(sellitem);
          });
        }

        if (payload.sellitemMemberships) {
          payload.sellitemMemberships.forEach(({ sellitemId, userId }) => {
            Sellitem.withId(sellitemId).users.add(userId);
          });
        }

        if (payload.sellitemLabels) {
          payload.sellitemLabels.forEach(({ sellitemId, labelId }) => {
            Sellitem.withId(sellitemId).labels.add(labelId);
          });
        }

        break;
      case ActionTypes.SOCKET_RECONNECT_HANDLE:
        Sellitem.all().delete();

        if (payload.sellitems) {
          payload.sellitems.forEach((sellitem) => {
            Sellitem.upsert(sellitem);
          });
        }

        if (payload.sellitemMemberships) {
          payload.sellitemMemberships.forEach(({ sellitemId, userId }) => {
            Sellitem.withId(sellitemId).users.add(userId);
          });
        }

        if (payload.sellitemLabels) {
          payload.sellitemLabels.forEach(({ sellitemId, labelId }) => {
            Sellitem.withId(sellitemId).labels.add(labelId);
          });
        }

        break;
      case ActionTypes.USER_TO_SELLITEM_ADD: {
        const sellitemModel = Sellitem.withId(payload.sellitemId);
        sellitemModel.users.add(payload.id);

        if (payload.isCurrent) {
          sellitemModel.isSubscribed = true;
        }

        break;
      }
      case ActionTypes.USER_TO_SELLITEM_ADD__SUCCESS:
      case ActionTypes.USER_TO_SELLITEM_ADD_HANDLE:
        try {
          Sellitem.withId(payload.sellitemMembership.sellitemId).users.add(
            payload.sellitemMembership.userId,
          );
        } catch {} // eslint-disable-line no-empty

        break;
      case ActionTypes.USER_FROM_SELLITEM_REMOVE:
        Sellitem.withId(payload.sellitemId).users.remove(payload.id);

        break;
      case ActionTypes.USER_FROM_SELLITEM_REMOVE__SUCCESS:
      case ActionTypes.USER_FROM_SELLITEM_REMOVE_HANDLE:
        try {
          Sellitem.withId(payload.sellitemMembership.sellitemId).users.remove(
            payload.sellitemMembership.userId,
          );
        } catch {} // eslint-disable-line no-empty

        break;
      case ActionTypes.CUSTOMER_FETCH__SUCCESS:
      case ActionTypes.GROUP_FETCH__SUCCESS:
        payload.sellitems.forEach((sellitem) => {
          Sellitem.upsert(sellitem);
        });

        payload.sellitemMemberships.forEach(({ sellitemId, userId }) => {
          Sellitem.withId(sellitemId).users.add(userId);
        });

        payload.sellitemLabels.forEach(({ sellitemId, labelId }) => {
          Sellitem.withId(sellitemId).labels.add(labelId);
        });

        break;
      case ActionTypes.LABEL_TO_SELLITEM_ADD:
        Sellitem.withId(payload.sellitemId).labels.add(payload.id);

        break;
      case ActionTypes.LABEL_TO_SELLITEM_ADD__SUCCESS:
      case ActionTypes.LABEL_TO_SELLITEM_ADD_HANDLE:
        try {
          Sellitem.withId(payload.sellitemLabel.sellitemId).labels.add(
            payload.sellitemLabel.labelId,
          );
        } catch {} // eslint-disable-line no-empty

        break;
      case ActionTypes.LABEL_FROM_SELLITEM_REMOVE:
        Sellitem.withId(payload.sellitemId).labels.remove(payload.id);

        break;
      case ActionTypes.LABEL_FROM_SELLITEM_REMOVE__SUCCESS:
      case ActionTypes.LABEL_FROM_SELLITEM_REMOVE_HANDLE:
        try {
          Sellitem.withId(payload.sellitemLabel.sellitemId).labels.remove(
            payload.sellitemLabel.labelId,
          );
        } catch {} // eslint-disable-line no-empty

        break;
      // case ActionTypes.SELLITEM_CREATE:
      case ActionTypes.SELLITEM_CREATE_HANDLE:
      case ActionTypes.SELLITEM_UPDATE__SUCCESS:
      case ActionTypes.SELLITEM_UPDATE_HANDLE:
        Sellitem.upsert(payload.sellitem);

        break;
      case ActionTypes.SELLITEM_CREATE__SUCCESS:
        // Sellitem.withId(payload.localId).delete();
        Sellitem.upsert(payload.sellitem);

        break;
      // case ActionTypes.SELLITEM_UPDATE:
      //   Sellitem.withId(payload.id).update(payload.data);

      //   break;
      case ActionTypes.SELLITEM_DELETE:
        Sellitem.withId(payload.id).delete();

        break;
      case ActionTypes.SELLITEM_DELETE__SUCCESS:
      case ActionTypes.SELLITEM_DELETE_HANDLE: {
        const sellitemModel = Sellitem.withId(payload.sellitem.id);

        if (sellitemModel) {
          sellitemModel.deleteWithRelated();
        }

        break;
      }
      case ActionTypes.ACTIONS_FETCH:
        Sellitem.withId(payload.sellitemId).update({
          isActionsFetching: true,
        });

        break;
      case ActionTypes.ACTIONS_FETCH__SUCCESS:
        Sellitem.withId(payload.sellitemId).update({
          isActionsFetching: false,
          isAllActionsFetched: payload.actions.length < Config.SELLITEMS_LIMIT,
        });

        break;
      case ActionTypes.CUSTOMERS_FETCH__SUCCESS:
      case ActionTypes.NOTIFICATION_CREATE_HANDLE:
        if (payload.sellitems) {
          payload.sellitems.forEach((sellitem) => {
            Sellitem.upsert(sellitem);
          });
        }

        break;
      case ActionTypes.SHIPMENT_FETCH__SUCCESS:
      case ActionTypes.SHIPMENTS_FETCH__SUCCESS:
        if (payload.sellitems) {
          Sellitem.all().delete();
          payload.sellitems.forEach((sellitem) => {
            Sellitem.upsert(sellitem);
          });
        }

        break;
      // case ActionTypes.SELLITEMS_UPDATE: {
      //   const values = { ...payload.data };
      //   delete values.ids;
      //   payload.data.ids.forEach((id) => {
      //     Sellitem.withId(id).update(values);
      //   });

      //   break;
      // }
      case ActionTypes.SELLITEMS_UPDATE_HANDLE:
      case ActionTypes.SELLITEMS_UPDATE__SUCCESS:
        if (payload.sellitems) {
          payload.sellitems.forEach((sellitem) => {
            Sellitem.upsert(sellitem);
          });
        }

        break;
      default:
    }
  }

  getCreatorQuerySet() {
    return this.user;
  }

  getProductQuerySet() {
    return this.product;
  }

  getOrderedPaymentsQuerySet() {
    return this.payments.orderBy('createdAt');
  }

  getOrderedAttachmentsQuerySet() {
    return this.attachments.orderBy('createdAt', false);
  }

  getOrderedInSellitemActionsQuerySet() {
    return this.actions.orderBy('createdAt', false);
  }

  getUnreadNotificationsQuerySet() {
    return this.notifications.filter({
      isRead: false,
    });
  }

  deleteRelated() {
    this.payments.delete();
    this.attachments.delete();
    this.actions.delete();
  }

  deleteWithRelated() {
    this.deleteRelated();
    this.delete();
  }
}
