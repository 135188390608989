import EntryActionTypes from '../../constants/EntryActionTypes';

export const createBrandInCurrentBusiness = (data) => ({
  type: EntryActionTypes.BRAND_IN_CURRENT_BUSINESS_CREATE,
  payload: {
    data,
  },
});

export const handleBrandCreate = (brand) => ({
  type: EntryActionTypes.BRAND_CREATE_HANDLE,
  payload: {
    brand,
  },
});

export const updateBrand = (id, data) => ({
  type: EntryActionTypes.BRAND_UPDATE,
  payload: {
    id,
    data,
  },
});

export const handleBrandUpdate = (brand) => ({
  type: EntryActionTypes.BRAND_UPDATE_HANDLE,
  payload: {
    brand,
  },
});

export const deleteBrand = (id) => ({
  type: EntryActionTypes.BRAND_DELETE,
  payload: {
    id,
  },
});

export const handleBrandDelete = (brand) => ({
  type: EntryActionTypes.BRAND_DELETE_HANDLE,
  payload: {
    brand,
  },
});

export const addBrandToFilterInCurrentGroup = (id) => ({
  type: EntryActionTypes.BRAND_TO_FILTER_IN_CURRENT_GROUP_ADD,
  payload: {
    id,
  },
});

export const removeBrandFromFilterInCurrentGroup = (id) => ({
  type: EntryActionTypes.BRAND_FROM_FILTER_IN_CURRENT_GROUP_REMOVE,
  payload: {
    id,
  },
});
