import { ORM } from 'redux-orm';

import {
  Action,
  Attachment,
  Group,
  GroupMembership,
  Sellitem,
  Label,
  Customer,
  Address,
  Product,
  Shipment,
  Shipline,
  Notification,
  Business,
  BusinessManager,
  Country,
  Brand,
  Category,
  Account,
  CustomerGroup,
  Payment,
  User,
  Stock,
} from './models';

const orm = new ORM({
  stateSelector: (state) => state.orm,
});

orm.register(
  User,
  Business,
  BusinessManager,
  Country,
  Brand,
  Category,
  Account,
  CustomerGroup,
  Group,
  GroupMembership,
  Label,
  Customer,
  Address,
  Product,
  Shipment,
  Shipline,
  Sellitem,
  Payment,
  Attachment,
  Action,
  Notification,
  Stock,
);

export default orm;
