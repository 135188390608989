// eslint-disable-next-line import/prefer-default-export
const replaceAccentedChar = (str) => {
  return str
    .toLowerCase()
    .replace(/[àáãạảăắằẳẵặâấầẩẫậ]/g, 'a')
    .replace(/[èéẹẻẽêềếểễệ]/g, 'e')
    .replace(/[đ]/g, 'd')
    .replace(/[ìíĩỉị]/g, 'i')
    .replace(/[òóõọỏôốồổỗộơớờởỡợ]/g, 'o')
    .replace(/[ùúũụủưứừửữự]/g, 'u')
    .replace(/[ỳỵỷỹý]/g, 'y');
};

function buildFullTextRegex(value) {
  const wildcard = '[\\s\\S]*';
  const flags = 'gimy';
  if (value.trim() === '') {
    return new RegExp(wildcard, flags);
  }

  const values = replaceAccentedChar(value.trim())
    .replace(/[\]/\\^$*+?.(){}|[-]/gim, '')
    .split(' ');
  const textGroup = `(${values.join('|')})`;

  const parts = [];
  const excludes = [];

  parts.push(wildcard);
  for (let i = 0, size = values.length; i < size; i += 1) {
    if (i > 0) {
      excludes.push(`\\${i}`);
      parts.push(`(?!${excludes.join('|')})`);
    }
    parts.push(textGroup);
    parts.push(wildcard);
  }

  return new RegExp(parts.join(''), flags);
}

function fullTextRegexFilter(value, search) {
  return buildFullTextRegex(search).test(replaceAccentedChar(value));
}

export { replaceAccentedChar, buildFullTextRegex, fullTextRegexFilter };
