import { LOCATION_CHANGE } from 'connected-react-router';

import ActionTypes from '../constants/ActionTypes';
import ModalTypes from '../constants/ModalTypes';

const initialState = {
  isInitializing: true,
  currentModal: null,
  appError: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case LOCATION_CHANGE:
    case ActionTypes.MODAL_CLOSE:
      return {
        ...state,
        currentModal: null,
      };
    case ActionTypes.CORE_INITIALIZE:
      return {
        ...state,
        isInitializing: false,
      };
    case ActionTypes.MODAL_OPEN:
      return {
        ...state,
        currentModal: payload.type,
      };
    case ActionTypes.USER_UPDATE_HANDLE:
      if (state.currentModal === ModalTypes.USERS && payload.isCurrent && !payload.user.isAdmin) {
        return {
          ...state,
          currentModal: null,
        };
      }

      return state;
    case ActionTypes.BUSINESS_MANAGER_DELETE:
    case ActionTypes.BUSINESS_MANAGER_DELETE_HANDLE:
      if (
        state.currentModal === ModalTypes.BUSINESS_SETTINGS &&
        payload.isCurrentUser &&
        payload.isCurrentBusiness
      ) {
        return {
          ...state,
          currentModal: null,
        };
      }

      return state;
    case ActionTypes.SELLITEM_CREATE__FAILURE:
      return {
        ...state,
        appError: type,
      };
    case ActionTypes.APP_ERROR_CLEAR:
      return {
        ...state,
        appError: null,
      };
    default:
      return state;
  }
};
