import { call, select } from 'redux-saga/effects';

import request from '../request';
import { locationSelector, pathsMatchSelector } from '../../../selectors';
import api from '../../../api';
import Paths from '../../../constants/Paths';
import mergeRecords from '../../../utils/merge-records';
import { defaultRange } from '../../../utils/date-range';

// eslint-disable-next-line import/prefer-default-export
export function* fetchGroupByCurrentPathRequest() {
  const pathsMatch = yield select(pathsMatchSelector);

  let group;
  let customer;
  let product;
  let sellitem;
  let shipment;
  let users;
  let businesses;
  let groupMemberships;
  let labels;
  let customers;
  let addresses;
  let products;
  let categories;
  let productCategories;
  let sellitems;
  let sellitems2;
  let sellitems3;
  let sellitemMemberships;
  let sellitemLabels;
  let shipments;
  let shiplines;
  let payments;
  let payments2;
  let attachments;

  if (pathsMatch) {
    let groupId;
    let currentItem;

    const { query } = yield select(locationSelector);
    const page = query?.page || 1;
    let filter = {};
    if (query.start && query.end) {
      const start = new Date(query.start);
      start.setHours(0, 0, 0);
      const end = new Date(query.end);
      end.setHours(23, 59, 59);
      filter = {
        filterArrivalDate: {
          start,
          end,
        },
        page,
      };
    }

    switch (pathsMatch.path) {
      case Paths.GROUPS:
      case Paths.WAREHOUSE:
      case Paths.DASHBOARD:
        groupId = pathsMatch.params.id;
        currentItem = { filterOrderDate: defaultRange() };
        break;
      case Paths.CUSTOMERS:
        ({
          item: customer,
          item: { groupId },
          included: { sellitems: sellitems2, addresses, payments: payments2 },
        } = yield call(request, api.getCustomer, pathsMatch.params.id));
        currentItem = { customerId: pathsMatch.params.id };
        break;
      case Paths.PRODUCTS:
        ({
          item: product,
          item: { groupId },
          included: { sellitems: sellitems3 },
        } = yield call(request, api.getProduct, pathsMatch.params.id));
        currentItem = { productId: pathsMatch.params.id };
        break;

      case Paths.SELLITEMS:
        ({
          item: sellitem,
          item: { groupId },
        } = yield call(request, api.getSellitem, pathsMatch.params.id));
        currentItem = { sellitemId: pathsMatch.params.id };
        break;
      case Paths.SHIPMENTS:
        ({
          item: shipment,
          item: { groupId },
        } = yield call(request, api.getShipment, pathsMatch.params.id));
        currentItem = { shipmentId: pathsMatch.params.id };
        break;
      case Paths.SHIPMENTPAGE: {
        // groupId = pathsMatch.params.id;
        ({
          items: shipments,
          included: { shiplines, sellitems: sellitems2, customers, products, payments: payments2 },
        } = yield call(request, api.getShipments, pathsMatch.params.id, filter));
        break;
      }

      default:
        break;
    }

    if (groupId) {
      ({
        item: group,
        included: {
          users,
          businesses,
          groupMemberships,
          labels,
          customers,
          addresses,
          products,
          categories,
          productCategories,
          sellitems,
          sellitemMemberships,
          sellitemLabels,
          payments,
          attachments,
          shipments,
        },
      } = yield call(request, api.getGroup, groupId, currentItem));
    }
  }

  return {
    group,
    customer,
    product,
    shipment,
    sellitem,
    users,
    groupMemberships,
    labels,
    customers,
    addresses,
    products,
    categories,
    productCategories,
    sellitems: mergeRecords(sellitems, sellitems2, sellitems3),
    sellitemMemberships,
    sellitemLabels,
    payments: mergeRecords(payments, payments2),
    attachments,
    shipments,
    shiplines,
    business: businesses[0],
  };
}
