import React from 'react';
import PropTypes from 'prop-types';
import { Tab } from 'semantic-ui-react';

import InformationEdit from './InformationEdit';
// import DeletePopup from '../../DeletePopup';

import styles from './GeneralPane.module.scss';

const GeneralPane = React.memo(({ defaultData, onUpdate }) => {
  return (
    <Tab.Pane attached={false} className={styles.wrapper}>
      <InformationEdit defaultData={defaultData} onUpdate={onUpdate} />
    </Tab.Pane>
  );
});

GeneralPane.propTypes = {
  defaultData: PropTypes.objectOf(PropTypes.any).isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default GeneralPane;
