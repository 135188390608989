import { call } from 'redux-saga/effects';

import { fetchGroupByCurrentPathRequest } from './group';
import request from '../request';
import api from '../../../api';
import mergeRecords from '../../../utils/merge-records';
// eslint-disable-next-line import/prefer-default-export
export function* fetchCoreRequest() {
  const { item: user } = yield call(request, api.getCurrentUser);
  const { items: users1 } = yield call(request, api.getUsers);

  const {
    items: businesses1,
    included: {
      businessManagers,
      countries,
      brands,
      accounts,
      customerGroups,
      groups,
      groupMemberships: groupMemberships1,
      categories: categories1,
    },
  } = yield call(request, api.getBusinesses);

  let group;
  let customer;
  let sellitem;
  let users2;
  let businesses2;
  let groupMemberships2;
  let labels;
  let customers;
  let addresses;
  let sellitems1;
  let sellitemMemberships;
  let sellitemLabels;
  let payments;
  let attachments;
  let products;
  let categories2;
  let productCategories;
  let shipments;
  let shiplines;
  let product;

  try {
    ({
      group,
      customer,
      sellitem,
      users: users2,
      businesses: businesses2,
      groupMemberships: groupMemberships2,
      labels,
      customers,
      addresses,
      sellitems: sellitems1,
      sellitemMemberships,
      sellitemLabels,
      payments,
      attachments,
      products,
      categories: categories2,
      productCategories,
      shipments,
      shiplines,
      product,
    } = yield call(fetchGroupByCurrentPathRequest));
  } catch {} // eslint-disable-line no-empty

  const body = yield call(request, api.getNotifications);

  let { items: notifications } = body;

  const {
    included: { users: users3, sellitems: sellitems2, actions },
  } = body;

  if (sellitem) {
    const notificationIds = notifications.flatMap((item) =>
      item.sellitemId === sellitem.id ? [item.id] : [],
    );

    if (notificationIds.length > 0) {
      yield call(request, api.updateNotifications, notificationIds, {
        isRead: true,
      });

      notifications = notifications.filter(
        (notification) => !notificationIds.includes(notification.id),
      );
    }
  }

  return {
    user,
    group,
    users: mergeRecords(users1, users2, users3),
    businesses: mergeRecords(businesses1, businesses2),
    businessManagers,
    countries,
    brands,
    accounts,
    customerGroups,
    groups,
    groupMemberships: mergeRecords(groupMemberships1, groupMemberships2),
    labels,
    customer,
    customers,
    addresses,
    sellitems: mergeRecords(sellitems1, sellitems2),
    sellitemMemberships,
    sellitemLabels,
    payments,
    attachments,
    actions,
    notifications,
    products,
    categories: mergeRecords(categories1, categories2),
    productCategories,
    shipments,
    shiplines,
    product,
  };
}
