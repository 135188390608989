import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Form } from 'semantic-ui-react';
import SearchCustomers from '../SearchCustomers';

import styles from './AddCustomer.module.scss';

const AddCustomer = React.memo(
  ({ onCreateShipmentItem, searchResults, onSearchCustomers, onFecthCustomers }) => {
    const [t] = useTranslation();

    const [customerSelected, setCustomerSelected] = useState([]);

    const [customerError, setCustomerError] = useState(false);

    const handleResultSelect = useCallback((customers) => {
      setCustomerSelected([customers]);
    }, []);

    const handleSubmit = useCallback(() => {
      let isError;

      if (customerSelected.length > 0 && !customerSelected[0].id) {
        setCustomerError(true);
        isError = true;
      }

      if (isError) return;

      onCreateShipmentItem(customerSelected[0]);
    }, [customerSelected, onCreateShipmentItem]);

    return (
      <Form className={styles.formAddSellitem}>
        <Form.Group className={styles.fieldGroup}>
          <Form.Field
            className={styles.fieldSearchCustomer}
            required
            error={customerError}
            onFocus={() => setCustomerError(false)}
          >
            <SearchCustomers
              customerSelected={customerSelected}
              searchResults={searchResults}
              onSearchCustomers={onSearchCustomers}
              onFecthCustomers={onFecthCustomers}
              onCustomerSelected={handleResultSelect}
            />
          </Form.Field>
          <Form.Field className={styles.formActions}>
            <Button positive content={t('action.add')} onClick={handleSubmit} />
          </Form.Field>
        </Form.Group>
      </Form>
    );
  },
);

AddCustomer.propTypes = {
  onCreateShipmentItem: PropTypes.func.isRequired,
  searchResults: PropTypes.objectOf(PropTypes.any).isRequired,
  onSearchCustomers: PropTypes.func.isRequired,
  onFecthCustomers: PropTypes.func.isRequired,
};

export default AddCustomer;
