/* eslint-disable import/prefer-default-export */
import ActionTypes from '../constants/ActionTypes';

export const sendMessageFacebook = (id, data) => ({
  type: ActionTypes.SEND_MESSAGE_FACEBOOK,
  payload: {
    id,
    data,
  },
});

sendMessageFacebook.success = (data) => ({
  type: ActionTypes.SEND_MESSAGE_FACEBOOK__SUCCESS,
  payload: {
    data,
  },
});

sendMessageFacebook.failure = (id, error) => ({
  type: ActionTypes.SEND_MESSAGE_FACEBOOK__FAILURE,
  payload: {
    id,
    error,
  },
});
