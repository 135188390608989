import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  isCurrentUserMemberForCurrentGroupSelector,
  productsForCurrentGroupSelector,
  // currentGroupSelector,
  pathSelector,
} from '../selectors';
import { searchProducts, openProductAddModal } from '../actions/entry';

import LeftSidebar from '../components/Products/LeftSidebar';

const mapStateToProps = (state) => {
  const { productId } = pathSelector(state);
  const products = productsForCurrentGroupSelector(state);
  const isCurrentUserMember = isCurrentUserMemberForCurrentGroupSelector(state);

  // const { isProductsFetching, isAllProductsFetched } = currentGroupSelector(state);
  return {
    product: { id: productId },
    products,
    canEdit: isCurrentUserMember,
    searchResults: { isSubmitting: false, products: [], query: '' },
    // isProductsFetching,
    // isAllProductsFetched,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onCreateProduct: openProductAddModal,
      onSearchProducts: (query) => searchProducts(query),
      // onProductsFetch: fetchProducts,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(LeftSidebar);
