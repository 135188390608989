import EntryActionTypes from '../../constants/EntryActionTypes';

export const createShipline = (data) => ({
  type: EntryActionTypes.SHIPLINE_CREATE,
  payload: {
    data,
  },
});

export const handleShiplineCreate = (shipline) => ({
  type: EntryActionTypes.SHIPLINE_CREATE_HANDLE,
  payload: {
    shipline,
  },
});

export const updateShipline = (id, data) => ({
  type: EntryActionTypes.SHIPLINE_UPDATE,
  payload: {
    id,
    data,
  },
});

export const updateCurrentShipline = (data) => ({
  type: EntryActionTypes.CURRENT_SHIPLINE_UPDATE,
  payload: {
    data,
  },
});

export const handleShiplineUpdate = (shipline) => ({
  type: EntryActionTypes.SHIPLINE_UPDATE_HANDLE,
  payload: {
    shipline,
  },
});

export const deleteShipline = (id) => ({
  type: EntryActionTypes.SHIPLINE_DELETE,
  payload: {
    id,
  },
});

export const deleteCurrentShipline = () => ({
  type: EntryActionTypes.CURRENT_SHIPLINE_DELETE,
  payload: {},
});

export const handleShiplineDelete = (shipline) => ({
  type: EntryActionTypes.SHIPLINE_DELETE_HANDLE,
  payload: {
    shipline,
  },
});
