import { Model, attr, many } from 'redux-orm';

import ActionTypes from '../constants/ActionTypes';
import { BusinessBackgroundTypes } from '../constants/Enums';

export default class extends Model {
  static modelName = 'Business';

  static fields = {
    id: attr(),
    name: attr(),
    slug: attr(),
    phone: attr(),
    hotline: attr(),
    email: attr(),
    page: attr(),
    facebook: attr(),
    address: attr(),
    since: attr(),
    month: attr(),
    customerPolicy: attr(),
    weightAmountPolicy: attr(),
    weightPolicy: attr(),
    background: attr(),
    backgroundImage: attr(),
    isBackgroundImageUpdating: attr({
      getDefault: () => false,
    }),
    managerUsers: many({
      to: 'User',
      through: 'BusinessManager',
      relatedName: 'businesses',
    }),
    countries: many({
      to: 'Country',
      as: 'country',
      relatedName: 'businesses',
    }),
    brands: many({
      to: 'Brand',
      as: 'brand',
      relatedName: 'businesses',
    }),
    categories: many({
      to: 'Category',
      as: 'category',
      relatedName: 'businesses',
    }),
    accounts: many({
      to: 'Account',
      as: 'account',
      relatedName: 'businesses',
    }),
    customerGroups: many({
      to: 'CustomerGroup',
      as: 'customerGroup',
      relatedName: 'businesses',
    }),
  };

  static reducer({ type, payload }, Business) {
    switch (type) {
      case ActionTypes.LOCATION_CHANGE_HANDLE:
        if (payload.businesses) {
          payload.businesses.forEach((business) => {
            Business.upsert(business);
          });
        }

        break;
      case ActionTypes.SOCKET_RECONNECT_HANDLE:
        Business.all().delete();

        payload.businesses.forEach((business) => {
          Business.upsert(business);
        });

        break;
      case ActionTypes.CORE_INITIALIZE:
      case ActionTypes.GROUP_FETCH__SUCCESS:
        payload.businesses.forEach((business) => {
          Business.upsert(business);
        });

        break;
      case ActionTypes.BUSINESS_CREATE__SUCCESS:
      case ActionTypes.BUSINESS_CREATE_HANDLE:
      case ActionTypes.BUSINESS_UPDATE__SUCCESS:
      case ActionTypes.BUSINESS_UPDATE_HANDLE:
        Business.upsert(payload.business);

        break;
      case ActionTypes.BUSINESS_UPDATE: {
        const business = Business.withId(payload.id);
        business.update(payload.data);

        if (
          payload.data.backgroundImage === null &&
          business.background &&
          business.background.type === BusinessBackgroundTypes.IMAGE
        ) {
          business.background = null;
        }

        break;
      }
      case ActionTypes.BUSINESS_BACKGROUND_IMAGE_UPDATE:
        Business.withId(payload.id).update({
          isBackgroundImageUpdating: true,
        });

        break;
      case ActionTypes.BUSINESS_BACKGROUND_IMAGE_UPDATE__SUCCESS:
        Business.withId(payload.business.id).update({
          ...payload.business,
          isBackgroundImageUpdating: false,
        });

        break;
      case ActionTypes.BUSINESS_BACKGROUND_IMAGE_UPDATE__FAILURE:
        Business.withId(payload.id).update({
          isBackgroundImageUpdating: false,
        });

        break;
      case ActionTypes.BUSINESS_DELETE:
        Business.withId(payload.id).deleteWithRelated();

        break;
      case ActionTypes.BUSINESS_DELETE__SUCCESS:
      case ActionTypes.BUSINESS_DELETE_HANDLE: {
        const businessModel = Business.withId(payload.business.id);

        if (businessModel) {
          businessModel.deleteWithRelated();
        }

        break;
      }
      case ActionTypes.BUSINESS_MANAGER_CREATE_HANDLE:
      case ActionTypes.GROUP_MEMBERSHIP_CREATE_HANDLE:
        if (payload.business) {
          const businessModel = Business.withId(payload.business.id);

          if (businessModel) {
            businessModel.deleteWithRelated();
          }

          Business.upsert(payload.business);
        }

        break;
      case ActionTypes.BUSINESS_MANAGER_CREATE_HANDLE__BUSINESS_FETCH:
      case ActionTypes.GROUP_MEMBERSHIP_CREATE_HANDLE__BUSINESS_FETCH: {
        const businessModel = Business.withId(payload.id);

        if (businessModel) {
          businessModel.groups.toModelArray().forEach((groupModel) => {
            if (groupModel.id !== payload.currentGroupId) {
              groupModel.update({
                isFetching: null,
              });

              groupModel.deleteRelated(payload.currentUserId);
            }
          });
        }

        break;
      }
      default:
    }
  }

  getOrderedManagersQuerySet() {
    return this.managers.orderBy('createdAt');
  }

  getOrderedCountriesQuerySet() {
    return this.countries.orderBy('createdAt');
  }

  getOrderedBrandsQuerySet() {
    return this.brands.orderBy('createdAt');
  }

  getOrderedCategoriesQuerySet() {
    return this.categories.orderBy('createdAt');
  }

  getOrderedAccountsQuerySet() {
    return this.accounts.orderBy('createdAt');
  }

  getOrderedCustomerGroupsQuerySet() {
    return this.customerGroups.orderBy('createdAt');
  }

  getOrderedGroupsQuerySet() {
    return this.groups.orderBy('position');
  }

  getOrderedAvailableGroupsModelArray(userId) {
    if (this.hasManagerUser(userId)) {
      return this.getOrderedGroupsQuerySet().toModelArray();
    }

    return this.getOrderedGroupsQuerySet()
      .toModelArray()
      .filter((groupModel) => groupModel.hasMemberUser(userId));
  }

  hasManagerUser(userId) {
    return this.managers
      .filter({
        userId,
      })
      .exists();
  }

  hasMemberUserForAnyGroup(userId) {
    return this.groups.toModelArray().some((groupModel) => groupModel.hasMemberUser(userId));
  }

  deleteRelated() {
    this.managers.delete();

    this.groups.toModelArray().forEach((groupModel) => {
      groupModel.deleteWithRelated();
    });
  }

  deleteWithRelated() {
    this.deleteRelated();
    this.delete();
  }
}
