import ActionTypes from '../constants/ActionTypes';

export const createShipline = (shipline) => ({
  type: ActionTypes.SHIPLINE_CREATE,
  payload: {
    shipline,
  },
});

createShipline.success = (localId, shipline, shipment) => ({
  type: ActionTypes.SHIPLINE_CREATE__SUCCESS,
  payload: {
    localId,
    shipline,
    shipment,
  },
});

createShipline.failure = (localId, error) => ({
  type: ActionTypes.SHIPLINE_CREATE__FAILURE,
  payload: {
    localId,
    error,
  },
});

export const handleShiplineCreate = (shipline) => ({
  type: ActionTypes.SHIPLINE_CREATE_HANDLE,
  payload: {
    shipline,
  },
});

export const updateShipline = (id, data) => ({
  type: ActionTypes.SHIPLINE_UPDATE,
  payload: {
    id,
    data,
  },
});

updateShipline.success = (shipline, shipment) => ({
  type: ActionTypes.SHIPLINE_UPDATE__SUCCESS,
  payload: {
    shipline,
    shipment,
  },
});

updateShipline.failure = (id, error) => ({
  type: ActionTypes.SHIPLINE_UPDATE__FAILURE,
  payload: {
    id,
    error,
  },
});

export const handleShiplineUpdate = (shipline) => ({
  type: ActionTypes.SHIPLINE_UPDATE_HANDLE,
  payload: {
    shipline,
  },
});

export const deleteShipline = (id) => ({
  type: ActionTypes.SHIPLINE_DELETE,
  payload: {
    id,
  },
});

deleteShipline.success = (shipline, shipment) => ({
  type: ActionTypes.SHIPLINE_DELETE__SUCCESS,
  payload: {
    shipline,
    shipment,
  },
});

deleteShipline.failure = (id, error) => ({
  type: ActionTypes.SHIPLINE_DELETE__FAILURE,
  payload: {
    id,
    error,
  },
});

export const handleShiplineDelete = (shipline, shipment) => ({
  type: ActionTypes.SHIPLINE_DELETE_HANDLE,
  payload: {
    shipline,
    shipment,
  },
});
