import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  filterLabelsForCurrentGroupSelector,
  filterUsersForCurrentGroupSelector,
  // filterStatusesForCurrentGroupSelector,
  // filterShipmentStatusesForCurrentGroupSelector,
  // filterShipProvinceForCurrentGroupSelector,
  filterBrandsForCurrentGroupSelector,
  filterCountriesForCurrentGroupSelector,
  isCurrentUserManagerForCurrentBusinessSelector,
  membershipsForCurrentGroupSelector,
  usersSelector,
  countriesForCurrentBusinessSelector,
  brandsForCurrentBusinessSelector,
  pathsMatchSelector,
  currentGroupSelector,
  locationSelector,
} from '../selectors';
import {
  addLabelToFilterInCurrentGroup,
  addBrandToFilterInCurrentGroup,
  addCountryToFilterInCurrentGroup,
  addUserToFilterInCurrentGroup,
  addStatusToFilterInCurrentGroup,
  addShipmentStatusToFilterInCurrentGroup,
  addShipProvinceToFilterInCurrentGroup,
  changeOrderDateToFilterInCurrentGroup,
  changeArrivalDateToFilterInCurrentGroup,
  changeWebOrderNoToFilterInCurrentGroup,
  changeArrivalDateForShipmentPageToFilterInCurrentGroup,
  createLabelInCurrentGroup,
  createMembershipInCurrentGroup,
  deleteGroupMembership,
  deleteLabel,
  removeLabelFromFilterInCurrentGroup,
  removeBrandFromFilterInCurrentGroup,
  removeCountryFromFilterInCurrentGroup,
  removeUserFromFilterInCurrentGroup,
  removeStatusFromFilterInCurrentGroup,
  removeShipmentStatusFromFilterInCurrentGroup,
  removeShipProvinceFromFilterInCurrentGroup,
  updateLabel,
} from '../actions/entry';
import GroupActions from '../components/GroupActions';

const mapStateToProps = (state) => {
  const { path } = pathsMatchSelector(state);
  const allUsers = usersSelector(state).filter(({ status }) => status === 'active');
  const isCurrentUserManager = isCurrentUserManagerForCurrentBusinessSelector(state);
  const memberships = membershipsForCurrentGroupSelector(state).filter(
    ({ user: { email } }) => email !== 'kanri@tipici.vn',
  );
  const countries = countriesForCurrentBusinessSelector(state);
  const brands = brandsForCurrentBusinessSelector(state);
  const filterUsers = filterUsersForCurrentGroupSelector(state);
  const filterLabels = filterLabelsForCurrentGroupSelector(state);
  // const filterStatuses = filterStatusesForCurrentGroupSelector(state);
  const filterBrands = filterBrandsForCurrentGroupSelector(state);
  const filterCountries = filterCountriesForCurrentGroupSelector(state);
  // const filterShipmentStatuses = filterShipmentStatusesForCurrentGroupSelector(state);
  // const filterShipProvinces = filterShipProvinceForCurrentGroupSelector(state);
  const {
    filterStatuses,
    filterShipmentStatuses,
    filterShipProvinces,
    filterOrderDate,
    filterArrivalDate,
    filterWebOrderNo,
    filterArrivalDateForShipmentPage,
  } = currentGroupSelector(state);

  const { query } = locationSelector(state);
  // const filterArrivalDate2 = {
  //   start: new Date(query?.start) || filterArrivalDate2.start,
  //   end: new Date(query?.end) || filterArrivalDateForShipmentPage.end,
  // };
  let filterArrivalDate2 = filterArrivalDateForShipmentPage;
  if (query.start && query.end) {
    filterArrivalDate2 = {
      start: new Date(query.start),
      end: new Date(query.end),
    };
  }

  return {
    path,
    memberships,
    brands,
    countries,
    filterUsers,
    filterLabels,
    filterStatuses,
    filterShipmentStatuses,
    filterShipProvinces,
    filterBrands,
    filterCountries,
    filterOrderDate,
    filterArrivalDate,
    filterWebOrderNo,
    filterArrivalDateForShipmentPage: filterArrivalDate2,
    allUsers,
    canEditMemberships: isCurrentUserManager,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onMembershipCreate: createMembershipInCurrentGroup,
      onMembershipDelete: deleteGroupMembership,
      onUserToFilterAdd: addUserToFilterInCurrentGroup,
      onUserFromFilterRemove: removeUserFromFilterInCurrentGroup,
      onLabelToFilterAdd: addLabelToFilterInCurrentGroup,
      onLabelFromFilterRemove: removeLabelFromFilterInCurrentGroup,
      onLabelCreate: createLabelInCurrentGroup,
      onLabelUpdate: updateLabel,
      onLabelDelete: deleteLabel,
      onOrderDateToFilterChange: changeOrderDateToFilterInCurrentGroup,
      onArrivalDateToFilterChange: changeArrivalDateToFilterInCurrentGroup,
      onFilterWebOrderNoChange: changeWebOrderNoToFilterInCurrentGroup,
      onArrivalDateForShipmentPageToFilterChange:
        changeArrivalDateForShipmentPageToFilterInCurrentGroup,
      onStatusToFilterAdd: addStatusToFilterInCurrentGroup,
      onStatusFromFilterRemove: removeStatusFromFilterInCurrentGroup,
      onShipmentStatusToFilterAdd: addShipmentStatusToFilterInCurrentGroup,
      onShipmentStatusFromFilterRemove: removeShipmentStatusFromFilterInCurrentGroup,
      onBrandToFilterAdd: addBrandToFilterInCurrentGroup,
      onBrandFromFilterRemove: removeBrandFromFilterInCurrentGroup,
      onCountryToFilterAdd: addCountryToFilterInCurrentGroup,
      onCountryFromFilterRemove: removeCountryFromFilterInCurrentGroup,
      onShipProvinceToFilterAdd: addShipProvinceToFilterInCurrentGroup,
      onShipProvinceFromFilterRemove: removeShipProvinceFromFilterInCurrentGroup,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(GroupActions);
