import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';
import React, { useMemo, useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, List, Segment, Visibility, Loader } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { HotTable, HotColumn } from '@handsontable/react';
import Handsontable from 'handsontable';
import ReactToPrint from 'react-to-print';
import MenusPopup from '../Customers/MenusPopup';
import { exportXlsx } from './export';
import RightSidebar from './RightSidebar';
import ShipmentItemContainer from '../../containers/ShipmentItemContainer';
import ShiplineDetailContainer from '../../containers/ShiplineDetailContainer';
import AddPopup from './AddPopup';
import ActionsPopup from './ActionsPopup';
import AddCustomer from './AddCustomer';
import AddressAddEditor from '../HotTable/AddressAddEditor';
import DatePickerEditor from '../HotTable/DatePickerEditor';
import PaymentAddEditor from '../HotTable/PaymentAddEditor';
import SelectEditor from '../HotTable/SelectEditor';
import SelectSellitemsEditor from '../HotTable/SelectSellitemsEditor';
import NumberEditor from '../HotTable/NumberEditor';
import { ReactComponent as DotIcon } from '../../assets/images/dots-vertical.svg';

import { Statuses, StatusesShipment } from '../../constants/Enums';
import SearchShipments from '../SearchShipments';
import ShipmentPrint from './ShipmentPrint';

import ModalTypes from '../../constants/ModalTypes';
import Summary from '../SellitemTable/Summary';
import { getFilterShipments, setFilterShipments } from '../../utils/filter-storage';

import styles from './Shipments.module.scss';
import globalStyles from '../../styles.module.scss';

const undoQ = [];
const redoQ = [];

const Shipments = React.memo(
  ({
    accounts,
    addresses,
    shipmentIds,
    shipment,
    shiplines,
    sellitems,
    currentModal,
    isShipmentsFetching,
    isAllShipmentsFetched,
    canEdit,
    onShipmentCreate,
    searchShipmentResults,
    searchResults,
    onSearchShipments,
    onSearchCustomers,
    onFecthCustomers,
    onUpdate,
    onCreateShipline,
    onDeleteShipline,
    onUpdateShipline,
    onUpdateSellitem,
    onOpenShiplineDetailModal,
    onCreateAddress,
    onUpdateAddress,
    onPaymentCreate,
    onShipmentsFetch,
  }) => {
    const [t] = useTranslation();

    const colHeaders = useMemo(
      () => [
        'id',
        'customerId',
        // t('common.actions'),
        t('common.customer'),
        t('common.receiver'),
        t('common.phone'),
        t('common.totalAmount'),
        t('common.shipAmount'),
        t('common.finalTotal'),
        t('common.remainTotal'),
        t('common.status'),
        t('common.estimatedDeliveryDate', { context: 'header' }),
        t('common.deliveredDate'),
        t('common.shipper'),
        t('common.isShipProvince'),
        t('common.note'),
      ],
      [t],
    );
    const handleExport = useCallback(() => {
      const excelHeaders = [
        'STT',
        t('common.code'),
        t('common.customer'),
        t('common.receiver'),
        t('common.phone'),
        t('common.totalAmount'),
        t('common.shipAmount'),
        t('common.finalTotal'),
        t('common.remainTotal'),
        t('common.status'),
        t('common.shipper'),
        t('common.isShipProvince'),
        t('common.note'),
      ];
      exportXlsx(
        shiplines,
        excelHeaders,
        // currentBag || { name: 'TiciLand', startDate: new Date() },
      );
    }, [shiplines, t]);

    const hotTableRef = useRef();
    const summaryRef = useRef();
    const printTableRef = useRef();

    const [currentShiplineId, setCurrentShiplineId] = useState(0);
    const [typing, setTyping] = useState();
    const [isSearching, setIsSearching] = useState(false);

    const shipProvinceOptions = [
      { value: true, label: t('common.yesShipProvince') },
      { value: false, label: t('common.noShipProvince') },
    ];

    const handleCreateShipmentItem = useCallback(
      (customer) => {
        const sellitemIds = [];
        let total = 0;
        let error = false;
        sellitems
          .filter((sellitem) => sellitem.customerId === customer.id)
          .forEach((si) => {
            sellitemIds.push(si.id);
            total += (si.quantity - si.quantityCancel) * si.sellPrice;
          });
        if (sellitemIds.length === 0) {
          alert(t('common.customerHaveNotSellitemArrival'));
          error = true;
        }
        const data = {
          customerId: customer.id,
          sellitemIds,
          status: 'notShip',
          isShipProvince: false,
          shippingAmount: 0,
          note: shipment.note,
          estimatedDeliveryDate: shipment.estimatedDeliveryDate,
          total,
        };
        if (customer.addressId) data.addressId = customer.addressId;
        if (shiplines.findIndex((sh) => sh.customerId === customer.id) !== -1) {
          alert(t('common.customerExitsInShipment'));
          error = true;
        }
        if (error) return;
        onCreateShipline(data);
      },
      [onCreateShipline, t, shiplines, sellitems, shipment],
    );

    const handleSearchShipments = useCallback(
      (e, data) => {
        if (typing) {
          clearTimeout(typing.timeout);
        }
        if (!data || data.value.length < data.minCharacters) {
          setIsSearching(false);
        }
        setTyping({
          timeout: setTimeout(() => {
            if (data && data.value.length >= data.minCharacters) {
              onSearchShipments(data.value);
              setIsSearching(true);
            }
          }, 200),
        });
      },
      [typing, onSearchShipments],
    );

    const afterGetColHeader = (col, TH) => {
      if (col === 3) {
        const button = TH.querySelector('.changeType');

        if (!button) {
          return;
        }

        button.parentElement.removeChild(button);
      }
    };

    const afterChange = (changes, source) => {
      const sourceChange = ['edit', 'CopyPaste.paste', 'Autofill.fill', 'undo', 'redo'];
      if (sourceChange.indexOf(source) > -1 && changes.length > 0) {
        changes.forEach(([row, prop, oldValue, newValue]) => {
          if ((oldValue === null || oldValue === undefined) && newValue === '') return;

          // eslint-disable-next-line eqeqeq
          if (oldValue != newValue) {
            let newData;
            let newDate;
            const { id, addressId, remainTotal, sellitemIds, status } = shiplines[row];

            switch (prop) {
              case 'estimatedDeliveryDate':
              case 'deliveredDate':
                if (newValue && newValue.includes('/')) {
                  const [d, M, yyyy] = newValue.split('/');
                  newDate = new Date(yyyy, M - 1, d);
                } else {
                  newDate = null;
                }
                newData = {
                  [prop]: newDate,
                };
                break;
              case 'address.phone':
                newData = {
                  phone: newValue || null,
                };
                break;
              case 'status':
                newData = {
                  [prop]: Object.values(StatusesShipment).find(({ text }) => text === newValue)
                    .name,
                };
                if (newData.status === StatusesShipment.SHIPPED.name) {
                  sellitemIds.forEach((sellitemId) => {
                    onUpdateSellitem(sellitemId, {
                      status: remainTotal <= 0 ? Statuses.FINISH.name : Statuses.DELIVERIED.name,
                    });
                  });
                }

                break;
              case 'remainTotal':
                if (remainTotal <= newValue && status === StatusesShipment.SHIPPED.text) {
                  sellitemIds.forEach((sellitemId) => {
                    onUpdateSellitem(sellitemId, { status: Statuses.FINISH.name });
                  });
                }
                return;
              case 'total':
                return;
              default:
                newData = {
                  [prop]: newValue || null,
                };
                break;
            }
            if (source === 'undo') {
              if (redoQ.length > 9) redoQ.shift();
              redoQ.push([id, prop, oldValue]);
            } else {
              if (source !== 'redo') redoQ.length = 0;
              if (undoQ.length > 9) undoQ.shift();
              undoQ.push([id, prop, oldValue]);
            }
            if (prop === 'address.phone') {
              if (addressId) onUpdateAddress(addressId, newData);
            } else {
              onUpdateShipline(id, newData);
            }
          }
        });
      }
    };

    const afterSelection = useCallback(() => {
      const selected = hotTableRef.current.hotInstance.getSelected();
      if (
        !(
          selected.length === 1 &&
          selected[0][0] === selected[0][2] &&
          selected[0][1] === selected[0][3]
        )
      ) {
        const data = [];
        selected.forEach((it) => {
          data.push(hotTableRef.current.hotInstance.getData(...it).flat());
        });
        summaryRef.current?.show(data);
      } else {
        summaryRef.current?.show([]);
      }
    }, []);

    const afterDeselect = () => {
      summaryRef.current?.show([]);
    };

    const afterFilter = (formulaStack) => {
      setFilterShipments(formulaStack);
    };

    const handleRedo = useCallback(() => {
      const hotInstance = redoQ.pop();
      if (hotInstance && hotInstance.length > 0) {
        hotInstance[0] = shiplines.findIndex((item) => item.id === hotInstance[0]);
        hotTableRef.current.hotInstance.selectCell(hotInstance[0], hotInstance[1]);
        hotTableRef.current.hotInstance.setDataAtRowProp(...hotInstance, 'redo');
      }
    }, [shiplines]);

    const handleUndo = useCallback(() => {
      const hotInstance = undoQ.pop();
      if (hotInstance && hotInstance.length > 0) {
        hotInstance[0] = shiplines.findIndex((item) => item.id === hotInstance[0]);
        hotTableRef.current.hotInstance.selectCell(hotInstance[0], hotInstance[1]);
        hotTableRef.current.hotInstance.setDataAtRowProp(...hotInstance, 'undo');
      }
    }, [shiplines]);

    const beforeKeyDown = (e) => {
      if (e.ctrlKey || e.metaKey) {
        if (e.keyCode === 90) {
          if (e.shiftKey) {
            handleRedo();
          } else {
            handleUndo();
          }
        }
      }
    };

    const beforeRemoveRow = useCallback(
      (row, count, physicalRows) => {
        physicalRows.forEach((r) => {
          const shiplineId = hotTableRef.current.hotInstance.getDataAtRowProp(r, 'id');
          const { id, status } = shiplines.find(({ id: curId }) => curId === shiplineId);
          if (canEdit && status === StatusesShipment.NOTSHIP.text) {
            onDeleteShipline(id);
          }
        });
        return false;
      },
      [canEdit, onDeleteShipline, shiplines],
    );

    const shipmentResultRenderer = () => {
      if (searchShipmentResults.shipments.length > 0)
        return searchShipmentResults.shipments.map((ship, index) => (
          <ShipmentItemContainer key={ship.id} id={ship.id} index={index} />
        ));
      return t('common.noResultsMessage');
    };

    // const addressRenderer = (instance, td, row, col, prop, value, cellProperties) => {
    //   const address = shiplines[row]?.address || null;
    //   let mValue = value;
    //   if (address) {
    //     const defaultAddress = address.address
    //       ? `${address.address}, ${address.ward}, ${address.district}, ${address.province}`
    //       : '';
    //     mValue = `${address.name} - ${address.phone}\n<i class="map marker alternate tiny icon"></i><small>${defaultAddress}</small>`;
    //   }
    //   Handsontable.renderers.HtmlRenderer.apply(this, [
    //     instance,
    //     td,
    //     row,
    //     col,
    //     prop,
    //     mValue,
    //     cellProperties,
    //   ]);
    // };

    // const customerRenderer = (instance, td, row, col, prop, value, cellProperties) => {
    //   Handsontable.renderers.HtmlRenderer.apply(this, [
    //     instance,
    //     td,
    //     row,
    //     col,
    //     prop,
    //     `${value.name}\n[${value.code}] <i class="phone mini icon"></i> ${value.phone}`,
    //     cellProperties,
    //   ]);
    // };

    const statusRenderer = (instance, td, row, col, prop, value, cellProperties) => {
      Handsontable.renderers.TextRenderer.apply(this, [
        instance,
        td,
        row,
        col,
        prop,
        value,
        cellProperties,
      ]);
      /* eslint-disable no-param-reassign */
      if (value) {
        const status = Object.values(StatusesShipment).find(({ text }) => text === value) || {
          name: '-',
          color: 'red',
        };
        // td.innerText = t(`statusShipment.${status.name}`);
        td.style.color = '#fff';
        td.classList.add(globalStyles[`background${upperFirst(camelCase(status.color))}`]);
      }
      /* eslint-enable no-param-reassign */
      return td;
    };

    const shipProvinceRenderer = (instance, td, row, col, prop, value, cellProperties) => {
      Handsontable.renderers.TextRenderer.apply(this, [
        instance,
        td,
        row,
        col,
        prop,
        value,
        cellProperties,
      ]);
      /* eslint-disable no-param-reassign */
      if (value === 'Ship tỉnh') {
        td.style.backgroundColor = '#afb0a4';
      } else {
        td.style.backgroundColor = '#ddd';
      }
      /* eslint-enable no-param-reassign */
    };

    const readOnlyRenderer = (instance, td, row, col, prop, value, cellProperties) => {
      const status = instance.getDataAtRowProp(row, 'status');
      // eslint-disable-next-line no-param-reassign
      cellProperties.readOnly = status !== StatusesShipment.NOTSHIP.text;
      Handsontable.renderers.NumericRenderer.apply(this, [
        instance,
        td,
        row,
        col,
        prop,
        value,
        cellProperties,
      ]);
      const id = shiplines[row]?.id || 0;
      const button = document.createElement('button');
      button.append(t('action.detail'));
      button.onclick = () => {
        setCurrentShiplineId(id);
        onOpenShiplineDetailModal();
      };

      // Handsontable.dom.empty(td);
      button.classList.add(styles.buttonDetail);
      td.appendChild(button);
      return td;
    };

    useEffect(() => {
      const c = getFilterShipments();
      if (c && hotTableRef.current) {
        // eslint-disable-next-line react/no-this-in-sfc
        const filtersPlugin = hotTableRef.current.hotInstance.getPlugin('Filters');
        // filtersPlugin.addCondition(1, 'by_value', [['ing', 'ed', 'as', 'on']]);
        if (filtersPlugin) {
          // console.log(filtersPlugin.conditionCollection.filteringStates.indexedValues);
          filtersPlugin.clearConditions();
          c.forEach((it) => {
            if (it) {
              it.conditions.forEach((con) => {
                filtersPlugin.addCondition(it.column, con.name, con.args, it.operation);
              });
            }
          });
          filtersPlugin.filter();
        }
      }
    }, [sellitems]);

    return (
      <div className={styles.wrapperGrid}>
        <Grid stackable columns={3}>
          <Grid.Row stretched>
            <Grid.Column
              className={styles.fixcolumn}
              mobile={16}
              tablet={5}
              computer={4}
              largeScreen={3}
              widescreen={3}
            >
              {/* <SidebarLeft /> */}
              <Segment className={styles.sidebarLeft}>
                <div className={styles.wrapperSearchShipments}>
                  <SearchShipments
                    searchResults={searchShipmentResults}
                    onSearchShipments={handleSearchShipments}
                  />
                  {canEdit && (
                    <>
                      <AddPopup onCreate={onShipmentCreate}>
                        <Button
                          circular
                          size="mini"
                          icon="shipping fast"
                          className={styles.addShipmentButton}
                        />
                      </AddPopup>
                      <ActionsPopup onImportUser={() => {}}>
                        <div className={styles.actions}>
                          <DotIcon className={styles.dotIcon} />
                        </div>
                      </ActionsPopup>
                    </>
                  )}
                </div>
                <List divided selection className={styles.wrapperShipments}>
                  {!isSearching && shipmentIds && shipmentIds.length > 0
                    ? shipmentIds.map((shipmentId, index) => (
                        <ShipmentItemContainer key={shipmentId} id={shipmentId} index={index} />
                      ))
                    : shipmentResultRenderer()}
                  {isShipmentsFetching ? (
                    <Loader
                      active
                      inverted
                      inline="centered"
                      size="small"
                      className={styles.loader}
                    />
                  ) : (
                    !isAllShipmentsFetched &&
                    !isSearching && <Visibility fireOnMount onOnScreen={onShipmentsFetch} />
                  )}
                </List>
              </Segment>
              <Segment className={styles.notRightSidebar}>
                <RightSidebar
                  onUpdate={onUpdate}
                  shipment={shipment}
                  shiplines={shiplines}
                  canEdit={canEdit}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={styles.fixcolumn}
              mobile={16}
              tablet={11}
              computer={12}
              largeScreen={10}
              widescreen={10}
            >
              <Segment className={styles.wrapper}>
                {canEdit && shipment && (
                  <div className={styles.topTableShipment}>
                    <div className={styles.searchCustomersWrapper}>
                      <AddCustomer
                        onCreateShipmentItem={handleCreateShipmentItem}
                        searchResults={searchResults}
                        onSearchCustomers={onSearchCustomers}
                        onFecthCustomers={onFecthCustomers}
                      />
                    </div>
                    <ReactToPrint
                      trigger={() => (
                        <Button
                          className={styles.buttonPrintShipment}
                          size="mini"
                          icon="print"
                          content={t('action.printShipment')}
                        />
                      )}
                      content={() => printTableRef.current}
                    />
                  </div>
                )}
                <div className={styles.shipmentPrint}>
                  <ShipmentPrint ref={printTableRef} shiplines={shiplines} />
                </div>
                <div id="hot-app" className={styles.hotWrapper}>
                  <HotTable
                    ref={hotTableRef}
                    autoRowSize={false}
                    autoColumnSize={false}
                    manualColumnResize
                    data={shiplines}
                    dropdownMenu={['filter_by_value', 'filter_action_bar']}
                    filters
                    colHeaders={colHeaders}
                    hiddenColumns={{ columns: [0, 1] }}
                    stretchH="all"
                    height="100%"
                    rowHeaders={false}
                    rowHeights={40}
                    fixedColumnsLeft={3}
                    contextMenu={{
                      items: {
                        undo: {
                          name: `${t(
                            'action.undo',
                          )}<span style="color:gray;float:right;">⌘Z</span>`,
                          callback() {
                            handleUndo();
                          },
                          disabled: () => undoQ.length < 1,
                        },
                        redo: {
                          name: `${t(
                            'action.redo',
                          )}<span style="color:gray;float:right;">⌘⇧Z</span>`,
                          callback() {
                            handleRedo();
                          },
                          disabled: () => redoQ.length < 1,
                        },
                        separator: '---------',
                        copy: {
                          name: `${t(
                            'action.copy',
                          )}<span style="color:gray;float:right;">⌘C</span>`,
                        },
                        separator2: '---------',
                        remove_row: {
                          name: `${t('action.delete')}`,
                          disabled() {
                            return (
                              // eslint-disable-next-line react/no-this-in-sfc
                              this.getDataAtRowProp(this.getSelectedLast()[0], 'status') !==
                              StatusesShipment.NOTSHIP.text
                            );
                          },
                          hidden: () => !canEdit,
                        },
                      },
                    }}
                    afterChange={afterChange}
                    afterGetColHeader={afterGetColHeader}
                    afterSelection={afterSelection}
                    afterDeselect={afterDeselect}
                    afterFilter={afterFilter}
                    beforeKeyDown={beforeKeyDown}
                    beforeRemoveRow={beforeRemoveRow}
                    enterMoves={{ col: 1, row: 0 }}
                    licenseKey="non-commercial-and-evaluation"
                  >
                    <HotColumn width={0} data="id" />
                    <HotColumn width={0} data="customerId" />
                    {/* <HotColumn width={75} data="actions" readOnly /> */}
                    <HotColumn width={160} data="customerString" readOnly />
                    <HotColumn
                      width={300}
                      data="addressString"
                      // renderer={addressRenderer}
                      t={t}
                      addresses={addresses}
                      onCreate={onCreateAddress}
                      onUpdateShipline={onUpdateShipline}
                      editor={AddressAddEditor}
                    />
                    <HotColumn width={100} data="address.phone" />
                    <HotColumn
                      width={90}
                      data="total"
                      type="numeric"
                      numericFormat={{ pattern: '0,0' }}
                      shiplines={shiplines}
                      sellitems={sellitems}
                      onUpdateShipline={onUpdateShipline}
                      editor={SelectSellitemsEditor}
                      renderer={readOnlyRenderer}
                    />
                    <HotColumn
                      width={70}
                      data="shippingAmount"
                      type="numeric"
                      numericFormat={{ pattern: '0,0' }}
                      editor={NumberEditor}
                    />
                    <HotColumn
                      width={95}
                      data="finalTotal"
                      type="numeric"
                      numericFormat={{ pattern: '0,0' }}
                      readOnly
                    />
                    <HotColumn
                      width={90}
                      data="remainTotal"
                      type="numeric"
                      numericFormat={{ pattern: '0,0' }}
                      t={t}
                      isShipline
                      shiplines={shiplines}
                      sellitems={sellitems}
                      accounts={accounts}
                      onCreate={onPaymentCreate}
                      editor={PaymentAddEditor}
                    />
                    <HotColumn
                      width={100}
                      data="status"
                      renderer={statusRenderer}
                      editor={SelectEditor}
                      options={Object.values(StatusesShipment).map(({ text, hex }) => ({
                        label: text,
                        value: text,
                        color: hex,
                      }))}
                    />
                    <HotColumn width={80} data="estimatedDeliveryDate" editor={DatePickerEditor} />
                    <HotColumn width={80} data="deliveredDate" editor={DatePickerEditor} />
                    <HotColumn width={90} data="shipper" />
                    <HotColumn
                      width={95}
                      data="isShipProvince"
                      renderer={shipProvinceRenderer}
                      editor={SelectEditor}
                      options={shipProvinceOptions}
                    />
                    <HotColumn width={200} data="note" settings={[{ dropdownMenu: false }]} />
                  </HotTable>
                  <div>
                    <Summary ref={summaryRef} />

                    <Button.Group className={styles.actionButtons}>
                      <MenusPopup
                        closeIcon={false}
                        canExport={canEdit && shiplines.length > 0}
                        canImport={false}
                        onExport={handleExport}
                        onImport={() => console.log('onImport')}
                      >
                        <Button size="mini" icon="bars" />
                      </MenusPopup>
                      <Button
                        className={styles.buttonTableActionUndo}
                        size="mini"
                        content={t('action.undo')}
                        icon="undo"
                        labelPosition="left"
                        onClick={handleUndo}
                        disabled={undoQ.length < 1}
                      />
                      <Button
                        className={styles.buttonTableActionRedo}
                        size="mini"
                        content={t('action.redo')}
                        icon="redo"
                        labelPosition="right"
                        onClick={handleRedo}
                        disabled={redoQ.length < 1}
                      />
                    </Button.Group>
                  </div>
                </div>
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={styles.rightSidebar}
              mobile={16}
              tablet={5}
              computer={4}
              largeScreen={3}
              widescreen={3}
            >
              <Segment className={styles.rightSidebar}>
                <RightSidebar
                  onUpdate={onUpdate}
                  shipment={shipment}
                  shiplines={shiplines}
                  canEdit={canEdit}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {currentModal === ModalTypes.SHIPLINE_DETAIL && (
          <ShiplineDetailContainer id={currentShiplineId} />
        )}
      </div>
    );
  },
);

Shipments.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.any).isRequired,
  shipmentIds: PropTypes.arrayOf(PropTypes.any).isRequired,
  addresses: PropTypes.arrayOf(PropTypes.any).isRequired,
  shipment: PropTypes.objectOf(PropTypes.any),
  sellitems: PropTypes.arrayOf(PropTypes.any).isRequired,
  shiplines: PropTypes.arrayOf(PropTypes.any).isRequired,
  canEdit: PropTypes.bool.isRequired,
  currentModal: PropTypes.string,
  onUpdate: PropTypes.func.isRequired,
  isShipmentsFetching: PropTypes.bool.isRequired,
  isAllShipmentsFetched: PropTypes.bool.isRequired,
  onShipmentCreate: PropTypes.func.isRequired,
  searchShipmentResults: PropTypes.objectOf(PropTypes.any).isRequired,
  searchResults: PropTypes.objectOf(PropTypes.any).isRequired,
  onSearchShipments: PropTypes.func.isRequired,
  onSearchCustomers: PropTypes.func.isRequired,
  onFecthCustomers: PropTypes.func.isRequired,
  onCreateShipline: PropTypes.func.isRequired,
  onDeleteShipline: PropTypes.func.isRequired,
  onUpdateShipline: PropTypes.func.isRequired,
  onUpdateSellitem: PropTypes.func.isRequired,
  onOpenShiplineDetailModal: PropTypes.func.isRequired,
  onCreateAddress: PropTypes.func.isRequired,
  onPaymentCreate: PropTypes.func.isRequired,
  onUpdateAddress: PropTypes.func.isRequired,
  onShipmentsFetch: PropTypes.func.isRequired,
  // onDeleteShiplineSellitems: PropTypes.func.isRequired,
};

Shipments.defaultProps = {
  shipment: undefined,
  currentModal: undefined,
};

export default Shipments;
