import { all, takeEvery } from 'redux-saga/effects';

import {
  createBusinessService,
  deleteCurrentBusinessService,
  handleBusinessCreateService,
  handleBusinessDeleteService,
  handleBusinessUpdateService,
  updateCurrentBusinessBackgroundImageService,
  updateCurrentBusinessService,
} from '../services';
import EntryActionTypes from '../../../constants/EntryActionTypes';

export default function* businessWatchers() {
  yield all([
    takeEvery(EntryActionTypes.BUSINESS_CREATE, ({ payload: { data } }) =>
      createBusinessService(data),
    ),
    takeEvery(EntryActionTypes.BUSINESS_CREATE_HANDLE, ({ payload: { business } }) =>
      handleBusinessCreateService(business),
    ),
    takeEvery(EntryActionTypes.CURRENT_BUSINESS_UPDATE, ({ payload: { data } }) =>
      updateCurrentBusinessService(data),
    ),
    takeEvery(EntryActionTypes.BUSINESS_UPDATE_HANDLE, ({ payload: { business } }) =>
      handleBusinessUpdateService(business),
    ),
    takeEvery(EntryActionTypes.CURRENT_BUSINESS_BACKGROUND_IMAGE_UPDATE, ({ payload: { data } }) =>
      updateCurrentBusinessBackgroundImageService(data),
    ),
    takeEvery(EntryActionTypes.CURRENT_BUSINESS_DELETE, () => deleteCurrentBusinessService()),
    takeEvery(EntryActionTypes.BUSINESS_DELETE_HANDLE, ({ payload: { business } }) =>
      handleBusinessDeleteService(business),
    ),
  ]);
}
