/* eslint-disable no-unused-vars */
/* eslint-disable no-unreachable */
import React from 'react';
import ReactDOM from 'react-dom';

import { Button, Form, Input } from 'semantic-ui-react';

import Handsontable from 'handsontable';

import styles from './PaymentAddEditor.module.scss';
import { StatusesShipment } from '../../constants/Enums';

const initialState = {
  id: null,
  customerId: null,
  value: null,
  amount: 0,
  accountId: '',
  name: null,
  refNo: null,
  isCompleted: false,
  isSubmitting: false,
  error: { amount: false, accountId: false },
};

class PaymentAddEditor extends Handsontable.editors.TextEditor {
  init() {
    this.mainElementRef = React.createRef();
    this.amountRef = React.createRef();
    this.props = { accounts: [], isShipline: false, onCreate: () => {}, t: null };
    this.mystate = initialState;
    this.handleChange = this.handleChange.bind(this);
    // this.handleToggle = this.handleToggle.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  open() {
    super.open();
    this.setState({
      ...initialState,
      id: this.instance.getDataAtRowProp(this.row, 'id'),
      customerId: this.instance.getDataAtRowProp(this.row, 'customerId'),
    });
    this.render();
    if (
      this.TEXTAREA_PARENT.offsetTop + this.TEXTAREA_PARENT.clientHeight + 20 >
      this.TEXTAREA_PARENT.offsetParent.clientHeight
    ) {
      this.TEXTAREA_PARENT.style.top = `${
        this.TEXTAREA_PARENT.offsetTop - this.TEXTAREA_PARENT.clientHeight
      }px`;
    } else {
      this.TEXTAREA_PARENT.style.top = `${this.TEXTAREA_PARENT.offsetTop + this.TD.clientHeight}px`;
    }

    if (
      this.TEXTAREA_PARENT.offsetLeft + this.TEXTAREA_PARENT.clientWidth >
      this.TEXTAREA_PARENT.offsetParent.clientWidth
    ) {
      this.TEXTAREA_PARENT.style.left = `${
        this.TEXTAREA_PARENT.offsetLeft - this.TEXTAREA_PARENT.clientWidth + this.TD.clientWidth + 1
      }px`;
    }
    // this.setState({ id: this.instance.getDataAtRowProp(this.row, 'id') });
    // if (this.mainElementRef.current) this.mainElementRef.current.style.display = 'block';
    // this.amountRef.current.focus();
    // Handsontable.editors.TextEditor.prototype.open.apply(this, []);
  }

  close() {
    super.close();
    this.instance.listen();
    Handsontable.dom.empty(this.TEXTAREA_PARENT);
    Handsontable.dom.addClass(this.TEXTAREA_PARENT, 'handsontableInputHolder');
    this.TEXTAREA_PARENT.appendChild(this.TEXTAREA);
  }

  setState(state) {
    this.mystate = { ...this.mystate, ...state };
  }

  render() {
    const { t, accounts, isShipline, instance } = this.cellProperties;
    if (isShipline) this.mystate.amount = instance?.getValue()?.toString() || '0';

    const accountOptions = accounts
      .filter((a) => !a.isClose)
      .map((a) => ({
        key: a.id,
        value: a.id,
        text: `${a.name}${a.accountNumber ? ` - ${a.accountNumber}` : ''}`,
      }));

    const renderResult = (
      <div className={styles.wrapper}>
        <h5>{t('common.createPayment')}</h5>
        <Form onSubmit={this.handleSubmit}>
          <Form.Dropdown
            fluid
            required
            // search
            // selection
            error={this.mystate.error.accountId}
            name="accountId"
            defaultValue={this.mystate.accountId}
            options={accountOptions}
            className={styles.field}
            placeholder={`${t('common.account')} *`}
            // onFocus={() => setError({ ...error, accountId: false })}
            onChange={this.handleChange}
          />
          <Form.Field
            required
            error={this.mystate.error.amount}
            // onFocus={() => setError({ ...error, amount: false })}
          >
            <Input
              fluid
              ref={this.amountRef}
              name="amount"
              maxLength="11"
              value={this.mystate.amount ? (+this.mystate.amount).toLocaleString('en-GB') : ''}
              className={styles.fieldAmount}
              pattern="[0-9,]+"
              placeholder={`${t('common.amount')} *`}
              onChange={this.handleChange}
              disabled={isShipline}
            />
          </Form.Field>
          <Form.Field
            required
            // error={error.name}
            // onFocus={() => setError({ ...error, name: false })}
          >
            <Input
              fluid
              name="name"
              defaultValue={this.mystate.name}
              className={styles.field}
              placeholder={t('common.note')}
              onChange={this.handleChange}
            />
          </Form.Field>
          {/* <Form.Field
            required
            // error={error.refNo}
            // onFocus={() => setError({ ...error, refNo: false })}
          >
            <Input
              fluid
              name="refNo"
              // value={data.refNo}
              className={styles.field}
              placeholder={t('common.refNo')}
              onChange={this.handleChange}
            />
          </Form.Field> */}
          <Form.Group widths="equal">
            <Form.Checkbox
              name="isCompleted"
              // checked={this.mystate.isCompleted}
              defaultChecked={this.mystate.isCompleted}
              value="true"
              label={t('action.isCompleted')}
              onChange={this.handleChange}
            />
            <Button positive content={t('action.save')} />
          </Form.Group>
        </Form>
      </div>
    );

    Handsontable.dom.empty(this.TEXTAREA_PARENT);
    Handsontable.dom.addClass(this.TEXTAREA_PARENT, 'handsontableInputHolder');
    const div = document.createElement('div');
    ReactDOM.render(renderResult, div);

    this.TEXTAREA_PARENT.appendChild(div);
  }

  prepare(row, col, prop, td, originalValue, cellProperties) {
    super.prepare(row, col, prop, td, originalValue, cellProperties);

    // const tdPosition = td.getBoundingClientRect();
    // this.setState(() => ({ sellitemId: cellProperties.instance.getDataAtRowProp(row, 'id') }));

    // if (this.mainElementRef.current) {
    //   this.mainElementRef.current.style.left = `${tdPosition.left}px`;
    //   this.mainElementRef.current.style.top = `${tdPosition.top}px`;
    // }

    const { accounts, sellitems, onCreate, isShipline, shiplines, t } = cellProperties;
    this.props = { accounts, sellitems, onCreate, isShipline, shiplines, t };
    // this.render();
  }

  // handleToggle(e, { checked }) {
  //   this.setState({ isCompleted: checked });
  // }

  handleChange(event) {
    if (event.target.value !== undefined) {
      if (event.target.name === 'amount') {
        this.setState({
          [event.target.name]: event.target.value.replace(/[^0-9]/g, ''),
        });
        this.render();
        if (this.amountRef.current) this.amountRef.current.focus();
      } else {
        this.setState({
          [event.target.name]: event.target.value,
        });
      }

      return;
    }
    if (event.target.nodeName === 'DIV' || event.target.nodeName === 'SPAN') {
      const selectedText = event.target.innerText;
      const accountId = this.props.accounts.find(({ name }) => selectedText.indexOf(name) >= 0)?.id;
      this.setState({ accountId });
    }
    // this.render();
  }

  handleSubmit(event) {
    event.preventDefault();

    const error = { amount: !this.mystate.amount, accountId: !this.mystate.accountId };
    this.setState({ error });
    if (!this.mystate.amount || !this.mystate.accountId) {
      this.render();
      return;
    }

    const { id, customerId, amount, accountId, name, refNo, isCompleted } = this.mystate;

    if (this.props.isShipline) {
      const shipline = this.props.shiplines.find((sl) => sl.id === id);
      if (shipline) {
        shipline.sellitemIds.forEach((sellitemId) => {
          const { remainTotal } = this.props.sellitems.find(
            ({ id: currentId }) => currentId === sellitemId,
          ) || { remainTotal: 0 };
          if (remainTotal > 0) {
            this.props.onCreate(shipline.customerId, {
              customerId: shipline.customerId,
              amount: remainTotal,
              accountId,
              name: name || this.props.t('common.payments'),
              refNo,
              isCompleted,
              sellitemId,
              type: 'payment',
            });
          }
        });
        if (shipline.shippingAmount > 0) {
          this.props.onCreate(shipline.customerId, {
            customerId: shipline.customerId,
            amount: shipline.shippingAmount,
            accountId,
            name: `Phí ship`,
            refNo: shipline.id,
            isCompleted,
            // sellitemId: undefined,
            type: 'shipping',
          });
        }
        if (shipline.status === StatusesShipment.SHIPPED.text) {
          this.setValue(0);
        }
      }

      // this.props.onCreate(shipline.customerId, {
      //   customerId: shipline.customerId,
      //   amount: parseInt(amount.trim().replace(/,/, ''), 10),
      //   accountId,
      //   name: name || this.props.t('common.payments'),
      //   refNo,
      //   isCompleted,
      //   shiplineId: id,
      //   type: 'payment',
      //   sellitemIds: shipline.sellitemIds,
      // });
    } else {
      this.props.onCreate(customerId, {
        customerId,
        amount: parseInt(amount.trim().replace(/,/, ''), 10),
        accountId,
        name: name || this.props.t('common.payments'),
        refNo,
        isCompleted,
        sellitemId: id,
        type: 'payment',
      });
    }

    this.finishEditing(false, false, () => setTimeout(() => this.close()));
  }
}

export default PaymentAddEditor;
