import { createSelector } from 'redux-orm';

import orm from '../orm';

export const makeBrandByIdSelector = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ Brand }, id) => {
      const brandModel = Brand.withId(id);

      if (!brandModel) {
        return brandModel;
      }

      return brandModel.ref;
    },
  );

export const brandByIdSelector = makeBrandByIdSelector();
