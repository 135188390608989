import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  currentGroupSelector,
  countryByIdSelector,
  customerByIdSelector,
  accountsForCurrentBusinessSelector,
  pathSelector,
} from '../selectors';
import { fetchDashboard } from '../actions/entry';
import Dashboard from '../components/Dashboard';

const mapStateToProps = (state) => {
  const { dashboard, isFetching } = state.dashboard;
  const { businessId } = pathSelector(state);
  const currentGroup = currentGroupSelector(state);
  const accounts = accountsForCurrentBusinessSelector(state);

  if (dashboard && !isFetching) {
    const sum = dashboard.countriesByTimeRange.reduce((a, b) => a + b.quantity * 1, 0);
    dashboard.countriesByTimeRange = dashboard.countriesByTimeRange.map((ct) => {
      const country = countryByIdSelector(state, ct.country_id);
      return {
        ...ct,
        percent: Math.round((ct.quantity / sum) * 100),
        country,
      };
    });
    if (
      dashboard.topCustomersByTimeRange.customer &&
      dashboard.topCustomersByTimeRange.customer.id
    ) {
      const topCustomerByTimeRange = customerByIdSelector(
        state,
        dashboard.topCustomersByTimeRange.customer.id,
      );
      dashboard.topCustomersByTimeRange.customer = {
        ...dashboard.topCustomersByTimeRange.customer,
        ...topCustomerByTimeRange,
      };
    }

    if (dashboard.topCustomers.customer && dashboard.topCustomers.customer.id) {
      const topCustomer = customerByIdSelector(state, dashboard.topCustomers.customer.id);
      dashboard.topCustomers.customer = {
        ...dashboard.topCustomers.customer,
        ...topCustomer,
      };
    }
  }

  return {
    dashboard,
    isFetching,
    accounts,
    businessId,
    currentGroup,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onfetchDashboard: fetchDashboard,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
