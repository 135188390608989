import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { isCoreInitializingSelector, appErrorSelector } from '../selectors';
import { clearAppError } from '../actions/entry';
import CoreWrapper from '../components/CoreWrapper';

const mapStateToProps = (state) => {
  const isCoreInitializing = isCoreInitializingSelector(state);
  const appError = appErrorSelector(state);

  return {
    isInitializing: isCoreInitializing,
    isSocketDisconnected: state.socket.isDisconnected,
    appError,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearError: clearAppError,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(CoreWrapper);
