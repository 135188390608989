import EntryActionTypes from '../../constants/EntryActionTypes';

export const createShipment = (data) => ({
  type: EntryActionTypes.SHIPMENT_CREATE,
  payload: {
    data,
  },
});

export const handleShipmentCreate = (shipment) => ({
  type: EntryActionTypes.SHIPMENT_CREATE_HANDLE,
  payload: {
    shipment,
  },
});

export const updateShipment = (id, data) => ({
  type: EntryActionTypes.SHIPMENT_UPDATE,
  payload: {
    id,
    data,
  },
});

export const updateCurrentShipment = (data) => ({
  type: EntryActionTypes.CURRENT_SHIPMENT_UPDATE,
  payload: {
    data,
  },
});

export const handleShipmentUpdate = (shipment) => ({
  type: EntryActionTypes.SHIPMENT_UPDATE_HANDLE,
  payload: {
    shipment,
  },
});

export const deleteShipment = (id) => ({
  type: EntryActionTypes.SHIPMENT_DELETE,
  payload: {
    id,
  },
});

export const deleteCurrentShipment = () => ({
  type: EntryActionTypes.CURRENT_SHIPMENT_DELETE,
  payload: {},
});

export const handleShipmentDelete = (shipment) => ({
  type: EntryActionTypes.SHIPMENT_DELETE_HANDLE,
  payload: {
    shipment,
  },
});
