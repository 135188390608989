import socket from './socket';

/* Actions */

const createCountry = (businessId, data, headers) =>
  socket.post(`/businesses/${businessId}/countries`, data, headers);

const updateCountry = (id, data, headers) => socket.patch(`/countries/${id}`, data, headers);

const deleteCountry = (id, headers) => socket.delete(`/countries/${id}`, undefined, headers);

export default {
  createCountry,
  updateCountry,
  deleteCountry,
};
