import { all, takeEvery } from 'redux-saga/effects';

import {
  addStatusToFilterInCurrentGroupService,
  removeStatusFromFilterInCurrentGroupService,
  addShipmentStatusToFilterInCurrentGroupService,
  removeShipmentStatusFromFilterInCurrentGroupService,
  addShipProvinceToFilterInCurrentGroupService,
  removeShipProvinceFromFilterInCurrentGroupService,
} from '../services';
import EntryActionTypes from '../../../constants/EntryActionTypes';

export default function* userWatchers() {
  yield all([
    takeEvery(EntryActionTypes.STATUS_TO_FILTER_IN_CURRENT_GROUP_ADD, ({ payload: { key } }) =>
      addStatusToFilterInCurrentGroupService(key),
    ),
    takeEvery(EntryActionTypes.STATUS_FROM_FILTER_IN_CURRENT_GROUP_REMOVE, ({ payload: { key } }) =>
      removeStatusFromFilterInCurrentGroupService(key),
    ),
    takeEvery(
      EntryActionTypes.SHIPMENT_STATUS_TO_FILTER_IN_CURRENT_GROUP_ADD,
      ({ payload: { key } }) => addShipmentStatusToFilterInCurrentGroupService(key),
    ),
    takeEvery(
      EntryActionTypes.SHIPMENT_STATUS_FROM_FILTER_IN_CURRENT_GROUP_REMOVE,
      ({ payload: { key } }) => removeShipmentStatusFromFilterInCurrentGroupService(key),
    ),
    takeEvery(
      EntryActionTypes.SHIP_PROVINCE_TO_FILTER_IN_CURRENT_GROUP_ADD,
      ({ payload: { key } }) => addShipProvinceToFilterInCurrentGroupService(key),
    ),
    takeEvery(
      EntryActionTypes.SHIP_PROVINCE_FROM_FILTER_IN_CURRENT_GROUP_REMOVE,
      ({ payload: { key } }) => removeShipProvinceFromFilterInCurrentGroupService(key),
    ),
  ]);
}
