/* eslint-disable no-unused-vars */
import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { Button, Form, Message } from 'semantic-ui-react';
import XLSX from 'sheetjs-style';

import { usePrevious } from '../../lib/hooks';
import { closePopup } from '../../lib/popup';
import { FilePicker, Popup } from '../../lib/custom-ui';

import styles from './ImportStep.module.scss';

const ImportStep = React.memo(
  ({ title, type, isSubmitting, response, onMessageDismiss, importGroup, onBack }) => {
    const [t] = useTranslation();
    const wasSubmitting = usePrevious(isSubmitting);

    const [file, setFile] = useState(null);

    const handleFileSelect = useCallback((f) => {
      if (f.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;
        reader.onload = (e) => {
          /* Parse data */
          const bstr = e.target.result;
          const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' });
          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
          const csv = new File([data], f.name.replace('.xlsx', '.csv'), {
            type: 'text/csv;charset=UTF-8',
          });
          setFile(csv);
        };
        if (rABS) reader.readAsBinaryString(f);
        else reader.readAsArrayBuffer(f);
      } else {
        setFile(f);
      }
    }, []);

    const message = useMemo(() => {
      if (!response) {
        return response;
      }

      switch (response.message) {
        case 'No file was uploaded':
          return {
            type: 'error',
            content: t('common.noFileWasUploaded'),
          };
        case 'Group not found':
          return {
            type: 'error',
            content: t('common.groupNotFound_title'),
          };
        case 'OK':
          return {
            type:
              response.errorLine.length + response.existedLine.length === 0 ? 'success' : 'warning',
            content: null,
            list: [
              `Thành công: ${response.success}`,
              `Lỗi: [${response.errorLine.join(', ') || 0}]`,
              `Đã tồn tại: [${response.existedLine.join(', ') || 0}]`,
            ],
          };
        default:
          return {
            type: 'warning',
            content: t('common.unknownError'),
          };
      }
    }, [response, t]);

    const handleSubmit = useCallback(() => {
      importGroup({ file, type });
    }, [importGroup, file, type]);

    const handleClose = useCallback(() => {
      // onMessageDismiss();
      closePopup();
    }, []);

    return (
      <>
        <Popup.Header onBack={onBack}>{title}</Popup.Header>
        <Popup.Content>
          {wasSubmitting && message && (
            <Message
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...{
                [message.type]: true,
              }}
              visible
              content={message.content}
              list={message.list}
              onDismiss={onMessageDismiss}
            />
          )}
          <Form onSubmit={handleSubmit}>
            {file && <div>{file.name}</div>}
            <FilePicker accept="csv" onSelect={handleFileSelect}>
              <Button
                content={t('common.fromComputer', {
                  context: 'title',
                })}
                type="button"
                className={styles.actionButton}
              />
            </FilePicker>
            <Button
              positive
              content={t('action.save')}
              loading={isSubmitting}
              disabled={isSubmitting || !file}
            />
          </Form>
          <Button
            basic
            content={t('action.close')}
            className={styles.deleteButton}
            onClick={handleClose}
          />
        </Popup.Content>
      </>
    );
  },
);

ImportStep.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  response: PropTypes.objectOf(PropTypes.any),
  importGroup: PropTypes.func.isRequired,
  onMessageDismiss: PropTypes.func.isRequired,
  onBack: PropTypes.func,
};

ImportStep.defaultProps = {
  response: undefined,
  onBack: undefined,
};

export default ImportStep;
