import ActionTypes from '../constants/ActionTypes';

export const createProduct = (product) => ({
  type: ActionTypes.PRODUCT_CREATE,
  payload: {
    product,
  },
});

createProduct.success = (localId, product) => ({
  type: ActionTypes.PRODUCT_CREATE__SUCCESS,
  payload: {
    localId,
    product,
  },
});

createProduct.failure = (localId, error) => ({
  type: ActionTypes.PRODUCT_CREATE__FAILURE,
  payload: {
    localId,
    error,
  },
});

export const handleProductCreate = (product) => ({
  type: ActionTypes.PRODUCT_CREATE_HANDLE,
  payload: {
    product,
  },
});

export const updateProduct = (id, data) => ({
  type: ActionTypes.PRODUCT_UPDATE,
  payload: {
    id,
    data,
  },
});

updateProduct.success = (product, productCategories) => ({
  type: ActionTypes.PRODUCT_UPDATE__SUCCESS,
  payload: {
    product,
    productCategories,
  },
});

updateProduct.failure = (id, error) => ({
  type: ActionTypes.PRODUCT_UPDATE__FAILURE,
  payload: {
    id,
    error,
  },
});

export const handleProductUpdate = (product) => ({
  type: ActionTypes.PRODUCT_UPDATE_HANDLE,
  payload: {
    product,
  },
});

export const deleteProduct = (id) => ({
  type: ActionTypes.PRODUCT_DELETE,
  payload: {
    id,
  },
});

deleteProduct.success = (product) => ({
  type: ActionTypes.PRODUCT_DELETE__SUCCESS,
  payload: {
    product,
  },
});

deleteProduct.failure = (id, error) => ({
  type: ActionTypes.PRODUCT_DELETE__FAILURE,
  payload: {
    id,
    error,
  },
});

export const handleProductDelete = (product) => ({
  type: ActionTypes.PRODUCT_DELETE_HANDLE,
  payload: {
    product,
  },
});

export const fetchProduct = (id) => ({
  type: ActionTypes.PRODUCT_FETCH,
  payload: {
    id,
  },
});

fetchProduct.success = (
  product,
  customers,
  addresses,
  sellitems,
  sellitemMemberships,
  sellitemLabels,
  payments,
  attachments,
  stocks,
  productCategories,
) => ({
  type: ActionTypes.PRODUCT_FETCH__SUCCESS,
  payload: {
    product,
    customers,
    addresses,
    sellitems,
    sellitemMemberships,
    sellitemLabels,
    payments,
    attachments,
    stocks,
    productCategories,
  },
});

fetchProduct.failure = (id, error) => ({
  type: ActionTypes.PRODUCT_FETCH__FAILURE,
  payload: {
    id,
    error,
  },
});
