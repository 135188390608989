import EntryActionTypes from '../../constants/EntryActionTypes';

export const createBusiness = (data) => ({
  type: EntryActionTypes.BUSINESS_CREATE,
  payload: {
    data,
  },
});

export const handleBusinessCreate = (business) => ({
  type: EntryActionTypes.BUSINESS_CREATE_HANDLE,
  payload: {
    business,
  },
});

export const updateCurrentBusiness = (data) => ({
  type: EntryActionTypes.CURRENT_BUSINESS_UPDATE,
  payload: {
    data,
  },
});

export const handleBusinessUpdate = (business) => ({
  type: EntryActionTypes.BUSINESS_UPDATE_HANDLE,
  payload: {
    business,
  },
});

export const updateCurrentBusinessBackgroundImage = (data) => ({
  type: EntryActionTypes.CURRENT_BUSINESS_BACKGROUND_IMAGE_UPDATE,
  payload: {
    data,
  },
});

export const deleteCurrentBusiness = () => ({
  type: EntryActionTypes.CURRENT_BUSINESS_DELETE,
  payload: {},
});

export const handleBusinessDelete = (business) => ({
  type: EntryActionTypes.BUSINESS_DELETE_HANDLE,
  payload: {
    business,
  },
});
