import { all, takeEvery } from 'redux-saga/effects';

import { createStockInCurrentProductService, updateStockService } from '../services';
import EntryActionTypes from '../../../constants/EntryActionTypes';

export default function* stockWatchers() {
  yield all([
    takeEvery(EntryActionTypes.STOCK_CREATE, ({ payload: { data } }) =>
      createStockInCurrentProductService(data),
    ),
    takeEvery(EntryActionTypes.STOCK_UPDATE, ({ payload: { id, data } }) =>
      updateStockService(id, data),
    ),
  ]);
}
