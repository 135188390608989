import { createSelector } from 'redux-orm';

import orm from '../orm';
import { pathSelector } from './router';
import { currentUserIdSelector } from './user';
import { isLocalId } from '../utils/local-id';

export const currentBusinessSelector = createSelector(
  orm,
  (state) => pathSelector(state).businessId,
  (state) => currentUserIdSelector(state),
  (state) => pathSelector(state).groupId,
  ({ Business, Group }, id, currentUserId, groupId) => {
    if (!id) {
      return id;
    }

    const businessModel = Business.withId(id);

    if (!businessModel) {
      return businessModel;
    }

    let firstGroupId = groupId;
    let firstCustomerId;
    let firstProductId;
    let firstShipmentId;

    if (!firstGroupId) {
      const groupsModels = businessModel.getOrderedAvailableGroupsModelArray(currentUserId);
      firstGroupId = groupsModels[0] && groupsModels[0].id;
    }
    if (firstGroupId) {
      const groupModel = Group.withId(firstGroupId);
      // const { customerQuery } = groupModel;
      const firstCustomerModel = groupModel
        .getOrderedCustomersQuerySet()
        // .filter(({ code }) => code === customerQuery)
        .first();
      firstCustomerId = firstCustomerModel?.id || firstGroupId;

      const firstProductModel = groupModel.getOrderedProductsQuerySet().first();
      firstProductId = firstProductModel?.id || firstGroupId;

      const firstShipmentModel = groupModel.getOrderedShipmentsQuerySet().first();
      firstShipmentId = firstShipmentModel?.id || firstGroupId;

      // const customerIds = groupModel
      //   .getOrderedCustomersQuerySet()
      //   .toRefArray()
      //   .map((customer) => customer.id);
      // firstCustomerId = customerIds.length > 0 ? customerIds[0] : firstGroupId;

      // const productIds = groupModel
      //   .getOrderedProductsQuerySet()
      //   .toRefArray()
      //   .map((product) => product.id);
      // firstProductId = productIds.length > 0 ? productIds[0] : firstGroupId;

      // const shipmentIds = groupModel
      //   .getOrderedShipmentsQuerySet()
      //   .toRefArray()
      //   .map((shipment) => shipment.id);
      // firstShipmentId = shipmentIds.length > 0 ? shipmentIds[0] : firstGroupId;
    }
    // if (groupId) {
    //   const groupsModels = businessModel.getOrderedAvailableGroupsModelArray(currentUserId);
    //   firstGroupId = groupsModels[0] && groupsModels[0].id;
    // }

    return {
      ...businessModel.ref,
      firstGroupId,
      firstCustomerId,
      firstProductId,
      firstShipmentId,
    };
    // return businessModel.ref;
  },
);

export const managersForCurrentBusinessSelector = createSelector(
  orm,
  (state) => pathSelector(state).businessId,
  (state) => currentUserIdSelector(state),
  ({ Business }, id, currentUserId) => {
    if (!id) {
      return id;
    }

    const businessModel = Business.withId(id);

    if (!businessModel) {
      return businessModel;
    }

    return businessModel
      .getOrderedManagersQuerySet()
      .toModelArray()
      .map((businessManagerModel) => ({
        ...businessManagerModel.ref,
        isPersisted: !isLocalId(businessManagerModel.id),
        user: {
          ...businessManagerModel.user.ref,
          isCurrent: businessManagerModel.user.id === currentUserId,
        },
      }));
  },
);

export const countriesForCurrentBusinessSelector = createSelector(
  orm,
  (state) => pathSelector(state).businessId,
  ({ Business }, id) => {
    if (!id) {
      return id;
    }

    const businessModel = Business.withId(id);

    if (!businessModel) {
      return businessModel;
    }

    return businessModel
      .getOrderedCountriesQuerySet()
      .toModelArray()
      .map((countryModel) => ({
        ...countryModel.ref,
        isPersisted: !isLocalId(countryModel.id),
      }));
  },
);

export const brandsForCurrentBusinessSelector = createSelector(
  orm,
  (state) => pathSelector(state).businessId,
  ({ Business }, id) => {
    if (!id) {
      return id;
    }

    const businessModel = Business.withId(id);

    if (!businessModel) {
      return businessModel;
    }

    return businessModel
      .getOrderedBrandsQuerySet()
      .toModelArray()
      .map((brandModel) => ({
        ...brandModel.ref,
        isPersisted: !isLocalId(brandModel.id),
      }));
  },
);

export const categoriesForCurrentBusinessSelector = createSelector(
  orm,
  (state) => pathSelector(state).businessId,
  ({ Business }, id) => {
    if (!id) {
      return id;
    }

    const businessModel = Business.withId(id);

    if (!businessModel) {
      return businessModel;
    }

    return businessModel
      .getOrderedCategoriesQuerySet()
      .toModelArray()
      .map((categoryModel) => ({
        ...categoryModel.ref,
        isPersisted: !isLocalId(categoryModel.id),
      }));
  },
);

export const accountsForCurrentBusinessSelector = createSelector(
  orm,
  (state) => pathSelector(state).businessId,
  ({ Business }, id) => {
    if (!id) {
      return id;
    }

    const businessModel = Business.withId(id);

    if (!businessModel) {
      return businessModel;
    }

    return businessModel
      .getOrderedAccountsQuerySet()
      .toModelArray()
      .map((accountModel) => ({
        ...accountModel.ref,
        isPersisted: !isLocalId(accountModel.id),
      }));
  },
);

export const customerGroupsForCurrentBusinessSelector = createSelector(
  orm,
  (state) => pathSelector(state).businessId,
  ({ Business }, id) => {
    if (!id) {
      return id;
    }

    const businessModel = Business.withId(id);

    if (!businessModel) {
      return businessModel;
    }

    return businessModel
      .getOrderedCustomerGroupsQuerySet()
      .toModelArray()
      .map((customerGroupModel) => ({
        ...customerGroupModel.ref,
        isPersisted: !isLocalId(customerGroupModel.id),
      }));
  },
);

export const groupsForCurrentBusinessSelector = createSelector(
  orm,
  (state) => pathSelector(state).businessId,
  (state) => currentUserIdSelector(state),
  ({ Business }, id, currentUserId) => {
    if (!id) {
      return id;
    }

    const businessModel = Business.withId(id);

    if (!businessModel) {
      return businessModel;
    }

    return businessModel.getOrderedAvailableGroupsModelArray(currentUserId).map((groupModel) => ({
      ...groupModel.ref,
      isPersisted: !isLocalId(groupModel.id),
    }));
  },
);

export const isCurrentUserManagerForCurrentBusinessSelector = createSelector(
  orm,
  (state) => pathSelector(state).businessId,
  (state) => currentUserIdSelector(state),
  ({ Business }, id, currentUserId) => {
    if (!id) {
      return false;
    }

    const businessModel = Business.withId(id);

    if (!businessModel) {
      return false;
    }

    return businessModel.hasManagerUser(currentUserId);
  },
);

export const countryByProductIdSelector = createSelector(
  orm,
  (_, id) => id,
  ({ Country, Product }, id) => {
    if (!id) {
      return id;
    }

    const productModel = Product.withId(id);

    if (!productModel) {
      return productModel;
    }

    const countryModel = Country.withId(productModel.countryId);

    if (!countryModel) {
      return countryModel;
    }

    return countryModel.ref;
  },
);

export const brandByProductIdSelector = createSelector(
  orm,
  (_, id) => id,
  ({ Brand, Product }, id) => {
    if (!id) {
      return id;
    }

    const productModel = Product.withId(id);

    if (!productModel) {
      return productModel;
    }

    const brandModel = Brand.withId(productModel.brandId);

    if (!brandModel) {
      return brandModel;
    }

    return brandModel.ref;
  },
);
