import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { List } from 'semantic-ui-react';

import styles from './ShipmentItem.module.scss';
import Paths from '../../constants/Paths';

const ShipmentItem = React.memo(
  ({ id, totalAmount, shiplineCount, code, deliveredDate, isActive }) => {
    const [t] = useTranslation();

    return (
      <List.Item className={classNames(styles.shipmentItem, isActive && styles.active)}>
        <Link to={Paths.SHIPMENTS.replace(':id', id)} className={styles.linkToShipment}>
          <div className={classNames(styles.shipment_wrapper)}>
            <div>
              <b>
                {t('common.shipment')}: #{code || ''}
                <span>
                  {' '}
                  {format(
                    deliveredDate ? new Date(deliveredDate) : new Date(),
                    '(dd/MM/yyyy)',
                  )}{' '}
                </span>
              </b>
            </div>
            <div className={styles.shipment_attribute}>
              <span className={styles.shipment_att_label}>{t('common.customers')}:</span>
              <span className={styles.shipment_att_value}>{shiplineCount || 0}</span>
              <span className={styles.shipment_att_label}>, {t('common.totalPrice')}:</span>
              <span className={styles.shipment_att_value}>
                {totalAmount?.toLocaleString('en-GB') || 0}
              </span>
            </div>
          </div>
        </Link>
      </List.Item>
    );
  },
);

ShipmentItem.propTypes = {
  id: PropTypes.string.isRequired,
  totalAmount: PropTypes.number,
  shiplineCount: PropTypes.number,
  code: PropTypes.string,
  deliveredDate: PropTypes.string,
  isActive: PropTypes.bool.isRequired,
};

ShipmentItem.defaultProps = {
  code: undefined,
  deliveredDate: undefined,
  totalAmount: undefined,
  shiplineCount: undefined,
};

export default ShipmentItem;
