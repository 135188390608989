import socket from './socket';
import { transformSellitem } from './sellitems';

/* Actions */

const getShipments = (groupId, data, headers) =>
  socket.get(`/groups/${groupId}/shipments`, data, headers).then((body) => ({
    ...body,
    included: {
      ...body.included,
      sellitems: body.included.sellitems.map(transformSellitem),
    },
  }));

const searchShipments = (groupId, data, headers) =>
  socket.get(`/groups/${groupId}/search-shipments`, data, headers).then((body) => ({
    ...body,
  }));

const createShipment = (groupId, data, headers) =>
  socket.post(`/groups/${groupId}/shipments`, data, headers).then((body) => ({
    ...body,
  }));

const getShipment = (id, headers) =>
  socket.get(`/shipments/${id}`, undefined, headers).then((body) => ({
    ...body,
    included: {
      ...body.included,
      sellitems: body.included.sellitems.map(transformSellitem),
    },
  }));

const updateShipment = (id, data, headers) =>
  socket.patch(`/shipments/${id}`, data, headers).then((body) => ({
    ...body,
  }));

const deleteShipment = (id, headers) =>
  socket.delete(`/shipments/${id}`, undefined, headers).then((body) => ({
    ...body,
  }));

/* Event handlers */

const makeHandleShipmentCreate = (next) => (body) => {
  next({
    ...body,
  });
};

const makeHandleShipmentUpdate = (next) => (body) => {
  next({
    ...body,
  });
};

const makeHandleShipmentDelete = makeHandleShipmentUpdate;

export default {
  getShipments,
  searchShipments,
  createShipment,
  getShipment,
  updateShipment,
  deleteShipment,
  makeHandleShipmentCreate,
  makeHandleShipmentUpdate,
  makeHandleShipmentDelete,
};
