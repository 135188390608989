import { takeEvery, all } from 'redux-saga/effects';

import {
  searchShipmentsInCurrentGroupService,
  fetchShipmentsInCurrentGroupService,
} from '../services';
import EntryActionTypes from '../../../constants/EntryActionTypes';

export default function* shipmentsWatchers() {
  yield all([
    takeEvery(EntryActionTypes.SHIPMENTS_SEARCH, ({ payload: { query } }) =>
      searchShipmentsInCurrentGroupService(query),
    ),
    takeEvery(EntryActionTypes.SHIPMENTS_FETCH, ({ payload: { data } }) =>
      fetchShipmentsInCurrentGroupService(data),
    ),
  ]);
}
