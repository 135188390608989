import { call, put, select } from 'redux-saga/effects';

import { goToProductService } from './router';
import { createSellitemService } from './sellitem';
import request from '../request';
import { pathSelector, pathsMatchSelector } from '../../../selectors';
import {
  createProduct,
  deleteProduct,
  fetchProduct,
  handleProductCreate,
  handleProductDelete,
  handleProductUpdate,
  updateProduct,
} from '../../../actions';
import api from '../../../api';
import { createLocalId } from '../../../utils/local-id';
import Paths from '../../../constants/Paths';
import { Statuses } from '../../../constants/Enums';

export function* createProductService(groupId, data) {
  const nextData = {
    ...data,
  };

  const localId = yield call(createLocalId);

  yield put(
    createProduct({
      ...nextData,
      groupId,
      id: localId,
    }),
  );

  let product;
  try {
    ({ item: product } = yield call(request, api.createProduct, groupId, nextData));
  } catch (error) {
    yield put(createProduct.failure(localId, error));
    return;
  }

  yield put(createProduct.success(localId, product));

  const { customerId } = yield select(pathSelector);
  if (customerId) {
    yield call(createSellitemService, customerId, {
      ...product,
      productId: product.id,
      quantity: 1,
      status: Statuses.NEWORDER.name,
    });
  }

  const { path } = yield select(pathsMatchSelector);
  switch (path) {
    case Paths.PRODUCTS:
      yield call(goToProductService, product.id);
      break;
    case Paths.CUSTOMERS:
      // console.log(path);
      break;
    default:
      yield call(goToProductService, product.id);
  }
}

export function* createProductInCurrentGroupService(data) {
  const { groupId } = yield select(pathSelector);

  yield call(createProductService, groupId, data);
}

export function* handleProductCreateService(product) {
  yield put(handleProductCreate(product));
}

export function* fetchProductService(id) {
  yield put(fetchProduct(id));

  let product;
  let sellitems;
  let customers;
  let addresses;
  let sellitemMemberships;
  let sellitemLabels;
  let payments;
  let productStocks;
  let productCategories;
  let attachments;

  try {
    ({
      item: product,
      included: {
        stocks: productStocks,
        customers,
        addresses,
        sellitems,
        sellitemMemberships,
        sellitemLabels,
        payments,
        attachments,
        productCategories,
      },
    } = yield call(request, api.getProduct, id));
  } catch (error) {
    yield put(fetchProduct.failure(id, error));
    return;
  }
  const stocks = [];
  productStocks.forEach((stock) => {
    stocks.push({ ...stock, productId: id });
  });
  yield put(
    fetchProduct.success(
      product,
      customers,
      addresses,
      sellitems,
      sellitemMemberships,
      sellitemLabels,
      payments,
      stocks,
      attachments,
      productCategories,
    ),
  );
}

export function* updateProductService(id, data) {
  yield put(updateProduct(id, data));

  let product;
  let productCategories;
  try {
    ({
      item: product,
      included: { productCategories },
    } = yield call(request, api.updateProduct, id, data));
  } catch (error) {
    yield put(updateProduct.failure(id, error));
    return;
  }

  yield put(updateProduct.success(product, productCategories));
}

export function* handleProductUpdateService(product) {
  yield put(handleProductUpdate(product));
}

export function* deleteProductService(id) {
  yield put(deleteProduct(id));

  let product;
  try {
    ({ item: product } = yield call(request, api.deleteProduct, id));
  } catch (error) {
    yield put(deleteProduct.failure(id, error));
    return;
  }

  yield put(deleteProduct.success(product));
}

export function* handleProductDeleteService(product) {
  yield put(handleProductDelete(product));
}
