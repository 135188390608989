/* eslint-disable import/prefer-default-export */
import { call, put } from 'redux-saga/effects';

import request from '../request';
import { sendMessageFacebook } from '../../../actions';
import { createLocalId } from '../../../utils/local-id';
import api from '../../../api';

export function* sendMessageFacebookService(data) {
  const localId = yield call(createLocalId);

  yield put(sendMessageFacebook({ id: localId, data }));
  let facebook;
  try {
    ({ item: facebook } = yield call(request, api.sendMessageFacebook, localId, data));
  } catch (error) {
    yield put(sendMessageFacebook.failure(localId, error));
    return;
  } // eslint-disable-line no-empty
  yield put(sendMessageFacebook.success(facebook));
}
