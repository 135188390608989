import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';
import { format } from 'date-fns';
import Handsontable from 'handsontable';
import styles from './ShipmentPage.module.scss';
import { Statuses, StatusesShipment } from '../../constants/Enums';

import globalStyles from '../../styles.module.scss';

export const statusRenderer = (instance, td, row, col, prop, value, cellProperties) => {
  Handsontable.renderers.TextRenderer.apply(this, [
    instance,
    td,
    row,
    col,
    prop,
    value,
    cellProperties,
  ]);
  /* eslint-disable no-param-reassign */
  if (value) {
    const status = Object.values(Statuses).find(({ text }) => text === value) || {
      name: '-',
      color: 'red',
    };
    // td.innerText = status.text; // t(`status.${status.name}`);
    td.style.color = '#fff';
    td.classList.add(globalStyles[`background${upperFirst(camelCase(status.color))}`]);
  }
  /* eslint-enable no-param-reassign */
  return td;
};

export const shipStatusRenderer = (instance, td, row, col, prop, value, cellProperties) => {
  Handsontable.renderers.TextRenderer.apply(this, [
    instance,
    td,
    row,
    col,
    prop,
    value,
    cellProperties,
  ]);
  /* eslint-disable no-param-reassign */
  if (value) {
    const status = Object.values(StatusesShipment).find(({ text }) => text === value) || {
      name: '-',
      color: 'red',
    };
    // td.innerText = t(`statusShipment.${status.name}`);
    td.style.color = '#fff';
    td.classList.add(globalStyles[`background${upperFirst(camelCase(status.color))}`]);
  }
  /* eslint-enable no-param-reassign */
  return td;
};

export const linkRenderer = (instance, td, row, col, prop, value, cellProperties) => {
  const url = instance.getDataAtRowProp(row, 'product.url');
  Handsontable.renderers.HtmlRenderer.apply(this, [
    instance,
    td,
    row,
    col,
    prop,
    `${value} <a href="${url}" style="display:inline" title="Mở ${value}" target="_blank"><i class="cube small icon"></i></a>`,
    cellProperties,
  ]);
};

export const imageRenderer = (instance, td, row, col, prop, value) => {
  const img = document.createElement('IMG');
  const product = instance.getDataAtRowProp(row, 'product');
  // img.onerror = (e) => {
  //   e.target.src = '/img/default.png';
  // };
  img.src = value;
  if (product && product.variation) {
    const color = instance.getDataAtRowProp(row, 'color');
    if (color && product.variation.colors) {
      img.src = product.variation.colors.find(({ name }) => name === color)?.imageUrl;
    }
  }

  Handsontable.dom.empty(td);
  td.classList.add(styles.image);
  td.appendChild(img);
};

export const quantityRenderer = (instance, td, row, col, prop, value, cellProperties) => {
  Handsontable.renderers.TextRenderer.apply(this, [
    instance,
    td,
    row,
    col,
    prop,
    value,
    cellProperties,
  ]);
  const r = Math.max(50, 255 - value * 12);
  if (value > 1) {
    /* eslint-disable no-param-reassign */
    td.style.backgroundColor = `rgba(${r}, ${r}, 50)`;
    if (value >= 8) td.style.color = '#fff';
    /* eslint-enable no-param-reassign */
  }
  return td;
};

export const shipProvinceRenderer = (instance, td, row, col, prop, value, cellProperties) => {
  Handsontable.renderers.TextRenderer.apply(this, [
    instance,
    td,
    row,
    col,
    prop,
    value,
    cellProperties,
  ]);
  /* eslint-disable no-param-reassign */
  if (value === 'Ship tỉnh') {
    td.style.backgroundColor = '#afb0a4';
  } else {
    td.style.backgroundColor = '#ddd';
  }
  /* eslint-enable no-param-reassign */
};

export const dateRenderer = (instance, td, row, col, prop, value, cellProperties) => {
  Handsontable.renderers.TextRenderer.apply(this, [
    instance,
    td,
    row,
    col,
    prop,
    value && format(value, 'HH:mm d/M/yyyy'),
    cellProperties,
  ]);
  if (value) {
    /* eslint-disable no-param-reassign */
    // td.innerText = t('format:longDateTime', {
    //   postProcess: 'formatDate',
    //   value: new Date(value),
    // });
    td.style.color = '#777';
    /* eslint-enable no-param-reassign */
  }
  return td;
};

export const arrivalDateRenderer = (instance, td, row, col, prop, value, cellProperties) => {
  /* eslint-disable no-param-reassign */
  Handsontable.renderers.TextRenderer.apply(this, [
    instance,
    td,
    row,
    col,
    prop,
    value,
    cellProperties,
  ]);
  if (!value) {
    const estimatedArrivalDate = instance.getDataAtRowProp(row, 'estimatedArrivalDate');
    td.innerText = cellProperties.placeholder.replace(
      ':date',
      format(estimatedArrivalDate, 'd/M/yyyy'),
    );
    td.style.color = '#ccc';
  }
  /* eslint-enable no-param-reassign */
  return td;
};

export const percentRenderer = (instance, td, row, col, prop, value, cellProperties) => {
  // const status = instance.getDataAtRowProp(row, 'status');
  // cellProperties.readOnly = readOnly || row === 0 || status === PaymentStatuses.FINISH.name;
  Handsontable.renderers.NumericRenderer.apply(this, [
    instance,
    td,
    row,
    col,
    prop,
    value !== null ? `${+value}%` : value,
    cellProperties,
  ]);
  return td;
};
