import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { usersExceptCurrentSelector } from '../selectors';
import { closeModal } from '../actions/entry';
import SellitemInvoiceModal from '../components/SellitemInvoiceModal';

const mapStateToProps = (state) => {
  const users = usersExceptCurrentSelector(state);

  return {
    items: users,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onClose: closeModal,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(SellitemInvoiceModal);
