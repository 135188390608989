import { createSelector } from 'redux-orm';

import orm from '../orm';
import { isLocalId } from '../utils/local-id';

export const makeShiplineByIdSelector = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ Shipline }, id) => {
      const shiplineModel = Shipline.withId(id);

      if (!shiplineModel) {
        return shiplineModel;
      }

      return {
        ...shiplineModel.ref,
        customer: shiplineModel.customer.ref,
        address: shiplineModel.address?.ref,
        isPersisted: !isLocalId(id),
      };
    },
  );

export const shiplineByIdSelector = makeShiplineByIdSelector();
