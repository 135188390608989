import ActionTypes from '../constants/ActionTypes';

// eslint-disable-next-line import/prefer-default-export
export const fetchSellitems = (groupId, filters) => ({
  type: ActionTypes.SELLITEMS_FETCH,
  payload: {
    groupId,
    filters,
  },
});

fetchSellitems.success = (
  groupId,
  sellitems,
  customers,
  addresses,
  sellitemMemberships,
  sellitemLabels,
  products,
  categories,
  productCategories,
  payments,
  attachments,
) => ({
  type: ActionTypes.SELLITEMS_FETCH__SUCCESS,
  payload: {
    groupId,
    sellitems,
    customers,
    addresses,
    sellitemMemberships,
    sellitemLabels,
    products,
    categories,
    productCategories,
    payments,
    attachments,
  },
});

fetchSellitems.failure = (groupId, error) => ({
  type: ActionTypes.SELLITEMS_FETCH__FAILURE,
  payload: {
    groupId,
    error,
  },
});

export const changeOrderDateToGroupFilter = (orderDate, groupId) => ({
  type: ActionTypes.ORDER_DATE_TO_GROUP_FILTER_CHANGE,
  payload: {
    orderDate,
    groupId,
  },
});

export const changeArrivalDateToGroupFilter = (arrivalDate, groupId) => ({
  type: ActionTypes.ARRIVAL_DATE_TO_GROUP_FILTER_CHANGE,
  payload: {
    arrivalDate,
    groupId,
  },
});

export const changeWebOrderNoToGroupFilter = (webOrderNo, groupId) => ({
  type: ActionTypes.WEB_ORDER_NO_TO_GROUP_FILTER_CHANGE,
  payload: {
    webOrderNo,
    groupId,
  },
});

export const changeArrivalDateForShipmentPageToGroupFilter = (arrivalDate, groupId) => ({
  type: ActionTypes.ARRIVAL_DATE_FOR_SHIPMENT_PAGE_TO_GROUP_FILTER_CHANGE,
  payload: {
    arrivalDate,
    groupId,
  },
});

export const updateSellitems = (id, data) => ({
  type: ActionTypes.SELLITEMS_UPDATE,
  payload: {
    id,
    data,
  },
});

updateSellitems.success = (sellitems) => ({
  type: ActionTypes.SELLITEMS_UPDATE__SUCCESS,
  payload: {
    sellitems,
  },
});

updateSellitems.failure = (id, error) => ({
  type: ActionTypes.SELLITEMS_UPDATE__FAILURE,
  payload: {
    id,
    error,
  },
});

export const handleSellitemsUpdate = (sellitems) => ({
  type: ActionTypes.SELLITEMS_UPDATE_HANDLE,
  payload: {
    sellitems,
  },
});
