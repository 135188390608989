import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { List } from 'semantic-ui-react';

import styles from './Dashboard.module.scss';

const RevenueItem = ({ revenueCountries }) => {
  const [t] = useTranslation();

  return (
    <>
      <div className={styles.listHeader}>
        <h3 className={styles.filterTopTitle}>{t('common.dashboard.revenueByCountry')}</h3>
      </div>
      <List divided selection className={styles.wrapperRevenue}>
        {revenueCountries.map((data) => (
          <div key={data.country_id} className={styles.revenueItemWrapper}>
            <div className={styles.itemLeft}>
              <span>{data.percent && `${data.percent}%`}</span>
            </div>
            <div className={styles.itemCenter}>
              <div className={styles.itemName}>{data.country?.name}</div>
              <div className={styles.itemInfo}>
                <span>
                  {t('common.dashboard.quantityProduct')}:{' '}
                  <span className={styles.textOrange}>{data.quantity}</span>
                </span>
                {', '}
                <span>
                  {t('common.dashboard.totalAmount')}:{' '}
                  <span className={styles.textBlue}>
                    {(data.sell_price * 1).toLocaleString('en-GB')}
                  </span>
                </span>
              </div>
            </div>
          </div>
        ))}
      </List>
    </>
  );
};

RevenueItem.propTypes = {
  revenueCountries: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default RevenueItem;
