import { call, put, select } from 'redux-saga/effects';

import { goToShipmentService } from './router';
import request from '../request';
import { pathSelector } from '../../../selectors';
import {
  createShipline,
  deleteShipline,
  handleShiplineCreate,
  handleShiplineDelete,
  handleShiplineUpdate,
  updateShipline,
} from '../../../actions';
import api from '../../../api';
import { createLocalId } from '../../../utils/local-id';

export function* createShiplineService(shipmentId, data) {
  const nextData = {
    ...data,
  };

  const localId = yield call(createLocalId);

  yield put(
    createShipline({
      ...nextData,
      shipmentId,
      id: localId,
    }),
  );

  let shipline;
  let shipment;
  try {
    ({
      item: shipline,
      included: { shipment },
    } = yield call(request, api.createShipline, shipmentId, nextData));
  } catch (error) {
    yield put(createShipline.failure(localId, error));
    return;
  }

  yield put(createShipline.success(localId, shipline, shipment));
  yield call(goToShipmentService, shipmentId);
}

export function* createShiplineInCurrentShipmentService(data) {
  const { shipmentId } = yield select(pathSelector);

  yield call(createShiplineService, shipmentId, data);
}

export function* handleShiplineCreateService(label) {
  yield put(handleShiplineCreate(label));
}

export function* updateShiplineService(id, data) {
  yield put(updateShipline(id, data));

  let shipline;
  let shipment;
  try {
    ({
      item: shipline,
      included: { shipment },
    } = yield call(request, api.updateShipline, id, data));
  } catch (error) {
    yield put(updateShipline.failure(id, error));
    return;
  }

  yield put(updateShipline.success(shipline, shipment));
}

export function* handleShiplineUpdateService(shipment) {
  yield put(handleShiplineUpdate(shipment));
}

export function* deleteShiplineService(id) {
  yield put(deleteShipline(id));

  let shipline;
  let shipment;
  try {
    ({
      item: shipline,
      included: { shipment },
    } = yield call(request, api.deleteShipline, id));
  } catch (error) {
    yield put(deleteShipline.failure(id, error));
    return;
  }

  yield put(deleteShipline.success(shipline, shipment));
}

export function* handleShiplineDeleteService(shipline) {
  yield put(handleShiplineDelete(shipline));
}
