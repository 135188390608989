import React from 'react';
import PropTypes from 'prop-types';
import { Segment } from 'semantic-ui-react';

import Filters from './Filters';

import styles from './WarehouseActions.module.scss';

const WarehouseActions = React.memo(({ products, onChangeFilter, onChangeSearchKey }) => {
  return (
    <Segment className={styles.actions}>
      <div className={styles.action}>
        <Filters
          products={products}
          onChangeFilter={onChangeFilter}
          onChangeSearchKey={onChangeSearchKey}
        />
      </div>
    </Segment>
  );
});

WarehouseActions.propTypes = {
  products: PropTypes.arrayOf(PropTypes.any).isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  onChangeSearchKey: PropTypes.func.isRequired,
};

export default WarehouseActions;
