import { connect } from 'react-redux';

import { currentModalSelector } from '../selectors';
import ModalTypes from '../constants/ModalTypes';
import Business from '../components/Business';

const mapStateToProps = (state) => {
  const currentModal = currentModalSelector(state);

  return {
    isSettingsModalOpened: currentModal === ModalTypes.BUSINESS_SETTINGS,
  };
};

export default connect(mapStateToProps)(Business);
