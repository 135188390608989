import EntryActionTypes from '../../constants/EntryActionTypes';

export const createCountryInCurrentBusiness = (data) => ({
  type: EntryActionTypes.COUNTRY_IN_CURRENT_BUSINESS_CREATE,
  payload: {
    data,
  },
});

export const handleCountryCreate = (country) => ({
  type: EntryActionTypes.COUNTRY_CREATE_HANDLE,
  payload: {
    country,
  },
});

export const updateCountry = (id, data) => ({
  type: EntryActionTypes.COUNTRY_UPDATE,
  payload: {
    id,
    data,
  },
});

export const handleCountryUpdate = (country) => ({
  type: EntryActionTypes.COUNTRY_UPDATE_HANDLE,
  payload: {
    country,
  },
});

export const deleteCountry = (id) => ({
  type: EntryActionTypes.COUNTRY_DELETE,
  payload: {
    id,
  },
});

export const handleCountryDelete = (country) => ({
  type: EntryActionTypes.COUNTRY_DELETE_HANDLE,
  payload: {
    country,
  },
});

export const addCountryToFilterInCurrentGroup = (id) => ({
  type: EntryActionTypes.COUNTRY_TO_FILTER_IN_CURRENT_GROUP_ADD,
  payload: {
    id,
  },
});

export const removeCountryFromFilterInCurrentGroup = (id) => ({
  type: EntryActionTypes.COUNTRY_FROM_FILTER_IN_CURRENT_GROUP_REMOVE,
  payload: {
    id,
  },
});
