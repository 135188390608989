import { all, takeEvery } from 'redux-saga/effects';

import {
  createAccountInCurrentBusinessService,
  deleteAccountService,
  handleAccountCreateService,
  handleAccountDeleteService,
  handleAccountUpdateService,
  updateAccountService,
} from '../services';
import EntryActionTypes from '../../../constants/EntryActionTypes';

export default function* accountWatchers() {
  yield all([
    takeEvery(EntryActionTypes.ACCOUNT_IN_CURRENT_BUSINESS_CREATE, ({ payload: { data } }) =>
      createAccountInCurrentBusinessService(data),
    ),
    takeEvery(EntryActionTypes.ACCOUNT_CREATE_HANDLE, ({ payload: { account } }) =>
      handleAccountCreateService(account),
    ),
    takeEvery(EntryActionTypes.ACCOUNT_UPDATE, ({ payload: { id, data } }) =>
      updateAccountService(id, data),
    ),
    takeEvery(EntryActionTypes.ACCOUNT_UPDATE_HANDLE, ({ payload: { account } }) =>
      handleAccountUpdateService(account),
    ),
    takeEvery(EntryActionTypes.ACCOUNT_DELETE, ({ payload: { id } }) => deleteAccountService(id)),
    takeEvery(EntryActionTypes.ACCOUNT_DELETE_HANDLE, ({ payload: { account } }) =>
      handleAccountDeleteService(account),
    ),
  ]);
}
