/* eslint-disable no-unused-vars */
import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Accordion, Button, Icon, Label, List, Segment } from 'semantic-ui-react';
import { closePopup } from '../../lib/popup';

import ActionsPopup from '../CustomerItem/ActionsPopup';
import Addresses from './Addresses';
import Payments from './Payments';
import NameEdit from '../Customer/NameEdit';
import AddPopup from './Addresses/AddPopup';
import AddPaymentPopup from './Payments/AddPopup';
import { PaymentTypes } from '../../constants/Enums';

import styles from './RightSidebar.module.scss';

const RightSidebar = React.memo(
  ({
    accounts,
    addresses,
    customer,
    customerGroups,
    payments,
    totals,
    canEdit,
    isPersisted,
    onAddressCreate,
    onAddressDelete,
    onAddressUpdate,
    onPaymentCreate,
    onPaymentDelete,
    onPaymentUpdate,
    onUpdate,
  }) => {
    const [t] = useTranslation();

    const nameEdit = useRef(null);
    const facebookEdit = useRef(null);
    const phoneEdit = useRef(null);
    const [active, setActive] = useState(false);

    const handleHeaderClick = useCallback(() => {
      if (isPersisted && canEdit) {
        nameEdit.current.open();
      }
    }, [isPersisted, canEdit]);

    const handleNameUpdate = useCallback(
      (newName) => {
        onUpdate(customer.id, {
          name: newName,
        });
      },
      [customer.id, onUpdate],
    );

    const handleFacebookHeaderClick = useCallback(() => {
      if (isPersisted && canEdit) {
        facebookEdit.current.open();
      }
    }, [isPersisted, canEdit]);

    const handleFacebookUpdate = useCallback(
      (value) => {
        onUpdate(customer.id, {
          facebook: value,
        });
      },
      [customer.id, onUpdate],
    );

    const handlePhoneHeaderClick = useCallback(() => {
      if (isPersisted && canEdit) {
        phoneEdit.current.open();
      }
    }, [isPersisted, canEdit]);

    const handlePhoneUpdate = useCallback(
      (value) => {
        onUpdate(customer.id, {
          phone: value,
        });
      },
      [customer.id, onUpdate],
    );

    const handleAddressIdUpdate = useCallback(
      (value) => {
        onUpdate(customer.id, {
          addressId: value,
        });
      },
      [customer.id, onUpdate],
    );

    const handleAccordionClick = useCallback(() => {
      setActive(!active);
    }, [active]);

    const depositAccount = accounts.find((a) => a.type === PaymentTypes.DEPOSIT) || {
      accountId: '',
    };

    const handleCustomerGroupUpdate = useCallback(
      (data) => {
        onUpdate(customer.id, data);
      },
      [customer.id, onUpdate],
    );

    const handleCustomerGroupDelete = useCallback(() => {
      onUpdate(customer.id, { customerGroupId: null });
      closePopup();
    }, [customer.id, onUpdate]);

    const allDeposits = payments.filter((p) => p.type === PaymentTypes.DEPOSIT);
    const allPayments = payments.filter((p) => p.type === PaymentTypes.PAYMENT);
    const allPaymentsByDeposit = allPayments.filter((p) => p.accountId === depositAccount.id);

    const totalDeposits = allDeposits.reduce((amount, current) => {
      return amount + current.amount * 1;
    }, 0);

    const totalPayments = allPayments.reduce((amount, current) => {
      return amount + current.amount * 1;
    }, 0);

    const totalPaymentsByDeposit = allPaymentsByDeposit.reduce((amount, current) => {
      return amount + current.amount * 1;
    }, 0);

    console.log('Customers RightSidebar render');

    return (
      <Segment className={styles.wrapper}>
        <List divided verticalAlign="middle" className={styles.details}>
          <List.Item>
            <List.Content
              as="h4"
              style={{ minHeight: '28px', marginTop: '0px' }}
              onClick={handleHeaderClick}
            >
              <NameEdit ref={nameEdit} defaultValue={customer.name} onUpdate={handleNameUpdate}>
                <div className={styles.headerName}>{customer.name}</div>
              </NameEdit>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Content floated="right">
              {t('common.customerGroup')}&nbsp;
              <ActionsPopup
                currentCustomerGroupId={customer.customerGroupId}
                items={customerGroups}
                onUpdate={handleCustomerGroupUpdate}
                onDelete={handleCustomerGroupDelete}
              >
                <Label className={styles.group} style={{ backgroundColor: customer.group?.color }}>
                  {customer.group?.name || '+'}
                </Label>
              </ActionsPopup>
            </List.Content>
            <List.Content>
              {t('common.code')}: {customer.code}
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Icon verticalAlign="middle">
              <a href={customer.facebook} target="_brank">
                <Icon name="facebook" />
              </a>
            </List.Icon>
            <List.Content style={{ padding: '0' }} onClick={handleFacebookHeaderClick}>
              <NameEdit
                ref={facebookEdit}
                defaultValue={customer.facebook || ''}
                onUpdate={handleFacebookUpdate}
              >
                <div className={styles.itemDetail}>
                  {customer.facebook?.replace(
                    /https:\/\/www.facebook.com|https:\/\/www.messenger.com/,
                    '',
                  )}
                </div>
              </NameEdit>
            </List.Content>
          </List.Item>
          <List.Item onClick={handlePhoneHeaderClick}>
            <List.Content floated="right" style={{ padding: '0' }}>
              <NameEdit
                ref={phoneEdit}
                defaultValue={customer.phone || ''}
                onUpdate={handlePhoneUpdate}
              >
                <div className={styles.headerName}>{customer.phone}</div>
              </NameEdit>
            </List.Content>
            <List.Content>
              {t('common.phone')} <Icon name="pencil" size="tiny" />
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Content floated="right">
              <AddPopup
                onCreate={onAddressCreate}
                onUpdate={onUpdate}
                defaultData={{ isDefault: addresses.length === 0 }}
              >
                <Button
                  basic
                  icon="plus"
                  size="mini"
                  content={t('action.addAddress')}
                  className={styles.addButton}
                />
              </AddPopup>
            </List.Content>
            <List.Content onClick={handleAccordionClick}>
              {t('common.address')}
              <Label size="mini" content={addresses.length} />
            </List.Content>
            <List.Content style={{ padding: 0 }}>
              <Accordion>
                <Accordion.Title active={active} style={{ display: 'none' }} />
                <Accordion.Content active={active}>
                  <Addresses
                    defaultAddressId={customer.addressId || ''}
                    items={addresses}
                    canEdit
                    onCreate={onAddressCreate}
                    onUpdate={onAddressUpdate}
                    onDelete={onAddressDelete}
                    onCustomerUpdate={(addressId) => handleAddressIdUpdate(addressId)}
                  />
                </Accordion.Content>
              </Accordion>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Content floated="right">{totalDeposits.toLocaleString('en-GB')}</List.Content>
            <List.Content>
              {t('common.totalDeposit')}
              <AddPaymentPopup
                title="common.createDeposit"
                accounts={accounts.filter((a) => a.type !== PaymentTypes.DEPOSIT)}
                customerId={customer.id}
                onCreate={onPaymentCreate}
                onUpdate={onPaymentUpdate}
              >
                <Button basic icon="plus" size="mini" className={styles.addButton} />
              </AddPaymentPopup>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Content
              floated="right"
              className={classNames(
                totalDeposits - totalPaymentsByDeposit < 0 ? styles.minus : styles.plus,
              )}
            >
              {(totalDeposits - totalPaymentsByDeposit).toLocaleString('en-GB')}
            </List.Content>
            <List.Content>{t('common.totalDepositRemain')}</List.Content>
          </List.Item>
          <List.Item>
            <List.Content floated="right">{totals.quantity}</List.Content>
            <List.Content>{t('common.totalSellitems')}</List.Content>
          </List.Item>
          <List.Item>
            <List.Content floated="right">{totals.finalTotal.toLocaleString('en-GB')}</List.Content>
            <List.Content>{t('common.totalPurchases')}</List.Content>
          </List.Item>
          <List.Item>
            <List.Content floated="right">{totalPayments.toLocaleString('en-GB')}</List.Content>
            <List.Content>{t('common.totalPayments')}</List.Content>
          </List.Item>
          <List.Item>
            <List.Content
              floated="right"
              className={classNames(
                totals.finalTotal - totalPayments > 0 ? styles.minus : styles.plus,
              )}
            >
              {(totals.finalTotal - totalPayments).toLocaleString('en-GB')}
            </List.Content>
            <List.Content>{t('common.totalRemains')}</List.Content>
          </List.Item>
        </List>
        <hr style={{ margin: '0 -14px' }} />
        <Payments
          accounts={accounts}
          items={payments}
          canEdit
          onUpdate={onPaymentUpdate}
          onDelete={onPaymentDelete}
        />
      </Segment>
    );
  },
);

RightSidebar.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.any).isRequired,
  addresses: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  canEdit: PropTypes.bool.isRequired,
  customer: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  customerGroups: PropTypes.arrayOf(PropTypes.any).isRequired,
  payments: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  totals: PropTypes.objectOf(PropTypes.any).isRequired,
  isPersisted: PropTypes.bool.isRequired,
  onAddressCreate: PropTypes.func.isRequired,
  onAddressDelete: PropTypes.func.isRequired,
  onAddressUpdate: PropTypes.func.isRequired,
  onPaymentCreate: PropTypes.func.isRequired,
  onPaymentDelete: PropTypes.func.isRequired,
  onPaymentUpdate: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

// RightSidebar.defaultProps = {
//   animation: 'push',
//   direction: undefined,
//   visible: true,
// };

export default RightSidebar;
