import ActionTypes from '../constants/ActionTypes';

export const createSellitem = (sellitem) => ({
  type: ActionTypes.SELLITEM_CREATE,
  payload: {
    sellitem,
  },
});

createSellitem.success = (localId, sellitem) => ({
  type: ActionTypes.SELLITEM_CREATE__SUCCESS,
  payload: {
    localId,
    sellitem,
  },
});

createSellitem.failure = (localId, error) => ({
  type: ActionTypes.SELLITEM_CREATE__FAILURE,
  payload: {
    localId,
    error,
  },
});

export const handleSellitemCreate = (sellitem, product) => ({
  type: ActionTypes.SELLITEM_CREATE_HANDLE,
  payload: {
    sellitem,
    product,
  },
});

export const updateSellitem = (id, data) => ({
  type: ActionTypes.SELLITEM_UPDATE,
  payload: {
    id,
    data,
  },
});

updateSellitem.success = (sellitem) => ({
  type: ActionTypes.SELLITEM_UPDATE__SUCCESS,
  payload: {
    sellitem,
  },
});

updateSellitem.failure = (id, error) => ({
  type: ActionTypes.SELLITEM_UPDATE__FAILURE,
  payload: {
    id,
    error,
  },
});

export const handleSellitemUpdate = (sellitem) => ({
  type: ActionTypes.SELLITEM_UPDATE_HANDLE,
  payload: {
    sellitem,
  },
});

export const deleteSellitem = (id) => ({
  type: ActionTypes.SELLITEM_DELETE,
  payload: {
    id,
  },
});

deleteSellitem.success = (sellitem) => ({
  type: ActionTypes.SELLITEM_DELETE__SUCCESS,
  payload: {
    sellitem,
  },
});

deleteSellitem.failure = (id, error) => ({
  type: ActionTypes.SELLITEM_DELETE__FAILURE,
  payload: {
    id,
    error,
  },
});

export const handleSellitemDelete = (sellitem) => ({
  type: ActionTypes.SELLITEM_DELETE_HANDLE,
  payload: {
    sellitem,
  },
});

export const addTodoSellitem = (sellitem) => ({
  type: ActionTypes.SELLITEM_ADD_TODO,
  payload: {
    id: sellitem.id,
    sellitem,
  },
});
