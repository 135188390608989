/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { HotTable, HotColumn } from '@handsontable/react';
import { Dimmer, Icon, Loader, Pagination } from 'semantic-ui-react';

import Summary from '../SellitemTable/Summary';
import CustomerRenderer from '../Sellitems/CustomerRenderer';
import { getFilterShipmentsPage, setFilterShipmentsPage } from '../../utils/filter-storage';

import {
  arrivalDateRenderer,
  linkRenderer,
  shipStatusRenderer,
  imageRenderer,
  quantityRenderer,
  shipProvinceRenderer,
} from './renderer';

import styles from './ShipmentPage.module.scss';

const ShipmentPage = React.memo(
  ({
    sellitems,
    stat,
    currentPage,
    arrivalDate,
    isShipmentsFetching,
    canEdit,
    onShipmentsFetch,
  }) => {
    const [t] = useTranslation();

    const history = useHistory();

    const hotTableRef = useRef();
    const summaryRef = useRef();

    const colHeaders = useMemo(
      () => [
        'id',
        t('common.shipment'),
        t('common.members'),
        t('common.code'),
        t('common.customer'),
        t('common.arrivalDate'),
        t('common.products'),
        t('common.image'),
        t('common.quantity'),
        t('common.totalAmount'),
        t('common.shipAmount'),
        t('common.finalTotal'),
        t('common.payments'),
        t('common.status'),
        t('common.estimatedDeliveryDate', { context: 'header' }),
        t('common.deliveredDate'),
        t('common.note'),
        t('common.sellitemPaid_header'),
        t('common.remainTotal'),
        t('common.isShipProvince'),
        t('common.shipper'),
      ],
      [t],
    );

    console.log('ShipmentPage render');

    const afterSelection = useCallback(() => {
      const selected = hotTableRef.current.hotInstance.getSelected();
      if (
        !(
          selected.length === 1 &&
          selected[0][0] === selected[0][2] &&
          selected[0][1] === selected[0][3]
        )
      ) {
        const data = [];
        selected.forEach((it) => {
          data.push(hotTableRef.current.hotInstance.getData(...it).flat());
        });
        summaryRef.current?.show(data);
      } else {
        summaryRef.current?.show([]);
      }
    }, []);

    const afterDeselect = () => {
      summaryRef.current?.show([]);
    };

    const afterFilter = (formulaStack) => {
      setFilterShipmentsPage(formulaStack);
    };

    const afterGetColHeader = (col, TH) => {
      if (col === 5 || col === 11) {
        // const button = TH.querySelector('.changeType');
        // if (!button) {
        //   return;
        // }
        // button.parentElement.removeChild(button);
      }
    };
    const onPageChange = (e, { activePage }) => {
      // setCurrentPage(activePage);
      const start = format(new Date(arrivalDate.start) || '', 'yyyy-MM-dd');
      const end = format(new Date(arrivalDate.end) || '', 'yyyy-MM-dd');
      const search = new URLSearchParams(window.location.search);
      search.set('page', activePage);

      history.push({
        pathname: window.location.pathname,
        search: search.toString(), // `page=${activePage}&start=${start}&end=${end}`,
      });
      // onShipmentsFetch({ page: activePage });
    };

    useEffect(() => {
      const c = getFilterShipmentsPage();
      if (c && hotTableRef.current) {
        // eslint-disable-next-line react/no-this-in-sfc
        const filtersPlugin = hotTableRef.current.hotInstance.getPlugin('Filters');
        // filtersPlugin.addCondition(1, 'by_value', [['ing', 'ed', 'as', 'on']]);
        if (filtersPlugin) {
          // console.log(filtersPlugin.conditionCollection.filteringStates.indexedValues);
          filtersPlugin.clearConditions();
          c.forEach((it) => {
            if (it) {
              it.conditions.forEach((con) => {
                filtersPlugin.addCondition(it.column, con.name, con.args, it.operation);
              });
            }
          });
          filtersPlugin.filter();
        }
      }
    }, [sellitems]);

    if (sellitems.length === 0) {
      return (
        <div className={styles.wrapper}>
          <p>Thay đổi ngày về hoặc số trang. Refresh lại nếu cần</p>
        </div>
      );
    }

    return (
      <div id="hot-app" className={styles.wrapper}>
        <HotTable
          ref={hotTableRef}
          data={sellitems}
          colHeaders={colHeaders}
          hiddenColumns={{ columns: [0] }}
          height="100%"
          stretchH="all"
          autoColumnSize={false}
          autoRowSize={false}
          className={styles.oddeven}
          manualColumnResize
          rowHeaders={false}
          rowHeights={43}
          enterMoves={{ col: 1, row: 0 }}
          dropdownMenu={['filter_by_value', 'filter_action_bar']}
          filters
          afterGetColHeader={afterGetColHeader}
          afterSelection={afterSelection}
          afterDeselect={afterDeselect}
          afterFilter={afterFilter}
          licenseKey="non-commercial-and-evaluation"
        >
          <HotColumn width={0} data="id" />
          <HotColumn width={65} data="shipId" className="htRight" readOnly />
          <HotColumn
            width={60}
            data="creator"
            settings={[{ dropdownMenu: false }]}
            className="htRight"
            readOnly
          />
          <HotColumn
            width={58}
            data="customerCode"
            settings={[{ dropdownMenu: false }]}
            className="htRight"
            readOnly
          />
          <HotColumn width={170} data="customerName" settings={[{ dropdownMenu: false }]} readOnly>
            <CustomerRenderer hot-renderer showFacebook />
          </HotColumn>
          <HotColumn
            width={88}
            data="arrivalDate"
            placeholder={`${t('common.sellitemsInvoice.columnExpectedArriveDay')}\n:date`}
            renderer={arrivalDateRenderer}
            readOnly
          />
          <HotColumn width={180} data="name" renderer={linkRenderer} readOnly />
          <HotColumn width={40} data="imageUrl" renderer={imageRenderer} readOnly />
          <HotColumn
            width={48}
            data="quantity"
            type="numeric"
            renderer={quantityRenderer}
            readOnly
          />
          <HotColumn
            width={90}
            data="total"
            type="numeric"
            numericFormat={{ pattern: '0,0' }}
            readOnly
          />
          <HotColumn
            width={90}
            data="shippingAmount"
            type="numeric"
            numericFormat={{ pattern: '0,0' }}
            readOnly
          />
          <HotColumn
            width={90}
            data="finalTotal"
            type="numeric"
            numericFormat={{ pattern: '0,0' }}
            readOnly
          />
          <HotColumn width={114} data="paymentMethods" type="numeric" readOnly />
          <HotColumn width={100} data="shipStatus" renderer={shipStatusRenderer} readOnly />
          <HotColumn width={80} data="estimatedDeliveryDate" readOnly />
          <HotColumn width={80} data="deliveredDate" readOnly />
          <HotColumn width={110} data="note" readOnly />
          <HotColumn
            width={90}
            data="payment"
            type="numeric"
            numericFormat={{ pattern: '0,0' }}
            readOnly
          />
          <HotColumn
            width={90}
            data="remainTotal"
            type="numeric"
            numericFormat={{ pattern: '0,0' }}
            readOnly
          />
          <HotColumn width={95} data="isShipProvince" renderer={shipProvinceRenderer} readOnly />
          <HotColumn width={90} data="shipper" readOnly />
        </HotTable>
        <div>
          <Summary ref={summaryRef} />
          {stat && (
            <Pagination
              activePage={currentPage}
              boundaryRange={1}
              onPageChange={onPageChange}
              size="mini"
              siblingRange={3}
              prevItem={null}
              nextItem={null}
              // prevItem={{ content: <Icon name="angle left" />, icon: true }}
              // nextItem={{ content: <Icon name="angle right" />, icon: true }}
              // firstItem={{ content: <Icon name="angle double left" />, icon: true }}
              // lastItem={{ content: <Icon name="angle double right" />, icon: true }}
              firstItem={null}
              lastItem={null}
              totalPages={Math.ceil(stat.sellitemsTotalCount / 1000)}
            />
          )}
        </div>
        {isShipmentsFetching && (
          <Dimmer active>
            <Loader active inline="centered" size="small" className={styles.loader} />
          </Dimmer>
        )}
      </div>
    );
  },
);

ShipmentPage.propTypes = {
  sellitems: PropTypes.arrayOf(PropTypes.any).isRequired,
  stat: PropTypes.objectOf(PropTypes.any),
  currentPage: PropTypes.number.isRequired,
  arrivalDate: PropTypes.objectOf(PropTypes.any),
  isShipmentsFetching: PropTypes.bool.isRequired,
  canEdit: PropTypes.bool.isRequired,
  onShipmentsFetch: PropTypes.func.isRequired,
};

ShipmentPage.defaultProps = {
  stat: undefined,
  arrivalDate: {
    start: new Date(),
    end: new Date(),
  },
};

export default ShipmentPage;
