/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import { Button, Form, Menu } from 'semantic-ui-react';
import { withPopup } from '../../lib/popup';
import { useDidUpdate, useToggle } from '../../lib/hooks';
import { Input, Popup } from '../../lib/custom-ui';

import { useForm } from '../../hooks';

import styles from './OrderDateEditPopup.module.scss';

const OrderDateEditStep = React.memo(({ defaultValue, title, onUpdate, onClose }) => {
  const [t] = useTranslation();

  const [data, handleFieldChange, setData] = useForm(() => {
    const date = defaultValue || {
      start: new Date().setHours(12, 0, 0, 0),
      end: new Date().setHours(12, 0, 0, 0),
    };

    return {
      start: t('format:date', {
        postProcess: 'formatDate',
        value: date.start,
      }),
      end: t('format:date', {
        postProcess: 'formatDate',
        value: date.end,
      }),
    };
  });

  const [selectTimeFieldState, selectTimeField] = useToggle();

  const startDateField = useRef(null);
  const endDateField = useRef(null);

  const nullableStartDate = useMemo(() => {
    const date = t('format:date', {
      postProcess: 'parseDate',
      value: data.start,
    });

    if (Number.isNaN(date.getTime())) {
      return null;
    }

    return date;
  }, [data.start, t]);

  const nullableEndDate = useMemo(() => {
    const date = t('format:date', {
      postProcess: 'parseDate',
      value: data.end,
    });

    if (Number.isNaN(date.getTime())) {
      return null;
    }

    return date;
  }, [data.end, t]);

  const handleDatePickerChange = useCallback(
    (update) => {
      const start = update[0];
      start.setHours(0, 0, 0);
      const end = update[1];
      if (end) end.setHours(23, 59, 59);
      setData((prevData) => ({
        // ...prevData,
        start: t('format:date', {
          postProcess: 'formatDate',
          value: start,
        }),
        end: end
          ? t('format:date', {
              postProcess: 'formatDate',
              value: end,
            })
          : '',
      }));
      // setData({
      //   start,
      //   end,
      // });
    },
    [setData, t],
  );

  const handleOnOrderDateClick = useCallback(
    (month, day) => {
      const today = new Date();
      today.setHours(23, 59, 59);
      let newDate;
      switch (day) {
        case 1:
          newDate = null;
          break;
        case -1:
          newDate = {
            start: new Date(
              today.getFullYear(),
              today.getMonth() + month,
              today.getDate() + day,
              0,
              0,
              0,
            ),
            end: new Date(
              today.getFullYear(),
              today.getMonth() + month,
              today.getDate() + day,
              23,
              59,
              59,
            ),
          };
          break;
        default:
          newDate = {
            start: new Date(
              today.getFullYear(),
              today.getMonth() + month,
              today.getDate() + day,
              0,
              0,
              0,
            ),
            end: today,
          };
          break;
      }
      setData(newDate);
      if (newDate?.end) {
        onUpdate(newDate);
        onClose();
      }
      if (!newDate) {
        onUpdate(newDate);
        onClose();
      }
    },
    [onClose, onUpdate, setData],
  );

  const handleSubmit = useCallback(() => {
    if (!nullableEndDate) {
      endDateField.current.select();
      return;
    }

    if (nullableEndDate) {
      onUpdate({ start: nullableStartDate, end: nullableEndDate });
      onClose();
    }
  }, [nullableEndDate, nullableStartDate, onClose, onUpdate]);

  const handleClearClick = useCallback(() => {
    if (defaultValue) {
      onUpdate(null);
    }
  }, [defaultValue, onUpdate]);

  useEffect(() => {
    startDateField.current.select();
  }, []);

  useDidUpdate(() => {
    endDateField.current.select();
  }, [selectTimeFieldState]);

  return (
    <>
      <Popup.Header>
        {t(title, {
          context: 'title',
        })}
      </Popup.Header>
      <Popup.Content>
        <Menu secondary fluid vertical className={styles.menu}>
          <Menu.Item className={styles.menuItem}>
            <Button
              size="mini"
              content={t('action.gotoToday')}
              onClick={() => handleOnOrderDateClick(0, 0)}
            />
            <Button
              size="mini"
              content={t('action.gotoYesterday')}
              onClick={() => handleOnOrderDateClick(0, -1)}
            />
            <Button
              size="mini"
              content={t('action.goto7DaysBefore')}
              onClick={() => handleOnOrderDateClick(0, -7)}
            />
          </Menu.Item>
          <Menu.Item className={styles.menuItem}>
            <Button
              size="mini"
              content={t('action.goto30DaysBefore')}
              onClick={() => handleOnOrderDateClick(0, -30)}
            />
            <Button
              size="mini"
              content={t('action.goto60DaysBefore')}
              onClick={() => handleOnOrderDateClick(0, -60)}
            />
          </Menu.Item>
          <Menu.Item className={styles.menuItem}>
            <Button
              size="mini"
              content={t('action.goto90DaysBefore')}
              onClick={() => handleOnOrderDateClick(0, -90)}
            />
            <Button
              size="mini"
              content={t('action.goto12MonthsBefore')}
              onClick={() => handleOnOrderDateClick(-12, 0)}
            />
          </Menu.Item>
        </Menu>
        <Form onSubmit={handleSubmit}>
          <div className={styles.fieldWrapper}>
            <div className={styles.fieldBox}>
              <div className={styles.text}>{t('common.startDate')}</div>
              <Input
                ref={startDateField}
                name="start"
                value={data.start}
                onChange={handleFieldChange}
              />
            </div>
            <div className={styles.fieldBox}>
              <div className={styles.text}>{t('common.endDate')}</div>
              <Input ref={endDateField} name="end" value={data.end} onChange={handleFieldChange} />
            </div>
          </div>
          <DatePicker
            inline
            disabledKeygroupNavigation
            maxDate={new Date()}
            selectsRange
            startDate={nullableStartDate}
            endDate={nullableEndDate}
            selected={nullableEndDate}
            monthsShown={1}
            onChange={handleDatePickerChange}
          />
          <Button positive content={t('action.save')} />
        </Form>
        {title === 'common.selectArrivalDate' && (
          <Button
            negative
            content={t('action.remove')}
            className={styles.deleteButton}
            onClick={() => handleOnOrderDateClick(0, 1)}
          />
        )}
      </Popup.Content>
    </>
  );
});

OrderDateEditStep.propTypes = {
  defaultValue: PropTypes.objectOf(PropTypes.any),
  title: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

OrderDateEditStep.defaultProps = {
  defaultValue: undefined,
  title: 'common.selectOrderDate',
};

export default withPopup(OrderDateEditStep);
