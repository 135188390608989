import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Input, Search } from 'semantic-ui-react';

import styles from './SearchShipments.module.scss';

const SearchShipments = React.memo(({ searchResults, onSearchShipments }) => {
  const [t] = useTranslation();

  const [focused, setFocused] = useState(false);
  const searchField = useRef(null);
  const { isSubmitting, shipments } = searchResults;

  const results = shipments.map(({ id, code, shipper }) => ({
    id,
    code,
    title: code,
    shipper,
  }));

  const handleWindowKeyDown = useCallback(
    (e) => {
      if (!focused && e.keyCode === 191) {
        e.preventDefault();
        searchField.current.select();
      }
    },
    [focused],
  );

  useEffect(() => {
    window.addEventListener('keydown', handleWindowKeyDown);

    return () => {
      window.removeEventListener('keydown', handleWindowKeyDown);
    };
  }, [handleWindowKeyDown]);

  return (
    <div className={styles.wrapper}>
      <Search
        fluid
        minCharacters={1}
        loading={isSubmitting}
        open={false}
        noResultsMessage={t('common.noResultsMessage')}
        input={
          <Input
            className={styles.input_search}
            fluid
            ref={searchField}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            icon="shipping fast"
            size="small"
            iconPosition="left"
            placeholder={t('common.searchShipment')}
          />
        }
        onSearchChange={onSearchShipments}
        results={results}
      />
    </div>
  );
});

SearchShipments.propTypes = {
  searchResults: PropTypes.objectOf(PropTypes.any).isRequired,
  onSearchShipments: PropTypes.func.isRequired,
};

export default SearchShipments;
