import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Modal, Tab } from 'semantic-ui-react';

import ManagersPane from './ManagersPane';
import LogoPane from './LogoPane';
import CommonPane from './CommonPane';
import GeneralPane from './GeneralPane';

const BusinessSettingsModal = React.memo(
  ({
    name,
    phone,
    hotline,
    page,
    facebook,
    address,
    weightAmountPolicy,
    weightPolicy,
    background,
    backgroundImage,
    isBackgroundImageUpdating,
    managers,
    allUsers,
    countries,
    brands,
    categories,
    accounts,
    customerGroups,
    onUpdate,
    onBackgroundImageUpdate,
    onDelete,
    onManagerCreate,
    onManagerDelete,
    onAccountUpdate,
    onAccountCreate,
    onAccountDelete,
    onBrandUpdate,
    onBrandCreate,
    onBrandDelete,
    onCategoryUpdate,
    onCategoryCreate,
    onCategoryDelete,
    onCountryUpdate,
    onCountryCreate,
    onCountryDelete,
    onCustomerGroupUpdate,
    onCustomerGroupCreate,
    onCustomerGroupDelete,
    onClose,
  }) => {
    const [t] = useTranslation();

    const handleBackgroundUpdate = useCallback(
      (newBackground) => {
        onUpdate({
          background: newBackground,
        });
      },
      [onUpdate],
    );

    const handleBackgroundImageDelete = useCallback(() => {
      onUpdate({
        backgroundImage: null,
      });
    }, [onUpdate]);

    const panes = [
      {
        menuItem: t('common.general', {
          context: 'title',
        }),
        render: () => (
          <GeneralPane
            defaultData={{
              name,
              phone,
              hotline,
              page,
              facebook,
              address,
              weightAmountPolicy,
              weightPolicy,
            }}
            onUpdate={onUpdate}
            onDelete={onDelete}
          />
        ),
      },
      {
        menuItem: t('common.managers', {
          context: 'title',
        }),
        render: () => (
          <ManagersPane
            items={managers}
            allUsers={allUsers}
            onCreate={onManagerCreate}
            onDelete={onManagerDelete}
          />
        ),
      },
      {
        menuItem: t('common.country'),
        render: () => (
          <CommonPane
            items={countries.map((it) => ({
              id: it.id,
              name: it.name,
              code: it.code,
              taxPercent: it.taxPercent,
              exchangeRate: it.exchangeRate,
              weightAmount: it.weightAmount,
              feeAmount: it.feeAmount,
              estimatedArrivalDay: it.estimatedArrivalDay,
            }))}
            columns={[
              { type: 'text', data: 'id' },
              { type: 'text', data: 'name' },
              { type: 'text', data: 'code' },
              {
                type: 'numeric',
                data: 'taxPercent',
                numericFormat: {
                  pattern: '0%',
                },
                validator: /(^[01]$|^(\d+.\d+)$)/,
                allowInvalid: false,
                // invalidCellClassName: 'invalidClass',
                // validator: (value, callback) => {
                //   callback(/^(\d|\.)*$/.test(value));
                //   return false;
                // },
              },
              {
                type: 'numeric',
                data: 'exchangeRate',
                numericFormat: {
                  pattern: '0,0',
                },
                validator: /^[0-9]+$/,
                allowInvalid: false,
              },
              {
                type: 'numeric',
                data: 'weightAmount',
                numericFormat: {
                  pattern: '0,0',
                },
                validator: /^[0-9]+$/,
                allowInvalid: false,
              },
              {
                type: 'numeric',
                data: 'feeAmount',
                numericFormat: {
                  pattern: '0,0',
                },
                validator: /^[0-9]+$/,
                allowInvalid: false,
              },
              {
                type: 'numeric',
                data: 'estimatedArrivalDay',
                validator: /^[0-9]+$/,
                allowInvalid: false,
              },
            ]}
            colHeaders={[
              'ID',
              t('common.name'),
              t('common.code'),
              t('common.taxPercent'),
              t('common.exchangeRate'),
              t('common.weightAmount'),
              t('common.feeAmount'),
              t('common.estimatedArrivalDay'),
            ]}
            // colWidths={[0, 65, 94, 85, 74, 86, 96, 128]}
            onUpdate={onCountryUpdate}
            onCreate={onCountryCreate}
            onDelete={onCountryDelete}
          />
        ),
      },
      {
        menuItem: t('common.brand'),
        render: () => (
          <CommonPane
            items={brands.map((it) => ({
              id: it.id,
              name: it.name,
              url: it.url,
              regex: it.regex,
            }))}
            columns={[
              { type: 'text', data: 'id' },
              { type: 'text', data: 'name', validator: /\w+/, allowInvalid: false },
              { type: 'text', data: 'url' },
              { type: 'text', data: 'regex' },
            ]}
            colHeaders={['ID', t('common.name'), t('common.url'), t('common.regex')]}
            onUpdate={onBrandUpdate}
            onCreate={onBrandCreate}
            onDelete={onBrandDelete}
          />
        ),
      },
      {
        menuItem: t('common.category'),
        render: () => (
          <CommonPane
            items={categories.map((it) => ({
              id: it.id,
              name: it.name,
              description: it.description,
            }))}
            columns={[
              { type: 'text', data: 'id' },
              { type: 'text', data: 'name', validator: /\w+/, allowInvalid: false },
              { type: 'text', data: 'description' },
            ]}
            colHeaders={['ID', t('common.name'), t('common.description')]}
            onUpdate={onCategoryUpdate}
            onCreate={onCategoryCreate}
            onDelete={onCategoryDelete}
          />
        ),
      },
      {
        menuItem: t('common.accountPayment'),
        render: () => (
          <CommonPane
            items={accounts.map((it) => ({
              id: it.id,
              name: it.name,
              accountNumber: it.accountNumber,
              isClosed: it.isClosed,
            }))}
            columns={[
              { type: 'text', data: 'id' },
              { type: 'text', data: 'name', validator: /\w+/, allowInvalid: false },
              { type: 'text', data: 'accountNumber' },
              { type: 'checkbox', data: 'isClosed', className: 'htCenter htMiddle' },
            ]}
            colHeaders={['ID', t('common.name'), t('common.accountNumber'), t('common.isClosed')]}
            onUpdate={onAccountUpdate}
            onCreate={onAccountCreate}
            onDelete={onAccountDelete}
          />
        ),
      },
      {
        menuItem: t('common.customerGroup', {
          context: 'title',
        }),
        render: () => (
          <CommonPane
            items={customerGroups}
            columns={[
              { type: 'text', data: 'id' },
              { type: 'text', data: 'name', validator: /\w+/, allowInvalid: false },
              { type: 'text', data: 'description' },
              { type: 'text', data: 'color' },
              { type: 'checkbox', data: 'isWholesale', className: 'htCenter htMiddle' },
            ]}
            colHeaders={[
              'ID',
              t('common.name'),
              t('common.description'),
              t('common.color'),
              t('common.isWholesale'),
            ]}
            onUpdate={onCustomerGroupUpdate}
            onCreate={onCustomerGroupCreate}
            onDelete={onCustomerGroupDelete}
          />
        ),
      },
      {
        menuItem: t('common.logo', {
          context: 'title',
        }),
        render: () => (
          <LogoPane
            item={background}
            imageCoverUrl={backgroundImage && backgroundImage.url}
            isImageUpdating={isBackgroundImageUpdating}
            onUpdate={handleBackgroundUpdate}
            onImageUpdate={onBackgroundImageUpdate}
            onImageDelete={handleBackgroundImageDelete}
          />
        ),
      },
    ];

    return (
      <Modal open closeIcon closeOnEscape={false} size="small" centered={false} onClose={onClose}>
        <Modal.Content>
          <Tab
            menu={{
              secondary: true,
              pointing: true,
            }}
            panes={panes}
          />
        </Modal.Content>
      </Modal>
    );
  },
);

BusinessSettingsModal.propTypes = {
  name: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  hotline: PropTypes.string.isRequired,
  page: PropTypes.string.isRequired,
  facebook: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  weightAmountPolicy: PropTypes.string.isRequired,
  weightPolicy: PropTypes.string.isRequired,
  /* eslint-disable react/forbid-prop-types */
  background: PropTypes.object,
  backgroundImage: PropTypes.object,
  /* eslint-enable react/forbid-prop-types */
  isBackgroundImageUpdating: PropTypes.bool.isRequired,
  /* eslint-disable react/forbid-prop-types */
  managers: PropTypes.array.isRequired,
  allUsers: PropTypes.array.isRequired,
  countries: PropTypes.array.isRequired,
  brands: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  accounts: PropTypes.array.isRequired,
  customerGroups: PropTypes.array.isRequired,
  /* eslint-enable react/forbid-prop-types */
  onUpdate: PropTypes.func.isRequired,
  onBackgroundImageUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onManagerCreate: PropTypes.func.isRequired,
  onManagerDelete: PropTypes.func.isRequired,
  onAccountUpdate: PropTypes.func.isRequired,
  onAccountCreate: PropTypes.func.isRequired,
  onAccountDelete: PropTypes.func.isRequired,
  onBrandUpdate: PropTypes.func.isRequired,
  onBrandCreate: PropTypes.func.isRequired,
  onBrandDelete: PropTypes.func.isRequired,
  onCategoryUpdate: PropTypes.func.isRequired,
  onCategoryCreate: PropTypes.func.isRequired,
  onCategoryDelete: PropTypes.func.isRequired,
  onCountryUpdate: PropTypes.func.isRequired,
  onCountryCreate: PropTypes.func.isRequired,
  onCountryDelete: PropTypes.func.isRequired,
  onCustomerGroupUpdate: PropTypes.func.isRequired,
  onCustomerGroupCreate: PropTypes.func.isRequired,
  onCustomerGroupDelete: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

BusinessSettingsModal.defaultProps = {
  background: undefined,
  backgroundImage: undefined,
};

export default BusinessSettingsModal;
