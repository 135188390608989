/* eslint-disable no-unused-vars */
import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Button, Input, Search } from 'semantic-ui-react';
import { Statuses } from '../../constants/Enums';

import styles from './SearchProducts.module.scss';

// const DEFAULT_DATA = {
//   name: '',
// };

const SearchProducts = React.memo(
  ({
    customerId,
    // customerCode,
    searchResults,
    onSearchProducts,
    onProductCreate,
    onSellitemCreate,
  }) => {
    const [t] = useTranslation();

    const [query, setQuery] = useState('');
    // const [focused, setFocused] = useState(false);
    const searchField = useRef(null);

    const { isSubmitting, products } = searchResults;

    const handleSearchChange = useCallback(
      (e, data) => {
        if (data && data.value.length >= data.minCharacters) {
          setQuery(data.value);
          onSearchProducts(data.value);
        } else {
          e.preventDefault();
          setQuery('');
        }
      },
      [onSearchProducts],
    );

    const results = products.map(({ id, name, variation, color, url, webCode, sellPrice }) => ({
      id,
      title: name,
      description: `${
        variation && variation.colors ? variation.colors.map((c) => c.name).join(', ') : color || ''
      }, 【${webCode}】${url.substring(0, url.lastIndexOf('/'))}...`,
      price: sellPrice,
    }));

    const resultRenderer = ({ id, title, description, price }) => (
      <div key={id} className={styles.result}>
        <span className={styles.title}>{title}</span>
        <span className={styles.description}>{description}</span>
        <span>
          {t('common.sellPrice')}{' '}
          <span className={styles.price}>
            {parseInt(price, 10).toLocaleString('vi-VN', { style: 'currency', currency: 'VND' })}
          </span>
        </span>
      </div>
    );

    const handleResultSelect = (event, { result }) => {
      const product = products.find((p) => p.id === result.id);

      onSellitemCreate({
        ...product,
        customerId,
        productId: product.id,
        name: result.title,
        // invoice: `${customerCode}-${t('format:longDate', {
        //   postProcess: 'formatDate',
        //   value: new Date(),
        // })}`,
        quantity: 1,
        status: Statuses.NEWORDER.name,
      });
    };

    // const handleWindowKeyDown = useCallback(
    //   (e) => {
    //     if (!focused && e.keyCode === 191) {
    //       e.preventDefault();
    //       searchField.current.select();
    //     }
    //   },
    //   [focused],
    // );

    // useEffect(() => {
    //   window.addEventListener('keydown', handleWindowKeyDown);

    //   return () => {
    //     window.removeEventListener('keydown', handleWindowKeyDown);
    //   };
    // }, [handleWindowKeyDown]);

    return (
      <div className={styles.wrapper}>
        <Search
          fluid
          minCharacters={1}
          className={styles.searchWrapper}
          loading={isSubmitting}
          noResultsMessage={t('common.noResultsMessage')}
          input={
            <Input
              fluid
              ref={searchField}
              // onFocus={() => setFocused(true)}
              // onBlur={() => setFocused(false)}
              icon="cube"
              size="small"
              iconPosition="left"
              // action={{
              //   color: 'teal',
              //   labelPosition: 'right',
              //   icon: 'search',
              //   content: 'Tim',
              // }}
              placeholder={t('common.searchProduct')}
            />
          }
          onResultSelect={handleResultSelect}
          onSearchChange={handleSearchChange}
          resultRenderer={resultRenderer}
          results={results}
          value={query}
        />
        <Button
          className={styles.addProduct}
          onClick={onProductCreate}
          color="teal"
          content={t('common.addProduct', { context: 'title' })}
          icon="add"
        />
      </div>
    );
  },
);

SearchProducts.propTypes = {
  // title: PropTypes.string.isRequired,
  customerId: PropTypes.string.isRequired,
  // customerCode: PropTypes.string.isRequired,
  searchResults: PropTypes.objectOf(PropTypes.any).isRequired,
  onSearchProducts: PropTypes.func.isRequired,
  onProductCreate: PropTypes.func.isRequired,
  onSellitemCreate: PropTypes.func.isRequired,
};

// SearchProducts.defaultProps = {
//   animation: 'push',
//   direction: undefined,
//   visible: true,
// };

export default SearchProducts;
