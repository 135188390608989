/* eslint-disable no-unused-vars */
import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';

import styles from './Dashboard.module.scss';

const DateFilter = ({ defautDateRange, onChangePeriod, onChangeLastPeriod }) => {
  const [t] = useTranslation();

  const currentDate = new Date();
  const lastMonth = new Date(new Date().setDate(new Date().getDate() - 30));

  const [dateRange, setDateRange] = useState(defautDateRange);
  const [startDate, endDate] = dateRange;

  const start = startDate || lastMonth;
  const end = endDate || currentDate;
  const lastPeriodStart = new Date(start.getFullYear(), start.getMonth() - 1, start.getDate());
  const lastPeriodEnd = new Date(end.getFullYear(), end.getMonth() - 1, end.getDate());
  const lastYearPeriodStart = new Date(start.getFullYear() - 1, start.getMonth(), start.getDate());
  const lastYearPeriodEnd = new Date(end.getFullYear() - 1, end.getMonth(), end.getDate());

  const dateCompareOptions = [
    {
      key: 1,
      value: 'lastMonth',
      text: `${t('common.dashboard.lastPeriod')} (${t('format:date', {
        postProcess: 'formatDate',
        value: lastPeriodStart,
      })} - ${t('format:date', { postProcess: 'formatDate', value: lastPeriodEnd })} )`,
    },
    {
      key: 2,
      value: 'lastYear',
      text: `${t('common.dashboard.lastYearPeriod')} (${t('format:date', {
        postProcess: 'formatDate',
        value: lastYearPeriodStart,
      })} - ${t('format:date', { postProcess: 'formatDate', value: lastYearPeriodEnd })} )`,
    },
  ];

  const inputValue = `${t('format:date', {
    postProcess: 'formatDate',
    value: start,
  })} - ${t('format:date', { postProcess: 'formatDate', value: end })}`;

  const [filterDate, setFilterDate] = useState('lastMonth');
  const handleChangeLastPeriod = useCallback(
    (event, dataValue) => {
      setFilterDate(dataValue.value);
      onChangeLastPeriod(dataValue.value);
    },
    [setFilterDate, onChangeLastPeriod],
  );

  const handleChangeDate = useCallback(
    (update) => {
      setDateRange(update);
      onChangePeriod(update);
    },
    [setDateRange, onChangePeriod],
  );

  return (
    <>
      <div className={styles.filterDate}>
        <div className={styles.dateRange}>
          <DatePicker
            dateFormat="dd/MM/yyyy"
            selectsRange
            startDate={startDate}
            endDate={endDate}
            onChange={(update) => {
              handleChangeDate(update);
            }}
            value={inputValue}
          />
        </div>
        {/* <div className={styles.filterLastPeriod}>
          <span className={styles.labelFilterLastPeriod}>
            {t('common.dashboard.compareWith')}:{' '}
          </span>
          <Dropdown
            className={styles.filterDatePeriod}
            selection
            value={filterDate}
            options={dateCompareOptions}
            onChange={handleChangeLastPeriod}
          />
        </div> */}
      </div>
    </>
  );
};

DateFilter.propTypes = {
  defautDateRange: PropTypes.arrayOf(PropTypes.shape).isRequired,
  onChangePeriod: PropTypes.func.isRequired,
  onChangeLastPeriod: PropTypes.func.isRequired,
};

export default DateFilter;
