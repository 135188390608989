import { createSelector } from 'redux-orm';

import orm from '../orm';
import { pathSelector } from './router';
import { currentGroupSelector } from './group';
import { isLocalId } from '../utils/local-id';

export const makeCustomerByIdSelector = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ Customer }, id) => {
      const customerModel = Customer.withId(id);

      if (!customerModel) {
        return customerModel;
      }

      return {
        ...customerModel.ref,
        address: customerModel.address,
        isPersisted: !isLocalId(id),
      };
    },
  );

export const customerByIdSelector = makeCustomerByIdSelector();

export const currentCustomerSelector = createSelector(
  orm,
  (state) => pathSelector(state).customerId,
  ({ Customer }, id) => {
    if (!id) {
      return id;
    }

    const customerModel = Customer.withId(id);

    if (!customerModel) {
      return customerModel;
    }

    return customerModel.ref;
  },
);

export const makeSellitemIdsByCustomerIdSelector = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ Customer }, id) => {
      const customerModel = Customer.withId(id);

      if (!customerModel) {
        return customerModel;
      }

      return customerModel
        .getOrderedFilteredSellitemsModelArray()
        .map((sellitemModel) => sellitemModel.id);
    },
  );

export const sellitemIdsByCustomerIdSelector = makeSellitemIdsByCustomerIdSelector();

export const makeSellitemsByCustomerIdSelector = () =>
  createSelector(
    orm,
    (_, id) => id,
    (state) => currentGroupSelector(state),
    ({ Customer }, customerId, currentGroup) => {
      const customerModel = Customer.withId(customerId);

      if (!customerModel) {
        return customerModel;
      }

      const { filterStatuses } = currentGroup;
      let sellitemsModel = customerModel.getOrderedFilteredSellitemsModelArray();
      if (filterStatuses.length > 0) {
        sellitemsModel = sellitemsModel.filter((sellitemModel) =>
          filterStatuses.includes(sellitemModel.status),
        );
      }
      return sellitemsModel.map((sellitemModel) => {
        const {
          id,
          position,
          name,
          description,
          colorCode,
          sizeCode,
          color,
          size,
          unit,
          quantity,
          quantityCancel,
          webCode,
          webPrice,
          webExtra,
          exchangeRate,
          sellPrice,
          wholesalePrice,
          feeAmount,
          weight,
          weightAmount,
          isWithWeightAmount,
          webOrderNo,
          arrivalDate,
          status,
          timer,
          createdAt,
          updatedAt,
          groupId,
          creatorUserId,
          coverAttachmentId,
          productId,
          stockId,
          invoice,
        } = sellitemModel.ref;
        let p = sellitemModel.product?.ref;
        if (!p) {
          p = {
            id: null,
            name: null,
            description: null,
            url: null,
            imageUrl: null,
            variation: {},
            color: null,
            size: null,
            unit: null,
            webCode: null,
            webPrice: 0,
            webExtra: null,
            sellPrice: 0,
            wholesalePrice: 0,
            feeAmount: 0,
            weight: null,
            weightAmount: null,
            isWithWeightAmount: false,
          };
        }
        return {
          id,
          position,
          name,
          description,
          colorCode,
          sizeCode,
          color,
          size,
          unit,
          quantity,
          quantityCancel,
          webCode,
          webPrice,
          webExtra,
          exchangeRate,
          sellPrice,
          wholesalePrice,
          feeAmount,
          weight,
          weightAmount,
          isWithWeightAmount,
          webOrderNo,
          arrivalDate,
          status,
          timer,
          createdAt,
          updatedAt,
          groupId,
          customerId,
          creatorUserId,
          coverAttachmentId,
          productId,
          stockId,
          invoice,
          creator: sellitemModel.creator?.ref.name,
          customer: sellitemModel.customer.ref.name,
          country: sellitemModel.product?.country?.ref,
          // countryName: sellitemModel.product?.country?.ref.name,
          brandName: sellitemModel.product?.brand?.ref.name,
          product: {
            id: p.id,
            name: p.name,
            description: p.description,
            type: p.type,
            code: p.code,
            url: p.url,
            imageUrl: p.imageUrl,
            variation: p.variation,
            color: p.color,
            size: p.size,
            unit: p.unit,
            webCode: p.webCode,
            webPrice: p.webPrice,
            webExtra: p.webExtra,
            sellPrice: p.sellPrice,
            wholesalePrice: p.wholesalePrice,
            feeAmount: p.feeAmount,
            weight: p.weight,
            weightAmount: p.weightAmount,
            isWithWeightAmount: p.isWithWeightAmount,
          },
        };
      });
    },
  );

export const sellitemsByCustomerIdSelector = makeSellitemsByCustomerIdSelector();

export const customerGroupByIdSelector = createSelector(
  orm,
  (_, id) => id,
  ({ CustomerGroup }, id) => {
    if (!id) {
      return id;
    }

    const customerGroupModel = CustomerGroup.withId(id);

    if (!customerGroupModel) {
      return customerGroupModel;
    }

    return customerGroupModel.ref;
  },
);

export const addressesForCurrentCustomerSelector = createSelector(
  orm,
  (state) => pathSelector(state).customerId,
  ({ Customer }, id) => {
    if (!id) {
      return id;
    }

    const customerModel = Customer.withId(id);

    if (!customerModel) {
      return customerModel;
    }

    return customerModel
      .getOrderedAddressesQuerySet()
      .toRefArray()
      .map((address) => ({
        ...address,
        isPersisted: !isLocalId(address.id),
      }));
  },
);

export const addressesByCustomerIdSelector = createSelector(
  orm,
  (_, id) => id,
  ({ Customer }, id) => {
    if (!id) {
      return id;
    }

    const customerModel = Customer.withId(id);
    if (!customerModel) {
      return customerModel;
    }

    return customerModel
      .getOrderedAddressesQuerySet()
      .toRefArray()
      .map((address) => ({
        ...address,
        isPersisted: !isLocalId(address.id),
      }));
  },
);

export const paymentsForCurrentCustomerSelector = createSelector(
  orm,
  (state) => pathSelector(state).customerId,
  ({ Customer }, id) => {
    if (!id) {
      return id;
    }

    const customerModel = Customer.withId(id);

    if (!customerModel) {
      return customerModel;
    }

    return customerModel
      .getOrderedPaymentsQuerySet()
      .toRefArray()
      .map((payment) => ({
        ...payment,
        isPersisted: !isLocalId(payment.id),
      }));
  },
);

export const paymentsCustomerIdSelector = createSelector(
  orm,
  (_, id) => id,
  ({ Customer }, id) => {
    if (!id) {
      return id;
    }

    const customerModel = Customer.withId(id);

    if (!customerModel) {
      return customerModel;
    }

    return customerModel
      .getOrderedPaymentsQuerySet()
      .toRefArray()
      .map((payment) => ({
        ...payment,
        isPersisted: !isLocalId(payment.id),
      }));
  },
);
