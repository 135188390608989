import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { closeModal, createBusiness } from '../actions/entry';
import BusinessAddModal from '../components/BusinessAddModal';

const mapStateToProps = ({
  ui: {
    businessCreateForm: { data: defaultData, isSubmitting },
  },
}) => ({
  defaultData,
  isSubmitting,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onCreate: createBusiness,
      onClose: closeModal,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(BusinessAddModal);
