/* eslint-disable no-unused-vars */
import React, { useRef, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Image, Grid, Icon, Modal, Table } from 'semantic-ui-react';
import { HotTable, HotColumn } from '@handsontable/react';
import Handsontable from 'handsontable';
import './SellitemModalPrint.css';

const SellitemModalPrint = React.forwardRef(
  (
    {
      business,
      customer,
      defaultAddress,
      finalTotals,
      finalPayments,
      sellitems,
      locationQuery,
      payments,
    },
    ref,
  ) => {
    const [t] = useTranslation();

    const colHeaders = useMemo(
      () => [
        '#',
        t('common.sellitemsInvoice.columnSaler'),
        t('common.sellitemsInvoice.columnProductName'),
        t('common.sellitemsInvoice.columnColor'),
        t('common.sellitemsInvoice.columnImage'),
        t('common.sellitemsInvoice.columnSize'),
        t('common.sellitemsInvoice.columnQuality'),
        t('common.sellitemsInvoice.columnPriceWithoutweightAmount'),
        t('common.sellitemsInvoice.columnWeigh'),
        t('common.sellitemsInvoice.columnweighAmount'),
        t('common.sellitemsInvoice.columnFinalTotal'),
        t('common.sellitemsInvoice.columnStatus'),
        // t('common.sellitemsInvoice.columnNote'),
        locationQuery.v === 'v'
          ? t('common.sellitemsInvoice.columnArriveDay')
          : t('common.sellitemsInvoice.columnExpectedArriveDay'),
      ],
      [locationQuery.v, t],
    );

    const colSummaryHeaders = useMemo(
      () => [
        '#',
        t('common.sellitemsInvoice.columnSumRefNum'),
        t('common.sellitemsInvoice.columnSumTotal'),
        t('common.sellitemsInvoice.columnSumPayment'),
        t('common.sellitemsInvoice.columnSumNote'),
      ],
      [t],
    );

    const sumWeightAmount = (weightAmount, isWithWeightAmount, quantity) => {
      if (quantity > 0) {
        if (isWithWeightAmount > 0) {
          return t('common.withWeightAmount');
        }
        if (weightAmount) {
          return parseInt(weightAmount, 10).toLocaleString('en-GB');
        }
        return t('common.withoutWeightAmount');
      }
      return '';
    };

    return (
      <div className="wrapper" ref={ref}>
        <div className="header">
          {locationQuery.v === 'v' ? (
            <Modal.Header>
              {t('common.sellitemsInvoice.notiArrive')} ({t('common.sellitemsInvoice.arriveCode')}:
              {customer.code}-{format(new Date(), 'yyyyMMdd')})
            </Modal.Header>
          ) : (
            <Modal.Header>
              {t('common.sellitemsInvoice.notiInvoice')} ({t('common.sellitemsInvoice.orderCode')}:
              {customer.code}-{format(new Date(), 'yyyyMMdd')})
            </Modal.Header>
          )}
        </div>
        <div className="content">
          <Modal.Content>
            <Grid className="grid">
              <Grid.Row className="headerPadding">
                <Grid.Column width={4} className="headerPadding">
                  <div
                    className="thumbnail"
                    style={{
                      background:
                        business.background &&
                        business.background.type === 'image' &&
                        business.backgroundImage
                          ? `url("${business.backgroundImage.url}") center / contain no-repeat`
                          : `url("/logo512.png") center / contain no-repeat`,
                    }}
                    // style={{
                    //   background: `url("/KB_Logo.png") center / cover`,
                    // }}
                  />
                </Grid.Column>
                <Grid.Column width={4} className="headerPadding">
                  <div className="headerWrapper">
                    <strong>{`${t('common.sellitemsInvoice.date')}: `}</strong>
                    <span>{format(new Date(), 'dd/MM/yyyy')}</span>
                  </div>
                  <div className="headerWrapper">
                    <strong>{`${t('common.sellitemsInvoice.facebook')}: `}</strong>
                    <span>{customer.name}</span>
                  </div>
                  <div className="headerWrapper">
                    <strong>{`${t('common.sellitemsInvoice.customerID')}: `}</strong>
                    <span>{customer.code}</span>
                  </div>
                  <div className="headerWrapper">
                    <strong>{`${t('common.sellitemsInvoice.wallet')}: `}</strong>
                    <span>{customer.totalDepositRemain.toLocaleString('en-GB')} ₫</span>
                  </div>
                </Grid.Column>
                <Grid.Column width={4} className="headerPadding">
                  <div className="headerWrapper">
                    <strong>{`${t('common.sellitemsInvoice.address')}: `}</strong>
                    <span>
                      {defaultAddress.address}, {defaultAddress.ward}, {defaultAddress.district},{' '}
                      {defaultAddress.province}
                    </span>
                  </div>
                  <div className="headerWrapper">
                    <strong>{`${t('common.sellitemsInvoice.phoneNumber')}: `}</strong>
                    <span>{defaultAddress.phone}</span>
                  </div>
                </Grid.Column>
                <Grid.Column width={4} className="headerPadding">
                  <div className="headerWrapper">
                    <br />
                    <br />
                  </div>
                  <div className="paymentLayout">
                    <div className="paymentTitle">{`${t(
                      'common.sellitemsInvoice.totalPayment',
                    )}: `}</div>
                    <span>{finalTotals.toLocaleString('en-GB')} ₫</span>
                  </div>
                  <div className="paymentLayout">
                    <div className="paymentTitle">{`${t('common.sellitemsInvoice.paid')}: `}</div>
                    <div>{finalPayments.toLocaleString('en-GB')} ₫</div>
                  </div>
                  <div className="paymentLayout">
                    <div className="paymentTitle">{`${t('common.sellitemsInvoice.debt')}: `}</div>
                    <div className="debt">
                      {(finalTotals - finalPayments).toLocaleString('en-GB')} ₫
                    </div>
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <div id="hot-app" className="hotWrapper">
              <Table>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>#</Table.HeaderCell>
                    <Table.HeaderCell>{t('common.sellitemsInvoice.columnSaler')}</Table.HeaderCell>
                    <Table.HeaderCell>
                      {t('common.sellitemsInvoice.countryBrandName')} <br />
                      {t('common.productName')}
                    </Table.HeaderCell>
                    <Table.HeaderCell>{t('common.sellitemsInvoice.columnColor')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('common.sellitemsInvoice.columnImage')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('common.sellitemsInvoice.columnSize')}</Table.HeaderCell>
                    <Table.HeaderCell>
                      {t('common.sellitemsInvoice.columnQuality')}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {t('common.sellitemsInvoice.columnPriceWithoutweightAmount')}
                    </Table.HeaderCell>
                    <Table.HeaderCell>{t('common.sellitemsInvoice.columnWeigh')}</Table.HeaderCell>

                    <Table.HeaderCell>
                      {t('common.sellitemsInvoice.columnweighAmount')}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {t('common.sellitemsInvoice.columnFinalTotal')}
                    </Table.HeaderCell>
                    <Table.HeaderCell>{t('common.sellitemsInvoice.columnStatus')}</Table.HeaderCell>
                    {/* <Table.HeaderCell>{t('common.sellitemsInvoice.columnNote')}</Table.HeaderCell> */}
                    <Table.HeaderCell>
                      {locationQuery.v === 'v'
                        ? t('common.sellitemsInvoice.columnArriveDay')
                        : t('common.sellitemsInvoice.columnExpectedArriveDay')}
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {sellitems.map((sellitem) => (
                    <Table.Row key={sellitem.id + sellitem.index}>
                      <Table.Cell>{sellitem.index}</Table.Cell>
                      <Table.Cell>{sellitem.creator}</Table.Cell>
                      <Table.Cell>
                        {sellitem.countryBrandName} <br /> {sellitem.sellitemName}
                      </Table.Cell>
                      <Table.Cell>{sellitem.color}</Table.Cell>
                      <Table.Cell>
                        {sellitem.imageUrl && <Image src={sellitem.imageUrl} size="mini" />}
                      </Table.Cell>
                      <Table.Cell>{sellitem.size}</Table.Cell>
                      <Table.Cell>{sellitem.quantity}</Table.Cell>
                      <Table.Cell>
                        {sellitem.totalWithoutWeightAmount > 0 &&
                          (sellitem.totalWithoutWeightAmount * 1).toLocaleString('en-GB')}
                      </Table.Cell>
                      <Table.Cell>{sellitem.weight}</Table.Cell>
                      <Table.Cell>
                        {sumWeightAmount(
                          (sellitem.weightAmount * 1).toLocaleString('en-GB'),
                          sellitem.isWithWeightAmount,
                          sellitem.quantity,
                        )}
                      </Table.Cell>
                      <Table.Cell>{(sellitem.finalTotal * 1).toLocaleString('en-GB')}</Table.Cell>
                      <Table.Cell>{sellitem.status && t(`status.${sellitem.status}`)}</Table.Cell>
                      {/* <Table.Cell>{sellitem.note}</Table.Cell> */}
                      <Table.Cell>{sellitem.arrivalDate}</Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </div>
            <div className="hotWrapper">
              <Grid className="grid">
                <Grid.Row className="headerPadding">
                  <Grid.Column width={8} className="headerPadding">
                    <div>{business.weightAmountPolicy}</div>
                    <br />
                    <div>{business.weightPolicy}</div>
                  </Grid.Column>
                  <Grid.Column width={8} className="headerPadding">
                    <div className="noteHeader">{t('common.sellitemsInvoice.paymentInfo')}:</div>
                    <Table>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>#</Table.HeaderCell>
                          <Table.HeaderCell>
                            {t('common.sellitemsInvoice.columnSumRefNum')}
                          </Table.HeaderCell>
                          <Table.HeaderCell>
                            {t('common.sellitemsInvoice.columnSumTotal')}
                          </Table.HeaderCell>
                          <Table.HeaderCell>
                            {t('common.sellitemsInvoice.columnSumPayment')}
                          </Table.HeaderCell>
                          <Table.HeaderCell>
                            {t('common.sellitemsInvoice.columnSumNote')}
                          </Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {payments.map((payment, index) => (
                          <Table.Row key={payment.id + payment.index}>
                            <Table.Cell>{index + 1}</Table.Cell>
                            <Table.Cell>{payment.refNo}</Table.Cell>
                            <Table.Cell>{(payment.amount * 1).toLocaleString('en-GB')}</Table.Cell>
                            <Table.Cell>{payment.accountName}</Table.Cell>
                            <Table.Cell>{payment.name}</Table.Cell>
                          </Table.Row>
                        ))}
                      </Table.Body>
                    </Table>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
          </Modal.Content>
        </div>
      </div>
    );
  },
);

SellitemModalPrint.propTypes = {
  /* eslint-disable react/forbid-prop-types */
  payments: PropTypes.array.isRequired,
  sellitems: PropTypes.arrayOf(PropTypes.any).isRequired,
  finalTotals: PropTypes.number.isRequired,
  finalPayments: PropTypes.number.isRequired,
  locationQuery: PropTypes.object.isRequired,
  defaultAddress: PropTypes.object.isRequired,
  customer: PropTypes.object.isRequired,
  business: PropTypes.object.isRequired,
};

export default SellitemModalPrint;
