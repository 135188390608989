import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { format } from 'date-fns';

import {
  accountsForCurrentBusinessSelector,
  addressesForCurrentCustomerSelector,
  isCurrentUserMemberForCurrentGroupSelector,
  customerByIdSelector,
  // customerGroupByIdSelector,
  // customerIdsForCurrentGroupSelector,
  customerGroupsForCurrentBusinessSelector,
  pathSelector,
  paymentsForCurrentCustomerSelector,
  sellitemsByCustomerIdSelector,
  countryByProductIdSelector,
} from '../selectors';
import {
  createAddressInCurrentCustomer,
  createPayment,
  createSellitem,
  deleteAddress,
  deletePayment,
  openProductAddModal,
  searchProducts,
  updateAddress,
  updateCustomer,
  updatePayment,
  updateSellitems,
} from '../actions/entry';
import Customers from '../components/Customers';
import { Statuses } from '../constants/Enums';
// import { addTodoSellitem } from '../actions';

const mapStateToProps = (state) => {
  const { customerId } = pathSelector(state);
  const isCurrentUserMember = isCurrentUserMemberForCurrentGroupSelector(state);
  // const customerIds = customerIdsForCurrentGroupSelector(state);
  const accounts = accountsForCurrentBusinessSelector(state);
  const customerGroups = customerGroupsForCurrentBusinessSelector(state);

  let sellitems = [];
  let customer = {};
  let customerGroup = {};
  let addresses = [];
  let payments = [];
  if (customerId) {
    sellitems = sellitemsByCustomerIdSelector(state, customerId);
    payments = paymentsForCurrentCustomerSelector(state);
    // console.log(sellitems);
    sellitems = sellitems.map((si) => {
      const { estimatedArrivalDay } = countryByProductIdSelector(state, si.productId) || {
        estimatedArrivalDay: 0,
      };
      const finalTotal = si.quantity * si.sellPrice + si.weightAmount * 1;
      const payment = payments
        .filter(({ sellitemId }) => sellitemId === si.id)
        .reduce((sum, current) => sum + parseInt(current.amount, 10), 0);
      const estimatedArrivalDate = new Date(si.createdAt);
      estimatedArrivalDate.setDate(estimatedArrivalDate.getDate() + estimatedArrivalDay);

      return {
        ...si,
        // member: users.find((u) => u.id === si.creatorUserId).name,
        imageUrl: si.product?.imageUrl,
        payment: payment === 0 ? null : payment,
        remainTotal: finalTotal - payment,
        finalTotal,
        estimatedArrivalDate,
        arrivalDate: si.arrivalDate && format(si.arrivalDate, 'd/M/yyyy'),
        status: Statuses[si.status?.toUpperCase()].text,
      };
    });

    customer = customerByIdSelector(state, customerId);
    addresses = addressesForCurrentCustomerSelector(state);
    // customerGroup = customerGroupByIdSelector(state, customer.customerGroupId);
    customerGroup = customerGroups.find(({ id }) => id === customer.customerGroupId);
  }

  const { products } = state;

  console.log('CustomersContainer');

  return {
    accounts,
    addresses,
    customer: {
      ...customer,
      group: customerGroup,
    },
    // customerIds,
    customerGroups,
    payments,
    sellitems,
    canEdit: isCurrentUserMember,
    isPersisted: customer.isPersisted || false,
    isSellitemModalOpened: false,
    searchProductResults: products || { isSubmitting: false, products: [], query: '' },
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onAddressCreate: createAddressInCurrentCustomer,
      onAddressDelete: deleteAddress,
      onAddressUpdate: updateAddress,
      onPaymentCreate: (customerId, data) => createPayment(customerId, data),
      onPaymentUpdate: updatePayment,
      onPaymentDelete: deletePayment,
      onProductCreate: openProductAddModal,
      onSearchProducts: (query) => searchProducts(query),
      onSellitemCreate: (data) => createSellitem(data.customerId, data),
      onUpdateSellitems: (id, data) => updateSellitems(id, data),
      onUpdate: (id, data) => updateCustomer(id, data),
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Customers);
