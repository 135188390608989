import EntryActionTypes from '../../constants/EntryActionTypes';

// eslint-disable-next-line import/prefer-default-export
export const fetchSellitemsInCurrentGroup = (data) => ({
  type: EntryActionTypes.SELLITEMS_IN_CURRENT_GROUP_FETCH,
  payload: { data },
});

export const changeOrderDateToFilterInCurrentGroup = (orderDate) => ({
  type: EntryActionTypes.ORDER_DATE_TO_FILTER_IN_CURRENT_GROUP_CHANGE,
  payload: {
    orderDate,
  },
});

export const changeArrivalDateToFilterInCurrentGroup = (arrivalDate) => ({
  type: EntryActionTypes.ARRIVAL_DATE_TO_FILTER_IN_CURRENT_GROUP_CHANGE,
  payload: {
    arrivalDate,
  },
});

export const changeWebOrderNoToFilterInCurrentGroup = (webOrderNo) => ({
  type: EntryActionTypes.WEB_ORDER_NO_TO_FILTER_IN_CURRENT_GROUP_CHANGE,
  payload: {
    webOrderNo,
  },
});

export const changeArrivalDateForShipmentPageToFilterInCurrentGroup = (arrivalDate) => ({
  type: EntryActionTypes.ARRIVAL_DATE_FOR_SHIPMENT_TO_FILTER_IN_CURRENT_GROUP_CHANGE,
  payload: {
    arrivalDate,
  },
});

export const updateSellitems = (id, data) => ({
  type: EntryActionTypes.SELLITEMS_UPDATE,
  payload: {
    id,
    data,
  },
});

export const handleSellitemsUpdate = (sellitems) => ({
  type: EntryActionTypes.SELLITEMS_UPDATE_HANDLE,
  payload: {
    sellitems,
  },
});
