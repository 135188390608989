import React, { useCallback, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Label, Segment } from 'semantic-ui-react';
import HotTable, { HotColumn } from '@handsontable/react';
import Handsontable from 'handsontable';

import AddPopup from './AddPopup';
import SearchCustomers from '../SearchCustomers';
import ActionsPopup from './ActionsPopup';
import { ReactComponent as DotIcon } from '../../assets/images/dots-vertical.svg';
import Paths from '../../constants/Paths';

import styles from './LeftSidebar.module.scss';

const LeftSidebar = React.memo(
  ({
    customer,
    customers,
    customerGroups,
    searchCustomerResults,
    canEdit,
    onCustomerCreate,
    onSearchCustomers,
  }) => {
    const history = useHistory();

    const hotTableRef = useRef();

    const handleResultSelect = useCallback(
      ({ id }) => {
        history.push(Paths.CUSTOMERS.replace(':id', id));
      },
      [history],
    );

    console.log('Customer LeftSidebar component');

    const afterSelection = useCallback(
      (row) => {
        const id = hotTableRef.current.hotInstance.getDataAtRowProp(row, 'id');
        if (id !== customer.id) {
          history.push(Paths.CUSTOMERS.replace(':id', id));
        }
      },
      [customer?.id, history],
    );

    const itemRenderer = (instance, td, row, col, prop, value) => {
      /* eslint-disable no-param-reassign */
      if (value) {
        const id = instance.getDataAtRowProp(row, 'id');
        const code = instance.getDataAtRowProp(row, 'code');
        const phone = instance.getDataAtRowProp(row, 'phone');
        const customerGroupId = instance.getDataAtRowProp(row, 'customerGroupId');
        const customerGroup = customerGroups.find((cg) => cg.id === customerGroupId);

        const div = document.createElement('div');
        div.classList.add(styles.item);
        ReactDOM.render(
          <>
            <Label
              size="mini"
              attached="top right"
              className={styles.group}
              style={{ backgroundColor: customerGroup?.color }}
            >
              {customerGroup ? customerGroup.name : '+'}
            </Label>
            {value}
            <br />
            <span className={styles.code}>{code}</span>
            <span className={styles.phone}>{phone}</span>
          </>,
          div,
        );
        Handsontable.dom.empty(td);
        td.appendChild(div);
        if (id === customer?.id) td.classList.add(styles.current);
      }
      /* eslint-enable no-param-reassign */
      return td;
    };

    useEffect(() => {
      if (customer) onSearchCustomers(customer.code);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <Segment className={styles.sidebarLeft}>
        <div className={styles.wrapperSearchCustomers}>
          <SearchCustomers
            customerSelected={[]}
            searchResults={searchCustomerResults}
            onSearchCustomers={onSearchCustomers}
            onCustomerSelected={handleResultSelect}
          />
          {canEdit && (
            <>
              <AddPopup onCreate={onCustomerCreate} onSearchCustomers={onSearchCustomers}>
                <Button circular size="mini" icon="user add" className={styles.addCustomerButton} />
              </AddPopup>
              <ActionsPopup>
                <div className={styles.actions}>
                  <DotIcon className={styles.dotIcon} />
                </div>
              </ActionsPopup>
            </>
          )}
        </div>
        <div className={styles.wrapperCustomers}>
          <HotTable
            ref={hotTableRef}
            data={customers}
            height="100%"
            stretchH="all"
            autoColumnSize={false}
            autoRowSize={false}
            disableVisualSelection
            readOnly
            colHeaders={false}
            rowHeaders={false}
            rowHeights={43}
            enterMoves={{ col: 1, row: 0 }}
            selectionMode="single"
            afterSelection={afterSelection}
            // dropdownMenu={['filter_by_value', 'filter_action_bar']}
            // filters
            licenseKey="non-commercial-and-evaluation"
          >
            <HotColumn data="name" renderer={itemRenderer} />
          </HotTable>
        </div>
      </Segment>
    );
  },
);

LeftSidebar.propTypes = {
  customer: PropTypes.objectOf(PropTypes.any).isRequired,
  customers: PropTypes.arrayOf(PropTypes.any).isRequired,
  customerGroups: PropTypes.arrayOf(PropTypes.any).isRequired,
  searchCustomerResults: PropTypes.objectOf(PropTypes.any).isRequired,
  canEdit: PropTypes.bool.isRequired,
  onCustomerCreate: PropTypes.func.isRequired,
  onSearchCustomers: PropTypes.func.isRequired,
};

export default LeftSidebar;
