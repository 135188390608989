import { Model, attr, fk } from 'redux-orm';

import ActionTypes from '../constants/ActionTypes';

export default class extends Model {
  static modelName = 'Address';

  static fields = {
    id: attr(),
    name: attr(),
    phone: attr(),
    province: attr(),
    district: attr(),
    ward: attr(),
    address: attr(),
    type: attr(),
    customerId: fk({
      to: 'Customer',
      as: 'customer',
      relatedName: 'addresses',
    }),
  };

  static reducer({ type, payload }, Address) {
    switch (type) {
      case ActionTypes.LOCATION_CHANGE_HANDLE:
      case ActionTypes.CORE_INITIALIZE:
      case ActionTypes.BUSINESS_MANAGER_CREATE_HANDLE:
      case ActionTypes.GROUP_MEMBERSHIP_CREATE_HANDLE:
        if (payload.addresses) {
          payload.addresses.forEach((address) => {
            Address.upsert(address);
          });
        }

        break;
      case ActionTypes.CUSTOMERS_FETCH__SUCCESS:
      case ActionTypes.SHIPMENT_FETCH__SUCCESS:
        if (payload.addresses) {
          payload.addresses.forEach((address) => {
            if (Address.idExists(address.id)) Address.withId(address.id).update(address);
            else Address.upsert(address);
          });
        }

        break;
      case ActionTypes.SOCKET_RECONNECT_HANDLE:
        Address.all().delete();

        if (payload.addresses) {
          payload.addresses.forEach((address) => {
            Address.upsert(address);
          });
        }

        break;
      case ActionTypes.SELLITEMS_FETCH__SUCCESS:
      case ActionTypes.GROUP_FETCH__SUCCESS:
        payload.addresses.forEach((address) => {
          Address.upsert(address);
        });

        break;
      case ActionTypes.ADDRESS_CREATE:
      case ActionTypes.ADDRESS_CREATE_HANDLE:
      case ActionTypes.ADDRESS_UPDATE__SUCCESS:
      case ActionTypes.ADDRESS_UPDATE_HANDLE:
        Address.upsert(payload.address);

        break;
      case ActionTypes.ADDRESS_CREATE__SUCCESS:
        Address.withId(payload.localId).delete();
        Address.upsert(payload.address);

        break;
      case ActionTypes.ADDRESS_UPDATE:
        Address.withId(payload.id).update(payload.data);

        break;
      case ActionTypes.ADDRESS_DELETE:
        Address.withId(payload.id).delete();

        break;
      case ActionTypes.ADDRESS_DELETE__SUCCESS:
      case ActionTypes.ADDRESS_DELETE_HANDLE: {
        const addressModel = Address.withId(payload.address.id);

        if (addressModel) {
          addressModel.delete();
        }

        break;
      }
      default:
    }
  }
}
