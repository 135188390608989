import EntryActionTypes from '../../constants/EntryActionTypes';

export const createProduct = (data) => ({
  type: EntryActionTypes.PRODUCT_CREATE,
  payload: {
    data,
  },
});

export const handleProductCreate = (product) => ({
  type: EntryActionTypes.PRODUCT_CREATE_HANDLE,
  payload: {
    product,
  },
});

export const updateProduct = (id, data) => ({
  type: EntryActionTypes.PRODUCT_UPDATE,
  payload: {
    id,
    data,
  },
});

export const updateCurrentProduct = (data) => ({
  type: EntryActionTypes.CURRENT_PRODUCT_UPDATE,
  payload: {
    data,
  },
});

export const handleProductUpdate = (product) => ({
  type: EntryActionTypes.PRODUCT_UPDATE_HANDLE,
  payload: {
    product,
  },
});

export const deleteProduct = (id) => ({
  type: EntryActionTypes.PRODUCT_DELETE,
  payload: {
    id,
  },
});

export const deleteCurrentProduct = () => ({
  type: EntryActionTypes.CURRENT_PRODUCT_DELETE,
  payload: {},
});

export const handleProductDelete = (product) => ({
  type: EntryActionTypes.PRODUCT_DELETE_HANDLE,
  payload: {
    product,
  },
});
