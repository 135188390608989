/* eslint-disable no-unused-vars */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Label } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import Paths from '../../constants/Paths';

const CustomerRenderer = React.memo(
  ({ row, col, prop, cellProperties, value, showFacebook, originPath }) => {
    // The avaiable renderer-related props are:
    // - row (row index)
    // - col (column index)
    // - prop (column property name)
    // - TD (the HTML cell element)
    // - cellProperties (the cellProperties object for the edited cell)
    // console.log(props);
    const [t] = useTranslation();

    const sellitemId = cellProperties?.instance.getDataAtRowProp(row, 'id');
    const customerId = cellProperties?.instance.getDataAtRowProp(row, 'customerId');
    // const customerFacebook = cellProperties?.instance.getDataAtRowProp(row, 'customerFacebook');
    // const date = format(
    //   new Date(cellProperties?.instance.getDataAtRowProp(row, 'createdAt')),
    //   'yyyyMMdd',
    // );
    // date = date ? format(date, 'yyyyMMdd') : date;
    // console.log('date:', date);
    // const handleClick = useCallback(() => {
    //   if (document.activeElement) {
    //     document.activeElement.blur();
    //   }
    // }, []);

    return (
      <>
        <span style={{ whiteSpace: 'pre' }}>{value}</span>
        <div style={{ display: 'block', paddingTop: '-2px' }}>
          <Link to={Paths.SELLITEMS.replace(':id', `${sellitemId}?v=d&o=${originPath}`)}>
            <Label
              // onClick={onShowInvoice}
              content={t('common.sellitemsInvoice.saleItemInvoice')}
              color="green"
              size="mini"
            />
          </Link>
          <Link to={Paths.SELLITEMS.replace(':id', `${sellitemId}?v=v&o=${originPath}`)}>
            <Label
              // onClick={onShowArrive}
              content={t('common.sellitemsInvoice.saleItemArrive')}
              color="blue"
              size="mini"
              style={{ marginLeft: '4px' }}
            />
          </Link>
          {showFacebook && (
            <Link to={Paths.CUSTOMERS.replace(':id', customerId)}>
              <Label
                // onClick={onShowArrive}
                content={t('common.view')}
                basic
                size="mini"
                style={{ marginLeft: '4px' }}
              />
            </Link>
          )}
        </div>
        {/* <LabelsPopup
          items={[]}
          currentIds={[]}
          title="common.filterByLabels_title"
          // onSelect={onLabelAdd}
          // onDeselect={onLabelRemove}
          // onCreate={onLabelCreate}
          // onUpdate={onLabelUpdate}
          // onDelete={onLabelDelete}
        >
          <Label content={value} color="green" style={{ margin: '0', padding: '5px 4px' }} />
        </LabelsPopup> */}
      </>
    );
  },
);

CustomerRenderer.propTypes = {
  row: PropTypes.number,
  col: PropTypes.number,
  prop: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  cellProperties: PropTypes.objectOf(PropTypes.any),
  value: PropTypes.string,
  showFacebook: PropTypes.bool,
  originPath: PropTypes.string,
};

CustomerRenderer.defaultProps = {
  row: undefined,
  col: undefined,
  prop: undefined,
  value: undefined,
  cellProperties: undefined,
  showFacebook: false,
  originPath: 'g',
};

export default CustomerRenderer;
