/* eslint-disable import/prefer-default-export */
import EntryActionTypes from '../../constants/EntryActionTypes';

export const fetchDashboard = (startDate, endDate) => ({
  type: EntryActionTypes.DASHBOARD_FETCH,
  payload: {
    startDate,
    endDate,
  },
});
