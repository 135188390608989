import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';
import React, { useCallback } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';
import { withPopup } from '../../../lib/popup';
import { Popup } from '../../../lib/custom-ui';

import styles from './WarehouseActions.module.scss';
import globalStyles from '../../../styles.module.scss';

const ProductsPopup = React.memo(({ items, currentIds, title, onSelect, onDeselect, onBack }) => {
  const [t] = useTranslation();

  const handleToggleClick = useCallback(
    (isActive) => {
      if (isActive) {
        onDeselect();
      } else {
        onSelect();
      }
    },
    [onSelect, onDeselect],
  );

  return (
    <>
      <Popup.Header onBack={onBack}>{t(title)}</Popup.Header>
      <Popup.Content>
        {items.map((item) => (
          <div className={styles.wrapper}>
            <Button
              fluid
              content={item.name}
              active={currentIds.includes(item.id)}
              disabled={!item.isPersisted}
              className={classNames(
                styles.labelButton,
                currentIds.includes(item.id) && styles.labelButtonActive,
                globalStyles[`background${upperFirst(camelCase(item.color))}`],
              )}
              onClick={handleToggleClick(currentIds.includes(item.id))}
            />
          </div>
        ))}
      </Popup.Content>
    </>
  );
});

ProductsPopup.propTypes = {
  /* eslint-disable react/forbid-prop-types */
  items: PropTypes.array.isRequired,
  currentIds: PropTypes.array.isRequired,
  /* eslint-enable react/forbid-prop-types */
  title: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  onDeselect: PropTypes.func.isRequired,
  onBack: PropTypes.func,
};

ProductsPopup.defaultProps = {
  title: 'common.labels',
  onBack: undefined,
};

export default withPopup(ProductsPopup);
