const ROOT = '/';
const LOGIN = '/login';
const BUSINESSES = '/b/:id';
const GROUPS = '/g/:id';
const PRODUCTS = '/p/:id';
const CUSTOMERS = '/c/:id';
const SHIPMENTS = '/s/:id';
const SELLITEMS = '/i/:id';
const DASHBOARD = '/d/:id';
const WAREHOUSE = '/w/:id';
const SHIPMENTPAGE = '/m/:id';

export default {
  ROOT,
  LOGIN,
  BUSINESSES,
  GROUPS,
  PRODUCTS,
  CUSTOMERS,
  SHIPMENTS,
  SELLITEMS,
  DASHBOARD,
  WAREHOUSE,
  SHIPMENTPAGE,
};
