import router from './router';
import socket from './socket';
import login from './login';
import core from './core';
import modal from './modal';
import user from './user';
import business from './business';
import businessManager from './business-manager';
import country from './country';
import brand from './brand';
import category from './category';
import account from './account';
import customerGroup from './customer-group';
import group from './group';
import groupMembership from './group-membership';
import importGroup from './import';
import label from './label';
import customer from './customer';
import customers from './customers';
import address from './address';
import product from './product';
import products from './products';
import sellitems from './sellitems';
import sellitem from './sellitem';
import shipment from './shipment';
import shipline from './shipline';
import shipments from './shipments';
import payment from './payment';
import attachment from './attachment';
import actions from './actions';
import action from './action';
import commentAction from './comment-action';
import notification from './notification';
import stock from './stock';
import status from './status';
import dashboard from './dashboard';
import facebook from './facebook';

export default [
  router,
  socket,
  login,
  core,
  modal,
  user,
  business,
  businessManager,
  country,
  brand,
  category,
  account,
  customerGroup,
  group,
  groupMembership,
  importGroup,
  label,
  customer,
  customers,
  address,
  product,
  products,
  sellitems,
  sellitem,
  shipments,
  shipment,
  shipline,
  payment,
  attachment,
  actions,
  action,
  commentAction,
  notification,
  stock,
  status,
  dashboard,
  facebook,
];
