import socket from './socket';

/* Actions */

const createDeposit = (customerId, data, headers) =>
  socket.post(`/customers/${customerId}/deposits`, data, headers);

const createPayment = (customerId, data, headers) =>
  socket.post(`/customers/${customerId}/payments`, data, headers);

const updatePayment = (id, data, headers) => socket.patch(`/payments/${id}`, data, headers);

const deletePayment = (id, headers) => socket.delete(`/payments/${id}`, undefined, headers);

export default {
  createDeposit,
  createPayment,
  updatePayment,
  deletePayment,
};
