import { call, put, select } from 'redux-saga/effects';

import { goToShipmentService } from './router';
import request from '../request';
import { pathSelector } from '../../../selectors';
import {
  createShipment,
  deleteShipment,
  fetchShipment,
  handleShipmentCreate,
  handleShipmentDelete,
  handleShipmentUpdate,
  updateShipment,
} from '../../../actions';
import api from '../../../api';
import { createLocalId } from '../../../utils/local-id';

export function* createShipmentService(groupId, data) {
  const nextData = {
    ...data,
  };

  const localId = yield call(createLocalId);

  yield put(
    createShipment({
      ...nextData,
      groupId,
      id: localId,
    }),
  );

  let shipment;
  try {
    ({ item: shipment } = yield call(request, api.createShipment, groupId, nextData));
  } catch (error) {
    yield put(createShipment.failure(localId, error));
    return;
  }

  yield put(createShipment.success(localId, shipment));
  yield call(goToShipmentService, shipment.id);
}

export function* createShipmentInCurrentGroupService(data) {
  const { groupId } = yield select(pathSelector);

  yield call(createShipmentService, groupId, data);
}

export function* handleShipmentCreateService(shipment) {
  yield put(handleShipmentCreate(shipment));
}

export function* fetchShipmentService(id) {
  yield put(fetchShipment(id));

  let shipment;
  let shiplines;
  let sellitems;
  let addresses;
  let products;
  let payments;

  try {
    ({
      item: shipment,
      included: { shiplines, sellitems, addresses, products, payments },
    } = yield call(request, api.getShipment, id));
  } catch (error) {
    yield put(fetchShipment.failure(id, error));
    return;
  }
  yield put(fetchShipment.success(shipment, shiplines, sellitems, addresses, products, payments));
}

export function* updateShipmentService(id, data) {
  yield put(updateShipment(id, data));

  let shipment;
  try {
    ({ item: shipment } = yield call(request, api.updateShipment, id, data));
  } catch (error) {
    yield put(updateShipment.failure(id, error));
    return;
  }

  yield put(updateShipment.success(shipment));
}

export function* handleShipmentUpdateService(shipment) {
  yield put(handleShipmentUpdate(shipment));
}

export function* deleteShipmentService(id) {
  yield put(deleteShipment(id));

  let shipment;
  try {
    ({ item: shipment } = yield call(request, api.deleteShipment, id));
  } catch (error) {
    yield put(deleteShipment.failure(id, error));
    return;
  }

  yield put(deleteShipment.success(shipment));
}

export function* handleShipmentDeleteService(label) {
  yield put(handleShipmentDelete(label));
}
