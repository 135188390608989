const SERVER_BASE_URL =
  process.env.REACT_APP_SERVER_BASE_URL ||
  (process.env.NODE_ENV === 'production' ? '' : 'http://localhost:1337');

const POSITION_GAP = 65535;

const SELLITEMS_LIMIT = 1000;

const PRODUCTS_LIMIT = 1000;

const ACTIONS_LIMIT = 10;

const IMGUR_CONFIG = {
  refresh_token: '42200584ad5e06bce04d6e182baeeca6016b46e1',
  client_id: '1a8afdab1e65d76',
  client_secret: 'b8736429e99b20ca63f91818fb8e62abba6916d0',
};

const EDITOR_OPTIONS = {
  autofocus: true,
  spellChecker: false,
  status: false,
  toolbar: [
    'bold',
    'italic',
    'heading',
    'strikethrough',
    '|',
    'quote',
    'unordered-list',
    'ordered-list',
    'table',
    '|',
    'link',
    'image',
    '|',
    'undo',
    'redo',
    // '|',
    // {
    //   name: 'audio',
    //   action: handleText2Speech,
    //   className: 'fa fa-volume-up',
    //   title: 'Chuyen doi thanh am thanh',
    // },
    // '|',
    // 'guide',
  ],
};

export default {
  SERVER_BASE_URL,
  POSITION_GAP,
  SELLITEMS_LIMIT,
  PRODUCTS_LIMIT,
  ACTIONS_LIMIT,
  EDITOR_OPTIONS,
  IMGUR_CONFIG,
};
