import { call, put, select } from 'redux-saga/effects';

import { goToBusinessService } from './router';
import request from '../request';
import { pathSelector } from '../../../selectors';
import {
  createBrand,
  deleteBrand,
  handleBrandCreate,
  handleBrandDelete,
  handleBrandUpdate,
  updateBrand,
  addBrandToGroupFilter,
  removeBrandFromGroupFilter,
} from '../../../actions';
import api from '../../../api';
import { createLocalId } from '../../../utils/local-id';

export function* createBrandService(businessId, data) {
  const nextData = {
    ...data,
  };

  const localId = yield call(createLocalId);

  yield put(
    createBrand({
      ...nextData,
      businessId,
      id: localId,
    }),
  );

  let brand;

  try {
    ({ item: brand } = yield call(request, api.createBrand, businessId, nextData));
  } catch (error) {
    yield put(createBrand.failure(localId, error));
    return;
  }

  yield put(createBrand.success(localId, brand));
}

export function* createBrandInCurrentBusinessService(data) {
  const { businessId } = yield select(pathSelector);

  yield call(createBrandService, businessId, data);
}

export function* handleBrandCreateService(brand) {
  yield put(handleBrandCreate(brand));
}

export function* updateBrandService(id, data) {
  yield put(updateBrand(id, data));

  let brand;
  try {
    ({ item: brand } = yield call(request, api.updateBrand, id, data));
  } catch (error) {
    yield put(updateBrand.failure(id, error));
    return;
  }

  yield put(updateBrand.success(brand));
}

export function* handleBrandUpdateService(brand) {
  yield put(handleBrandUpdate(brand));
}

export function* deleteBrandService(id) {
  const { brandId, businessId } = yield select(pathSelector);

  if (id === brandId) {
    yield call(goToBusinessService, businessId);
  }

  yield put(deleteBrand(id));

  let brand;
  try {
    ({ item: brand } = yield call(request, api.deleteBrand, id));
  } catch (error) {
    yield put(deleteBrand.failure(id, error));
    return;
  }

  yield put(deleteBrand.success(brand));
}

export function* handleBrandDeleteService(brand) {
  const { brandId, businessId } = yield select(pathSelector);

  if (brand.id === brandId) {
    yield call(goToBusinessService, businessId);
  }

  yield put(handleBrandDelete(brand));
}

export function* addBrandToGroupFilterService(id, groupId) {
  yield put(addBrandToGroupFilter(id, groupId));
}

export function* addBrandToFilterInCurrentGroupService(id) {
  const { groupId } = yield select(pathSelector);

  yield call(addBrandToGroupFilterService, id, groupId);
}

export function* removeBrandFromGroupFilterService(id, groupId) {
  yield put(removeBrandFromGroupFilter(id, groupId));
}

export function* removeBrandFromFilterInCurrentGroupService(id) {
  const { groupId } = yield select(pathSelector);

  yield call(removeBrandFromGroupFilterService, id, groupId);
}
