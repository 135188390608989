import { Model, attr, fk } from 'redux-orm';

import ActionTypes from '../constants/ActionTypes';

export default class extends Model {
  static modelName = 'BusinessManager';

  static fields = {
    id: attr(),
    businessId: fk({
      to: 'Business',
      as: 'business',
      relatedName: 'managers',
    }),
    userId: fk({
      to: 'User',
      as: 'user',
      relatedName: 'businessManagers',
    }),
  };

  static reducer({ type, payload }, BusinessManager) {
    switch (type) {
      case ActionTypes.SOCKET_RECONNECT_HANDLE:
        BusinessManager.all().delete();

        payload.businessManagers.forEach((businessManager) => {
          BusinessManager.upsert(businessManager);
        });

        break;
      case ActionTypes.CORE_INITIALIZE:
      case ActionTypes.BUSINESS_CREATE__SUCCESS:
      case ActionTypes.BUSINESS_CREATE_HANDLE:
        payload.businessManagers.forEach((businessManager) => {
          BusinessManager.upsert(businessManager);
        });

        break;
      case ActionTypes.BUSINESS_MANAGER_CREATE:
        BusinessManager.upsert(payload.businessManager);

        break;
      case ActionTypes.BUSINESS_MANAGER_CREATE__SUCCESS:
        BusinessManager.withId(payload.localId).delete();
        BusinessManager.upsert(payload.businessManager);

        break;
      case ActionTypes.BUSINESS_MANAGER_CREATE_HANDLE:
        BusinessManager.upsert(payload.businessManager);

        if (payload.businessManagers) {
          payload.businessManagers.forEach((businessManager) => {
            BusinessManager.upsert(businessManager);
          });
        }

        break;
      case ActionTypes.BUSINESS_MANAGER_DELETE:
        BusinessManager.withId(payload.id).delete();

        break;
      case ActionTypes.BUSINESS_MANAGER_DELETE__SUCCESS:
      case ActionTypes.BUSINESS_MANAGER_DELETE_HANDLE: {
        const businessManagerModel = BusinessManager.withId(payload.businessManager.id);

        if (businessManagerModel) {
          businessManagerModel.delete();
        }

        break;
      }
      case ActionTypes.GROUP_MEMBERSHIP_CREATE_HANDLE:
        if (payload.businessManagers) {
          payload.businessManagers.forEach((businessManager) => {
            BusinessManager.upsert(businessManager);
          });
        }

        break;
      default:
    }
  }
}
