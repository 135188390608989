import { call, put, select } from 'redux-saga/effects';

import request from '../request';
import { pathSelector } from '../../../selectors';
import { createStock, updateStock } from '../../../actions';
import api from '../../../api';
import { createLocalId } from '../../../utils/local-id';

export function* createStockService(productId, data) {
  const nextData = {
    ...data,
  };

  const localId = yield call(createLocalId);

  yield put(
    createStock({
      ...nextData,
      productId,
      id: localId,
    }),
  );

  let stock;
  try {
    ({ item: stock } = yield call(request, api.createStock, productId, nextData));
  } catch (error) {
    yield put(createStock.failure(localId, error));
    return;
  }

  yield put(createStock.success(localId, productId, stock));
}

export function* createStockInCurrentProductService(data) {
  const { productId } = yield select(pathSelector);

  yield call(createStockService, productId, data);
}

export function* updateStockService(id, data) {
  yield put(updateStock(id, data));

  let stock;
  try {
    ({ item: stock } = yield call(request, api.updateStock, id, data));
  } catch (error) {
    yield put(updateStock.failure(id, error));
    return;
  }

  yield put(updateStock.success(stock));
}
