import socket from './socket';
import http from './http';
import { transformSellitem } from './sellitems';

/* Actions */

const createCustomer = (groupId, data, headers) =>
  socket.post(`/groups/${groupId}/customers`, data, headers);

const getCustomer = (id, headers) =>
  socket.get(`/customers/${id}`, undefined, headers).then((body) => ({
    ...body,
    included: {
      ...body.included,
      sellitems: body.included.sellitems.map(transformSellitem),
    },
  }));

const updateCustomer = (id, data, headers) => socket.patch(`/customers/${id}`, data, headers);

const deleteCustomer = (id, headers) => socket.delete(`/customers/${id}`, undefined, headers);

const searchCustomers = (groupId, data, headers) =>
  socket.get(`/groups/${groupId}/search-customers`, data, headers).then((body) => ({
    ...body,
    included: {
      ...body.included,
      sellitems: body.included.sellitems.map(transformSellitem),
    },
  }));

const importCustomers = (groupId, data, headers) =>
  http.post(`/groups/${groupId}/import-customers`, data, headers);

export default {
  createCustomer,
  getCustomer,
  updateCustomer,
  deleteCustomer,
  searchCustomers,
  importCustomers,
};
