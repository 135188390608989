import { createSelector } from 'redux-orm';

import orm from '../orm';

export const allAddressSelector = createSelector(orm, ({ Address }) =>
  Address.all().orderBy('createdAt').toRefArray(),
);

export const makeAddressByIdSelector = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ Address }, id) => {
      const addressModel = Address.withId(id);

      if (!addressModel) {
        return addressModel;
      }

      return {
        ...addressModel.ref,
      };
    },
  );

export const addressByIdSelector = makeAddressByIdSelector();
