import { all, takeEvery } from 'redux-saga/effects';

import {
  createPaymentService,
  deletePaymentService,
  handlePaymentCreateService,
  handlePaymentDeleteService,
  handlePaymentUpdateService,
  updatePaymentService,
} from '../services';
import EntryActionTypes from '../../../constants/EntryActionTypes';

export default function* paymentWatchers() {
  yield all([
    takeEvery(EntryActionTypes.PAYMENT_CREATE, ({ payload: { customerId, data } }) =>
      createPaymentService(customerId, data),
    ),
    takeEvery(EntryActionTypes.PAYMENT_CREATE_HANDLE, ({ payload: { payment } }) =>
      handlePaymentCreateService(payment),
    ),
    takeEvery(EntryActionTypes.PAYMENT_UPDATE, ({ payload: { id, data } }) =>
      updatePaymentService(id, data),
    ),
    takeEvery(EntryActionTypes.PAYMENT_UPDATE_HANDLE, ({ payload: { payment } }) =>
      handlePaymentUpdateService(payment),
    ),
    takeEvery(EntryActionTypes.PAYMENT_DELETE, ({ payload: { id } }) => deletePaymentService(id)),
    takeEvery(EntryActionTypes.PAYMENT_DELETE_HANDLE, ({ payload: { payment } }) =>
      handlePaymentDeleteService(payment),
    ),
  ]);
}
