import { call, put, select } from 'redux-saga/effects';

import { goToBusinessService, goToRootService } from './router';
import request from '../request';
import { pathSelector } from '../../../selectors';
import {
  createBusiness,
  deleteBusiness,
  handleBusinessCreate,
  handleBusinessDelete,
  handleBusinessUpdate,
  updateBusiness,
  updateBusinessBackgroundImage,
} from '../../../actions';
import api from '../../../api';

export function* createBusinessService(data) {
  yield put(createBusiness(data));

  let business;
  let businessManagers;

  try {
    ({
      item: business,
      included: { businessManagers },
    } = yield call(request, api.createBusiness, data));
  } catch (error) {
    yield put(createBusiness.failure(error));
    return;
  }

  yield put(createBusiness.success(business, businessManagers));
  yield call(goToBusinessService, business.id);
}

export function* handleBusinessCreateService({ id }) {
  let business;
  let users;
  let businessManagers;
  let countries;
  let brands;
  let accounts;
  let customerGroups;
  let groups;
  let groupMemberships;
  let categories;

  try {
    ({
      item: business,
      included: {
        users,
        businessManagers,
        countries,
        brands,
        accounts,
        customerGroups,
        groups,
        groupMemberships,
        categories,
      },
    } = yield call(request, api.getBusiness, id));
  } catch (error) {
    return;
  }

  yield put(
    handleBusinessCreate(
      business,
      users,
      businessManagers,
      countries,
      brands,
      accounts,
      customerGroups,
      groups,
      groupMemberships,
      categories,
    ),
  );
}

export function* updateBusinessService(id, data) {
  yield put(updateBusiness(id, data));

  let business;
  try {
    ({ item: business } = yield call(request, api.updateBusiness, id, data));
  } catch (error) {
    yield put(updateBusiness.failure(id, error));
  }

  yield put(updateBusiness.success(business));
}

export function* updateCurrentBusinessService(data) {
  const { businessId } = yield select(pathSelector);

  yield call(updateBusinessService, businessId, data);
}

export function* handleBusinessUpdateService(business) {
  yield put(handleBusinessUpdate(business));
}

export function* updateBusinessBackgroundImageService(id, data) {
  yield put(updateBusinessBackgroundImage(id));

  let business;
  try {
    ({ item: business } = yield call(request, api.updateBusinessBackgroundImage, id, data));
  } catch (error) {
    yield put(updateBusinessBackgroundImage.failure(id, error));
  }

  yield put(updateBusinessBackgroundImage.success(business));
}

export function* updateCurrentBusinessBackgroundImageService(data) {
  const { businessId } = yield select(pathSelector);

  yield call(updateBusinessBackgroundImageService, businessId, data);
}

export function* deleteBusinessService(id) {
  const { businessId } = yield select(pathSelector);

  if (id === businessId) {
    yield call(goToRootService);
  }

  yield put(deleteBusiness(id));

  let business;
  try {
    ({ item: business } = yield call(request, api.deleteBusiness, id));
  } catch (error) {
    yield put(deleteBusiness.failure(id, error));
  }

  yield put(deleteBusiness.success(business));
}

export function* deleteCurrentBusinessService() {
  const { businessId } = yield select(pathSelector);

  yield call(deleteBusinessService, businessId);
}

export function* handleBusinessDeleteService(business) {
  const { businessId } = yield select(pathSelector);

  if (business.id === businessId) {
    yield call(goToRootService);
  }

  yield put(handleBusinessDelete(business));
}
