import { Model, attr, fk } from 'redux-orm';

import ActionTypes from '../constants/ActionTypes';

export default class extends Model {
  static modelName = 'Shipment';

  static fields = {
    id: attr(),
    isFetching: attr({
      getDefault: () => null,
    }),
    groupId: fk({
      to: 'Group',
      as: 'group',
      relatedName: 'shipments',
    }),
    customerId: fk({
      to: 'Customer',
      as: 'customer',
      relatedName: 'shipments',
    }),
    creatorUserId: fk({
      to: 'User',
      as: 'creator',
      relatedName: 'shipments',
    }),
    // shipperUserId: fk({
    //   to: 'User',
    //   as: 'shipper',
    //   relatedName: 'shipments',
    // }),
    status: attr(),
  };

  static reducer({ type, payload }, Shipment) {
    switch (type) {
      case ActionTypes.LOCATION_CHANGE_HANDLE:
      case ActionTypes.CORE_INITIALIZE:
      case ActionTypes.BUSINESS_MANAGER_CREATE_HANDLE:
      case ActionTypes.GROUP_MEMBERSHIP_CREATE_HANDLE:
      case ActionTypes.SHIPMENTS_FETCH__SUCCESS:
        if (payload.shipments) {
          payload.shipments.forEach((shipment) => {
            Shipment.upsert(shipment);
          });
        }

        break;
      case ActionTypes.LOCATION_CHANGE_HANDLE__SHIPMENT_FETCH:
      case ActionTypes.SHIPMENT_FETCH:
        if (Shipment.idExists(payload.id)) {
          Shipment.withId(payload.id).update({
            isFetching: true,
          });
        }

        break;
      case ActionTypes.SHIPMENT_FETCH__SUCCESS:
        Shipment.upsert({
          ...payload.shipment,
          isFetching: false,
        });

        break;
      case ActionTypes.SOCKET_RECONNECT_HANDLE:
        Shipment.all().delete();

        if (payload.shipments) {
          payload.shipments.forEach((shipment) => {
            Shipment.upsert(shipment);
          });
        }

        break;
      case ActionTypes.GROUP_FETCH__SUCCESS:
        payload.shipments.forEach((shipment) => {
          Shipment.upsert(shipment);
        });

        break;
      case ActionTypes.SHIPMENT_CREATE:
      case ActionTypes.SHIPMENT_CREATE_HANDLE:
      case ActionTypes.SHIPMENT_UPDATE__SUCCESS:
      case ActionTypes.SHIPMENT_UPDATE_HANDLE:
        Shipment.upsert(payload.shipment);

        break;
      case ActionTypes.SHIPMENT_CREATE__SUCCESS:
        Shipment.withId(payload.localId).delete();
        Shipment.upsert(payload.shipment);

        break;
      case ActionTypes.SHIPMENT_UPDATE:
        Shipment.withId(payload.id).update(payload.data);

        break;
      case ActionTypes.SHIPMENT_DELETE:
        Shipment.withId(payload.id).deleteWithRelated();

        break;
      case ActionTypes.SHIPMENT_DELETE__SUCCESS:
      case ActionTypes.SHIPMENT_DELETE_HANDLE: {
        const shipmentModel = Shipment.withId(payload.shipment.id);

        if (shipmentModel) {
          shipmentModel.deleteWithRelated();
        }

        break;
      }
      case ActionTypes.SHIPLINE_CREATE__SUCCESS:
      case ActionTypes.SHIPLINE_CREATE_HANDLE:
      case ActionTypes.SHIPLINE_DELETE__SUCCESS:
      case ActionTypes.SHIPLINE_DELETE_HANDLE:
      case ActionTypes.SHIPLINE_UPDATE__SUCCESS:
      case ActionTypes.SHIPLINE_UPDATE_HANDLE:
        if (payload.shipment) {
          Shipment.upsert(payload.shipment);
        }
        break;
      default:
    }
  }

  static getOrderedQuerySet() {
    return this.orderBy('createdAt', false);
  }

  getOrderedShiplinesQuerySet() {
    return this.shiplines.orderBy('createdAt', false);
  }

  deleteRelated() {
    this.sellitems.toModelArray().forEach((sellitemModel) => {
      sellitemModel.deleteWithRelated();
    });
  }

  deleteWithRelated() {
    this.deleteRelated();
    this.delete();
  }
}
