import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Dropdown, Form } from 'semantic-ui-react';
import { Popup } from '../../lib/custom-ui';

import { useForm } from '../../hooks';

import styles from './SellitemMoveStep.module.scss';

const SellitemMoveStep = React.memo(
  ({ businessesToCustomers, defaultPath, onMove, onTransfer, onGroupFetch, onBack, onClose }) => {
    const [t] = useTranslation();

    const [path, handleFieldChange] = useForm(() => ({
      businessId: null,
      groupId: null,
      customerId: null,
      ...defaultPath,
    }));

    const selectedBusiness = useMemo(
      () => businessesToCustomers.find((business) => business.id === path.businessId) || null,
      [businessesToCustomers, path.businessId],
    );

    const selectedGroup = useMemo(
      () =>
        (selectedBusiness && selectedBusiness.groups.find((group) => group.id === path.groupId)) ||
        null,
      [selectedBusiness, path.groupId],
    );

    const selectedCustomer = useMemo(
      () =>
        (selectedGroup &&
          selectedGroup.customers.find((customer) => customer.id === path.customerId)) ||
        null,
      [selectedGroup, path.customerId],
    );

    const handleGroupIdFieldChange = useCallback(
      (event, data) => {
        if (selectedBusiness.groups.find((group) => group.id === data.value).isFetching === null) {
          onGroupFetch(data.value);
        }

        handleFieldChange(event, data);
      },
      [onGroupFetch, handleFieldChange, selectedBusiness],
    );

    const handleSubmit = useCallback(() => {
      if (selectedGroup.id !== defaultPath.groupId) {
        onTransfer(selectedGroup.id, selectedCustomer.id);
      } else if (selectedCustomer.id !== defaultPath.customerId) {
        onMove(selectedCustomer.id);
      }

      onClose();
    }, [defaultPath, onMove, onTransfer, onClose, selectedGroup, selectedCustomer]);

    return (
      <>
        <Popup.Header onBack={onBack}>
          {t('common.moveSellitem', {
            context: 'title',
          })}
        </Popup.Header>
        <Popup.Content>
          <Form onSubmit={handleSubmit}>
            <div className={styles.text}>{t('common.business')}</div>
            <Dropdown
              fluid
              selection
              name="businessId"
              options={businessesToCustomers.map((business) => ({
                text: business.name,
                value: business.id,
              }))}
              value={selectedBusiness && selectedBusiness.id}
              placeholder={
                businessesToCustomers.length === 0
                  ? t('common.noBusinesses')
                  : t('common.selectBusiness')
              }
              disabled={businessesToCustomers.length === 0}
              className={styles.field}
              onChange={handleFieldChange}
            />
            {selectedBusiness && (
              <>
                <div className={styles.text}>{t('common.group')}</div>
                <Dropdown
                  fluid
                  selection
                  name="groupId"
                  options={selectedBusiness.groups.map((group) => ({
                    text: group.name,
                    value: group.id,
                  }))}
                  value={selectedGroup && selectedGroup.id}
                  placeholder={
                    selectedBusiness.groups.length === 0
                      ? t('common.noGroups')
                      : t('common.selectGroup')
                  }
                  disabled={selectedBusiness.groups.length === 0}
                  className={styles.field}
                  onChange={handleGroupIdFieldChange}
                />
              </>
            )}
            {selectedGroup && (
              <>
                <div className={styles.text}>{t('common.customer')}</div>
                <Dropdown
                  fluid
                  selection
                  name="customerId"
                  options={selectedGroup.customers.map((customer) => ({
                    text: customer.name,
                    value: customer.id,
                  }))}
                  value={selectedCustomer && selectedCustomer.id}
                  placeholder={
                    selectedGroup.isFetching === false && selectedGroup.customers.length === 0
                      ? t('common.noCustomers')
                      : t('common.selectCustomer')
                  }
                  loading={selectedGroup.isFetching !== false}
                  disabled={
                    selectedGroup.isFetching !== false || selectedGroup.customers.length === 0
                  }
                  className={styles.field}
                  onChange={handleFieldChange}
                />
              </>
            )}
            <Button
              positive
              content={t('action.move')}
              disabled={(selectedGroup && selectedGroup.isFetching !== false) || !selectedCustomer}
            />
          </Form>
        </Popup.Content>
      </>
    );
  },
);

SellitemMoveStep.propTypes = {
  /* eslint-disable react/forbid-prop-types */
  businessesToCustomers: PropTypes.array.isRequired,
  defaultPath: PropTypes.object.isRequired,
  /* eslint-enable react/forbid-prop-types */
  onMove: PropTypes.func.isRequired,
  onTransfer: PropTypes.func.isRequired,
  onGroupFetch: PropTypes.func.isRequired,
  onBack: PropTypes.func,
  onClose: PropTypes.func.isRequired,
};

SellitemMoveStep.defaultProps = {
  onBack: undefined,
};

export default SellitemMoveStep;
