import { call, put, select } from 'redux-saga/effects';

import request from '../request';
import { fetchGroupByCurrentPathRequest } from '../requests';
import {
  currentUserIdSelector,
  notificationsBySellitemIdSelector,
  pathSelector,
  businessManagerByIdSelector,
} from '../../../selectors';
import {
  createBusinessManager,
  deleteBusinessManager,
  handleBusinessManagerCreate,
  handleBusinessManagerDelete,
} from '../../../actions';
import api from '../../../api';
import { createLocalId } from '../../../utils/local-id';
import mergeRecords from '../../../utils/merge-records';

export function* createBusinessManagerService(businessId, data) {
  const localId = yield call(createLocalId);

  yield put(
    createBusinessManager({
      ...data,
      businessId,
      id: localId,
    }),
  );

  let businessManager;
  try {
    ({ item: businessManager } = yield call(request, api.createBusinessManager, businessId, data));
  } catch (error) {
    yield put(createBusinessManager.failure(localId, error));
  }

  yield put(createBusinessManager.success(localId, businessManager));
}

export function* createManagerInCurrentBusinessService(data) {
  const { businessId } = yield select(pathSelector);

  yield call(createBusinessManagerService, businessId, data);
}

export function* handleBusinessManagerCreateService(businessManager) {
  const currentUserId = yield select(currentUserIdSelector);
  const isCurrentUser = businessManager.userId === currentUserId;

  let user;
  let business;
  let group;
  let users1;
  let users2;
  let businessManagers;
  let groups;
  let groupMemberships1;
  let groupMemberships2;
  let labels;
  let customers;
  let addresses;
  let sellitems;
  let sellitemMemberships;
  let sellitemLabels;
  let payments;
  let attachments;
  let notifications;
  let products;
  let categories;
  let productCategories;
  let shipments;

  if (isCurrentUser) {
    const { groupId } = yield select(pathSelector);

    yield put(
      handleBusinessManagerCreate.fetchBusiness(businessManager.businessId, currentUserId, groupId),
    );

    try {
      ({
        item: business,
        included: { users: users1, businessManagers, groups, groupMemberships: groupMemberships1 },
      } = yield call(request, api.getBusiness, businessManager.businessId));
    } catch {
      return;
    }

    let body;
    try {
      body = yield call(fetchGroupByCurrentPathRequest);
    } catch {} // eslint-disable-line no-empty

    if (body && body.business && body.business.id === businessManager.businessId) {
      ({
        business,
        group,
        users: users2,
        groupMemberships: groupMemberships2,
        labels,
        customers,
        addresses,
        sellitems,
        sellitemMemberships,
        sellitemLabels,
        payments,
        attachments,
        products,
        categories,
        productCategories,
        shipments,
      } = body);

      if (body.sellitem) {
        notifications = yield select(notificationsBySellitemIdSelector, body.sellitem.id);
      }
    }
  } else {
    try {
      ({ item: user } = yield call(request, api.getUser, businessManager.userId));
    } catch {
      return;
    }
  }

  yield put(
    handleBusinessManagerCreate(
      businessManager,
      business,
      group,
      isCurrentUser ? mergeRecords(users1, users2) : [user],
      businessManagers,
      groups,
      mergeRecords(groupMemberships1, groupMemberships2),
      labels,
      customers,
      addresses,
      sellitems,
      sellitemMemberships,
      sellitemLabels,
      payments,
      attachments,
      notifications,
      products,
      categories,
      productCategories,
      shipments,
    ),
  );
}

export function* deleteBusinessManagerService(id) {
  let businessManager = yield select(businessManagerByIdSelector, id);

  const currentUserId = yield select(currentUserIdSelector);
  const { businessId } = yield select(pathSelector);

  yield put(
    deleteBusinessManager(
      id,
      businessManager.userId === currentUserId,
      businessManager.businessId === businessId,
    ),
  );

  try {
    ({ item: businessManager } = yield call(request, api.deleteBusinessManager, id));
  } catch (error) {
    yield put(deleteBusinessManager.failure(id, error));
    return;
  }

  yield put(deleteBusinessManager.success(businessManager));
}

export function* handleBusinessManagerDeleteService(businessManager) {
  const currentUserId = yield select(currentUserIdSelector);
  const { businessId } = yield select(pathSelector);

  yield put(
    handleBusinessManagerDelete(
      businessManager,
      businessManager.userId === currentUserId,
      businessManager.businessId === businessId,
    ),
  );
}
