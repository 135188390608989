import { Model, attr, fk } from 'redux-orm';

import ActionTypes from '../constants/ActionTypes';

export default class extends Model {
  static modelName = 'Payment';

  static fields = {
    id: attr(),
    name: attr(),
    isCompleted: attr({
      getDefault: () => false,
    }),
    customerId: fk({
      to: 'Customer',
      as: 'customer',
      relatedName: 'payments',
    }),
    sellitemId: fk({
      to: 'Sellitem',
      as: 'sellitem',
      relatedName: 'payments',
    }),
  };

  static reducer({ type, payload }, Payment) {
    switch (type) {
      case ActionTypes.LOCATION_CHANGE_HANDLE:
      case ActionTypes.CORE_INITIALIZE:
      case ActionTypes.BUSINESS_MANAGER_CREATE_HANDLE:
      case ActionTypes.GROUP_MEMBERSHIP_CREATE_HANDLE:
        if (payload.payments) {
          payload.payments.forEach((payment) => {
            Payment.upsert(payment);
          });
        }

        break;
      case ActionTypes.SOCKET_RECONNECT_HANDLE:
        Payment.all().delete();

        if (payload.payments) {
          payload.payments.forEach((payment) => {
            Payment.upsert(payment);
          });
        }

        break;
      case ActionTypes.CUSTOMERS_FETCH__SUCCESS:
      case ActionTypes.GROUP_FETCH__SUCCESS:
      case ActionTypes.SELLITEMS_FETCH__SUCCESS:
      case ActionTypes.SHIPMENTS_FETCH__SUCCESS:
        if (payload.payments) {
          payload.payments.forEach((payment) => {
            Payment.upsert(payment);
          });
        }

        break;
      case ActionTypes.PAYMENT_CREATE:
      case ActionTypes.PAYMENT_CREATE_HANDLE:
      case ActionTypes.PAYMENT_UPDATE__SUCCESS:
      case ActionTypes.PAYMENT_UPDATE_HANDLE:
        Payment.upsert(payload.payment);

        break;
      case ActionTypes.PAYMENT_CREATE__SUCCESS:
        Payment.withId(payload.localId).delete();
        Payment.upsert(payload.payment);

        break;
      case ActionTypes.PAYMENT_UPDATE:
        Payment.withId(payload.id).update(payload.data);

        break;
      case ActionTypes.PAYMENT_DELETE:
        Payment.withId(payload.id).delete();

        break;
      case ActionTypes.PAYMENT_DELETE__SUCCESS:
      case ActionTypes.PAYMENT_DELETE_HANDLE: {
        const paymentModel = Payment.withId(payload.payment.id);

        if (paymentModel) {
          paymentModel.delete();
        }

        break;
      }
      default:
    }
  }
}
