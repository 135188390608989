/* eslint-disable import/prefer-default-export */
import ActionTypes from '../constants/ActionTypes';

export const searchCustomers = (groupId, query) => ({
  type: ActionTypes.CUSTOMERS_SEARCH,
  payload: {
    groupId,
    query,
  },
});

searchCustomers.success = (groupId, customers, sellitems, addresses, payments) => ({
  type: ActionTypes.CUSTOMERS_FETCH__SUCCESS,
  payload: {
    groupId,
    customers,
    sellitems,
    addresses,
    payments,
  },
});

searchCustomers.failure = (groupId, error) => ({
  type: ActionTypes.CUSTOMERS_FETCH__FAILURE,
  payload: {
    groupId,
    error,
  },
});

export const fetchCustomers = (groupId, query) => ({
  type: ActionTypes.CUSTOMERS_FETCH,
  payload: {
    groupId,
    query,
  },
});

fetchCustomers.success = (groupId, customers, sellitems, addresses, payments) => ({
  type: ActionTypes.CUSTOMERS_FETCH__SUCCESS,
  payload: {
    groupId,
    customers,
    sellitems,
    addresses,
    payments,
  },
});

fetchCustomers.failure = (groupId, error) => ({
  type: ActionTypes.CUSTOMERS_FETCH__FAILURE,
  payload: {
    groupId,
    error,
  },
});
