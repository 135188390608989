import { call, put, select } from 'redux-saga/effects';

import request from '../request';
import { pathSelector } from '../../../selectors';
import {
  createAddress,
  deleteAddress,
  handleAddressCreate,
  handleAddressDelete,
  handleAddressUpdate,
  updateAddress,
} from '../../../actions';
import { updateShiplineService } from './shipline';
import api from '../../../api';
import { createLocalId } from '../../../utils/local-id';

export function* createAddressService(customerId, data) {
  const localId = yield call(createLocalId);

  yield put(
    createAddress({
      ...data,
      customerId,
      id: localId,
    }),
  );

  let address;
  try {
    ({ item: address } = yield call(request, api.createAddress, customerId, data));
  } catch (error) {
    yield put(createAddress.failure(localId, error));
    return;
  }

  yield put(createAddress.success(localId, address));
}

export function* createAddressInCurrentCustomerService(data) {
  const { customerId } = yield select(pathSelector);

  yield call(createAddressService, customerId, data);
}

export function* createAddressInShiplineService(customerId, data, shiplineId) {
  const localId = yield call(createLocalId);

  yield put(
    createAddress({
      ...data,
      customerId,
      id: localId,
    }),
  );

  let address;
  try {
    ({ item: address } = yield call(request, api.createAddress, customerId, data));
  } catch (error) {
    yield put(createAddress.failure(localId, error));
    return;
  }
  yield put(createAddress.success(localId, address));
  if (address && address.id)
    yield call(updateShiplineService, shiplineId, { addressId: address.id });
}

export function* handleAddressCreateService(address) {
  yield put(handleAddressCreate(address));
}

export function* updateAddressService(id, data) {
  yield put(updateAddress(id, data));

  let address;
  try {
    ({ item: address } = yield call(request, api.updateAddress, id, data));
  } catch (error) {
    yield put(updateAddress.failure(id, error));
    return;
  }

  yield put(updateAddress.success(address));
}

export function* handleAddressUpdateService(address) {
  yield put(handleAddressUpdate(address));
}

export function* deleteAddressService(id) {
  yield put(deleteAddress(id));

  let address;
  try {
    ({ item: address } = yield call(request, api.deleteAddress, id));
  } catch (error) {
    yield put(deleteAddress.failure(id, error));
    return;
  }

  yield put(deleteAddress.success(address));
}

export function* handleAddressDeleteService(address) {
  yield put(handleAddressDelete(address));
}
