import ActionTypes from '../constants/ActionTypes';

export const createCustomerGroup = (customerGroup) => ({
  type: ActionTypes.CUSTOMER_GROUP_CREATE,
  payload: {
    customerGroup,
  },
});

createCustomerGroup.success = (localId, customerGroup) => ({
  type: ActionTypes.CUSTOMER_GROUP_CREATE__SUCCESS,
  payload: {
    localId,
    customerGroup,
  },
});

createCustomerGroup.failure = (localId, error) => ({
  type: ActionTypes.CUSTOMER_GROUP_CREATE__FAILURE,
  payload: {
    localId,
    error,
  },
});

export const handleCustomerGroupCreate = (customerGroup) => ({
  type: ActionTypes.CUSTOMER_GROUP_CREATE_HANDLE,
  payload: {
    customerGroup,
  },
});

export const updateCustomerGroup = (id, data) => ({
  type: ActionTypes.CUSTOMER_GROUP_UPDATE,
  payload: {
    id,
    data,
  },
});

updateCustomerGroup.success = (customerGroup) => ({
  type: ActionTypes.CUSTOMER_GROUP_UPDATE__SUCCESS,
  payload: {
    customerGroup,
  },
});

updateCustomerGroup.failure = (id, error) => ({
  type: ActionTypes.CUSTOMER_GROUP_UPDATE__FAILURE,
  payload: {
    id,
    error,
  },
});

export const handleCustomerGroupUpdate = (customerGroup) => ({
  type: ActionTypes.CUSTOMER_GROUP_UPDATE_HANDLE,
  payload: {
    customerGroup,
  },
});

export const deleteCustomerGroup = (id) => ({
  type: ActionTypes.CUSTOMER_GROUP_DELETE,
  payload: {
    id,
  },
});

deleteCustomerGroup.success = (customerGroup) => ({
  type: ActionTypes.CUSTOMER_GROUP_DELETE__SUCCESS,
  payload: {
    customerGroup,
  },
});

deleteCustomerGroup.failure = (id, error) => ({
  type: ActionTypes.CUSTOMER_GROUP_DELETE__FAILURE,
  payload: {
    id,
    error,
  },
});

export const handleCustomerGroupDelete = (customerGroup) => ({
  type: ActionTypes.CUSTOMER_GROUP_DELETE_HANDLE,
  payload: {
    customerGroup,
  },
});
