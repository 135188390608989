import { all, takeEvery } from 'redux-saga/effects';

import {
  createCountryInCurrentBusinessService,
  deleteCountryService,
  handleCountryCreateService,
  handleCountryDeleteService,
  handleCountryUpdateService,
  updateCountryService,
  addCountryToFilterInCurrentGroupService,
  removeCountryFromFilterInCurrentGroupService,
} from '../services';
import EntryActionTypes from '../../../constants/EntryActionTypes';

export default function* countryWatchers() {
  yield all([
    takeEvery(EntryActionTypes.COUNTRY_IN_CURRENT_BUSINESS_CREATE, ({ payload: { data } }) =>
      createCountryInCurrentBusinessService(data),
    ),
    takeEvery(EntryActionTypes.COUNTRY_CREATE_HANDLE, ({ payload: { country } }) =>
      handleCountryCreateService(country),
    ),
    takeEvery(EntryActionTypes.COUNTRY_UPDATE, ({ payload: { id, data } }) =>
      updateCountryService(id, data),
    ),
    takeEvery(EntryActionTypes.COUNTRY_UPDATE_HANDLE, ({ payload: { country } }) =>
      handleCountryUpdateService(country),
    ),
    takeEvery(EntryActionTypes.COUNTRY_DELETE, ({ payload: { id } }) => deleteCountryService(id)),
    takeEvery(EntryActionTypes.COUNTRY_DELETE_HANDLE, ({ payload: { country } }) =>
      handleCountryDeleteService(country),
    ),
    takeEvery(EntryActionTypes.COUNTRY_TO_FILTER_IN_CURRENT_GROUP_ADD, ({ payload: { id } }) =>
      addCountryToFilterInCurrentGroupService(id),
    ),
    takeEvery(EntryActionTypes.COUNTRY_FROM_FILTER_IN_CURRENT_GROUP_REMOVE, ({ payload: { id } }) =>
      removeCountryFromFilterInCurrentGroupService(id),
    ),
  ]);
}
