import { Model, attr, fk } from 'redux-orm';

import ActionTypes from '../constants/ActionTypes';

export default class extends Model {
  static modelName = 'Category';

  static fields = {
    id: attr(),
    name: attr(),
    description: attr(),
    businessId: fk({
      to: 'Business',
      as: 'business',
      relatedName: 'categories',
    }),
    parentId: fk({
      to: 'Category',
      as: 'category',
      relatedName: 'categories',
    }),
  };

  static reducer({ type, payload }, Category) {
    switch (type) {
      case ActionTypes.SOCKET_RECONNECT_HANDLE:
        Category.all().delete();

        if (payload.categories) {
          payload.categories.forEach((category) => {
            Category.upsert(category);
          });
        }

        break;
      case ActionTypes.CORE_INITIALIZE:
      case ActionTypes.BUSINESS_CREATE__SUCCESS:
      case ActionTypes.BUSINESS_CREATE_HANDLE:
        if (payload.categories) {
          payload.categories.forEach((category) => {
            Category.upsert(category);
          });
        }

        break;
      case ActionTypes.CATEGORY_CREATE:
      case ActionTypes.CATEGORY_CREATE_HANDLE:
      case ActionTypes.CATEGORY_UPDATE__SUCCESS:
      case ActionTypes.CATEGORY_UPDATE_HANDLE:
        Category.upsert(payload.category);

        break;
      case ActionTypes.CATEGORY_CREATE__SUCCESS:
        Category.withId(payload.localId).delete();
        Category.upsert(payload.category);

        break;
      // case ActionTypes.CATEGORY_CREATE_HANDLE:
      //   Category.upsert(payload.category);

      //   if (payload.categories) {
      //     payload.categories.forEach((category) => {
      //       Category.upsert(category);
      //     });
      //   }

      //   break;
      case ActionTypes.CATEGORY_UPDATE:
        Category.withId(payload.id).update(payload.data);

        break;
      case ActionTypes.CATEGORY_DELETE:
        Category.withId(payload.id).delete();

        break;
      case ActionTypes.CATEGORY_DELETE__SUCCESS:
      case ActionTypes.CATEGORY_DELETE_HANDLE: {
        const categoryModel = Category.withId(payload.category.id);

        if (categoryModel) {
          categoryModel.delete();
        }

        break;
      }
      default:
    }
  }
}
