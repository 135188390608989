import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';

import { Popup } from '../../lib/custom-ui';
import { withPopup } from '../../lib/popup';

import styles from './ProductInfosPopup.module.scss';

const CountriesStep = React.memo(
  ({ item, allCountries, title, onCountrySelect, onBack, onClose }) => {
    const [t] = useTranslation();

    const countryOptions = allCountries.map((it) => ({
      value: it.id,
      label: `${it.name} - ${it.code}`,
      id: it.id,
    }));
    const [selectedCountry, setSelectedCountry] = useState({
      value: item.id,
      label: `${item.name} - ${item.code}`,
      id: item.id,
    });

    const handleCountryChange = useCallback(
      (selected) => {
        setSelectedCountry(selected);
        onCountrySelect(selected);
        onClose();
      },
      [onCountrySelect, setSelectedCountry, onClose],
    );

    return (
      <>
        <Popup.Header onBack={onBack}>{title}</Popup.Header>
        <Popup.Content className={styles.select}>
          <Select
            isClearable={false}
            placeholder={t('common.selectCountry')}
            noOptionsMessage={() => t('common.noOptionsMessage')}
            components={{ ClearIndicator: null }}
            value={selectedCountry}
            name="country"
            options={countryOptions}
            onChange={handleCountryChange}
          />
        </Popup.Content>
      </>
    );
  },
);

CountriesStep.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  allCountries: PropTypes.arrayOf(PropTypes.any).isRequired,
  title: PropTypes.string,
  onCountrySelect: PropTypes.func.isRequired,
  onBack: PropTypes.func,
  onClose: PropTypes.func,
};

CountriesStep.defaultProps = {
  title: 'common.country',
  onBack: undefined,
  onClose: undefined,
};

export default withPopup(CountriesStep);
