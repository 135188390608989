import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';
import { HotTable, HotColumn } from '@handsontable/react';
// import { HyperFormula } from 'hyperformula';

import Summary from './Summary';
import MenusPopup from '../Customers/MenusPopup';

import DatePickerEditor from '../HotTable/DatePickerEditor';
import NumberEditor from '../HotTable/NumberEditor';
import PaymentAddEditor from '../HotTable/PaymentAddEditor';
import SelectEditor from '../HotTable/SelectEditor';
import CustomerRenderer from '../Sellitems/CustomerRenderer';

import {
  arrivalDateRenderer,
  dateRenderer,
  linkRenderer,
  statusRenderer,
  imageRenderer,
  quantityRenderer,
  weightAmountRenderer,
} from './renderer';

import { Statuses } from '../../constants/Enums';
import {
  getFilterCustomers,
  getFilterProducts,
  setFilterCustomers,
  setFilterProducts,
} from '../../utils/filter-storage';

import styles from './SellitemTable.module.scss';

const undoQ = [];
const redoQ = [];

const SellitemTable = React.memo(
  ({
    accounts,
    sellitems,
    canEdit,
    page,
    originPath,
    showRightSidebar,
    onPaymentCreate,
    onUpdateSellitems,
  }) => {
    const [t] = useTranslation();

    const hotTableRef = useRef();
    const summaryRef = useRef();

    const colHeaders = useMemo(
      () => [
        'id',
        t('common.dateOrder'),
        t('common.members'),
        t('common.status'),
        t('common.arrivalDate'),
        page === 'products' ? t('common.code') : t('common.image'),
        page === 'products' ? t('common.customers') : t('common.products'),
        t('common.color'),
        t('common.size'),
        t('common.quantity'),
        t('common.webOrderNo'),
        t('common.sellPrice'),
        // t('common.weight'),
        t('common.weightAmount'),
        t('common.finalTotal'),
        t('common.payment'),
        t('common.remainTotal'),
        // t('common.note'),
      ],
      [page, t],
    );

    const afterChange = useCallback(
      (changes, source) => {
        const sourceChange = ['edit', 'CopyPaste.paste', 'Autofill.fill', 'undo', 'redo'];
        if (sourceChange.indexOf(source) > -1 && changes.length > 0) {
          let newData;
          const ids = [];
          let currentGroupId;
          changes.forEach(([row, prop, oldValue, newValue]) => {
            if ((oldValue === null || oldValue === undefined) && newValue === '') return;

            // eslint-disable-next-line eqeqeq
            if (oldValue != newValue) {
              let newDate = null;
              let colorCode = null;
              let sizeCode = null;
              let webCode = null;

              const sellitemId = hotTableRef.current.hotInstance.getDataAtRowProp(row, 'id');
              const {
                id,
                groupId,
                color,
                size,
                // weight,
                country: { weightAmount },
                product: { variation },
                // weightAmount,
              } = sellitems.find(({ id: curId }) => curId === sellitemId);
              ids.push(id);
              currentGroupId = groupId;

              switch (prop) {
                case 'arrivalDate':
                  if (newValue) {
                    console.log(newValue);
                    if (newValue.includes('/')) {
                      const [d, M, yyyy] = newValue.split('/');
                      newDate = new Date(yyyy, M - 1, d);
                    } else {
                      newDate = new Date(newValue);
                    }
                    newData = {
                      [prop]: newDate,
                      status: Statuses.ARRIVAL.name,
                    };
                  } else {
                    newData = {
                      [prop]: newDate,
                    };
                  }
                  break;
                case 'color':
                  if (variation && variation.colors) {
                    const clrObj = variation.colors.find((it) => it.name === newValue);
                    colorCode = clrObj?.colorId;
                    webCode = clrObj?.webCode;
                    // console.log('variation_webcode:', webCode);
                    sizeCode = variation.colors
                      .find((it) => it.name === color)
                      ?.sizes.find((it) => it.name === size)?.sizeCode;
                  }
                  if (!webCode) {
                    newData = {
                      [prop]: newValue || null,
                      colorCode,
                      sizeCode,
                    };
                  } else {
                    newData = {
                      [prop]: newValue || null,
                      colorCode,
                      sizeCode,
                      webCode,
                    };
                  }

                  break;
                case 'size':
                  if (variation) {
                    if (variation.colors) {
                      sizeCode = variation.colors
                        .find((it) => it.name === color)
                        ?.sizes.find((it) => it.name === newValue)?.sizeCode;
                    } else if (variation.sizes) {
                      sizeCode = variation.sizes.find((it) => it.name === newValue)?.sizeCode;
                    }
                  }
                  newData = {
                    [prop]: newValue || null,
                    sizeCode,
                  };
                  break;
                case 'status':
                  newData = {
                    [prop]: Object.values(Statuses).find(({ text }) => text === newValue).name,
                  };
                  break;
                case 'weight':
                  if (newValue === t('common.withWeightAmount')) {
                    newData = {
                      [prop]: null,
                      weightAmount: null,
                      isWithWeightAmount: true,
                    };
                  } else if (newValue === t('common.withoutWeightAmount')) {
                    newData = {
                      // [prop]: null,
                      // weightAmount: isWithWeightAmount ? null : (weightAmount || 1) * newValue,
                      isWithWeightAmount: false,
                    };
                  } else {
                    newData = {
                      [prop]: newValue || null,
                      weightAmount: newValue ? (weightAmount || 1) * newValue : null,
                      isWithWeightAmount: false,
                    };
                  }
                  break;
                case 'payment':
                  return;
                default:
                  newData = {
                    [prop]: newValue || null,
                  };
                  break;
              }
              if (source === 'undo') {
                if (redoQ.length > 9) redoQ.shift();
                redoQ.push([id, prop, oldValue]);
              } else {
                if (source !== 'redo') redoQ.length = 0;
                if (undoQ.length > 9) undoQ.shift();
                undoQ.push([id, prop, oldValue]);
              }
              // onUpdateSellitem(id, newData);
            }
          });
          if (newData) {
            newData.ids = ids;
            onUpdateSellitems(currentGroupId, newData);
          }
        }
      },
      [onUpdateSellitems, sellitems, t],
    );

    const afterSelection = useCallback(() => {
      const selected = hotTableRef.current.hotInstance.getSelected();
      if (
        !(
          selected.length === 1 &&
          selected[0][0] === selected[0][2] &&
          selected[0][1] === selected[0][3]
        )
      ) {
        const data = [];
        selected.forEach((it) => {
          data.push(hotTableRef.current.hotInstance.getData(...it).flat());
        });
        if (summaryRef.current) summaryRef.current.show(data);
      } else if (summaryRef.current) {
        // const data = hotTableRef.current.hotInstance.getDataAtCell(selected[0][0], selected[0][1]);
        // summaryRef.current.show([[data]]);
        summaryRef.current.show([]);
      }
    }, []);

    const afterDeselect = () => {
      if (summaryRef.current) summaryRef.current.show([]);
    };

    const afterGetColHeader = (col, TH) => {
      if ((page === 'customer' && col === 5) || col === 11) {
        const button = TH.querySelector('.changeType');

        if (!button) {
          return;
        }

        button.parentElement.removeChild(button);
      }
    };

    const afterFilter = (formulaStack) => {
      if (page === 'products') setFilterProducts(formulaStack);
      else setFilterCustomers(formulaStack);
    };

    const handleRedo = useCallback(() => {
      const hotInstance = redoQ.pop();
      if (hotInstance && hotInstance.length > 0) {
        hotInstance[0] = sellitems.findIndex((item) => item.id === hotInstance[0]);
        hotTableRef.current.hotInstance.selectCell(hotInstance[0], hotInstance[1]);
        hotTableRef.current.hotInstance.setDataAtRowProp(...hotInstance, 'redo');
      }
    }, [sellitems]);

    const handleUndo = useCallback(() => {
      const hotInstance = undoQ.pop();
      if (hotInstance && hotInstance.length > 0) {
        hotInstance[0] = sellitems.findIndex((item) => item.id === hotInstance[0]);
        hotTableRef.current.hotInstance.selectCell(hotInstance[0], hotInstance[1]);
        hotTableRef.current.hotInstance.setDataAtRowProp(...hotInstance, 'undo');
      }
    }, [sellitems]);

    const beforeKeyDown = useCallback(
      (e) => {
        if (e.key === 'Delete' || e.key === 'Backspace') {
          // e.preventDefault();
          e.stopImmediatePropagation();
          return;
        }
        if (e.ctrlKey || e.metaKey) {
          if (e.keyCode === 90) {
            if (e.shiftKey) {
              handleRedo();
            } else {
              handleUndo();
            }
          }
        }
      },
      [handleRedo, handleUndo],
    );

    const handleExport = useCallback(() => {
      const exportPlugin = hotTableRef.current.hotInstance.getPlugin('exportFile');
      exportPlugin.downloadFile('csv', {
        columnHeaders: true,
        filename: 'WowOrder [YYYY]-[MM]-[DD]',
      });
    }, []);

    // const [, setValue] = useState(0);
    useEffect(() => {
      undoQ.length = 0;
      redoQ.length = 0;
      // setValue(1);
    }, []);

    useEffect(() => {
      const c = page === 'products' ? getFilterProducts() : getFilterCustomers();
      if (c && hotTableRef.current) {
        // eslint-disable-next-line react/no-this-in-sfc
        const filtersPlugin = hotTableRef.current.hotInstance.getPlugin('Filters');
        // filtersPlugin.addCondition(1, 'by_value', [['ing', 'ed', 'as', 'on']]);
        if (filtersPlugin) {
          // console.log(filtersPlugin.conditionCollection.filteringStates.indexedValues);
          filtersPlugin.clearConditions();
          c.forEach((it) => {
            if (it) {
              it.conditions.forEach((con) => {
                filtersPlugin.addCondition(it.column, con.name, con.args, it.operation);
              });
            }
          });
          filtersPlugin.filter();
        }
      }
    }, [page, sellitems]);

    useEffect(() => {
      hotTableRef.current.hotInstance.refreshDimensions();
    }, [showRightSidebar]);

    return (
      <div id="hot-app" className={styles.wrapper}>
        <HotTable
          ref={hotTableRef}
          data={sellitems}
          colHeaders={colHeaders}
          hiddenColumns={{ columns: [0] }}
          height="100%"
          stretchH="all"
          autoColumnSize={false}
          autoRowSize={false}
          manualColumnResize
          rowHeaders={false}
          rowHeights={43}
          contextMenu={{
            items: {
              undo: {
                name: `${t('action.undo')}<span style="color:gray;float:right;">⌘Z</span>`,
                callback() {
                  handleUndo();
                },
                disabled: () => undoQ.length < 1,
              },
              redo: {
                name: `${t('action.redo')}<span style="color:gray;float:right;">⌘⇧Z</span>`,
                callback() {
                  handleRedo();
                },
                disabled: () => redoQ.length < 1,
              },
              separator: '---------',
              copy: { name: `${t('action.copy')}<span style="color:gray;float:right;">⌘C</span>` },
            },
          }}
          enterMoves={{ col: 1, row: 0 }}
          dropdownMenu={['filter_by_value', 'filter_action_bar']}
          filters
          afterChange={afterChange}
          afterGetColHeader={afterGetColHeader}
          afterSelection={afterSelection}
          afterDeselect={afterDeselect}
          afterFilter={afterFilter}
          beforeKeyDown={beforeKeyDown}
          licenseKey="non-commercial-and-evaluation"
        >
          <HotColumn width={0} data="id" />
          <HotColumn width={90} data="createdAt" renderer={dateRenderer} readOnly />
          {/* <HotColumn width={70} data="creator" readOnly /> */}
          <HotColumn width={73} data="creator" settings={[{ dropdownMenu: false }]} readOnly>
            <CustomerRenderer hot-renderer originPath={originPath} />
          </HotColumn>
          <HotColumn
            width={100}
            data="status"
            renderer={statusRenderer}
            editor={SelectEditor}
            options={Object.values(Statuses).map(({ text, hex }) => ({
              label: text,
              value: text,
              color: hex,
            }))}
          />
          <HotColumn
            width={90}
            data="arrivalDate"
            placeholder={`${t('common.sellitemsInvoice.columnExpectedArriveDay')}\n:date`}
            editor={DatePickerEditor}
            renderer={arrivalDateRenderer}
          />
          {page === 'products' ? (
            <HotColumn width={70} data="code" readOnly />
          ) : (
            <HotColumn width={30} data="imageUrl" renderer={imageRenderer} readOnly />
          )}
          {page === 'products' ? (
            <HotColumn width={140} data="customer" readOnly />
          ) : (
            <HotColumn width={180} data="name" renderer={linkRenderer} />
          )}
          <HotColumn width={110} data="color" editor={SelectEditor} />
          <HotColumn width={70} data="size" editor={SelectEditor} />
          {/* <HotColumn width={110} data="color">
            <SelectEditor hot-editor />
          </HotColumn> */}
          {/* <HotColumn width={70} data="size">
            <SelectEditor hot-editor />
          </HotColumn> */}
          <HotColumn width={42} data="quantity" type="numeric" renderer={quantityRenderer} />
          <HotColumn width={120} data="webOrderNo" />
          <HotColumn
            width={80}
            data="sellPrice"
            type="numeric"
            numericFormat={{ pattern: '0,0' }}
            editor={NumberEditor}
          />
          {/* <HotColumn width={50} data="weight" type="numeric" /> */}
          <HotColumn
            width={80}
            data="weight"
            type="autocomplete"
            source={[t('common.withWeightAmount'), t('common.withoutWeightAmount')]}
            filter={false}
            placeholder={t('common.weightPlacehoder')}
            renderer={weightAmountRenderer}
          />
          <HotColumn
            width={96}
            data="finalTotal"
            type="numeric"
            numericFormat={{ pattern: '0,0' }}
            readOnly
          />
          <HotColumn
            width={90}
            data="payment"
            type="numeric"
            numericFormat={{ pattern: '0,0' }}
            t={t}
            accounts={accounts}
            onCreate={onPaymentCreate}
            placeholder={t('common.createPayment')}
            editor={PaymentAddEditor}
          />
          <HotColumn
            width={90}
            data="remainTotal"
            type="numeric"
            numericFormat={{ pattern: '0,0' }}
            readOnly
          />
        </HotTable>
        <div>
          <Summary ref={summaryRef} />

          <Button.Group className={styles.actionButtons}>
            <MenusPopup
              closeIcon={false}
              canExport={false} // canEdit && sellitems.length > 0
              canImport={canEdit}
              onExport={handleExport}
            >
              <Button size="mini" icon="bars" />
            </MenusPopup>
            <Button
              className={styles.buttonTableActionUndo}
              size="mini"
              content={t('action.undo')}
              icon="undo"
              labelPosition="left"
              onClick={handleUndo}
              disabled={undoQ.length < 1}
            />
            <Button
              className={styles.buttonTableActionRedo}
              size="mini"
              content={t('action.redo')}
              icon="redo"
              labelPosition="right"
              onClick={handleRedo}
              disabled={redoQ.length < 1}
            />
          </Button.Group>
        </div>
      </div>
    );
  },
);

SellitemTable.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.any).isRequired,
  sellitems: PropTypes.arrayOf(PropTypes.any).isRequired,
  canEdit: PropTypes.bool.isRequired,
  page: PropTypes.string,
  originPath: PropTypes.string,
  showRightSidebar: PropTypes.bool,
  onPaymentCreate: PropTypes.func.isRequired,
  onUpdateSellitems: PropTypes.func.isRequired,
};

SellitemTable.defaultProps = {
  page: 'customer',
  showRightSidebar: false,
  originPath: 'c',
};

export default SellitemTable;
