import socket from './socket';
import { transformSellitem } from './sellitems';
import { transformAttachment } from './attachments';

/* Actions */

const createGroup = (businessId, data, headers) =>
  socket.post(`/businesses/${businessId}/groups`, data, headers);

const getGroup = (id, currentItem, headers) =>
  socket.get(`/groups/${id}`, currentItem, headers).then((body) => ({
    ...body,
    included: {
      ...body.included,
      sellitems: body.included.sellitems.map(transformSellitem),
      attachments: body.included.attachments.map(transformAttachment),
    },
  }));

const updateGroup = (id, data, headers) => socket.patch(`/groups/${id}`, data, headers);

const deleteGroup = (id, headers) => socket.delete(`/groups/${id}`, undefined, headers);

export default {
  createGroup,
  getGroup,
  updateGroup,
  deleteGroup,
};
