import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Menu, Icon } from 'semantic-ui-react';
import { withPopup } from '../../lib/popup';
import { Popup } from '../../lib/custom-ui';

import styles from './ShipProvince.module.scss';
import globalStyles from '../../styles.module.scss';

const ShipProvinceStep = React.memo(
  ({
    items,
    isFilter,
    isUser,
    currentShipProvince,
    title,
    canEdit,
    onDeselect,
    onSelect,
    onClose,
  }) => {
    const [t] = useTranslation();
    const handleUpdateStatus = useCallback(
      (status) => {
        if (isFilter) {
          if (currentShipProvince.includes(status)) {
            onDeselect({ status });
          } else {
            onSelect({ status });
          }
        } else if (isUser) {
          onSelect({ defaultStatus: status });
          onClose();
        } else {
          onSelect({ status });
          if (!isFilter) onClose();
        }
      },
      [isFilter, isUser, currentShipProvince, onClose, onDeselect, onSelect],
    );

    return (
      <>
        <Popup.Header>
          {t(title, {
            context: 'title',
          })}
        </Popup.Header>
        <Popup.Content>
          <Menu secondary vertical className={styles.menu}>
            {canEdit &&
              items.map(({ name: status, color, text }) => (
                <Menu.Item
                  key={status}
                  className={classNames(
                    styles.menuItem,
                    // currentStatuses.includes(status) && styles.menuItemActive,
                    globalStyles[`background${upperFirst(camelCase(color))}`],
                  )}
                  onClick={() => handleUpdateStatus(status)}
                >
                  {text}
                  {currentShipProvince.includes(status) && <Icon name="check" />}
                </Menu.Item>
              ))}
            {!canEdit &&
              items
                // .filter(({ name: status }) => currentStatuses.includes(status))
                .map(({ name: status, color }) => (
                  <Menu.Item
                    key={status}
                    className={classNames(
                      styles.menuItem,
                      // currentStatuses.includes(status) && styles.menuItemActive,
                      globalStyles[`background${upperFirst(camelCase(color))}`],
                    )}
                    onClick={() => handleUpdateStatus(status)}
                  >
                    {t(`status.${status}`, {
                      context: 'title',
                    })}
                    {currentShipProvince.includes(status) && <Icon name="check" />}
                  </Menu.Item>
                ))}
          </Menu>
        </Popup.Content>
      </>
    );
  },
);

ShipProvinceStep.propTypes = {
  /* eslint-disable react/forbid-prop-types */
  items: PropTypes.array.isRequired,
  currentShipProvince: PropTypes.array.isRequired,
  /* eslint-enable react/forbid-prop-types */
  title: PropTypes.string,
  canEdit: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onDeselect: PropTypes.func,
  isFilter: PropTypes.bool,
  isUser: PropTypes.bool,
};

ShipProvinceStep.defaultProps = {
  title: 'common.status',
  isFilter: false,
  isUser: false,
  onDeselect: undefined,
};

export default withPopup(ShipProvinceStep);
