import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Input, Search, Button } from 'semantic-ui-react';
import HotTable, { HotColumn } from '@handsontable/react';
import Handsontable from 'handsontable';

import ActionsPopup from './ActionsPopup';
// import ProductItemContainer from '../../containers/ProductItemContainer';
import { ReactComponent as DotIcon } from '../../assets/images/dots-vertical.svg';
import Paths from '../../constants/Paths';

import styles from './LeftSidebar.module.scss';

const LeftSidebar = React.memo(
  ({ canEdit, product, products, searchResults, onSearchProducts, onCreateProduct }) => {
    const [t] = useTranslation();

    const history = useHistory();

    const hotTableRef = useRef();

    const [query, setQuery] = useState('');
    const [typing, setTyping] = useState({ isSearching: false });
    const searchField = useRef(null);
    const { isSubmitting } = searchResults;

    const handleSearchChange = useCallback(
      (e, data) => {
        if (typing) {
          clearTimeout(typing.timeout);
        }
        if (data && data.value.length >= data.minCharacters) {
          setQuery(data.value);
        } else {
          setQuery('');
          setTyping({ isSearching: false });
          onSearchProducts('');
        }
        setTyping({
          isSearching: true,
          timeout: setTimeout(() => {
            if (data && data.value.length >= data.minCharacters) {
              onSearchProducts(data.value);
            }
          }, 200),
        });
      },
      [onSearchProducts, typing],
    );

    // const results = products.map(({ id, name }) => ({
    //   id,
    //   title: name,
    //   productid: id,
    // }));

    // const resultRenderer = () => {
    //   // if (results.length > 0)
    //   //   return results.map((productItem, index) => (
    //   //     <ProductItemContainer key={productItem.id} id={productItem.id} index={index} />
    //   //   ));
    //   return t('common.noResultsMessage');
    // };

    const afterSelection = useCallback(
      (row) => {
        const id = hotTableRef.current.hotInstance.getDataAtRowProp(row, 'id');
        if (id !== product.id) {
          history.push(Paths.PRODUCTS.replace(':id', id));
        }
      },
      [history, product.id],
    );

    const itemRenderer = (instance, td, row, col, prop, value, cellProperties) => {
      const id = instance.getDataAtRowProp(row, 'id');
      const curProduct = products.find((p) => p.id === id);
      if (curProduct) {
        const { webCode, imageUrl, countryName, brandName, categoryName, color } = curProduct;
        Handsontable.renderers.HtmlRenderer.apply(this, [
          instance,
          td,
          row,
          col,
          prop,
          `<img src="${imageUrl}" class=${styles.img} /><div class="${
            styles.meta
          }"><span>${value}</span><span>${countryName} | ${brandName} | ${
            webCode || ''
          } | ${categoryName}</span><span>Màu: ${color || ''}</span></div>`,
          cellProperties,
        ]);
      } else {
        Handsontable.renderers.TextRenderer.apply(this, [
          instance,
          td,
          row,
          col,
          prop,
          value,
          cellProperties,
        ]);
      }
      if (id === product.id) td.classList.add(styles.current);
    };

    // useEffect(() => {
    //   onSearchProducts(product.code);
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    return (
      <div className={styles.wrapper}>
        <div className={styles.sidebarLeftHeader}>
          <div className={styles.searchWrapper}>
            <Search
              fluid
              minCharacters={1}
              loading={isSubmitting}
              open={false}
              noResultsMessage={t('common.noResultsMessage')}
              input={
                <Input
                  className={styles.input_search}
                  fluid
                  ref={searchField}
                  icon="cube"
                  size="small"
                  iconPosition="left"
                  placeholder={t('common.searchProduct')}
                />
              }
              onSearchChange={handleSearchChange}
              results={[]}
              value={query}
            />
          </div>
          {canEdit && (
            <>
              <Button
                circular
                size="mini"
                icon="cube"
                className={styles.addProductButton}
                onClick={onCreateProduct}
              />
              <ActionsPopup>
                <div className={styles.actions}>
                  <DotIcon className={styles.dotIcon} />
                </div>
              </ActionsPopup>
            </>
          )}
        </div>
        <div className={styles.wrapperProducts}>
          <HotTable
            ref={hotTableRef}
            data={products}
            height="100%"
            stretchH="all"
            autoColumnSize={false}
            autoRowSize={false}
            disableVisualSelection
            readOnly
            colHeaders={false}
            rowHeaders={false}
            rowHeights={58}
            enterMoves={{ col: 1, row: 0 }}
            selectionMode="single"
            afterSelection={afterSelection}
            // dropdownMenu={['filter_by_value', 'filter_action_bar']}
            // filters
            licenseKey="non-commercial-and-evaluation"
          >
            <HotColumn data="name" renderer={itemRenderer} />
          </HotTable>
        </div>
      </div>
    );
  },
);

LeftSidebar.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  product: PropTypes.objectOf(PropTypes.any).isRequired,
  products: PropTypes.arrayOf(PropTypes.any).isRequired,
  searchResults: PropTypes.objectOf(PropTypes.any).isRequired,
  onSearchProducts: PropTypes.func.isRequired,
  onCreateProduct: PropTypes.func.isRequired,
};

export default LeftSidebar;
