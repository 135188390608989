import { call, put, select } from 'redux-saga/effects';

import { fetchCoreRequest } from '../requests';
import { currentUserIdSelector, pathSelector } from '../../../selectors';
import { handleSocketDisconnect, handleSocketReconnect } from '../../../actions';

export function* handleSocketDisconnectService() {
  yield put(handleSocketDisconnect());
}

export function* handleSocketReconnectService() {
  const currentUserId = yield select(currentUserIdSelector);
  const { groupId } = yield select(pathSelector);

  yield put(handleSocketReconnect.fetchCore(currentUserId, groupId));

  const {
    user,
    group,
    users,
    businesses,
    businessManagers,
    countries,
    brands,
    accounts,
    customerGroups,
    groups,
    groupMemberships,
    labels,
    customers,
    addresses,
    sellitems,
    sellitemMemberships,
    sellitemLabels,
    payments,
    attachments,
    actions,
    notifications,
    products,
    categories,
    productCategories,
    shipments,
    shiplines,
    product,
  } = yield call(fetchCoreRequest); // TODO: handle error

  yield put(
    handleSocketReconnect(
      user,
      group,
      users,
      businesses,
      businessManagers,
      countries,
      brands,
      accounts,
      customerGroups,
      groups,
      groupMemberships,
      labels,
      customers,
      addresses,
      sellitems,
      sellitemMemberships,
      sellitemLabels,
      payments,
      attachments,
      actions,
      notifications,
      products,
      categories,
      productCategories,
      shipments,
      shiplines,
      product,
    ),
  );
}
