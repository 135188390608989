export default {
  /* Router */

  LOCATION_CHANGE_HANDLE: 'LOCATION_CHANGE_HANDLE',
  LOCATION_CHANGE_HANDLE__GROUP_FETCH: 'LOCATION_CHANGE_HANDLE__GROUP_FETCH',
  LOCATION_CHANGE_HANDLE__CUSTOMER_FETCH: 'LOCATION_CHANGE_HANDLE__CUSTOMER_FETCH',
  LOCATION_CHANGE_HANDLE__PRODUCT_FETCH: 'LOCATION_CHANGE_HANDLE__PRODUCT_FETCH',
  LOCATION_CHANGE_HANDLE__SHIPMENT_FETCH: 'LOCATION_CHANGE_HANDLE__SHIPMENT_FETCH',
  LOCATION_CHANGE_HANDLE__SHIPMENTS_FETCH: 'LOCATION_CHANGE_HANDLE__SHIPMENTS_FETCH',
  LOCATION_CHANGE_HANDLE__DASHBOARD_FETCH: 'LOCATION_CHANGE_HANDLE__DASHBOARD_FETCH',

  /* Socket */

  SOCKET_DISCONNECT_HANDLE: 'SOCKET_DISCONNECT_HANDLE',
  SOCKET_RECONNECT_HANDLE: 'SOCKET_RECONNECT_HANDLE',
  SOCKET_RECONNECT_HANDLE__CORE_FETCH: 'SOCKET_RECONNECT_HANDLE__CORE_FETCH',

  /* Login */

  AUTHENTICATE: 'AUTHENTICATE',
  AUTHENTICATE__SUCCESS: 'AUTHENTICATE__SUCCESS',
  AUTHENTICATE__FAILURE: 'AUTHENTICATE__FAILURE',
  AUTHENTICATE_ERROR_CLEAR: 'AUTHENTICATE_ERROR_CLEAR',

  AUTHENTICATE_IMGUR: 'AUTHENTICATE_IMGUR',
  AUTHENTICATE_IMGUR__SUCCESS: 'AUTHENTICATE_IMGUR__SUCCESS',
  AUTHENTICATE_IMGUR__FAILURE: 'AUTHENTICATE_IMGUR__FAILURE',

  LOGOUT: 'LOGOUT',

  /* Core */

  CORE_INITIALIZE: 'CORE_INITIALIZE',
  APP_ERROR_CLEAR: 'APP_ERROR_CLEAR',

  /* Modal */

  MODAL_OPEN: 'MODAL_OPEN',
  MODAL_CLOSE: 'MODAL_CLOSE',

  /* User */

  USER_CREATE: 'USER_CREATE',
  USER_CREATE__SUCCESS: 'USER_CREATE__SUCCESS',
  USER_CREATE__FAILURE: 'USER_CREATE__FAILURE',
  USER_CREATE_HANDLE: 'USER_CREATE_HANDLE',
  USER_CREATE_ERROR_CLEAR: 'USER_CREATE_ERROR_CLEAR',
  USER_UPDATE: 'USER_UPDATE',
  USER_UPDATE__SUCCESS: 'USER_UPDATE__SUCCESS',
  USER_UPDATE__FAILURE: 'USER_UPDATE__FAILURE',
  USER_UPDATE_HANDLE: 'USER_UPDATE_HANDLE',
  USER_EMAIL_UPDATE: 'USER_EMAIL_UPDATE',
  USER_EMAIL_UPDATE__SUCCESS: 'USER_EMAIL_UPDATE__SUCCESS',
  USER_EMAIL_UPDATE__FAILURE: 'USER_EMAIL_UPDATE__FAILURE',
  USER_EMAIL_UPDATE_ERROR_CLEAR: 'USER_EMAIL_UPDATE_ERROR_CLEAR',
  USER_PASSWORD_UPDATE: 'USER_PASSWORD_UPDATE',
  USER_PASSWORD_UPDATE__SUCCESS: 'USER_PASSWORD_UPDATE__SUCCESS',
  USER_PASSWORD_UPDATE__FAILURE: 'USER_PASSWORD_UPDATE__FAILURE',
  USER_PASSWORD_UPDATE_ERROR_CLEAR: 'USER_PASSWORD_UPDATE_ERROR_CLEAR',
  USER_USERNAME_UPDATE: 'USER_USERNAME_UPDATE',
  USER_USERNAME_UPDATE__SUCCESS: 'USER_USERNAME_UPDATE__SUCCESS',
  USER_USERNAME_UPDATE__FAILURE: 'USER_USERNAME_UPDATE__FAILURE',
  USER_USERNAME_UPDATE_ERROR_CLEAR: 'USER_USERNAME_UPDATE_ERROR_CLEAR',
  USER_AVATAR_UPDATE: 'USER_AVATAR_UPDATE',
  USER_AVATAR_UPDATE__SUCCESS: 'USER_AVATAR_UPDATE__SUCCESS',
  USER_AVATAR_UPDATE__FAILURE: 'USER_AVATAR_UPDATE__FAILURE',
  USER_DELETE: 'USER_DELETE',
  USER_DELETE__SUCCESS: 'USER_DELETE__SUCCESS',
  USER_DELETE__FAILURE: 'USER_DELETE__FAILURE',
  USER_DELETE_HANDLE: 'USER_DELETE_HANDLE',
  USER_TO_SELLITEM_ADD: 'USER_TO_SELLITEM_ADD',
  USER_TO_SELLITEM_ADD__SUCCESS: 'USER_TO_SELLITEM_ADD__SUCCESS',
  USER_TO_SELLITEM_ADD__FAILURE: 'USER_TO_SELLITEM_ADD__FAILURE',
  USER_TO_SELLITEM_ADD_HANDLE: 'USER_TO_SELLITEM_ADD_HANDLE',
  USER_FROM_SELLITEM_REMOVE: 'USER_FROM_SELLITEM_REMOVE',
  USER_FROM_SELLITEM_REMOVE__SUCCESS: 'USER_FROM_SELLITEM_REMOVE__SUCCESS',
  USER_FROM_SELLITEM_REMOVE__FAILURE: 'USER_FROM_SELLITEM_REMOVE__FAILURE',
  USER_FROM_SELLITEM_REMOVE_HANDLE: 'USER_FROM_SELLITEM_REMOVE_HANDLE',
  USER_TO_GROUP_FILTER_ADD: 'USER_TO_GROUP_FILTER_ADD',
  USER_FROM_GROUP_FILTER_REMOVE: 'USER_FROM_GROUP_FILTER_REMOVE',

  /* Business */

  BUSINESS_CREATE: 'BUSINESS_CREATE',
  BUSINESS_CREATE__SUCCESS: 'BUSINESS_CREATE__SUCCESS',
  BUSINESS_CREATE__FAILURE: 'BUSINESS_CREATE__FAILURE',
  BUSINESS_CREATE_HANDLE: 'BUSINESS_CREATE_HANDLE',
  BUSINESS_UPDATE: 'BUSINESS_UPDATE',
  BUSINESS_UPDATE__SUCCESS: 'BUSINESS_UPDATE__SUCCESS',
  BUSINESS_UPDATE__FAILURE: 'BUSINESS_UPDATE__FAILURE',
  BUSINESS_UPDATE_HANDLE: 'BUSINESS_UPDATE_HANDLE',
  BUSINESS_BACKGROUND_IMAGE_UPDATE: 'BUSINESS_BACKGROUND_IMAGE_UPDATE',
  BUSINESS_BACKGROUND_IMAGE_UPDATE__SUCCESS: 'BUSINESS_BACKGROUND_IMAGE_UPDATE__SUCCESS',
  BUSINESS_BACKGROUND_IMAGE_UPDATE__FAILURE: 'BUSINESS_BACKGROUND_IMAGE_UPDATE__FAILURE',
  BUSINESS_DELETE: 'BUSINESS_DELETE',
  BUSINESS_DELETE__SUCCESS: 'BUSINESS_DELETE__SUCCESS',
  BUSINESS_DELETE__FAILURE: 'BUSINESS_DELETE__FAILURE',
  BUSINESS_DELETE_HANDLE: 'BUSINESS_DELETE_HANDLE',

  /* Business manager */

  BUSINESS_MANAGER_CREATE: 'BUSINESS_MANAGER_CREATE',
  BUSINESS_MANAGER_CREATE__SUCCESS: 'BUSINESS_MANAGER_CREATE__SUCCESS',
  BUSINESS_MANAGER_CREATE__FAILURE: 'BUSINESS_MANAGER_CREATE__FAILURE',
  BUSINESS_MANAGER_CREATE_HANDLE: 'BUSINESS_MANAGER_CREATE_HANDLE',
  BUSINESS_MANAGER_CREATE_HANDLE__BUSINESS_FETCH: 'BUSINESS_MANAGER_CREATE_HANDLE__BUSINESS_FETCH',
  BUSINESS_MANAGER_DELETE: 'BUSINESS_MANAGER_DELETE',
  BUSINESS_MANAGER_DELETE__SUCCESS: 'BUSINESS_MANAGER_DELETE__SUCCESS',
  BUSINESS_MANAGER_DELETE__FAILURE: 'BUSINESS_MANAGER_DELETE__FAILURE',
  BUSINESS_MANAGER_DELETE_HANDLE: 'BUSINESS_MANAGER_DELETE_HANDLE',

  /* Country */

  COUNTRY_CREATE: 'COUNTRY_CREATE',
  COUNTRY_CREATE__SUCCESS: 'COUNTRY_CREATE__SUCCESS',
  COUNTRY_CREATE__FAILURE: 'COUNTRY_CREATE__FAILURE',
  COUNTRY_CREATE_HANDLE: 'COUNTRY_CREATE_HANDLE',
  COUNTRY_CREATE_HANDLE__BUSINESS_FETCH: 'COUNTRY_CREATE_HANDLE__BUSINESS_FETCH',
  COUNTRY_UPDATE: 'COUNTRY_UPDATE',
  COUNTRY_UPDATE__SUCCESS: 'COUNTRY_UPDATE__SUCCESS',
  COUNTRY_UPDATE__FAILURE: 'COUNTRY_UPDATE__FAILURE',
  COUNTRY_UPDATE_HANDLE: 'COUNTRY_UPDATE_HANDLE',
  COUNTRY_DELETE: 'COUNTRY_DELETE',
  COUNTRY_DELETE__SUCCESS: 'COUNTRY_DELETE__SUCCESS',
  COUNTRY_DELETE__FAILURE: 'COUNTRY_DELETE__FAILURE',
  COUNTRY_DELETE_HANDLE: 'COUNTRY_DELETE_HANDLE',
  COUNTRY_TO_GROUP_FILTER_ADD: 'COUNTRY_TO_GROUP_FILTER_ADD',
  COUNTRY_FROM_GROUP_FILTER_REMOVE: 'COUNTRY_FROM_GROUP_FILTER_REMOVE',

  /* Brand */

  BRAND_CREATE: 'BRAND_CREATE',
  BRAND_CREATE__SUCCESS: 'BRAND_CREATE__SUCCESS',
  BRAND_CREATE__FAILURE: 'BRAND_CREATE__FAILURE',
  BRAND_CREATE_HANDLE: 'BRAND_CREATE_HANDLE',
  BRAND_CREATE_HANDLE__BUSINESS_FETCH: 'BRAND_CREATE_HANDLE__BUSINESS_FETCH',
  BRAND_UPDATE: 'BRAND_UPDATE',
  BRAND_UPDATE__SUCCESS: 'BRAND_UPDATE__SUCCESS',
  BRAND_UPDATE__FAILURE: 'BRAND_UPDATE__FAILURE',
  BRAND_UPDATE_HANDLE: 'BRAND_UPDATE_HANDLE',
  BRAND_DELETE: 'BRAND_DELETE',
  BRAND_DELETE__SUCCESS: 'BRAND_DELETE__SUCCESS',
  BRAND_DELETE__FAILURE: 'BRAND_DELETE__FAILURE',
  BRAND_DELETE_HANDLE: 'BRAND_DELETE_HANDLE',
  BRAND_TO_GROUP_FILTER_ADD: 'BRAND_TO_GROUP_FILTER_ADD',
  BRAND_FROM_GROUP_FILTER_REMOVE: 'BRAND_FROM_GROUP_FILTER_REMOVE',

  /* Category */

  CATEGORY_CREATE: 'CATEGORY_CREATE',
  CATEGORY_CREATE__SUCCESS: 'CATEGORY_CREATE__SUCCESS',
  CATEGORY_CREATE__FAILURE: 'CATEGORY_CREATE__FAILURE',
  CATEGORY_CREATE_HANDLE: 'CATEGORY_CREATE_HANDLE',
  CATEGORY_CREATE_HANDLE__BUSINESS_FETCH: 'CATEGORY_CREATE_HANDLE__BUSINESS_FETCH',
  CATEGORY_UPDATE: 'CATEGORY_UPDATE',
  CATEGORY_UPDATE__SUCCESS: 'CATEGORY_UPDATE__SUCCESS',
  CATEGORY_UPDATE__FAILURE: 'CATEGORY_UPDATE__FAILURE',
  CATEGORY_UPDATE_HANDLE: 'CATEGORY_UPDATE_HANDLE',
  CATEGORY_DELETE: 'CATEGORY_DELETE',
  CATEGORY_DELETE__SUCCESS: 'CATEGORY_DELETE__SUCCESS',
  CATEGORY_DELETE__FAILURE: 'CATEGORY_DELETE__FAILURE',
  CATEGORY_DELETE_HANDLE: 'CATEGORY_DELETE_HANDLE',
  CATEGORY_TO_GROUP_FILTER_ADD: 'CATEGORY_TO_GROUP_FILTER_ADD',
  CATEGORY_FROM_GROUP_FILTER_REMOVE: 'CATEGORY_FROM_GROUP_FILTER_REMOVE',

  /* Account */

  ACCOUNT_CREATE: 'ACCOUNT_CREATE',
  ACCOUNT_CREATE__SUCCESS: 'ACCOUNT_CREATE__SUCCESS',
  ACCOUNT_CREATE__FAILURE: 'ACCOUNT_CREATE__FAILURE',
  ACCOUNT_CREATE_HANDLE: 'ACCOUNT_CREATE_HANDLE',
  ACCOUNT_CREATE_HANDLE__BUSINESS_FETCH: 'ACCOUNT_CREATE_HANDLE__BUSINESS_FETCH',
  ACCOUNT_UPDATE: 'ACCOUNT_UPDATE',
  ACCOUNT_UPDATE__SUCCESS: 'ACCOUNT_UPDATE__SUCCESS',
  ACCOUNT_UPDATE__FAILURE: 'ACCOUNT_UPDATE__FAILURE',
  ACCOUNT_UPDATE_HANDLE: 'ACCOUNT_UPDATE_HANDLE',
  ACCOUNT_DELETE: 'ACCOUNT_DELETE',
  ACCOUNT_DELETE__SUCCESS: 'ACCOUNT_DELETE__SUCCESS',
  ACCOUNT_DELETE__FAILURE: 'ACCOUNT_DELETE__FAILURE',
  ACCOUNT_DELETE_HANDLE: 'ACCOUNT_DELETE_HANDLE',

  /* Customer group */

  CUSTOMER_GROUP_CREATE: 'CUSTOMER_GROUP_CREATE',
  CUSTOMER_GROUP_CREATE__SUCCESS: 'CUSTOMER_GROUP_CREATE__SUCCESS',
  CUSTOMER_GROUP_CREATE__FAILURE: 'CUSTOMER_GROUP_CREATE__FAILURE',
  CUSTOMER_GROUP_CREATE_HANDLE: 'CUSTOMER_GROUP_CREATE_HANDLE',
  CUSTOMER_GROUP_CREATE_HANDLE__BUSINESS_FETCH: 'CUSTOMER_GROUP_CREATE_HANDLE__BUSINESS_FETCH',
  CUSTOMER_GROUP_UPDATE: 'CUSTOMER_GROUP_UPDATE',
  CUSTOMER_GROUP_UPDATE__SUCCESS: 'CUSTOMER_GROUP_UPDATE__SUCCESS',
  CUSTOMER_GROUP_UPDATE__FAILURE: 'CUSTOMER_GROUP_UPDATE__FAILURE',
  CUSTOMER_GROUP_UPDATE_HANDLE: 'CUSTOMER_GROUP_UPDATE_HANDLE',
  CUSTOMER_GROUP_DELETE: 'CUSTOMER_GROUP_DELETE',
  CUSTOMER_GROUP_DELETE__SUCCESS: 'CUSTOMER_GROUP_DELETE__SUCCESS',
  CUSTOMER_GROUP_DELETE__FAILURE: 'CUSTOMER_GROUP_DELETE__FAILURE',
  CUSTOMER_GROUP_DELETE_HANDLE: 'CUSTOMER_GROUP_DELETE_HANDLE',

  /* Group */

  GROUP_CREATE: 'GROUP_CREATE',
  GROUP_CREATE__SUCCESS: 'GROUP_CREATE__SUCCESS',
  GROUP_CREATE__FAILURE: 'GROUP_CREATE__FAILURE',
  GROUP_CREATE_HANDLE: 'GROUP_CREATE_HANDLE',
  GROUP_FETCH: 'GROUP_FETCH',
  GROUP_FETCH__SUCCESS: 'GROUP_FETCH__SUCCESS',
  GROUP_FETCH__FAILURE: 'GROUP_FETCH__FAILURE',
  GROUP_UPDATE: 'GROUP_UPDATE',
  GROUP_UPDATE__SUCCESS: 'GROUP_UPDATE__SUCCESS',
  GROUP_UPDATE__FAILURE: 'GROUP_UPDATE__FAILURE',
  GROUP_UPDATE_HANDLE: 'GROUP_UPDATE_HANDLE',
  GROUP_DELETE: 'GROUP_DELETE',
  GROUP_DELETE__SUCCESS: 'GROUP_DELETE__SUCCESS',
  GROUP_DELETE__FAILURE: 'GROUP_DELETE__FAILURE',
  GROUP_DELETE_HANDLE: 'GROUP_DELETE_HANDLE',

  /* Group membership */

  GROUP_MEMBERSHIP_CREATE: 'GROUP_MEMBERSHIP_CREATE',
  GROUP_MEMBERSHIP_CREATE__SUCCESS: 'GROUP_MEMBERSHIP_CREATE__SUCCESS',
  GROUP_MEMBERSHIP_CREATE__FAILURE: 'GROUP_MEMBERSHIP_CREATE__FAILURE',
  GROUP_MEMBERSHIP_CREATE_HANDLE: 'GROUP_MEMBERSHIP_CREATE_HANDLE',
  GROUP_MEMBERSHIP_CREATE_HANDLE__BUSINESS_FETCH: 'GROUP_MEMBERSHIP_CREATE_HANDLE__BUSINESS_FETCH',
  GROUP_MEMBERSHIP_DELETE: 'GROUP_MEMBERSHIP_DELETE',
  GROUP_MEMBERSHIP_DELETE__SUCCESS: 'GROUP_MEMBERSHIP_DELETE__SUCCESS',
  GROUP_MEMBERSHIP_DELETE__FAILURE: 'GROUP_MEMBERSHIP_DELETE__FAILURE',
  GROUP_MEMBERSHIP_DELETE_HANDLE: 'GROUP_MEMBERSHIP_DELETE_HANDLE',

  /* Label */

  LABEL_CREATE: 'LABEL_CREATE',
  LABEL_CREATE__SUCCESS: 'LABEL_CREATE__SUCCESS',
  LABEL_CREATE__FAILURE: 'LABEL_CREATE__FAILURE',
  LABEL_CREATE_HANDLE: 'LABEL_CREATE_HANDLE',
  LABEL_UPDATE: 'LABEL_UPDATE',
  LABEL_UPDATE__SUCCESS: 'LABEL_UPDATE__SUCCESS',
  LABEL_UPDATE__FAILURE: 'LABEL_UPDATE__FAILURE',
  LABEL_UPDATE_HANDLE: 'LABEL_UPDATE_HANDLE',
  LABEL_DELETE: 'LABEL_DELETE',
  LABEL_DELETE__SUCCESS: 'LABEL_DELETE__SUCCESS',
  LABEL_DELETE__FAILURE: 'LABEL_DELETE__FAILURE',
  LABEL_DELETE_HANDLE: 'LABEL_DELETE_HANDLE',
  LABEL_TO_SELLITEM_ADD: 'LABEL_TO_SELLITEM_ADD',
  LABEL_TO_SELLITEM_ADD__SUCCESS: 'LABEL_TO_SELLITEM_ADD__SUCCESS',
  LABEL_TO_SELLITEM_ADD__FAILURE: 'LABEL_TO_SELLITEM_ADD__FAILURE',
  LABEL_TO_SELLITEM_ADD_HANDLE: 'LABEL_TO_SELLITEM_ADD_HANDLE',
  LABEL_FROM_SELLITEM_REMOVE: 'LABEL_FROM_SELLITEM_REMOVE',
  LABEL_FROM_SELLITEM_REMOVE__SUCCESS: 'LABEL_FROM_SELLITEM_REMOVE__SUCCESS',
  LABEL_FROM_SELLITEM_REMOVE__FAILURE: 'LABEL_FROM_SELLITEM_REMOVE__FAILURE',
  LABEL_FROM_SELLITEM_REMOVE_HANDLE: 'LABEL_FROM_SELLITEM_REMOVE_HANDLE',
  LABEL_TO_GROUP_FILTER_ADD: 'LABEL_TO_GROUP_FILTER_ADD',
  LABEL_FROM_GROUP_FILTER_REMOVE: 'LABEL_FROM_GROUP_FILTER_REMOVE',

  /* Customer */

  CUSTOMER_CREATE: 'CUSTOMER_CREATE',
  CUSTOMER_CREATE__SUCCESS: 'CUSTOMER_CREATE__SUCCESS',
  CUSTOMER_CREATE__FAILURE: 'CUSTOMER_CREATE__FAILURE',
  CUSTOMER_CREATE_HANDLE: 'CUSTOMER_CREATE_HANDLE',
  CUSTOMER_FETCH: 'CUSTOMER_FETCH',
  CUSTOMER_FETCH__SUCCESS: 'CUSTOMER_FETCH__SUCCESS',
  CUSTOMER_FETCH__FAILURE: 'CUSTOMER_FETCH__FAILURE',
  CUSTOMER_UPDATE: 'CUSTOMER_UPDATE',
  CUSTOMER_UPDATE__SUCCESS: 'CUSTOMER_UPDATE__SUCCESS',
  CUSTOMER_UPDATE__FAILURE: 'CUSTOMER_UPDATE__FAILURE',
  CUSTOMER_UPDATE_HANDLE: 'CUSTOMER_UPDATE_HANDLE',
  CUSTOMER_DELETE: 'CUSTOMER_DELETE',
  CUSTOMER_DELETE__SUCCESS: 'CUSTOMER_DELETE__SUCCESS',
  CUSTOMER_DELETE__FAILURE: 'CUSTOMER_DELETE__FAILURE',
  CUSTOMER_DELETE_HANDLE: 'CUSTOMER_DELETE_HANDLE',

  /* Import */
  GROUP_IMPORT: 'GROUP_IMPORT',
  GROUP_IMPORT__SUCCESS: 'GROUP_IMPORT__SUCCESS',
  GROUP_IMPORT__FAILURE: 'GROUP_IMPORT__FAILURE',
  GROUP_IMPORT_ERROR_CLEAR: 'GROUP_IMPORT_ERROR_CLEAR',

  /* Address */

  ADDRESS_CREATE: 'ADDRESS_CREATE',
  ADDRESS_CREATE__SUCCESS: 'ADDRESS_CREATE__SUCCESS',
  ADDRESS_CREATE__FAILURE: 'ADDRESS_CREATE__FAILURE',
  ADDRESS_CREATE_HANDLE: 'ADDRESS_CREATE_HANDLE',
  ADDRESS_UPDATE: 'ADDRESS_UPDATE',
  ADDRESS_UPDATE__SUCCESS: 'ADDRESS_UPDATE__SUCCESS',
  ADDRESS_UPDATE__FAILURE: 'ADDRESS_UPDATE__FAILURE',
  ADDRESS_UPDATE_HANDLE: 'ADDRESS_UPDATE_HANDLE',
  ADDRESS_DELETE: 'ADDRESS_DELETE',
  ADDRESS_DELETE__SUCCESS: 'ADDRESS_DELETE__SUCCESS',
  ADDRESS_DELETE__FAILURE: 'ADDRESS_DELETE__FAILURE',
  ADDRESS_DELETE_HANDLE: 'ADDRESS_DELETE_HANDLE',

  /* Product */

  PRODUCT_CREATE: 'PRODUCT_CREATE',
  PRODUCT_CREATE__SUCCESS: 'PRODUCT_CREATE__SUCCESS',
  PRODUCT_CREATE__FAILURE: 'PRODUCT_CREATE__FAILURE',
  PRODUCT_CREATE_HANDLE: 'PRODUCT_CREATE_HANDLE',
  PRODUCT_FETCH: 'PRODUCT_FETCH',
  PRODUCT_FETCH__SUCCESS: 'PRODUCT_FETCH__SUCCESS',
  PRODUCT_FETCH__FAILURE: 'PRODUCT_FETCH__FAILURE',
  PRODUCT_UPDATE: 'PRODUCT_UPDATE',
  PRODUCT_UPDATE__SUCCESS: 'PRODUCT_UPDATE__SUCCESS',
  PRODUCT_UPDATE__FAILURE: 'PRODUCT_UPDATE__FAILURE',
  PRODUCT_UPDATE_HANDLE: 'PRODUCT_UPDATE_HANDLE',
  PRODUCT_TRANSFER: 'PRODUCT_TRANSFER',
  PRODUCT_TRANSFER__SUCCESS: 'PRODUCT_TRANSFER__SUCCESS',
  PRODUCT_TRANSFER__FAILURE: 'PRODUCT_TRANSFER__FAILURE',
  PRODUCT_DELETE: 'PRODUCT_DELETE',
  PRODUCT_DELETE__SUCCESS: 'PRODUCT_DELETE__SUCCESS',
  PRODUCT_DELETE__FAILURE: 'PRODUCT_DELETE__FAILURE',
  PRODUCT_DELETE_HANDLE: 'PRODUCT_DELETE_HANDLE',

  /* Products */
  PRODUCTS_FETCH: 'PRODUCTS_FETCH',
  PRODUCTS_FETCH__SUCCESS: 'PRODUCTS_FETCH__SUCCESS',
  PRODUCTS_FETCH__FAILURE: 'PRODUCTS_FETCH__FAILURE',

  /* Sellitem */

  SELLITEM_CREATE: 'SELLITEM_CREATE',
  SELLITEM_CREATE__SUCCESS: 'SELLITEM_CREATE__SUCCESS',
  SELLITEM_CREATE__FAILURE: 'SELLITEM_CREATE__FAILURE',
  SELLITEM_CREATE_HANDLE: 'SELLITEM_CREATE_HANDLE',
  SELLITEM_FETCH: 'SELLITEM_FETCH',
  SELLITEM_FETCH__SUCCESS: 'SELLITEM_FETCH__SUCCESS',
  SELLITEM_FETCH__FAILURE: 'SELLITEM_FETCH__FAILURE',
  SELLITEM_UPDATE: 'SELLITEM_UPDATE',
  SELLITEM_UPDATE__SUCCESS: 'SELLITEM_UPDATE__SUCCESS',
  SELLITEM_UPDATE__FAILURE: 'SELLITEM_UPDATE__FAILURE',
  SELLITEM_UPDATE_HANDLE: 'SELLITEM_UPDATE_HANDLE',
  SELLITEMS_UPDATE: 'SELLITEMS_UPDATE',
  SELLITEMS_UPDATE__SUCCESS: 'SELLITEMS_UPDATE__SUCCESS',
  SELLITEMS_UPDATE__FAILURE: 'SELLITEMS_UPDATE__FAILURE',
  SELLITEMS_UPDATE_HANDLE: 'SELLITEMS_UPDATE_HANDLE',
  SELLITEM_TRANSFER: 'SELLITEM_TRANSFER',
  SELLITEM_TRANSFER__SUCCESS: 'SELLITEM_TRANSFER__SUCCESS',
  SELLITEM_TRANSFER__FAILURE: 'SELLITEM_TRANSFER__FAILURE',
  SELLITEM_DELETE: 'SELLITEM_DELETE',
  SELLITEM_DELETE__SUCCESS: 'SELLITEM_DELETE__SUCCESS',
  SELLITEM_DELETE__FAILURE: 'SELLITEM_DELETE__FAILURE',
  SELLITEM_DELETE_HANDLE: 'SELLITEM_DELETE_HANDLE',
  SELLITEM_ADD_TODO: 'SELLITEM_ADD_TODO',
  SELLITEM_TOGGLE_TODO: 'SELLITEM_TOGGLE_TODO',

  /* Sellitems */
  SELLITEMS_FETCH: 'SELLITEMS_FETCH',
  SELLITEMS_FETCH__SUCCESS: 'SELLITEMS_FETCH__SUCCESS',
  SELLITEMS_FETCH__FAILURE: 'SELLITEMS_FETCH__FAILURE',

  /* Shipment */

  SHIPMENT_CREATE: 'SHIPMENT_CREATE',
  SHIPMENT_CREATE__SUCCESS: 'SHIPMENT_CREATE__SUCCESS',
  SHIPMENT_CREATE__FAILURE: 'SHIPMENT_CREATE__FAILURE',
  SHIPMENT_CREATE_HANDLE: 'SHIPMENT_CREATE_HANDLE',
  SHIPMENT_FETCH: 'SHIPMENT_FETCH',
  SHIPMENT_FETCH__SUCCESS: 'SHIPMENT_FETCH__SUCCESS',
  SHIPMENT_FETCH__FAILURE: 'SHIPMENT_FETCH__FAILURE',
  SHIPMENT_UPDATE: 'SHIPMENT_UPDATE',
  SHIPMENT_UPDATE__SUCCESS: 'SHIPMENT_UPDATE__SUCCESS',
  SHIPMENT_UPDATE__FAILURE: 'SHIPMENT_UPDATE__FAILURE',
  SHIPMENT_UPDATE_HANDLE: 'SHIPMENT_UPDATE_HANDLE',
  SHIPMENT_DELETE: 'SHIPMENT_DELETE',
  SHIPMENT_DELETE__SUCCESS: 'SHIPMENT_DELETE__SUCCESS',
  SHIPMENT_DELETE__FAILURE: 'SHIPMENT_DELETE__FAILURE',
  SHIPMENT_DELETE_HANDLE: 'SHIPMENT_DELETE_HANDLE',

  SHIPLINE_CREATE: 'SHIPLINE_CREATE',
  SHIPLINE_CREATE__SUCCESS: 'SHIPLINE_CREATE__SUCCESS',
  SHIPLINE_CREATE__FAILURE: 'SHIPLINE_CREATE__FAILURE',
  SHIPLINE_CREATE_HANDLE: 'SHIPLINE_CREATE_HANDLE',
  SHIPLINE_UPDATE: 'SHIPLINE_UPDATE',
  SHIPLINE_UPDATE__SUCCESS: 'SHIPLINE_UPDATE__SUCCESS',
  SHIPLINE_UPDATE__FAILURE: 'SHIPLINE_UPDATE__FAILURE',
  SHIPLINE_UPDATE_HANDLE: 'SHIPLINE_UPDATE_HANDLE',
  SHIPLINE_DELETE: 'SHIPLINE_DELETE',
  SHIPLINE_DELETE__SUCCESS: 'SHIPLINE_DELETE__SUCCESS',
  SHIPLINE_DELETE__FAILURE: 'SHIPLINE_DELETE__FAILURE',
  SHIPLINE_DELETE_HANDLE: 'SHIPLINE_DELETE_HANDLE',

  /* Shipments */
  SHIPMENTS_FETCH: 'SHIPMENTS_FETCH',
  SHIPMENTS_FETCH__SUCCESS: 'SHIPMENTS_FETCH__SUCCESS',
  SHIPMENTS_FETCH__FAILURE: 'SHIPMENTS_FETCH__FAILURE',

  /* Payment */

  PAYMENT_CREATE: 'PAYMENT_CREATE',
  PAYMENT_CREATE__SUCCESS: 'PAYMENT_CREATE__SUCCESS',
  PAYMENT_CREATE__FAILURE: 'PAYMENT_CREATE__FAILURE',
  PAYMENT_CREATE_HANDLE: 'PAYMENT_CREATE_HANDLE',
  PAYMENT_UPDATE: 'PAYMENT_UPDATE',
  PAYMENT_UPDATE__SUCCESS: 'PAYMENT_UPDATE__SUCCESS',
  PAYMENT_UPDATE__FAILURE: 'PAYMENT_UPDATE__FAILURE',
  PAYMENT_UPDATE_HANDLE: 'PAYMENT_UPDATE_HANDLE',
  PAYMENT_DELETE: 'PAYMENT_DELETE',
  PAYMENT_DELETE__SUCCESS: 'PAYMENT_DELETE__SUCCESS',
  PAYMENT_DELETE__FAILURE: 'PAYMENT_DELETE__FAILURE',
  PAYMENT_DELETE_HANDLE: 'PAYMENT_DELETE_HANDLE',

  /* Attachment */

  ATTACHMENT_CREATE: 'ATTACHMENT_CREATE',
  ATTACHMENT_CREATE__SUCCESS: 'ATTACHMENT_CREATE__SUCCESS',
  ATTACHMENT_CREATE__FAILURE: 'ATTACHMENT_CREATE__FAILURE',
  ATTACHMENT_CREATE_HANDLE: 'ATTACHMENT_CREATE_HANDLE',
  ATTACHMENT_UPDATE: 'ATTACHMENT_UPDATE',
  ATTACHMENT_UPDATE__SUCCESS: 'ATTACHMENT_UPDATE__SUCCESS',
  ATTACHMENT_UPDATE__FAILURE: 'ATTACHMENT_UPDATE__FAILURE',
  ATTACHMENT_UPDATE_HANDLE: 'ATTACHMENT_UPDATE_HANDLE',
  ATTACHMENT_DELETE: 'ATTACHMENT_DELETE',
  ATTACHMENT_DELETE__SUCCESS: 'ATTACHMENT_DELETE__SUCCESS',
  ATTACHMENT_DELETE__FAILURE: 'ATTACHMENT_DELETE__FAILURE',
  ATTACHMENT_DELETE_HANDLE: 'ATTACHMENT_DELETE_HANDLE',
  ATTACHMENT_IMGUR_CREATE: 'ATTACHMENT_IMGUR_CREATE',
  ATTACHMENT_IMGUR_CREATE__SUCCESS: 'ATTACHMENT_IMGUR_CREATE__SUCCESS',
  ATTACHMENT_IMGUR_CREATE__FAILURE: 'ATTACHMENT_IMGUR_CREATE__FAILURE',

  /* Actions */

  ACTIONS_FETCH: 'ACTIONS_FETCH',
  ACTIONS_FETCH__SUCCESS: 'ACTIONS_FETCH__SUCCESS',
  ACTIONS_FETCH__FAILURE: 'ACTIONS_FETCH__FAILURE',

  /* Action */

  ACTION_CREATE_HANDLE: 'ACTION_CREATE_HANDLE',
  ACTION_UPDATE_HANDLE: 'ACTION_UPDATE_HANDLE',
  ACTION_DELETE_HANDLE: 'ACTION_DELETE_HANDLE',

  /* Comment action */

  COMMENT_ACTION_CREATE: 'COMMENT_ACTION_CREATE',
  COMMENT_ACTION_CREATE__SUCCESS: 'COMMENT_ACTION_CREATE__SUCCESS',
  COMMENT_ACTION_CREATE__FAILURE: 'COMMENT_ACTION_CREATE__FAILURE',
  COMMENT_ACTION_UPDATE: 'COMMENT_ACTION_UPDATE',
  COMMENT_ACTION_UPDATE__SUCCESS: 'COMMENT_ACTION_UPDATE__SUCCESS',
  COMMENT_ACTION_UPDATE__FAILURE: 'COMMENT_ACTION_UPDATE__FAILURE',
  COMMENT_ACTION_DELETE: 'COMMENT_ACTION_DELETE',
  COMMENT_ACTION_DELETE__SUCCESS: 'COMMENT_ACTION_DELETE__SUCCESS',
  COMMENT_ACTION_DELETE__FAILURE: 'COMMENT_ACTION_DELETE__FAILURE',

  /* Notification */

  NOTIFICATION_CREATE_HANDLE: 'NOTIFICATION_CREATE_HANDLE',
  NOTIFICATION_DELETE: 'NOTIFICATION_DELETE',
  NOTIFICATION_DELETE__SUCCESS: 'NOTIFICATION_DELETE__SUCCESS',
  NOTIFICATION_DELETE__FAILURE: 'NOTIFICATION_DELETE__FAILURE',
  NOTIFICATION_DELETE_HANDLE: 'NOTIFICATION_DELETE_HANDLE',

  /* Customer */
  CUSTOMERS_SEARCH: 'CUSTOMERS_SEARCH',
  CUSTOMERS_FETCH: 'CUSTOMERS_FETCH',
  CUSTOMERS_FETCH__SUCCESS: 'CUSTOMERS_FETCH__SUCCESS',
  CUSTOMERS_FETCH__FAILURE: 'CUSTOMERS_FETCH__FAILURE',

  /* Stock */
  STOCK_CREATE: 'STOCK_CREATE',
  STOCK_CREATE__SUCCESS: 'STOCK_CREATE__SUCCESS',
  STOCK_CREATE__FAILURE: 'STOCK_CREATE__FAILURE',
  STOCK_UPDATE: 'STOCK_UPDATE',
  STOCK_UPDATE__SUCCESS: 'STOCK_UPDATE__SUCCESS',
  STOCK_UPDATE__FAILURE: 'STOCK_UPDATE__FAILURE',

  /* Filter */
  ORDER_DATE_TO_GROUP_FILTER_CHANGE: 'ORDER_DATE_TO_GROUP_FILTER_CHANGE',
  ARRIVAL_DATE_TO_GROUP_FILTER_CHANGE: 'ARRIVAL_DATE_TO_GROUP_FILTER_CHANGE',
  WEB_ORDER_NO_TO_GROUP_FILTER_CHANGE: 'WEB_ORDER_NO_TO_GROUP_FILTER_CHANGE',
  ARRIVAL_DATE_FOR_SHIPMENT_PAGE_TO_GROUP_FILTER_CHANGE:
    'ARRIVAL_DATE_FOR_SHIPMENT_PAGE_TO_GROUP_FILTER_CHANGE',
  STATUS_TO_GROUP_FILTER_ADD: 'STATUS_TO_GROUP_FILTER_ADD',
  STATUS_FROM_GROUP_FILTER_REMOVE: 'STATUS_FROM_GROUP_FILTER_REMOVE',
  SHIPMENT_STATUS_TO_GROUP_FILTER_ADD: 'SHIPMENT_STATUS_TO_GROUP_FILTER_ADD',
  SHIPMENT_STATUS_FROM_GROUP_FILTER_REMOVE: 'SHIPMENT_STATUS_FROM_GROUP_FILTER_REMOVE',
  SHIP_PROVINCE_TO_GROUP_FILTER_ADD: 'SHIP_PROVINCE_TO_GROUP_FILTER_ADD',
  SHIP_PROVINCE_FROM_GROUP_FILTER_REMOVE: 'SHIP_PROVINCE_FROM_GROUP_FILTER_REMOVE',

  /* Dashboard */
  DASHBOARD_FETCH: 'DASHBOARD_FETCH',
  DASHBOARD_FETCH__SUCCESS: 'DASHBOARD_FETCH__SUCCESS',
  DASHBOARD_FETCH__FAILURE: 'DASHBOARD_FETCH__FAILURE',

  /* Facebook */
  SEND_MESSAGE_FACEBOOK: 'SEND_MESSAGE_FACEBOOK',
  SEND_MESSAGE_FACEBOOK__SUCCESS: 'SEND_MESSAGE_FACEBOOK__SUCCESS',
  SEND_MESSAGE_FACEBOOK__FAILURE: 'SEND_MESSAGE_FACEBOOK__FAILURE',
};
