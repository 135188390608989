import { all, takeEvery } from 'redux-saga/effects';

import { clearAppErrorService, initializeCoreService } from '../services';
import EntryActionTypes from '../../../constants/EntryActionTypes';

export default function* coreWatchers() {
  yield all([
    takeEvery(EntryActionTypes.CORE_INITIALIZE, () => initializeCoreService()),
    takeEvery(EntryActionTypes.APP_ERROR_CLEAR, () => clearAppErrorService()),
  ]);
}
