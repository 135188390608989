import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Form } from 'semantic-ui-react';
import { withPopup, closePopup } from '../../lib/popup';
import { Input, Popup } from '../../lib/custom-ui';

import { useForm } from '../../hooks';

import styles from './StockAdd.module.scss';

const SellitemAdd = React.memo(({ onCreate }) => {
  const [t] = useTranslation();

  const [data, handleFieldChange] = useForm({
    quantity: '',
    webPrice: '',
    exchangeRate: '',
    note: '',
  });

  const quantityField = useRef(null);

  const handleSubmit = useCallback(() => {
    const cleanData = {
      ...data,
      quantity: data.quantity.trim(),
      webPrice: data.webPrice.trim(),
      exchangeRate: data.exchangeRate.trim(),
      note: data.note.trim(),
    };

    if (!cleanData.quantity) {
      quantityField.current.select();
      return;
    }

    if (!cleanData.webPrice) {
      cleanData.webPrice = null;
    }

    if (!cleanData.exchangeRate) {
      cleanData.exchangeRate = undefined;
    }

    if (!cleanData.note) {
      cleanData.note = undefined;
    }

    onCreate(cleanData);
    closePopup();
  }, [onCreate, data]);

  useEffect(() => {
    quantityField.current.select();
  }, []);

  return (
    <>
      <Popup.Header>
        {t('common.addStock', {
          context: 'title',
        })}
      </Popup.Header>
      <Popup.Content>
        <Form onSubmit={handleSubmit}>
          <Input
            fluid
            ref={quantityField}
            name="quantity"
            value={data.quantity}
            className={styles.field}
            placeholder={`${t('common.quantity')} *`}
            onChange={handleFieldChange}
          />
          <Input
            fluid
            name="webPrice"
            value={data.webPrice}
            className={styles.field}
            placeholder={t('common.webPrice')}
            onChange={handleFieldChange}
          />
          <Input
            fluid
            name="exchangeRate"
            value={data.exchangeRate}
            className={styles.field}
            placeholder={t('common.exchangeRate')}
            onChange={handleFieldChange}
          />
          <Input
            fluid
            name="note"
            value={data.note}
            className={styles.field}
            placeholder={t('common.note')}
            onChange={handleFieldChange}
          />
          <Button positive content={t('action.addStock')} />
        </Form>
      </Popup.Content>
    </>
  );
});

SellitemAdd.propTypes = {
  onCreate: PropTypes.func.isRequired,
};

export default withPopup(SellitemAdd);
