import React from 'react';
import ReactDOM from 'react-dom';

import DatePicker from 'react-datepicker';
import Handsontable from 'handsontable';
// import { Statuses } from '../../constants/Enums';

// import styles from './DateEditor.module.scss';

export default class DatePickerEditor extends Handsontable.editors.TextEditor {
  init() {
    this.onChange = this.onChange.bind(this);
    this.date = null;
  }

  setValue(value) {
    this.date = value;
  }

  getValue() {
    return this.date;
  }

  open() {
    super.open();
    this.render();
  }

  close() {
    super.close();
    this.instance.listen();
    Handsontable.dom.empty(this.TEXTAREA_PARENT);
    Handsontable.dom.addClass(this.TEXTAREA_PARENT, 'handsontableInputHolder');
    this.TEXTAREA_PARENT.appendChild(this.TEXTAREA);
    if (this.isOpened()) this.instance.selectCell(this.row, this.col);
  }

  focus() {
    super.focus();
    // this.instance.listen();
    // if (this.inputRef.current) {
    //   this.inputRef.current.focus();
    // }
  }

  onChange(value) {
    let newDate;
    if (value) {
      if (value instanceof Date) {
        newDate = value.toISOString();
      } else if (value.includes('/')) {
        const [d, M, yyyy] = value.split('/');
        newDate = new Date(yyyy, M - 1, d).toISOString();
      }
    }
    if (newDate !== this.date) {
      this.setValue(newDate);
    }
    // this.setValue(value);
    // this.setValue(this.date ? value : value.toISOString());
    this.finishEditing(false, false, () => setTimeout(() => this.close()));
  }

  onBeforeKeyDown(event) {
    super.onBeforeKeyDown(event);

    const keys = Handsontable.helper.KEY_CODES;
    switch (event.keyCode) {
      case keys.ARROW_LEFT:
      case keys.ARROW_RIGHT:
      case keys.ARROW_UP:
      case keys.ARROW_DOWN:
        event.stopImmediatePropagation();

        break;

      case keys.ENTER:
      case keys.ESCAPE: {
        // this.close();
        this.finishEditing(true, false, () => setTimeout(() => this.close()));
        break;
      }

      default:
        break;
    }
  }

  // prepare(row, col, prop, td, originalValue, cellProperties) {
  //   super.prepare(row, col, prop, td, originalValue, cellProperties);

  // }

  render() {
    let selected = new Date();
    if (this.date) {
      if (this.date.includes('/')) {
        const [d, M, yyyy] = this.date.split('/');
        selected = new Date(yyyy, M - 1, d);
      } else {
        selected = new Date(this.date);
      }
    }
    const renderResult = (
      <DatePicker
        autoFocus
        inline
        dateFormat="dd/MM/yyyy"
        selected={selected}
        onBlur={() => this.finishEditing()}
        onChange={this.onChange}
      />
    );
    Handsontable.dom.empty(this.TEXTAREA_PARENT);
    Handsontable.dom.addClass(this.TEXTAREA_PARENT, 'handsontableInputHolder');
    const div = document.createElement('div');
    // div.classList.add(styles.wrapper1);
    ReactDOM.render(renderResult, div);

    // this.TEXTAREA.value = this.getValue();
    // this.TEXTAREA_PARENT.appendChild(this.TEXTAREA);
    this.TEXTAREA_PARENT.appendChild(div);

    if (
      this.TEXTAREA_PARENT.offsetTop + this.TEXTAREA_PARENT.clientHeight + 20 >
      this.TEXTAREA_PARENT.offsetParent.clientHeight
    ) {
      this.TEXTAREA_PARENT.style.top = `${
        this.TEXTAREA_PARENT.offsetTop - this.TEXTAREA_PARENT.clientHeight + 5
      }px`;
    } else {
      this.TEXTAREA_PARENT.style.top = `${this.TEXTAREA_PARENT.offsetTop + this.TD.clientHeight}px`;
    }

    if (
      this.TEXTAREA_PARENT.offsetLeft + this.TEXTAREA_PARENT.clientWidth >
      this.TEXTAREA_PARENT.offsetParent.clientWidth
    ) {
      this.TEXTAREA_PARENT.style.left = `${
        this.TEXTAREA_PARENT.offsetLeft - this.TEXTAREA_PARENT.clientWidth + this.TD.clientWidth
      }px`;
    }
  }
}
