import EntryActionTypes from '../../constants/EntryActionTypes';

export const createCategoryInCurrentBusiness = (data) => ({
  type: EntryActionTypes.CATEGORY_IN_CURRENT_BUSINESS_CREATE,
  payload: {
    data,
  },
});

export const handleCategoryCreate = (category) => ({
  type: EntryActionTypes.CATEGORY_CREATE_HANDLE,
  payload: {
    category,
  },
});

export const updateCategory = (id, data) => ({
  type: EntryActionTypes.CATEGORY_UPDATE,
  payload: {
    id,
    data,
  },
});

export const handleCategoryUpdate = (category) => ({
  type: EntryActionTypes.CATEGORY_UPDATE_HANDLE,
  payload: {
    category,
  },
});

export const deleteCategory = (id) => ({
  type: EntryActionTypes.CATEGORY_DELETE,
  payload: {
    id,
  },
});

export const handleCategoryDelete = (category) => ({
  type: EntryActionTypes.CATEGORY_DELETE_HANDLE,
  payload: {
    category,
  },
});

export const addCategoryToFilterInCurrentGroup = (id) => ({
  type: EntryActionTypes.CATEGORY_TO_FILTER_IN_CURRENT_GROUP_ADD,
  payload: {
    id,
  },
});

export const removeCategoryFromFilterInCurrentGroup = (id) => ({
  type: EntryActionTypes.CATEGORY_FROM_FILTER_IN_CURRENT_GROUP_REMOVE,
  payload: {
    id,
  },
});
