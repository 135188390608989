import React, { useCallback, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { Button, Form } from 'semantic-ui-react';
import { Input } from '../../lib/custom-ui';
import { usePrevious } from '../../lib/hooks';

import SearchCustomers from '../SearchCustomers';
import AddPopup from '../Customers/AddPopup';
import { useForm } from '../../hooks';
import { Statuses } from '../../constants/Enums';

import styles from './AddOrder.module.scss';

const customStyles = (isError) => ({
  control: (base) => ({
    ...base,
    ...(isError && { borderColor: '#e0b4b4', color: '#9f3a38' }),
  }),
  placeholder: (base) => ({
    ...base,
    ...(isError && { borderColor: '#e0b4b4', color: '#9f3a38' }),
  }),
});

const AddOrder = React.memo(
  ({
    defaultData,
    isSubmitting,
    error,
    product,
    customer,
    onChangeColor,
    searchResults,
    onCreateCustomer,
    onCreateSellitem,
    onSearchCustomers,
  }) => {
    const [t] = useTranslation();
    const wasSubmitting = usePrevious(isSubmitting);

    const [data, handleFieldChange, setData] = useForm({
      ...defaultData,
      ...product,
      color: product.color || '',
      size: product.size || '',
      quantity: 1,
    });

    const [customerSelected, setCustomerSelected] = useState([]);

    const [sizeOptions, setSizeOptions] = useState([]);

    const colorOptions = useMemo(() => {
      if (product.variation && product.variation.sizes) {
        setSizeOptions(
          product.variation.sizes.map((it) => ({
            key: it.size,
            value: it.sizeCode,
            label: it.name,
            sizeCode: it.sizeCode,
          })),
        );
      } else {
        setSizeOptions([]);
      }
      if (product.variation && product.variation.colors) {
        return product.variation.colors.map((it) => ({
          key: it.colorId,
          value: it.colorCode,
          label: it.name,
          colorCode: it.colorCode,
          webCode: it.webCode,
        }));
      }
      return [];
    }, [product.variation]);

    const [errors, setErrors] = useState({
      customer: false,
      color: false,
      size: false,
      quantity: false,
    });

    const handleSubmit = useCallback(() => {
      let colorCode;
      let sizeCode;
      // let webCode;
      // webCode = product.webCode;

      const err = {
        customer: !customerSelected,
        color: colorOptions.length > 0 && !data.color,
        size: sizeOptions.length > 0 && !data.size,
        quantity: !data.quantity || data.quantity < 1,
      };
      setErrors(err);

      if (Object.values(err).some((e) => e)) {
        return;
      }

      const cleanData = {
        ...product,
        color: data.color || undefined,
        size: data.size || undefined,
        quantity: data.quantity,
        // customerId: customerSelected.id,
        // invoice: `${customerSelected.code}-${t('format:longDate', {
        //   postProcess: 'formatDate',
        //   value: new Date(),
        // })}`,
        colorCode,
        sizeCode,
        productId: product.id,
        status: Statuses.NEWORDER.name,
        webCode: data.webCode || product.webCode,
        createdAt: new Date(),
      };

      customerSelected.forEach((c) => {
        cleanData.customerId = c.id;
        onCreateSellitem(cleanData);
      });
      // setCustomerSelected([]);
      // onCreateSellitem(cleanData);
    }, [product, colorOptions, data, sizeOptions, customerSelected, onCreateSellitem]);

    const handleCreateCustomer = useCallback(
      (c) => {
        onSearchCustomers(c.name);
        onCreateCustomer(c);
      },
      [onCreateCustomer, onSearchCustomers],
    );

    const handleResultSelect = useCallback((customers) => {
      setCustomerSelected(customers);
    }, []);

    const handleColorFieldChange = useCallback(
      (opt) => {
        setData((prev) => ({
          ...prev,
          color: opt.label,
          colorCode: opt.colorCode,
          size: '',
          sizeCode: undefined,
          webCode: opt.webCode,
        }));

        if (product.variation && product.variation.colors) {
          const sizesOfColor = product.variation.colors.find(
            (item) => item.colorCode === opt.colorCode,
          );
          if (sizesOfColor && sizesOfColor.sizes) {
            setSizeOptions(
              sizesOfColor.sizes.map((it) => ({
                key: it.size,
                value: it.sizeCode,
                label: it.name,
              })),
            );
          }
          onChangeColor(opt.label);
        }
      },
      [onChangeColor, product.variation, setData],
    );

    const handleSizeFieldChange = useCallback(
      (opt) => {
        setData((prev) => ({ ...prev, size: opt.label, sizeCode: opt.sizeCode }));
      },
      [setData],
    );

    useEffect(() => {
      if (wasSubmitting && !isSubmitting) {
        if (!error) {
          setData({
            ...defaultData,
            color: defaultData.color || '',
            size: defaultData.size || '',
            customerId: 0,
          });
        }
      }
    }, [wasSubmitting, error, isSubmitting, defaultData, setData]);

    useEffect(() => {
      setData({
        color: product.color || '',
        size: product.size || '',
        quantity: 1,
      });
    }, [product.id, product.color, product.size, setData]);

    // add to search customer input when create new customer
    useEffect(() => {
      if (customer) {
        const { id, code, name, phone, addressId } = customer;
        if (code) {
          setCustomerSelected((prev) => [
            ...prev,
            {
              id,
              code,
              title: name,
              phone,
              value: id,
              label: `[${code}] ${name}`,
              addressId,
            },
          ]);
        }
      }
    }, [customer]);

    console.log('AddOrder component');

    return (
      <Form className={styles.formAddSellitem}>
        <Form.Group className={styles.fieldGroup}>
          <AddPopup onCreate={handleCreateCustomer} onSearchCustomers={onSearchCustomers}>
            <Button circular size="mini" icon="user add" className={styles.addCustomerButton} />
          </AddPopup>
          <Form.Field
            width={10}
            required
            error={errors.customer}
            onFocus={() => setErrors({ ...errors, customer: false })}
          >
            <SearchCustomers
              isMulti
              customerSelected={customerSelected}
              searchResults={searchResults}
              onSearchCustomers={onSearchCustomers}
              onCustomerSelected={handleResultSelect}
            />
          </Form.Field>
          <Form.Field width={3} required>
            {colorOptions.length > 0 ? (
              <Select
                name="color"
                components={{ DropdownIndicator: null }}
                options={colorOptions}
                onChange={handleColorFieldChange}
                onFocus={() => setErrors({ ...errors, color: false })}
                placeholder={t('common.selectColors')}
                noOptionsMessage={() => null}
                styles={customStyles(errors.color)}
                value={colorOptions.find((it) => it.label === data.color) || ''}
              />
            ) : (
              <Input
                fluid
                name="color"
                value={data.color}
                className={styles.field}
                placeholder={`${t('common.color')}`}
                onChange={handleFieldChange}
              />
            )}
          </Form.Field>
          <Form.Field width={2} required>
            {sizeOptions.length > 0 ? (
              <Select
                name="size"
                components={{ DropdownIndicator: null }}
                options={sizeOptions}
                onChange={handleSizeFieldChange}
                onFocus={() => setErrors({ ...errors, size: false })}
                placeholder={t('common.selectSizes')}
                noOptionsMessage={() => null}
                styles={customStyles(errors.size)}
                value={sizeOptions.find((it) => it.label === data.size) || ''}
              />
            ) : (
              <Input
                fluid
                name="size"
                value={data.size}
                className={styles.field}
                placeholder={`${t('common.size')}`}
                onChange={handleFieldChange}
              />
            )}
          </Form.Field>
          <Form.Field
            width={1}
            required
            error={errors.quantity}
            onFocus={() => setErrors({ ...errors, quantity: false })}
          >
            <Input
              fluid
              type="number"
              name="quantity"
              value={data.quantity}
              className={styles.field}
              placeholder={`${t('common.quantity')} *`}
              onChange={handleFieldChange}
            />
          </Form.Field>
          <Form.Field>
            <Button
              positive
              size="medium"
              content={t('action.save')}
              onClick={handleSubmit}
              disabled={customerSelected.length === 0}
            />
          </Form.Field>
        </Form.Group>
      </Form>
    );
  },
);

AddOrder.propTypes = {
  defaultData: PropTypes.objectOf(PropTypes.any).isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  error: PropTypes.string,
  product: PropTypes.objectOf(PropTypes.any).isRequired,
  customer: PropTypes.objectOf(PropTypes.any),
  onCreateCustomer: PropTypes.func.isRequired,
  onCreateSellitem: PropTypes.func.isRequired,
  searchResults: PropTypes.objectOf(PropTypes.any).isRequired,
  onSearchCustomers: PropTypes.func.isRequired,
  onChangeColor: PropTypes.func.isRequired,
};

AddOrder.defaultProps = {
  error: undefined,
  customer: undefined,
};

export default AddOrder;
