/* eslint-disable no-unused-vars */
import { call, put, select, take } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { format } from 'date-fns';

import request from '../request';
import {
  currentGroupSelector,
  currentCustomerSelector,
  isCoreInitializingSelector,
  notificationIdsForCurrentSellitemSelector,
  pathsMatchSelector,
  currentProductSelector,
  currentShipmentSelector,
  defaultGroupSelector,
  groupByIdSelector,
  lastShipmentIdByGroupIdSelector,
  locationSelector,
} from '../../../selectors';
import {
  handleLocationChange,
  fetchProduct,
  fetchShipment,
  fetchShipments,
  fetchDashboard,
  changeArrivalDateForShipmentPageToGroupFilter,
} from '../../../actions';
import api from '../../../api';
import ActionTypes from '../../../constants/ActionTypes';
import Paths from '../../../constants/Paths';
import { defaultArrivalRangeForShipmentPage } from '../../../utils/date-range';

export function* goToRootService() {
  yield put(push(Paths.ROOT));
}

export function* goToBusinessService(businessId) {
  yield put(push(Paths.BUSINESSES.replace(':id', businessId)));
}

export function* goToGroupService(groupId) {
  yield put(push(Paths.GROUPS.replace(':id', groupId)));
}

export function* goToCustomerService(customerId) {
  yield put(push(Paths.CUSTOMERS.replace(':id', customerId)));
}

export function* goToProductService(productId) {
  yield put(push(Paths.PRODUCTS.replace(':id', productId)));
}

export function* goToShipmentService(shipmentId) {
  yield put(push(Paths.SHIPMENTS.replace(':id', shipmentId)));
}

export function* goToSellitemService(sellitemId) {
  yield put(push(Paths.SELLITEMS.replace(':id', sellitemId)));
}

export function* handleLocationChangeService() {
  const pathsMatch = yield select(pathsMatchSelector);

  if (!pathsMatch) {
    return;
  }

  switch (pathsMatch.path) {
    case Paths.LOGIN:
      yield call(goToRootService);

      break;
    default:
  }

  const isCoreInitializing = yield select(isCoreInitializingSelector);

  if (isCoreInitializing) {
    yield take(ActionTypes.CORE_INITIALIZE);
  }

  let group;
  let customer;
  let customerGroup;
  let users;
  let businesses;
  let groupMemberships;
  let labels;
  let customers;
  let addresses;
  let sellitems;
  let shipmentPageSellitems;
  let sellitemMemberships;
  let sellitemLabels;
  let payments;
  let attachments;
  let notifications;
  let products;
  let categories;
  let productCategories;
  let product;
  let shipments;
  let shipment;
  let shiplines;
  let stocks;
  let dashboard;
  let groupData;
  let stat;

  switch (pathsMatch.path) {
    case Paths.PRODUCTS: {
      let currentGroup = yield select(currentGroupSelector);
      const currentProduct = yield select(currentProductSelector);
      if (!currentGroup) currentGroup = yield select(defaultGroupSelector);
      if (
        (currentProduct && currentProduct.isFetching === null) ||
        (!currentProduct && currentGroup.id && pathsMatch.params.id !== currentGroup.id)
      ) {
        const productId = currentProduct ? currentProduct.id : pathsMatch.params.id;
        yield put(handleLocationChange.fetchProduct(productId));
        let productStocks;
        try {
          ({
            item: product,
            included: {
              stocks: productStocks,
              sellitems,
              customers,
              addresses,
              sellitemMemberships,
              sellitemLabels,
              payments,
              attachments,
              productCategories,
            },
          } = yield call(request, api.getProduct, productId));
        } catch (error) {} // eslint-disable-line no-empty
        stocks = [];
        if (productStocks && productStocks.length > 0)
          productStocks.forEach((stock) => {
            stocks.push({ ...stock, productId });
          });
        yield put(
          fetchProduct.success(
            product,
            sellitems,
            customers,
            addresses,
            sellitemMemberships,
            sellitemLabels,
            payments,
            attachments,
            stocks,
            productCategories,
          ),
        );
      }
      break;
    }
    case Paths.SHIPMENTS: {
      let currentGroup = yield select(currentGroupSelector);
      const currentShipment = yield select(currentShipmentSelector);

      if (!currentGroup) currentGroup = yield select(defaultGroupSelector);

      if (
        (currentShipment && currentShipment.isFetching === null) ||
        (!currentShipment && currentGroup.id && pathsMatch.params.id !== currentGroup.id)
      ) {
        const shipmentId = currentShipment ? currentShipment.id : pathsMatch.params.id;
        yield put(handleLocationChange.fetchShipment(shipmentId));
        try {
          ({
            item: shipment,
            included: { sellitems, shiplines, addresses, products, payments },
          } = yield call(request, api.getShipment, shipmentId));
        } catch (error) {
          yield put(fetchShipment.failure(shipmentId, error));
          return;
        } // eslint-disable-line no-empty
        yield put(
          fetchShipment.success(shipment, shiplines, sellitems, addresses, products, payments),
        );
      }
      break;
    }
    case Paths.SHIPMENTPAGE: {
      let currentGroup = yield select(currentGroupSelector);
      if (!currentGroup) currentGroup = yield select(defaultGroupSelector);

      if (currentGroup.id) {
        const groupId = currentGroup.id;
        const lastId = yield select(lastShipmentIdByGroupIdSelector, groupId);
        const { filterArrivalDateForShipmentPage } = yield select(groupByIdSelector, groupId);
        const { query } = yield select(locationSelector);
        const page = query?.page || 1;

        let filterArrivalDate = filterArrivalDateForShipmentPage;

        if (query.start && query.end) {
          const start = new Date(query.start);
          start.setHours(0, 0, 0);
          const end = new Date(query.end);
          end.setHours(23, 59, 59);
          filterArrivalDate = { start, end };
          // yield put(changeArrivalDateForShipmentPageToGroupFilter(filterArrivalDate, groupId));
        }

        yield put(handleLocationChange.fetchShipments(currentGroup.id));
        try {
          ({
            items: shipments,
            included: {
              shiplines,
              sellitems: shipmentPageSellitems,
              customers,
              products,
              payments,
              stat,
            },
          } = yield call(request, api.getShipments, currentGroup.id, {
            beforeId: lastId,
            filterArrivalDate,
            page,
          }));
        } catch (error) {
          yield put(fetchShipments.failure(groupId, error));
          return;
        } // eslint-disable-line no-empty
        yield put(
          fetchShipments.success(
            groupId,
            shipments,
            shiplines,
            shipmentPageSellitems,
            customers,
            products,
            payments,
            stat,
          ),
        );
      }
      break;
    }
    case Paths.GROUPS:
    case Paths.WAREHOUSE:
    case Paths.SELLITEMS: {
      const currentGroup = yield select(currentGroupSelector);
      const currentItem = { sellitemId: pathsMatch.params.id };
      if (currentGroup && currentGroup.isFetching === null) {
        yield put(handleLocationChange.fetchGroup(currentGroup.id));

        try {
          ({
            item: group,
            included: {
              users,
              businesses,
              groupMemberships,
              labels,
              customers,
              addresses,
              sellitems,
              sellitemMemberships,
              sellitemLabels,
              payments,
              attachments,
              products,
              categories,
              productCategories,
              shipments,
            },
          } = yield call(request, api.getGroup, currentGroup.id, currentItem));
        } catch (error) {} // eslint-disable-line no-empty
      }

      if (pathsMatch.path === Paths.SELLITEMS) {
        const notificationIds = yield select(notificationIdsForCurrentSellitemSelector);

        if (notificationIds && notificationIds.length > 0) {
          try {
            ({ items: notifications } = yield call(
              request,
              api.updateNotifications,
              notificationIds,
              {
                isRead: true,
              },
            ));
          } catch (error) {} // eslint-disable-line no-empty
        }

        const currentCustomer = yield select(currentCustomerSelector);
        if (currentCustomer && currentCustomer.isFetching === null) {
          yield put(handleLocationChange.fetchCustomer(currentCustomer.id));

          try {
            ({
              item: customer,
              included: {
                customerGroup,
                addresses,
                products,
                categories,
                productCategories,
                sellitems,
                sellitemMemberships,
                sellitemLabels,
                payments,
                attachments,
              },
            } = yield call(request, api.getCustomer, currentCustomer.id));
          } catch (error) {} // eslint-disable-line no-empty
        }
      }

      break;
    }
    case Paths.CUSTOMERS: {
      const currentCustomer = yield select(currentCustomerSelector);

      if (currentCustomer && currentCustomer.isFetching === null) {
        yield put(handleLocationChange.fetchCustomer(currentCustomer.id));

        try {
          ({
            item: customer,
            included: {
              customerGroup,
              addresses,
              products,
              categories,
              productCategories,
              sellitems,
              sellitemMemberships,
              sellitemLabels,
              payments,
              attachments,
            },
          } = yield call(request, api.getCustomer, currentCustomer.id));
        } catch (error) {} // eslint-disable-line no-empty
      }

      if (pathsMatch.path === Paths.SELLITEMS) {
        const notificationIds = yield select(notificationIdsForCurrentSellitemSelector);

        if (notificationIds && notificationIds.length > 0) {
          try {
            ({ items: notifications } = yield call(
              request,
              api.updateNotifications,
              notificationIds,
              {
                isRead: true,
              },
            ));
          } catch (error) {} // eslint-disable-line no-empty
        }
      }

      break;
    }
    case Paths.DASHBOARD: {
      let currentGroup = yield select(currentGroupSelector);
      if (!currentGroup) currentGroup = yield select(defaultGroupSelector);

      if (currentGroup.id) {
        const endDate = new Date();
        endDate.setHours(23, 59, 59);
        const endDateStr = format(endDate, 'yyyy-MM-dd HH:mm:ss');
        // const startDate = new Date(new Date().setDate(new Date().getDate() - 30));
        const startDate = new Date();
        startDate.setHours(0, 0, 0);
        const startDateStr = format(startDate, 'yyyy-MM-dd HH:mm:ss');

        yield put(handleLocationChange.fetchDashboard(currentGroup.id));
        try {
          ({ item: groupData, included: dashboard } = yield call(
            request,
            api.getDashboard,
            currentGroup.id,
            startDateStr,
            endDateStr,
          ));
        } catch (error) {
          yield put(fetchDashboard.failure(currentGroup.id, error));
          return;
        } // eslint-disable-line no-empty
        yield put(fetchDashboard.success(groupData, dashboard));
      }
      break;
    }
    default:
  }

  yield put(
    handleLocationChange(
      group,
      customer,
      customerGroup,
      users,
      businesses,
      groupMemberships,
      labels,
      customers,
      addresses,
      sellitems,
      sellitemMemberships,
      sellitemLabels,
      payments,
      attachments,
      notifications,
      products,
      categories,
      productCategories,
      shipments,
      stocks,
      dashboard,
    ),
  );
}
