// eslint-disable-next-line import/prefer-default-export
export const defaultRange = () => {
  const today = new Date();
  today.setHours(23, 59, 59);
  const range = {
    start: new Date(today.getFullYear(), today.getMonth() - 12, today.getDate() - 0, 0, 0, 0),
    end: today,
  };
  return range;
};

export const defaultArrivalRange = () => {
  const today = new Date();
  const range = {
    start: today,
    end: today,
  };
  return range;
};

export const defaultArrivalRangeForShipmentPage = () => {
  const today = new Date();
  today.setHours(23, 59, 59);
  const range = {
    start: new Date(today.getFullYear(), today.getMonth() - 1, today.getDate(), 0, 0, 0),
    end: today,
  };
  return range;
};
