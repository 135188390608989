import { all, takeEvery } from 'redux-saga/effects';

import {
  fetchSellitemsInCurrentGroupService,
  changeOrderDateToFilterInCurrentGroupService,
  changeArrivalDateToFilterInCurrentGroupService,
  changeWebOrderNoToFilterInCurrentGroupService,
  changeArrivalDateForShipmentPageToFilterInCurrentGroupService,
  updateSellitemsService,
  handleSellitemsUpdateService,
} from '../services';
import EntryActionTypes from '../../../constants/EntryActionTypes';

export default function* sellitemsWatchers() {
  yield all([
    takeEvery(EntryActionTypes.SELLITEMS_IN_CURRENT_GROUP_FETCH, ({ payload: { data } }) =>
      fetchSellitemsInCurrentGroupService(data),
    ),
    takeEvery(
      EntryActionTypes.ORDER_DATE_TO_FILTER_IN_CURRENT_GROUP_CHANGE,
      ({ payload: { orderDate } }) => changeOrderDateToFilterInCurrentGroupService(orderDate),
    ),
    takeEvery(
      EntryActionTypes.ARRIVAL_DATE_TO_FILTER_IN_CURRENT_GROUP_CHANGE,
      ({ payload: { arrivalDate } }) => changeArrivalDateToFilterInCurrentGroupService(arrivalDate),
    ),
    takeEvery(
      EntryActionTypes.WEB_ORDER_NO_TO_FILTER_IN_CURRENT_GROUP_CHANGE,
      ({ payload: { webOrderNo } }) => changeWebOrderNoToFilterInCurrentGroupService(webOrderNo),
    ),
    takeEvery(
      EntryActionTypes.ARRIVAL_DATE_FOR_SHIPMENT_TO_FILTER_IN_CURRENT_GROUP_CHANGE,
      ({ payload: { arrivalDate } }) =>
        changeArrivalDateForShipmentPageToFilterInCurrentGroupService(arrivalDate),
    ),
    takeEvery(EntryActionTypes.SELLITEMS_UPDATE, ({ payload: { id, data } }) =>
      updateSellitemsService(id, data),
    ),
    takeEvery(EntryActionTypes.SELLITEMS_UPDATE_HANDLE, ({ payload: { sellitems } }) =>
      handleSellitemsUpdateService(sellitems),
    ),
  ]);
  // yield takeEvery(EntryActionTypes.SELLITEMS_IN_CURRENT_GROUP_FETCH, () =>
  //   fetchSellitemsInCurrentGroupService(),
  // );
}
