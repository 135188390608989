import { takeEvery, all } from 'redux-saga/effects';

import {
  fetchCustomersInCurrentGroupService,
  searchCustomersInCurrentGroupService,
} from '../services';
import EntryActionTypes from '../../../constants/EntryActionTypes';

export default function* customersWatchers() {
  yield all([
    takeEvery(EntryActionTypes.CUSTOMERS_SEARCH, ({ payload: { query } }) =>
      searchCustomersInCurrentGroupService(query),
    ),
    takeEvery(EntryActionTypes.CUSTOMERS_FETCH, ({ payload: { query } }) =>
      fetchCustomersInCurrentGroupService(query),
    ),
  ]);
}
