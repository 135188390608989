import { createSelector } from 'redux-orm';

import orm from '../orm';

export const makeBusinessManagerByIdSelector = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ BusinessManager }, id) => {
      const businessManagerModel = BusinessManager.withId(id);

      if (!businessManagerModel) {
        return businessManagerModel;
      }

      return businessManagerModel.ref;
    },
  );

export const businessManagerByIdSelector = makeBusinessManagerByIdSelector();
