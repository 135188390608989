import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Grid, Segment } from 'semantic-ui-react';

import RightSidebar from './RightSidebar';
import CustomersLeftSidebarContainer from '../../containers/CustomersLeftSidebarContainer';
import SearchProducts from '../SearchProducts/SearchProducts';
import SellitemTable from '../SellitemTable';

import styles from './Customers.module.scss';

const Customers = React.memo(
  ({
    accounts,
    addresses,
    customer,
    customerGroups,
    payments,
    sellitems,
    searchProductResults,
    isPersisted,
    canEdit,
    onAddressCreate,
    onAddressDelete,
    onAddressUpdate,
    onPaymentCreate,
    onPaymentDelete,
    onPaymentUpdate,
    onProductCreate,
    onSearchProducts,
    onSellitemCreate,
    onUpdateSellitems,
    onUpdate,
  }) => {
    const totals = useMemo(
      () =>
        sellitems.reduce(
          (previous, current) => ({
            quantity: previous.quantity + current.quantity,
            finalTotal: previous.finalTotal + current.finalTotal,
          }),
          { quantity: 0, finalTotal: 0 },
        ),
      [sellitems],
    );

    console.log('Customers render');

    return (
      <div className={styles.wrapperGrid}>
        <Grid stackable columns={3}>
          <Grid.Row stretched>
            <Grid.Column width={3}>
              <CustomersLeftSidebarContainer />
            </Grid.Column>
            <Grid.Column width={customer && customer.id ? 10 : 13}>
              <Segment className={styles.wrapper}>
                {customer && customer.id && (
                  <>
                    <SearchProducts
                      customerId={customer.id}
                      customerCode={customer.code || ''}
                      searchResults={searchProductResults}
                      onSearchProducts={onSearchProducts}
                      onSellitemCreate={onSellitemCreate}
                      onProductCreate={onProductCreate}
                    />
                    <SellitemTable
                      accounts={accounts}
                      canEdit={canEdit}
                      sellitems={sellitems}
                      onPaymentCreate={onPaymentCreate}
                      onUpdateSellitems={onUpdateSellitems}
                    />
                  </>
                )}
              </Segment>
            </Grid.Column>
            <Grid.Column width={3}>
              {customer && customer.id && (
                <RightSidebar
                  accounts={accounts}
                  addresses={addresses}
                  canEdit={canEdit}
                  isPersisted={isPersisted}
                  customer={customer}
                  customerGroups={customerGroups}
                  payments={payments}
                  totals={totals}
                  onAddressCreate={onAddressCreate}
                  onAddressDelete={onAddressDelete}
                  onAddressUpdate={onAddressUpdate}
                  onPaymentCreate={onPaymentCreate}
                  onPaymentDelete={onPaymentDelete}
                  onPaymentUpdate={onPaymentUpdate}
                  onUpdate={onUpdate}
                />
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  },
);

Customers.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.any).isRequired,
  addresses: PropTypes.arrayOf(PropTypes.any).isRequired,
  customer: PropTypes.objectOf(PropTypes.any).isRequired,
  customerGroups: PropTypes.arrayOf(PropTypes.any).isRequired,
  payments: PropTypes.arrayOf(PropTypes.any).isRequired,
  sellitems: PropTypes.arrayOf(PropTypes.any).isRequired,
  searchProductResults: PropTypes.objectOf(PropTypes.any).isRequired,
  canEdit: PropTypes.bool.isRequired,
  isPersisted: PropTypes.bool.isRequired,
  onAddressCreate: PropTypes.func.isRequired,
  onAddressDelete: PropTypes.func.isRequired,
  onAddressUpdate: PropTypes.func.isRequired,
  onPaymentCreate: PropTypes.func.isRequired,
  onPaymentDelete: PropTypes.func.isRequired,
  onPaymentUpdate: PropTypes.func.isRequired,
  onProductCreate: PropTypes.func.isRequired,
  onSearchProducts: PropTypes.func.isRequired,
  onSellitemCreate: PropTypes.func.isRequired,
  onUpdateSellitems: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default Customers;
