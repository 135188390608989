import { call, put, select } from 'redux-saga/effects';

import request from '../request';
import { isAttachmentWithIdExistsSelector, pathSelector } from '../../../selectors';
import {
  createAttachment,
  createAttachmentImgur,
  deleteAttachment,
  handleAttachmentCreate,
  handleAttachmentDelete,
  handleAttachmentUpdate,
  updateAttachment,
} from '../../../actions';
import api from '../../../api';
import { createLocalId } from '../../../utils/local-id';
import { getAccessTokenImgur, setAccessTokenImgur } from '../../../utils/access-token-storage';

export function* createAttachmentService(sellitemId, data) {
  const localId = yield call(createLocalId);

  yield put(
    createAttachment({
      sellitemId,
      id: localId,
      name: data.file.name,
    }),
  );

  let attachment;
  try {
    ({ item: attachment } = yield call(request, api.createAttachment, sellitemId, data, localId));
  } catch (error) {
    yield put(createAttachment.failure(localId, error));
    return;
  }

  yield put(createAttachment.success(localId, attachment));
}

export function* createAttachmentInCurrentSellitemService(data) {
  const { sellitemId } = yield select(pathSelector);

  yield call(createAttachmentService, sellitemId, data);
}

export function* createAttachmentProductService(data, uuid) {
  const localId = yield call(createLocalId);

  let accessTokenImgur;
  accessTokenImgur = getAccessTokenImgur();
  if (!accessTokenImgur || accessTokenImgur === null) {
    try {
      ({ access_token: accessTokenImgur } = yield call(api.createAccessTokenImgur));
      // eslint-disable-next-line no-empty
    } catch (error) {}
    if (accessTokenImgur) setAccessTokenImgur(accessTokenImgur);
  }

  yield put(
    createAttachmentImgur({
      id: localId,
      data,
    }),
  );

  let attachment;
  try {
    ({ data: attachment } = yield call(request, api.createAttachmentImgur, data));
  } catch (error) {
    yield put(createAttachmentImgur.failure(localId, error));
    return;
  }

  yield put(createAttachmentImgur.success(localId, attachment, 'imgur', uuid));
}

export function* handleAttachmentCreateService(attachment, requestId) {
  const isExists = yield select(isAttachmentWithIdExistsSelector, requestId);

  if (!isExists) {
    yield put(handleAttachmentCreate(attachment));
  }
}

export function* updateAttachmentService(id, data) {
  yield put(updateAttachment(id, data));

  let attachment;
  try {
    ({ item: attachment } = yield call(request, api.updateAttachment, id, data));
  } catch (error) {
    yield put(updateAttachment.failure(id, error));
    return;
  }

  yield put(updateAttachment.success(attachment));
}

export function* handleAttachmentUpdateService(attachment) {
  yield put(handleAttachmentUpdate(attachment));
}

export function* deleteAttachmentService(id) {
  yield put(deleteAttachment(id));

  let attachment;
  try {
    ({ item: attachment } = yield call(request, api.deleteAttachment, id));
  } catch (error) {
    yield put(deleteAttachment.failure(id, error));
    return;
  }

  yield put(deleteAttachment.success(attachment));
}

export function* handleAttachmentDeleteService(attachment) {
  yield put(handleAttachmentDelete(attachment));
}
