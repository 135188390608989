/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { List, Segment, Button, Icon } from 'semantic-ui-react';

import NameEdit from '../Product/NameEdit';
import StockAdd from '../Product/StockAdd';
import CountriesPopup from '../ProductInfosPopup';
import BrandsPopup from '../ProductInfosPopup/BrandsPopup';
import StocksPopup from './ActionsPopup/StocksPopup';
import DeletePopup from '../DeletePopup';
import { useModal } from '../../hooks';
import ProductEditModalContainer from '../../containers/ProductEditModalContainer';
import { closePopup } from '../../lib/popup';

import styles from './RightSidebar.module.scss';
import { Statuses } from '../../constants/Enums';
import Paths from '../../constants/Paths';
// import { ReactComponent as DotIcon } from '../../assets/images/dots-vertical.svg';
import { closeModal } from '../../actions/entry';

const RightSidebar = React.memo(
  ({
    product,
    defaultColor,
    sellitems,
    brands,
    countries,
    nextProductId,
    isPersisted,
    stocks,
    canEdit,
    onDelete,
    onUpdate,
    onStockCreate,
  }) => {
    const [t] = useTranslation();
    const history = useHistory();

    const nameEdit = useRef(null);
    const imageUrlEdit = useRef(null);
    const webPriceEdit = useRef(null);
    const sellPriceEdit = useRef(null);
    const weightAmountEdit = useRef(null);
    const [showEditModal, setShowEditModal] = useState(false);

    const handleHeaderClick = useCallback(
      (field) => {
        if (isPersisted && canEdit) {
          switch (field) {
            case 'name':
              nameEdit.current.open();
              break;
            case 'imageUrl':
              imageUrlEdit.current.open();
              break;
            case 'webPrice':
              webPriceEdit.current.open();
              break;
            case 'sellPrice':
              sellPriceEdit.current.open();
              break;
            case 'weightAmount':
              weightAmountEdit.current.open();
              break;
            default:
          }
        }
      },
      [isPersisted, canEdit],
    );

    const handleFieldUpdate = useCallback(
      (data, field) => {
        switch (field) {
          case 'country':
            onUpdate(product.id, {
              countryId: data.value,
            });
            break;
          case 'brand':
            onUpdate(product.id, {
              brandId: data.value,
            });
            break;
          default:
            onUpdate(product.id, {
              [field]: data,
            });
        }
      },
      [product.id, onUpdate],
    );

    const handleDeleteProduct = useCallback(() => {
      if (isPersisted && canEdit) {
        onDelete(product.id);
        closePopup();
        history.replace(Paths.PRODUCTS.replace(':id', nextProductId));
      }
    }, [isPersisted, canEdit, onDelete, product.id, history, nextProductId]);

    const [imageProduct, setImageUrl] = useState(product.imageUrl);
    useEffect(() => {
      setImageUrl(product.imageUrl);
      if (product.variation && product.variation.colors) {
        const color = product.variation.colors.find((item) => item.name === defaultColor);
        if (color && color.imageUrl) setImageUrl(color.imageUrl);
      }
    }, [defaultColor, product]);

    let sizes = '';
    if (product.variation) {
      if (product.variation.sizes) {
        product.variation.sizes.forEach((size, index) => {
          sizes += index === 0 ? size.name : `,${size.name}`;
        });
      }
      if (
        product.variation.colors &&
        product.variation.colors[0] &&
        product.variation.colors[0].sizes
      ) {
        product.variation.colors[0].sizes.forEach((size, index) => {
          sizes += index === 0 ? size.name : `,${size.name}`;
        });
      }
    } else {
      sizes = product.size;
    }

    const [modal, openModal, handleModalClose] = useModal();

    const handleOpenEditModal = useCallback(() => {
      setShowEditModal(true);
    }, []);

    const handleCloseEditModal = useCallback(() => {
      closeModal();
      setShowEditModal(false);
    }, []);

    const totalSold = sellitems
      .filter(
        (sellItem) =>
          sellItem.status !== Statuses.CANCELEDOK.text &&
          sellItem.status !== Statuses.CANCELED.text,
      )
      .reduce((sum, { quantity }) => sum + quantity, 0);

    return (
      <Segment className={styles.details}>
        <List divided verticalAlign="middle">
          <List.Item>
            <List.Content
              as="h4"
              className={styles.productName}
              onClick={() => handleHeaderClick('name')}
            >
              {canEdit ? (
                <NameEdit
                  ref={nameEdit}
                  defaultValue={product.name}
                  onUpdate={(data) => handleFieldUpdate(data, 'name')}
                >
                  <div className={styles.headerName}>{product.name}</div>
                </NameEdit>
              ) : (
                <div className={styles.headerName}>{product.name}</div>
              )}
            </List.Content>

            {canEdit && (
              <>
                <Button size="small" className={`${styles.btnEdit}`} onClick={handleOpenEditModal}>
                  <Icon fitted name="pencil" size="small" />
                </Button>
                {showEditModal && (
                  <ProductEditModalContainer defaultData={product} onClose={handleCloseEditModal} />
                )}
              </>
            )}

            <List.Content>
              <div className={styles.productInfo}>
                {canEdit ? (
                  <>
                    <BrandsPopup
                      item={product.productBrand}
                      allBrands={brands}
                      canEdit={canEdit}
                      title={t('common.brand')}
                      onBrandSelect={(data) => handleFieldUpdate(data, 'brand')}
                    >
                      <Button
                        className={styles.productBrand}
                        content={product.productBrand.name || ''}
                        size="tiny"
                      />
                    </BrandsPopup>
                    <CountriesPopup
                      item={product.productCountry}
                      allCountries={countries}
                      canEdit={canEdit}
                      title={t('common.country')}
                      onCountrySelect={(data) => handleFieldUpdate(data, 'country')}
                    >
                      <Button
                        className={styles.productCountry}
                        content={product.productCountry.code || ''}
                        size="tiny"
                      />
                    </CountriesPopup>
                    <Button
                      as="a"
                      size="tiny"
                      basic
                      className={styles.productCountry}
                      href={product.url}
                      title={product.name}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="cube small icon" />
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      className={styles.productBrand}
                      content={product.productBrand.name || ''}
                      size="tiny"
                    />
                    <Button
                      className={styles.productCountry}
                      content={product.productCountry.code || ''}
                      size="tiny"
                    />
                  </>
                )}
              </div>
              <div className={styles.productImage} onClick={() => handleHeaderClick('imageUrl')}>
                {canEdit ? (
                  <NameEdit
                    ref={imageUrlEdit}
                    defaultValue={product.imageUrl || ''}
                    onUpdate={(data) => handleFieldUpdate(data, 'imageUrl')}
                  >
                    <img alt="" src={imageProduct} />
                  </NameEdit>
                ) : (
                  <img alt="" src={imageProduct} />
                )}
              </div>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Content floated="right" className={styles.listVariation}>
              {product.productCategories?.map(({ name }) => name).join(', ')}
            </List.Content>
            <List.Content>{t('common.category')}:</List.Content>
          </List.Item>
          <List.Item>
            <List.Content floated="right" className={styles.listVariation}>
              {product.variation && product.variation.colors
                ? product.variation.colors.map((color, index) =>
                    index === 0 ? color.name : `,${color.name}`,
                  )
                : product.color || ''}
            </List.Content>
            <List.Content>{t('common.color')}:</List.Content>
          </List.Item>
          <List.Item>
            <List.Content floated="right" className={styles.listVariation}>
              {sizes}
            </List.Content>
            <List.Content>{t('common.size')}:</List.Content>
          </List.Item>
          <List.Item>
            <List.Content floated="right" onClick={() => handleHeaderClick('webPrice')}>
              {canEdit ? (
                <NameEdit
                  ref={webPriceEdit}
                  defaultValue={product.webPrice || ''}
                  onUpdate={(data) => handleFieldUpdate(data, 'webPrice')}
                >
                  <div className={styles.headerName}>
                    {(product.webPrice * 1).toLocaleString('en-GB')}
                  </div>
                </NameEdit>
              ) : (
                <div className={styles.headerName}>
                  {(product.webPrice * 1).toLocaleString('en-GB')}
                </div>
              )}
            </List.Content>
            <List.Content>{t('common.webPrice')}:</List.Content>
          </List.Item>
          <List.Item>
            <List.Content floated="right" onClick={() => handleHeaderClick('sellPrice')}>
              {canEdit ? (
                <NameEdit
                  ref={sellPriceEdit}
                  defaultValue={product.sellPrice || ''}
                  onUpdate={(data) => handleFieldUpdate(data, 'sellPrice')}
                >
                  <div className={styles.headerName}>
                    {(product.sellPrice * 1).toLocaleString('en-GB')}
                  </div>
                </NameEdit>
              ) : (
                <div className={styles.headerName}>
                  {(product.sellPrice * 1).toLocaleString('en-GB')}
                </div>
              )}
            </List.Content>
            <List.Content>{t('common.sellPrice')}:</List.Content>
          </List.Item>
          <List.Item>
            <List.Content floated="right" onClick={() => handleHeaderClick('weightAmount')}>
              {canEdit ? (
                <NameEdit
                  ref={weightAmountEdit}
                  defaultValue={product.weightAmount || ''}
                  onUpdate={(data) => handleFieldUpdate(data, 'weightAmount')}
                >
                  <div className={styles.headerName}>
                    {(product.weightAmount * 1).toLocaleString('en-GB')}
                  </div>
                </NameEdit>
              ) : (
                <div className={styles.headerName}>
                  {(product.weightAmount * 1).toLocaleString('en-GB')}
                </div>
              )}
            </List.Content>
            <List.Content>{t('common.weightAmount')}:</List.Content>
          </List.Item>
          <List.Item>
            <List.Content floated="right">
              {(product.weightAmount * 1 + product.sellPrice * 1).toLocaleString('en-GB')}
            </List.Content>
            <List.Content>{t('common.finalTotal')}:</List.Content>
          </List.Item>
          <List.Item>
            <List.Content floated="right">{totalSold}</List.Content>
            <List.Content>{t('common.totalSold')}:</List.Content>
          </List.Item>
          <List.Item>
            <List.Content floated="right">
              {sellitems
                .filter((sellItem) => sellItem.status === Statuses.FINISH.text)
                .reduce((sum, { quantity }) => sum + quantity, 0)}
            </List.Content>
            <List.Content>{t('common.totalDelivered')}:</List.Content>
          </List.Item>
          <List.Item>
            <List.Content floated="right">
              {sellitems
                .filter(
                  (sellItem) =>
                    sellItem.status !== Statuses.CANCELEDOK.text &&
                    sellItem.status !== Statuses.CANCELED.text &&
                    sellItem.status !== Statuses.FINISH.text,
                )
                .reduce((sum, { quantity }) => sum + quantity, 0)}
            </List.Content>
            <List.Content>{t('common.totalNotDelivery')}:</List.Content>
          </List.Item>
          <List.Item>
            <List.Content floated="right">
              {sellitems
                .filter(
                  (sellItem) =>
                    sellItem.status === Statuses.WEBSHIPPED.text ||
                    sellItem.status === Statuses.DONEORDER.text ||
                    sellItem.status === Statuses.SEND.text,
                )
                .reduce((sum, { quantity }) => sum + quantity, 0)}
            </List.Content>
            <List.Content>{t('common.totalOrderNotBeenVN')}:</List.Content>
          </List.Item>
          <List.Item>
            <List.Content floated="right">
              {stocks.reduce((sum, { quantity }) => sum + quantity, 0) - totalSold}
            </List.Content>
            <List.Content>
              <span className={styles.stocksLabel} onClick={openModal}>
                {t('common.totalProductInStock')}:{' '}
              </span>
              {modal && <StocksPopup stocks={stocks} onClose={handleModalClose} />}
              {canEdit && (
                <StockAdd onCreate={onStockCreate}>
                  <Button size="mini" icon="add" className={styles.addStock} />
                </StockAdd>
              )}
            </List.Content>
          </List.Item>
        </List>
        <DeletePopup
          title={t('common.deleteProduct', {
            context: 'title',
          })}
          content={t('common.areYouSureYouWantToDeleteThisProduct')}
          buttonContent={t('action.deleteProduct')}
          onConfirm={handleDeleteProduct}
        >
          <Button size="mini" color="red" disabled={sellitems.length > 0}>
            {t('action.delete')}
          </Button>
        </DeletePopup>
      </Segment>
    );
  },
);

RightSidebar.propTypes = {
  isPersisted: PropTypes.bool.isRequired,
  defaultColor: PropTypes.string,
  sellitems: PropTypes.arrayOf(PropTypes.any).isRequired,
  stocks: PropTypes.arrayOf(PropTypes.any).isRequired,
  brands: PropTypes.arrayOf(PropTypes.any).isRequired,
  countries: PropTypes.arrayOf(PropTypes.any).isRequired,
  product: PropTypes.objectOf(PropTypes.any).isRequired,
  nextProductId: PropTypes.string.isRequired,
  canEdit: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onStockCreate: PropTypes.func.isRequired,
};

RightSidebar.defaultProps = {
  defaultColor: undefined,
};

export default RightSidebar;
