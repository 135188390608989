import ActionTypes from '../constants/ActionTypes';

// eslint-disable-next-line import/prefer-default-export
export const handleLocationChange = (
  group,
  customer,
  customerGroup,
  users,
  businesses,
  groupMemberships,
  labels,
  customers,
  addresses,
  sellitems,
  sellitemMemberships,
  sellitemLabels,
  payments,
  attachments,
  notifications,
  products,
  categories,
  productCategories,
  shipments,
  stocks,
  dashboard,
) => ({
  type: ActionTypes.LOCATION_CHANGE_HANDLE,
  payload: {
    group,
    customer,
    customerGroup,
    users,
    businesses,
    groupMemberships,
    labels,
    customers,
    addresses,
    sellitems,
    sellitemMemberships,
    sellitemLabels,
    payments,
    attachments,
    notifications,
    products,
    categories,
    productCategories,
    shipments,
    stocks,
    dashboard,
  },
});

handleLocationChange.fetchGroup = (id) => ({
  type: ActionTypes.LOCATION_CHANGE_HANDLE__GROUP_FETCH,
  payload: {
    id,
  },
});

handleLocationChange.fetchCustomer = (id) => ({
  type: ActionTypes.LOCATION_CHANGE_HANDLE__CUSTOMER_FETCH,
  payload: {
    id,
  },
});

handleLocationChange.fetchProduct = (id) => ({
  type: ActionTypes.LOCATION_CHANGE_HANDLE__PRODUCT_FETCH,
  payload: {
    id,
  },
});

handleLocationChange.fetchShipment = (id) => ({
  type: ActionTypes.LOCATION_CHANGE_HANDLE__SHIPMENT_FETCH,
  payload: {
    id,
  },
});

handleLocationChange.fetchShipments = (groupId) => ({
  type: ActionTypes.LOCATION_CHANGE_HANDLE__SHIPMENTS_FETCH,
  payload: {
    groupId,
  },
});

handleLocationChange.fetchDashboard = (id) => ({
  type: ActionTypes.LOCATION_CHANGE_HANDLE__DASHBOARD_FETCH,
  payload: {
    id,
  },
});
