import EntryActionTypes from '../../constants/EntryActionTypes';

export const createManagerInCurrentBusiness = (data) => ({
  type: EntryActionTypes.MANAGER_IN_CURRENT_BUSINESS_CREATE,
  payload: {
    data,
  },
});

export const handleBusinessManagerCreate = (businessManager) => ({
  type: EntryActionTypes.BUSINESS_MANAGER_CREATE_HANDLE,
  payload: {
    businessManager,
  },
});

export const deleteBusinessManager = (id) => ({
  type: EntryActionTypes.BUSINESS_MANAGER_DELETE,
  payload: {
    id,
  },
});

export const handleBusinessManagerDelete = (businessManager) => ({
  type: EntryActionTypes.BUSINESS_MANAGER_DELETE_HANDLE,
  payload: {
    businessManager,
  },
});
