import socket from './socket';

/* Actions */

const createAddress = (customerId, data, headers) =>
  socket.post(`/customers/${customerId}/addresses`, data, headers);

const updateAddress = (id, data, headers) => socket.patch(`/addresses/${id}`, data, headers);

const deleteAddress = (id, headers) => socket.delete(`/addresses/${id}`, undefined, headers);

export default {
  createAddress,
  updateAddress,
  deleteAddress,
};
