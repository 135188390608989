import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import omit from 'lodash/omit';
import { format } from 'date-fns';
import {
  actionsForCurrentSellitemSelector,
  attachmentsForCurrentSellitemSelector,
  currentSellitemSelector,
  isCurrentUserManagerForCurrentBusinessSelector,
  isCurrentUserMemberForCurrentGroupSelector,
  labelsForCurrentGroupSelector,
  labelsForCurrentSellitemSelector,
  membershipsForCurrentGroupSelector,
  pathSelector,
  businessesToCustomersForCurrentUserSelector,
  paymentsForSellitemIdSelector,
  currentBusinessSelector,
  sellitemsByCustomerIdSelector,
  customerByIdSelector,
  countryByProductIdSelector,
  brandByProductIdSelector,
  locationSelector,
  accountsForCurrentBusinessSelector,
  addressesByCustomerIdSelector,
  paymentsCustomerIdSelector,
} from '../selectors';
import {
  addLabelToCurrentSellitem,
  addUserToCurrentSellitem,
  createAttachmentInCurrentSellitem,
  createCommentActionInCurrentSellitem,
  createLabelInCurrentGroup,
  createPayment,
  deleteAttachment,
  deleteCommentAction,
  deleteCurrentSellitem,
  deleteLabel,
  deletePayment,
  fetchActionsInCurrentSellitem,
  fetchGroup,
  moveCurrentSellitem,
  removeLabelFromCurrentSellitem,
  removeUserFromCurrentSellitem,
  transferCurrentSellitem,
  updateAttachment,
  updateCommentAction,
  updateCurrentSellitem,
  updateLabel,
  updatePayment,
} from '../actions/entry';
import Paths from '../constants/Paths';
import SellitemModal from '../components/SellitemModal';
import { Statuses, CancelStatusNames, PaymentTypes } from '../constants/Enums';

const mapStateToProps = (state) => {
  const { businessId } = pathSelector(state);
  const { query } = locationSelector(state);
  const allBusinessesToCustomers = businessesToCustomersForCurrentUserSelector(state);
  const isCurrentUserManager = isCurrentUserManagerForCurrentBusinessSelector(state);
  const allGroupMemberships = membershipsForCurrentGroupSelector(state);
  const allLabels = labelsForCurrentGroupSelector(state);
  const isCurrentUserMember = isCurrentUserMemberForCurrentGroupSelector(state);
  const accounts = accountsForCurrentBusinessSelector(state);

  const {
    name,
    description,
    arrivalDate,
    timer,
    isSubscribed,
    isActionsFetching,
    isAllActionsFetched,
    groupId,
    customerId,
    createdAt,
  } = currentSellitemSelector(state);

  const users = []; // usersForCurrentSellitemSelector(state);
  const labels = labelsForCurrentSellitemSelector(state);
  const attachments = attachmentsForCurrentSellitemSelector(state);
  const actions = actionsForCurrentSellitemSelector(state);
  const currentBusiness = currentBusinessSelector(state);
  // let sellitems = sellitemsForCurrentGroupSelector(state);
  let sellitems = sellitemsByCustomerIdSelector(state, customerId);
  const customer = customerByIdSelector(state, customerId);
  const addresses = addressesByCustomerIdSelector(state, customerId);
  // const users = usersSelector(state);
  let finalTotals = 0;
  let finalPayments = 0;
  const totalRow = {
    quantity: 0,
    finalTotal: 0,
    weight: 0,
    weightAmount: 0,
    isWithWeightAmount: true,
  };
  const defaultAddress = addresses.find((addr) => addr.id === customer.addressId) || {};

  const newOrderStatuses = [
    Statuses.NEWORDER.name,
    Statuses.STATUS1.name,
    Statuses.ADDEDBAG.name,
    Statuses.PENDING.name,
    Statuses.CANCELED.name,
    Statuses.CANCELEDOK.name,
  ];
  let paymentInfos = [];
  const createdAtWithFormat = format(createdAt, 'yyyyMMdd');
  sellitems = sellitems
    .filter((si) => {
      if (query.v === 'v') {
        return si.status === Statuses.ARRIVAL.name;
      }
      return (
        newOrderStatuses.includes(si.status) &&
        createdAtWithFormat === format(si.createdAt, 'yyyyMMdd')
      );
    })
    .map((si, index) => {
      const isCancel = CancelStatusNames.includes(si.status);
      const country = countryByProductIdSelector(state, si.productId);
      const brand = brandByProductIdSelector(state, si.productId);

      const weightAmount = isCancel ? 0 : si.weightAmount;
      const totalWithoutWeightAmount = isCancel ? 0 : si.quantity * si.sellPrice; // feeAmount tien cong
      const finalTotal = isCancel ? 0 : totalWithoutWeightAmount + 1 * weightAmount; // feeAmount tien cong
      const payments = paymentsForSellitemIdSelector(state, si.id);
      const payment = payments
        .filter(({ type }) => type === PaymentTypes.PAYMENT)
        .reduce((sum, current) => sum + parseInt(current.amount, 10), 0);

      const paymentInfo = payments
        .filter(({ type }) => type === PaymentTypes.PAYMENT)
        .map((pay, idx) => {
          const account = accounts.filter((item) => item.id === pay.accountId);
          return {
            idx: idx + 1,
            ...pay,
            accountName: account.length > 0 ? account[0]?.name : pay.type,
          };
        });
      paymentInfos = [...paymentInfos, ...paymentInfo];

      finalTotals += finalTotal;
      finalPayments += payment;
      totalRow.finalTotal += finalTotal;
      totalRow.quantity += si.quantity;
      totalRow.weight += si.weight;
      totalRow.weightAmount += weightAmount > 0 ? 1 * weightAmount : 0; // Tien can weightAmount
      totalRow.isWithWeightAmount = totalRow.isWithWeightAmount && si.isWithWeightAmount;

      // process array date
      const curCreatedAt = new Date(si.createdAt);
      curCreatedAt.setDate(curCreatedAt.getDate() + +country?.estimatedArrivalDay);

      const arrivalDateFormat = si.arrivalDate
        ? format(new Date(si.arrivalDate), 'dd/MM/yyyy')
        : '';
      const isHasArriveDate = si.arrivalDate || CancelStatusNames.includes(si.status);
      const arrivalDateOrExpDate =
        isHasArriveDate === false ? format(curCreatedAt, 'dd/MM/yyyy') : arrivalDateFormat;

      let image;
      if (si.product?.variation?.colors) {
        // eslint-disable-next-line no-shadow
        image = si.product.variation.colors.find(({ name }) => name === si.color)?.imageUrl;
      } else {
        image = si.product?.imageUrl;
      }
      return {
        index: index + 1,
        ...si,
        name: `${country?.name}/${brand?.name}<br/>${si.name}`,
        countryBrandName: `${country?.name}/${si.webCode}`,
        sellitemName: si.name,
        arrivalDate: arrivalDateOrExpDate, // : si.arrivalDate ? format(new Date(si.arrivalDate), 'dd-MM-yyyy') : '', // estimatedArrivalDay
        // orderNo: si.invoice,
        member: si.creator, // users.find((u) => u.id === si.creatorUserId).name,
        // customer: name,
        // country: country?.name,
        // brand: brand?.name,
        imageUrl: image,
        image: `<img src="${image}" alt="${si.product?.description}" width="45">`,
        // subtotal, // Tạm tính
        // total, // Thành tiền VNĐ [unit_price_before_discount*quantity*(1-line_discount_amount)*exchange_rate]
        // diff: subtotal - total, // Chênh lệch [subtotal-total]
        weightAmount,
        totalWithoutWeightAmount,
        finalTotal,
        payment, // Đã cọc
        remainTotal: finalTotal - payment, // Còn lại
      };
    });

  // Total
  sellitems.push({ ...totalRow });

  // Tiền gửi còn lại
  const paymentsCustomer = paymentsCustomerIdSelector(state, customerId);
  const depositAccount = accounts.find((a) => a.type === PaymentTypes.DEPOSIT) || {
    accountId: '',
  };
  const allDeposits = paymentsCustomer.filter((p) => p.type === PaymentTypes.DEPOSIT);
  const allPayments = paymentsCustomer.filter((p) => p.type === PaymentTypes.PAYMENT);
  const allPaymentsByDeposit = allPayments.filter((p) => p.accountId === depositAccount.id);
  const totalDeposits = allDeposits.reduce((amount, current) => {
    return amount + current.amount * 1;
  }, 0);
  const totalPaymentsByDeposit = allPaymentsByDeposit.reduce((amount, current) => {
    return amount + current.amount * 1;
  }, 0);
  customer.totalDepositRemain = totalDeposits - totalPaymentsByDeposit;

  return {
    name,
    description,
    arrivalDate,
    timer,
    createdAt,
    isSubscribed,
    isActionsFetching,
    isAllActionsFetched,
    customer,
    groupId,
    businessId,
    users,
    labels,
    locationQuery: query,
    payments: paymentInfos,
    attachments,
    actions,
    allBusinessesToCustomers,
    allGroupMemberships,
    allLabels,
    canEdit: isCurrentUserMember,
    canEditAllCommentActions: isCurrentUserManager,
    business: currentBusiness,
    sellitems,
    finalTotals,
    finalPayments,
    defaultAddress,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onUpdate: updateCurrentSellitem,
      onMove: moveCurrentSellitem,
      onTransfer: transferCurrentSellitem,
      onDelete: deleteCurrentSellitem,
      onUserAdd: addUserToCurrentSellitem,
      onUserRemove: removeUserFromCurrentSellitem,
      onGroupFetch: fetchGroup,
      onLabelAdd: addLabelToCurrentSellitem,
      onLabelRemove: removeLabelFromCurrentSellitem,
      onLabelCreate: createLabelInCurrentGroup,
      onLabelUpdate: updateLabel,
      onLabelDelete: deleteLabel,
      onPaymentCreate: createPayment,
      onPaymentUpdate: updatePayment,
      onPaymentDelete: deletePayment,
      onAttachmentCreate: createAttachmentInCurrentSellitem,
      onAttachmentUpdate: updateAttachment,
      onAttachmentDelete: deleteAttachment,
      onActionsFetch: fetchActionsInCurrentSellitem,
      onCommentActionCreate: createCommentActionInCurrentSellitem,
      onCommentActionUpdate: updateCommentAction,
      onCommentActionDelete: deleteCommentAction,
      push,
    },
    dispatch,
  );

const mergeProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  ...omit(dispatchProps, 'push'),
  onClose: () => {
    switch (stateProps.locationQuery.o) {
      case 'c':
        return dispatchProps.push(Paths.CUSTOMERS.replace(':id', stateProps.customer.id));
      case 'g':
        return dispatchProps.push(Paths.GROUPS.replace(':id', stateProps.groupId));
      default:
        return dispatchProps.push(stateProps.locationQuery.o);
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(SellitemModal);
