import { all, takeEvery } from 'redux-saga/effects';

import {
  createManagerInCurrentBusinessService,
  deleteBusinessManagerService,
  // handleBusinessManagerCreateService,
  handleBusinessManagerDeleteService,
} from '../services';
import EntryActionTypes from '../../../constants/EntryActionTypes';

export default function* businessManagerWatchers() {
  yield all([
    takeEvery(EntryActionTypes.MANAGER_IN_CURRENT_BUSINESS_CREATE, ({ payload: { data } }) =>
      createManagerInCurrentBusinessService(data),
    ),
    // takeEvery(EntryActionTypes.BUSINESS_MANAGER_CREATE_HANDLE, ({ payload: { businessManager } }) =>
    //   handleBusinessManagerCreateService(businessManager),
    // ),
    takeEvery(EntryActionTypes.BUSINESS_MANAGER_DELETE, ({ payload: { id } }) =>
      deleteBusinessManagerService(id),
    ),
    takeEvery(EntryActionTypes.BUSINESS_MANAGER_DELETE_HANDLE, ({ payload: { businessManager } }) =>
      handleBusinessManagerDeleteService(businessManager),
    ),
  ]);
}
