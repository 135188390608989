import { all, takeEvery } from 'redux-saga/effects';

import {
  createCategoryInCurrentBusinessService,
  deleteCategoryService,
  handleCategoryCreateService,
  handleCategoryDeleteService,
  handleCategoryUpdateService,
  updateCategoryService,
  addCategoryToFilterInCurrentGroupService,
  removeCategoryFromFilterInCurrentGroupService,
} from '../services';
import EntryActionTypes from '../../../constants/EntryActionTypes';

export default function* categoryWatchers() {
  yield all([
    takeEvery(EntryActionTypes.CATEGORY_IN_CURRENT_BUSINESS_CREATE, ({ payload: { data } }) =>
      createCategoryInCurrentBusinessService(data),
    ),
    takeEvery(EntryActionTypes.CATEGORY_CREATE_HANDLE, ({ payload: { category } }) =>
      handleCategoryCreateService(category),
    ),
    takeEvery(EntryActionTypes.CATEGORY_UPDATE, ({ payload: { id, data } }) =>
      updateCategoryService(id, data),
    ),
    takeEvery(EntryActionTypes.CATEGORY_UPDATE_HANDLE, ({ payload: { category } }) =>
      handleCategoryUpdateService(category),
    ),
    takeEvery(EntryActionTypes.CATEGORY_DELETE, ({ payload: { id } }) => deleteCategoryService(id)),
    takeEvery(EntryActionTypes.CATEGORY_DELETE_HANDLE, ({ payload: { category } }) =>
      handleCategoryDeleteService(category),
    ),
    takeEvery(EntryActionTypes.CATEGORY_TO_FILTER_IN_CURRENT_GROUP_ADD, ({ payload: { id } }) =>
      addCategoryToFilterInCurrentGroupService(id),
    ),
    takeEvery(
      EntryActionTypes.CATEGORY_FROM_FILTER_IN_CURRENT_GROUP_REMOVE,
      ({ payload: { id } }) => removeCategoryFromFilterInCurrentGroupService(id),
    ),
  ]);
}
