import ActionTypes from '../constants/ActionTypes';

// eslint-disable-next-line import/prefer-default-export
export const initializeCore = (
  user,
  group,
  users,
  businesses,
  businessManagers,
  countries,
  brands,
  accounts,
  customerGroups,
  groups,
  groupMemberships,
  labels,
  customers,
  addresses,
  sellitems,
  sellitemMemberships,
  sellitemLabels,
  payments,
  attachments,
  actions,
  notifications,
  products,
  categories,
  productCategories,
  shipments,
) => ({
  type: ActionTypes.CORE_INITIALIZE,
  payload: {
    user,
    group,
    users,
    businesses,
    businessManagers,
    countries,
    brands,
    accounts,
    customerGroups,
    groups,
    groupMemberships,
    labels,
    customers,
    addresses,
    sellitems,
    sellitemMemberships,
    sellitemLabels,
    payments,
    attachments,
    actions,
    notifications,
    products,
    categories,
    productCategories,
    shipments,
  },
});

export const clearAppError = () => ({
  type: ActionTypes.APP_ERROR_CLEAR,
  payload: {},
});
