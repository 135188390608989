import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  currentUserSelector,
  countriesForCurrentBusinessSelector,
  brandsForCurrentBusinessSelector,
  categoriesForCurrentBusinessSelector,
  attachmentUploadImgurSelector,
} from '../selectors';
import { clearUserCreateError, updateProduct, createAttachmentProduct } from '../actions/entry';
import ProductEditPopup from '../components/ProductEditPopup';

const mapStateToProps = (state) => {
  const { isSubmitting, error } = state.ui.productCreateForm;
  const { isAdmin } = currentUserSelector(state);
  const countries = countriesForCurrentBusinessSelector(state);
  const brands = brandsForCurrentBusinessSelector(state);
  const categories = categoriesForCurrentBusinessSelector(state);
  const attachmentImgur = attachmentUploadImgurSelector(state);
  return {
    countries,
    brands,
    categories,
    attachmentImgur,
    isSubmitting,
    error,
    isAdmin,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onAttachmentCreate: createAttachmentProduct,
      onUpdate: (id, data) => updateProduct(id, data),
      onMessageDismiss: clearUserCreateError,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ProductEditPopup);
