import EntryActionTypes from '../../constants/EntryActionTypes';

export const createAddressInCurrentCustomer = (data) => ({
  type: EntryActionTypes.ADDRESS_IN_CURRENT_CUSTOMER_CREATE,
  payload: {
    data,
  },
});

export const createAddressInShipline = (customerId, data, shiplineId) => ({
  type: EntryActionTypes.ADDRESS_CREATE,
  payload: {
    customerId,
    data,
    shiplineId,
  },
});

export const handleAddressCreate = (address) => ({
  type: EntryActionTypes.ADDRESS_CREATE_HANDLE,
  payload: {
    address,
  },
});

export const updateAddress = (id, data) => ({
  type: EntryActionTypes.ADDRESS_UPDATE,
  payload: {
    id,
    data,
  },
});

export const handleAddressUpdate = (address) => ({
  type: EntryActionTypes.ADDRESS_UPDATE_HANDLE,
  payload: {
    address,
  },
});

export const deleteAddress = (id) => ({
  type: EntryActionTypes.ADDRESS_DELETE,
  payload: {
    id,
  },
});

export const handleAddressDelete = (address) => ({
  type: EntryActionTypes.ADDRESS_DELETE_HANDLE,
  payload: {
    address,
  },
});
