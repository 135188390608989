import ActionTypes from '../constants/ActionTypes';

export const createBusinessManager = (businessManager) => ({
  type: ActionTypes.BUSINESS_MANAGER_CREATE,
  payload: {
    businessManager,
  },
});

createBusinessManager.success = (localId, businessManager) => ({
  type: ActionTypes.BUSINESS_MANAGER_CREATE__SUCCESS,
  payload: {
    localId,
    businessManager,
  },
});

createBusinessManager.failure = (localId, error) => ({
  type: ActionTypes.BUSINESS_MANAGER_CREATE__FAILURE,
  payload: {
    localId,
    error,
  },
});

export const handleBusinessManagerCreate = (
  businessManager,
  business,
  group,
  users,
  businessManagers,
  groups,
  groupMemberships,
  labels,
  customers,
  addresses,
  sellitems,
  sellitemMemberships,
  sellitemLabels,
  payments,
  attachments,
  products,
  categories,
  productCategories,
) => ({
  type: ActionTypes.BUSINESS_MANAGER_CREATE_HANDLE,
  payload: {
    businessManager,
    business,
    group,
    users,
    businessManagers,
    groups,
    groupMemberships,
    labels,
    customers,
    addresses,
    sellitems,
    sellitemMemberships,
    sellitemLabels,
    payments,
    attachments,
    products,
    categories,
    productCategories,
  },
});

handleBusinessManagerCreate.fetchBusiness = (id, currentUserId, currentGroupId) => ({
  type: ActionTypes.BUSINESS_MANAGER_CREATE_HANDLE__BUSINESS_FETCH,
  payload: {
    id,
    currentUserId,
    currentGroupId,
  },
});

export const deleteBusinessManager = (id, isCurrentUser, isCurrentBusiness) => ({
  type: ActionTypes.BUSINESS_MANAGER_DELETE,
  payload: {
    id,
    isCurrentUser,
    isCurrentBusiness,
  },
});

deleteBusinessManager.success = (businessManager) => ({
  type: ActionTypes.BUSINESS_MANAGER_DELETE__SUCCESS,
  payload: {
    businessManager,
  },
});

deleteBusinessManager.failure = (id, error) => ({
  type: ActionTypes.BUSINESS_MANAGER_DELETE__FAILURE,
  payload: {
    id,
    error,
  },
});

export const handleBusinessManagerDelete = (businessManager, isCurrentUser, isCurrentBusiness) => ({
  type: ActionTypes.BUSINESS_MANAGER_DELETE_HANDLE,
  payload: {
    businessManager,
    isCurrentUser,
    isCurrentBusiness,
  },
});
