import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { format } from 'date-fns';

import {
  isCurrentUserMemberForCurrentGroupSelector,
  pathSelector,
  productByIdSelector,
  productsForCurrentGroupSelector,
  countryByIdSelector,
  brandByIdSelector,
  sellitemsByProductIdSelector,
  paymentsForSellitemIdSelector,
  usersSelector,
  customerByIdSelector,
  makeStocksByProductIdSelector,
  countriesForCurrentBusinessSelector,
  brandsForCurrentBusinessSelector,
  accountsForCurrentBusinessSelector,
  countryByProductIdSelector,
  categoriesByProductIdSelector,
} from '../selectors';
import {
  createStock,
  createPayment,
  deleteProduct,
  updateSellitems,
  updateProduct,
} from '../actions/entry';
import { getRightSidebar, setRightSidebar } from '../utils/right-sidebar-storage';

import Products from '../components/Products';
import { PaymentTypes, Statuses } from '../constants/Enums';

const mapStateToProps = (state) => {
  const stocksByProductIdSelector = makeStocksByProductIdSelector();
  const isCurrentUserMember = isCurrentUserMemberForCurrentGroupSelector(state);
  const accounts = accountsForCurrentBusinessSelector(state);
  const { productId, groupId } = pathSelector(state);
  let sellitems = [];
  let stocks = [];
  let product;

  if (productId) {
    const {
      id,
      // groupId,
      creatorUserId,
      coverAttachmentId,
      countryId,
      brandId,
      name,
      description,
      type,
      code,
      url,
      imageUrl,
      variation,
      color,
      size,
      unit,
      webCode,
      webPrice,
      webExtra,
      sellPrice,
      wholesalePrice,
      feeAmount,
      weight,
      weightAmount,
      isWithWeightAmount,
      point,
      createdAt,
      updatedAt,
      isPersisted,
    } = productByIdSelector(state, productId);
    let productCountry;
    let productBrand;

    if (countryId) {
      productCountry = countryByIdSelector(state, countryId);
    }
    if (brandId) {
      productBrand = brandByIdSelector(state, brandId);
    }
    const productCategories = categoriesByProductIdSelector(state, productId);

    product = {
      id,
      groupId,
      creatorUserId,
      coverAttachmentId,
      countryId,
      brandId,
      name,
      description,
      type,
      code,
      url,
      imageUrl,
      variation,
      color,
      size,
      unit,
      webCode,
      webPrice,
      webExtra,
      sellPrice,
      wholesalePrice,
      feeAmount,
      weight,
      weightAmount,
      isWithWeightAmount,
      point,
      createdAt,
      updatedAt,
      productCountry,
      productBrand,
      productCategories,
      isPersisted,
    };

    const users = usersSelector(state);
    sellitems = sellitemsByProductIdSelector(state, productId);

    sellitems = sellitems.map((si) => {
      const { estimatedArrivalDay } = countryByProductIdSelector(state, si.productId);
      const payments = paymentsForSellitemIdSelector(state, si.id);
      const payment = payments
        .filter(({ _type }) => _type === PaymentTypes.PAYMENT)
        .reduce((sum, current) => sum + parseInt(current.amount, 10), 0);

      let finalTotal =
        (parseFloat(si.quantity) - parseFloat(si.quantityCancel)) * parseFloat(si.sellPrice);
      // if (si.feeAmount) finalTotal += parseFloat(si.feeAmount);
      if (si?.weightAmount) finalTotal += parseFloat(si.weightAmount);
      const customer = customerByIdSelector(state, si.customerId);
      const estimatedArrivalDate = new Date(si.createdAt);
      estimatedArrivalDate.setDate(estimatedArrivalDate.getDate() + estimatedArrivalDay);

      return {
        ...si,
        member: users.find((u) => u.id === si.creatorUserId)?.name,
        finalTotal,
        remainTotal: finalTotal - payment,
        payment,
        customer: customer?.name,
        code: customer?.code,
        estimatedArrivalDate,
        arrivalDate: si.arrivalDate && format(si.arrivalDate, 'd/M/yyyy'),
        status: Statuses[si.status?.toUpperCase()].text,
      };
    });

    stocks = stocksByProductIdSelector(state, productId);
  }

  const countries = countriesForCurrentBusinessSelector(state);
  const brands = brandsForCurrentBusinessSelector(state);

  const products = productsForCurrentGroupSelector(state);
  const currentIndex = products.findIndex(({ id }) => id === productId);

  let { id: nextProductId } =
    products.length > 1
      ? products[Math.min(products.length - 1, currentIndex + 1)]
      : { id: undefined };
  if (!nextProductId) nextProductId = groupId;

  const toggleRightSidebar = getRightSidebar();

  return {
    accounts,
    product,
    sellitems,
    stocks,
    countries,
    brands,
    nextProductId,
    toggleRightSidebar,
    canEdit: isCurrentUserMember,
    onSetRightSidebar: (data) => setRightSidebar(data),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onDeleteProduct: (id) => deleteProduct(id),
      onUpdateProduct: (id, data) => updateProduct(id, data),
      onUpdateSellitems: (id, data) => updateSellitems(id, data),
      onStockCreate: (data) => createStock(data),
      onPaymentCreate: (customerId, data) => createPayment(customerId, data),
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Products);
