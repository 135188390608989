import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { updateShipment } from '../actions/entry';

import {
  makeShipmentByIdSelector,
  // shiplinesForShipmentIdSelector,
  pathSelector,
} from '../selectors';
import ShipmentItem from '../components/ShipmentItem';

const makeMapStateToProps = () => {
  const shipmentByIdSelector = makeShipmentByIdSelector();

  return (state, { id }) => {
    const { shipmentId } = pathSelector(state);
    const { code, total, shiplineCount, deliveredDate } = shipmentByIdSelector(state, id);
    // const shiplines = shiplinesForShipmentIdSelector(state, id);

    return {
      id,
      totalAmount: +total || 0,
      shiplineCount,
      code,
      deliveredDate,
      isActive: shipmentId === id,
    };
  };
};

const mapDispatchToProps = (dispatch, { id }) =>
  bindActionCreators(
    {
      onUpdate: (data) => updateShipment(id, data),
    },
    dispatch,
  );

export default connect(makeMapStateToProps, mapDispatchToProps)(ShipmentItem);
