/* eslint-disable no-unused-vars */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Icon, Menu } from 'semantic-ui-react';
import { withPopup } from '../../lib/popup';
import { Popup } from '../../lib/custom-ui';

import { useSteps } from '../../hooks';
import DeleteStep from '../DeleteStep';

import styles from './ActionsPopup.module.scss';

const StepTypes = {
  DELETE: 'DELETE',
};

const ActionsStep = React.memo(({ currentCustomerGroupId, items, onUpdate, onDelete, onClose }) => {
  const [t] = useTranslation();
  const [step, openStep, handleBack] = useSteps();

  const handleUpdateClick = useCallback(
    (id) => {
      onUpdate({ customerGroupId: id });
      onClose();
    },
    [onClose, onUpdate],
  );

  const handleDeleteClick = useCallback(() => {
    openStep(StepTypes.DELETE);
  }, [openStep]);

  // const handleDelete = useCallback(() => {
  //   openStep(StepTypes.DELETE);
  // }, [openStep]);

  if (step && step.type === StepTypes.DELETE) {
    return (
      <DeleteStep
        title={t('action.deleteCustomerGroup', {
          context: 'title',
        })}
        content={t('common.areYouSureYouWantToDeleteGroupOfThisCustomer')}
        buttonContent={t('action.deleteCustomerGroup', { context: 'title' })}
        onConfirm={onDelete}
        onBack={handleBack}
      />
    );
  }

  return (
    <>
      <Popup.Header>
        {t('common.customerActions', {
          context: 'title',
        })}
      </Popup.Header>
      <Popup.Content>
        <Menu secondary vertical className={styles.menu}>
          {items.map(({ id, name, description }) => (
            <Menu.Item key={id} className={styles.menuItem} onClick={() => handleUpdateClick(id)}>
              {name}
              <span className={styles.meta}>{description}</span>
              {currentCustomerGroupId === id && <Icon name="check" />}
            </Menu.Item>
          ))}
          {currentCustomerGroupId && (
            <Menu.Item className={styles.menuItem} onClick={handleDeleteClick}>
              {t('action.deleteCustomerGroup', {
                context: 'title',
              })}
            </Menu.Item>
          )}
        </Menu>
      </Popup.Content>
    </>
  );
});

ActionsStep.propTypes = {
  currentCustomerGroupId: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

ActionsStep.defaultProps = {
  currentCustomerGroupId: undefined,
};

export default withPopup(ActionsStep);
