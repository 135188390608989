import socket from './socket';

/* Actions */

const getDashboard = (id, startDate, endDate, headers) =>
  socket
    .get(`/groups/${id}/stats?startDate=${startDate}&endDate=${endDate}`, undefined, headers)
    .then((body) => ({
      ...body,
    }));

export default {
  getDashboard,
};
