import { all, takeEvery } from 'redux-saga/effects';

import {
  createProductInCurrentGroupService,
  handleProductCreateService,
  updateProductService,
  handleProductUpdateService,
  deleteProductService,
} from '../services';
import EntryActionTypes from '../../../constants/EntryActionTypes';

export default function* productWatchers() {
  yield all([
    takeEvery(EntryActionTypes.PRODUCT_CREATE, ({ payload: { data } }) =>
      createProductInCurrentGroupService(data),
    ),
    takeEvery(EntryActionTypes.PRODUCT_CREATE_HANDLE, ({ payload: { product } }) =>
      handleProductCreateService(product),
    ),
    takeEvery(EntryActionTypes.PRODUCT_UPDATE, ({ payload: { id, data } }) =>
      updateProductService(id, data),
    ),
    takeEvery(EntryActionTypes.PRODUCT_UPDATE_HANDLE, ({ payload: { product } }) =>
      handleProductUpdateService(product),
    ),
    takeEvery(EntryActionTypes.PRODUCT_DELETE, ({ payload: { id } }) => deleteProductService(id)),
  ]);
}
