import { all, takeEvery } from 'redux-saga/effects';

import {
  createCustomerGroupInCurrentBusinessService,
  deleteCustomerGroupService,
  handleCustomerGroupCreateService,
  handleCustomerGroupDeleteService,
  handleCustomerGroupUpdateService,
  updateCustomerGroupService,
} from '../services';
import EntryActionTypes from '../../../constants/EntryActionTypes';

export default function* customerGroupWatchers() {
  yield all([
    takeEvery(EntryActionTypes.CUSTOMER_GROUP_IN_CURRENT_BUSINESS_CREATE, ({ payload: { data } }) =>
      createCustomerGroupInCurrentBusinessService(data),
    ),
    takeEvery(EntryActionTypes.CUSTOMER_GROUP_CREATE_HANDLE, ({ payload: { customerGroup } }) =>
      handleCustomerGroupCreateService(customerGroup),
    ),
    takeEvery(EntryActionTypes.CUSTOMER_GROUP_UPDATE, ({ payload: { id, data } }) =>
      updateCustomerGroupService(id, data),
    ),
    takeEvery(EntryActionTypes.CUSTOMER_GROUP_UPDATE_HANDLE, ({ payload: { customerGroup } }) =>
      handleCustomerGroupUpdateService(customerGroup),
    ),
    takeEvery(EntryActionTypes.CUSTOMER_GROUP_DELETE, ({ payload: { id } }) =>
      deleteCustomerGroupService(id),
    ),
    takeEvery(EntryActionTypes.CUSTOMER_GROUP_DELETE_HANDLE, ({ payload: { customerGroup } }) =>
      handleCustomerGroupDeleteService(customerGroup),
    ),
  ]);
}
