import ActionTypes from '../constants/ActionTypes';

export const createCustomer = (customer) => ({
  type: ActionTypes.CUSTOMER_CREATE,
  payload: {
    customer,
  },
});

createCustomer.success = (localId, customer) => ({
  type: ActionTypes.CUSTOMER_CREATE__SUCCESS,
  payload: {
    localId,
    customer,
  },
});

createCustomer.failure = (localId, error) => ({
  type: ActionTypes.CUSTOMER_CREATE__FAILURE,
  payload: {
    localId,
    error,
  },
});

export const handleCustomerCreate = (customer) => ({
  type: ActionTypes.CUSTOMER_CREATE_HANDLE,
  payload: {
    customer,
  },
});

export const fetchCustomer = (id) => ({
  type: ActionTypes.CUSTOMER_FETCH,
  payload: {
    id,
  },
});

fetchCustomer.success = (
  customer,
  customerGroup,
  sellitems,
  sellitemMemberships,
  sellitemLabels,
  payments,
  attachments,
) => ({
  type: ActionTypes.CUSTOMER_FETCH__SUCCESS,
  payload: {
    customer,
    customerGroup,
    sellitems,
    sellitemMemberships,
    sellitemLabels,
    payments,
    attachments,
  },
});

fetchCustomer.failure = (id, error) => ({
  type: ActionTypes.CUSTOMER_FETCH__FAILURE,
  payload: {
    id,
    error,
  },
});

export const updateCustomer = (id, data) => ({
  type: ActionTypes.CUSTOMER_UPDATE,
  payload: {
    id,
    data,
  },
});

updateCustomer.success = (customer) => ({
  type: ActionTypes.CUSTOMER_UPDATE__SUCCESS,
  payload: {
    customer,
  },
});

updateCustomer.failure = (id, error) => ({
  type: ActionTypes.CUSTOMER_UPDATE__FAILURE,
  payload: {
    id,
    error,
  },
});

export const handleCustomerUpdate = (customer) => ({
  type: ActionTypes.CUSTOMER_UPDATE_HANDLE,
  payload: {
    customer,
  },
});

export const deleteCustomer = (id) => ({
  type: ActionTypes.CUSTOMER_DELETE,
  payload: {
    id,
  },
});

deleteCustomer.success = (customer) => ({
  type: ActionTypes.CUSTOMER_DELETE__SUCCESS,
  payload: {
    customer,
  },
});

deleteCustomer.failure = (id, error) => ({
  type: ActionTypes.CUSTOMER_DELETE__FAILURE,
  payload: {
    id,
    error,
  },
});

export const handleCustomerDelete = (customer) => ({
  type: ActionTypes.CUSTOMER_DELETE_HANDLE,
  payload: {
    customer,
  },
});
