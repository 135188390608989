import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Menu } from 'semantic-ui-react';
import { withPopup } from '../../lib/popup';
import { Popup } from '../../lib/custom-ui';
import { useSteps } from '../../hooks';

import ImportContainer from '../../containers/ImportContainer';

import styles from './MenusPopup.module.scss';

const StepTypes = {
  IMPORT: 'IMPORT',
};

const MenusStep = React.memo(({ canExport, canImport, onExport, onClose }) => {
  const [t] = useTranslation();
  const [step, openStep, handleBack] = useSteps();

  const handleImportStepClick = useCallback(() => {
    openStep(StepTypes.IMPORT);
  }, [openStep]);

  const handleExportClick = useCallback(() => {
    onExport();
    onClose();
  }, [onClose, onExport]);

  if (step) {
    switch (step.type) {
      case StepTypes.IMPORT:
        return (
          <ImportContainer
            title={t('action.importSellitems', {
              context: 'title',
            })}
            type="sellitem"
            // onImport={onImportUser}
            onBack={handleBack}
            // onClose={onClose}
          />
        );
      default:
    }
  }

  return (
    <div>
      <Popup.Content>
        <Menu secondary vertical className={styles.menu}>
          {canImport && (
            <Menu.Item
              className={styles.menuItem}
              onClick={handleImportStepClick}
              disabled={!canImport}
            >
              {t('action.importSellitems', {
                context: 'title',
              })}
            </Menu.Item>
          )}
          <Menu.Item className={styles.menuItem} onClick={handleExportClick} disabled={!canExport}>
            {t('action.exportSellitems', {
              context: 'title',
            })}
          </Menu.Item>
        </Menu>
      </Popup.Content>
    </div>
  );
});

MenusStep.propTypes = {
  canExport: PropTypes.bool.isRequired,
  canImport: PropTypes.bool.isRequired,
  onExport: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default withPopup(MenusStep);
