import { Model, attr, fk } from 'redux-orm';

import ActionTypes from '../constants/ActionTypes';

export default class extends Model {
  static modelName = 'Country';

  static fields = {
    id: attr(),
    name: attr(),
    code: attr(),
    taxPercent: attr(),
    exchangeRate: attr(),
    weightAmount: attr(),
    feeAmount: attr(),
    estimatedArrivalDay: attr(),
    businessId: fk({
      to: 'Business',
      as: 'business',
      relatedName: 'countries',
    }),
  };

  static reducer({ type, payload }, Country) {
    switch (type) {
      case ActionTypes.SOCKET_RECONNECT_HANDLE:
        Country.all().delete();

        if (payload.countries) {
          payload.countries.forEach((country) => {
            Country.upsert(country);
          });
        }

        break;
      case ActionTypes.CORE_INITIALIZE:
      case ActionTypes.BUSINESS_CREATE__SUCCESS:
      case ActionTypes.BUSINESS_CREATE_HANDLE:
        if (payload.countries) {
          payload.countries.forEach((country) => {
            Country.upsert(country);
          });
        }

        break;
      case ActionTypes.COUNTRY_CREATE:
      case ActionTypes.COUNTRY_CREATE_HANDLE:
      case ActionTypes.COUNTRY_UPDATE__SUCCESS:
      case ActionTypes.COUNTRY_UPDATE_HANDLE:
        Country.upsert(payload.country);

        break;
      case ActionTypes.COUNTRY_CREATE__SUCCESS:
        Country.withId(payload.localId).delete();
        Country.upsert(payload.country);

        break;
      // case ActionTypes.COUNTRY_CREATE_HANDLE:
      //   Country.upsert(payload.country);

      //   if (payload.countries) {
      //     payload.countries.forEach((country) => {
      //       Country.upsert(country);
      //     });
      //   }

      //   break;
      case ActionTypes.COUNTRY_UPDATE:
        Country.withId(payload.id).update(payload.data);

        break;
      case ActionTypes.COUNTRY_DELETE:
        Country.withId(payload.id).delete();

        break;
      case ActionTypes.COUNTRY_DELETE__SUCCESS:
      case ActionTypes.COUNTRY_DELETE_HANDLE: {
        const countryModel = Country.withId(payload.country.id);

        if (countryModel) {
          countryModel.delete();
        }

        break;
      }
      default:
    }
  }
}
