import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { format } from 'date-fns';

import {
  isCurrentUserMemberForCurrentGroupSelector,
  shipmentIdsForCurrentGroupSelector,
  currentShipmentSelector,
  allSellitemsForCurrentGroupSelector,
  paymentsBySellitemIdSelector,
  addressesByCustomerIdSelector,
  shiplinesForCurrentShipmentSelector,
  allAddressSelector,
  currentModalSelector,
  accountsForCurrentBusinessSelector,
  currentGroupSelector,
  customersForCurrentGroupSelector,
  paymentsCustomerIdSelector,
} from '../selectors';
import {
  createShipment,
  fetchShipments,
  searchShipments,
  searchCustomers,
  updateShipment,
  updateAddress,
  createShipline,
  updateShipline,
  deleteShipline,
  openShiplineDetailModal,
  createAddressInShipline,
  createPayment,
  fetchCustomers,
  updateSellitem,
} from '../actions/entry';
import Shipments from '../components/Shipments';
import { PaymentTypes, Statuses, StatusesShipment } from '../constants/Enums';

const mapStateToProps = (state) => {
  const customers = customersForCurrentGroupSelector(state);
  const isCurrentUserMember = isCurrentUserMemberForCurrentGroupSelector(state);
  const shipmentIds = shipmentIdsForCurrentGroupSelector(state);
  const shipment = currentShipmentSelector(state);
  const accounts = accountsForCurrentBusinessSelector(state);
  const allSellitems = allSellitemsForCurrentGroupSelector(state);

  // console.log('Shipment:sellitems:', allSellitems);

  const sellitems = allSellitems
    .filter((si) => si.status === Statuses.ARRIVAL.name)
    .map((si) => {
      const payments = paymentsBySellitemIdSelector(state, si.id);
      const quantity = si.quantity - si.quantityCancel;
      const subtotal = quantity * si.sellPrice; // Tạm tính
      const total = si.webPrice * quantity * (1 - si.webExtra / 100) * si.exchangeRate; // Thành tiền VNĐ
      const finalTotal = quantity * si.sellPrice + 1 * si.weightAmount;
      const payment = payments.reduce((paymentSum, pay) => {
        if (pay.type === PaymentTypes.PAYMENT) {
          return paymentSum + parseInt(pay.amount, 10);
        }
        return paymentSum;
      }, 0);
      return {
        ...si,
        member: si.creator,
        imageUrl: si.product?.imageUrl,
        subtotal,
        diff: subtotal - total,
        finalTotal,
        payment,
        remainTotal: finalTotal - payment,
      };
    });

  if (customers.customers && customers.customers.length > 0) {
    customers.customers = customers.customers.map((ct) => {
      const addresses = addressesByCustomerIdSelector(state, ct.id);
      const defaultAddress = addresses?.filter((addr) => addr.id === ct.addressId);
      return {
        ...ct,
        customerAddress: defaultAddress.length > 0 && defaultAddress[0],
      };
    });
  }

  let shiplines = [];

  if (shipment && shipment.id) {
    shiplines = shiplinesForCurrentShipmentSelector(state);
    shiplines = shiplines
      .sort((a, b) => {
        return a.customer.code - b.customer.code;
      })
      .map((line) => {
        let paymentShiplineSum = 0;
        const defaultAddress = line.address?.address
          ? `${line.address.address}, ${line?.address.ward}, ${line?.address.district}, ${line?.address.province}`
          : '';
        let total = 0;
        line.sellitemIds?.forEach((si) => {
          const sellitem = allSellitems.find((psi) => psi.id === si);
          if (sellitem) {
            const payments = paymentsBySellitemIdSelector(state, sellitem.id);
            total +=
              (sellitem.quantity - sellitem.quantityCancel) * sellitem.sellPrice +
              1 * sellitem.weightAmount;
            paymentShiplineSum += payments.reduce((paymentSum, pay) => {
              if (pay.type === PaymentTypes.PAYMENT) {
                return paymentSum + parseInt(pay.amount, 10);
              }
              return paymentSum;
            }, 0);
          }
          const customersPayments = paymentsCustomerIdSelector(state, line.customerId);
          paymentShiplineSum += customersPayments
            .filter(({ refNo }) => refNo === line.id)
            .reduce((sum, { amount }) => sum + +amount, 0);
        });
        const status = StatusesShipment[(line.status || shipment.status).toUpperCase()];
        return {
          ...line,
          customerString: `${line.customer.name}\n[${line.customer.code}] 📞${line.customer.phone}`,
          addressString: line.address
            ? `${line.address.name} - ${line.address.phone}\n${defaultAddress}`
            : `${line.customer?.name} - ${line.customer?.phone}`,
          isShipProvince: line.isShipProvince ? 'Ship tỉnh' : 'Ship nội thành',
          shipper: line.shipper || shipment.shipper,
          status: status.text,
          estimatedDeliveryDate:
            line.estimatedDeliveryDate && format(new Date(line.estimatedDeliveryDate), 'd/M/yyyy'),
          deliveredDate: line.deliveredDate && format(new Date(line.deliveredDate), 'd/M/yyyy'),
          note: line.note || shipment.note,
          finalTotal: total + line.shippingAmount,
          remainTotal: total + line.shippingAmount - paymentShiplineSum,
          defaultAddress,
          canDelete: status.name === StatusesShipment.NOTSHIP.name,
          total,
        };
      });
  }

  const currentModal = currentModalSelector(state);
  const addresses = allAddressSelector(state);
  const { shipments } = state;
  const { isShipmentsFetching, isAllShipmentsFetched } = currentGroupSelector(state);

  return {
    accounts,
    addresses,
    shipmentIds,
    shipment,
    shiplines,
    sellitems,
    currentModal,
    isShipmentsFetching,
    isAllShipmentsFetched,
    canEdit: isCurrentUserMember,
    searchShipmentResults: shipments || { isSubmitting: false, shipments: [], query: '' },
    searchResults: { isSubmitting: false, customers: customers.slice(0, 50), query: '' },
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onShipmentCreate: createShipment,
      onSearchShipments: (query) => searchShipments(query),
      onSearchCustomers: (query) => searchCustomers(query),
      onFecthCustomers: (query) => fetchCustomers(query),
      onShipmentsFetch: fetchShipments,
      onUpdate: (id, data) => updateShipment(id, data),
      onCreateShipline: (data) => createShipline(data),
      onUpdateShipline: (id, data) => updateShipline(id, data),
      onDeleteShipline: (id) => deleteShipline(id),
      onUpdateSellitem: (id, data) => updateSellitem(id, data),
      onOpenShiplineDetailModal: openShiplineDetailModal,
      onCreateAddress: (customerId, data, shiplineId) =>
        createAddressInShipline(customerId, data, shiplineId),
      onPaymentCreate: createPayment,
      onUpdateAddress: (id, data) => updateAddress(id, data),
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Shipments);
