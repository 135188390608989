import { createSelector } from 'redux-orm';

import orm from '../orm';
import { pathSelector } from './router';
import { currentGroupSelector } from './group';
import { isLocalId } from '../utils/local-id';

export const makeProductByIdSelector = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ Product }, id) => {
      const productModel = Product.withId(id);

      if (!productModel) {
        return productModel;
      }

      return {
        ...productModel.ref,
        isPersisted: !isLocalId(id),
      };
    },
  );

export const productByIdSelector = makeProductByIdSelector();

export const currentProductSelector = createSelector(
  orm,
  (state) => pathSelector(state).productId,
  ({ Product }, id) => {
    if (!id) {
      return id;
    }

    const productModel = Product.withId(id);

    if (!productModel) {
      return productModel;
    }

    return productModel.ref;
  },
);

export const makeSellitemsByProductIdSelector = () =>
  createSelector(
    orm,
    (_, id) => id,
    (state) => currentGroupSelector(state),
    ({ Product }, id, currentGroup) => {
      const productModel = Product.withId(id);

      if (!productModel) {
        return productModel;
      }

      const { filterStatuses } = currentGroup;
      let sellitemsModel = productModel.getOrderedFilteredSellitemsModelArray();
      if (filterStatuses.length > 0) {
        sellitemsModel = sellitemsModel.filter((sellitemModel) =>
          filterStatuses.includes(sellitemModel.status),
        );
      }

      return sellitemsModel.map((sellitemModel) => ({
        ...sellitemModel.ref,
        creator: sellitemModel.creator?.ref.name,
        product: productModel.ref,
        country: productModel.country.ref,
      }));
    },
  );

export const sellitemsByProductIdSelector = makeSellitemsByProductIdSelector();

export const makeStocksByProductIdSelector = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ Product }, id) => {
      const productModel = Product.withId(id);

      if (!productModel) {
        return productModel;
      }
      return productModel
        .getOrderedStocksQuerySet()
        .toModelArray()
        .map((stockModel) => ({
          ...stockModel.ref,
          creator: stockModel.creator?.ref.name,
          product: stockModel.product?.ref,
        }));
    },
  );
export const stocksByProductIdSelector = makeStocksByProductIdSelector();

export const makeAttachmentsByProductIdSelector = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ Product, Attachment }, id) => {
      const productModel = Product.withId(id);

      if (!productModel) {
        return productModel;
      }

      let attachmentRefArray = productModel.getOrderedAttachmentsQuerySet().toRefArray();
      if (productModel.coverAttachmentId) {
        const attachmentModel = Attachment.withId(productModel.coverAttachmentId);
        if (
          attachmentModel &&
          !attachmentRefArray.find((p) => p.id === productModel.coverAttachmentId)
        ) {
          attachmentRefArray = attachmentRefArray.concat([attachmentModel.ref]);
        }
      }

      return attachmentRefArray.map((attachment) => ({
        ...attachment,
        isCover: attachment.id === productModel.coverAttachmentId,
        isPersisted: !isLocalId(attachment.id),
      }));
    },
  );
export const attachmentsByProductIdSelector = makeAttachmentsByProductIdSelector();

export const categoriesForCurrentProductSelector = createSelector(
  orm,
  (state) => pathSelector(state).productId,
  ({ Product }, id) => {
    if (!id) {
      return id;
    }

    const productModel = Product.withId(id);

    if (!productModel) {
      return productModel;
    }

    return productModel.categories.toRefArray();
  },
);

export const makeCategoriesByProductIdSelector = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ Product }, id) => {
      const productModel = Product.withId(id);

      if (!productModel) {
        return productModel;
      }

      return productModel.categories?.toRefArray();
    },
  );

export const categoriesByProductIdSelector = makeCategoriesByProductIdSelector();
