import { all, takeEvery } from 'redux-saga/effects';

import {
  createBrandInCurrentBusinessService,
  deleteBrandService,
  handleBrandCreateService,
  handleBrandDeleteService,
  handleBrandUpdateService,
  updateBrandService,
  addBrandToFilterInCurrentGroupService,
  removeBrandFromFilterInCurrentGroupService,
} from '../services';
import EntryActionTypes from '../../../constants/EntryActionTypes';

export default function* brandWatchers() {
  yield all([
    takeEvery(EntryActionTypes.BRAND_IN_CURRENT_BUSINESS_CREATE, ({ payload: { data } }) =>
      createBrandInCurrentBusinessService(data),
    ),
    takeEvery(EntryActionTypes.BRAND_CREATE_HANDLE, ({ payload: { brand } }) =>
      handleBrandCreateService(brand),
    ),
    takeEvery(EntryActionTypes.BRAND_UPDATE, ({ payload: { id, data } }) =>
      updateBrandService(id, data),
    ),
    takeEvery(EntryActionTypes.BRAND_UPDATE_HANDLE, ({ payload: { brand } }) =>
      handleBrandUpdateService(brand),
    ),
    takeEvery(EntryActionTypes.BRAND_DELETE, ({ payload: { id } }) => deleteBrandService(id)),
    takeEvery(EntryActionTypes.BRAND_DELETE_HANDLE, ({ payload: { brand } }) =>
      handleBrandDeleteService(brand),
    ),
    takeEvery(EntryActionTypes.BRAND_TO_FILTER_IN_CURRENT_GROUP_ADD, ({ payload: { id } }) =>
      addBrandToFilterInCurrentGroupService(id),
    ),
    takeEvery(EntryActionTypes.BRAND_FROM_FILTER_IN_CURRENT_GROUP_REMOVE, ({ payload: { id } }) =>
      removeBrandFromFilterInCurrentGroupService(id),
    ),
  ]);
}
