import { connect } from 'react-redux';

import { currentGroupSelector, currentBusinessSelector } from '../selectors';
import Warehouse from '../components/Warehouse';

const mapStateToProps = (state) => {
  const currentBusiness = currentBusinessSelector(state);
  const currentGroup = currentGroupSelector(state);

  return {
    currentGroup,
    currentBusiness,
  };
};

export default connect(mapStateToProps)(Warehouse);
