import { call, put } from 'redux-saga/effects';

import { fetchCoreRequest } from '../requests';
import { clearAppError, initializeCore } from '../../../actions';
import i18n from '../../../i18n';

// eslint-disable-next-line import/prefer-default-export
export function* initializeCoreService() {
  const {
    user,
    group,
    users,
    businesses,
    businessManagers,
    countries,
    brands,
    accounts,
    customerGroups,
    groups,
    groupMemberships,
    labels,
    customers,
    addresses,
    sellitems,
    sellitemMemberships,
    sellitemLabels,
    payments,
    attachments,
    actions,
    notifications,
    products,
    categories,
    productCategories,
    shipments,
    shiplines,
  } = yield call(fetchCoreRequest); // TODO: handle error

  yield call(i18n.loadCoreLocale, i18n.language);

  yield put(
    initializeCore(
      user,
      group,
      users,
      businesses,
      businessManagers,
      countries,
      brands,
      accounts,
      customerGroups,
      groups,
      groupMemberships,
      labels,
      customers,
      addresses,
      sellitems,
      sellitemMemberships,
      sellitemLabels,
      payments,
      attachments,
      actions,
      notifications,
      products,
      categories,
      productCategories,
      shipments,
      shiplines,
    ),
  );
}

export function* clearAppErrorService() {
  yield put(clearAppError());
}
