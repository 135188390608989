import { createSelector } from 'redux-orm';
import isUndefined from 'lodash/isUndefined';

import orm from '../orm';
import Config from '../constants/Config';

export const isCoreInitializingSelector = ({ core: { isInitializing } }) => isInitializing;

const nextPosition = (items, index, excludedId) => {
  const filteredItems = isUndefined(excludedId)
    ? items
    : items.filter((item) => item.id !== excludedId);

  if (isUndefined(index)) {
    const lastItem = filteredItems[filteredItems.length - 1];

    return (lastItem ? lastItem.position : 0) + Config.POSITION_GAP;
  }

  const prevItem = filteredItems[index - 1];
  const nextItem = filteredItems[index];

  const prevPosition = prevItem ? prevItem.position : 0;

  if (!nextItem) {
    return prevPosition + Config.POSITION_GAP;
  }

  return prevPosition + (nextItem.position - prevPosition) / 2;
};

export const nextGroupPositionSelector = createSelector(
  orm,
  (_, businessId) => businessId,
  (_, __, index) => index,
  (_, __, ___, excludedId) => excludedId,
  ({ Business }, businessId, index, excludedId) => {
    const businessModel = Business.withId(businessId);

    if (!businessModel) {
      return businessModel;
    }

    return nextPosition(businessModel.getOrderedGroupsQuerySet().toRefArray(), index, excludedId);
  },
);

export const nextCustomerPositionSelector = createSelector(
  orm,
  (_, groupId) => groupId,
  (_, __, index) => index,
  (_, __, ___, excludedId) => excludedId,
  ({ Group }, groupId, index, excludedId) => {
    const groupModel = Group.withId(groupId);

    if (!groupModel) {
      return groupModel;
    }

    return nextPosition(groupModel.getOrderedCustomersQuerySet().toRefArray(), index, excludedId);
  },
);

export const nextSellitemPositionSelector = createSelector(
  orm,
  (_, customerId) => customerId,
  (_, __, index) => index,
  (_, __, ___, excludedId) => excludedId,
  ({ Customer }, customerId, index, excludedId) => {
    const customerModel = Customer.withId(customerId);

    if (!customerModel) {
      return customerModel;
    }

    return nextPosition(customerModel.getOrderedFilteredSellitemsModelArray(), index, excludedId);
  },
);

export const appErrorSelector = ({ core: { appError } }) => appError;
