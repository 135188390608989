import EntryActionTypes from '../../constants/EntryActionTypes';

export const searchShipments = (query) => ({
  type: EntryActionTypes.SHIPMENTS_SEARCH,
  payload: {
    query,
  },
});

export const fetchShipments = (data) => ({
  type: EntryActionTypes.SHIPMENTS_FETCH,
  payload: { data },
});
