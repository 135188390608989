import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, Popup, Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import SellitemTable from '../SellitemTable';
import RightSidebar from './RightSidebar';
import ProductsLeftSidebarContainer from '../../containers/ProductsLeftSidebarContainer';
import SellitemAddContainer from '../../containers/SellitemAddContainer';
import { ReactComponent as DotIcon } from '../../assets/images/dots-vertical.svg';

import styles from './Products.module.scss';

const Products = React.memo(
  ({
    accounts,
    product,
    sellitems,
    stocks,
    brands,
    countries,
    nextProductId,
    canEdit,
    toggleRightSidebar,
    onDeleteProduct,
    onUpdateProduct,
    onUpdateSellitems,
    onStockCreate,
    onSetRightSidebar,
    onPaymentCreate,
  }) => {
    const [t] = useTranslation();

    const [showRightSidebar, setShowRightSidebar] = useState(toggleRightSidebar);

    const handleToggleRightSidebar = useCallback(() => {
      onSetRightSidebar(!showRightSidebar);
      setShowRightSidebar(!showRightSidebar);
    }, [onSetRightSidebar, showRightSidebar]);

    const [defaultColor, setDefaultColor] = useState();

    const handleChangeColor = useCallback(
      (color) => {
        setDefaultColor(color);
      },
      [setDefaultColor],
    );

    return (
      <div className={styles.wrapperGrid}>
        <Grid stackable columns={3}>
          <Grid.Row stretched>
            <Grid.Column width={3}>
              <Segment className={styles.sidebarLeft}>
                <ProductsLeftSidebarContainer />
              </Segment>
            </Grid.Column>
            <Grid.Column width={showRightSidebar && product ? 10 : 13}>
              <Segment className={styles.wrapper}>
                <div style={{ marginBottom: '-2px' }}>
                  {product && (
                    <>
                      <Popup
                        content={
                          showRightSidebar
                            ? t('action.hideProductInfo')
                            : t('action.showProductInfo')
                        }
                        trigger={
                          <Button
                            size="small"
                            content={<DotIcon className={styles.dotIcon} />}
                            className={`${styles.buttonToggleSidebar} ${
                              !showRightSidebar ? styles.buttonActive : ''
                            }`}
                            onClick={handleToggleRightSidebar}
                          />
                        }
                      />
                      <SellitemAddContainer onChangeColor={handleChangeColor} />
                    </>
                  )}
                </div>
                <SellitemTable
                  accounts={accounts}
                  canEdit={canEdit}
                  sellitems={sellitems}
                  page="products"
                  originPath={product?.id ? `/p/${product.id}` : ``}
                  showRightSidebar={showRightSidebar}
                  onPaymentCreate={onPaymentCreate}
                  onUpdateSellitems={onUpdateSellitems}
                />
              </Segment>
            </Grid.Column>
            {product && showRightSidebar && (
              <Grid.Column width={3}>
                <RightSidebar
                  canEdit={canEdit}
                  sellitems={sellitems}
                  isPersisted={product.isPersisted}
                  product={product}
                  defaultColor={defaultColor}
                  stocks={stocks}
                  brands={brands}
                  countries={countries}
                  nextProductId={nextProductId}
                  onDelete={onDeleteProduct}
                  onUpdate={onUpdateProduct}
                  onStockCreate={onStockCreate}
                />
              </Grid.Column>
            )}
          </Grid.Row>
        </Grid>
      </div>
    );
  },
);

Products.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.any).isRequired,
  sellitems: PropTypes.arrayOf(PropTypes.any).isRequired,
  brands: PropTypes.arrayOf(PropTypes.any).isRequired,
  countries: PropTypes.arrayOf(PropTypes.any).isRequired,
  nextProductId: PropTypes.string.isRequired,
  canEdit: PropTypes.bool.isRequired,
  onDeleteProduct: PropTypes.func.isRequired,
  onUpdateProduct: PropTypes.func.isRequired,
  product: PropTypes.objectOf(PropTypes.any),
  toggleRightSidebar: PropTypes.bool.isRequired,
  stocks: PropTypes.arrayOf(PropTypes.any).isRequired,
  onUpdateSellitems: PropTypes.func.isRequired,
  onStockCreate: PropTypes.func.isRequired,
  onSetRightSidebar: PropTypes.func.isRequired,
  onPaymentCreate: PropTypes.func.isRequired,
};

Products.defaultProps = {
  product: undefined,
};

export default Products;
