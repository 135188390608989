/* eslint-disable no-unused-vars */
import React, { useCallback, useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dimmer, Loader } from 'semantic-ui-react';

import SellitemModalContainer from '../../containers/SellitemModalContainer';
import SellitemInvoiceModalContainer from '../../containers/SellitemInvoiceModalContainer';

import SellitemFullTable from '../SellitemFullTable';

import styles from './Sellitems.module.scss';

const Sellitems = React.memo(
  ({
    accounts,
    sellitems,
    isSellitemModalOpened,
    canEdit,
    canDelete,
    isSellitemsFetching,
    isAllSellitemsFetched,
    stat,
    currentPage,
    onSellitemsFetch,
    onDeleteSellitem,
    onUpdateSellitems,
    onPaymentCreate,
    isOwner,
  }) => {
    const [isShowInvoice, SetIsShowInvoice] = useState(false);
    const [currentSellitem, setCurrentSellitem] = useState();

    const wrapper = useRef(null);

    const onHideInvoice = useCallback(() => {
      setCurrentSellitem(null);
      SetIsShowInvoice(false);
    }, []);

    // const [, setValue] = useState(0);
    // useEffect(() => {
    //   onSellitemsFetch({ page: 1 });
    // }, [onSellitemsFetch]);

    console.log('Sellitems', currentPage);

    return (
      <>
        <div ref={wrapper} className={styles.wrapper}>
          <SellitemFullTable
            accounts={accounts}
            canEdit={canEdit}
            canDelete={canDelete}
            sellitems={sellitems}
            stat={stat}
            currentPage={currentPage}
            onPaymentCreate={onPaymentCreate}
            onDeleteSellitem={onDeleteSellitem}
            onUpdateSellitems={onUpdateSellitems}
            onSellitemsFetch={onSellitemsFetch}
            isOwner={isOwner}
          />
        </div>
        {isSellitemsFetching && (
          <Dimmer active>
            <Loader active inline="centered" size="small" className={styles.loader} />
          </Dimmer>
        )}
        {/* {isSellitemsFetching ? (
          <Dimmer active>
            <Loader active inline="centered" size="small" className={styles.loader} />
          </Dimmer>
        ) : (
          !isAllSellitemsFetched && (
            <Visibility
              fireOnMount
              onOnScreen={onSellitemsFetch}
              // onBottomVisible={onSellitemsFetch}
            />
          )
        )} */}
        {isSellitemModalOpened && <SellitemModalContainer />}
        {isShowInvoice && (
          <SellitemInvoiceModalContainer id={currentSellitem} onHideInvoice={onHideInvoice} />
        )}
      </>
    );
  },
);

Sellitems.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.any).isRequired,
  sellitems: PropTypes.arrayOf(PropTypes.any).isRequired,
  isSellitemModalOpened: PropTypes.bool.isRequired,
  canEdit: PropTypes.bool.isRequired,
  canDelete: PropTypes.bool.isRequired,
  isSellitemsFetching: PropTypes.bool.isRequired,
  isAllSellitemsFetched: PropTypes.bool.isRequired,
  stat: PropTypes.objectOf(PropTypes.any).isRequired,
  currentPage: PropTypes.number.isRequired,
  onSellitemsFetch: PropTypes.func.isRequired,
  onDeleteSellitem: PropTypes.func.isRequired,
  onUpdateSellitems: PropTypes.func.isRequired,
  onPaymentCreate: PropTypes.func.isRequired,
  isOwner: PropTypes.bool.isRequired,
};

export default Sellitems;
