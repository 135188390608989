import socket from './socket';

/* Actions */

const createShipline = (shipmentId, data, headers) =>
  socket.post(`/shipments/${shipmentId}/shiplines`, data, headers).then((body) => ({
    ...body,
  }));

const updateShipline = (shiplineId, data, headers) =>
  socket.patch(`/shiplines/${shiplineId}`, data, headers).then((body) => ({
    ...body,
  }));

const deleteShipline = (shiplineId, headers) =>
  socket.delete(`/shiplines/${shiplineId}`, undefined, headers).then((body) => ({
    ...body,
  }));

export default {
  createShipline,
  updateShipline,
  deleteShipline,
};
