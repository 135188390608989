import { connect } from 'react-redux';

import { currentGroupSelector, pathSelector } from '../selectors';
import Fixed from '../components/Fixed';

const mapStateToProps = (state) => {
  const { businessId } = pathSelector(state);
  const currentGroup = currentGroupSelector(state);

  return {
    businessId,
    group: currentGroup,
  };
};

export default connect(mapStateToProps)(Fixed);
