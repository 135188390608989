/* eslint-disable no-unused-vars */
import { call, put, select } from 'redux-saga/effects';

import request from '../request';
import {
  groupByIdSelector,
  lastShipmentIdByGroupIdSelector,
  pathSelector,
  locationSelector,
} from '../../../selectors';
import {
  searchShipments,
  fetchShipments,
  changeArrivalDateForShipmentPageToGroupFilter,
} from '../../../actions';
import api from '../../../api';

export function* searchShipmentsService(groupId, query) {
  yield put(searchShipments(groupId, query));
  let shipments;

  try {
    ({ items: shipments } = yield call(request, api.searchShipments, groupId, {
      query,
    }));
  } catch (error) {
    yield put(searchShipments.failure(groupId, error));
    return;
  }

  yield put(searchShipments.success(groupId, shipments));
}

export function* searchShipmentsInCurrentGroupService(query) {
  const { groupId } = yield select(pathSelector);

  yield call(searchShipmentsService, groupId, query);
}

export function* fetchShipmentsService(groupId, data) {
  const lastId = yield select(lastShipmentIdByGroupIdSelector, groupId);
  const { filterArrivalDateForShipmentPage } = yield select(groupByIdSelector, groupId);

  yield put(fetchShipments(groupId, filterArrivalDateForShipmentPage));

  let shipments;
  let shiplines;
  let sellitems;
  let customers;
  let products;
  let payments;
  let stat;

  const { query } = yield select(locationSelector);
  const page = query?.page || 1;
  // if (query.start && query.end) {
  //   yield put(
  //     changeArrivalDateForShipmentPageToGroupFilter(
  //       {
  //         start: new Date(query.start),
  //         end: new Date(query.end),
  //       },
  //       groupId,
  //     ),
  //   );
  // }

  try {
    ({
      items: shipments,
      included: { shiplines, sellitems, customers, products, payments, stat },
    } = yield call(request, api.getShipments, groupId, {
      beforeId: lastId,
      filterArrivalDate: filterArrivalDateForShipmentPage,
      // data,
      page,
    }));
  } catch (error) {
    yield put(fetchShipments.failure(groupId, error));
    return;
  }

  yield put(
    fetchShipments.success(
      groupId,
      shipments,
      shiplines,
      sellitems,
      customers,
      products,
      payments,
      stat,
    ),
  );
}

export function* fetchShipmentsInCurrentGroupService() {
  const { groupId } = yield select(pathSelector);

  yield call(fetchShipmentsService, groupId);
}

export function* changeArrivalDateForShipmentPageToGroupFilterService(arrivalDate, groupId) {
  yield put(changeArrivalDateForShipmentPageToGroupFilter(arrivalDate, groupId));

  yield call(fetchShipmentsService, groupId);
}

export function* changeArrivalDateForShipmentPageToFilterInCurrentGroupService(arrivalDate) {
  const { groupId } = yield select(pathSelector);

  yield call(changeArrivalDateForShipmentPageToGroupFilterService, arrivalDate, groupId);
}
