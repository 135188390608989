import { Model, attr, fk } from 'redux-orm';

import ActionTypes from '../constants/ActionTypes';

export default class extends Model {
  static modelName = 'Brand';

  static fields = {
    id: attr(),
    name: attr(),
    url: attr(),
    regex: attr(),
    businessId: fk({
      to: 'Business',
      as: 'business',
      relatedName: 'brands',
    }),
  };

  static reducer({ type, payload }, Brand) {
    switch (type) {
      case ActionTypes.SOCKET_RECONNECT_HANDLE:
        Brand.all().delete();

        if (payload.brands) {
          payload.brands.forEach((brand) => {
            Brand.upsert(brand);
          });
        }

        break;
      case ActionTypes.CORE_INITIALIZE:
      case ActionTypes.BUSINESS_CREATE__SUCCESS:
      case ActionTypes.BUSINESS_CREATE_HANDLE:
        if (payload.brands) {
          payload.brands.forEach((brand) => {
            Brand.upsert(brand);
          });
        }

        break;
      case ActionTypes.BRAND_CREATE:
      case ActionTypes.BRAND_CREATE_HANDLE:
      case ActionTypes.BRAND_UPDATE__SUCCESS:
      case ActionTypes.BRAND_UPDATE_HANDLE:
        Brand.upsert(payload.brand);

        break;
      case ActionTypes.BRAND_CREATE__SUCCESS:
        Brand.withId(payload.localId).delete();
        Brand.upsert(payload.brand);

        break;
      // case ActionTypes.BRAND_CREATE_HANDLE:
      //   Brand.upsert(payload.brand);

      //   if (payload.brands) {
      //     payload.brands.forEach((brand) => {
      //       Brand.upsert(brand);
      //     });
      //   }

      //   break;
      case ActionTypes.BRAND_DELETE:
        Brand.withId(payload.id).delete();

        break;
      case ActionTypes.BRAND_DELETE__SUCCESS:
      case ActionTypes.BRAND_DELETE_HANDLE: {
        const brandModel = Brand.withId(payload.brand.id);

        if (brandModel) {
          brandModel.delete();
        }

        break;
      }
      default:
    }
  }
}
