/* eslint-disable no-unused-vars */
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { Input } from 'semantic-ui-react';
import { defaultArrivalRange, defaultArrivalRangeForShipmentPage } from '../../utils/date-range';

import User from '../User';
import Label from '../Label';
import { Statuses, StatusesShipment, ProvinceShipment } from '../../constants/Enums';
import StatusesPopup from '../StatusesPopup';
import ShipProvincePopup from './ShipProvincePopup';
import GroupMembershipsPopup from '../GroupMembershipsPopup';
import GroupBrandsPopup from '../GroupBrandsPopup';
import GroupCountriesPopup from '../GroupCountriesPopup';
import OrderDateEditPopup from '../OrderDateEditPopup';
import Paths from '../../constants/Paths';

import styles from './Filters.module.scss';

const Filters = React.memo(
  ({
    path,
    users,
    brands,
    countries,
    statuses,
    shipmentStatuses,
    filterShipProvinces,
    filterOrderDate,
    filterArrivalDate,
    filterWebOrderNo,
    filterArrivalDateForShipmentPage,
    allGroupMemberships,
    allBrands,
    allCountries,
    onUserAdd,
    onUserRemove,
    onBrandAdd,
    onBrandRemove,
    onCountryAdd,
    onCountryRemove,
    onOrderDateChange,
    onArrivalDateChange,
    onFilterWebOrderNoChange,
    onArrivalDateChangeForShipmentPage,
    onStatusAdd,
    onStatusRemove,
    onShipmentStatusAdd,
    onShipmentStatusRemove,
    onShipProvinceAdd,
    onShipProvinceRemove,
  }) => {
    const [t] = useTranslation();
    const history = useHistory();

    const today = new Date();
    today.setHours(23, 59, 59);
    const [dateRange, setDateRange] = useState(filterOrderDate);
    const [arrivalDateRange, setArrivalDateRange] = useState(filterArrivalDate);
    const [arrivalDateRangeForShipmentPage, setArrivalDateRangeForShipmentPage] = useState(
      filterArrivalDateForShipmentPage,
    );

    const inputValue = `${t('format:date', {
      postProcess: 'formatDate',
      value: filterOrderDate.start,
    })} - ${
      filterOrderDate.end
        ? t('format:date', { postProcess: 'formatDate', value: filterOrderDate.end })
        : ''
    }`;

    const inputArrivalValue = filterArrivalDate
      ? `${t('format:date', {
          postProcess: 'formatDate',
          value: filterArrivalDate.start,
        })} - ${
          filterArrivalDate.end
            ? t('format:date', { postProcess: 'formatDate', value: filterArrivalDate.end })
            : ''
        }`
      : '--';

    const handleOnChangeOrderDateClick = useCallback(
      (update) => {
        setDateRange({ start: update[0], end: update[1] });
        if (update[0] && update[1]) {
          const start = update[0];
          start.setHours(0, 0, 0);
          const end = update[1];
          end.setHours(23, 59, 59);
          onOrderDateChange({ start, end });
        }
      },
      [onOrderDateChange],
    );

    const handleOnChangeOrderDate = useCallback(
      (update) => {
        setDateRange(update);
        onOrderDateChange(update);
      },
      [onOrderDateChange],
    );

    const handleOnChangeArrivalDate = useCallback(
      (update) => {
        setArrivalDateRange(update);
        onArrivalDateChange(update);
      },
      [onArrivalDateChange],
    );

    const handleOnChangeArrivalDateForShipmentPage = useCallback(
      (update) => {
        // console.log('Filters: handleOnChangeArrivalDateForShipmentPage: update', update);
        setArrivalDateRangeForShipmentPage(update);
        // onArrivalDateChangeForShipmentPage(update);

        const start = format(update.start || '', 'yyyy-MM-dd');
        const end = format(update.end || '', 'yyyy-MM-dd');
        const search = new URLSearchParams(window.location.search);
        search.set('start', start);
        search.set('end', end);
        history.push({
          pathname: window.location.pathname,
          search: search.toString(),
        });
      },
      [history],
    );

    const handleRemoveUserClick = useCallback(
      (id) => {
        onUserRemove(id);
      },
      [onUserRemove],
    );

    const handleRemoveCountryClick = useCallback(
      (id) => {
        onCountryRemove(id);
      },
      [onCountryRemove],
    );

    const handleRemoveBrandClick = useCallback(
      (id) => {
        onBrandRemove(id);
      },
      [onBrandRemove],
    );

    const handleRemoveStatusClick = useCallback(
      (key) => {
        onStatusRemove(key);
      },
      [onStatusRemove],
    );

    const handleRemoveShipmentStatusClick = useCallback(
      (key) => {
        onShipmentStatusRemove(key);
      },
      [onShipmentStatusRemove],
    );

    const handleRemoveShipProvinceClick = useCallback(
      (key) => {
        onShipProvinceRemove(key);
      },
      [onShipProvinceRemove],
    );

    const handleFilterWebOrderNoChange = useCallback(
      (e) => {
        // console.log(e.target.value);
        onFilterWebOrderNoChange(e.target.value);
      },
      [onFilterWebOrderNoChange],
    );

    if (path === Paths.SHIPMENTPAGE) {
      const inputArrival = filterArrivalDateForShipmentPage
        ? `${t('format:date', {
            postProcess: 'formatDate',
            value: filterArrivalDateForShipmentPage.start,
          })} - ${
            filterArrivalDateForShipmentPage.end
              ? t('format:date', {
                  postProcess: 'formatDate',
                  value: filterArrivalDateForShipmentPage.end,
                })
              : ''
          }`
        : '--';

      return (
        <>
          <span className={styles.filter}>
            <OrderDateEditPopup
              defaultValue={filterArrivalDateForShipmentPage}
              title="common.selectArrivalDate"
              onUpdate={handleOnChangeArrivalDateForShipmentPage}
            >
              <button type="button" className={styles.filterButton}>
                <span className={styles.filterTitle}>{`${t('common.arrivalDate')}:`}</span>
                {filterArrivalDateForShipmentPage && (
                  <span className={styles.filterLabel}>{inputArrival}</span>
                )}
              </button>
            </OrderDateEditPopup>
          </span>
        </>
      );
    }

    if (path === Paths.SHIPMENTS) {
      return (
        <>
          <span className={styles.filter}>
            <StatusesPopup
              items={Object.values(StatusesShipment)}
              isFilter
              currentStatuses={shipmentStatuses}
              title="common.filterByStatuses"
              canEdit
              onSelect={onShipmentStatusAdd}
              onDeselect={onShipmentStatusRemove}
            >
              <button type="button" className={styles.filterButton}>
                <span className={styles.filterTitle}>{`${t('common.status')}:`}</span>
                {shipmentStatuses.length === 0 && (
                  <span className={styles.filterLabel}>{t('common.all')}</span>
                )}
              </button>
            </StatusesPopup>
            {shipmentStatuses
              .map((status) => StatusesShipment[status.toUpperCase()])
              .map(({ name: status, color }) => (
                <span key={status} className={styles.filterItem}>
                  <Label
                    name={t(`statusShipment.${status}`, {
                      context: 'title',
                    })}
                    color={color}
                    size="small"
                    onClick={() => handleRemoveShipmentStatusClick(status)}
                  />
                </span>
              ))}
          </span>
          <span className={styles.filter}>
            <ShipProvincePopup
              items={Object.values(ProvinceShipment)}
              isFilter
              currentShipProvince={filterShipProvinces}
              title="common.isShipProvince"
              canEdit
              onSelect={onShipProvinceAdd}
              onDeselect={onShipProvinceRemove}
            >
              <button type="button" className={styles.filterButton}>
                <span className={styles.filterTitle}>{`${t('common.isShipProvince')}:`}</span>
                {filterShipProvinces.length === 0 && (
                  <span className={styles.filterLabel}>{t('common.all')}</span>
                )}
              </button>
            </ShipProvincePopup>
            {filterShipProvinces
              .map((status) => ProvinceShipment[status.toUpperCase()])
              .map(({ name: status, color, text }) => (
                <span key={status} className={styles.filterItem}>
                  <Label
                    name={text}
                    color={color}
                    size="small"
                    onClick={() => handleRemoveShipProvinceClick(status)}
                  />
                </span>
              ))}
          </span>
        </>
      );
    }

    return (
      <>
        <span className={styles.filter}>
          <GroupMembershipsPopup
            items={allGroupMemberships}
            currentUserIds={users.map((user) => user.id)}
            title="common.filterByMembers_title"
            onUserSelect={onUserAdd}
            onUserDeselect={onUserRemove}
          >
            <button type="button" className={styles.filterButton}>
              <span className={styles.filterTitle}>{`${t('common.members')}:`}</span>
              {users.length === 0 && <span className={styles.filterLabel}>{t('common.all')}</span>}
            </button>
          </GroupMembershipsPopup>
          {users.map((user) => (
            <span key={user.id} className={styles.filterItem}>
              <User
                name={user.name}
                avatarUrl={user.avatarUrl}
                size="tiny"
                onClick={() => handleRemoveUserClick(user.id)}
              />
            </span>
          ))}
        </span>
        <span className={styles.filter}>
          <GroupCountriesPopup
            items={allCountries}
            currentCountryIds={countries.map((country) => country.id)}
            title="common.filterByCountries_title"
            onCountrySelect={onCountryAdd}
            onCountryDeselect={onCountryRemove}
          >
            <button type="button" className={styles.filterButton}>
              <span className={styles.filterTitle}>{`${t('common.country')}:`}</span>
              {countries.length === 0 && (
                <span className={styles.filterLabel}>{t('common.all')}</span>
              )}
            </button>
          </GroupCountriesPopup>
          {countries.map((country) => (
            <span key={country.id} className={styles.filterItem}>
              <Label
                name={country.name}
                size="tiny"
                color="light-orange"
                onClick={() => handleRemoveCountryClick(country.id)}
              />
            </span>
          ))}
        </span>
        <span className={styles.filter}>
          <GroupBrandsPopup
            items={allBrands}
            currentBrandIds={brands.map((brand) => brand.id)}
            title="common.filterByBrands_title"
            onBrandSelect={onBrandAdd}
            onBrandDeselect={onBrandRemove}
          >
            <button type="button" className={styles.filterButton}>
              <span className={styles.filterTitle}>{`${t('common.brand')}:`}</span>
              {brands.length === 0 && <span className={styles.filterLabel}>{t('common.all')}</span>}
            </button>
          </GroupBrandsPopup>
          {brands.map((brand) => (
            <span key={brand.id} className={styles.filterItem}>
              <Label
                name={brand.name}
                size="tiny"
                color="tank-green"
                onClick={() => handleRemoveBrandClick(brand.id)}
              />
            </span>
          ))}
        </span>
        <span className={styles.filter}>
          <StatusesPopup
            items={Object.values(Statuses)}
            isFilter
            currentStatuses={statuses}
            title="common.filterByStatuses"
            canEdit
            onSelect={onStatusAdd}
            onDeselect={onStatusRemove}
          >
            <button type="button" className={styles.filterButton}>
              <span className={styles.filterTitle}>{`${t('common.status')}:`}</span>
              {statuses.length === 0 && (
                <span className={styles.filterLabel}>{t('common.all')}</span>
              )}
            </button>
          </StatusesPopup>
          {statuses
            .map((status) => Statuses[status.toUpperCase()])
            .map(({ name: status, color }) => (
              <span key={status} className={styles.filterItem}>
                <Label
                  name={t(`status.${status}`, {
                    context: 'title',
                  })}
                  color={color}
                  size="small"
                  onClick={() => handleRemoveStatusClick(status)}
                />
              </span>
            ))}
        </span>
        <span className={styles.filter}>
          <OrderDateEditPopup defaultValue={filterOrderDate} onUpdate={handleOnChangeOrderDate}>
            <button type="button" className={styles.filterButton}>
              <span className={styles.filterTitle}>{`${t('common.dateOrder')}:`}</span>
              {filterOrderDate && <span className={styles.filterLabel}>{inputValue}</span>}
            </button>
          </OrderDateEditPopup>
        </span>
        <span className={styles.filter}>
          <OrderDateEditPopup
            defaultValue={filterArrivalDate || defaultArrivalRange()}
            title="common.selectArrivalDate"
            onUpdate={handleOnChangeArrivalDate}
          >
            <button type="button" className={styles.filterButton}>
              <span className={styles.filterTitle}>{`${t('common.arrivalDate')}:`}</span>
              <span className={styles.filterLabel}>{inputArrivalValue}</span>
            </button>
          </OrderDateEditPopup>
        </span>
        <span className={styles.filter}>
          {/* <span className={styles.filterTitle}>{`${t('common.webOrderNo')}:`}</span> */}
          <Input
            // inverted
            name="filterWebOrderNo"
            value={filterWebOrderNo || ''}
            className={styles.field}
            placeholder={`${t('common.webOrderNo')}:`}
            onChange={handleFilterWebOrderNoChange}
          />
        </span>
        {/* <span className={styles.filter}>
          <span className={styles.filterButton}>
            <span className={styles.filterTitle}>{`${t('common.dateOrder')}:`}</span>
            <span className={styles.filterLabel}>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                selectsRange
                maxDate={today}
                startDate={dateRange.start}
                endDate={dateRange.end}
                // monthsShown={2}
                todayButton="Hôm nay"
                // onChange={(update) => {
                //   setDateRange({ start: update[0], end: update[1] });
                // }}
                onChange={handleOnChangeOrderDateClick}
                value={inputValue}
                selected={dateRange.end}
              />
            </span>
          </span>
        </span> */}
      </>
    );
  },
);

Filters.propTypes = {
  path: PropTypes.string.isRequired,
  users: PropTypes.arrayOf(PropTypes.any).isRequired,
  brands: PropTypes.arrayOf(PropTypes.any).isRequired,
  countries: PropTypes.arrayOf(PropTypes.any).isRequired,
  statuses: PropTypes.arrayOf(PropTypes.any).isRequired,
  shipmentStatuses: PropTypes.arrayOf(PropTypes.any).isRequired,
  filterShipProvinces: PropTypes.arrayOf(PropTypes.any).isRequired,
  filterOrderDate: PropTypes.objectOf(PropTypes.any).isRequired,
  filterArrivalDate: PropTypes.objectOf(PropTypes.any),
  filterWebOrderNo: PropTypes.string,
  filterArrivalDateForShipmentPage: PropTypes.objectOf(PropTypes.any),
  allGroupMemberships: PropTypes.arrayOf(PropTypes.any).isRequired,
  allBrands: PropTypes.arrayOf(PropTypes.any).isRequired,
  allCountries: PropTypes.arrayOf(PropTypes.any).isRequired,
  onUserAdd: PropTypes.func.isRequired,
  onUserRemove: PropTypes.func.isRequired,
  onBrandAdd: PropTypes.func.isRequired,
  onBrandRemove: PropTypes.func.isRequired,
  onCountryAdd: PropTypes.func.isRequired,
  onCountryRemove: PropTypes.func.isRequired,
  onOrderDateChange: PropTypes.func.isRequired,
  onArrivalDateChange: PropTypes.func.isRequired,
  onFilterWebOrderNoChange: PropTypes.func.isRequired,
  onArrivalDateChangeForShipmentPage: PropTypes.func.isRequired,
  onStatusAdd: PropTypes.func.isRequired,
  onStatusRemove: PropTypes.func.isRequired,
  onShipmentStatusAdd: PropTypes.func.isRequired,
  onShipmentStatusRemove: PropTypes.func.isRequired,
  onShipProvinceAdd: PropTypes.func.isRequired,
  onShipProvinceRemove: PropTypes.func.isRequired,
};

Filters.defaultProps = {
  filterArrivalDate: undefined,
  filterWebOrderNo: undefined,
  filterArrivalDateForShipmentPage: undefined,
};

export default Filters;
