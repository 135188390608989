import ActionTypes from '../constants/ActionTypes';

export const createShipment = (shipment) => ({
  type: ActionTypes.SHIPMENT_CREATE,
  payload: {
    shipment,
  },
});

createShipment.success = (localId, shipment) => ({
  type: ActionTypes.SHIPMENT_CREATE__SUCCESS,
  payload: {
    localId,
    shipment,
  },
});

createShipment.failure = (localId, error) => ({
  type: ActionTypes.SHIPMENT_CREATE__FAILURE,
  payload: {
    localId,
    error,
  },
});

export const handleShipmentCreate = (shipment) => ({
  type: ActionTypes.SHIPMENT_CREATE_HANDLE,
  payload: {
    shipment,
  },
});

export const updateShipment = (id, data) => ({
  type: ActionTypes.SHIPMENT_UPDATE,
  payload: {
    id,
    data,
  },
});

updateShipment.success = (shipment) => ({
  type: ActionTypes.SHIPMENT_UPDATE__SUCCESS,
  payload: {
    shipment,
  },
});

updateShipment.failure = (id, error) => ({
  type: ActionTypes.SHIPMENT_UPDATE__FAILURE,
  payload: {
    id,
    error,
  },
});

export const handleShipmentUpdate = (shipment) => ({
  type: ActionTypes.SHIPMENT_UPDATE_HANDLE,
  payload: {
    shipment,
  },
});

export const deleteShipment = (id) => ({
  type: ActionTypes.SHIPMENT_DELETE,
  payload: {
    id,
  },
});

deleteShipment.success = (shipment) => ({
  type: ActionTypes.SHIPMENT_DELETE__SUCCESS,
  payload: {
    shipment,
  },
});

deleteShipment.failure = (id, error) => ({
  type: ActionTypes.SHIPMENT_DELETE__FAILURE,
  payload: {
    id,
    error,
  },
});

export const handleShipmentDelete = (shipment) => ({
  type: ActionTypes.SHIPMENT_DELETE_HANDLE,
  payload: {
    shipment,
  },
});

export const fetchShipment = (id) => ({
  type: ActionTypes.SHIPMENT_FETCH,
  payload: {
    id,
  },
});

fetchShipment.success = (shipment, shiplines, sellitems, addresses, products, payments) => ({
  type: ActionTypes.SHIPMENT_FETCH__SUCCESS,
  payload: {
    shipment,
    shiplines,
    sellitems,
    addresses,
    products,
    payments,
  },
});

fetchShipment.failure = (id, error) => ({
  type: ActionTypes.SHIPMENT_FETCH__FAILURE,
  payload: {
    id,
    error,
  },
});
