import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  currentUserSelector,
  countriesForCurrentBusinessSelector,
  brandsForCurrentBusinessSelector,
  categoriesForCurrentBusinessSelector,
  attachmentUploadImgurSelector,
} from '../selectors';
import {
  clearUserCreateError,
  createProduct,
  closeModal,
  createAttachmentProduct,
} from '../actions/entry';
import ProductAddPopup from '../components/ProductAddPopup';

const mapStateToProps = (state) => {
  const { data: defaultData, isSubmitting, error } = state.ui.productCreateForm;
  const { isAdmin } = currentUserSelector(state);
  const countries = countriesForCurrentBusinessSelector(state);
  const brands = brandsForCurrentBusinessSelector(state);
  const categories = categoriesForCurrentBusinessSelector(state);
  const attachmentImgur = attachmentUploadImgurSelector(state);
  return {
    countries,
    brands,
    categories,
    defaultData,
    attachmentImgur,
    isSubmitting,
    error,
    isAdmin,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onAttachmentCreate: createAttachmentProduct,
      onCreate: createProduct,
      onMessageDismiss: clearUserCreateError,
      onClose: closeModal,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ProductAddPopup);
