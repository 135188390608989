import socket from './socket';
import http from './http';
import { transformAttachment } from './attachments';
import { transformSellitem } from './sellitems';

/* Actions */

const getProducts = (groupId, data, headers) =>
  socket.get(`/groups/${groupId}/products`, data, headers).then((body) => {
    return {
      ...body,
      included: {
        ...body.included,
        attachments: body.included.attachments.map(transformAttachment),
        sellitems: body.included.sellitems.map(transformSellitem),
      },
    };
  });

const searchProducts = (groupId, data, headers) =>
  socket.get(`/groups/${groupId}/search-products`, data, headers).then((body) => ({
    ...body,
    included: {
      ...body.included,
      attachments: body.included.attachments.map(transformAttachment),
      sellitems: body.included.sellitems
        ? body.included.sellitems.map(transformSellitem)
        : undefined,
    },
  }));

const createProduct = (groupId, data, headers) =>
  socket.post(`/groups/${groupId}/products`, data, headers).then((body) => ({
    ...body,
  }));

const getProduct = (id, headers) =>
  socket.get(`/products/${id}`, undefined, headers).then((body) => ({
    ...body,
    included: {
      ...body.included,
      attachments: body.included.attachments.map(transformAttachment),
      sellitems: body.included.sellitems.map(transformSellitem),
    },
  }));

const updateProduct = (id, data, headers) =>
  socket.patch(`/products/${id}`, data, headers).then((body) => ({
    ...body,
  }));

const deleteProduct = (id, headers) =>
  socket.delete(`/products/${id}`, undefined, headers).then((body) => ({
    ...body,
  }));

/* Event handlers */

const makeHandleProductCreate = (next) => (body) => {
  next({
    ...body,
  });
};

const makeHandleProductUpdate = (next) => (body) => {
  next({
    ...body,
  });
};

const makeHandleProductDelete = makeHandleProductUpdate;

const importProduct = (groupId, data, headers) =>
  http.post(`/groups/${groupId}/import-products`, data, headers);

export default {
  getProducts,
  importProduct,
  searchProducts,
  createProduct,
  getProduct,
  updateProduct,
  deleteProduct,
  makeHandleProductCreate,
  makeHandleProductUpdate,
  makeHandleProductDelete,
};
