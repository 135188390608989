import ActionTypes from '../constants/ActionTypes';

export const createCountry = (country) => ({
  type: ActionTypes.COUNTRY_CREATE,
  payload: {
    country,
  },
});

createCountry.success = (localId, country) => ({
  type: ActionTypes.COUNTRY_CREATE__SUCCESS,
  payload: {
    localId,
    country,
  },
});

createCountry.failure = (localId, error) => ({
  type: ActionTypes.COUNTRY_CREATE__FAILURE,
  payload: {
    localId,
    error,
  },
});

export const handleCountryCreate = (country) => ({
  type: ActionTypes.COUNTRY_CREATE_HANDLE,
  payload: {
    country,
  },
});

export const updateCountry = (id, data) => ({
  type: ActionTypes.COUNTRY_UPDATE,
  payload: {
    id,
    data,
  },
});

updateCountry.success = (country) => ({
  type: ActionTypes.COUNTRY_UPDATE__SUCCESS,
  payload: {
    country,
  },
});

updateCountry.failure = (id, error) => ({
  type: ActionTypes.COUNTRY_UPDATE__FAILURE,
  payload: {
    id,
    error,
  },
});

export const handleCountryUpdate = (country) => ({
  type: ActionTypes.COUNTRY_UPDATE_HANDLE,
  payload: {
    country,
  },
});

export const deleteCountry = (id) => ({
  type: ActionTypes.COUNTRY_DELETE,
  payload: {
    id,
  },
});

deleteCountry.success = (country) => ({
  type: ActionTypes.COUNTRY_DELETE__SUCCESS,
  payload: {
    country,
  },
});

deleteCountry.failure = (id, error) => ({
  type: ActionTypes.COUNTRY_DELETE__FAILURE,
  payload: {
    id,
    error,
  },
});

export const handleCountryDelete = (country) => ({
  type: ActionTypes.COUNTRY_DELETE_HANDLE,
  payload: {
    country,
  },
});

export const addCountryToGroupFilter = (id, groupId) => ({
  type: ActionTypes.COUNTRY_TO_GROUP_FILTER_ADD,
  payload: {
    id,
    groupId,
  },
});

export const removeCountryFromGroupFilter = (id, groupId) => ({
  type: ActionTypes.COUNTRY_FROM_GROUP_FILTER_REMOVE,
  payload: {
    id,
    groupId,
  },
});
