import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Menu } from 'semantic-ui-react';
import { Popup } from '../../lib/custom-ui';

import Item from './Item';

import styles from './GroupCountriesStep.module.scss';

const GroupCountriesStep = React.memo(
  ({ items, currentCountryIds, title, onCountrySelect, onCountryDeselect, onBack }) => {
    const [t] = useTranslation();

    const handleCountrySelect = useCallback(
      (id) => {
        onCountrySelect(id);
      },
      [onCountrySelect],
    );

    const handleCountryDeselect = useCallback(
      (id) => {
        onCountryDeselect(id);
      },
      [onCountryDeselect],
    );

    return (
      <>
        <Popup.Header onBack={onBack}>{t(title)}</Popup.Header>
        <Popup.Content>
          <Menu secondary vertical className={styles.menu}>
            {items.map((item) => (
              <Item
                key={item.id}
                isPersisted={item.isPersisted}
                isActive={currentCountryIds.includes(item.id)}
                code={item.code}
                name={item.name}
                onSelect={() => handleCountrySelect(item.id)}
                onDeselect={() => handleCountryDeselect(item.id)}
              />
            ))}
          </Menu>
        </Popup.Content>
      </>
    );
  },
);

GroupCountriesStep.propTypes = {
  /* eslint-disable react/forbid-prop-types */
  items: PropTypes.array.isRequired,
  currentCountryIds: PropTypes.array.isRequired,
  /* eslint-enable react/forbid-prop-types */
  title: PropTypes.string,
  onCountrySelect: PropTypes.func.isRequired,
  onCountryDeselect: PropTypes.func.isRequired,
  onBack: PropTypes.func,
};

GroupCountriesStep.defaultProps = {
  title: 'common.members',
  onBack: undefined,
};

export default GroupCountriesStep;
