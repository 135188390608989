/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tab } from 'semantic-ui-react';
import { HotTable } from '@handsontable/react';
import Handsontable from 'handsontable';

import styles from './CommonPane.module.scss';

const CommonPane = React.memo(
  ({ items, columns, colHeaders, colWidths, onUpdate, onCreate, onDelete }) => {
    // const hotTableComponent = useRef(null);
    const [data, setData] = useState({ name: undefined, code: undefined });
    const [saving, setSaving] = useState(false);

    const beforeChange = (changes, source) => {
      // [[row, prop, oldVal, newVal], ...]
      // console.log('beforeChange', changes);
      // eslint-disable-next-line eqeqeq
      if (changes[0][2] != changes[0][3]) {
        return true;
      }
      return false;
    };

    const afterChange = (changes, source) => {
      // [[row, prop, oldVal, newVal], ...]
      // console.log('afterChange:', changes);
      if (changes) {
        changes.map((c) => {
          const field = c[1];
          const row = items[c[0]];
          // console.log('row.id:', row.id);
          if (row && row.id) {
            if (c[2] !== c[3] && c[3] !== '') {
              onUpdate(row.id, { [field]: c[3] });
            }
          } else {
            const cleanData = { ...data, [field]: c[3] };
            setData(cleanData);
            // console.log('cleanData:', cleanData);
            if (cleanData.name && (cleanData.code || !columns[2].data.code)) {
              onCreate(cleanData);
              setData({ name: undefined, code: undefined });
            }
          }
          return c;
        });
      }
    };

    const afterCreateRow = (index, amount) => {
      // console.log(index, amount);
    };

    const beforeRemoveRow = (index, amount, physicalRows) => {
      const row = items[index];
      if (row && row.id) onDelete(row.id);
    };

    const beforeKeyDown = (event) => {
      if (event.keyCode === 46) {
        Handsontable.dom.stopImmediatePropagation(event);
      }
    };

    return (
      <Tab.Pane attached={false} className={styles.wrapper}>
        <div id="hot-app" className={styles.hotWrapper}>
          <HotTable
            data={items}
            columns={columns}
            colHeaders={colHeaders}
            colWidths={colWidths}
            hiddenColumns={{ columns: [0] }}
            rowHeaders
            // width={678}
            height={Math.min(24, items.length + 1) * 24 + 26}
            minSpareRows={1}
            stretchH="all"
            // contextMenu={['row_above', 'row_below', 'remove_row']}
            contextMenu={['remove_row']}
            enterMoves={{ col: 1, row: 0 }}
            afterChange={afterChange}
            afterCreateRow={afterCreateRow}
            beforeRemoveRow={beforeRemoveRow}
            beforeKeyDown={beforeKeyDown}
            beforeChange={beforeChange}
            licenseKey="non-commercial-and-evaluation"
          />
        </div>
        {saving && <div>Saving...</div>}
      </Tab.Pane>
    );
  },
);

CommonPane.propTypes = {
  /* eslint-disable react/forbid-prop-types */
  items: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  colHeaders: PropTypes.array.isRequired,
  colWidths: PropTypes.array,
  /* eslint-enable react/forbid-prop-types */
  onUpdate: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

CommonPane.defaultProps = {
  colWidths: undefined,
};

export default CommonPane;
