import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Button, Icon, List } from 'semantic-ui-react';

import styles from './Payments.module.scss';
import ActionsPopup from './ActionsPopup';
import { PaymentTypes } from '../../../constants/Enums';

const Payments = React.memo(({ accounts, items, canEdit, onUpdate, onDelete }) => {
  const [t] = useTranslation();

  const handleUpdate = useCallback(
    (id, data) => {
      onUpdate(id, data);
    },
    [onUpdate],
  );

  const handleDelete = useCallback(
    (id) => {
      onDelete(id);
    },
    [onDelete],
  );

  return (
    <>
      <List divided className={styles.addresses}>
        {items.map(({ id, name, accountId, amount, type, createdAt }) => (
          <List.Item key={id} className={classNames(canEdit && styles.contentHoverable)}>
            <List.Content>
              {createdAt && (
                <span className={styles.meta}>
                  {t('format:longDateTime', {
                    postProcess: 'formatDate',
                    value: new Date(createdAt),
                  })}
                  [{accounts.find((a) => a.id === accountId)?.name}]
                </span>
              )}
              <br />
              {name}&nbsp;
              {type === PaymentTypes.DEPOSIT ? '+' : '-'}
              {(amount * 1).toLocaleString()}
            </List.Content>
            <ActionsPopup
              accounts={accounts}
              defaultData={{ id, name, accountId, amount, type, createdAt }}
              onUpdate={(data) => handleUpdate(id, data)}
              onDelete={() => handleDelete(id)}
            >
              <Button className={classNames(styles.button, styles.target)}>
                <Icon fitted name="pencil" size="small" />
              </Button>
            </ActionsPopup>
          </List.Item>
        ))}
        {items.length === 0 && <List.Item content={t('common.noPayment')} />}
      </List>
    </>
  );
});

Payments.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.any).isRequired,
  items: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  canEdit: PropTypes.bool.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default Payments;
