import ActionTypes from '../constants/ActionTypes';

export const addStatusToGroupFilter = (key, groupId) => ({
  type: ActionTypes.STATUS_TO_GROUP_FILTER_ADD,
  payload: {
    key,
    groupId,
  },
});

export const removeStatusFromGroupFilter = (key, groupId) => ({
  type: ActionTypes.STATUS_FROM_GROUP_FILTER_REMOVE,
  payload: {
    key,
    groupId,
  },
});

export const addShipmentStatusToGroupFilter = (key, groupId) => ({
  type: ActionTypes.SHIPMENT_STATUS_TO_GROUP_FILTER_ADD,
  payload: {
    key,
    groupId,
  },
});

export const removeShipmentStatusFromGroupFilter = (key, groupId) => ({
  type: ActionTypes.SHIPMENT_STATUS_FROM_GROUP_FILTER_REMOVE,
  payload: {
    key,
    groupId,
  },
});

export const addShipProvinceToGroupFilter = (key, groupId) => ({
  type: ActionTypes.SHIP_PROVINCE_TO_GROUP_FILTER_ADD,
  payload: {
    key,
    groupId,
  },
});

export const removeShipProvinceFromGroupFilter = (key, groupId) => ({
  type: ActionTypes.SHIP_PROVINCE_FROM_GROUP_FILTER_REMOVE,
  payload: {
    key,
    groupId,
  },
});
