import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Menu } from 'semantic-ui-react';
import { withPopup } from '../../../lib/popup';
import { Popup } from '../../../lib/custom-ui';

import { useSteps } from '../../../hooks';
import DeleteStep from '../../DeleteStep';

import styles from './ActionsPopup.module.scss';
import EditStep from './EditStep';

const StepTypes = {
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
};

const ActionsStep = React.memo(
  ({ defaultData, isDefault, onCreate, onUpdate, onDelete, onCustomerUpdate, onClose }) => {
    const [t] = useTranslation();
    const [step, openStep, handleBack] = useSteps();

    const handleUpdateClick = useCallback(() => {
      openStep(StepTypes.UPDATE);
    }, [openStep]);

    const handleSetDefaultClick = useCallback(() => {
      onCustomerUpdate();
      onClose();
    }, [onClose, onCustomerUpdate]);

    const handleDeleteClick = useCallback(() => {
      openStep(StepTypes.DELETE);
    }, [openStep]);

    if (step) {
      switch (step.type) {
        case StepTypes.UPDATE:
          return (
            <EditStep
              title="action.editAddress"
              defaultData={defaultData}
              onCreate={onCreate}
              onUpdate={onUpdate}
              onBack={handleBack}
              onClose={onClose}
            />
          );
        case StepTypes.DELETE:
          return (
            <DeleteStep
              title={t('common.deleteAddress', {
                context: 'title',
              })}
              content={t('common.areYouSureYouWantToDeleteThisAddress')}
              buttonContent={t('action.deleteAddress')}
              onConfirm={onDelete}
              onBack={handleBack}
            />
          );
        default:
          break;
      }
    }

    return (
      <>
        <Popup.Header>
          {t('common.actions', {
            context: 'title',
          })}
        </Popup.Header>
        <Popup.Content>
          <Menu secondary vertical className={styles.menu}>
            <Menu.Item className={styles.menuItem} onClick={handleUpdateClick}>
              {t('action.editAddress', {
                context: 'title',
              })}
            </Menu.Item>
            {!isDefault && (
              <>
                <Menu.Item className={styles.menuItem} onClick={handleSetDefaultClick}>
                  {t('action.setDefault', {
                    context: 'title',
                  })}
                </Menu.Item>
                <Menu.Item className={styles.menuItem} onClick={handleDeleteClick}>
                  {t('action.deleteAddress', {
                    context: 'title',
                  })}
                </Menu.Item>
              </>
            )}
          </Menu>
        </Popup.Content>
      </>
    );
  },
);

ActionsStep.propTypes = {
  defaultData: PropTypes.objectOf(PropTypes.any).isRequired,
  isDefault: PropTypes.bool.isRequired,
  onCreate: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onCustomerUpdate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default withPopup(ActionsStep);
