/* eslint-disable no-unused-vars */
import React, { useRef, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Button, Grid, Image, Modal, Table } from 'semantic-ui-react';
import { HotTable, HotColumn } from '@handsontable/react';
import Handsontable from 'handsontable';
import ReactToPrint from 'react-to-print';
import { Markdown } from '../../lib/custom-ui';

import NameField from './NameField';
import DescriptionEdit from './DescriptionEdit';
import Payments from './Payments';
import Attachments from './Attachments';
import AttachmentAddZone from './AttachmentAddZone';
import AttachmentAddPopup from './AttachmentAddPopup';
import Actions from './Actions';
import User from '../User';
import Label from '../Label';
import DueDate from '../DueDate';
import Timer from '../Timer';
import GroupMembershipsPopup from '../GroupMembershipsPopup';
import LabelsPopup from '../LabelsPopup';
import DueDateEditPopup from '../DueDateEditPopup';
import TimerEditPopup from '../TimerEditPopup';
import SellitemMovePopup from '../SellitemMovePopup';
import DeletePopup from '../DeletePopup';
import SellitemModalPrint from './SellitemModalPrint';
import styles from './SellitemModal.module.scss';

const SellitemModal = React.memo(
  ({
    business,
    sellitems,
    finalTotals,
    finalPayments,
    name,
    description,
    arrivalDate,
    createdAt,
    timer,
    isSubscribed,
    isActionsFetching,
    isAllActionsFetched,
    customer,
    groupId,
    businessId,
    users,
    labels,
    locationQuery,
    payments,
    attachments,
    actions,
    allBusinessesToCustomers,
    allGroupMemberships,
    allLabels,
    canEdit,
    canEditAllCommentActions,
    defaultAddress,
    onUpdate,
    onMove,
    onTransfer,
    onDelete,
    onUserAdd,
    onUserRemove,
    onGroupFetch,
    onLabelAdd,
    onLabelRemove,
    onLabelCreate,
    onLabelUpdate,
    onLabelDelete,
    onPaymentCreate,
    onPaymentUpdate,
    onPaymentDelete,
    onAttachmentCreate,
    onAttachmentUpdate,
    onAttachmentDelete,
    onActionsFetch,
    onCommentActionCreate,
    onCommentActionUpdate,
    onCommentActionDelete,
    onClose,
  }) => {
    const [t] = useTranslation();

    const wrapper = useRef(null);

    const handleNameUpdate = useCallback(
      (newName) => {
        onUpdate({
          name: newName,
        });
      },
      [onUpdate],
    );

    const handleDescriptionUpdate = useCallback(
      (newDescription) => {
        onUpdate({
          description: newDescription,
        });
      },
      [onUpdate],
    );

    const handleDueDateUpdate = useCallback(
      (newDueDate) => {
        onUpdate({
          arrivalDate: newDueDate,
        });
      },
      [onUpdate],
    );

    const handleTimerUpdate = useCallback(
      (newTimer) => {
        onUpdate({
          timer: newTimer,
        });
      },
      [onUpdate],
    );

    const handleCoverUpdate = useCallback(
      (newCoverAttachmentId) => {
        onUpdate({
          coverAttachmentId: newCoverAttachmentId,
        });
      },
      [onUpdate],
    );

    const handleToggleSubscriptionClick = useCallback(() => {
      onUpdate({
        isSubscribed: !isSubscribed,
      });
    }, [isSubscribed, onUpdate]);

    const userIds = users.map((user) => user.id);
    const labelIds = labels.map((label) => label.id);

    const colHeaders = useMemo(
      () => [
        '#',
        t('common.sellitemsInvoice.columnSaler'),
        t('common.sellitemsInvoice.columnProductName'),
        t('common.sellitemsInvoice.columnColor'),
        t('common.sellitemsInvoice.columnImage'),
        t('common.sellitemsInvoice.columnSize'),
        t('common.sellitemsInvoice.columnQuality'),
        t('common.sellitemsInvoice.columnPriceWithoutweightAmount'),
        t('common.sellitemsInvoice.columnWeigh'),
        t('common.sellitemsInvoice.columnweighAmount'),
        t('common.sellitemsInvoice.columnFinalTotal'),
        t('common.sellitemsInvoice.columnStatus'),
        // t('common.sellitemsInvoice.columnNote'),
        locationQuery.v === 'v'
          ? t('common.sellitemsInvoice.columnArriveDay')
          : t('common.sellitemsInvoice.columnExpectedArriveDay'),
      ],
      [locationQuery.v, t],
    );

    const colSummaryHeaders = useMemo(
      () => [
        '#',
        t('common.sellitemsInvoice.columnSumRefNum'),
        t('common.sellitemsInvoice.columnSumTotal'),
        t('common.sellitemsInvoice.columnSumPayment'),
        t('common.sellitemsInvoice.columnSumNote'),
      ],
      [t],
    );

    const printTableRef = useRef();

    const weightAmountRenderer = (instance, td, row, col, prop, value, cellProperties) => {
      const isWithWeightAmount = instance.getDataAtRowProp(row, 'isWithWeightAmount');
      const quantity = instance.getDataAtRowProp(row, 'quantity');
      Handsontable.renderers.TextRenderer.apply(this, [
        instance,
        td,
        row,
        col,
        prop,
        value,
        cellProperties,
      ]);
      if (quantity > 0) {
        if (isWithWeightAmount > 0) {
          // eslint-disable-next-line no-param-reassign
          td.style.backgroundColor = '#ddd';
          // eslint-disable-next-line no-param-reassign
          td.innerText = t('common.withWeightAmount');
        } else if (value) {
          // eslint-disable-next-line no-param-reassign
          td.innerText = parseInt(value, 10).toLocaleString('en-GB');
        } else {
          // eslint-disable-next-line no-param-reassign
          td.innerText = t('common.withoutWeightAmount');
        }
      } else {
        // eslint-disable-next-line no-param-reassign
        td.innerText = '';
      }
      return td;
    };

    const sumWeightAmount = (weightAmount, isWithWeightAmount, quantity) => {
      if (quantity > 0) {
        if (isWithWeightAmount > 0) {
          return t('common.withWeightAmount');
        }
        if (weightAmount) {
          return weightAmount.toLocaleString('en-GB');
        }
        return t('common.withoutWeightAmount');
      }
      return '';
    };

    const buttonColor = locationQuery.v === 'v' ? 'blue' : 'green';

    const contentNode = (
      <Modal.Content>
        <Grid className={styles.grid}>
          <Grid.Row className={styles.headerPadding}>
            <Grid.Column width={3} className={styles.headerPadding}>
              <div
                className={styles.thumbnail}
                style={{
                  background:
                    business.background &&
                    business.background.type === 'image' &&
                    business.backgroundImage
                      ? `url("${business.backgroundImage.url}") center / contain no-repeat`
                      : `url("/logo512.png") center / contain no-repeat`,
                }}
              />
            </Grid.Column>
            <Grid.Column width={5} className={styles.headerPadding}>
              <div className={styles.headerWrapper}>
                <strong>{`${t('common.sellitemsInvoice.date')}: `}</strong>
                <span>{format(new Date(), 'dd/MM/yyyy')}</span>
              </div>
              <div className={styles.headerWrapper}>
                <strong>{`${t('common.sellitemsInvoice.facebook')}: `}</strong>
                <span>{customer.name}</span>
              </div>
              <div className={styles.headerWrapper}>
                <strong>{`${t('common.sellitemsInvoice.customerID')}: `}</strong>
                <span>{customer.code}</span>
              </div>
              <div className={styles.headerWrapper}>
                <strong>{`${t('common.sellitemsInvoice.wallet')}: `}</strong>
                <span>{customer.totalDepositRemain.toLocaleString('en-GB')} ₫</span>
              </div>
            </Grid.Column>
            <Grid.Column width={4} className={styles.headerPadding}>
              <div className={styles.headerWrapper}>
                <strong>{`${t('common.sellitemsInvoice.address')}: `}</strong>
                <span>
                  {defaultAddress.address}, {defaultAddress.ward}, {defaultAddress.district},{' '}
                  {defaultAddress.province}
                </span>
              </div>
              <div className={styles.headerWrapper}>
                <strong>{`${t('common.sellitemsInvoice.phoneNumber')}: `}</strong>
                <span>{defaultAddress.phone}</span>
              </div>
            </Grid.Column>
            <Grid.Column width={4} className={styles.headerPadding}>
              <div className={styles.headerWrapper}>
                <br />
                <br />
              </div>
              <div className={styles.paymentLayout}>
                <div className={styles.paymentTitle}>{`${t(
                  'common.sellitemsInvoice.totalPayment',
                )}: `}</div>
                <span>{finalTotals.toLocaleString('en-GB')} ₫</span>
              </div>
              <div className={styles.paymentLayout}>
                <div className={styles.paymentTitle}>{`${t(
                  'common.sellitemsInvoice.paid',
                )}: `}</div>
                <div>{finalPayments.toLocaleString('en-GB')} ₫</div>
              </div>
              <div className={styles.paymentLayout}>
                <div className={styles.paymentTitle}>{`${t(
                  'common.sellitemsInvoice.debt',
                )}: `}</div>
                <div className={styles.debt}>
                  {(finalTotals - finalPayments).toLocaleString('en-GB')} ₫
                </div>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <div id="hot-app" className={styles.hotWrapper}>
          <Table className={styles[locationQuery.v]}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                <Table.HeaderCell width={1}>
                  {t('common.sellitemsInvoice.columnSaler')}
                </Table.HeaderCell>
                <Table.HeaderCell width={3}>
                  {t('common.sellitemsInvoice.countryBrandName')} <br />
                  {t('common.productName')}
                </Table.HeaderCell>
                <Table.HeaderCell>{t('common.sellitemsInvoice.columnColor')}</Table.HeaderCell>
                <Table.HeaderCell>{t('common.sellitemsInvoice.columnImage')}</Table.HeaderCell>
                <Table.HeaderCell>{t('common.sellitemsInvoice.columnSize')}</Table.HeaderCell>
                <Table.HeaderCell>{t('common.sellitemsInvoice.columnQuality')}</Table.HeaderCell>
                <Table.HeaderCell>
                  {t('common.sellitemsInvoice.columnPriceWithoutweightAmount')}
                </Table.HeaderCell>
                <Table.HeaderCell>{t('common.sellitemsInvoice.columnWeigh')}</Table.HeaderCell>

                <Table.HeaderCell>
                  {t('common.sellitemsInvoice.columnweighAmount')}
                </Table.HeaderCell>
                <Table.HeaderCell>{t('common.sellitemsInvoice.columnFinalTotal')}</Table.HeaderCell>
                <Table.HeaderCell>{t('common.sellitemsInvoice.columnStatus')}</Table.HeaderCell>
                {/* <Table.HeaderCell>{t('common.sellitemsInvoice.columnNote')}</Table.HeaderCell> */}
                <Table.HeaderCell>
                  {locationQuery.v === 'v'
                    ? t('common.sellitemsInvoice.columnArriveDay')
                    : t('common.sellitemsInvoice.columnExpectedArriveDay')}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {sellitems.map((sellitem) => (
                <Table.Row key={sellitem.id + sellitem.index}>
                  <Table.Cell>{sellitem.index}</Table.Cell>
                  <Table.Cell>{sellitem.creator}</Table.Cell>
                  <Table.Cell>
                    {sellitem.countryBrandName} <br /> {sellitem.sellitemName}
                  </Table.Cell>
                  <Table.Cell>{sellitem.color}</Table.Cell>
                  <Table.Cell>
                    <Image src={sellitem.imageUrl} size="mini" />
                  </Table.Cell>
                  <Table.Cell>{sellitem.size}</Table.Cell>
                  <Table.Cell>{sellitem.quantity}</Table.Cell>
                  <Table.Cell>
                    {sellitem.totalWithoutWeightAmount > 0 &&
                      (sellitem.totalWithoutWeightAmount * 1).toLocaleString('en-GB')}
                  </Table.Cell>
                  <Table.Cell>{(1 * sellitem.weight).toFixed(3)}</Table.Cell>
                  <Table.Cell>
                    {sumWeightAmount(
                      (sellitem.weightAmount * 1).toLocaleString('en-GB'),
                      sellitem.isWithWeightAmount,
                      sellitem.quantity,
                    )}
                  </Table.Cell>
                  <Table.Cell>{(sellitem.finalTotal * 1).toLocaleString('en-GB')}</Table.Cell>
                  <Table.Cell>{sellitem.status && t(`status.${sellitem.status}`)}</Table.Cell>
                  {/* <Table.Cell>{sellitem.note}</Table.Cell> */}
                  <Table.Cell>{sellitem.arrivalDate}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
        <div className={styles.hotWrapper}>
          <Grid className={styles.grid}>
            <Grid.Row className={styles.headerPadding}>
              <Grid.Column width={8} className={styles.headerPadding}>
                <div>{business.weightAmountPolicy}</div>
                <br />
                <div>{business.weightPolicy}</div>
              </Grid.Column>
              <Grid.Column width={8} className={styles.headerPadding}>
                <div className={styles.noteHeader}>{t('common.sellitemsInvoice.paymentInfo')}:</div>
                <Table className={styles[locationQuery.v]}>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>#</Table.HeaderCell>
                      <Table.HeaderCell>
                        {t('common.sellitemsInvoice.columnSumRefNum')}
                      </Table.HeaderCell>
                      <Table.HeaderCell>
                        {t('common.sellitemsInvoice.columnSumTotal')}
                      </Table.HeaderCell>
                      <Table.HeaderCell>
                        {t('common.sellitemsInvoice.columnSumPayment')}
                      </Table.HeaderCell>
                      <Table.HeaderCell>
                        {t('common.sellitemsInvoice.columnSumNote')}
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {payments.map((payment, index) => (
                      <Table.Row key={payment.id + payment.index}>
                        <Table.Cell>{index + 1}</Table.Cell>
                        <Table.Cell>{payment.refNo}</Table.Cell>
                        <Table.Cell>{(payment.amount * 1).toLocaleString('en-GB')}</Table.Cell>
                        <Table.Cell>{payment.accountName}</Table.Cell>
                        <Table.Cell>{payment.name}</Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </Modal.Content>
    );

    return (
      <Modal open closeIcon size="large" centered={false} onClose={onClose}>
        {locationQuery.v === 'v' ? (
          <Modal.Header>
            {t('common.sellitemsInvoice.notiArrive')} ({t('common.sellitemsInvoice.arriveCode')}:
            {customer.code}-{format(new Date(), 'yyyyMMdd')})
          </Modal.Header>
        ) : (
          <Modal.Header>
            {t('common.sellitemsInvoice.notiInvoice')} ({t('common.sellitemsInvoice.orderCode')}:
            {customer.code}-{format(createdAt, 'yyyyMMdd')})
          </Modal.Header>
        )}
        {contentNode}
        <div className={styles.sellitemModalPrint}>
          <SellitemModalPrint
            ref={printTableRef}
            shiplines={sellitems}
            business={business}
            customer={customer}
            defaultAddress={defaultAddress}
            finalTotals={finalTotals}
            finalPayments={finalPayments}
            sellitems={sellitems}
            locationQuery={locationQuery}
            payments={payments}
          />
        </div>
        <Modal.Actions>
          <ReactToPrint
            trigger={() => <Button color={buttonColor} content={t('action.print')} />}
            content={() => printTableRef.current}
          />
          <Button color={buttonColor} content={t('action.close')} onClick={onClose} />
        </Modal.Actions>
      </Modal>
    );
  },
);

SellitemModal.propTypes = {
  business: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  finalTotals: PropTypes.number.isRequired,
  finalPayments: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  arrivalDate: PropTypes.instanceOf(Date),
  createdAt: PropTypes.instanceOf(Date).isRequired,
  timer: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  isSubscribed: PropTypes.bool.isRequired,
  isActionsFetching: PropTypes.bool.isRequired,
  isAllActionsFetched: PropTypes.bool.isRequired,
  customer: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  groupId: PropTypes.string.isRequired,
  businessId: PropTypes.string.isRequired,
  /* eslint-disable react/forbid-prop-types */
  sellitems: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  labels: PropTypes.array.isRequired,
  locationQuery: PropTypes.object.isRequired,
  payments: PropTypes.array.isRequired,
  attachments: PropTypes.array.isRequired,
  actions: PropTypes.array.isRequired,
  allBusinessesToCustomers: PropTypes.array.isRequired,
  allGroupMemberships: PropTypes.array.isRequired,
  allLabels: PropTypes.array.isRequired,
  /* eslint-enable react/forbid-prop-types */
  canEdit: PropTypes.bool.isRequired,
  canEditAllCommentActions: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  defaultAddress: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onMove: PropTypes.func.isRequired,
  onTransfer: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onUserAdd: PropTypes.func.isRequired,
  onUserRemove: PropTypes.func.isRequired,
  onGroupFetch: PropTypes.func.isRequired,
  onLabelAdd: PropTypes.func.isRequired,
  onLabelRemove: PropTypes.func.isRequired,
  onLabelCreate: PropTypes.func.isRequired,
  onLabelUpdate: PropTypes.func.isRequired,
  onLabelDelete: PropTypes.func.isRequired,
  onPaymentCreate: PropTypes.func.isRequired,
  onPaymentUpdate: PropTypes.func.isRequired,
  onPaymentDelete: PropTypes.func.isRequired,
  onAttachmentCreate: PropTypes.func.isRequired,
  onAttachmentUpdate: PropTypes.func.isRequired,
  onAttachmentDelete: PropTypes.func.isRequired,
  onActionsFetch: PropTypes.func.isRequired,
  onCommentActionCreate: PropTypes.func.isRequired,
  onCommentActionUpdate: PropTypes.func.isRequired,
  onCommentActionDelete: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

SellitemModal.defaultProps = {
  description: undefined,
  arrivalDate: new Date(),
  timer: undefined,
};

export default SellitemModal;
