/* eslint-disable import/prefer-default-export */
import { call, put, select } from 'redux-saga/effects';

import request from '../request';
import { currentGroupSelector, defaultGroupSelector } from '../../../selectors';
import { fetchDashboard } from '../../../actions';
import api from '../../../api';

export function* fetchDashboardService(startDate, endDate) {
  let currentGroup = yield select(currentGroupSelector);
  if (!currentGroup) currentGroup = yield select(defaultGroupSelector);

  if (currentGroup.id) {
    yield put(fetchDashboard(currentGroup.id));
    let groupData;
    let dashboard;
    try {
      ({ item: groupData, included: dashboard } = yield call(
        request,
        api.getDashboard,
        currentGroup.id,
        startDate,
        endDate,
      ));
    } catch (error) {
      yield put(fetchDashboard.failure(currentGroup.id, error));
      return;
    } // eslint-disable-line no-empty
    yield put(fetchDashboard.success(groupData, dashboard));
  }
}
