import EntryActionTypes from '../../constants/EntryActionTypes';

export const createSellitem = (customerId, data) => ({
  type: EntryActionTypes.SELLITEM_CREATE,
  payload: {
    customerId,
    data,
  },
});

export const handleSellitemCreate = (sellitem, product) => ({
  type: EntryActionTypes.SELLITEM_CREATE_HANDLE,
  payload: {
    sellitem,
    product,
  },
});

export const updateSellitem = (id, data) => ({
  type: EntryActionTypes.SELLITEM_UPDATE,
  payload: {
    id,
    data,
  },
});

export const updateCurrentSellitem = (data) => ({
  type: EntryActionTypes.CURRENT_SELLITEM_UPDATE,
  payload: {
    data,
  },
});

export const handleSellitemUpdate = (sellitem) => ({
  type: EntryActionTypes.SELLITEM_UPDATE_HANDLE,
  payload: {
    sellitem,
  },
});

export const moveSellitem = (id, customerId, index = 0) => ({
  type: EntryActionTypes.SELLITEM_MOVE,
  payload: {
    id,
    customerId,
    index,
  },
});

export const moveCurrentSellitem = (customerId, index = 0) => ({
  type: EntryActionTypes.CURRENT_SELLITEM_MOVE,
  payload: {
    customerId,
    index,
  },
});

export const transferSellitem = (id, groupId, customerId, index = 0) => ({
  type: EntryActionTypes.SELLITEM_TRANSFER,
  payload: {
    id,
    groupId,
    customerId,
    index,
  },
});

export const transferCurrentSellitem = (groupId, customerId, index = 0) => ({
  type: EntryActionTypes.CURRENT_SELLITEM_TRANSFER,
  payload: {
    groupId,
    customerId,
    index,
  },
});

export const deleteSellitem = (id) => ({
  type: EntryActionTypes.SELLITEM_DELETE,
  payload: {
    id,
  },
});

export const deleteCurrentSellitem = () => ({
  type: EntryActionTypes.CURRENT_SELLITEM_DELETE,
  payload: {},
});

export const handleSellitemDelete = (sellitem) => ({
  type: EntryActionTypes.SELLITEM_DELETE_HANDLE,
  payload: {
    sellitem,
  },
});
