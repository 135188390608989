import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';

import { Popup } from '../../lib/custom-ui';
import { withPopup } from '../../lib/popup';

import styles from './ProductInfosPopup.module.scss';

const BrandsStep = React.memo(({ item, allBrands, title, onBrandSelect, onBack, onClose }) => {
  const [t] = useTranslation();
  const brandOptions = allBrands.map((it) => ({ value: it.id, label: it.name, id: 0 }));
  const [selectedBrand, setSelectedBrand] = useState({
    value: item.id,
    label: item.name,
    id: item.id,
  });

  const handleCountryChange = useCallback(
    (selected) => {
      setSelectedBrand(selected);
      onBrandSelect(selected);
      onClose();
    },
    [onBrandSelect, setSelectedBrand, onClose],
  );

  return (
    <>
      <Popup.Header onBack={onBack}>{title}</Popup.Header>
      <Popup.Content className={styles.select}>
        <Select
          isClearable={false}
          placeholder={t('common.selectBrand')}
          noOptionsMessage={() => t('common.noOptionsMessage')}
          components={{ ClearIndicator: null }}
          value={selectedBrand}
          name="country"
          options={brandOptions}
          onChange={handleCountryChange}
        />
      </Popup.Content>
    </>
  );
});

BrandsStep.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  allBrands: PropTypes.arrayOf(PropTypes.any).isRequired,
  title: PropTypes.string,
  onBrandSelect: PropTypes.func.isRequired,
  onBack: PropTypes.func,
  onClose: PropTypes.func,
};

BrandsStep.defaultProps = {
  title: 'common.country',
  onBack: undefined,
  onClose: undefined,
};

export default withPopup(BrandsStep);
