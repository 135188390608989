import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { HotTable, HotColumn } from '@handsontable/react';

import styles from './WarehouseTable.module.scss';

const WarehouseTable = React.memo(({ stocks }) => {
  const [t] = useTranslation();

  const colHeaders = useMemo(
    () => [
      'id',
      t('common.dateOrder'),
      t('common.importOrExport'),
      t('common.country'),
      t('common.webcode'),
      t('common.product'),
      t('common.image'),
      t('common.color'),
      t('common.size'),
      t('common.code'),
      t('common.quantity'),
      t('common.webPrice'),
      t('common.exchangeRate'),
      t('common.sellPrice'),
      t('common.total'),
      t('common.quantity'),
      t('common.exchangeRate'),
      t('common.exchangeRate'),
      t('common.exchangeRate'),
      t('common.exchangeRate'),
      t('common.exchangeRate'),
      t('common.exchangeRate'),
    ],
    [t],
  );

  return (
    <div id="hot-app" className={styles.wrapper}>
      <HotTable
        data={stocks}
        colHeaders={colHeaders}
        hiddenColumns={{ columns: [0] }}
        height="100%"
        stretchH="all"
        autoColumnSize={false}
        manualColumnResize
        rowHeaders={false}
        rowHeights={43}
        enterMoves={{ col: 1, row: 0 }}
        dropdownMenu={['filter_by_value', 'filter_action_bar']}
        filters
        licenseKey="non-commercial-and-evaluation"
      >
        <HotColumn width={0} data="id" />
        <HotColumn width={90} data="id" />
        <HotColumn width={90} data="id" />
        <HotColumn width={90} data="id" />
        <HotColumn width={90} data="id" />
        <HotColumn width={90} data="id" />
        <HotColumn width={90} data="id" />
        <HotColumn width={90} data="id" />
        <HotColumn width={90} data="id" />
        <HotColumn width={90} data="id" />
        <HotColumn width={90} data="id" />
        <HotColumn width={90} data="id" />
        <HotColumn width={90} data="id" />
        <HotColumn width={90} data="id" />
      </HotTable>
    </div>
  );
});

WarehouseTable.propTypes = {
  stocks: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default WarehouseTable;
