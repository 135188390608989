/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import { Modal, Table } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import styles from './StocksPopup.module.scss';

const StocksProduct = React.memo(({ stocks, onClose }) => {
  const [t] = useTranslation();

  return (
    <Modal open closeIcon size="large" onClose={onClose} className={styles.stocksPopup}>
      <Modal.Header>
        {t('common.historyStocks', {
          context: 'title',
        })}
      </Modal.Header>
      <Modal.Content>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{t('common.dateAddStock')}</Table.HeaderCell>
              <Table.HeaderCell>{t('common.productName')}</Table.HeaderCell>
              <Table.HeaderCell>{t('common.users')}</Table.HeaderCell>
              <Table.HeaderCell>{t('common.quantity')}</Table.HeaderCell>
              <Table.HeaderCell>{t('common.webPrice')}</Table.HeaderCell>
              <Table.HeaderCell>{t('common.exchangeRate')}</Table.HeaderCell>
              <Table.HeaderCell>{t('common.note')}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {stocks.map((stock) => (
              <Table.Row key={stock.id}>
                <Table.Cell>
                  {t('format:longDateTime', {
                    postProcess: 'formatDate',
                    value: new Date(stock.createdAt),
                  })}
                </Table.Cell>
                <Table.Cell>{stock.product?.name}</Table.Cell>
                <Table.Cell>{stock.creator}</Table.Cell>
                <Table.Cell>{stock.quantity}</Table.Cell>
                <Table.Cell>{(stock.webPrice * 1).toLocaleString('en-GB')}</Table.Cell>
                <Table.Cell>{(stock.exchangeRate * 1).toLocaleString('en-GB')}</Table.Cell>
                <Table.Cell>{stock.note}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Modal.Content>
    </Modal>
  );
});

StocksProduct.propTypes = {
  onClose: PropTypes.func.isRequired,
  stocks: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default StocksProduct;
