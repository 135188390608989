import { Model, attr, fk, oneToOne, many } from 'redux-orm';

import ActionTypes from '../constants/ActionTypes';
import Config from '../constants/Config';

export default class extends Model {
  static modelName = 'Product';

  static fields = {
    id: attr(),
    name: attr(),
    description: attr(),
    type: attr(),
    code: attr(),
    url: attr(),
    imageUrl: attr(),
    variation: attr(),
    color: attr(),
    size: attr(),
    unit: attr(),
    webCode: attr(),
    webPrice: attr(),
    webExtra: attr(),
    sellPrice: attr(),
    wholesalePrice: attr(),
    feeAmount: attr(),
    weight: attr(),
    weightAmount: attr(),
    point: attr(),
    isFetching: attr({
      getDefault: () => null,
    }),
    isWithWeightAmount: attr({
      getDefault: () => false,
    }),
    isSubscribed: attr(),
    isSellitemsFetching: attr({
      getDefault: () => false,
    }),
    isAllSellitemsFetched: attr({
      getDefault: () => false,
    }),
    groupId: fk({
      to: 'Group',
      as: 'group',
      relatedName: 'products',
    }),
    countryId: fk({
      to: 'Country',
      as: 'country',
      relatedName: 'products',
    }),
    brandId: fk({
      to: 'Brand',
      as: 'brand',
      relatedName: 'products',
    }),
    coverAttachmentId: oneToOne({
      to: 'Attachment',
      as: 'coverAttachment',
      relatedName: 'coveredProduct',
    }),
    categories: many('Category', 'products'),
    // categories: many({
    //   to: 'Category',
    //   through: 'ProductCategory',
    //   relatedName: 'products',
    // }),
  };

  static reducer({ type, payload }, Product) {
    switch (type) {
      case ActionTypes.LOCATION_CHANGE_HANDLE:
      case ActionTypes.CORE_INITIALIZE:
      case ActionTypes.BUSINESS_MANAGER_CREATE_HANDLE:
      case ActionTypes.GROUP_MEMBERSHIP_CREATE_HANDLE:
      case ActionTypes.PRODUCTS_FETCH__SUCCESS:
        if (payload.products) {
          payload.products.forEach((product) => {
            Product.upsert(product);
          });
        }

        break;
      case ActionTypes.SHIPMENTS_FETCH__SUCCESS:
      case ActionTypes.SHIPMENT_FETCH__SUCCESS:
        if (payload.products) {
          Product.all().delete();
          payload.products.forEach((product) => {
            Product.upsert(product);
          });
        }

        break;
      case ActionTypes.LOCATION_CHANGE_HANDLE__PRODUCT_FETCH:
      case ActionTypes.PRODUCT_FETCH:
        if (Product.idExists(payload.id)) {
          Product.withId(payload.id).update({
            isFetching: true,
          });
          Product.withId(payload.id).categories.clear();
        }

        if (payload.productCategories) {
          payload.productCategories.forEach(({ productId, categoryId }) => {
            Product.withId(productId).categories.add(categoryId);
          });
        }
        break;
      case ActionTypes.PRODUCT_FETCH__SUCCESS:
        Product.upsert({
          ...payload.product,
          isFetching: false,
        });

        if (payload.productCategories) {
          payload.productCategories.forEach(({ productId, categoryId }) => {
            Product.withId(productId).categories.add(categoryId);
          });
        }

        break;
      case ActionTypes.SOCKET_RECONNECT_HANDLE:
        Product.all().delete();

        if (payload.product) {
          Product.upsert(payload.product);
        }
        if (payload.products) {
          payload.products.forEach((product) => {
            Product.upsert(product);
            Product.withId(product.id).categories.clear();
          });
        }

        if (payload.productCategories) {
          payload.productCategories.forEach(({ productId, categoryId }) => {
            Product.withId(productId).categories.add(categoryId);
          });
        }

        break;
      case ActionTypes.SELLITEMS_FETCH__SUCCESS:
      case ActionTypes.CUSTOMER_FETCH__SUCCESS:
      case ActionTypes.GROUP_FETCH__SUCCESS:
        payload.products.forEach((product) => {
          Product.upsert(product);
          Product.withId(product.id).categories.clear();
        });

        if (payload.productCategories) {
          payload.productCategories.forEach(({ productId, categoryId }) => {
            Product.withId(productId).categories.add(categoryId);
          });
        }

        break;
      case ActionTypes.PRODUCT_CREATE:
      case ActionTypes.PRODUCT_CREATE_HANDLE:
      case ActionTypes.PRODUCT_UPDATE__SUCCESS:
      case ActionTypes.PRODUCT_UPDATE_HANDLE:
        Product.upsert(payload.product);

        if (payload.productCategories) {
          Product.withId(payload.product.id).categories.clear();
          payload.productCategories.forEach(({ productId, categoryId }) => {
            Product.withId(productId).categories.add(categoryId);
          });
        }

        break;
      case ActionTypes.PRODUCT_CREATE__SUCCESS:
        Product.withId(payload.localId).delete();
        Product.upsert(payload.product);

        break;
      case ActionTypes.PRODUCT_UPDATE:
        Product.withId(payload.id).update(payload.data);

        break;
      case ActionTypes.PRODUCT_DELETE:
        Product.withId(payload.id).delete();

        break;
      case ActionTypes.PRODUCT_DELETE__SUCCESS:
      case ActionTypes.PRODUCT_DELETE_HANDLE: {
        const productModel = Product.withId(payload.product.id);

        if (productModel) {
          productModel.deleteWithRelated();
        }

        break;
      }
      case ActionTypes.SELLITEM_CREATE_HANDLE:
        if (!Product.exists({ id: payload.product.id }) && payload.product) {
          Product.upsert(payload.product);
        }
        break;
      case ActionTypes.ACTIONS_FETCH:
        Product.withId(payload.productId).update({
          isSellitemsFetching: true,
        });

        break;
      case ActionTypes.ACTIONS_FETCH__SUCCESS:
        Product.withId(payload.productId).update({
          isSellitemsFetching: false,
          isAllActionsFetched: payload.actions.length < Config.PRODUCTS_LIMIT,
        });

        break;
      case ActionTypes.NOTIFICATION_CREATE_HANDLE:
        payload.products.forEach((product) => {
          Product.upsert(product);
        });

        break;
      default:
    }
  }

  static getOrderedQuerySet() {
    return this.orderBy('createdAt', false);
  }

  getOrderedAttachmentsQuerySet() {
    return this.attachments.orderBy('createdAt', false);
  }

  getOrderedSellitemsQuerySet() {
    return this.sellitems.orderBy('createdAt', false);
  }

  getOrderedStocksQuerySet() {
    return this.stocks.orderBy('createdAt', false);
  }

  getOrderedFilteredSellitemsModelArray() {
    const sellitemModels = this.getOrderedSellitemsQuerySet().toModelArray();

    return sellitemModels;
  }

  deleteRelated() {
    this.sellitems.delete();
    this.attachments.delete();
  }

  deleteWithRelated() {
    this.deleteRelated();
    this.delete();
  }
}
