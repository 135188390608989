import { all, takeEvery } from 'redux-saga/effects';

import {
  createCustomerInCurrentGroupService,
  deleteCustomerService,
  handleCustomerCreateService,
  handleCustomerDeleteService,
  handleCustomerUpdateService,
  moveCustomerService,
  updateCustomerService,
} from '../services';
import EntryActionTypes from '../../../constants/EntryActionTypes';

export default function* customerWatchers() {
  yield all([
    takeEvery(EntryActionTypes.CUSTOMER_IN_CURRENT_GROUP_CREATE, ({ payload: { data } }) =>
      createCustomerInCurrentGroupService(data),
    ),
    takeEvery(EntryActionTypes.CUSTOMER_CREATE_HANDLE, ({ payload: { customer } }) =>
      handleCustomerCreateService(customer),
    ),
    takeEvery(EntryActionTypes.CUSTOMER_UPDATE, ({ payload: { id, data } }) =>
      updateCustomerService(id, data),
    ),
    takeEvery(EntryActionTypes.CUSTOMER_UPDATE_HANDLE, ({ payload: { customer } }) =>
      handleCustomerUpdateService(customer),
    ),
    takeEvery(EntryActionTypes.CUSTOMER_MOVE, ({ payload: { id, index } }) =>
      moveCustomerService(id, index),
    ),
    takeEvery(EntryActionTypes.CUSTOMER_DELETE, ({ payload: { id } }) => deleteCustomerService(id)),
    takeEvery(EntryActionTypes.CUSTOMER_DELETE_HANDLE, ({ payload: { customer } }) =>
      handleCustomerDeleteService(customer),
    ),
  ]);
}
