import { call, put, select } from 'redux-saga/effects';

import request from '../request';
import {
  groupByIdSelector,
  lastSellitemIdByGroupIdSelector,
  pathSelector,
} from '../../../selectors';
import {
  fetchSellitems,
  changeOrderDateToGroupFilter,
  changeArrivalDateToGroupFilter,
  changeWebOrderNoToGroupFilter,
  updateSellitems,
  handleSellitemsUpdate,
} from '../../../actions';
import api from '../../../api';

export function* fetchSellitemsService(groupId, data) {
  const lastId = yield select(lastSellitemIdByGroupIdSelector, groupId);
  const { filterOrderDate, filterArrivalDate, filterStatuses, filterWebOrderNo } = yield select(
    groupByIdSelector,
    groupId,
  );

  const filters = {
    beforeId: lastId,
    data,
    filterOrderDate,
    filterArrivalDate,
    filterStatuses,
    filterWebOrderNo: filterWebOrderNo || undefined,
  };

  yield put(fetchSellitems(groupId, filters));

  let sellitems;
  let customers;
  let addresses;
  let sellitemMemberships;
  let sellitemLabels;
  let products;
  let categories;
  let productCategories;
  let payments;
  let attachments;

  try {
    ({
      items: sellitems,
      included: {
        customers,
        addresses,
        sellitemMemberships,
        sellitemLabels,
        products,
        categories,
        productCategories,
        payments,
        attachments,
      },
    } = yield call(request, api.getSellitems, groupId, filters));
  } catch (error) {
    yield put(fetchSellitems.failure(groupId, error));
    return;
  }

  yield put(
    fetchSellitems.success(
      groupId,
      sellitems,
      customers,
      addresses,
      sellitemMemberships,
      sellitemLabels,
      products,
      categories,
      productCategories,
      payments,
      attachments,
    ),
  );
}

export function* fetchSellitemsInCurrentGroupService(data) {
  const { groupId } = yield select(pathSelector);

  yield call(fetchSellitemsService, groupId, data);
}

export function* changeOrderDateToGroupFilterService(orderDate, groupId) {
  yield put(changeOrderDateToGroupFilter(orderDate, groupId));

  yield call(fetchSellitemsService, groupId);
}

export function* changeOrderDateToFilterInCurrentGroupService(orderDate) {
  const { groupId } = yield select(pathSelector);

  yield call(changeOrderDateToGroupFilterService, orderDate, groupId);
}

export function* changeArrivalDateToGroupFilterService(arrivalDate, groupId) {
  yield put(changeArrivalDateToGroupFilter(arrivalDate, groupId));

  yield call(fetchSellitemsService, groupId);
}

export function* changeArrivalDateToFilterInCurrentGroupService(arrivalDate) {
  const { groupId } = yield select(pathSelector);

  yield call(changeArrivalDateToGroupFilterService, arrivalDate, groupId);
}

export function* changeWebOrderNoToGroupFilterService(webOrderNo, groupId) {
  yield put(changeWebOrderNoToGroupFilter(webOrderNo, groupId));

  yield call(fetchSellitemsService, groupId);
}

export function* changeWebOrderNoToFilterInCurrentGroupService(webOrderNo) {
  const { groupId } = yield select(pathSelector);

  yield call(changeWebOrderNoToGroupFilterService, webOrderNo, groupId);
}

export function* updateSellitemsService(groupId, data) {
  yield put(updateSellitems(groupId, data));

  let sellitems;
  try {
    ({ items: sellitems } = yield call(request, api.updateSellitems, groupId, data));
  } catch (error) {
    yield put(updateSellitems.failure(groupId, error));
    return;
  }

  yield put(updateSellitems.success(sellitems));
}

export function* handleSellitemsUpdateService(sellitem) {
  yield put(handleSellitemsUpdate(sellitem));
}
