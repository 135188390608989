import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import styles from './Dashboard.module.scss';

const ChartFilter = ({ time, chartType, filterTime, filterChartType }) => {
  const [t] = useTranslation();

  const handChangeFilterDate = useCallback(
    (e, type) => {
      filterTime(type);
    },
    [filterTime],
  );

  const handChangeFilterType = useCallback(
    (e, type) => {
      filterChartType(type);
    },
    [filterChartType],
  );

  return (
    <div className={styles.filterChartWrapper}>
      <Button.Group className={styles.filterGroupTime}>
        <Button
          className={styles.buttonItem}
          icon
          active={time === 'date'}
          onClick={(e) => handChangeFilterDate(e, 'date')}
        >
          {t('common.date')}
        </Button>
        {/* <Button size="mini" icon active={time === 'week'} onClick={(e) => handChangeFilterDate(e, 'week')}>
          {t('common.week')}
        </Button> */}
        <Button
          className={styles.buttonItem}
          icon
          active={time === 'month'}
          onClick={(e) => handChangeFilterDate(e, 'month')}
        >
          {t('common.month')}
        </Button>
      </Button.Group>{' '}
      <Button.Group className={styles.filterGroupType}>
        <Button
          className={styles.buttonItem}
          icon
          active={chartType === 'bar'}
          onClick={(e) => handChangeFilterType(e, 'bar')}
        >
          <Icon name="chart bar" />
        </Button>
        <Button
          className={styles.buttonItem}
          icon
          active={chartType === 'line'}
          onClick={(e) => handChangeFilterType(e, 'line')}
        >
          <Icon name="chart line" />
        </Button>
      </Button.Group>
    </div>
  );
};

ChartFilter.propTypes = {
  time: PropTypes.string.isRequired,
  chartType: PropTypes.string.isRequired,
  filterTime: PropTypes.func.isRequired,
  filterChartType: PropTypes.func.isRequired,
};

export default ChartFilter;
