import { all, takeEvery } from 'redux-saga/effects';

import {
  createShipmentInCurrentGroupService,
  handleShipmentCreateService,
  handleShipmentUpdateService,
  updateShipmentService,
} from '../services';
import EntryActionTypes from '../../../constants/EntryActionTypes';

export default function* shipmentWatchers() {
  yield all([
    takeEvery(EntryActionTypes.SHIPMENT_CREATE, ({ payload: { data } }) =>
      createShipmentInCurrentGroupService(data),
    ),
    takeEvery(EntryActionTypes.SHIPMENT_CREATE_HANDLE, ({ payload: { shipment } }) =>
      handleShipmentCreateService(shipment),
    ),
    takeEvery(EntryActionTypes.SHIPMENT_UPDATE, ({ payload: { id, data } }) =>
      updateShipmentService(id, data),
    ),
    takeEvery(EntryActionTypes.SHIPMENT_UPDATE_HANDLE, ({ payload: { shipment } }) =>
      handleShipmentUpdateService(shipment),
    ),
  ]);
}
