import { call, put } from 'redux-saga/effects';

import request from '../request';
import {
  createPayment,
  deletePayment,
  handlePaymentCreate,
  handlePaymentDelete,
  handlePaymentUpdate,
  updatePayment,
} from '../../../actions';
import api from '../../../api';
import { createLocalId } from '../../../utils/local-id';

export function* createDepositService(customerId, data) {
  const localId = yield call(createLocalId);

  yield put(
    createPayment({
      ...data,
      customerId,
      id: localId,
    }),
  );

  let payment;
  try {
    ({ item: payment } = yield call(request, api.createDeposit, customerId, {
      ...data,
      // type: 'deposit',
    }));
  } catch (error) {
    yield put(createPayment.failure(localId, error));
    return;
  }

  yield put(createPayment.success(localId, payment));
}

export function* createPaymentService(customerId, data) {
  const localId = yield call(createLocalId);

  yield put(
    createPayment({
      ...data,
      customerId,
      id: localId,
    }),
  );

  let payment;
  try {
    ({ item: payment } = yield call(request, api.createPayment, customerId, data));
  } catch (error) {
    yield put(createPayment.failure(localId, error));
    return;
  }

  yield put(createPayment.success(localId, payment));
}

export function* handlePaymentCreateService(payment) {
  yield put(handlePaymentCreate(payment));
}

export function* updatePaymentService(id, data) {
  yield put(updatePayment(id, data));

  let payment;
  try {
    ({ item: payment } = yield call(request, api.updatePayment, id, data));
  } catch (error) {
    yield put(updatePayment.failure(id, error));
    return;
  }

  yield put(updatePayment.success(payment));
}

export function* handlePaymentUpdateService(payment) {
  yield put(handlePaymentUpdate(payment));
}

export function* deletePaymentService(id) {
  yield put(deletePayment(id));

  let payment;
  try {
    ({ item: payment } = yield call(request, api.deletePayment, id));
  } catch (error) {
    yield put(deletePayment.failure(id, error));
    return;
  }

  yield put(deletePayment.success(payment));
}

export function* handlePaymentDeleteService(payment) {
  yield put(handlePaymentDelete(payment));
}
