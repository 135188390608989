import EntryActionTypes from '../../constants/EntryActionTypes';

export const createStock = (data) => ({
  type: EntryActionTypes.STOCK_CREATE,
  payload: {
    data,
  },
});

export const handleStockCreate = (stock) => ({
  type: EntryActionTypes.STOCK_CREATE_HANDLE,
  payload: {
    stock,
  },
});
