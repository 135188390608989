import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import Background from '../Background';
import HeaderContainer from '../../containers/HeaderContainer';

import WarehouseActions from './WarehouseActions';
import WarehouseTable from './WarehouseTable';

import styles from './Warehouse.module.scss';

const Warehouse = ({ currentBusiness, currentGroup }) => {
  const onChangeFilter = useCallback((type, value) => {
    console.log(type, value);
  }, []);

  const onChangeSearchKey = useCallback((value) => {
    console.log(value);
  }, []);

  const products = [
    { id: 1, name: 'Test 1' },
    { id: 2, name: 'Test 2' },
    { id: 3, name: 'Test 3' },
  ];

  return (
    <>
      {currentBusiness && currentBusiness.background && (
        <Background
          type={currentBusiness.background.type}
          name={currentBusiness.background.name}
          imageUrl={currentBusiness.backgroundImage && currentBusiness.backgroundImage.url}
        />
      )}
      {currentGroup && !currentGroup.isFetching && (
        <>
          <HeaderContainer />
          <WarehouseActions
            products={products}
            onChangeFilter={onChangeFilter}
            onChangeSearchKey={onChangeSearchKey}
          />
          <div className={styles.dashboardWrapper}>
            <Grid>
              <Grid.Row>
                <Grid.Column width={4}>
                  <WarehouseTable stocks={[]} />
                </Grid.Column>
                <Grid.Column width={12} className={styles.dashboardContent}>
                  <WarehouseTable stocks={[]} />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        </>
      )}
    </>
  );
};

Warehouse.propTypes = {
  currentGroup: PropTypes.objectOf(PropTypes.any),
  currentBusiness: PropTypes.objectOf(PropTypes.any),
};

Warehouse.defaultProps = {
  currentGroup: undefined,
  currentBusiness: undefined,
};

export default Warehouse;
