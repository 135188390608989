import XLSX from 'sheetjs-style';
import { format } from 'date-fns';
// eslint-disable-next-line import/prefer-default-export
export const exportXlsx = (sellitems, colHeaders) => {
  const s = {
    // font: { bold: false },
    border: {
      top: { style: 'thin', color: { auto: 1 } },
      right: { style: 'thin', color: { auto: 1 } },
      bottom: { style: 'thin', color: { auto: 1 } },
      left: { style: 'thin', color: { auto: 1 } },
    },
  };
  const hs = {
    ...s,
    // fill: { patternType: 'solid', bgColor: { indexed: 64 } },
    alignment: { horizontal: 'center', vertical: 'top', wrapText: true },
    font: {
      // name: 'Roboto',
      // sz: 24,
      bold: true,
      // color: { rgb: 'FFFFAA00' },
    },
  };
  const h1 = {
    alignment: { horizontal: 'center', vertical: 'top', wrapText: true },
    font: {
      sz: 12,
      bold: true,
    },
  };
  // const h2 = {
  //   ...s,
  //   alignment: { horizontal: 'center', vertical: 'center' },
  //   font: {
  //     // name: 'Roboto',
  //     sz: 14,
  //     bold: true,
  //     // color: { rgb: 'FFFFAA00' },
  //   },
  // };

  const dataHeaders = [
    {
      STT: {
        v: `KHANHBUI.VN - ORDER AUTHENTIC`,
        s: h1,
      },
      dateOrder: { v: '', s: h1 },
      saler: { v: '', s: h1 },
      customerCode: { v: '', s: h1 },
      customerName: { v: '', s: h1 },
      status: { v: '', s: h1 },
      arrivalDate: { v: '', s: h1 },
      country: { v: '', s: h1 },
      web: { v: '', s: h1 },
      product: { v: '', s: h1 },
      url: { v: '', s: h1 },
      color: { v: '', s: h1 },
      size: { v: '', s: h1 },
      code: { v: '', s: h1 },
      quantity: { v: '', s: h1 },
      webPrice: { v: '', s: h1 },
      webExtra: { v: '', s: h1 },
      sellPrice: { v: '', s: h1 },
      subTotal: { v: '', s: h1 },
      webOrderNo: { v: '', s: h1 },
      feeAmount: { v: '', s: h1 },
      diff: { v: '', s: h1 },
      weightAmount: { v: '', s: h1 },
      finalTotal: { v: '', s: h1 },
      payment: { v: '', s: h1 },
      remainTotal: { v: '', s: h1 },
    },
    {
      STT: { v: colHeaders[0], s: hs },
      dateOrder: { v: colHeaders[1], s: hs },
      saler: { v: colHeaders[2], s: hs },
      customerCode: { v: colHeaders[3], s: hs },
      customerName: { v: colHeaders[4], s: hs },
      status: { v: colHeaders[5], s: hs },
      arrivalDate: { v: colHeaders[6], s: hs },
      country: { v: colHeaders[7], s: hs },
      web: { v: colHeaders[8], s: hs },
      product: { v: colHeaders[9], s: hs },
      url: { v: colHeaders[10], s: hs },
      color: { v: colHeaders[11], s: hs },
      size: { v: colHeaders[12], s: hs },
      code: { v: colHeaders[13], s: hs },
      quantity: { v: colHeaders[14], s: hs },
      webPrice: { v: colHeaders[15], s: hs },
      webExtra: { v: colHeaders[16], s: hs },
      sellPrice: { v: colHeaders[17], s: hs },
      subTotal: { v: colHeaders[18], s: hs },
      webOrderNo: { v: colHeaders[19], s: hs },
      feeAmount: { v: colHeaders[20], s: hs },
      diff: { v: colHeaders[21], s: hs },
      weightAmount: { v: colHeaders[22], s: hs },
      finalTotal: { v: colHeaders[23], s: hs },
      payment: { v: colHeaders[24], s: hs },
      remainTotal: { v: colHeaders[25], s: hs },
    },
  ];

  let r = dataHeaders.length;
  const data = sellitems.map(
    (
      {
        createdAt,
        member,
        customerCode,
        customerName,
        status,
        arrivalDate,
        country: { name: countryName },
        brandName,
        product,
        color,
        size,
        webCode,
        quantity,
        webPrice,
        webExtra,
        sellPrice,
        // subtotal,
        webOrderNo,
        feeAmount,
        diff,
        weightAmount,
        finalTotal,
        payment,
        remainTotal,
      },
      index,
    ) => {
      r += 1;
      return {
        STT: { t: 'n', v: index + 1, z: '#,##0', s },
        dateOrder: { v: format(new Date(createdAt) || '', 'dd/MM/yyyy'), s },
        saler: { v: member || '', s },
        customerCode: { v: customerCode || '', s },
        customerName: { v: customerName || '', s },
        status: { v: status || '', s },
        arrivalDate: { v: arrivalDate || '', s },
        country: { v: countryName || '', s },
        web: { v: brandName || '', s },
        product: { v: product.name || '', s },
        url: { v: product.url || '', s },
        color: { v: color || '', s },
        size: { v: size || '', s },
        code: { v: webCode || '', s },
        quantity: { t: 'n', v: quantity || '', z: '#,##0', s },
        webPrice: { t: 'n', v: webPrice || '', z: '#,##0.00', s },
        webExtra: { t: 'n', v: webExtra || '', z: '#,##0', s },
        sellPrice: { t: 'n', v: sellPrice || '', z: '#,##0', s },
        // subTotal: { v: subtotal || '', s },
        subTotal: { t: 'n', f: `=O${r}*R${r}`, z: '#,##0', s },
        webOrderNo: { v: webOrderNo || '', s },
        feeAmount: { t: 'n', v: feeAmount || '', z: '#,##0', s },
        diff: { t: 'n', v: diff || '', z: '#,##0', s },
        weightAmount: { t: 'n', v: weightAmount || '', z: '#,##0', s },
        finalTotal: { t: 'n', v: finalTotal || '', z: '#,##0', s },
        payment: { t: 'n', v: payment || '', z: '#,##0', s },
        remainTotal: { t: 'n', v: remainTotal || '', z: '#,##0', s },
      };
    },
  );

  const ws = XLSX.utils.json_to_sheet(dataHeaders.concat(data), { skipHeader: true });
  const wscols = [
    { wch: 4 }, // stt
    { wch: 12 }, // dateOrder
    { wch: 14 }, // saler
    { wch: 8 }, // customerCode
    { wch: 14 }, // customerName
    { wch: 10 }, // status
    { wch: 12 }, // arrivalDate
    { wch: 5 }, // country
    { wch: 10 }, // web
    { wch: 20 }, // product
    { wch: 12 }, // url
    { wch: 14 }, // color
    { wch: 10 }, // size
    { wch: 14 }, // code
    { wch: 4 }, // quantity
    { wch: 14 }, // webPrice
    { wch: 10 }, // webExtra
    { wch: 10 }, // sellPrice
    { wch: 10 }, // subTotal
    { wch: 20 }, // webOrderNo
    { wch: 10 }, // feeAmount
    { wch: 10 }, // diff
    { wch: 10 }, // weightAmount
    { wch: 10 }, // finalTotal
    { wch: 10 }, // payment
    { wch: 10 }, // remainTotal
  ];
  ws['!cols'] = wscols;
  ws['!rows'] = [{ hpx: 36 }, { hpx: 36 }];

  const merge = [
    { s: { r: 0, c: 0 }, e: { r: 0, c: 25 } },
    // { s: { r: 1, c: 0 }, e: { r: 1, c: 25 } },
  ];
  ws['!merges'] = merge;

  const wb = { Sheets: { 'Sheet 1': ws }, SheetNames: ['Sheet 1'] };
  const excelBuffer = XLSX.write(wb, {
    bookType: 'xlsx',
    cellStyles: true,
    skipHeader: true,
    type: 'array',
  });
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const blob = new Blob([excelBuffer], { type: fileType });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = `WowOrder-${format(new Date(), 'ddMMyyyy')}.xlsx`;
  link.click();
};
