import React, { useCallback, useMemo, useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Form, Message, Modal, Image, Table, Grid } from 'semantic-ui-react';
import SimpleMDE from 'react-simplemde-editor';
import Select from 'react-select';
import { Input, FilePicker } from '../../lib/custom-ui';
import { usePrevious } from '../../lib/hooks';

import { useForm } from '../../hooks';

import styles from './ProductAddPopup.module.scss';

const createMessage = (error) => {
  if (!error) {
    return error;
  }
  let m;
  switch (error) {
    default:
      if (error.problems && error.problems.length > 0) {
        return {
          type: 'error',
          content: error.problems.join(', '),
          title: 'Lỗi - liên hệ tipici',
        };
      }
      m = error.match(/`([a-zA-Z]+)`/);
      if (m) {
        return {
          type: 'warning',
          content: `common.${m[1]}`,
          title: 'Lỗi',
        };
      }
      return {
        type: 'warning',
        content: 'common.unknownError',
        title: '',
      };
  }
};

const ProductAddPopup = React.memo(
  ({
    countries,
    brands,
    categories,
    defaultData,
    attachmentImgur,
    isSubmitting,
    error,
    onCreate,
    onMessageDismiss,
    onClose,
    onAttachmentCreate,
  }) => {
    const [t] = useTranslation();
    const wasSubmitting = usePrevious(isSubmitting);

    const [data, handleFieldChange] = useForm(() => ({
      ...defaultData,
      imageOption: 'url',
      webCode: undefined,
      webExtra: undefined,
    }));

    const message = useMemo(() => createMessage(error), [error]);

    const nameField = useRef(null);
    const urlField = useRef(null);

    const [selectedCategory, setSelectedCategory] = useState();

    const [nameError, setNameError] = useState(false);
    const [countryError, setCountryError] = useState(false);
    const [brandError, setBrandError] = useState(false);
    const [categoryError, setCategoryError] = useState(false);
    const [urlError, setUrlError] = useState(false);

    const countryOptions = [];
    countries.forEach((item) => {
      countryOptions.push({
        key: item.id,
        value: item.id,
        text: item.name,
      });
    });

    const brandOptions = [];
    brands.forEach((item) => {
      brandOptions.push({
        key: item.id,
        value: item.id,
        text: item.name,
      });
    });
    const categoryOptions = [];
    categories.forEach((item) => {
      categoryOptions.push({
        key: item.id,
        value: item.id,
        text: item.name,
        label: item.name,
      });
    });

    const typeOptions = [
      { key: 'order', value: 'Order', text: 'Order' },
      { key: 'stock', value: 'Sẵn', text: 'Sẵn' },
    ];

    const imageOptions = [
      {
        key: 1,
        value: 'url',
        text: t('common.url'),
      },
      {
        key: 2,
        value: 'uploadImgur',
        text: t('common.uploadImgur'),
      },
      {
        key: 3,
        value: 'uploadHost',
        text: t('common.uploadHost'),
      },
    ];

    const [fields, setFields] = useState([]);

    const handleAdd = () => {
      const values = [...fields];
      values.push({
        color: '',
        size: '',
        quantity: 0,
        webPrice: 0,
        feeAmount: 0,
        sellPrice: 0,
        wholesalePrice: 0,
        imageUrl: '',
      });
      setFields(values);
    };

    const handleRemove = (i) => {
      const values = [...fields];
      values.splice(i, 1);
      setFields(values);
    };

    const handleChange = (i, event) => {
      const values = [...fields];
      values[i][event.target.name] = event.target.value;
      setFields(values);
      console.log('values', values);
    };

    const [filePath, setFilePath] = useState({});
    const handleFileSelect = (file, type) => {
      if (type === 'host' && file.size > 1024000) {
        alert(t('common.pleaseUploadOtherImage'));
        return;
      }
      const reader = new FileReader();
      reader.onload = (e) => {
        const uuid = URL.createObjectURL(file);
        setFilePath({ uuid, file: e.target.result, fileName: file.name });
        if (type === 'imgur' && e.target.result) {
          const images = e.target.result.split(';base64,');
          if (images[1]) {
            onAttachmentCreate(images[1], uuid);
          }
        }
      };

      reader.readAsDataURL(file);
    };

    const handleCategoryChange = (selected) => {
      setSelectedCategory(selected);
    };

    const handleSubmit = useCallback(() => {
      const imageFile = attachmentImgur.find((attachment) => attachment.uuid === filePath.uuid);
      let imageUrl;
      let fileName;
      if (imageFile) {
        imageUrl = imageFile.link;
      } else if (filePath && filePath.file) {
        imageUrl = filePath.file;
        fileName = filePath.fileName;
      }

      let haveColor = false;
      let haveSize = false;
      const keyColors = [];
      const keySizes = [];
      const groupVariation = fields.reduce((variations, variationItem) => {
        const variationList = variations;
        if (!variationList.colors) variationList.colors = [];
        if (!variationList.sizes) variationList.sizes = [];
        if (variationItem.color && variationItem.color !== '') {
          haveColor = true;
          if (!variationList.colors[variationItem.color.toLowerCase()]) {
            keyColors.push(variationItem.color.toLowerCase());
            variationList.colors[variationItem.color.toLowerCase()] = {
              colorId: variationItem.color.toLowerCase(),
              name: variationItem.color,
              colorCode: variationItem.color.toLowerCase(),
              imageUrl: variationItem.imageUrl,
              quantity: variationItem.quantity,
              webPrice: variationItem.webPrice,
              sellPrice: variationItem.sellPrice,
              wholesalePrice: variationItem.wholesalePrice,
              feeAmount: variationItem.feeAmount,
            };
          }
          if (!variationList.colors[variationItem.color.toLowerCase()].sizes)
            variationList.colors[variationItem.color.toLowerCase()].sizes = [];
          variationList.colors[variationItem.color.toLowerCase()].sizes.push({
            size: variationItem.size,
            name: variationItem.size,
            imageUrl: variationItem.imageUrl,
            quantity: variationItem.quantity,
            webPrice: variationItem.webPrice,
            sellPrice: variationItem.sellPrice,
            wholesalePrice: variationItem.wholesalePrice,
            feeAmount: variationItem.feeAmount,
          });
        } else if (variationItem.size && variationItem.size !== '') {
          haveSize = true;
          if (!variationList.sizes[variationItem.size.toLowerCase()]) {
            keySizes.push(variationItem.size.toLowerCase());
            variationList.sizes[variationItem.size.toLowerCase()] = {};
          }
          variationList.sizes[variationItem.size.toLowerCase()] = {
            size: variationItem.size,
            name: variationItem.size,
          };
        }
        return variationList;
      }, []);

      const variationColors = [];
      keyColors.forEach((color) => {
        variationColors.push({ ...groupVariation.colors[color], colorId: color });
      });

      const variationSizes = [];
      keySizes.forEach((size) => {
        variationSizes.push({ ...groupVariation.sizes[size] });
      });

      const cleanData = {
        ...data,
        name: data.name.trim(),
        code: data.name.trim() || undefined,
        url: data.url.trim(),
        type: data.type.trim(),
        imageUrl: data.imageOption === 'url' ? data.imageUrl : imageUrl,
        fileName,
        color: fields[0] && fields[0].color ? fields[0].color : null,
        size: fields[0] && fields[0].size ? fields[0].size : null,
        quantity: fields[0] && fields[0].quantity ? fields[0].quantity : 0,
        webPrice: fields[0] && fields[0].webPrice ? fields[0].webPrice : 0,
        feeAmount: fields[0] && fields[0].feeAmount ? fields[0].feeAmount : 0,
        sellPrice: fields[0] && fields[0].sellPrice ? fields[0].sellPrice : 0,
        wholesalePrice: fields[0] && fields[0].wholesalePrice ? fields[0].wholesalePrice : 0,
        webExtra: data.webExtra || undefined,
        weight: data.weight || undefined,
        point: data.point || undefined,
        categoryIds: selectedCategory ? [selectedCategory?.value] : [],
      };

      if (fields.length > 1) {
        if (haveColor && haveSize)
          cleanData.variation = {
            colors: variationColors,
            sizes: variationSizes,
          };
        else if (haveColor) cleanData.variation = { colors: variationColors };
        else if (haveSize) cleanData.variation = { sizes: variationSizes };
      }

      let isError = false;

      if (!cleanData.name) {
        nameField.current.select();
        setNameError(true);
        isError = true;
      }

      if (!cleanData.countryId) {
        setCountryError(true);
        isError = true;
      }

      if (!cleanData.brandId) {
        setBrandError(true);
        isError = true;
      }

      if (!cleanData.url) {
        urlField.current.select();
        setUrlError(true);
        isError = true;
      }

      if (isError) return;
      onCreate(cleanData);
    }, [attachmentImgur, filePath, fields, data, selectedCategory, onCreate]);

    useEffect(() => {
      const values = [];
      values.push({
        color: '',
        size: '',
        quantity: 0,
        webPrice: 0,
        feeAmount: 0,
        sellPrice: 0,
        wholesalePrice: 0,
        imageOption: 'url',
        uuid: '',
        type: 'Order',
        code: '',
      });
      setFields(values);
    }, [setFields]);

    useEffect(() => {
      if (wasSubmitting && !isSubmitting) {
        if (!error) {
          onClose();
        }
      }
    }, [wasSubmitting, error, isSubmitting, onClose]);

    return (
      <Modal open closeIcon size="large" onClose={onClose} className={styles.addProductPopup}>
        <Modal.Header>
          {t('common.addProduct', {
            context: 'title',
          })}
        </Modal.Header>
        <Modal.Content>
          {message && (
            <Message
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...{
                [message.type]: true,
              }}
              header={message.title}
              visible
              content={
                message.content.indexOf('common.') >= 0 ? t(message.content) : message.content
              }
              onDismiss={onMessageDismiss}
            />
          )}
          <Form onSubmit={handleSubmit}>
            <Grid>
              <Grid.Row>
                <Grid.Column width={12}>
                  <Form.Group>
                    <Form.Select
                      name="type"
                      className={styles.field}
                      placeholder={t('common.stocks')}
                      width={2}
                      fluid
                      label={t('common.stocks')}
                      selection
                      value={data.type}
                      options={typeOptions}
                      onChange={handleFieldChange}
                    />
                    <Form.Field
                      required
                      width={14}
                      error={urlError}
                      onFocus={() => setUrlError(false)}
                    >
                      <label htmlFor="url">{t('common.url')}</label>
                      <Input
                        fluid
                        name="url"
                        ref={urlField}
                        value={data.url}
                        readOnly={isSubmitting}
                        className={styles.field}
                        onChange={handleFieldChange}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group>
                    <Form.Field
                      required
                      width="8"
                      error={nameError}
                      onFocus={() => setNameError(false)}
                    >
                      <label htmlFor="name">{t('common.name')}</label>
                      <Input
                        fluid
                        ref={nameField}
                        name="name"
                        value={data.name}
                        readOnly={isSubmitting}
                        className={styles.field}
                        onChange={handleFieldChange}
                      />
                    </Form.Field>
                    <Form.Field
                      required
                      width="4"
                      error={countryError}
                      onFocus={() => setCountryError(false)}
                    >
                      <label htmlFor="country">{t('common.country')}</label>
                      <Form.Select
                        name="countryId"
                        className={styles.field}
                        placeholder={t('common.selectCountry')}
                        search
                        fluid
                        selection
                        value={data.countryId}
                        options={countryOptions}
                        onChange={handleFieldChange}
                      />
                    </Form.Field>
                    <Form.Field
                      required
                      width="4"
                      error={brandError}
                      onFocus={() => setBrandError(false)}
                    >
                      <label htmlFor="brand">{t('common.brand')}</label>
                      <Form.Select
                        name="brandId"
                        className={styles.field}
                        placeholder={t('common.selectBrand')}
                        search
                        fluid
                        selection
                        value={data.brandId}
                        options={brandOptions}
                        onChange={handleFieldChange}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group>
                    <Form.Field width="4">
                      <label htmlFor="webCode">{t('common.webCode')}</label>
                      <Input
                        fluid
                        name="webCode"
                        value={data.webCode}
                        readOnly={isSubmitting}
                        className={styles.field}
                        onChange={handleFieldChange}
                      />
                    </Form.Field>
                    <Form.Field width="3">
                      <label htmlFor="webExtra">{t('common.webExtra')}</label>
                      <Input
                        fluid
                        type="number"
                        name="webExtra"
                        value={data.webExtra}
                        readOnly={isSubmitting}
                        className={styles.field}
                        onChange={handleFieldChange}
                      />
                    </Form.Field>
                    <Form.Field width={3}>
                      <label htmlFor="point">{t('common.point')}</label>
                      <Input
                        name="point"
                        defaultValue={data.point}
                        readOnly={isSubmitting}
                        className={styles.field}
                        onChange={handleFieldChange}
                        placeholder={t('common.point')}
                      />
                    </Form.Field>
                    <Form.Field width="3">
                      <label htmlFor="weight">{t('common.weight')}</label>
                      <Input
                        fluid
                        type="number"
                        name="weight"
                        value={data.weight}
                        readOnly={isSubmitting}
                        className={styles.field}
                        onChange={handleFieldChange}
                      />
                    </Form.Field>
                    <Form.Field width="3">
                      <label htmlFor="weightAmount">{t('common.weightAmount')}</label>
                      <Input
                        fluid
                        type="number"
                        name="weightAmount"
                        value={data.weightAmount}
                        readOnly={isSubmitting}
                        className={styles.field}
                        onChange={handleFieldChange}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group>
                    <Form.Field width={16}>
                      <label htmlFor="description">{t('common.description')}</label>
                      <SimpleMDE
                        name="description"
                        value={data.description}
                        // options={EDITOR_OPTIONS}
                        placeholder={t('common.enterDescription')}
                        className={styles.simplemde}
                        onChange={(value) =>
                          handleFieldChange(null, { name: 'description', value })
                        }
                      />
                    </Form.Field>
                  </Form.Group>
                </Grid.Column>
                <Grid.Column width={4}>
                  <Form.Field>
                    <label htmlFor="imageOption">{t('common.image')}</label>
                    <Form.Select
                      name="imageOption"
                      className={styles.field}
                      placeholder={t('common.selectImageType')}
                      fluid
                      selection
                      value={data.imageOption}
                      options={imageOptions}
                      onChange={handleFieldChange}
                    />
                    {data.imageOption === 'url' && (
                      <Input
                        fluid
                        name="imageUrl"
                        defaultValue={data.imageUrl}
                        readOnly={isSubmitting}
                        className={styles.fieldImageUrl}
                        onChange={handleFieldChange}
                        placeholder={t('common.imageUrl')}
                      />
                    )}
                    {data.imageOption === 'uploadImgur' && (
                      <div className={styles.imagePreview}>
                        <FilePicker
                          accept=".jpg, .png"
                          onSelect={(file) => handleFileSelect(file, 'imgur')}
                        >
                          <Button className={styles.uploadImage} type="button">
                            {t('common.fromComputer', {
                              context: 'title',
                            })}
                          </Button>
                        </FilePicker>
                        <Image
                          src={filePath.file || '/product.svg'}
                          className={styles.image}
                          size="tiny"
                          bordered
                          floated="right"
                        />
                      </div>
                    )}
                    {data.imageOption === 'uploadHost' && (
                      <div className={styles.imagePreview}>
                        <FilePicker
                          accept=".jpg, .png"
                          onSelect={(file) => handleFileSelect(file, 'host')}
                        >
                          <Button className={styles.uploadImage} type="button">
                            {t('common.fromComputer', {
                              context: 'title',
                            })}
                          </Button>
                        </FilePicker>
                        <Image
                          src={filePath.file || '/product.svg'}
                          className={styles.image}
                          size="tiny"
                          bordered
                          floated="right"
                        />
                      </div>
                    )}
                  </Form.Field>
                  <Form.Group>
                    <Form.Field width={10}>
                      <br />
                      <Form.Checkbox
                        name="isWithWeightAmount"
                        checked={data.isWithWeightAmount}
                        label={t('common.isWithWeightAmount')}
                        onChange={(e, dt) =>
                          handleFieldChange(e, { name: 'isWithWeightAmount', value: dt.checked })
                        }
                      />
                      <br />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group>
                    <Form.Field
                      width={16}
                      error={categoryError}
                      onFocus={() => setCategoryError(false)}
                    >
                      <label htmlFor="category">{t('common.category')}</label>
                      <Select
                        // isClearable={false}
                        isMulti={false}
                        components={{ DropdownIndicator: null }}
                        name="categoryIds"
                        options={categoryOptions}
                        onChange={handleCategoryChange}
                        placeholder={t('common.selectCategory')}
                      />
                    </Form.Field>
                  </Form.Group>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Table compact className={styles.listVariationWrapper}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={2}>{t('common.color')}</Table.HeaderCell>
                  <Table.HeaderCell width={2}>{t('common.size')}</Table.HeaderCell>
                  <Table.HeaderCell width={1}>{t('common.quantity')}</Table.HeaderCell>
                  <Table.HeaderCell width={2}>{t('common.webPrice')}</Table.HeaderCell>
                  <Table.HeaderCell width={2}>{t('common.feeAmount')}</Table.HeaderCell>
                  <Table.HeaderCell width={2}>{t('common.sellPrice')}</Table.HeaderCell>
                  <Table.HeaderCell width={2}>{t('common.wholesalePrice')}</Table.HeaderCell>
                  <Table.HeaderCell width={2}>{t('common.imageUrl')}</Table.HeaderCell>
                  <Table.HeaderCell width={1}>
                    <Button
                      className={styles.buttonAddVariation}
                      type="button"
                      onClick={() => handleAdd()}
                    >
                      {t('action.add')}
                    </Button>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {fields.map((field, index) => {
                  return (
                    // eslint-disable-next-line react/no-array-index-key
                    <Table.Row key={index}>
                      <Table.Cell>
                        <Input
                          fluid
                          name="color"
                          value={field.color}
                          readOnly={isSubmitting}
                          className={styles.field}
                          onChange={(e) => handleChange(index, e)}
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <Input
                          fluid
                          name="size"
                          defaultValue={field.size}
                          readOnly={isSubmitting}
                          className={styles.field}
                          onChange={(e) => handleChange(index, e)}
                        />
                      </Table.Cell>
                      <Table.Cell width="1">
                        <Input
                          fluid
                          name="quantity"
                          defaultValue={field.quantity}
                          readOnly={isSubmitting}
                          className={styles.field}
                          onChange={(e) => handleChange(index, e)}
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <Input
                          fluid
                          name="webPrice"
                          defaultValue={field.webPrice}
                          readOnly={isSubmitting}
                          className={styles.field}
                          onChange={(e) => handleChange(index, e)}
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <Input
                          fluid
                          name="feeAmount"
                          defaultValue={field.feeAmount}
                          readOnly={isSubmitting}
                          className={styles.field}
                          onChange={(e) => handleChange(index, e)}
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <Input
                          fluid
                          name="sellPrice"
                          defaultValue={field.sellPrice}
                          readOnly={isSubmitting}
                          className={styles.field}
                          onChange={(e) => handleChange(index, e)}
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <Input
                          fluid
                          name="wholesalePrice"
                          defaultValue={field.wholesalePrice}
                          readOnly={isSubmitting}
                          className={styles.field}
                          onChange={(e) => handleChange(index, e)}
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <Input
                          fluid
                          name="imageUrl"
                          defaultValue={field.imageUrl}
                          readOnly={isSubmitting}
                          className={styles.field}
                          onChange={(e) => handleChange(index, e)}
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <Image
                          src={field.imageUrl || data.imageUrl || '/product.svg'}
                          className={styles.thumb}
                          size="tiny"
                          bordered
                        />
                        <Button
                          size="tiny"
                          className={styles.buttonRemoveLevel}
                          type="button"
                          onClick={() => handleRemove(index)}
                        >
                          {t('action.delete')}
                        </Button>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
            <div className="text-right">
              <Button
                color="red"
                content={t('action.cancel')}
                disabled={isSubmitting}
                onClick={onClose}
              />
              <Button
                color="green"
                content={t('action.save')}
                loading={isSubmitting}
                disabled={isSubmitting}
              />
            </div>
          </Form>
        </Modal.Content>
      </Modal>
    );
  },
);

ProductAddPopup.propTypes = {
  countries: PropTypes.arrayOf(PropTypes.any).isRequired,
  brands: PropTypes.arrayOf(PropTypes.any).isRequired,
  categories: PropTypes.arrayOf(PropTypes.any).isRequired,
  defaultData: PropTypes.objectOf(PropTypes.any).isRequired,
  attachmentImgur: PropTypes.arrayOf(PropTypes.any).isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  error: PropTypes.string,
  onCreate: PropTypes.func.isRequired,
  onMessageDismiss: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onAttachmentCreate: PropTypes.func.isRequired,
};

ProductAddPopup.defaultProps = {
  error: undefined,
};

export default ProductAddPopup;
