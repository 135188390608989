import React, { useCallback, useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { List } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import User from '../User';

import NameEdit from '../ShipmentItem/NameEdit';

import styles from './RightSidebar.module.scss';

const RightSidebar = React.memo(({ canEdit, shipment, shiplines, onUpdate }) => {
  const [t] = useTranslation();

  const codeEdit = useRef(null);
  const shipperEdit = useRef(null);
  const noteEdit = useRef(null);

  const [deliveredDate, setDeliveredDate] = useState(new Date());

  const [isOpen, setIsOpen] = useState(false);
  const handleChange = (date) => {
    setIsOpen(!isOpen);
    setDeliveredDate(date);
    onUpdate(shipment.id, {
      deliveredDate: t('format:postDate', {
        postProcess: 'formatDate',
        value: date,
      }),
    });
  };

  const handleHeaderClick = useCallback(
    (field) => {
      if (canEdit) {
        switch (field) {
          case 'code':
            codeEdit.current.open();
            break;
          case 'shipper':
            shipperEdit.current.open();
            break;
          case 'note':
            noteEdit.current.open();
            break;
          case 'deliveredDate':
            setIsOpen(!isOpen);
            break;
          default:
        }
      }
    },
    [canEdit, isOpen],
  );

  const handleFieldUpdate = useCallback(
    (data, field) => {
      switch (field) {
        case 'code':
          onUpdate(shipment?.id, {
            code: data,
          });
          break;
        case 'shipper':
          onUpdate(shipment?.id, {
            shipper: data,
          });
          break;
        case 'note':
          onUpdate(shipment?.id, {
            note: data,
          });
          break;
        default:
      }
    },
    [shipment, onUpdate],
  );

  useEffect(() => {
    if (shipment && shipment.deliveredDate) setDeliveredDate(new Date(shipment.deliveredDate));
  }, [setDeliveredDate, shipment]);

  return (
    <>
      {shipment && (
        <>
          <h3>
            {t('common.shipment')} #{shipment.code}
          </h3>
          <hr style={{ margin: '0 -14px' }} />
          <List divided verticalAlign="middle" className={styles.details}>
            <List.Item className={styles.listItem}>
              <List.Content
                className={styles.listValue}
                floated="right"
                onClick={() => handleHeaderClick('code')}
              >
                {canEdit ? (
                  <NameEdit
                    ref={codeEdit}
                    defaultValue={shipment.code}
                    onUpdate={(data) => handleFieldUpdate(data, 'code')}
                  >
                    <div className={styles.headerName}>#{shipment.code}</div>
                  </NameEdit>
                ) : (
                  <div className={styles.headerName}>#{shipment.code}</div>
                )}
              </List.Content>
              <List.Content className={styles.listLabel}>{t('common.shipCode')}:</List.Content>
            </List.Item>
            <List.Item className={styles.listItem}>
              <List.Content
                className={styles.listValue}
                floated="right"
                onClick={() => handleHeaderClick('shipper')}
              >
                {canEdit ? (
                  <NameEdit
                    ref={shipperEdit}
                    defaultValue={shipment.shipper}
                    onUpdate={(data) => handleFieldUpdate(data, 'shipper')}
                  >
                    <div className={styles.headerName}>{shipment.shipper}</div>
                  </NameEdit>
                ) : (
                  <div className={styles.headerName}>{shipment.shipper}</div>
                )}
              </List.Content>
              <List.Content>{t('common.shipper')}:</List.Content>
            </List.Item>
            <List.Item className={styles.listItem}>
              <List.Content className={styles.listValue} floated="right">
                {shiplines?.length}
              </List.Content>
              <List.Content>{t('common.customer')}:</List.Content>
            </List.Item>
            <List.Item className={styles.listItem}>
              <List.Content className={styles.listValue} floated="right">
                {shiplines
                  ?.reduce((totalSum, shipline) => totalSum + shipline.finalTotal, 0)
                  .toLocaleString('en-GB')}
              </List.Content>
              <List.Content>{t('common.totalAmount')}:</List.Content>
            </List.Item>
            <List.Item className={styles.listItem}>
              <List.Content className={styles.listValue} floated="right">
                {t('format:date', {
                  postProcess: 'formatDate',
                  value: new Date(shipment.estimatedDeliveryDate),
                })}
              </List.Content>
              <List.Content>{t('common.estimatedDeliveryDate')}:</List.Content>
            </List.Item>
            <List.Item className={styles.listItem}>
              <List.Content
                className={`${styles.listValue} ${styles.dateValue}`}
                floated="right"
                onClick={() => handleHeaderClick('deliveredDate')}
              >
                {t('format:date', {
                  postProcess: 'formatDate',
                  value: new Date(shipment.deliveredDate),
                })}
                {isOpen && (
                  <div className={styles.datePicker}>
                    <DatePicker selected={deliveredDate} onChange={handleChange} inline />
                  </div>
                )}
              </List.Content>
              <List.Content>{t('common.deliveredDate')}:</List.Content>
            </List.Item>
            <List.Item className={styles.listItem}>
              <List.Content
                className={styles.listValue}
                floated="right"
                onClick={() => handleHeaderClick('note')}
              >
                {canEdit ? (
                  <NameEdit
                    ref={noteEdit}
                    defaultValue={shipment.note}
                    onUpdate={(data) => handleFieldUpdate(data, 'note')}
                  >
                    <div className={styles.headerName}>{shipment.note}</div>
                  </NameEdit>
                ) : (
                  <div className={styles.headerName}>{shipment.note}</div>
                )}
              </List.Content>
              <List.Content>{t('common.note')}:</List.Content>
            </List.Item>
          </List>
          <hr style={{ margin: '0 -14px' }} />
          <List className={styles.listActions}>
            <List.Item className={styles.listItem}>
              <List.Content className={styles.listValue}>
                <div className={styles.actionUser}>
                  <User name={shipment.creator?.name || ''} />
                </div>
                <div className={styles.actionContent}>
                  <span className={styles.actionDate}>
                    {t('format:longDateTime', {
                      postProcess: 'formatDate',
                      value: new Date(shipment.createdAt),
                    })}
                  </span>
                  <div className={styles.headerName}>
                    {shipment.creator?.name} - {t('common.createdShipment')}
                  </div>
                </div>
              </List.Content>
            </List.Item>
          </List>
        </>
      )}
    </>
  );
});

RightSidebar.propTypes = {
  canEdit: PropTypes.bool,
  shipment: PropTypes.objectOf(PropTypes.any),
  shiplines: PropTypes.arrayOf(PropTypes.any),
  onUpdate: PropTypes.func.isRequired,
};

RightSidebar.defaultProps = {
  shipment: undefined,
  shiplines: undefined,
  canEdit: false,
};

export default RightSidebar;
