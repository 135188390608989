import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  currentBusinessSelector,
  managersForCurrentBusinessSelector,
  countriesForCurrentBusinessSelector,
  brandsForCurrentBusinessSelector,
  categoriesForCurrentBusinessSelector,
  accountsForCurrentBusinessSelector,
  customerGroupsForCurrentBusinessSelector,
  usersSelector,
} from '../selectors';
import {
  closeModal,
  createAccountInCurrentBusiness,
  createBrandInCurrentBusiness,
  createCategoryInCurrentBusiness,
  createCountryInCurrentBusiness,
  createCustomerGroupInCurrentBusiness,
  createManagerInCurrentBusiness,
  deleteAccount,
  deleteBrand,
  deleteCategory,
  deleteCountry,
  deleteCurrentBusiness,
  deleteCustomerGroup,
  deleteBusinessManager,
  updateCurrentBusiness,
  updateAccount,
  updateBrand,
  updateCategory,
  updateCountry,
  updateCustomerGroup,
  updateCurrentBusinessBackgroundImage,
} from '../actions/entry';
import BusinessSettingsModal from '../components/BusinessSettingsModal';

const mapStateToProps = (state) => {
  const users = usersSelector(state);

  const {
    name,
    phone,
    hotline,
    page,
    facebook,
    address,
    weightAmountPolicy,
    weightPolicy,
    background,
    backgroundImage,
    isBackgroundImageUpdating,
  } = currentBusinessSelector(state);

  const managers = managersForCurrentBusinessSelector(state).filter(
    ({ user: { email } }) => email !== 'kanri@tipici.vn',
  );
  const countries = countriesForCurrentBusinessSelector(state);
  const brands = brandsForCurrentBusinessSelector(state);
  const categories = categoriesForCurrentBusinessSelector(state);
  const accounts = accountsForCurrentBusinessSelector(state);
  const customerGroups = customerGroupsForCurrentBusinessSelector(state);

  return {
    name,
    phone,
    hotline,
    page,
    facebook,
    address,
    weightAmountPolicy,
    weightPolicy,
    background,
    backgroundImage,
    isBackgroundImageUpdating,
    managers,
    allUsers: users.filter(({ status }) => status === 'active'),
    countries,
    brands,
    categories,
    accounts,
    customerGroups,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onUpdate: updateCurrentBusiness,
      onBackgroundImageUpdate: updateCurrentBusinessBackgroundImage,
      onAccountUpdate: updateAccount,
      onAccountCreate: createAccountInCurrentBusiness,
      onAccountDelete: deleteAccount,
      onBrandUpdate: updateBrand,
      onBrandCreate: createBrandInCurrentBusiness,
      onBrandDelete: deleteBrand,
      onCategoryUpdate: updateCategory,
      onCategoryCreate: createCategoryInCurrentBusiness,
      onCategoryDelete: deleteCategory,
      onCountryUpdate: updateCountry,
      onCountryCreate: createCountryInCurrentBusiness,
      onCountryDelete: deleteCountry,
      onCustomerGroupUpdate: updateCustomerGroup,
      onCustomerGroupCreate: createCustomerGroupInCurrentBusiness,
      onCustomerGroupDelete: deleteCustomerGroup,
      onDelete: deleteCurrentBusiness,
      onManagerCreate: createManagerInCurrentBusiness,
      onManagerDelete: deleteBusinessManager,
      onClose: closeModal,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(BusinessSettingsModal);
