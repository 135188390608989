import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Pagination } from 'semantic-ui-react';
import { HotTable, HotColumn } from '@handsontable/react';
// import { HyperFormula } from 'hyperformula';
import { exportXlsx } from './export';
import Summary from '../SellitemTable/Summary';
import MenusPopup from '../Customers/MenusPopup';
import CustomerRenderer from '../Sellitems/CustomerRenderer';
import DatePickerEditor from '../HotTable/DatePickerEditor';
import NumberEditor from '../HotTable/NumberEditor';
import PaymentAddEditor from '../HotTable/PaymentAddEditor';
import SelectEditor from '../HotTable/SelectEditor';
import { Statuses } from '../../constants/Enums';
import { getFilterSellitems, setFilterSellitems } from '../../utils/filter-storage';
import {
  arrivalDateRenderer,
  dateRenderer,
  linkRenderer,
  statusRenderer,
  imageRenderer,
  quantityRenderer,
  weightAmountRenderer,
  percentRenderer,
} from '../SellitemTable/renderer';

import styles from './SellitemFullTable.module.scss';

const undoQ = [];
const redoQ = [];

const SellitemFullTable = React.memo(
  ({
    accounts,
    sellitems,
    canEdit,
    canDelete,
    stat,
    currentPage,
    showRightSidebar,
    onPaymentCreate,
    onDeleteSellitem,
    onUpdateSellitems,
    onSellitemsFetch,
    isOwner,
  }) => {
    const [t] = useTranslation();

    const hotTableRef = useRef();
    const summaryRef = useRef();

    // const [currentPage, setCurrentPage] = useState(1);

    const onPageChange = (e, { activePage }) => {
      // setCurrentPage(activePage);
      onSellitemsFetch({ page: activePage });
    };

    const colHeaders = useMemo(
      () => [
        'id',
        t('common.dateOrder'),
        t('common.code'),
        t('common.customer'),
        t('common.status'),
        t('common.arrivalDate'),
        t('common.country', { context: 'title' }),
        t('common.brand'),
        t('common.stocks'),
        t('common.products'),
        t('common.image'),
        t('common.color'),
        t('common.size'),
        t('common.webCode'),
        t('common.quantity'),
        t('common.webPrice', { context: 'title' }),
        t('common.webExtra'),
        t('common.sellPrice'),
        t('common.subtotal'),
        t('common.webOrderNo'),
        t('common.feeAmount', { context: 'title' }),
        // t('common.total'),
        t('common.diff', { context: 'title' }),
        // t('common.weight'),
        t('common.weightAmount'),
        t('common.finalTotal'),
        t('common.payment'),
        t('common.remainTotal'),
        // t('common.members'),
        // t('common.estimatedArrivalDay'),
        // t('common.note'),
      ],
      [t],
    );

    const handleExport = useCallback(() => {
      const excelHeaders = [
        'STT',
        t('common.dateOrder'),
        t('common.members'),
        t('common.code'),
        t('common.customer'),
        t('common.status'),
        t('common.arrivalDate'),
        t('common.country'),
        t('common.brand'),
        t('common.stocks'),
        t('common.products'),
        t('common.image'),
        t('common.color'),
        t('common.size'),
        t('common.webCode'),
        t('common.quantity'),
        t('common.webPrice'),
        t('common.webExtra'),
        t('common.sellPrice'),
        t('common.subtotal'),
        t('common.webOrderNo'),
        t('common.feeAmount'),
        t('common.diff'),
        t('common.weightAmount'),
        t('common.finalTotal'),
        t('common.payment'),
        t('common.remainTotal'),
      ];
      exportXlsx(
        sellitems,
        excelHeaders,
        // currentBag || { name: 'TiciLand', startDate: new Date() },
      );
    }, [sellitems, t]);

    const afterChange = (changes, source) => {
      const sourceChange = ['edit', 'CopyPaste.paste', 'Autofill.fill', 'undo', 'redo'];
      if (sourceChange.indexOf(source) > -1 && changes.length > 0) {
        let newData;
        const ids = [];
        let currentGroupId;
        changes.forEach(([row, prop, oldValue, newValue]) => {
          if ((oldValue === null || oldValue === undefined) && newValue === '') return;

          // eslint-disable-next-line eqeqeq
          if (oldValue != newValue) {
            let newDate;
            let colorCode = null;
            let sizeCode = null;
            let webCode = null;
            const sellitemId = hotTableRef.current.hotInstance.getDataAtRowProp(row, 'id');
            const {
              id,
              color,
              groupId,
              // weight,
              country: { weightAmount },
              product: { variation },
              // isWithWeightAmount,
            } = sellitems.find(({ id: curId }) => curId === sellitemId);
            ids.push(id);
            currentGroupId = groupId;

            switch (prop) {
              case 'arrivalDate':
                if (newValue) {
                  if (newValue.includes('/')) {
                    const [d, M, yyyy] = newValue.split('/');
                    newDate = new Date(yyyy, M - 1, d);
                  } else {
                    newDate = new Date(newValue);
                  }
                  newData = {
                    [prop]: newDate,
                    status: Statuses.ARRIVAL.name,
                  };
                } else {
                  newData = {
                    [prop]: newDate,
                  };
                }

                break;
              case 'color':
                if (variation && variation.colors) {
                  const clrObj = variation.colors.find((it) => it.name === newValue);
                  colorCode = clrObj?.colorId;
                  webCode = clrObj?.webCode;
                  // console.log('variation_webcode:', webCode);
                }
                if (!webCode) {
                  newData = {
                    [prop]: newValue || null,
                    colorCode,
                  };
                } else {
                  newData = {
                    [prop]: newValue || null,
                    colorCode,
                    webCode,
                  };
                }

                break;
              case 'size':
                if (variation) {
                  if (variation.colors) {
                    sizeCode = variation.colors
                      .find((it) => it.name === color)
                      ?.sizes.find((it) => it.name === newValue)?.sizeCode;
                  } else if (variation.sizes) {
                    sizeCode = variation.sizes.find((it) => it.name === newValue)?.sizeCode;
                  }
                }
                newData = {
                  [prop]: newValue || null,
                  sizeCode,
                };
                break;
              case 'status':
                newData = {
                  [prop]: Object.values(Statuses).find(({ text }) => text === newValue).name,
                };
                if (newValue === Statuses.CANCELED.text || newValue === Statuses.CANCELEDOK.text) {
                  newData.quantity = 0;
                }

                break;
              case 'weight':
                if (newValue === t('common.withWeightAmount')) {
                  newData = {
                    [prop]: null,
                    weightAmount: null,
                    isWithWeightAmount: true,
                  };
                } else if (newValue === t('common.withoutWeightAmount')) {
                  newData = {
                    // [prop]: null,
                    // weightAmount: isWithWeightAmount ? null : (weightAmount || 1) * newValue,
                    isWithWeightAmount: false,
                  };
                } else {
                  newData = {
                    [prop]: newValue || null,
                    weightAmount: newValue ? (weightAmount || 1) * newValue : null,
                    isWithWeightAmount: false,
                  };
                }
                break;
              case 'payment':
                return;
              default:
                newData = {
                  [prop]: newValue || null,
                };
                break;
            }
            if (source === 'undo') {
              if (redoQ.length > 9) redoQ.shift();
              redoQ.push([id, prop, oldValue]);
            } else {
              if (source !== 'redo') redoQ.length = 0;
              if (undoQ.length > 9) undoQ.shift();
              undoQ.push([id, prop, oldValue]);
            }
            // onUpdateSellitem(id, newData);
          }
        });
        if (newData) {
          newData.ids = ids;
          onUpdateSellitems(currentGroupId, newData);
        }
      }
      return false;
    };

    const afterSelection = useCallback(() => {
      const selected = hotTableRef.current.hotInstance.getSelected();
      if (
        !(
          selected.length === 1 &&
          selected[0][0] === selected[0][2] &&
          selected[0][1] === selected[0][3]
        )
      ) {
        const data = [];
        selected.forEach((it) => {
          data.push(hotTableRef.current.hotInstance.getData(...it).flat());
        });
        summaryRef.current?.show(data);
      } else {
        summaryRef.current?.show([]);
      }
    }, []);

    const afterDeselect = () => {
      summaryRef.current?.show([]);
    };

    const afterGetColHeader = (col, TH) => {
      if (col === 1 || col === 10 || col === 16 || col === 22) {
        const button = TH.querySelector('.changeType');

        if (!button) {
          return;
        }

        button.parentElement.removeChild(button);
      }
    };

    const afterFilter = (formulaStack) => {
      setFilterSellitems(formulaStack);
    };

    const handleRedo = useCallback(() => {
      const hotInstance = redoQ.pop();
      if (hotInstance && hotInstance.length > 0) {
        hotInstance[0] = sellitems.findIndex((item) => item.id === hotInstance[0]);
        hotTableRef.current.hotInstance.selectCell(hotInstance[0], hotInstance[1]);
        hotTableRef.current.hotInstance.setDataAtRowProp(...hotInstance, 'redo');
      }
    }, [sellitems]);

    const handleUndo = useCallback(() => {
      const hotInstance = undoQ.pop();
      if (hotInstance && hotInstance.length > 0) {
        hotInstance[0] = sellitems.findIndex((item) => item.id === hotInstance[0]);
        hotTableRef.current.hotInstance.selectCell(hotInstance[0], hotInstance[1]);
        hotTableRef.current.hotInstance.setDataAtRowProp(...hotInstance, 'undo');
      }
    }, [sellitems]);

    // const handleLoadMore = useCallback(() => {
    //   onSellitemsFetch()
    // }, [sellitems]);

    const beforeKeyDown = (e) => {
      if (e.key === 'Delete' || e.key === 'Backspace') {
        // e.preventDefault();
        e.stopImmediatePropagation();
        return;
      }
      if (e.ctrlKey || e.metaKey) {
        if (e.keyCode === 90) {
          if (e.shiftKey) {
            handleRedo();
          } else {
            handleUndo();
          }
        }
      }
    };

    const beforeRemoveRow = useCallback(
      (row, count, physicalRows) => {
        physicalRows.forEach((r) => {
          const sellitemId = hotTableRef.current.hotInstance.getDataAtRowProp(r, 'id');
          const { id, status } = sellitems.find(({ id: curId }) => curId === sellitemId);
          if (canDelete && status === Statuses.CANCELEDOK.text) {
            onDeleteSellitem(id);
            // return true;
          }
        });
        return false;
      },
      [canDelete, onDeleteSellitem, sellitems],
    );

    // const handleExport = useCallback(() => {
    //   const exportPlugin = hotTableRef.current.hotInstance.getPlugin('exportFile');
    //   exportPlugin.downloadFile('csv', {
    //     columnHeaders: true,
    //     filename: 'WowOrder [YYYY]-[MM]-[DD]',
    //   });
    // }, []);

    // const [, setValue] = useState(0);
    useEffect(() => {
      undoQ.length = 0;
      redoQ.length = 0;
      // setValue(1);
    }, []);

    useEffect(() => {
      const c = getFilterSellitems();
      if (c && hotTableRef.current) {
        // eslint-disable-next-line react/no-this-in-sfc
        const filtersPlugin = hotTableRef.current.hotInstance.getPlugin('Filters');
        // filtersPlugin.addCondition(1, 'by_value', [['ing', 'ed', 'as', 'on']]);
        if (filtersPlugin) {
          // console.log(filtersPlugin.conditionCollection.filteringStates.indexedValues);
          filtersPlugin.clearConditions();
          c.forEach((it) => {
            if (it) {
              it.conditions.forEach((con) => {
                filtersPlugin.addCondition(it.column, con.name, con.args, it.operation);
              });
            }
          });
          filtersPlugin.filter();
        }
      }
    }, [sellitems]);

    useEffect(() => {
      hotTableRef.current.hotInstance.refreshDimensions();
    }, [showRightSidebar]);

    const startIdx = (currentPage - 1) * 1000 + 1;

    return (
      <div id="hot-app" className={styles.wrapper}>
        <HotTable
          ref={hotTableRef}
          data={sellitems}
          colHeaders={colHeaders}
          hiddenColumns={{ columns: [0] }}
          height="100%"
          stretchH="all"
          autoColumnSize={false}
          autoRowSize={false}
          autoWrapRow={false}
          className={styles.oddeven}
          fixedColumnsLeft={4}
          manualColumnResize
          rowHeaders={false}
          rowHeights={45}
          contextMenu={{
            items: {
              undo: {
                name: `${t('action.undo')}<span style="color:gray;float:right;">⌘Z</span>`,
                callback() {
                  handleUndo();
                },
                disabled: () => undoQ.length < 1,
              },
              redo: {
                name: `${t('action.redo')}<span style="color:gray;float:right;">⌘⇧Z</span>`,
                callback() {
                  handleRedo();
                },
                disabled: () => redoQ.length < 1,
              },
              separator: '---------',
              // cut: { name: `${t('action.delete')}<span style="color:gray;float:right;">⌘X</span>` },
              copy: { name: `${t('action.copy')}<span style="color:gray;float:right;">⌘C</span>` },
              separator2: '---------',
              hidden_columns_hide: { name: 'Ẩn cột' },
              remove_row: {
                name: `${t('action.delete')}`,
                disabled() {
                  return (
                    // eslint-disable-next-line react/no-this-in-sfc
                    this.getDataAtRowProp(this.getSelectedLast()[0], 'status') !==
                    Statuses.CANCELEDOK.text
                  );
                },
                hidden: () => !canDelete,
              },
            },
          }}
          enterMoves={{ col: 1, row: 0 }}
          dropdownMenu={['filter_by_value', 'filter_action_bar']}
          filters
          afterChange={afterChange}
          afterGetColHeader={afterGetColHeader}
          afterSelection={afterSelection}
          afterDeselect={afterDeselect}
          afterFilter={afterFilter}
          beforeKeyDown={beforeKeyDown}
          beforeRemoveRow={beforeRemoveRow}
          licenseKey="non-commercial-and-evaluation"
        >
          <HotColumn width={0} data="id" />
          <HotColumn width={90} data="createdAt" renderer={dateRenderer} readOnly />
          <HotColumn width={60} data="customerCode" settings={[{ dropdownMenu: false }]} readOnly />
          <HotColumn width={125} data="customerName" settings={[{ dropdownMenu: false }]} readOnly>
            <CustomerRenderer hot-renderer showFacebook />
          </HotColumn>
          <HotColumn
            width={100}
            data="status"
            renderer={statusRenderer}
            editor={SelectEditor}
            options={Object.values(Statuses).map(({ text, hex }) => ({
              label: text,
              value: text,
              color: hex,
            }))}
          />
          <HotColumn
            width={90}
            data="arrivalDate"
            placeholder={`${t('common.sellitemsInvoice.columnExpectedArriveDay')}\n:date`}
            editor={DatePickerEditor}
            renderer={arrivalDateRenderer}
          />
          <HotColumn width={40} data="country.name" readOnly />
          <HotColumn width={70} data="brandName" readOnly />
          <HotColumn width={70} data="productType" readOnly />
          <HotColumn width={180} data="name" className={styles.twoLines} renderer={linkRenderer} />
          <HotColumn width={30} data="imageUrl" renderer={imageRenderer} readOnly />
          <HotColumn width={100} data="color" editor={SelectEditor} />
          <HotColumn width={70} data="size" editor={SelectEditor} />
          <HotColumn width={100} data="webCode" />
          <HotColumn
            width={50}
            data="quantity"
            type="numeric"
            numericFormat={{ pattern: '0,0' }}
            renderer={quantityRenderer}
          />
          <HotColumn width={70} data="webPrice" type="numeric" numericFormat={{ pattern: '0,0' }} />
          <HotColumn
            width={40}
            data="webExtra"
            // type="numeric"
            // numericFormat={{ pattern: '0,0' }}
            className="htRight"
            renderer={percentRenderer}
          />
          <HotColumn
            width={80}
            data="sellPrice"
            type="numeric"
            numericFormat={{ pattern: '0,0' }}
            //
            editor={NumberEditor}
          />
          <HotColumn
            width={90}
            data="subtotal"
            type="numeric"
            numericFormat={{ pattern: '0,0' }}
            readOnly
          />
          <HotColumn width={120} data="webOrderNo" />
          <HotColumn
            width={60}
            data="feeAmount"
            type="numeric"
            numericFormat={{ pattern: '0,0' }}
            editor={NumberEditor}
          />
          <HotColumn
            width={70}
            data="diff"
            type="numeric"
            numericFormat={{ pattern: '0,0' }}
            readOnly
          />
          {/* <HotColumn width={60} data="weight" type="numeric" /> */}
          <HotColumn
            width={70}
            data="weight"
            type="autocomplete"
            source={[t('common.withWeightAmount'), t('common.withoutWeightAmount')]}
            filter={false}
            placeholder={t('common.weightPlacehoder')}
            renderer={weightAmountRenderer}
          />
          <HotColumn
            width={100}
            data="finalTotal"
            type="numeric"
            numericFormat={{ pattern: '0,0' }}
            readOnly
          />
          <HotColumn
            width={90}
            data="payment"
            type="numeric"
            numericFormat={{ pattern: '0,0' }}
            t={t}
            accounts={accounts}
            onCreate={onPaymentCreate}
            editor={PaymentAddEditor}
          />
          <HotColumn
            width={90}
            data="remainTotal"
            type="numeric"
            numericFormat={{ pattern: '0,0' }}
            readOnly
          />
          {/* <HotColumn width={100} data="creator" readOnly /> */}
        </HotTable>
        <div>
          <Summary ref={summaryRef} />

          <Button.Group className={styles.actionButtons}>
            <MenusPopup
              closeIcon={false}
              canExport={isOwner && canEdit && sellitems.length > 0}
              canImport={canEdit}
              onExport={handleExport}
              onImport={() => console.log('onImport')}
            >
              <Button size="mini" icon="bars" />
            </MenusPopup>
            <Button
              className={styles.buttonTableActionUndo}
              size="mini"
              content={t('action.undo')}
              icon="undo"
              labelPosition="left"
              onClick={handleUndo}
              disabled={undoQ.length < 1}
            />
            <Button
              className={styles.buttonTableActionRedo}
              size="mini"
              content={t('action.redo')}
              icon="redo"
              labelPosition="right"
              onClick={handleRedo}
              disabled={redoQ.length < 1}
            />
            {/* <Button
              className={styles.buttonTableActionUndo}
              size="mini"
              content={t('action.loadMore')}
              icon="sync"
              labelPosition="left"
              onClick={onSellitemsFetch}
              disabled={isAllSellitemsFetched}
            /> */}
            <Button
              basic
              size="mini"
              content={
                sellitems.length <= 0
                  ? `0/${stat.sellitemsTotalCount}`
                  : `${startIdx}〜${startIdx + sellitems.length - 1}/${stat.sellitemsTotalCount}`
              }
              disabled
            />
          </Button.Group>
          <Pagination
            activePage={currentPage}
            boundaryRange={1}
            onPageChange={onPageChange}
            size="mini"
            siblingRange={3}
            prevItem={null}
            nextItem={null}
            // prevItem={{ content: <Icon name="angle left" />, icon: true }}
            // nextItem={{ content: <Icon name="angle right" />, icon: true }}
            // firstItem={{ content: <Icon name="angle double left" />, icon: true }}
            // lastItem={{ content: <Icon name="angle double right" />, icon: true }}
            firstItem={null}
            lastItem={null}
            totalPages={Math.ceil(stat.sellitemsTotalCount / 1000)}
          />
        </div>
      </div>
    );
  },
);

SellitemFullTable.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.any).isRequired,
  sellitems: PropTypes.arrayOf(PropTypes.any).isRequired,
  canEdit: PropTypes.bool.isRequired,
  canDelete: PropTypes.bool.isRequired,
  stat: PropTypes.objectOf(PropTypes.any).isRequired,
  currentPage: PropTypes.number.isRequired,
  showRightSidebar: PropTypes.bool,
  onPaymentCreate: PropTypes.func.isRequired,
  onDeleteSellitem: PropTypes.func.isRequired,
  onUpdateSellitems: PropTypes.func.isRequired,
  onSellitemsFetch: PropTypes.func.isRequired,
  isOwner: PropTypes.bool.isRequired,
};

SellitemFullTable.defaultProps = {
  showRightSidebar: false,
};

export default SellitemFullTable;
