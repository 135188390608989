import { combineReducers } from 'redux';

import authenticateForm from './authenticate-form';
import userCreateForm from './user-create-form';
import businessCreateForm from './business-create-form';
import productCreateForm from './product-create-form';
import importForm from './import-form';
import sellitemCreateForm from './sellitem-create-form';

export default combineReducers({
  authenticateForm,
  userCreateForm,
  businessCreateForm,
  productCreateForm,
  importForm,
  sellitemCreateForm,
});
