import http from './http';
import socket from './socket';

/* Actions */

const getBusinesses = (headers) => socket.get('/businesses', undefined, headers);

const createBusiness = (data, headers) => socket.post('/businesses', data, headers);

const getBusiness = (id, headers) => socket.get(`/businesses/${id}`, undefined, headers);

const updateBusiness = (id, data, headers) => socket.patch(`/businesses/${id}`, data, headers);

const updateBusinessBackgroundImage = (id, data, headers) =>
  http.post(`/businesses/${id}/background-image`, data, headers);

const deleteBusiness = (id, headers) => socket.delete(`/businesses/${id}`, undefined, headers);

export default {
  getBusinesses,
  createBusiness,
  getBusiness,
  updateBusiness,
  updateBusinessBackgroundImage,
  deleteBusiness,
};
