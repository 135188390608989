import React from 'react';
import PropTypes from 'prop-types';

import HeaderContainer from '../../containers/HeaderContainer';
import BusinessContainer from '../../containers/BusinessContainer';
import GroupActionsContainer from '../../containers/GroupActionsContainer';

import styles from './Fixed.module.scss';

const Fixed = ({ businessId, group }) => (
  <div className={styles.wrapper}>
    <HeaderContainer />
    {businessId && <BusinessContainer />}
    {group && !group.isFetching && <GroupActionsContainer />}
  </div>
);

Fixed.propTypes = {
  businessId: PropTypes.string,
  group: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

Fixed.defaultProps = {
  businessId: undefined,
  group: undefined,
};

export default Fixed;
