import { all, takeEvery } from 'redux-saga/effects';

import {
  createShiplineInCurrentShipmentService,
  updateShiplineService,
  deleteShiplineService,
} from '../services';
import EntryActionTypes from '../../../constants/EntryActionTypes';

export default function* shiplineWatchers() {
  yield all([
    takeEvery(EntryActionTypes.SHIPLINE_CREATE, ({ payload: { data } }) =>
      createShiplineInCurrentShipmentService(data),
    ),
    takeEvery(EntryActionTypes.SHIPLINE_UPDATE, ({ payload: { id, data } }) =>
      updateShiplineService(id, data),
    ),
    takeEvery(EntryActionTypes.SHIPLINE_DELETE, ({ payload: { id, data } }) =>
      deleteShiplineService(id, data),
    ),
  ]);
}
