import { call, put, select } from 'redux-saga/effects';

import { goToBusinessService } from './router';
import request from '../request';
import { pathSelector } from '../../../selectors';
import {
  createCountry,
  deleteCountry,
  handleCountryCreate,
  handleCountryDelete,
  handleCountryUpdate,
  updateCountry,
  addCountryToGroupFilter,
  removeCountryFromGroupFilter,
} from '../../../actions';
import api from '../../../api';
import { createLocalId } from '../../../utils/local-id';

export function* createCountryService(businessId, data) {
  const nextData = {
    ...data,
  };

  const localId = yield call(createLocalId);

  yield put(
    createCountry({
      ...nextData,
      businessId,
      id: localId,
    }),
  );

  let country;

  try {
    ({ item: country } = yield call(request, api.createCountry, businessId, nextData));
  } catch (error) {
    yield put(createCountry.failure(localId, error));
    return;
  }

  yield put(createCountry.success(localId, country));
}

export function* createCountryInCurrentBusinessService(data) {
  const { businessId } = yield select(pathSelector);

  yield call(createCountryService, businessId, data);
}

export function* handleCountryCreateService(country) {
  yield put(handleCountryCreate(country));
}

export function* updateCountryService(id, data) {
  yield put(updateCountry(id, data));

  let country;
  try {
    ({ item: country } = yield call(request, api.updateCountry, id, data));
  } catch (error) {
    yield put(updateCountry.failure(id, error));
    return;
  }

  yield put(updateCountry.success(country));
}

export function* handleCountryUpdateService(country) {
  yield put(handleCountryUpdate(country));
}

export function* deleteCountryService(id) {
  const { countryId, businessId } = yield select(pathSelector);

  if (id === countryId) {
    yield call(goToBusinessService, businessId);
  }

  yield put(deleteCountry(id));

  let country;
  try {
    ({ item: country } = yield call(request, api.deleteCountry, id));
  } catch (error) {
    yield put(deleteCountry.failure(id, error));
    return;
  }

  yield put(deleteCountry.success(country));
}

export function* handleCountryDeleteService(country) {
  const { countryId, businessId } = yield select(pathSelector);

  if (country.id === countryId) {
    yield call(goToBusinessService, businessId);
  }

  yield put(handleCountryDelete(country));
}

export function* addCountryToGroupFilterService(id, groupId) {
  yield put(addCountryToGroupFilter(id, groupId));
}

export function* addCountryToFilterInCurrentGroupService(id) {
  const { groupId } = yield select(pathSelector);

  yield call(addCountryToGroupFilterService, id, groupId);
}

export function* removeCountryFromGroupFilterService(id, groupId) {
  yield put(removeCountryFromGroupFilter(id, groupId));
}

export function* removeCountryFromFilterInCurrentGroupService(id) {
  const { groupId } = yield select(pathSelector);

  yield call(removeCountryFromGroupFilterService, id, groupId);
}
