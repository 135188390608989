import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  paymentsBySellitemIdSelector,
  sellitemsByCustomerIdSelector,
  shiplineByIdSelector,
  currentShipmentSelector,
  customerByIdSelector,
  addressByIdSelector,
} from '../selectors';
import { closeModal, updateShipline } from '../actions/entry';
import ShiplineDetail from '../components/ShiplineDetail';
import { PaymentTypes, Statuses, StatusesShipment } from '../constants/Enums';

const mapStateToProps = (state, { id }) => {
  const shipline = shiplineByIdSelector(state, id);
  let sellitems = sellitemsByCustomerIdSelector(state, shipline.customerId);
  const customer = customerByIdSelector(state, shipline.customerId);
  let customerAddress;
  if (customer.addressId) customerAddress = addressByIdSelector(state, customer.addressId);
  const shipment = currentShipmentSelector(state);

  if (shipline.status === StatusesShipment.SHIPPED.name) {
    sellitems = sellitems.filter(({ id: sellitemId }) => shipline.sellitemIds.includes(sellitemId));
  } else {
    sellitems = sellitems.filter(({ status }) => status === Statuses.ARRIVAL.name);
    if (!sellitems) {
      sellitems = sellitems.filter(({ id: sellitemId }) =>
        shipline.sellitemIds.includes(sellitemId),
      );
    }
  }
  sellitems = sellitems.map((si) => {
    const payments = paymentsBySellitemIdSelector(state, si.id);
    const quantity = si.quantity - si.quantityCancel;
    const subtotal = quantity * si.sellPrice;
    const total = si.webPrice * quantity * (1 - si.webExtra / 100) * si.exchangeRate;
    const finalTotal = subtotal + 1 * si.weightAmount; // + 1 * si.feeAmount;
    const payment = payments.reduce((paymentSum, pay) => {
      if (pay.type === PaymentTypes.PAYMENT) {
        return paymentSum + parseInt(pay.amount, 10);
      }
      return paymentSum;
    }, 0);

    return {
      ...si,
      isChecked: shipline.sellitemIds.includes(si.id),
      member: si.creator,
      imageUrl: si.product?.imageUrl,
      subtotal,
      diff: subtotal - total,
      finalTotal,
      payment,
      remainTotal: finalTotal - payment,
    };
  });

  return {
    shipline: {
      ...shipline,
      shipper: shipment.shipper,
      address: shipline.address?.address
        ? `${shipline.address.name} - ${shipline.address.phone} - ${shipline.address.address}, ${shipline.address.ward}, ${shipline.address.district}, ${shipline.address.province}`
        : '',
    },
    customer: {
      ...customer,
      address: customerAddress,
    },
    sellitems,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onUpdateShipline: (id, data) => updateShipline(id, data),
      onClose: closeModal,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ShiplineDetail);
