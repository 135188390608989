import ActionTypes from '../constants/ActionTypes';

export const createAddress = (address) => ({
  type: ActionTypes.ADDRESS_CREATE,
  payload: {
    address,
  },
});

createAddress.success = (localId, address) => ({
  type: ActionTypes.ADDRESS_CREATE__SUCCESS,
  payload: {
    localId,
    address,
  },
});

createAddress.failure = (localId, error) => ({
  type: ActionTypes.ADDRESS_CREATE__FAILURE,
  payload: {
    localId,
    error,
  },
});

export const handleAddressCreate = (address) => ({
  type: ActionTypes.ADDRESS_CREATE_HANDLE,
  payload: {
    address,
  },
});

export const updateAddress = (id, data) => ({
  type: ActionTypes.ADDRESS_UPDATE,
  payload: {
    id,
    data,
  },
});

updateAddress.success = (address) => ({
  type: ActionTypes.ADDRESS_UPDATE__SUCCESS,
  payload: {
    address,
  },
});

updateAddress.failure = (id, error) => ({
  type: ActionTypes.ADDRESS_UPDATE__FAILURE,
  payload: {
    id,
    error,
  },
});

export const handleAddressUpdate = (address) => ({
  type: ActionTypes.ADDRESS_UPDATE_HANDLE,
  payload: {
    address,
  },
});

export const deleteAddress = (id) => ({
  type: ActionTypes.ADDRESS_DELETE,
  payload: {
    id,
  },
});

deleteAddress.success = (address) => ({
  type: ActionTypes.ADDRESS_DELETE__SUCCESS,
  payload: {
    address,
  },
});

deleteAddress.failure = (id, error) => ({
  type: ActionTypes.ADDRESS_DELETE__FAILURE,
  payload: {
    id,
    error,
  },
});

export const handleAddressDelete = (address) => ({
  type: ActionTypes.ADDRESS_DELETE_HANDLE,
  payload: {
    address,
  },
});
