import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Label, List } from 'semantic-ui-react';

import styles from './Addresses.module.scss';
import ActionsPopup from './ActionsPopup';

const Addresses = React.memo(
  ({ defaultAddressId, items, canEdit, onCreate, onUpdate, onDelete, onCustomerUpdate }) => {
    const [t] = useTranslation();

    const handleUpdate = useCallback(
      (id, data) => {
        onUpdate(id, data);
      },
      [onUpdate],
    );

    const handleDelete = useCallback(
      (id) => {
        onDelete(id);
      },
      [onDelete],
    );

    const handleCustomerUpdate = useCallback(
      (id) => {
        onCustomerUpdate(id);
      },
      [onCustomerUpdate],
    );

    return (
      <>
        <List celled verticalAlign="middle" className={styles.addresses}>
          {items.map(({ id, name, phone, province, district, ward, address }) => (
            <List.Item key={id} className={classNames(canEdit && styles.contentHoverable)}>
              <List.Content>
                {name}&nbsp;
                {defaultAddressId === id && (
                  <Label size="mini" color="green" content={t('common.default')} />
                )}
                <br />
                <span className={styles.meta}>{phone}</span>
                <br />
                <span className={styles.meta}>
                  {address}, {ward}, {district}, {province}
                </span>
              </List.Content>
              <ActionsPopup
                isDefault={defaultAddressId === id}
                defaultData={{ id, name, phone, province, district, ward, address }}
                onCreate={onCreate}
                onUpdate={(data) => handleUpdate(id, data)}
                onDelete={() => handleDelete(id)}
                onCustomerUpdate={() => handleCustomerUpdate(id)}
              >
                <Button className={classNames(styles.button, styles.target)}>
                  <Icon fitted name="pencil" size="small" />
                </Button>
              </ActionsPopup>
            </List.Item>
          ))}
          {items.length === 0 && <List.Item content={t('common.noAddress')} />}
        </List>
      </>
    );
  },
);

Addresses.propTypes = {
  defaultAddressId: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  canEdit: PropTypes.bool.isRequired,
  onCreate: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onCustomerUpdate: PropTypes.func.isRequired,
};

export default Addresses;
