import EntryActionTypes from '../../constants/EntryActionTypes';

export const createCustomerGroupInCurrentBusiness = (data) => ({
  type: EntryActionTypes.CUSTOMER_GROUP_IN_CURRENT_BUSINESS_CREATE,
  payload: {
    data,
  },
});

export const handleCustomerGroupCreate = (customerGroup) => ({
  type: EntryActionTypes.CUSTOMER_GROUP_CREATE_HANDLE,
  payload: {
    customerGroup,
  },
});

export const updateCustomerGroup = (id, data) => ({
  type: EntryActionTypes.CUSTOMER_GROUP_UPDATE,
  payload: {
    id,
    data,
  },
});

export const handleCustomerGroupUpdate = (customerGroup) => ({
  type: EntryActionTypes.CUSTOMER_GROUP_UPDATE_HANDLE,
  payload: {
    customerGroup,
  },
});

export const deleteCustomerGroup = (id) => ({
  type: EntryActionTypes.CUSTOMER_GROUP_DELETE,
  payload: {
    id,
  },
});

export const handleCustomerGroupDelete = (customerGroup) => ({
  type: EntryActionTypes.CUSTOMER_GROUP_DELETE_HANDLE,
  payload: {
    customerGroup,
  },
});
