/* eslint-disable import/prefer-default-export */
import ActionTypes from '../constants/ActionTypes';

export const searchShipments = (groupId, query) => ({
  type: ActionTypes.SHIPMENTS_FETCH,
  payload: {
    groupId,
    query,
  },
});

searchShipments.success = (groupId, shipments, sellitems) => ({
  type: ActionTypes.SHIPMENTS_FETCH__SUCCESS,
  payload: {
    groupId,
    shipments,
    sellitems,
  },
});

searchShipments.failure = (groupId, error) => ({
  type: ActionTypes.SHIPMENTS_FETCH__FAILURE,
  payload: {
    groupId,
    error,
  },
});

export const fetchShipments = (groupId, fitlerArrivalDate, data) => ({
  type: ActionTypes.SHIPMENTS_FETCH,
  payload: {
    groupId,
    fitlerArrivalDate,
    data,
  },
});

fetchShipments.success = (
  groupId,
  shipments,
  shiplines,
  sellitems,
  customers,
  products,
  payments,
  stat,
) => ({
  type: ActionTypes.SHIPMENTS_FETCH__SUCCESS,
  payload: {
    groupId,
    shipments,
    shiplines,
    sellitems,
    customers,
    products,
    payments,
    stat,
  },
});

fetchShipments.failure = (groupId, error) => ({
  type: ActionTypes.SHIPMENTS_FETCH__FAILURE,
  payload: {
    groupId,
    error,
  },
});
