import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Menu } from 'semantic-ui-react';
import { Popup } from '../../lib/custom-ui';

import Item from './Item';

import styles from './GroupBrandsStep.module.scss';

const GroupBrandsStep = React.memo(
  ({ items, currentBrandIds, title, onBrandSelect, onBrandDeselect, onBack }) => {
    const [t] = useTranslation();

    const handleBrandSelect = useCallback(
      (id) => {
        onBrandSelect(id);
      },
      [onBrandSelect],
    );

    const handleBrandDeselect = useCallback(
      (id) => {
        onBrandDeselect(id);
      },
      [onBrandDeselect],
    );

    return (
      <>
        <Popup.Header onBack={onBack}>{t(title)}</Popup.Header>
        <Popup.Content>
          <Menu secondary vertical className={styles.menu}>
            {items.map((item) => (
              <Item
                key={item.id}
                isPersisted={item.isPersisted}
                isActive={currentBrandIds.includes(item.id)}
                name={item.name}
                onSelect={() => handleBrandSelect(item.id)}
                onDeselect={() => handleBrandDeselect(item.id)}
              />
            ))}
          </Menu>
        </Popup.Content>
      </>
    );
  },
);

GroupBrandsStep.propTypes = {
  /* eslint-disable react/forbid-prop-types */
  items: PropTypes.array.isRequired,
  currentBrandIds: PropTypes.array.isRequired,
  /* eslint-enable react/forbid-prop-types */
  title: PropTypes.string,
  onBrandSelect: PropTypes.func.isRequired,
  onBrandDeselect: PropTypes.func.isRequired,
  onBack: PropTypes.func,
};

GroupBrandsStep.defaultProps = {
  title: 'common.members',
  onBack: undefined,
};

export default GroupBrandsStep;
