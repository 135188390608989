const FILTER_SELLITEMS_KEY = 'filterSellitems';
const FILTER_CUSTOMERS_KEY = 'filterCustomers';
const FILTER_PRODUCTS_KEY = 'filterProducts';
const FILTER_SHIPMENTS_KEY = 'filterShipments';
const FILTER_SHIPMENTS_PAGE_KEY = 'filterShipmentsPage';

export const getFilterSellitems = () => {
  return JSON.parse(localStorage.getItem(FILTER_SELLITEMS_KEY));
};

export const setFilterSellitems = (filterSellitems) => {
  localStorage.setItem(FILTER_SELLITEMS_KEY, JSON.stringify(filterSellitems));
};

export const removeFilterSellitems = () => {
  localStorage.removeItem(FILTER_SELLITEMS_KEY);
};

export const getFilterCustomers = () => {
  return JSON.parse(localStorage.getItem(FILTER_CUSTOMERS_KEY));
};

export const setFilterCustomers = (filterCustomers) => {
  localStorage.setItem(FILTER_CUSTOMERS_KEY, JSON.stringify(filterCustomers));
};

export const removeFilterCustomers = () => {
  localStorage.removeItem(FILTER_CUSTOMERS_KEY);
};

export const getFilterProducts = () => {
  return JSON.parse(localStorage.getItem(FILTER_PRODUCTS_KEY));
};

export const setFilterProducts = (filterProducts) => {
  localStorage.setItem(FILTER_PRODUCTS_KEY, JSON.stringify(filterProducts));
};

export const removeFilterProducts = () => {
  localStorage.removeItem(FILTER_PRODUCTS_KEY);
};

export const getFilterShipments = () => {
  return JSON.parse(localStorage.getItem(FILTER_SHIPMENTS_KEY));
};

export const setFilterShipments = (filterShipments) => {
  localStorage.setItem(FILTER_SHIPMENTS_KEY, JSON.stringify(filterShipments));
};

export const removeFilterShipments = () => {
  localStorage.removeItem(FILTER_SHIPMENTS_KEY);
};

export const getFilterShipmentsPage = () => {
  return JSON.parse(localStorage.getItem(FILTER_SHIPMENTS_PAGE_KEY));
};

export const setFilterShipmentsPage = (filterShipmentsPage) => {
  localStorage.setItem(FILTER_SHIPMENTS_PAGE_KEY, JSON.stringify(filterShipmentsPage));
};

export const removeFilterShipmentsPage = () => {
  localStorage.removeItem(FILTER_SHIPMENTS_PAGE_KEY);
};
