import { Model, attr, fk } from 'redux-orm';

import ActionTypes from '../constants/ActionTypes';

export default class extends Model {
  static modelName = 'Customer';

  static fields = {
    id: attr(),
    position: attr(),
    name: attr(),
    code: attr(),
    facebook: attr(),
    phone: attr(),
    mobile: attr(),
    walletAmount: attr(),
    isFetching: attr({
      getDefault: () => null,
    }),
    groupId: fk({
      to: 'Group',
      as: 'group',
      relatedName: 'customers',
    }),
    customerGroupId: fk({
      to: 'CustomerGroup',
      as: 'customerGroup',
      relatedName: 'customers',
    }),
  };

  static reducer({ type, payload }, Customer) {
    switch (type) {
      case ActionTypes.LOCATION_CHANGE_HANDLE:
      case ActionTypes.CORE_INITIALIZE:
      case ActionTypes.BUSINESS_MANAGER_CREATE_HANDLE:
      case ActionTypes.GROUP_MEMBERSHIP_CREATE_HANDLE:
        if (payload.customers) {
          payload.customers.forEach((customer) => {
            Customer.upsert(customer);
          });
        }

        break;
      case ActionTypes.LOCATION_CHANGE_HANDLE__CUSTOMER_FETCH:
      case ActionTypes.CUSTOMER_FETCH:
        Customer.withId(payload.id).update({
          isFetching: true,
        });

        break;
      case ActionTypes.CUSTOMER_FETCH__SUCCESS:
        Customer.upsert({
          ...payload.customer,
          isFetching: false,
        });

        break;
      case ActionTypes.SOCKET_RECONNECT_HANDLE:
        Customer.all().delete();

        if (payload.customers) {
          payload.customers.forEach((customer) => {
            Customer.upsert(customer);
          });
        }

        break;
      case ActionTypes.SHIPMENTS_FETCH__SUCCESS:
      case ActionTypes.SELLITEMS_FETCH__SUCCESS:
      case ActionTypes.GROUP_FETCH__SUCCESS:
      case ActionTypes.CUSTOMERS_FETCH__SUCCESS:
        payload.customers.forEach((customer) => {
          Customer.upsert(customer);
        });

        break;
      case ActionTypes.CUSTOMER_CREATE:
      case ActionTypes.CUSTOMER_CREATE_HANDLE:
      case ActionTypes.CUSTOMER_UPDATE__SUCCESS:
      case ActionTypes.CUSTOMER_UPDATE_HANDLE:
        Customer.upsert(payload.customer);

        break;
      case ActionTypes.CUSTOMER_CREATE__SUCCESS:
        Customer.withId(payload.localId).delete();
        Customer.upsert(payload.customer);

        break;
      case ActionTypes.CUSTOMER_UPDATE:
        Customer.withId(payload.id).update(payload.data);

        break;
      case ActionTypes.CUSTOMER_DELETE:
        Customer.withId(payload.id).deleteWithRelated();

        break;
      case ActionTypes.CUSTOMER_DELETE__SUCCESS:
      case ActionTypes.CUSTOMER_DELETE_HANDLE: {
        const customerModel = Customer.withId(payload.customer.id);

        if (customerModel) {
          customerModel.deleteWithRelated();
        }

        break;
      }
      default:
    }
  }

  getOrderedSellitemsQuerySet() {
    return this.sellitems.orderBy('createdAt', false);
  }

  getOrderedFilteredSellitemsModelArray() {
    let sellitemModels = this.getOrderedSellitemsQuerySet().toModelArray();

    const filterUserIds = this.group.filterUsers.toRefArray().map((user) => user.id);
    const filterLabelIds = this.group.filterLabels.toRefArray().map((label) => label.id);

    if (filterUserIds.length > 0) {
      sellitemModels = sellitemModels.filter((sellitemModel) => {
        return filterUserIds.includes(sellitemModel.creator.id);
        // const users = sellitemModel.creator.toRefArray();

        // return users.some((user) => filterUserIds.includes(user.id));
      });
    }

    if (filterLabelIds.length > 0) {
      sellitemModels = sellitemModels.filter((sellitemModel) => {
        const labels = sellitemModel.labels.toRefArray();

        return labels.some((label) => filterLabelIds.includes(label.id));
      });
    }

    return sellitemModels;
  }

  getOrderedAddressesQuerySet() {
    return this.addresses.orderBy('createdAt');
  }

  getOrderedPaymentsQuerySet() {
    return this.payments.orderBy('createdAt');
  }

  deleteRelated() {
    this.addresses.delete();
    this.sellitems.toModelArray().forEach((sellitemModel) => {
      sellitemModel.deleteWithRelated();
    });
  }

  deleteWithRelated() {
    this.deleteRelated();
    this.delete();
  }
}
