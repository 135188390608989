import { createSelector } from 'redux-orm';

import orm from '../orm';
import { pathSelector } from './router';
import { currentUserIdSelector } from './user';
import { isLocalId } from '../utils/local-id';
import { buildFullTextRegex, replaceAccentedChar } from '../utils/fts';

export const defaultGroupSelector = createSelector(orm, ({ Group }) =>
  Group.getOrderedQuerySet()
    .filter((group) => group.isFetching !== null)
    .toRefArray(),
);

export const makeGroupByIdSelector = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ Group }, id) => {
      const groupModel = Group.withId(id);

      if (!groupModel) {
        return groupModel;
      }

      return groupModel.ref;
    },
  );

export const groupByIdSelector = makeGroupByIdSelector();

export const currentGroupSelector = createSelector(
  orm,
  (state) => pathSelector(state).groupId,
  ({ Group }, id) => {
    if (!id) {
      return id;
    }

    const groupModel = Group.withId(id);

    if (!groupModel) {
      return groupModel;
    }

    return groupModel.ref;
  },
);

export const membershipsForCurrentGroupSelector = createSelector(
  orm,
  (state) => pathSelector(state).groupId,
  (state) => currentUserIdSelector(state),
  ({ Group }, id, currentUserId) => {
    if (!id) {
      return id;
    }

    const groupModel = Group.withId(id);

    if (!groupModel) {
      return groupModel;
    }

    return groupModel
      .getOrderedMembershipsQuerySet()
      .toModelArray()
      .map((groupMembershipModel) => ({
        ...groupMembershipModel.ref,
        isPersisted: !isLocalId(groupMembershipModel.id),
        user: {
          ...groupMembershipModel.user.ref,
          isCurrent: groupMembershipModel.user.id === currentUserId,
        },
      }));
  },
);

export const labelsForCurrentGroupSelector = createSelector(
  orm,
  (state) => pathSelector(state).groupId,
  ({ Group }, id) => {
    if (!id) {
      return id;
    }

    const groupModel = Group.withId(id);

    if (!groupModel) {
      return groupModel;
    }

    return groupModel.labels.toRefArray().map((label) => ({
      ...label,
      isPersisted: !isLocalId(label.id),
    }));
  },
);

export const customerIdsForCurrentGroupSelector = createSelector(
  orm,
  (state) => pathSelector(state).groupId,
  ({ Group }, id) => {
    if (!id) {
      return id;
    }

    const groupModel = Group.withId(id);

    if (!groupModel) {
      return groupModel;
    }

    return groupModel
      .getOrderedCustomersQuerySet()
      .toRefArray()
      .map((customer) => customer.id);
  },
);

export const customersForCurrentGroupSelector = createSelector(
  orm,
  (state) => pathSelector(state).groupId,
  ({ Group }, groupId) => {
    if (!groupId) {
      return groupId;
    }

    const groupModel = Group.withId(groupId);

    if (!groupModel) {
      return groupModel;
    }
    const { customerQuery: query } = groupModel;

    if (query) {
      const querySet = groupModel.getOrderedCustomersQuerySet();
      const queryIsNumber = parseInt(query, 10);
      const m1 = querySet
        .filter(
          ({ code, name, phone }) =>
            code === query ||
            name.toLowerCase() === query.toLowerCase() ||
            (queryIsNumber && phone === query),
        )
        .toRefArray()
        .map((customer) => customer);
      const m1Ids = m1.map(({ id }) => id);
      const rex = buildFullTextRegex(query);
      const m2 = querySet
        .filter(({ id }) => !m1Ids.includes(id))
        .filter(
          ({ code, name, phone }) =>
            code?.includes(query) ||
            (!queryIsNumber && rex.test(replaceAccentedChar(name))) ||
            (queryIsNumber && phone?.includes(query)),
        )
        .toRefArray()
        .map((customer) => customer);
      return [...m1, ...m2];
    }
    return groupModel
      .getOrderedCustomersQuerySet()
      .toRefArray()
      .map(({ id, phone, code, name, customerGroupId }) => ({
        id,
        phone,
        code,
        name,
        customerGroupId,
      }));
  },
);

export const sellitemIdsForCurrentGroupSelector = createSelector(
  orm,
  (state) => pathSelector(state).groupId,
  ({ Group }, id) => {
    if (!id) {
      return id;
    }

    const groupModel = Group.withId(id);

    if (!groupModel) {
      return groupModel;
    }

    return groupModel
      .getOrderedSellitemsQuerySet()
      .toRefArray()
      .map((sellitem) => sellitem.id);
  },
);

export const allSellitemsForCurrentGroupSelector = createSelector(
  orm,
  (state) => pathSelector(state).groupId,
  ({ Group }, id) => {
    if (!id) {
      return id;
    }

    const groupModel = Group.withId(id);

    if (!groupModel) {
      return groupModel;
    }

    return groupModel
      .getOrderedSellitemsQuerySet()
      .toModelArray()
      .map((sellitemModel) => ({
        ...sellitemModel.ref,
        creator: sellitemModel.creator?.ref.name,
        customer: sellitemModel.customer?.ref.name,
        product: sellitemModel.product?.ref,
        country: sellitemModel.product?.country?.ref.name,
        brand: sellitemModel.product?.brand?.ref.name,
      }));
  },
);

export const sellitemsForCurrentGroupSelector = createSelector(
  orm,
  (state) => pathSelector(state).groupId,
  // (state) => currentGroupSelector(state),
  ({ Group }, groupId) => {
    if (!groupId) {
      return groupId;
    }

    const groupModel = Group.withId(groupId);

    if (!groupModel) {
      return groupModel;
    }

    const {
      filterUsers,
      filterCountries,
      filterStatuses,
      filterBrands,
      filterOrderDate,
      filterArrivalDate,
    } = groupModel;
    let sellitemsModel = groupModel.getOrderedSellitemsQuerySet().toModelArray();

    if (filterUsers.count() > 0) {
      const filterUserIds = filterUsers.toRefArray().map((user) => user.id);
      sellitemsModel = sellitemsModel.filter((sellitemModel) => {
        return filterUserIds.includes(sellitemModel.creator.id);
      });
    }

    if (filterStatuses.length > 0) {
      sellitemsModel = sellitemsModel.filter((sellitemModel) =>
        filterStatuses.includes(sellitemModel.status),
      );
    }

    if (filterBrands.count() > 0) {
      const filterBrandIds = filterBrands.toRefArray().map((brand) => brand.id);
      sellitemsModel = sellitemsModel.filter((sellitemModel) =>
        filterBrandIds.includes(sellitemModel.product?.ref.brandId),
      );
    }

    if (filterCountries.count() > 0) {
      const filterCountryIds = filterCountries.toRefArray().map((country) => country.id);
      sellitemsModel = sellitemsModel.filter((sellitemModel) =>
        filterCountryIds.includes(sellitemModel.product?.country?.ref.id),
      );
    }

    if (filterOrderDate) {
      sellitemsModel = sellitemsModel.filter(
        (sellitemModel) =>
          sellitemModel.createdAt >= filterOrderDate.start &&
          sellitemModel.createdAt <= filterOrderDate.end,
      );
    }

    if (filterArrivalDate) {
      sellitemsModel = sellitemsModel.filter(
        (sellitemModel) =>
          sellitemModel.arrivalDate >= filterArrivalDate.start &&
          sellitemModel.arrivalDate <= filterArrivalDate.end,
      );
    }

    // return groupModel.getOrderedSellitemsQuerySet().toRefArray();
    return sellitemsModel.map((sellitemModel) => {
      const {
        code: customerCode,
        name: customerName,
        facebook: customerFacebook,
      } = sellitemModel.customer.ref;
      const {
        id,
        position,
        name,
        description,
        colorCode,
        sizeCode,
        color,
        size,
        unit,
        quantity,
        quantityCancel,
        webCode,
        webPrice,
        webExtra,
        exchangeRate,
        sellPrice,
        wholesalePrice,
        feeAmount,
        weight,
        weightAmount,
        isWithWeightAmount,
        webOrderNo,
        arrivalDate,
        status,
        timer,
        createdAt,
        updatedAt,
        customerId,
        creatorUserId,
        coverAttachmentId,
        productId,
        stockId,
        invoice,
      } = sellitemModel.ref;
      let p = sellitemModel.product?.ref;
      if (!p) {
        p = {
          id: null,
          name: null,
          description: null,
          url: null,
          imageUrl: null,
          variation: {},
          color: null,
          size: null,
          unit: null,
          webCode: null,
          webPrice: 0,
          webExtra: null,
          sellPrice: 0,
          wholesalePrice: 0,
          feeAmount: 0,
          weight: null,
          weightAmount: null,
          isWithWeightAmount: false,
        };
      }
      return {
        id,
        position,
        name,
        description,
        colorCode,
        sizeCode,
        color,
        size,
        unit,
        quantity,
        quantityCancel,
        webCode,
        webPrice,
        webExtra,
        exchangeRate,
        sellPrice,
        wholesalePrice,
        feeAmount,
        weight,
        weightAmount,
        isWithWeightAmount,
        webOrderNo,
        arrivalDate,
        status,
        timer,
        createdAt,
        updatedAt,
        customerId,
        groupId,
        creatorUserId,
        coverAttachmentId,
        productId,
        stockId,
        invoice,
        creator: sellitemModel.creator?.ref.name,
        customerCode,
        customerName,
        customerFacebook,
        product: {
          id: p.id,
          name: p.name,
          description: p.description,
          type: p.type,
          code: p.code,
          url: p.url,
          imageUrl: p.imageUrl,
          variation: p.variation,
          color: p.color,
          size: p.size,
          unit: p.unit,
          webCode: p.webCode,
          webPrice: p.webPrice,
          webExtra: p.webExtra,
          sellPrice: p.sellPrice,
          wholesalePrice: p.wholesalePrice,
          feeAmount: p.feeAmount,
          weight: p.weight,
          weightAmount: p.weightAmount,
          isWithWeightAmount: p.isWithWeightAmount,
        },
        country: sellitemModel.product?.country?.ref,
        // countryName: sellitemModel.product?.country?.ref.name,
        brandName: sellitemModel.product?.brand?.ref.name,
      };
    });
  },
);

export const sellitemsForCurrentGroupSelector2 = createSelector(
  orm,
  (state) => pathSelector(state).groupId,
  ({ Group }, groupId) => {
    if (!groupId) {
      return groupId;
    }

    const groupModel = Group.withId(groupId);

    if (!groupModel) {
      return groupModel;
    }

    const { filterArrivalDateForShipmentPage } = groupModel;
    let sellitemsModel = groupModel.getOrderedSellitemsQuerySet().toModelArray();

    if (filterArrivalDateForShipmentPage) {
      sellitemsModel = sellitemsModel.filter(
        (sellitemModel) =>
          sellitemModel.arrivalDate >= filterArrivalDateForShipmentPage.start &&
          sellitemModel.arrivalDate <= filterArrivalDateForShipmentPage.end,
      );
    }

    // return groupModel.getOrderedSellitemsQuerySet().toRefArray();
    return sellitemsModel.map((sellitemModel) => {
      const {
        code: customerCode,
        name: customerName,
        facebook: customerFacebook,
      } = sellitemModel.customer.ref;
      const {
        id,
        position,
        name,
        description,
        colorCode,
        sizeCode,
        color,
        size,
        unit,
        quantity,
        quantityCancel,
        webCode,
        webPrice,
        webExtra,
        exchangeRate,
        sellPrice,
        wholesalePrice,
        feeAmount,
        weight,
        weightAmount,
        isWithWeightAmount,
        webOrderNo,
        arrivalDate,
        status,
        timer,
        createdAt,
        updatedAt,
        customerId,
        creatorUserId,
        coverAttachmentId,
        productId,
        stockId,
        invoice,
      } = sellitemModel.ref;
      let p = sellitemModel.product?.ref;
      if (!p) {
        p = {
          id: null,
          name: null,
          description: null,
          url: null,
          imageUrl: null,
          variation: {},
          color: null,
          size: null,
          unit: null,
          webCode: null,
          webPrice: 0,
          webExtra: null,
          sellPrice: 0,
          wholesalePrice: 0,
          feeAmount: 0,
          weight: null,
          weightAmount: null,
          isWithWeightAmount: false,
        };
      }
      return {
        id,
        position,
        name,
        description,
        colorCode,
        sizeCode,
        color,
        size,
        unit,
        quantity,
        quantityCancel,
        webCode,
        webPrice,
        webExtra,
        exchangeRate,
        sellPrice,
        wholesalePrice,
        feeAmount,
        weight,
        weightAmount,
        isWithWeightAmount,
        webOrderNo,
        arrivalDate,
        status,
        timer,
        createdAt,
        updatedAt,
        customerId,
        groupId,
        creatorUserId,
        coverAttachmentId,
        productId,
        stockId,
        invoice,
        creator: sellitemModel.creator?.ref.name,
        customerCode,
        customerName,
        customerFacebook,
        product: {
          id: p.id,
          name: p.name,
          description: p.description,
          url: p.url,
          imageUrl: p.imageUrl,
          variation: p.variation,
          color: p.color,
          size: p.size,
          unit: p.unit,
          webCode: p.webCode,
          webPrice: p.webPrice,
          webExtra: p.webExtra,
          sellPrice: p.sellPrice,
          wholesalePrice: p.wholesalePrice,
          feeAmount: p.feeAmount,
          weight: p.weight,
          weightAmount: p.weightAmount,
          isWithWeightAmount: p.isWithWeightAmount,
        },
        country: sellitemModel.product?.country?.ref,
        // countryName: sellitemModel.product?.country?.ref.name,
        brandName: sellitemModel.product?.brand?.ref.name,
      };
    });
  },
);

export const productsForCurrentGroupSelector = createSelector(
  orm,
  (state) => pathSelector(state).groupId,
  (state) => state.products.query,
  ({ Group }, groupId, query) => {
    const groupModel = Group.withId(groupId);

    if (!groupModel) {
      return groupModel;
    }

    const { filterCountries, filterBrands } = groupModel;
    let productsModel = groupModel.getOrderedProductsQuerySet();

    if (filterBrands.count() > 0) {
      const filterBrandIds = filterBrands.toRefArray().map((brand) => brand.id);
      productsModel = productsModel.filter((productModel) =>
        filterBrandIds.includes(productModel?.brandId),
      );
    }

    if (filterCountries.count() > 0) {
      const filterCountryIds = filterCountries.toRefArray().map((country) => country.id);
      productsModel = productsModel.filter((productModel) =>
        filterCountryIds.includes(productModel.countryId),
      );
    }

    if (query) {
      const m1 = productsModel.filter(
        ({ code, name, url }) =>
          code === query || name.toLowerCase() === query.toLowerCase() || url === query,
      );
      // .toRefArray()
      // .map((product) => product);
      const m1Ids = m1.toModelArray().map(({ id }) => id);
      const rex = buildFullTextRegex(query);
      const m2 = productsModel
        .filter(({ id }) => !m1Ids.includes(id))
        .filter(
          ({ code, name, url }) =>
            code?.includes(query) || rex.test(replaceAccentedChar(name)) || url?.includes(query),
        );
      // .toRefArray()
      // .map((product) => product);
      const m11 = m1.toModelArray().map((productModel) => {
        const { id, name, webCode, imageUrl, url, color } = productModel;
        return {
          id,
          name,
          webCode,
          countryName: productModel.country?.name,
          brandName: productModel.brand?.name,
          categoryName: productModel.categories
            ?.toRefArray()
            .map(({ name: categoryName }) => categoryName)
            .join(', '),
          imageUrl,
          url,
          color,
        };
      });
      const m12 = m2.toModelArray().map((productModel) => {
        const { id, name, webCode, imageUrl, url, color } = productModel;
        return {
          id,
          name,
          webCode,
          countryName: productModel.country?.name,
          brandName: productModel.brand?.name,
          categoryName: productModel.categories
            ?.toRefArray()
            .map(({ name: categoryName }) => categoryName)
            .join(', '),
          imageUrl,
          url,
          color,
        };
      });
      return [...m11, ...m12];
    }

    return productsModel.toModelArray().map((productModel) => {
      const { id, name, webCode, imageUrl, url, color } = productModel;
      return {
        id,
        name,
        webCode,
        countryName: productModel.country?.name,
        brandName: productModel.brand?.name,
        categoryName: productModel.categories
          ?.toRefArray()
          .map(({ name: categoryName }) => categoryName)
          .join(', '),
        imageUrl,
        url,
        color,
      };
    });
  },
);

export const filterUsersForCurrentGroupSelector = createSelector(
  orm,
  (state) => pathSelector(state).groupId,
  ({ Group }, id) => {
    if (!id) {
      return id;
    }

    const groupModel = Group.withId(id);

    if (!groupModel) {
      return groupModel;
    }

    return groupModel.filterUsers.toRefArray();
  },
);

export const filterStatusesForCurrentGroupSelector = createSelector(
  orm,
  (state) => pathSelector(state).groupId,
  ({ Group }, id) => {
    if (!id) {
      return id;
    }

    const groupModel = Group.withId(id);

    if (!groupModel) {
      return groupModel;
    }

    return groupModel.filterStatuses;
  },
);

export const filterShipmentStatusesForCurrentGroupSelector = createSelector(
  orm,
  (state) => pathSelector(state).groupId,
  ({ Group }, id) => {
    if (!id) {
      return id;
    }

    const groupModel = Group.withId(id);
    // console.log('groupModel.filterShipmentStatuses:', groupModel.filterShipmentStatuses);
    if (!groupModel) {
      return groupModel;
    }

    return groupModel.filterShipmentStatuses;
  },
);

export const filterShipProvinceForCurrentGroupSelector = createSelector(
  orm,
  (state) => pathSelector(state).groupId,
  ({ Group }, id) => {
    if (!id) {
      return id;
    }

    const groupModel = Group.withId(id);
    // console.log('groupModel.filterShipProvinces:', groupModel.filterShipProvinces);
    if (!groupModel) {
      return groupModel;
    }

    return groupModel.filterShipProvinces;
  },
);

export const filterLabelsForCurrentGroupSelector = createSelector(
  orm,
  (state) => pathSelector(state).groupId,
  ({ Group }, id) => {
    if (!id) {
      return id;
    }

    const groupModel = Group.withId(id);

    if (!groupModel) {
      return groupModel;
    }

    return groupModel.filterLabels.toRefArray();
  },
);

export const filterBrandsForCurrentGroupSelector = createSelector(
  orm,
  (state) => pathSelector(state).groupId,
  ({ Group }, id) => {
    if (!id) {
      return id;
    }

    const groupModel = Group.withId(id);

    if (!groupModel) {
      return groupModel;
    }

    return groupModel.filterBrands.toRefArray();
  },
);

export const filterCountriesForCurrentGroupSelector = createSelector(
  orm,
  (state) => pathSelector(state).groupId,
  ({ Group }, id) => {
    if (!id) {
      return id;
    }

    const groupModel = Group.withId(id);

    if (!groupModel) {
      return groupModel;
    }

    return groupModel.filterCountries.toRefArray();
  },
);

export const isCurrentUserMemberForCurrentGroupSelector = createSelector(
  orm,
  (state) => pathSelector(state).groupId,
  (state) => currentUserIdSelector(state),
  ({ Group }, id, currentUserId) => {
    if (!id) {
      return false;
    }

    const groupModel = Group.withId(id);

    if (!groupModel) {
      return false;
    }

    return groupModel.hasMemberUser(currentUserId);
  },
);

export const makeLastSellitemIdByGroupIdSelector = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ Group }, id) => {
      const groupModel = Group.withId(id);

      if (!groupModel) {
        return groupModel;
      }

      return groupModel.lastFetchSellitemId;

      // const lastSellitemModel = groupModel.getOrderedInGroupSellitemsQuerySet().last();

      // return lastSellitemModel && lastSellitemModel.id;
    },
  );

export const lastSellitemIdByGroupIdSelector = makeLastSellitemIdByGroupIdSelector();

export const makeLastProductIdByGroupIdSelector = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ Group }, id) => {
      const groupModel = Group.withId(id);

      if (!groupModel) {
        return groupModel;
      }

      const lastProductModel = groupModel.getOrderedProductsQuerySet().last();

      return lastProductModel && lastProductModel.id;
    },
  );

export const lastProductIdByGroupIdSelector = makeLastProductIdByGroupIdSelector();

export const makeLastShipmentIdByGroupIdSelector = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ Group }, id) => {
      const groupModel = Group.withId(id);

      if (!groupModel) {
        return groupModel;
      }

      return groupModel.lastFetchShipmentId;

      // const lastShipmentModel = groupModel.getOrderedShipmentsQuerySet().last();

      // return lastShipmentModel && lastShipmentModel.id;
    },
  );

export const lastShipmentIdByGroupIdSelector = makeLastShipmentIdByGroupIdSelector();
