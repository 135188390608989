import { createSelector as createReselectSelector } from 'reselect';
import { createSelector as createReduxOrmSelector } from 'redux-orm';

import orm from '../orm';
import { currentUserIdSelector } from './user';
import matchPaths from '../utils/match-paths';
import Paths from '../constants/Paths';

export const pathnameSelector = ({
  router: {
    location: { pathname },
  },
}) => pathname;

export const locationSelector = ({ router: { location } }) => location;

export const pathsMatchSelector = createReselectSelector(pathnameSelector, (pathname) =>
  matchPaths(pathname, Object.values(Paths)),
);

export const pathSelector = createReduxOrmSelector(
  orm,
  pathsMatchSelector,
  (state) => currentUserIdSelector(state),
  ({ Business, Group, Customer, Product, Shipment, Sellitem }, pathsMatch, currentUserId) => {
    if (pathsMatch) {
      switch (pathsMatch.path) {
        case Paths.BUSINESSES: {
          const businessModel = Business.withId(pathsMatch.params.id);

          if (!businessModel) {
            return {
              businessId: null,
            };
          }

          if (!businessModel.hasManagerUser(currentUserId)) {
            if (!businessModel.hasMemberUserForAnyGroup(currentUserId)) {
              return {
                businessId: null,
              };
            }
          }

          return {
            businessId: businessModel.id,
          };
        }
        case Paths.PRODUCTS: {
          const productModel = Product.withId(pathsMatch.params.id);
          let groupModel = productModel && productModel.group;
          if (!productModel) {
            groupModel = Group.withId(pathsMatch.params.id);
          }
          const businessModel = groupModel && groupModel.business;

          if (!businessModel) {
            return {
              productId: null,
              groupId: null,
              businessId: null,
            };
          }

          if (!businessModel.hasManagerUser(currentUserId)) {
            if (!groupModel.hasMemberUser(currentUserId)) {
              return {
                productId: null,
                groupId: null,
                businessId: null,
              };
            }
          }

          return {
            productId: productModel ? productModel.id : null,
            groupId: groupModel.id,
            businessId: businessModel.id,
          };
        }
        case Paths.SHIPMENTS: {
          const shipmentModel = Shipment.withId(pathsMatch.params.id);
          let groupModel = shipmentModel && shipmentModel.group;
          if (!shipmentModel) {
            groupModel = Group.withId(pathsMatch.params.id);
          }
          const businessModel = groupModel && groupModel.business;

          if (!businessModel) {
            return {
              shipmentId: null,
              groupId: null,
              businessId: null,
            };
          }

          if (!businessModel.hasManagerUser(currentUserId)) {
            if (!groupModel.hasMemberUser(currentUserId)) {
              return {
                shipmentId: null,
                groupId: null,
                businessId: null,
              };
            }
          }

          return {
            shipmentId: shipmentModel ? shipmentModel.id : null,
            groupId: groupModel.id,
            businessId: businessModel.id,
          };
        }
        case Paths.SHIPMENTPAGE:
        case Paths.GROUPS: {
          const groupModel = Group.withId(pathsMatch.params.id);
          const businessModel = groupModel && groupModel.business;

          if (!businessModel) {
            return {
              groupId: null,
              businessId: null,
            };
          }

          if (!businessModel.hasManagerUser(currentUserId)) {
            if (!groupModel.hasMemberUser(currentUserId)) {
              return {
                groupId: null,
                businessId: null,
              };
            }
          }

          return {
            groupId: groupModel.id,
            businessId: businessModel.id,
          };
        }
        case Paths.CUSTOMERS: {
          const customerModel = Customer.withId(pathsMatch.params.id);
          let groupModel = customerModel && customerModel.group;
          if (!customerModel) {
            groupModel = Group.withId(pathsMatch.params.id);
          }
          const businessModel = groupModel && groupModel.business;

          if (!businessModel) {
            return {
              customerId: null,
              groupId: null,
              businessId: null,
            };
          }

          if (!businessModel.hasManagerUser(currentUserId)) {
            if (!groupModel.hasMemberUser(currentUserId)) {
              return {
                customerId: null,
                groupId: null,
                businessId: null,
              };
            }
          }

          return {
            customerId: customerModel ? customerModel.id : null,
            groupId: groupModel.id,
            businessId: businessModel.id,
          };
        }

        case Paths.SELLITEMS: {
          const sellitemModel = Sellitem.withId(pathsMatch.params.id);
          const groupModel = sellitemModel && sellitemModel.group;
          const businessModel = groupModel && groupModel.business;

          if (!businessModel) {
            return {
              sellitemId: null,
              groupId: null,
              businessId: null,
              customerId: null,
            };
          }

          if (!businessModel.hasManagerUser(currentUserId)) {
            if (!groupModel.hasMemberUser(currentUserId)) {
              return {
                sellitemId: null,
                groupId: null,
                businessId: null,
                customerId: null,
              };
            }
          }

          return {
            sellitemId: sellitemModel.id,
            groupId: groupModel.id,
            businessId: businessModel.id,
            customerId: sellitemModel.customerId,
          };
        }
        case Paths.WAREHOUSE:
        case Paths.DASHBOARD: {
          const groupModel = Group.withId(pathsMatch.params.id);
          const businessModel = groupModel && groupModel.business;

          if (!businessModel) {
            return {
              groupId: null,
              businessId: null,
            };
          }

          if (!businessModel.hasManagerUser(currentUserId)) {
            if (!groupModel.hasMemberUser(currentUserId)) {
              return {
                groupId: null,
                businessId: null,
              };
            }
          }

          return {
            groupId: groupModel.id,
            businessId: businessModel.id,
          };
        }
        default:
      }
    }

    return {};
  },
);
