/* eslint-disable no-nested-ternary */
import React from 'react';
import ReactDOM from 'react-dom';

import Select, { createFilter } from 'react-select';

import Handsontable from 'handsontable';

import styles from './SelectEditor.module.scss';

const colourStyles = {
  // control: (s, state) => ({
  //   ...s,
  //   backgroundColor: state.getValue()[0]?.color || 'red',
  // }),
  option: (s, { data, isDisabled, isFocused, isSelected }) => {
    // console.log(data, isDisabled, isFocused, isSelected);
    return {
      ...s,
      backgroundColor: isSelected || isFocused ? data.color : null,
      color: isDisabled ? '#ccc' : isSelected || isFocused ? 'white' : data.color,
      cursor: isDisabled ? 'not-allowed' : 'default',
      textDecoration: isSelected ? 'underline' : undefined,

      // ':active': {
      //   ...s[':active'],
      //   backgroundColor: '#0f0',
      //   color: 'white',
      // },
    };
  },
};

class CustomEditor extends Handsontable.editors.TextEditor {
  init() {
    this.inputRef = React.createRef();
    this.onSelectChange = this.onSelectChange.bind(this);
    this.options = [];
  }

  // // eslint-disable-next-line no-useless-constructor
  // constructor(props) {
  //   super(props);
  // }

  open() {
    super.open();
    // this.refreshDimensions();
    // this.removeHooksByKey('beforeKeyDown', this.onBeforeKeyDown);
    // this.addHook('beforeKeyDown', this.onBeforeKeyDown);
    const td = this.getEditedCell();
    const tdPosition = td.getBoundingClientRect();
    this.TEXTAREA_PARENT.style.minWidth = `${tdPosition.width + 1}px`;
    this.render();
    // Handsontable.editors.TextEditor.prototype.open.apply(this, []);
    if (this.inputRef.current) {
      this.inputRef.current.focus();
    }
  }

  close() {
    super.close();
    // console.log('close');
    // if (this.isOpened()) this.instance.selectCell(this.row, this.col);
    // Handsontable.editors.TextEditor.prototype.close.apply(this, []);
    // this.removeHooksByKey('beforeKeyDown', this.onBeforeKeyDown);
    this.instance.listen();
    Handsontable.dom.empty(this.TEXTAREA_PARENT);
    Handsontable.dom.addClass(this.TEXTAREA_PARENT, 'handsontableInputHolder');
    this.TEXTAREA_PARENT.appendChild(this.TEXTAREA);
  }

  onSelectChange({ value }) {
    this.setValue(value);
    this.finishEditing(false, false, () => setTimeout(() => this.close()));
  }

  onBeforeKeyDown(event) {
    super.onBeforeKeyDown(event);

    const keys = Handsontable.helper.KEY_CODES;
    // const ctrlDown = (event.ctrlKey || event.metaKey) && !event.altKey;

    switch (event.keyCode) {
      case keys.ARROW_LEFT:
      case keys.ARROW_RIGHT:
      case keys.ARROW_UP:
      case keys.ARROW_DOWN:
        event.stopImmediatePropagation();

        break;

      // case keys.ENTER: {
      //   if (ctrlDown || event.altKey) {
      //     // if ctrl+enter or alt+enter, add new line

      //     // this.beginEditing(''.concat(this.originalValue, '\n'));
      //     event.stopImmediatePropagation();
      //   }
      //   // this.selectCell(this.row, this.col);
      //   event.preventDefault(); // don't add newline to field
      //   break;
      // }

      case keys.ESCAPE: {
        // this.close();
        this.finishEditing(true, false, () => setTimeout(() => this.close()));
        break;
      }

      case keys.BACKSPACE:
      case keys.DELETE:
      case keys.HOME:
      case keys.END:
        event.stopImmediatePropagation(); // backspace, delete, home, end should only work locally when cell is edited (not in table context)

        break;

      default:
        break;
    }
  }

  // createElements() {
  //   super.createElements();

  //   // this.TEXTAREA = document.createElement('input');
  //   // this.TEXTAREA.select = () => {};
  //   // this.TEXTAREA.className = 'handsontableInput';
  //   // Handsontable.dom.empty(this.TEXTAREA_PARENT);
  //   // Handsontable.dom.addClass(this.TEXTAREA_PARENT, 'handsontableInputHolder');
  // }

  // focus() {
  //   super.focus();
  //   // this.instance.listen();
  //   // if (this.inputRef.current) {
  //   //   this.inputRef.current.focus();
  //   // }
  // }

  prepare(row, col, prop, td, originalValue, cellProperties) {
    super.prepare(row, col, prop, td, originalValue, cellProperties);

    const { instance } = this.cellProperties;
    const product = instance.getDataAtRowProp(row, 'product');
    const color = instance.getDataAtRowProp(row, 'color');
    let source = [];
    switch (prop) {
      case 'color':
        if (product && product.variation && product.variation.colors) {
          source = product.variation.colors.map(({ name }) => ({ label: name, value: name }));
        }
        break;
      case 'size':
        if (product && product.variation) {
          if (product.variation.sizes) {
            source = product.variation.sizes.map(({ name }) => ({ label: name, value: name }));
          }
          if (product.variation.colors) {
            const p = product.variation.colors.find(({ name }) => name === color);
            if (p) {
              source = p.sizes.map(({ name }) => ({ label: name, value: name }));
            }
          }
        }
        break;
      case 'isShipProvince':
      case 'status':
        source = cellProperties.options;
        break;
      default:
        break;
    }
    // console.log('CustomEditor', prop);
    this.options = source;
  }

  render() {
    // Handsontable.dom.empty(this.TEXTAREA_PARENT);
    // Handsontable.dom.addClass(this.TEXTAREA_PARENT, 'handsontableInputHolder');
    const source = this.options;
    if (source.length > 0) {
      // Handsontable.dom.empty(this.TEXTAREA_PARENT);
      const div = document.createElement('div');
      div.classList.add(styles.wrapper);
      ReactDOM.render(
        <Select
          ref={this.inputRef}
          autoFocus
          closeMenuOnSelect
          className={styles[this.prop]}
          menuIsOpen
          menuPosition="fixed"
          menuPlacement="auto"
          components={{
            DropdownIndicator: null,
          }}
          filterOption={createFilter({
            ignoreCase: true,
            ignoreAccents: true,
            matchFrom: 'start',
          })}
          isClearable={false}
          maxMenuHeight={this.prop === 'status' ? 280 : 218}
          onChange={this.onSelectChange}
          options={source}
          styles={this.prop === 'status' && colourStyles}
          value={source.find(({ value }) => value === this.originalValue)}
        />,
        div,
      );
      Handsontable.dom.empty(this.TEXTAREA_PARENT);
      Handsontable.dom.addClass(this.TEXTAREA_PARENT, 'handsontableInputHolder');
      // this.TEXTAREA_PARENT.style.top = `${this.TD.offsetParent.clientHeight - 280}px`;
      // if (this.TEXTAREA_PARENT.offsetTop + 280 > this.TEXTAREA_PARENT.offsetParent.clientHeight) {
      //   this.TEXTAREA_PARENT.style.top = `${
      //     this.TEXTAREA_PARENT.offsetParent.clientHeight - 300
      //   }px`;
      // }
      this.TEXTAREA_PARENT.appendChild(div);
    } else {
      // this.TEXTAREA = document.createElement('textarea');
      // this.TEXTAREA.className = 'handsontableInput';
      // this.TEXTAREA.setAttribute('data-hot-input', true);
      // this.TEXTAREA.value = this.originalValue;
      // Handsontable.dom.empty(this.TEXTAREA_PARENT);
      // Handsontable.dom.addClass(this.TEXTAREA_PARENT, 'handsontableInputHolder');
      // this.TEXTAREA_PARENT.appendChild(this.TEXTAREA);
    }
  }
}

export default CustomEditor;
