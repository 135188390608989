import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Segment, List } from 'semantic-ui-react';
import { format } from 'date-fns';
import { Bar } from 'react-chartjs-2';

import { useTranslation } from 'react-i18next';
import HeaderContainer from '../../containers/HeaderContainer';
import BusinessContainer from '../../containers/BusinessContainer';
import RevenueItem from './RevenueItem';
import CardInfo from './CardInfo';
import ChartFilter from './ChartFilter';
import DateFilter from './DateFilter';
import Label from '../Label';
import { Statuses } from '../../constants/Enums';

import styles from './Dashboard.module.scss';

const Dashboard = ({
  dashboard,
  isFetching,
  accounts,
  businessId,
  currentGroup,
  onfetchDashboard,
}) => {
  const [t] = useTranslation();

  const [time, setTime] = useState('date');
  const [chartType, setChartType] = useState('bar');
  const [label, setLabel] = useState([]);
  const [labels, setLabels] = useState([]);
  const [dataValue, setDataValue] = useState([]);
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);

  const onChangeFilterTime = useCallback(
    (type) => {
      setTime(type);
    },
    [setTime],
  );

  const onChangeChartType = useCallback(
    (type) => {
      setChartType(type);
    },
    [setChartType],
  );

  const handleChangeDate = useCallback(
    (data) => {
      setDateRange(data);
      if (data[0] && data[1]) {
        const startDate = new Date(data[0]);
        startDate.setHours(0, 0, 0);
        const startDateStr = format(startDate, 'yyyy-MM-dd HH:mm:ss');

        const endDate = new Date(data[1]);
        endDate.setHours(23, 59, 59);
        const endDateStr = format(endDate, 'yyyy-MM-dd HH:mm:ss');

        onfetchDashboard(startDateStr, endDateStr);
      }
    },
    [onfetchDashboard],
  );

  const data = {
    labels,
    datasets: [
      {
        type: chartType,
        barThickness: 10,
        label: t('common.dashboard.quantityProduct'),
        data: dataValue,
        backgroundColor: '#14B8A6',
        borderColor: '#14B8A6',
        stack: 'Stack 0',
      },
    ],
  };

  const options = {
    scales: {
      y: {
        max: Math.round(Math.max(...dataValue) * 1.35) || 20,
        min: 0,
      },
    },
    plugins: {
      legend: false,
    },
  };

  // ----- Chart - total number of items
  useEffect(() => {
    // Day chart
    if (time === 'date' && dashboard && dashboard.last30Days) {
      const chartLabels = [];
      const chartData = [];
      for (let i = 0; i < 30; i += 1) {
        chartLabels.push(format(new Date(new Date().setDate(new Date().getDate() - i)), 'dd/MM'));
        const dayValue = dashboard.last30Days.find(
          (day) =>
            day.day ===
            format(new Date(new Date().setDate(new Date().getDate() - i)), 'yyyy-MM-dd'),
        );
        chartData.push(dayValue?.quantity || 0);
      }
      setLabels(chartLabels.reverse());
      setDataValue(chartData.reverse());
      const chartLabel = `${t('common.dashboard.quantityProduct')} (${format(
        new Date(new Date().setDate(new Date().getDate() - 29)),
        'dd/MM/yyyy',
      )} - ${format(new Date(), 'dd/MM/yyyy')})`;
      setLabel(chartLabel);

      return;
    }

    // Month chart
    if (time === 'month' && dashboard && dashboard.last12Months) {
      const chartLabels = [];
      const chartData = [];
      for (let i = 0; i < 12; i += 1) {
        chartLabels.push(
          format(new Date(new Date().setMonth(new Date().getMonth() - i)), 'MM/yyyy'),
        );
        const dayValue = dashboard.last12Months.find(
          (day) =>
            day.yearmonth ===
            format(new Date(new Date().setMonth(new Date().getMonth() - i)), 'yyyy-MM'),
        );
        chartData.push(dayValue?.quantity || 0);
      }
      setLabels(chartLabels.reverse());
      setDataValue(chartData.reverse());
      const chartLabel = `${t('common.dashboard.quantityProduct')} (${format(
        new Date(new Date().setMonth(new Date().getMonth() - 11)),
        'MM/yyyy',
      )} - ${format(new Date(), 'MM/yyyy')})`;
      setLabel(chartLabel);
    }
  }, [dashboard, t, time]);

  const totalSellitemsAmountDone =
    dashboard?.sellitems?.find((si) => si.status === Statuses.FINISH.name)?.sell_total * 1 || '-';
  const totalSellitemsDone =
    dashboard?.sellitems?.find((si) => si.status === Statuses.FINISH.name)?.count * 1 || '-';

  return (
    <>
      {currentGroup && !currentGroup.isFetching && (
        <>
          <HeaderContainer />
          {businessId && <BusinessContainer />}
          {dashboard && !isFetching && (
            <div className={styles.dashboardWrapper}>
              <Grid>
                <Grid.Row>
                  <Grid.Column mobile={16} tablet={5} computer={4}>
                    <Segment className={styles.sidebarLeftFilter}>
                      <div className={styles.titleInterval}>{t('common.dashboard.interval')}</div>
                      <DateFilter
                        defautDateRange={dateRange}
                        onChangePeriod={handleChangeDate}
                        onChangeLastPeriod={onChangeChartType}
                      />
                    </Segment>
                    <Segment className={styles.sidebarLeftContent}>
                      <RevenueItem revenueCountries={dashboard.countriesByTimeRange || []} />
                    </Segment>
                  </Grid.Column>
                  <Grid.Column
                    mobile={16}
                    tablet={11}
                    computer={12}
                    className={styles.dashboardContent}
                  >
                    <Grid>
                      <Grid.Row>
                        <Grid.Column
                          mobile={16}
                          tablet={8}
                          computer={8}
                          largeScreen={4}
                          widescreen={4}
                        >
                          <CardInfo
                            title={t('common.dashboard.totalSellitem')}
                            icon="shopping cart"
                            value={(dashboard.statistic.sellitemsCount * 1).toLocaleString('en-GB')}
                            percent=""
                            color="#F97316"
                            background="#FFEDD5"
                          />
                        </Grid.Column>
                        <Grid.Column
                          mobile={16}
                          tablet={8}
                          computer={8}
                          largeScreen={4}
                          widescreen={4}
                        >
                          <CardInfo
                            title={t('common.dashboard.totalAmountOrder')}
                            icon="dollar"
                            value={(dashboard.statistic.sellTotal * 1).toLocaleString('en-GB')}
                            percent=""
                            color="#14B8A6"
                            background="#CCFBF1"
                          />
                        </Grid.Column>
                        <Grid.Column
                          mobile={16}
                          tablet={8}
                          computer={8}
                          largeScreen={4}
                          widescreen={4}
                        >
                          <CardInfo
                            title={t('common.dashboard.totalProfit')}
                            icon="dollar"
                            value={(dashboard.statistic.profitTotal * 1).toLocaleString('en-GB')}
                            percent=""
                            color="#EAB308"
                            background="#FEF9C3"
                          />
                        </Grid.Column>
                        <Grid.Column
                          mobile={16}
                          tablet={8}
                          computer={8}
                          largeScreen={4}
                          widescreen={4}
                        >
                          <CardInfo
                            title={t('common.dashboard.totalAmountPurchased')}
                            icon="dollar"
                            value={(dashboard.statistic.purchaseTotal * 1).toLocaleString('en-GB')}
                            percent=""
                            color="#3B82F6"
                            background="#DBEAFE"
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                    <Grid className={styles.dashboardContentList}>
                      <Grid.Row>
                        <Grid.Column
                          mobile={16}
                          tablet={8}
                          computer={8}
                          largeScreen={4}
                          widescreen={4}
                        >
                          <Segment className={styles.listWrapper}>
                            <h3 className={styles.listHeader}>
                              {t('common.dashboard.orderStatus')}
                            </h3>
                            <List className={styles.wrapperStatuses}>
                              {Object.values(Statuses).map((st) => {
                                const statusCount = dashboard.sellitems.find(
                                  (si) => si.status === st.name,
                                );
                                return (
                                  <List.Item key={st.name} className={styles.listItem}>
                                    <List.Content className={styles.listValue} floated="right">
                                      {statusCount?.count || '-'}
                                    </List.Content>
                                    <List.Content>
                                      <Label
                                        color={st.color}
                                        name={t(`status.${st.name}`)}
                                        size="small"
                                      />
                                    </List.Content>
                                  </List.Item>
                                );
                              })}
                            </List>
                          </Segment>
                        </Grid.Column>
                        <Grid.Column
                          className={styles.sidebarLeftGrid}
                          mobile={16}
                          tablet={8}
                          computer={8}
                          largeScreen={4}
                          widescreen={4}
                        >
                          <Segment className={styles.sidebarLeftContentMobile}>
                            <RevenueItem revenueCountries={dashboard.countriesByTimeRange || []} />
                          </Segment>
                        </Grid.Column>
                        <Grid.Column
                          mobile={16}
                          tablet={8}
                          computer={8}
                          largeScreen={4}
                          widescreen={4}
                        >
                          <Segment className={styles.listWrapper}>
                            <h3 className={styles.listHeader}>
                              {t('common.dashboard.productShip')}
                            </h3>
                            <List className={styles.wrapperCustomers}>
                              <List.Item className={styles.listItem}>
                                <List.Content className={styles.listValue} floated="right">
                                  {totalSellitemsDone.toLocaleString('en-GB')}
                                </List.Content>
                                <List.Content>
                                  {t('common.dashboard.totalSellitemsDone')}
                                </List.Content>
                              </List.Item>
                              <List.Item className={styles.listItem}>
                                <List.Content className={styles.listValue} floated="right">
                                  {totalSellitemsAmountDone.toLocaleString('en-GB')}
                                </List.Content>
                                <List.Content>
                                  {t('common.dashboard.totalSellitemsAmountDone')}
                                </List.Content>
                              </List.Item>
                            </List>
                            <List
                              className={`${styles.wrapperCustomers} ${styles.listSellitemsAmountDone}`}
                            >
                              {accounts.map((ac) => {
                                const payment = dashboard.finishSellitems.find(
                                  (fsi) => fsi.account_id === ac.id,
                                )?.payment;
                                return (
                                  <List.Item key={ac.id} className={styles.listItem}>
                                    <List.Content className={styles.listValue} floated="right">
                                      {payment ? (payment * 1).toLocaleString('en-GB') : '-'}
                                    </List.Content>
                                    <List.Content>- {ac.name}</List.Content>
                                  </List.Item>
                                );
                              })}
                            </List>
                          </Segment>
                        </Grid.Column>
                        <Grid.Column
                          mobile={16}
                          tablet={8}
                          computer={8}
                          largeScreen={4}
                          widescreen={4}
                        >
                          <Segment className={styles.listWrapper}>
                            <h3 className={styles.listHeader}>{`${t(
                              'common.dashboard.customer',
                            )} (${t('common.dashboard.selectedTime')})`}</h3>
                            <List className={styles.wrapperCustomers}>
                              <List.Item className={styles.listItem}>
                                <List.Content className={styles.listValue} floated="right">
                                  {dashboard.topCustomersByTimeRange?.total.customer
                                    ? (
                                        dashboard.topCustomersByTimeRange?.total.customer * 1
                                      ).toLocaleString('en-GB')
                                    : '-'}
                                </List.Content>
                                <List.Content>
                                  {t('common.dashboard.totalNewCustomers')}
                                </List.Content>
                              </List.Item>
                              <List.Item className={styles.listItem}>
                                <List.Content className={styles.listValue} floated="right">
                                  {dashboard.topCustomersByTimeRange?.total.payment
                                    ? (
                                        dashboard.topCustomersByTimeRange?.total.payment * 1
                                      ).toLocaleString('en-GB')
                                    : '-'}
                                </List.Content>
                                <List.Content>
                                  {t('common.dashboard.totalCustomersPaid')}
                                </List.Content>
                              </List.Item>
                              <List.Item className={styles.listItem}>
                                <List.Content className={styles.listValue} floated="right">
                                  {dashboard.topCustomersByTimeRange?.total.total
                                    ? (
                                        (dashboard.topCustomersByTimeRange?.total.total -
                                          dashboard.topCustomersByTimeRange?.total.payment) *
                                        1
                                      ).toLocaleString('en-GB')
                                    : '-'}
                                </List.Content>
                                <List.Content>
                                  {t('common.dashboard.totalRemainingOfCustomer')}
                                </List.Content>
                              </List.Item>
                            </List>
                            <h3 className={styles.listHeader}>
                              {t('common.dashboard.topCustomer')}
                            </h3>
                            <List className={styles.wrapperCustomers}>
                              <List.Item className={styles.listItem}>
                                <List.Content className={styles.listValue} floated="right">
                                  <a
                                    className={styles.customerName}
                                    href={dashboard.topCustomersByTimeRange?.customer?.facebook}
                                  >
                                    {dashboard.topCustomersByTimeRange?.customer?.name || '-'}
                                  </a>
                                  <br />
                                  {dashboard.topCustomersByTimeRange?.customer?.code
                                    ? `(${dashboard.topCustomersByTimeRange.customer.code})`
                                    : ''}
                                </List.Content>
                                <List.Content>{t('common.dashboard.customerId')}</List.Content>
                              </List.Item>
                              <List.Item className={styles.listItem}>
                                <List.Content className={styles.listValue} floated="right">
                                  {dashboard.topCustomersByTimeRange?.customer?.quantity
                                    ? (
                                        dashboard.topCustomersByTimeRange.customer.quantity * 1
                                      ).toLocaleString('en-GB')
                                    : '-'}
                                </List.Content>
                                <List.Content>
                                  {t('common.dashboard.quantitySellitem')}
                                </List.Content>
                              </List.Item>
                              <List.Item className={styles.listItem}>
                                <List.Content className={styles.listValue} floated="right">
                                  {dashboard.topCustomersByTimeRange?.customer?.total
                                    ? (
                                        dashboard.topCustomersByTimeRange.customer.total * 1
                                      ).toLocaleString('en-GB')
                                    : '-'}
                                </List.Content>
                                <List.Content>{t('common.dashboard.totalAmount')}</List.Content>
                              </List.Item>
                            </List>
                          </Segment>
                        </Grid.Column>
                        <Grid.Column
                          mobile={16}
                          tablet={8}
                          computer={8}
                          largeScreen={4}
                          widescreen={4}
                        >
                          <Segment className={styles.listWrapper}>
                            <h3 className={styles.listHeader}>{`${t(
                              'common.dashboard.customer',
                            )} (${t('common.dashboard.allTime')})`}</h3>
                            <List className={styles.wrapperCustomers}>
                              <List.Item className={styles.listItem}>
                                <List.Content className={styles.listValue} floated="right">
                                  {dashboard.topCustomers?.total.customer
                                    ? (dashboard.topCustomers?.total.customer * 1).toLocaleString(
                                        'en-GB',
                                      )
                                    : '-'}
                                </List.Content>
                                <List.Content>{t('common.dashboard.totalCustomers')}</List.Content>
                              </List.Item>
                              <List.Item className={styles.listItem}>
                                <List.Content className={styles.listValue} floated="right">
                                  {dashboard.topCustomers?.total.payment
                                    ? (dashboard.topCustomers?.total.payment * 1).toLocaleString(
                                        'en-GB',
                                      )
                                    : '-'}
                                </List.Content>
                                <List.Content>
                                  {t('common.dashboard.totalCustomersPaid')}
                                </List.Content>
                              </List.Item>
                              <List.Item className={styles.listItem}>
                                <List.Content className={styles.listValue} floated="right">
                                  {dashboard.topCustomers?.total.total
                                    ? (
                                        (dashboard.topCustomers?.total.total -
                                          dashboard.topCustomers?.total.payment) *
                                        1
                                      ).toLocaleString('en-GB')
                                    : '-'}
                                </List.Content>
                                <List.Content>
                                  {t('common.dashboard.totalRemainingOfCustomer')}
                                </List.Content>
                              </List.Item>
                            </List>
                            <h3 className={styles.listHeader}>
                              {t('common.dashboard.topCustomer')}
                            </h3>
                            <List className={styles.wrapperCustomers}>
                              <List.Item className={styles.listItem}>
                                <List.Content className={styles.listValue} floated="right">
                                  <a
                                    className={styles.customerName}
                                    href={dashboard.topCustomers?.customer?.facebook}
                                  >
                                    {dashboard.topCustomers?.customer?.name || '-'}
                                  </a>
                                  <br />
                                  {dashboard.topCustomers?.customer?.code
                                    ? `(${dashboard.topCustomers?.customer?.code})`
                                    : ''}
                                </List.Content>
                                <List.Content>{t('common.dashboard.customerId')}</List.Content>
                              </List.Item>
                              <List.Item className={styles.listItem}>
                                <List.Content className={styles.listValue} floated="right">
                                  {dashboard.topCustomers?.customer?.quantity
                                    ? (
                                        dashboard.topCustomers?.customer?.quantity * 1
                                      ).toLocaleString('en-GB')
                                    : '-'}
                                </List.Content>
                                <List.Content>
                                  {t('common.dashboard.quantitySellitem')}
                                </List.Content>
                              </List.Item>
                              <List.Item className={styles.listItem}>
                                <List.Content className={styles.listValue} floated="right">
                                  {dashboard.topCustomers?.customer?.total
                                    ? (dashboard.topCustomers?.customer?.total * 1).toLocaleString(
                                        'en-GB',
                                      )
                                    : '-'}
                                </List.Content>
                                <List.Content>{t('common.dashboard.totalAmount')}</List.Content>
                              </List.Item>
                            </List>
                          </Segment>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                    <Grid className={styles.gridChart}>
                      <Grid.Row className={styles.lastRow}>
                        <Grid.Column width={16}>
                          <Segment className={styles.graphWrapper}>
                            <h3 className={styles.titleGraph}>
                              {t('common.dashboard.totalSellitem')}
                            </h3>
                            <ChartFilter
                              time={time}
                              chartType={chartType}
                              filterTime={(type) => onChangeFilterTime(type)}
                              filterChartType={(type) => onChangeChartType(type)}
                            />
                            <div className={styles.chartLabel}>
                              <span className={styles.chartLabelItem}>{label}</span>
                            </div>
                            <Bar data={data} options={options} />
                          </Segment>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
          )}
        </>
      )}
    </>
  );
};

Dashboard.propTypes = {
  dashboard: PropTypes.objectOf(PropTypes.any),
  isFetching: PropTypes.bool.isRequired,
  accounts: PropTypes.arrayOf(PropTypes.any),
  businessId: PropTypes.string,
  currentGroup: PropTypes.objectOf(PropTypes.any),
  onfetchDashboard: PropTypes.func.isRequired,
};

Dashboard.defaultProps = {
  dashboard: undefined,
  accounts: undefined,
  businessId: undefined,
  currentGroup: undefined,
};

export default Dashboard;
