import { call, put, select } from 'redux-saga/effects';

import { goToBusinessService } from './router';
import request from '../request';
import { pathSelector } from '../../../selectors';
import {
  createCategory,
  deleteCategory,
  handleCategoryCreate,
  handleCategoryDelete,
  handleCategoryUpdate,
  updateCategory,
  addCategoryToGroupFilter,
  removeCategoryFromGroupFilter,
} from '../../../actions';
import api from '../../../api';
import { createLocalId } from '../../../utils/local-id';

export function* createCategoryService(businessId, data) {
  const nextData = {
    ...data,
  };

  const localId = yield call(createLocalId);

  yield put(
    createCategory({
      ...nextData,
      businessId,
      id: localId,
    }),
  );

  let category;

  try {
    ({ item: category } = yield call(request, api.createCategory, businessId, nextData));
  } catch (error) {
    yield put(createCategory.failure(localId, error));
    return;
  }

  yield put(createCategory.success(localId, category));
}

export function* createCategoryInCurrentBusinessService(data) {
  const { businessId } = yield select(pathSelector);

  yield call(createCategoryService, businessId, data);
}

export function* handleCategoryCreateService(category) {
  yield put(handleCategoryCreate(category));
}

export function* updateCategoryService(id, data) {
  yield put(updateCategory(id, data));

  let category;
  try {
    ({ item: category } = yield call(request, api.updateCategory, id, data));
  } catch (error) {
    yield put(updateCategory.failure(id, error));
    return;
  }

  yield put(updateCategory.success(category));
}

export function* handleCategoryUpdateService(category) {
  yield put(handleCategoryUpdate(category));
}

export function* deleteCategoryService(id) {
  const { categoryId, businessId } = yield select(pathSelector);

  if (id === categoryId) {
    yield call(goToBusinessService, businessId);
  }

  yield put(deleteCategory(id));

  let category;
  try {
    ({ item: category } = yield call(request, api.deleteCategory, id));
  } catch (error) {
    yield put(deleteCategory.failure(id, error));
    return;
  }

  yield put(deleteCategory.success(category));
}

export function* handleCategoryDeleteService(category) {
  const { categoryId, businessId } = yield select(pathSelector);

  if (category.id === categoryId) {
    yield call(goToBusinessService, businessId);
  }

  yield put(handleCategoryDelete(category));
}

export function* addCategoryToGroupFilterService(id, groupId) {
  yield put(addCategoryToGroupFilter(id, groupId));
}

export function* addCategoryToFilterInCurrentGroupService(id) {
  const { groupId } = yield select(pathSelector);

  yield call(addCategoryToGroupFilterService, id, groupId);
}

export function* removeCategoryFromGroupFilterService(id, groupId) {
  yield put(removeCategoryFromGroupFilter(id, groupId));
}

export function* removeCategoryFromFilterInCurrentGroupService(id) {
  const { groupId } = yield select(pathSelector);

  yield call(removeCategoryFromGroupFilterService, id, groupId);
}
