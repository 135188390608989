import http from './http';
import socket from './socket';
import accessTokens from './access-tokens';
import accessTokensImgur from './access-tokens-imgur';
import users from './users';
import businesses from './businesses';
import businessManagers from './business-managers';
import countries from './countries';
import brands from './brands';
import accounts from './accounts';
import customerGroups from './customer-groups';
import groups from './groups';
import groupMemberships from './group-memberships';
import labels from './labels';
import customers from './customers';
import addresses from './addresses';
import products from './products';
import categories from './categories';
import productCategories from './product-categories';
import shipments from './shipments';
import shipline from './shipline';
import sellitems from './sellitems';
import sellitemMemberships from './sellitem-memberships';
import sellitemLabels from './sellitem-labels';
import payments from './payments';
import attachments from './attachments';
import actions from './actions';
import commentActions from './comment-actions';
import notifications from './notifications';
import stocks from './stocks';
import dashboard from './dashboard';
import facebook from './facebook';

export { http, socket };

export default {
  ...accessTokens,
  ...accessTokensImgur,
  ...users,
  ...businesses,
  ...businessManagers,
  ...countries,
  ...brands,
  ...accounts,
  ...customerGroups,
  ...groups,
  ...groupMemberships,
  ...labels,
  ...customers,
  ...addresses,
  ...products,
  ...categories,
  ...productCategories,
  ...shipments,
  ...shipline,
  ...sellitems,
  ...sellitemMemberships,
  ...sellitemLabels,
  ...payments,
  ...attachments,
  ...actions,
  ...commentActions,
  ...notifications,
  ...stocks,
  ...dashboard,
  ...facebook,
};
