import { eventChannel } from 'redux-saga';
import { all, call, cancelled, put, take, takeEvery } from 'redux-saga/effects';

import { handleSocketDisconnectService, handleSocketReconnectService } from '../services';
import {
  handleBusinessManagerCreate as handleBusinessManagerCreateAction,
  handleBusinessManagerDelete as handleBusinessManagerDeleteAction,
  handleCountryCreate as handleCountryCreateAction,
  handleCountryUpdate as handleCountryUpdateAction,
  handleCountryDelete as handleCountryDeleteAction,
  handleBrandCreate as handleBrandCreateAction,
  handleBrandUpdate as handleBrandUpdateAction,
  handleBrandDelete as handleBrandDeleteAction,
  handleAccountCreate as handleAccountCreateAction,
  handleAccountUpdate as handleAccountUpdateAction,
  handleAccountDelete as handleAccountDeleteAction,
  handleCustomerGroupCreate as handleCustomerGroupCreateAction,
  handleCustomerGroupUpdate as handleCustomerGroupUpdateAction,
  handleCustomerGroupDelete as handleCustomerGroupDeleteAction,
  handleGroupCreate as handleGroupCreateAction,
  handleGroupUpdate as handleGroupUpdateAction,
  handleGroupDelete as handleGroupDeleteAction,
  handleGroupMembershipCreate as handleGroupMembershipCreateAction,
  handleGroupMembershipDelete as handleGroupMembershipDeleteAction,
  handleCustomerCreate as handleCustomerCreateAction,
  handleCustomerUpdate as handleCustomerUpdateAction,
  handleCustomerDelete as handleCustomerDeleteAction,
  handleProductCreate as handleProductCreateAction,
  handleProductUpdate as handleProductUpdateAction,
  handleProductDelete as handleProductDeleteAction,
  handleLabelCreate as handleLabelCreateAction,
  handleLabelUpdate as handleLabelUpdateAction,
  handleLabelDelete as handleLabelDeleteAction,
  handleSellitemCreate as handleSellitemCreateAction,
  handleSellitemUpdate as handleSellitemUpdateAction,
  handleSellitemsUpdate as handleSellitemsUpdateAction,
  handleSellitemDelete as handleSellitemDeleteAction,
  handleUserToSellitemAdd as handleUserToSellitemAddAction,
  handleUserFromSellitemRemove as handleUserFromSellitemRemoveAction,
  handleLabelToSellitemAdd as handleLabelToSellitemAddAction,
  handleLabelFromSellitemRemove as handleLabelFromSellitemRemoveAction,
  handlePaymentCreate as handlePaymentCreateAction,
  handlePaymentUpdate as handlePaymentUpdateAction,
  handlePaymentDelete as handlePaymentDeleteAction,
  handleAttachmentCreate as handleAttachmentCreateAction,
  handleAttachmentUpdate as handleAttachmentUpdateAction,
  handleAttachmentDelete as handleAttachmentDeleteAction,
  handleActionCreate as handleActionCreateAction,
  handleActionUpdate as handleActionUpdateAction,
  handleActionDelete as handleActionDeleteAction,
  handleNotificationCreate as handleNotificationCreateAction,
  handleNotificationDelete as handleNotificationDeleteAction,
  handleSocketDisconnect as handleSocketDisconnectAction,
  handleUserCreate as handleUserCreateAction,
  handleUserUpdate as handleUserUpdateAction,
  handleUserDelete as handleUserDeleteAction,
  handleBusinessCreate as handleBusinessCreateAction,
  handleBusinessUpdate as handleBusinessUpdateAction,
  handleBusinessDelete as handleBusinessDeleteAction,
  handleSocketReconnect as handleSocketReconnectAction,
  handleShipmentCreate as handleShipmentCreateAction,
  handleShipmentUpdate as handleShipmentUpdateAction,
  handleShipmentDelete as handleShipmentDeleteAction,
} from '../../../actions/entry';
import api, { socket } from '../../../api';
import EntryActionTypes from '../../../constants/EntryActionTypes';

const createSocketEventsChannel = () =>
  eventChannel((emit) => {
    const handleDisconnect = () => {
      emit(handleSocketDisconnectAction());
    };

    const handleReconnect = () => {
      emit(handleSocketReconnectAction());
    };

    const handleUserCreate = ({ item }) => {
      emit(handleUserCreateAction(item));
    };

    const handleUserUpdate = ({ item }) => {
      emit(handleUserUpdateAction(item));
    };

    const handleUserDelete = ({ item }) => {
      emit(handleUserDeleteAction(item));
    };

    const handleProductCreate = ({ item }) => {
      emit(handleProductCreateAction(item));
    };

    const handleProductUpdate = ({ item }) => {
      emit(handleProductUpdateAction(item));
    };

    const handleProductDelete = ({ item }) => {
      emit(handleProductDeleteAction(item));
    };

    const handleBusinessCreate = ({ item }) => {
      emit(handleBusinessCreateAction(item));
    };

    const handleBusinessUpdate = ({ item }) => {
      emit(handleBusinessUpdateAction(item));
    };

    const handleBusinessDelete = ({ item }) => {
      emit(handleBusinessDeleteAction(item));
    };

    const handleBusinessManagerCreate = ({ item }) => {
      emit(handleBusinessManagerCreateAction(item));
    };

    const handleBusinessManagerDelete = ({ item }) => {
      emit(handleBusinessManagerDeleteAction(item));
    };

    const handleCountryCreate = ({ item }) => {
      emit(handleCountryCreateAction(item));
    };

    const handleCountryUpdate = ({ item }) => {
      emit(handleCountryUpdateAction(item));
    };

    const handleCountryDelete = ({ item }) => {
      emit(handleCountryDeleteAction(item));
    };

    const handleBrandCreate = ({ item }) => {
      emit(handleBrandCreateAction(item));
    };

    const handleBrandUpdate = ({ item }) => {
      emit(handleBrandUpdateAction(item));
    };

    const handleBrandDelete = ({ item }) => {
      emit(handleBrandDeleteAction(item));
    };

    const handleAccountCreate = ({ item }) => {
      emit(handleAccountCreateAction(item));
    };

    const handleAccountUpdate = ({ item }) => {
      emit(handleAccountUpdateAction(item));
    };

    const handleAccountDelete = ({ item }) => {
      emit(handleAccountDeleteAction(item));
    };

    const handleCustomerGroupCreate = ({ item }) => {
      emit(handleCustomerGroupCreateAction(item));
    };

    const handleCustomerGroupUpdate = ({ item }) => {
      emit(handleCustomerGroupUpdateAction(item));
    };

    const handleCustomerGroupDelete = ({ item }) => {
      emit(handleCustomerGroupDeleteAction(item));
    };

    const handleGroupCreate = ({ item }) => {
      emit(handleGroupCreateAction(item));
    };

    const handleGroupUpdate = ({ item }) => {
      emit(handleGroupUpdateAction(item));
    };

    const handleGroupDelete = ({ item }) => {
      emit(handleGroupDeleteAction(item));
    };

    const handleGroupMembershipCreate = ({ item }) => {
      emit(handleGroupMembershipCreateAction(item));
    };

    const handleGroupMembershipDelete = ({ item }) => {
      emit(handleGroupMembershipDeleteAction(item));
    };

    const handleCustomerCreate = ({ item }) => {
      emit(handleCustomerCreateAction(item));
    };

    const handleCustomerUpdate = ({ item }) => {
      emit(handleCustomerUpdateAction(item));
    };

    const handleCustomerDelete = ({ item }) => {
      emit(handleCustomerDeleteAction(item));
    };

    const handleLabelCreate = ({ item }) => {
      emit(handleLabelCreateAction(item));
    };

    const handleLabelUpdate = ({ item }) => {
      emit(handleLabelUpdateAction(item));
    };

    const handleLabelDelete = ({ item }) => {
      emit(handleLabelDeleteAction(item));
    };

    const handleSellitemCreate = api.makeHandleSellitemCreate(({ item, included: { product } }) => {
      emit(handleSellitemCreateAction(item, product));
    });

    const handleSellitemUpdate = api.makeHandleSellitemUpdate(({ item }) => {
      emit(handleSellitemUpdateAction(item));
    });

    const handleSellitemsUpdate = api.makeHandleSellitemsUpdate(({ items }) => {
      emit(handleSellitemsUpdateAction(items));
    });

    const handleSellitemDelete = api.makeHandleSellitemDelete(({ item }) => {
      emit(handleSellitemDeleteAction(item));
    });

    const handleUserToSellitemAdd = ({ item }) => {
      emit(handleUserToSellitemAddAction(item));
    };

    const handleUserFromSellitemRemove = ({ item }) => {
      emit(handleUserFromSellitemRemoveAction(item));
    };

    const handleLabelToSellitemAdd = ({ item }) => {
      emit(handleLabelToSellitemAddAction(item));
    };

    const handleLabelFromSellitemRemove = ({ item }) => {
      emit(handleLabelFromSellitemRemoveAction(item));
    };

    const handlePaymentCreate = ({ item }) => {
      emit(handlePaymentCreateAction(item));
    };

    const handlePaymentUpdate = ({ item }) => {
      emit(handlePaymentUpdateAction(item));
    };

    const handlePaymentDelete = ({ item }) => {
      emit(handlePaymentDeleteAction(item));
    };

    const handleShipmentCreate = ({ item }) => {
      emit(handleShipmentCreateAction(item));
    };

    const handleShipmentUpdate = ({ item }) => {
      emit(handleShipmentUpdateAction(item));
    };

    const handleShipmentDelete = ({ item }) => {
      emit(handleShipmentDeleteAction(item));
    };

    const handleAttachmentCreate = api.makeHandleAttachmentCreate(({ item, requestId }) => {
      emit(handleAttachmentCreateAction(item, requestId));
    });

    const handleAttachmentUpdate = api.makeHandleAttachmentUpdate(({ item }) => {
      emit(handleAttachmentUpdateAction(item));
    });

    const handleAttachmentDelete = api.makeHandleAttachmentDelete(({ item }) => {
      emit(handleAttachmentDeleteAction(item));
    });

    const handleActionCreate = api.makeHandleActionCreate(({ item }) => {
      emit(handleActionCreateAction(item));
    });

    const handleActionUpdate = api.makeHandleActionUpdate(({ item }) => {
      emit(handleActionUpdateAction(item));
    });

    const handleActionDelete = api.makeHandleActionDelete(({ item }) => {
      emit(handleActionDeleteAction(item));
    });

    const handleNotificationCreate = ({ item }) => {
      emit(handleNotificationCreateAction(item));
    };

    const handleNotificationDelete = ({ item }) => {
      emit(handleNotificationDeleteAction(item));
    };

    socket.on('disconnect', handleDisconnect);
    socket.on('reconnect', handleReconnect);

    socket.on('userCreate', handleUserCreate);
    socket.on('userUpdate', handleUserUpdate);
    socket.on('userDelete', handleUserDelete);

    socket.on('businessCreate', handleBusinessCreate);
    socket.on('businessUpdate', handleBusinessUpdate);
    socket.on('businessDelete', handleBusinessDelete);

    socket.on('businessManagerCreate', handleBusinessManagerCreate);
    socket.on('businessManagerDelete', handleBusinessManagerDelete);

    socket.on('countryCreate', handleCountryCreate);
    socket.on('countryUpdate', handleCountryUpdate);
    socket.on('countryDelete', handleCountryDelete);

    socket.on('brandCreate', handleBrandCreate);
    socket.on('brandUpdate', handleBrandUpdate);
    socket.on('brandDelete', handleBrandDelete);

    socket.on('accountCreate', handleAccountCreate);
    socket.on('accountUpdate', handleAccountUpdate);
    socket.on('accountDelete', handleAccountDelete);

    socket.on('customerGroupCreate', handleCustomerGroupCreate);
    socket.on('customerGroupUpdate', handleCustomerGroupUpdate);
    socket.on('customerGroupDelete', handleCustomerGroupDelete);

    socket.on('groupCreate', handleGroupCreate);
    socket.on('groupUpdate', handleGroupUpdate);
    socket.on('groupDelete', handleGroupDelete);

    socket.on('groupMembershipCreate', handleGroupMembershipCreate);
    socket.on('groupMembershipDelete', handleGroupMembershipDelete);

    socket.on('customerCreate', handleCustomerCreate);
    socket.on('customerUpdate', handleCustomerUpdate);
    socket.on('customerDelete', handleCustomerDelete);

    socket.on('labelCreate', handleLabelCreate);
    socket.on('labelUpdate', handleLabelUpdate);
    socket.on('labelDelete', handleLabelDelete);

    socket.on('productCreate', handleProductCreate);
    socket.on('productUpdate', handleProductUpdate);
    socket.on('productDelete', handleProductDelete);

    socket.on('sellitemCreate', handleSellitemCreate);
    socket.on('sellitemUpdate', handleSellitemUpdate);
    socket.on('sellitemsUpdate', handleSellitemsUpdate);
    socket.on('sellitemDelete', handleSellitemDelete);

    socket.on('sellitemMembershipCreate', handleUserToSellitemAdd);
    socket.on('sellitemMembershipDelete', handleUserFromSellitemRemove);

    socket.on('sellitemLabelCreate', handleLabelToSellitemAdd);
    socket.on('sellitemLabelDelete', handleLabelFromSellitemRemove);

    socket.on('paymentCreate', handlePaymentCreate);
    socket.on('paymentUpdate', handlePaymentUpdate);
    socket.on('paymentDelete', handlePaymentDelete);

    socket.on('shipmentCreate', handleShipmentCreate);
    socket.on('shipmentUpdate', handleShipmentUpdate);
    socket.on('shipmentDelete', handleShipmentDelete);

    socket.on('attachmentCreate', handleAttachmentCreate);
    socket.on('attachmentUpdate', handleAttachmentUpdate);
    socket.on('attachmentDelete', handleAttachmentDelete);

    socket.on('actionCreate', handleActionCreate);
    socket.on('actionUpdate', handleActionUpdate);
    socket.on('actionDelete', handleActionDelete);

    socket.on('notificationCreate', handleNotificationCreate);
    socket.on('notificationUpdate', handleNotificationDelete);

    return () => {
      socket.off('disconnect', handleDisconnect);
      socket.off('reconnect', handleReconnect);

      socket.off('userCreate', handleUserCreate);
      socket.off('userUpdate', handleUserUpdate);
      socket.off('userDelete', handleUserDelete);

      socket.off('businessCreate', handleBusinessCreate);
      socket.off('businessUpdate', handleBusinessUpdate);
      socket.off('businessDelete', handleBusinessDelete);

      socket.off('businessManagerCreate', handleBusinessManagerCreate);
      socket.off('businessManagerDelete', handleBusinessManagerDelete);

      socket.off('countryCreate', handleCountryCreate);
      socket.off('countryUpdate', handleCountryUpdate);
      socket.off('countryDelete', handleCountryDelete);

      socket.off('brandCreate', handleBrandCreate);
      socket.off('brandUpdate', handleBrandUpdate);
      socket.off('brandDelete', handleBrandDelete);

      socket.off('accountCreate', handleAccountCreate);
      socket.off('accountUpdate', handleAccountUpdate);
      socket.off('accountDelete', handleAccountDelete);

      socket.off('customerGroupCreate', handleCustomerGroupCreate);
      socket.off('customerGroupUpdate', handleCustomerGroupUpdate);
      socket.off('customerGroupDelete', handleCustomerGroupDelete);

      socket.off('groupCreate', handleGroupCreate);
      socket.off('groupUpdate', handleGroupUpdate);
      socket.off('groupDelete', handleGroupDelete);

      socket.off('groupMembershipCreate', handleGroupMembershipCreate);
      socket.off('groupMembershipDelete', handleGroupMembershipDelete);

      socket.off('customerCreate', handleCustomerCreate);
      socket.off('customerUpdate', handleCustomerUpdate);
      socket.off('customerDelete', handleCustomerDelete);

      socket.off('productCreate', handleProductCreate);
      socket.off('productUpdate', handleProductUpdate);
      socket.off('productDelete', handleProductDelete);

      socket.off('labelCreate', handleLabelCreate);
      socket.off('labelUpdate', handleLabelUpdate);
      socket.off('labelDelete', handleLabelDelete);

      socket.off('sellitemCreate', handleSellitemCreate);
      socket.off('sellitemUpdate', handleSellitemUpdate);
      socket.off('sellitemsUpdate', handleSellitemsUpdate);
      socket.off('sellitemDelete', handleSellitemDelete);

      socket.off('sellitemMembershipCreate', handleUserToSellitemAdd);
      socket.off('sellitemMembershipDelete', handleUserFromSellitemRemove);

      socket.off('sellitemLabelCreate', handleLabelToSellitemAdd);
      socket.off('sellitemLabelDelete', handleLabelFromSellitemRemove);

      socket.off('paymentCreate', handlePaymentCreate);
      socket.off('paymentUpdate', handlePaymentUpdate);
      socket.off('paymentDelete', handlePaymentDelete);

      socket.off('shipmentCreate', handleShipmentCreate);
      socket.off('shipmentUpdate', handleShipmentUpdate);
      socket.off('shipmentDelete', handleShipmentDelete);

      socket.off('attachmentCreate', handleAttachmentCreate);
      socket.off('attachmentUpdate', handleAttachmentUpdate);
      socket.off('attachmentDelete', handleAttachmentDelete);

      socket.off('actionCreate', handleActionCreate);
      socket.off('actionUpdate', handleActionUpdate);
      socket.off('actionDelete', handleActionDelete);

      socket.off('notificationCreate', handleNotificationCreate);
      socket.off('notificationUpdate', handleNotificationDelete);
    };
  });

export default function* socketWatchers() {
  yield all([
    yield takeEvery(EntryActionTypes.SOCKET_DISCONNECT_HANDLE, () =>
      handleSocketDisconnectService(),
    ),
    yield takeEvery(EntryActionTypes.SOCKET_RECONNECT_HANDLE, () => handleSocketReconnectService()),
  ]);

  const socketEventsChannel = yield call(createSocketEventsChannel);

  try {
    while (true) {
      const action = yield take(socketEventsChannel);

      yield put(action);
    }
  } finally {
    if (yield cancelled()) {
      socketEventsChannel.close();
    }
  }
}
