import socket from './socket';

/* Actions */

const createStock = (productId, data, headers) =>
  socket.post(`/products/${productId}/stocks`, data, headers).then((body) => ({
    ...body,
    item: body.item,
  }));

const updateStock = (id, data, headers) =>
  socket.patch(`/stocks/${id}`, data, headers).then((body) => ({
    ...body,
    item: body.item,
  }));

export default {
  createStock,
  updateStock,
};
