import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Segment, Button } from 'semantic-ui-react';
import classNames from 'classnames';

import Filters from './Filters';
import Memberships from '../Memberships';

import styles from './GroupActions.module.scss';

const GroupActions = React.memo(
  ({
    path,
    memberships,
    brands,
    countries,
    filterUsers,
    filterBrands,
    filterCountries,
    filterStatuses,
    filterShipmentStatuses,
    filterShipProvinces,
    filterOrderDate,
    filterArrivalDate,
    filterArrivalDateForShipmentPage,
    filterWebOrderNo,
    allUsers,
    canEditMemberships,
    onMembershipCreate,
    onMembershipDelete,
    onUserToFilterAdd,
    onUserFromFilterRemove,
    onBrandToFilterAdd,
    onBrandFromFilterRemove,
    onCountryToFilterAdd,
    onCountryFromFilterRemove,
    onOrderDateToFilterChange,
    onArrivalDateToFilterChange,
    onFilterWebOrderNoChange,
    onArrivalDateForShipmentPageToFilterChange,
    onStatusToFilterAdd,
    onStatusFromFilterRemove,
    onShipmentStatusToFilterAdd,
    onShipmentStatusFromFilterRemove,
    onShipProvinceToFilterAdd,
    onShipProvinceFromFilterRemove,
  }) => {
    const [openFilterMenu, setOpenFilterMenu] = useState(false);
    const handleClickMenu = useCallback(() => {
      setOpenFilterMenu(!openFilterMenu);
    }, [openFilterMenu]);
    return (
      <>
        <Button
          className={classNames(styles.buttonFilter, openFilterMenu && styles.buttonAction)}
          primary
          circular
          // className={styles.buttonOpenMobileMenu}
          icon="filter"
          onClick={handleClickMenu}
        />
        <Segment className={classNames(styles.actions, openFilterMenu && styles.menuActive)}>
          <div className={styles.action}>
            <Memberships
              items={memberships}
              allUsers={allUsers}
              canEdit={canEditMemberships}
              onCreate={onMembershipCreate}
              onDelete={onMembershipDelete}
            />
          </div>
          <div className={styles.action}>
            <Filters
              path={path}
              users={filterUsers}
              brands={filterBrands}
              countries={filterCountries}
              statuses={filterStatuses}
              shipmentStatuses={filterShipmentStatuses}
              filterShipProvinces={filterShipProvinces}
              filterOrderDate={filterOrderDate}
              filterArrivalDate={filterArrivalDate}
              filterWebOrderNo={filterWebOrderNo}
              filterArrivalDateForShipmentPage={filterArrivalDateForShipmentPage}
              allGroupMemberships={memberships}
              allBrands={brands}
              allCountries={countries}
              onUserAdd={onUserToFilterAdd}
              onUserRemove={onUserFromFilterRemove}
              onBrandAdd={onBrandToFilterAdd}
              onBrandRemove={onBrandFromFilterRemove}
              onCountryAdd={onCountryToFilterAdd}
              onCountryRemove={onCountryFromFilterRemove}
              onOrderDateChange={onOrderDateToFilterChange}
              onArrivalDateChange={onArrivalDateToFilterChange}
              onFilterWebOrderNoChange={onFilterWebOrderNoChange}
              onArrivalDateChangeForShipmentPage={onArrivalDateForShipmentPageToFilterChange}
              onStatusAdd={onStatusToFilterAdd}
              onStatusRemove={onStatusFromFilterRemove}
              onShipmentStatusAdd={onShipmentStatusToFilterAdd}
              onShipmentStatusRemove={onShipmentStatusFromFilterRemove}
              onShipProvinceAdd={onShipProvinceToFilterAdd}
              onShipProvinceRemove={onShipProvinceFromFilterRemove}
            />
          </div>
          <Button
            className={classNames(styles.buttonMinimize)}
            // primary
            // className={styles.buttonOpenMobileMenu}
            icon="times"
            onClick={handleClickMenu}
          />
        </Segment>
      </>
    );
  },
);

GroupActions.propTypes = {
  path: PropTypes.string.isRequired,
  memberships: PropTypes.arrayOf(PropTypes.any).isRequired,
  brands: PropTypes.arrayOf(PropTypes.any).isRequired,
  countries: PropTypes.arrayOf(PropTypes.any).isRequired,
  filterUsers: PropTypes.arrayOf(PropTypes.any).isRequired,
  filterBrands: PropTypes.arrayOf(PropTypes.any).isRequired,
  filterCountries: PropTypes.arrayOf(PropTypes.any).isRequired,
  filterStatuses: PropTypes.arrayOf(PropTypes.any).isRequired,
  filterShipmentStatuses: PropTypes.arrayOf(PropTypes.any).isRequired,
  filterShipProvinces: PropTypes.arrayOf(PropTypes.any).isRequired,
  filterOrderDate: PropTypes.objectOf(PropTypes.any).isRequired,
  filterArrivalDate: PropTypes.objectOf(PropTypes.any),
  filterWebOrderNo: PropTypes.string,
  filterArrivalDateForShipmentPage: PropTypes.objectOf(PropTypes.any),
  allUsers: PropTypes.arrayOf(PropTypes.any).isRequired,
  canEditMemberships: PropTypes.bool.isRequired,
  onMembershipCreate: PropTypes.func.isRequired,
  onMembershipDelete: PropTypes.func.isRequired,
  onUserToFilterAdd: PropTypes.func.isRequired,
  onUserFromFilterRemove: PropTypes.func.isRequired,
  onBrandToFilterAdd: PropTypes.func.isRequired,
  onBrandFromFilterRemove: PropTypes.func.isRequired,
  onCountryToFilterAdd: PropTypes.func.isRequired,
  onCountryFromFilterRemove: PropTypes.func.isRequired,
  onOrderDateToFilterChange: PropTypes.func.isRequired,
  onArrivalDateToFilterChange: PropTypes.func.isRequired,
  onFilterWebOrderNoChange: PropTypes.func.isRequired,
  onArrivalDateForShipmentPageToFilterChange: PropTypes.func.isRequired,
  onStatusToFilterAdd: PropTypes.func.isRequired,
  onStatusFromFilterRemove: PropTypes.func.isRequired,
  onShipmentStatusToFilterAdd: PropTypes.func.isRequired,
  onShipmentStatusFromFilterRemove: PropTypes.func.isRequired,
  onShipProvinceToFilterAdd: PropTypes.func.isRequired,
  onShipProvinceFromFilterRemove: PropTypes.func.isRequired,
};

GroupActions.defaultProps = {
  filterArrivalDate: undefined,
  filterWebOrderNo: undefined,
  filterArrivalDateForShipmentPage: undefined,
};

export default GroupActions;
