import socket from './socket';

/* Actions */

const createBrand = (businessId, data, headers) =>
  socket.post(`/businesses/${businessId}/brands`, data, headers);

const updateBrand = (id, data, headers) => socket.patch(`/brands/${id}`, data, headers);

const deleteBrand = (id, headers) => socket.delete(`/brands/${id}`, undefined, headers);

export default {
  createBrand,
  updateBrand,
  deleteBrand,
};
