import React from 'react';
import PropTypes from 'prop-types';

// import GroupsContainer from '../../containers/GroupsContainer';
import BusinessSettingsModalContainer from '../../containers/BusinessSettingsModalContainer';

// import styles from './Business.module.scss';

const Business = React.memo(({ isSettingsModalOpened }) => {
  return (
    <>
      {/* <div className={styles.wrapper}>
        <GroupsContainer />
      </div> */}
      {isSettingsModalOpened && <BusinessSettingsModalContainer />}
    </>
  );
});

Business.propTypes = {
  isSettingsModalOpened: PropTypes.bool.isRequired,
};

export default Business;
