import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { format } from 'date-fns';
import {
  currentGroupSelector,
  isCurrentUserMemberForCurrentGroupSelector,
  sellitemsForCurrentGroupSelector,
  pathSelector,
  paymentsBySellitemIdSelector,
  accountsForCurrentBusinessSelector,
  countryByProductIdSelector,
  isCurrentUserManagerForCurrentBusinessSelector,
  isOwnerSelector,
  currentUserSelector,
} from '../selectors';
import {
  fetchSellitemsInCurrentGroup,
  // createCustomerInCurrentGroup,
  // moveCustomer,
  updateSellitems,
  createPayment,
  deleteSellitem,
} from '../actions/entry';
import Sellitems from '../components/Sellitems';
import { CancelStatusNames, PaymentTypes, Statuses } from '../constants/Enums';

const mapStateToProps = (state) => {
  const { sellitemId } = pathSelector(state);
  const isOwner = isOwnerSelector(state);
  const { email } = currentUserSelector(state);
  const isCurrentUserMember = isCurrentUserMemberForCurrentGroupSelector(state);
  const isCurrentUserManager = isCurrentUserManagerForCurrentBusinessSelector(state);
  const accounts = accountsForCurrentBusinessSelector(state);
  let sellitems = sellitemsForCurrentGroupSelector(state);
  const { isSellitemsFetching, isAllSellitemsFetched, stat, currentPage } =
    currentGroupSelector(state);
  sellitems = sellitems.map((si) => {
    const { estimatedArrivalDay } = countryByProductIdSelector(state, si.productId) || {
      estimatedArrivalDay: 0,
    };
    const payments = paymentsBySellitemIdSelector(state, si.id);
    // recalc
    const quantity = si.quantity - si.quantityCancel;
    const subtotal = quantity * si.sellPrice; // Tạm tính
    const total = Math.round(si.webPrice * quantity * (1 - si.webExtra / 100) * si.exchangeRate); // Thành tiền VNĐ
    const finalTotal = si.quantity * si.sellPrice + 1 * si.weightAmount;
    const payment = payments.reduce((paymentSum, pay) => {
      if (pay.type === PaymentTypes.PAYMENT) {
        return paymentSum + parseInt(pay.amount, 10);
      }
      return paymentSum;
    }, 0);

    // process array date
    const createdAt = new Date(si.createdAt);
    createdAt.setDate(createdAt.getDate() + estimatedArrivalDay);
    const notOrder = si.arrivalDate || CancelStatusNames.includes(si.status); // Ngày dự kiến về

    // if (si.product) {
    //   const stocks = stocksByProductIdSelector(state, si.product.id);
    //   if (stocks?.length > 0) console.log(stocks);
    // }

    return {
      ...si,
      isExpArriveDate: notOrder === false, // Hiển thị ngày dự kiến về
      member: si.creator, // users.find((u) => u.id === si.creatorUserId).name,
      imageUrl: si.product?.imageUrl,
      productType: si.product?.type,
      subtotal, // Tạm tính
      // total, // Thành tiền VNĐ [unit_price_before_discount*quantity*(1-line_discount_amount)*exchange_rate]
      diff: subtotal - total, // Chênh lệch [subtotal-total]
      finalTotal,
      payment: payment || null, // Đã cọc
      remainTotal: finalTotal - payment, // Còn lại
      estimatedArrivalDate: createdAt,
      arrivalDate: si.arrivalDate && format(si.arrivalDate, 'd/M/yyyy'),
      status: Statuses[si.status?.toUpperCase()].text,
    };
  });

  return {
    accounts,
    sellitems,
    isSellitemModalOpened: !!sellitemId,
    canEdit: isCurrentUserMember,
    canDelete: isCurrentUserManager,
    isSellitemsFetching,
    isAllSellitemsFetched,
    stat,
    currentPage,
    isOwner: isOwner || email === 'kanri@tipici.vn',
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      // onCustomerCreate: createCustomerInCurrentGroup,
      // onCustomerMove: moveCustomer,
      onSellitemsFetch: (data) => fetchSellitemsInCurrentGroup(data),
      onDeleteSellitem: (id, data) => deleteSellitem(id, data),
      onUpdateSellitems: (id, data) => updateSellitems(id, data),
      onPaymentCreate: (customerId, data) => createPayment(customerId, data),
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Sellitems);
