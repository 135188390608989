import ActionTypes from '../../constants/ActionTypes';

const initialState = {
  data: {
    countryId: 0,
    brandId: 0,
    name: '',
    description: null,
    type: 'Order',
    code: '',
    url: '',
    imageUrl: null,
    color: '',
    size: '',
    unit: null,
    webCode: null,
    webPrice: 0,
    webExtra: 0,
    sellPrice: 0,
    wholesalePrice: 0,
    feeAmount: 0,
    weight: 0,
    weightAmount: 0,
    isWithWeightAmount: false,
  },
  isSubmitting: false,
  error: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.PRODUCT_CREATE:
      return {
        ...state,
        data: {
          ...state.data,
          ...payload.data,
        },
        isSubmitting: true,
      };
    case ActionTypes.PRODUCT_CREATE__SUCCESS:
      return initialState;
    case ActionTypes.PRODUCT_CREATE__FAILURE:
      return {
        ...state,
        isSubmitting: false,
        error: payload.error,
      };
    case ActionTypes.USER_CREATE_ERROR_CLEAR:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
