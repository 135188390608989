import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { BusinessBackgroundTypes } from '../../constants/Enums';

import styles from './Background.module.scss';
import globalStyles from '../../styles.module.scss';

// eslint-disable-next-line no-unused-vars
const Background = ({ type, name, imageUrl }) => (
  <div
    className={classNames(
      styles.wrapper,
      type === BusinessBackgroundTypes.GRADIENT &&
        globalStyles[`background${upperFirst(camelCase(name))}`],
    )}
    // style={{
    //   background: type === 'image' && `url("${imageUrl}") center / cover`,
    // }}
  />
);

Background.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string,
  imageUrl: PropTypes.string,
};

Background.defaultProps = {
  name: undefined,
  imageUrl: undefined,
};

export default Background;
