import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { format } from 'date-fns';

import {
  isCurrentUserMemberForCurrentGroupSelector,
  sellitemsForCurrentGroupSelector,
  paymentsBySellitemIdSelector,
  accountsForCurrentBusinessSelector,
  countryByProductIdSelector,
  shipmentIdsForCurrentGroupSelector,
  shiplinesForShipmentIdSelector,
  currentGroupSelector,
  locationSelector,
} from '../selectors';

import { fetchShipments, updateSellitems } from '../actions/entry';

import { PaymentTypes, Statuses, StatusesShipment } from '../constants/Enums';
import ShipmentPage from '../components/ShipmentPage';

const mapStateToProps = (state) => {
  const isCurrentUserMember = isCurrentUserMemberForCurrentGroupSelector(state);
  const accounts = accountsForCurrentBusinessSelector(state);
  let sellitems = sellitemsForCurrentGroupSelector(state);
  const shipmentIds = shipmentIdsForCurrentGroupSelector(state);
  const { isShipmentsFetching, stat, filterArrivalDateForShipmentPage } =
    currentGroupSelector(state);
  const { query } = locationSelector(state);
  const allShiplines = [];
  let shiplines;
  if (shipmentIds) {
    shipmentIds.forEach((shipmentId) => {
      shiplines = shiplinesForShipmentIdSelector(state, shipmentId);
      shiplines.forEach((shipline) => {
        allShiplines.push(shipline);
      });
    });
  }

  const addedShiplines = [];

  sellitems = sellitems
    // .filter(
    //   (si) =>
    //     si.status === Statuses.ARRIVAL.name ||
    //     si.status === Statuses.DELIVERIED.name ||
    //     si.status === Statuses.FINISH.name,
    // )
    .map((si) => {
      const { estimatedArrivalDay } = countryByProductIdSelector(state, si.productId) || {
        estimatedArrivalDay: 0,
      };
      const payments = paymentsBySellitemIdSelector(state, si.id);
      let paymentMethods = '';

      const total = si.quantity * si.sellPrice + 1 * si.weightAmount;
      const payment = payments.reduce((paymentSum, pay) => {
        if (pay.type === PaymentTypes.PAYMENT) {
          return paymentSum + parseInt(pay.amount, 10);
        }
        return paymentSum;
      }, 0);

      payments.forEach((pay) => {
        const { name } = accounts.find((account) => account.id === pay.accountId);

        if (name) {
          paymentMethods += paymentMethods === '' ? `${name}` : `,${name}`;
        }
      });

      let shipId = '';
      let shipStatus = StatusesShipment.NOTSHIP.name;
      let shippingAmount = 0;
      let isShipProvince = '';
      let estimatedDeliveryDate = '';
      let deliveredDate = '';
      let note = '';
      let shipper = '';

      allShiplines.forEach((shipline) => {
        const sItemId = shipline.sellitemIds?.find((e) => e === si.id);

        if (sItemId) {
          shipId = shipline.shipCode;
          shipStatus = shipline.status;
          isShipProvince = shipline.isShipProvince ? 'Ship tỉnh' : 'Ship nội thành';
          estimatedDeliveryDate =
            shipline.estimatedDeliveryDate &&
            format(new Date(shipline.estimatedDeliveryDate), 'd/M/yyyy');
          deliveredDate =
            shipline.deliveredDate && format(new Date(shipline.deliveredDate), 'd/M/yyyy');
          note = shipline.note;
          shipper = shipline.shipper;

          const firstSellitemInShipline = addedShiplines?.find(
            (e) => e.shipId === shipId && e.customerId === si.customerId,
          );
          if (!firstSellitemInShipline) {
            addedShiplines.push({
              shipId,
              customerId: si.customerId,
            });

            shippingAmount = shipline.shippingAmount;
          }
          return false;
        }

        return true;
      });

      const finalTotal = total + shippingAmount; // Tổng cộng

      // process array date
      const createdAt = new Date(si.createdAt);
      createdAt.setDate(createdAt.getDate() + estimatedArrivalDay);

      return {
        ...si,
        shipId,
        member: si.creator, // users.find((u) => u.id === si.creatorUserId).name,
        imageUrl: si.product?.imageUrl,
        total, // Tiền hàng
        shippingAmount, // Tiền ship
        finalTotal, // Tổng cộng
        paymentMethods,
        payment: payment || null, // Đã cọc
        remainTotal: finalTotal - payment, // Còn lại
        estimatedArrivalDate: createdAt,
        arrivalDate: si.arrivalDate && format(si.arrivalDate, 'd/M/yyyy'),
        status: Statuses[si.status?.toUpperCase()].text,
        shipStatus: StatusesShipment[shipStatus?.toUpperCase()].text,
        isShipProvince,
        estimatedDeliveryDate,
        deliveredDate,
        note,
        shipper,
      };
    });

  return {
    sellitems: sellitems.sort((a, b) => {
      return b.shipId - a.shipId;
    }),
    currentPage: query?.page * 1 || 1,
    arrivalDate: {
      start: query?.arrivalDateStart || filterArrivalDateForShipmentPage.start,
      end: query?.arrivalDateEnd || filterArrivalDateForShipmentPage.end,
    },
    isShipmentsFetching,
    stat,
    canEdit: isCurrentUserMember,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onShipmentsFetch: (data) => fetchShipments(data),
      onUpdateSellitems: (id, data) => updateSellitems(id, data),
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentPage);
