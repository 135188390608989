import { call, put, select } from 'redux-saga/effects';

import { goToBusinessService } from './router';
import request from '../request';
import { pathSelector } from '../../../selectors';
import {
  createCustomerGroup,
  deleteCustomerGroup,
  handleCustomerGroupCreate,
  handleCustomerGroupDelete,
  handleCustomerGroupUpdate,
  updateCustomerGroup,
} from '../../../actions';
import api from '../../../api';
import { createLocalId } from '../../../utils/local-id';

export function* createCustomerGroupService(businessId, data) {
  const nextData = {
    ...data,
  };

  const localId = yield call(createLocalId);

  yield put(
    createCustomerGroup({
      ...nextData,
      businessId,
      id: localId,
    }),
  );

  let customerGroup;

  try {
    ({ item: customerGroup } = yield call(request, api.createCustomerGroup, businessId, nextData));
  } catch (error) {
    yield put(createCustomerGroup.failure(localId, error));
    return;
  }

  yield put(createCustomerGroup.success(localId, customerGroup));
}

export function* createCustomerGroupInCurrentBusinessService(data) {
  const { businessId } = yield select(pathSelector);

  yield call(createCustomerGroupService, businessId, data);
}

export function* handleCustomerGroupCreateService(customerGroup) {
  yield put(handleCustomerGroupCreate(customerGroup));
}

export function* updateCustomerGroupService(id, data) {
  yield put(updateCustomerGroup(id, data));

  let customerGroup;
  try {
    ({ item: customerGroup } = yield call(request, api.updateCustomerGroup, id, data));
  } catch (error) {
    yield put(updateCustomerGroup.failure(id, error));
    return;
  }

  yield put(updateCustomerGroup.success(customerGroup));
}

export function* handleCustomerGroupUpdateService(customerGroup) {
  yield put(handleCustomerGroupUpdate(customerGroup));
}

export function* deleteCustomerGroupService(id) {
  const { customerGroupId, businessId } = yield select(pathSelector);

  if (id === customerGroupId) {
    yield call(goToBusinessService, businessId);
  }

  yield put(deleteCustomerGroup(id));

  let customerGroup;
  try {
    ({ item: customerGroup } = yield call(request, api.deleteCustomerGroup, id));
  } catch (error) {
    yield put(deleteCustomerGroup.failure(id, error));
    return;
  }

  yield put(deleteCustomerGroup.success(customerGroup));
}

export function* handleCustomerGroupDeleteService(customerGroup) {
  const { customerGroupId, businessId } = yield select(pathSelector);

  if (customerGroup.id === customerGroupId) {
    yield call(goToBusinessService, businessId);
  }

  yield put(handleCustomerGroupDelete(customerGroup));
}
