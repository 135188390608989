import { Model, attr, fk, many } from 'redux-orm';

import ActionTypes from '../constants/ActionTypes';
import Config from '../constants/Config';
import { defaultRange, defaultArrivalRangeForShipmentPage } from '../utils/date-range';

export default class extends Model {
  static modelName = 'Group';

  static fields = {
    id: attr(),
    type: attr(),
    position: attr(),
    name: attr(),
    isFetching: attr({
      getDefault: () => null,
    }),
    isSellitemsFetching: attr({
      getDefault: () => false,
    }),
    isAllSellitemsFetched: attr({
      getDefault: () => false,
    }),
    isProductsFetching: attr({
      getDefault: () => false,
    }),
    isAllProductsFetched: attr({
      getDefault: () => false,
    }),
    isShipmentsFetching: attr({
      getDefault: () => false,
    }),
    isAllShipmentsFetched: attr({
      getDefault: () => false,
    }),
    stat: attr(),
    businessId: fk({
      to: 'Business',
      as: 'business',
      relatedName: 'groups',
    }),
    memberUsers: many({
      to: 'User',
      through: 'GroupMembership',
      relatedName: 'groups',
    }),
    lastFetchSellitemId: attr(),
    lastFetchShipmentId: attr(),
    currentPage: attr({
      getDefault: () => 1,
    }),
    currentPageShipment: attr({
      getDefault: () => 1,
    }),
    filterUsers: many('User', 'filterGroups'),
    filterBrands: many('Brand', 'filterGroups'),
    filterCountries: many('Country', 'filterGroups'),
    filterLabels: many('Label', 'filterGroups'),
    filterStatuses: attr({
      getDefault: () => [],
    }),
    filterShipmentStatuses: attr({
      getDefault: () => [],
    }),
    filterShipProvinces: attr({
      getDefault: () => [],
    }),
    filterOrderDate: attr({
      getDefault: () => defaultRange(),
    }),
    filterArrivalDate: attr({
      getDefault: () => null,
    }),
    filterWebOrderNo: attr({
      getDefault: () => null,
    }),
    filterArrivalDateForShipmentPage: attr({
      getDefault: () => defaultArrivalRangeForShipmentPage(),
    }),
    customerQuery: attr(),
  };

  static reducer({ type, payload }, Group) {
    switch (type) {
      case ActionTypes.LOCATION_CHANGE_HANDLE:
      case ActionTypes.GROUP_FETCH__SUCCESS:
        if (payload.group) {
          Group.upsert({
            ...payload.group,
            isFetching: false,
          });
        }

        break;
      case ActionTypes.LOCATION_CHANGE_HANDLE__GROUP_FETCH:
      case ActionTypes.GROUP_FETCH:
        Group.withId(payload.id).update({
          isFetching: true,
        });

        break;
      case ActionTypes.SOCKET_RECONNECT_HANDLE:
        Group.all().delete();

        if (payload.group) {
          Group.upsert({
            ...payload.group,
            isFetching: false,
          });
        }

        payload.groups.forEach((group) => {
          Group.upsert(group);
        });

        break;
      case ActionTypes.SOCKET_RECONNECT_HANDLE__CORE_FETCH:
        Group.all()
          .toModelArray()
          .forEach((groupModel) => {
            if (groupModel.id !== payload.currentGroupId) {
              groupModel.update({
                isFetching: null,
              });

              groupModel.deleteRelated(payload.currentUserId);
            }
          });

        break;
      case ActionTypes.CORE_INITIALIZE:
        if (payload.group) {
          Group.upsert({
            ...payload.group,
            isFetching: false,
          });
        }

        payload.groups.forEach((group) => {
          Group.upsert(group);
        });

        break;
      case ActionTypes.USER_TO_GROUP_FILTER_ADD:
        Group.withId(payload.groupId).filterUsers.add(payload.id);

        break;
      case ActionTypes.USER_FROM_GROUP_FILTER_REMOVE:
        Group.withId(payload.groupId).filterUsers.remove(payload.id);

        break;
      case ActionTypes.BRAND_TO_GROUP_FILTER_ADD:
        Group.withId(payload.groupId).filterBrands.add(payload.id);

        break;
      case ActionTypes.BRAND_FROM_GROUP_FILTER_REMOVE:
        Group.withId(payload.groupId).filterBrands.remove(payload.id);

        break;
      case ActionTypes.COUNTRY_TO_GROUP_FILTER_ADD:
        Group.withId(payload.groupId).filterCountries.add(payload.id);

        break;
      case ActionTypes.COUNTRY_FROM_GROUP_FILTER_REMOVE:
        Group.withId(payload.groupId).filterCountries.remove(payload.id);

        break;
      case ActionTypes.STATUS_TO_GROUP_FILTER_ADD:
        {
          const groupModel = Group.withId(payload.groupId);
          // groupModel.update({
          //   isFetching: true,
          // });
          groupModel.filterStatuses.push(payload.key.status);
          // groupModel.update({
          //   isFetching: false,
          // });
        }

        break;
      case ActionTypes.STATUS_FROM_GROUP_FILTER_REMOVE:
        {
          const groupModel = Group.withId(payload.groupId);
          // groupModel.update({
          //   isFetching: true,
          // });
          const { filterStatuses } = groupModel;
          groupModel.filterStatuses.splice(filterStatuses.indexOf(payload.key.status), 1);
          // groupModel.update({
          //   isFetching: false,
          // });
        }

        break;
      case ActionTypes.SHIPMENT_STATUS_TO_GROUP_FILTER_ADD:
        {
          const groupModel = Group.withId(payload.groupId);
          groupModel.update({
            isFetching: true,
          });
          groupModel.filterShipmentStatuses.push(payload.key.status);
          groupModel.update({
            isFetching: false,
          });
        }

        break;
      case ActionTypes.SHIPMENT_STATUS_FROM_GROUP_FILTER_REMOVE:
        {
          const groupModel = Group.withId(payload.groupId);
          groupModel.update({
            isFetching: true,
          });
          const { filterShipmentStatuses } = groupModel;
          groupModel.filterShipmentStatuses.splice(
            filterShipmentStatuses.indexOf(payload.key.status),
            1,
          );
          groupModel.update({
            isFetching: false,
          });
        }

        break;

      case ActionTypes.SHIP_PROVINCE_TO_GROUP_FILTER_ADD:
        {
          const groupModel = Group.withId(payload.groupId);
          // console.log('groupModel:', groupModel);
          groupModel.update({
            isFetching: true,
          });
          // console.log('payload:', payload);
          groupModel.filterShipProvinces.push(payload.key.status);
          groupModel.update({
            isFetching: false,
          });
        }

        break;
      case ActionTypes.SHIP_PROVINCE_FROM_GROUP_FILTER_REMOVE:
        {
          const groupModel = Group.withId(payload.groupId);
          groupModel.update({
            isFetching: true,
          });
          const { filterShipProvinces } = groupModel;
          groupModel.filterShipProvinces.splice(filterShipProvinces.indexOf(payload.key.status), 1);
          groupModel.update({
            isFetching: false,
          });
        }

        break;
      case ActionTypes.BUSINESS_CREATE_HANDLE:
        payload.groups.forEach((group) => {
          Group.upsert(group);
        });

        break;
      case ActionTypes.BUSINESS_MANAGER_CREATE_HANDLE:
      case ActionTypes.GROUP_MEMBERSHIP_CREATE_HANDLE:
        if (payload.groups) {
          payload.groups.forEach((group) => {
            Group.upsert({
              ...group,
              ...(payload.group &&
                payload.group.id === group.id && {
                  isFetching: false,
                }),
            });
          });
        }

        break;
      case ActionTypes.GROUP_CREATE:
      case ActionTypes.GROUP_CREATE_HANDLE:
      case ActionTypes.GROUP_UPDATE__SUCCESS:
      case ActionTypes.GROUP_UPDATE_HANDLE:
        Group.upsert(payload.group);

        break;
      case ActionTypes.GROUP_CREATE__SUCCESS:
        Group.withId(payload.localId).delete();

        Group.upsert({
          ...payload.group,
          isFetching: false,
        });

        break;
      case ActionTypes.GROUP_FETCH__FAILURE:
        Group.withId(payload.id).update({
          isFetching: null,
        });

        break;
      case ActionTypes.GROUP_UPDATE:
        Group.withId(payload.id).update(payload.data);

        break;
      case ActionTypes.GROUP_DELETE:
        Group.withId(payload.id).deleteWithRelated();

        break;
      case ActionTypes.GROUP_DELETE__SUCCESS:
      case ActionTypes.GROUP_DELETE_HANDLE: {
        const groupModel = Group.withId(payload.group.id);

        if (groupModel) {
          groupModel.deleteWithRelated();
        }

        break;
      }
      case ActionTypes.SELLITEMS_FETCH:
        Group.withId(payload.groupId).update({
          isSellitemsFetching: true,
          currentPage: payload.filters.data ? payload.filters.data.page : 1,
        });

        break;
      case ActionTypes.SELLITEMS_FETCH__SUCCESS:
        Group.withId(payload.groupId).update({
          isSellitemsFetching: false,
          isAllSellitemsFetched: payload.sellitems.length < Config.SELLITEMS_LIMIT,
          lastFetchSellitemId:
            payload.sellitems.length > 0
              ? payload.sellitems[payload.sellitems.length - 1].id
              : undefined,
          // currentPage: 1,
        });

        break;
      case ActionTypes.PRODUCTS_FETCH:
        Group.withId(payload.groupId).update({
          isProductsFetching: true,
        });

        break;
      case ActionTypes.PRODUCTS_FETCH__SUCCESS:
        Group.withId(payload.groupId).update({
          isProductsFetching: false,
          isAllProductsFetched: payload.products.length < Config.ACTIONS_LIMIT,
        });

        break;
      case ActionTypes.LOCATION_CHANGE_HANDLE__SHIPMENTS_FETCH:
      case ActionTypes.SHIPMENTS_FETCH:
        Group.withId(payload.groupId).update({
          isShipmentsFetching: true,
          // currentPageShipment: 1, // payload.filters.data ? payload.filters.data.page : 1,
        });

        break;
      case ActionTypes.SHIPMENTS_FETCH__SUCCESS:
        {
          const groupModel = Group.withId(payload.groupId);
          groupModel.update({
            isShipmentsFetching: false,
            // isAllShipmentsFetched: payload.shipments.length < Config.ACTIONS_LIMIT,
            // lastFetchShipmentId:
            //   payload.shipments.length > 0
            //     ? payload.shipments[payload.shipments.length - 1].id
            //     : undefined,
            stat: {
              ...groupModel.stat,
              sellitemsTotalCount: payload.stat.sellitemsTotalCount,
              shipmentsTotalCount: payload.stat.shipmentsTotalCount,
              // shiplinesTotalCount: groupModel.stat.shiplinesTotalCount + 1,
            },
          });
        }

        /*
          groupModel.update({
            stat: {
              ...groupModel.stat,
              sellitemsTotalCount: groupModel.stat.sellitemsTotalCount + 1,
              shipmentsTotalCount: groupModel.stat.shipmentsTotalCount + 1,
              shiplinesTotalCount: groupModel.stat.shiplinesTotalCount + 1,
            },
          });
        */

        break;
      case ActionTypes.LABEL_TO_GROUP_FILTER_ADD:
        Group.withId(payload.groupId).filterLabels.add(payload.id);

        break;
      case ActionTypes.LABEL_FROM_GROUP_FILTER_REMOVE:
        Group.withId(payload.groupId).filterLabels.remove(payload.id);

        break;
      case ActionTypes.ORDER_DATE_TO_GROUP_FILTER_CHANGE:
        {
          const groupModel = Group.withId(payload.groupId);
          groupModel.update({
            filterOrderDate: payload.orderDate,
          });
        }

        break;
      case ActionTypes.CUSTOMERS_SEARCH:
        {
          const groupModel = Group.withId(payload.groupId);
          groupModel.update({
            customerQuery: payload.query,
          });
        }

        break;
      case ActionTypes.ARRIVAL_DATE_TO_GROUP_FILTER_CHANGE:
        {
          const groupModel = Group.withId(payload.groupId);
          groupModel.update({
            filterArrivalDate: payload.arrivalDate,
          });
        }

        break;
      case ActionTypes.WEB_ORDER_NO_TO_GROUP_FILTER_CHANGE:
        {
          const groupModel = Group.withId(payload.groupId);
          groupModel.update({
            filterWebOrderNo: payload.webOrderNo,
          });
        }

        break;
      case ActionTypes.ARRIVAL_DATE_FOR_SHIPMENT_PAGE_TO_GROUP_FILTER_CHANGE:
        {
          const groupModel = Group.withId(payload.groupId);
          groupModel.update({
            filterArrivalDateForShipmentPage: payload.arrivalDate,
          });
        }

        break;
      case ActionTypes.SELLITEM_CREATE__SUCCESS:
        {
          const groupModel = Group.withId(payload.sellitem.groupId);
          groupModel.update({
            stat: {
              ...groupModel.stat,
              sellitemsTotalCount: groupModel.stat.sellitemsTotalCount + 1,
            },
          });
        }

        break;
      default:
    }
  }

  static getOrderedQuerySet() {
    return this.orderBy('createdAt', false);
  }

  getOrderedMembershipsQuerySet() {
    return this.memberships; // .orderBy('createdAt');
  }

  getOrderedCustomersQuerySet() {
    return this.customers.orderBy('createdAt', false);
  }

  getOrderedProductsQuerySet() {
    return this.products.orderBy('createdAt', false);
  }

  getOrderedShipmentsQuerySet() {
    return this.shipments.orderBy('createdAt', false);
  }

  getOrderedSellitemsQuerySet() {
    return this.sellitems.orderBy('createdAt', false);
  }

  getOrderedInGroupSellitemsQuerySet() {
    return this.sellitems.orderBy('createdAt', false);
  }

  hasMemberUser(userId) {
    return this.memberships
      .filter({
        userId,
      })
      .exists();
  }

  deleteRelated(exceptMemberUserId) {
    this.memberships.toModelArray().forEach((groupMembershipModel) => {
      if (groupMembershipModel.userId !== exceptMemberUserId) {
        groupMembershipModel.deleteWithRelated();
      }
    });

    this.labels.delete();

    this.customers.toModelArray().forEach((customerModel) => {
      customerModel.deleteWithRelated();
    });
  }

  deleteWithRelated() {
    this.deleteRelated();
    this.delete();
  }
}
