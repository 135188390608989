import { createSelector } from 'redux-orm';

import orm from '../orm';

export const makeCountryByIdSelector = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ Country }, id) => {
      const countryModel = Country.withId(id);

      if (!countryModel) {
        return countryModel;
      }

      return countryModel.ref;
    },
  );

export const countryByIdSelector = makeCountryByIdSelector();
