import socket from './socket';

/* Actions */

const createBusinessManager = (businessId, data, headers) =>
  socket.post(`/businesses/${businessId}/managers`, data, headers);

const deleteBusinessManager = (id, headers) =>
  socket.delete(`/business-managers/${id}`, undefined, headers);

export default {
  createBusinessManager,
  deleteBusinessManager,
};
