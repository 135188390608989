import { takeEvery, all } from 'redux-saga/effects';

import { sendMessageFacebookService } from '../services';
import EntryActionTypes from '../../../constants/EntryActionTypes';

export default function* facebookWatchers() {
  yield all([
    takeEvery(EntryActionTypes.SEND_MESSAGE_FACEBOOK, ({ payload: { data } }) =>
      sendMessageFacebookService(data),
    ),
  ]);
}
