/* eslint-disable no-unused-vars */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withPopup } from '../../../lib/popup';
import { Popup } from '../../../lib/custom-ui';
import UserItem from './UserItem';

import styles from './AddPopup.module.scss';

const AddStep = React.memo(
  ({ users, items, currentUserIds, title, onDelete, onCreate, onClose }) => {
    const [t] = useTranslation();

    const handleUserSelect = useCallback(
      (id) => {
        onCreate({
          userId: id,
        });

        onClose();
      },
      [onCreate, onClose],
    );

    return (
      <>
        <Popup.Header>
          {t(title, {
            context: 'title',
          })}
        </Popup.Header>
        <Popup.Content>
          <div className={styles.menu}>
            {users.map((user) => (
              <span key={user.id} className={styles.menu}>
                <UserItem
                  key={user.id}
                  name={user.name}
                  email={user.email}
                  avatarUrl={user.avatarUrl}
                  isActive={currentUserIds.includes(user.id)}
                  onSelect={() => handleUserSelect(user.id)}
                  unSelect={() => onDelete(items.filter((item) => item.user.id === user.id)[0].id)}
                />
              </span>
            ))}
          </div>
        </Popup.Content>
      </>
    );
  },
);

AddStep.propTypes = {
  /* eslint-disable react/forbid-prop-types */
  users: PropTypes.array.isRequired,
  items: PropTypes.array.isRequired,
  currentUserIds: PropTypes.array.isRequired,
  /* eslint-disable react/forbid-prop-types */
  title: PropTypes.string,
  onCreate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

AddStep.defaultProps = {
  title: 'common.addMember',
};

export default withPopup(AddStep);
