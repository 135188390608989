import EntryActionTypes from '../../constants/EntryActionTypes';

export const createPayment = (customerId, data) => ({
  type: EntryActionTypes.PAYMENT_CREATE,
  payload: {
    customerId,
    data,
  },
});

export const handlePaymentCreate = (payment) => ({
  type: EntryActionTypes.PAYMENT_CREATE_HANDLE,
  payload: {
    payment,
  },
});

export const updatePayment = (id, data) => ({
  type: EntryActionTypes.PAYMENT_UPDATE,
  payload: {
    id,
    data,
  },
});

export const handlePaymentUpdate = (payment) => ({
  type: EntryActionTypes.PAYMENT_UPDATE_HANDLE,
  payload: {
    payment,
  },
});

export const deletePayment = (id) => ({
  type: EntryActionTypes.PAYMENT_DELETE,
  payload: {
    id,
  },
});

export const handlePaymentDelete = (payment) => ({
  type: EntryActionTypes.PAYMENT_DELETE_HANDLE,
  payload: {
    payment,
  },
});
