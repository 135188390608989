import { dequal } from 'dequal';
import pickBy from 'lodash/pickBy';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Form, Input, TextArea } from 'semantic-ui-react';

import { useForm } from '../../../hooks';

import styles from './InformationEdit.module.scss';

const InformationEdit = React.memo(({ defaultData, onUpdate }) => {
  const [t] = useTranslation();

  const [data, handleFieldChange] = useForm(() => ({
    name: '',
    phone: '',
    hotline: '',
    page: '',
    facebook: '',
    address: '',
    ...pickBy(defaultData),
  }));

  const [errors, setErrors] = useState({
    name: false,
    description: false,
    categoryId: false,
    startDate: false,
    endDate: false,
  });

  const cleanData = useMemo(
    () => ({
      ...data,
      name: data.name.trim(),
    }),
    [data],
  );

  const nameField = useRef(null);

  const handleSubmit = useCallback(() => {
    const err = {
      name: !cleanData.name,
      phone: !cleanData.phone,
      hotline: !cleanData.hotline,
      page: !cleanData.page,
      facebook: !cleanData.facebook,
      address: !cleanData.address,
    };
    setErrors(err);

    if (!cleanData.name) {
      nameField.current.select();
      return;
    }

    if (Object.values(err).some((e) => e)) {
      return;
    }

    onUpdate(cleanData);
  }, [onUpdate, cleanData]);

  return (
    <Form onSubmit={handleSubmit}>
      <div className={styles.text}>{t('common.title')}</div>
      <Input
        fluid
        ref={nameField}
        name="name"
        value={data.name}
        className={styles.field}
        onChange={handleFieldChange}
      />
      <Form.Field
        required
        error={errors.address}
        onFocus={() => setErrors({ ...errors, address: false })}
      >
        <label htmlFor="address">{t('common.address')}</label>
        <Input
          fluid
          name="address"
          value={data.address}
          className={styles.field}
          onChange={handleFieldChange}
        />
      </Form.Field>
      <Form.Group widths="equal">
        <Form.Field
          required
          error={errors.phone}
          onFocus={() => setErrors({ ...errors, phone: false })}
        >
          <label htmlFor="phone">{t('common.phone')}</label>
          <Input
            fluid
            name="phone"
            value={data.phone}
            className={styles.field}
            onChange={handleFieldChange}
          />
        </Form.Field>
        <Form.Field
          required
          error={errors.hotline}
          onFocus={() => setErrors({ ...errors, hotline: false })}
        >
          <label htmlFor="hotline">{t('common.hotline')}</label>
          <Input
            fluid
            name="hotline"
            value={data.hotline}
            className={styles.field}
            onChange={handleFieldChange}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field
          required
          error={errors.page}
          onFocus={() => setErrors({ ...errors, page: false })}
        >
          <label htmlFor="page">{t('common.fanPage')}</label>
          <Input
            fluid
            name="page"
            value={data.page}
            className={styles.field}
            onChange={handleFieldChange}
          />
        </Form.Field>
        <Form.Field
          required
          error={errors.facebook}
          onFocus={() => setErrors({ ...errors, facebook: false })}
        >
          <label htmlFor="facebook">{t('common.facebook')}</label>
          <Input
            fluid
            name="facebook"
            value={data.facebook}
            className={styles.field}
            onChange={handleFieldChange}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field
          // required
          error={errors.weightAmountPolicy}
          onFocus={() => setErrors({ ...errors, weightAmountPolicy: false })}
        >
          {/* <label htmlFor="page">{t('common.fanPage')}</label> */}
          <TextArea
            // fluid
            name="weightAmountPolicy"
            value={data.weightAmountPolicy}
            className={styles.field}
            onChange={handleFieldChange}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field
          // required
          error={errors.weightPolicy}
          onFocus={() => setErrors({ ...errors, weightPolicy: false })}
        >
          {/* <label htmlFor="facebook">{t('common.facebook')}</label> */}
          <TextArea
            // fluid
            name="weightPolicy"
            value={data.weightPolicy}
            className={styles.field}
            onChange={handleFieldChange}
          />
        </Form.Field>
      </Form.Group>
      <Button positive disabled={dequal(cleanData, defaultData)} content={t('action.save')} />
    </Form>
  );
});

InformationEdit.propTypes = {
  defaultData: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  onUpdate: PropTypes.func.isRequired,
};

export default InformationEdit;
