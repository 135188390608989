import { Model, attr, fk } from 'redux-orm';

import ActionTypes from '../constants/ActionTypes';

export default class extends Model {
  static modelName = 'Stock';

  static fields = {
    id: attr(),
    quantity: attr(),
    webPrice: attr(),
    exchangeRate: attr(),
    note: attr(),
    isFetching: attr({
      getDefault: () => null,
    }),
    productId: fk({
      to: 'Product',
      as: 'product',
      relatedName: 'stocks',
    }),
    creatorUserId: fk({
      to: 'User',
      as: 'creator',
      relatedName: 'stocks',
    }),
  };

  static reducer({ type, payload }, Stock) {
    switch (type) {
      case ActionTypes.LOCATION_CHANGE_HANDLE:
      case ActionTypes.CORE_INITIALIZE:
      case ActionTypes.BUSINESS_MANAGER_CREATE_HANDLE:
      case ActionTypes.GROUP_MEMBERSHIP_CREATE_HANDLE:
      case ActionTypes.LOCATION_CHANGE_HANDLE__PRODUCT_FETCH:
      case ActionTypes.PRODUCT_FETCH__SUCCESS:
        if (payload.stocks) {
          payload.stocks.forEach((stock) => {
            Stock.upsert(stock);
          });
        }

        break;
      case ActionTypes.STOCK_CREATE:
        Stock.upsert(payload.stock);

        break;
      case ActionTypes.STOCK_CREATE__SUCCESS:
        Stock.withId(payload.localId).delete();
        Stock.upsert(payload.stock);

        break;
      case ActionTypes.STOCK_UPDATE__SUCCESS:
        Stock.withId(payload.id).update(payload.data);

        break;
      default:
    }
  }

  static getOrderedQuerySet() {
    return this.orderBy('createdAt', false);
  }
}
