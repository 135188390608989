import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Icon, Menu, Segment, Button } from 'semantic-ui-react';

import { defaultArrivalRangeForShipmentPage } from '../../utils/date-range';
import Paths from '../../constants/Paths';
import NotificationsPopup from './NotificationsPopup';
import Search from '../Search';
import UserPopup from '../UserPopup';
import { ReactComponent as Logo } from '../../assets/images/logo.svg';

import styles from './Header.module.scss';
import User from '../User';

const Header = React.memo(
  ({
    business,
    user,
    notifications,
    path,
    onlineUsers,
    canEditBusiness,
    canEditUsers,
    isAdmin,
    isOwner,
    isShipper,
    onBusinessSettingsClick,
    onUsersClick,
    onNotificationDelete,
    onUserSettingsClick,
    onLogout,
  }) => {
    const [t] = useTranslation();

    const [openMenuMobile, setOpenMenuMobile] = useState(false);
    const menuItems = [
      {
        isShow: !isShipper,
        to: business && Paths.GROUPS.replace(':id', business.firstGroupId),
        title: t('common.sellitems'),
        isActive: path === Paths.GROUPS,
      },
      {
        isShow: !isShipper,
        to: business && Paths.CUSTOMERS.replace(':id', business.firstCustomerId),
        title: t('common.customers'),
        isActive: path === Paths.CUSTOMERS,
      },
      {
        isShow: !isShipper,
        to: business && Paths.PRODUCTS.replace(':id', business.firstProductId),
        title: t('common.products'),
        isActive: path === Paths.PRODUCTS,
      },
      // {
      //   isShow: false,
      //   to: business && Paths.WAREHOUSE.replace(':id', business.firstGroupId),
      //   title: t('common.warehouse'),
      //   isActive: path === Paths.WAREHOUSE,
      // },
      {
        isShow: !isShipper,
        to:
          business &&
          Paths.SHIPMENTPAGE.replace(':id', business.firstGroupId) +
            new URLSearchParams(defaultArrivalRangeForShipmentPage).toString(),
        title: t('common.shipments'),
        isActive: path === Paths.SHIPMENTPAGE,
      },
      {
        isShow: true,
        to: business && Paths.SHIPMENTS.replace(':id', business.firstShipmentId),
        title: t('common.shipment'),
        isActive: path === Paths.SHIPMENTS,
      },
      {
        isShow: isAdmin || isOwner,
        to: business && Paths.DASHBOARD.replace(':id', business.firstGroupId),
        title: t('common.reports'),
        isActive: path === Paths.DASHBOARD,
      },
    ];

    const handleBusinessSettingsClick = useCallback(() => {
      if (canEditBusiness) {
        onBusinessSettingsClick();
      }
    }, [canEditBusiness, onBusinessSettingsClick]);

    const handleClickMenu = useCallback(() => {
      setOpenMenuMobile(!openMenuMobile);
    }, [openMenuMobile]);

    return (
      <div className={styles.wrapper}>
        {!business && (
          <Link to={Paths.ROOT} className={classNames(styles.title)}>
            <Logo className={styles.logoSvg} />
          </Link>
        )}
        <Menu size="large" className={styles.menu}>
          {business && (
            <>
              <Menu.Menu position="left">
                <Menu.Item
                  as={Link}
                  to={Paths.ROOT}
                  className={classNames(styles.item, styles.itemHoverable)}
                >
                  <Logo className={styles.logoSvg} />
                </Menu.Item>
              </Menu.Menu>
              <div style={{ padding: 0 }}>
                <Segment basic vertical className={styles.headerLine}>
                  <Menu.Menu position="left" className={styles.headerLineLeft}>
                    <Menu.Item
                      className={classNames(
                        styles.item,
                        canEditBusiness && styles.itemHoverable,
                        styles.title,
                      )}
                      onClick={handleBusinessSettingsClick}
                    >
                      {business.name}
                    </Menu.Item>
                    <Menu.Item className={classNames(styles.item, styles.searchItem)}>
                      <Search title={t('common.search')} />
                    </Menu.Item>
                    {/* <Menu.Item className={classNames(styles.item, styles.online)}>
                      <span className={styles.users}>
                        {onlineUsers.map((u) => (
                          <span key={u.id} className={styles.user}>
                            <User name={u.name} avatarUrl={u.avatarUrl} size="small" />
                          </span>
                        ))}
                      </span>
                    </Menu.Item> */}
                  </Menu.Menu>
                </Segment>
                {/* <Segment
                  basic
                  vertical
                  className={classNames(styles.headerLine, styles.headerLine2)}
                >
                  <Menu.Menu position="left">
                    {menuItems.map(
                      (menu) =>
                        menu.isShow && (
                          <Menu.Item
                            key={menu.to}
                            as={Link}
                            to={menu.to}
                            className={classNames(
                              styles.item,
                              styles.subItemHoverable,
                              styles.subItem,
                              menu.isActive && styles.active,
                            )}
                          >
                            {menu.title}
                          </Menu.Item>
                        ),
                    )}
                    {/* <Menu.Item className={classNames(styles.item, styles.subItem, styles.activity)}>
                      Thao tác lần cuối bởi&nbsp;<u>Jack</u>&nbsp;1 phút trước
                    </Menu.Item> *
                  </Menu.Menu>
                </Segment> */}
                <Segment
                  basic
                  vertical
                  className={classNames(
                    styles.headerLine,
                    styles.headerLine2,
                    openMenuMobile && styles.menuActive,
                  )}
                >
                  <Menu.Menu position="left" className={styles.menuLeft}>
                    {menuItems
                      .filter(({ hidden }) => !hidden)
                      .map(
                        (menu) =>
                          menu.isShow && (
                            <Menu.Item
                              onClick={handleClickMenu}
                              key={menu.to}
                              as={Link}
                              to={menu.to}
                              className={classNames(
                                styles.item,
                                styles.subItemHoverable,
                                styles.subItem,
                                menu.isActive && styles.active,
                              )}
                            >
                              {menu.title}
                            </Menu.Item>
                          ),
                      )}
                    {/* <Menu.Item
                      className={classNames(
                        styles.item,
                        styles.searchMobile,
                        'hidden-desktop show-tablet',
                      )}
                    >
                      <Search title={t('common.search')} />
                    </Menu.Item> */}
                  </Menu.Menu>
                </Segment>
                {openMenuMobile && <div className={styles.bgOverlay} />}
              </div>
            </>
          )}
          <Menu.Menu position="right" className={styles.rightMenu}>
            <Menu.Item className={classNames(styles.item)}>
              <span className={styles.users}>
                {onlineUsers.map((u) => (
                  <span key={u.id} className={styles.user}>
                    <User name={u.name} avatarUrl={u.avatarUrl} size="small" />
                  </span>
                ))}
              </span>
            </Menu.Item>
            {canEditUsers && (
              <Menu.Item
                className={classNames(styles.item, styles.itemHoverable)}
                onClick={onUsersClick}
              >
                <Icon fitted name="users" />
              </Menu.Item>
            )}
            <NotificationsPopup items={notifications} onDelete={onNotificationDelete}>
              <Menu.Item className={classNames(styles.item, styles.itemHoverable)}>
                <Icon fitted name="bell" />
                {notifications.length > 0 && (
                  <span className={styles.notification}>{notifications.length}</span>
                )}
              </Menu.Item>
            </NotificationsPopup>
            {/* <Menu.Item className={classNames(styles.item)}>
              <span className={styles.users}>
                {onlineUsers.map((u) => (
                  <span key={u.id} className={styles.user}>
                    <User name={u.name} avatarUrl={u.avatarUrl} size="small" />
                  </span>
                ))}
              </span>
              <UserPopup onSettingsClick={onUserSettingsClick} onLogout={onLogout}>
                <User name={user.name} avatarUrl={user.avatarUrl} size="small" />
              </UserPopup>
            </Menu.Item> */}
            <UserPopup onSettingsClick={onUserSettingsClick} onLogout={onLogout}>
              <Menu.Item className={classNames(styles.item, styles.itemHoverable)}>
                <User name={user.name} avatarUrl={user.avatarUrl} size="large" />
              </Menu.Item>
            </UserPopup>
            {business && (
              <Button
                primary
                className={styles.buttonOpenMobileMenu}
                icon="bars"
                onClick={handleClickMenu}
              />
            )}
          </Menu.Menu>
        </Menu>
      </div>
    );
  },
);

Header.propTypes = {
  /* eslint-disable react/forbid-prop-types */
  business: PropTypes.object,
  user: PropTypes.object.isRequired,
  notifications: PropTypes.array.isRequired,
  /* eslint-enable react/forbid-prop-types */
  path: PropTypes.string.isRequired,
  onlineUsers: PropTypes.arrayOf(PropTypes.any).isRequired,
  canEditBusiness: PropTypes.bool.isRequired,
  canEditUsers: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isOwner: PropTypes.bool.isRequired,
  isShipper: PropTypes.bool.isRequired,
  onBusinessSettingsClick: PropTypes.func.isRequired,
  onUsersClick: PropTypes.func.isRequired,
  onNotificationDelete: PropTypes.func.isRequired,
  onUserSettingsClick: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
};

Header.defaultProps = {
  business: undefined,
};

export default Header;
