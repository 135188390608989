import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Menu } from 'semantic-ui-react';
import { withPopup } from '../../../lib/popup';
import { Popup } from '../../../lib/custom-ui';

import { useSteps } from '../../../hooks';
import DeleteStep from '../../DeleteStep';

import styles from './ActionsPopup.module.scss';
import EditStep from './EditStep';

const StepTypes = {
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
};

const ActionsStep = React.memo(({ accounts, defaultData, onUpdate, onDelete, onClose }) => {
  const [t] = useTranslation();
  const [step, openStep, handleBack] = useSteps();

  const handleUpdateClick = useCallback(() => {
    openStep(StepTypes.UPDATE);
  }, [openStep]);

  const handleDeleteClick = useCallback(() => {
    openStep(StepTypes.DELETE);
  }, [openStep]);

  if (step) {
    switch (step.type) {
      case StepTypes.UPDATE:
        return (
          <EditStep
            accounts={accounts}
            title="action.editPayment"
            defaultData={defaultData}
            onCreate={() => {}}
            onUpdate={onUpdate}
            onBack={handleBack}
            onClose={onClose}
          />
        );
      case StepTypes.DELETE:
        return (
          <DeleteStep
            title={t('common.deletePayment', {
              context: 'title',
            })}
            content={t('common.areYouSureYouWantToDeleteThisPayment')}
            buttonContent={t('action.deletePayment')}
            onConfirm={onDelete}
            onBack={handleBack}
          />
        );
      default:
        break;
    }
  }

  return (
    <>
      <Popup.Header>
        {t('common.actions', {
          context: 'title',
        })}
      </Popup.Header>
      <Popup.Content>
        <Menu secondary vertical className={styles.menu}>
          <Menu.Item className={styles.menuItem} onClick={handleUpdateClick}>
            {t('action.editPayment', {
              context: 'title',
            })}
          </Menu.Item>
          <Menu.Item className={styles.menuItem} onClick={handleDeleteClick}>
            {t('action.deletePayment', {
              context: 'title',
            })}
          </Menu.Item>
        </Menu>
      </Popup.Content>
    </>
  );
});

ActionsStep.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.any).isRequired,
  defaultData: PropTypes.objectOf(PropTypes.any).isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default withPopup(ActionsStep);
