import { call, put, select } from 'redux-saga/effects';

import { goToBusinessService } from './router';
import request from '../request';
import { fetchGroupByCurrentPathRequest } from '../requests';
import {
  groupMembershipByIdSelector,
  currentUserIdSelector,
  isCurrentUserManagerForCurrentBusinessSelector,
  notificationsBySellitemIdSelector,
  pathSelector,
} from '../../../selectors';
import {
  createGroupMembership,
  deleteGroupMembership,
  handleGroupMembershipCreate,
  handleGroupMembershipDelete,
} from '../../../actions';
import api from '../../../api';
import { createLocalId } from '../../../utils/local-id';
import mergeRecords from '../../../utils/merge-records';

export function* createGroupMembershipService(groupId, data) {
  const localId = yield call(createLocalId);

  yield put(
    createGroupMembership({
      ...data,
      groupId,
      id: localId,
    }),
  );

  let groupMembership;
  try {
    ({ item: groupMembership } = yield call(request, api.createGroupMembership, groupId, data));
  } catch (error) {
    yield put(createGroupMembership.failure(localId, error));
    return;
  }

  yield put(createGroupMembership.success(localId, groupMembership));
}

export function* createMembershipInCurrentGroupService(data) {
  const { groupId } = yield select(pathSelector);

  yield call(createGroupMembershipService, groupId, data);
}

export function* handleGroupMembershipCreateService(groupMembership) {
  const currentUserId = yield select(currentUserIdSelector);
  const isCurrentUser = groupMembership.userId === currentUserId;

  let user;
  let business;
  let group1;
  let users1;
  let users2;
  let businessManagers;
  let groups;
  let groupMemberships1;
  let groupMemberships2;
  let labels;
  let customers;
  let addresses;
  let sellitems;
  let sellitemMemberships;
  let sellitemLabels;
  let payments;
  let attachments;
  let notifications;
  let products;
  let categories;
  let productCategories;
  let shipments;

  if (isCurrentUser) {
    let group2;
    try {
      ({ item: group2 } = yield call(request, api.getGroup, groupMembership.groupId, undefined));
    } catch {
      return;
    }

    const { groupId } = yield select(pathSelector);

    yield put(handleGroupMembershipCreate.fetchBusiness(group2.businessId, currentUserId, groupId));

    try {
      ({
        item: business,
        included: {
          users: users1,
          businessManagers,
          groups,
          groupMemberships: groupMemberships1,
          categories,
        },
      } = yield call(request, api.getBusiness, group2.businessId));
    } catch {
      return;
    }

    let body;
    try {
      body = yield call(fetchGroupByCurrentPathRequest);
    } catch {} // eslint-disable-line no-empty

    if (body && body.business && body.business.id === group2.businessId) {
      ({
        business,
        group: group1,
        users: users2,
        groupMemberships: groupMemberships2,
        labels,
        customers,
        addresses,
        sellitems,
        sellitemMemberships,
        sellitemLabels,
        payments,
        attachments,
        products,
        categories,
        productCategories,
        shipments,
      } = body);

      if (body.sellitem) {
        notifications = yield select(notificationsBySellitemIdSelector, body.sellitem.id);
      }
    }
  } else {
    try {
      ({ item: user } = yield call(request, api.getUser, groupMembership.userId));
    } catch {
      return;
    }
  }

  yield put(
    handleGroupMembershipCreate(
      groupMembership,
      business,
      group1,
      isCurrentUser ? mergeRecords(users1, users2) : [user],
      businessManagers,
      groups,
      mergeRecords(groupMemberships1, groupMemberships2),
      labels,
      customers,
      addresses,
      sellitems,
      sellitemMemberships,
      sellitemLabels,
      payments,
      attachments,
      notifications,
      products,
      categories,
      productCategories,
      shipments,
    ),
  );
}

export function* deleteGroupMembershipService(id) {
  let groupMembership = yield select(groupMembershipByIdSelector, id);

  const currentUserId = yield select(currentUserIdSelector);
  const { groupId, businessId } = yield select(pathSelector);

  if (groupMembership.userId === currentUserId && groupMembership.groupId === groupId) {
    const isCurrentUserManager = yield select(isCurrentUserManagerForCurrentBusinessSelector);

    if (!isCurrentUserManager) {
      yield call(goToBusinessService, businessId);
    }
  }

  yield put(deleteGroupMembership(id));

  try {
    ({ item: groupMembership } = yield call(request, api.deleteGroupMembership, id));
  } catch (error) {
    yield put(deleteGroupMembership.failure(id, error));
    return;
  }

  yield put(deleteGroupMembership.success(groupMembership));
}

export function* handleGroupMembershipDeleteService(groupMembership) {
  const currentUserId = yield select(currentUserIdSelector);
  const { groupId, businessId } = yield select(pathSelector);

  if (groupMembership.userId === currentUserId && groupMembership.groupId === groupId) {
    const isCurrentUserManager = yield select(isCurrentUserManagerForCurrentBusinessSelector);

    if (!isCurrentUserManager) {
      yield call(goToBusinessService, businessId);
    }
  }

  yield put(handleGroupMembershipDelete(groupMembership));
}
