import ActionTypes from '../constants/ActionTypes';

export const createGroup = (group) => ({
  type: ActionTypes.GROUP_CREATE,
  payload: {
    group,
  },
});

createGroup.success = (localId, group, groupMemberships) => ({
  type: ActionTypes.GROUP_CREATE__SUCCESS,
  payload: {
    localId,
    group,
    groupMemberships,
  },
});

createGroup.failure = (localId, error) => ({
  type: ActionTypes.GROUP_CREATE__FAILURE,
  payload: {
    localId,
    error,
  },
});

export const handleGroupCreate = (group) => ({
  type: ActionTypes.GROUP_CREATE_HANDLE,
  payload: {
    group,
  },
});

export const fetchGroup = (id) => ({
  type: ActionTypes.GROUP_FETCH,
  payload: {
    id,
  },
});

fetchGroup.success = (
  group,
  users,
  businesses,
  groupMemberships,
  labels,
  customers,
  addresses,
  products,
  categories,
  productCategories,
  sellitems,
  sellitemMemberships,
  sellitemLabels,
  payments,
  attachments,
  shipments,
) => ({
  type: ActionTypes.GROUP_FETCH__SUCCESS,
  payload: {
    group,
    users,
    businesses,
    groupMemberships,
    labels,
    customers,
    addresses,
    products,
    categories,
    productCategories,
    sellitems,
    sellitemMemberships,
    sellitemLabels,
    payments,
    attachments,
    shipments,
  },
});

fetchGroup.failure = (id, error) => ({
  type: ActionTypes.GROUP_FETCH__FAILURE,
  payload: {
    id,
    error,
  },
});

export const updateGroup = (id, data) => ({
  type: ActionTypes.GROUP_UPDATE,
  payload: {
    id,
    data,
  },
});

updateGroup.success = (group) => ({
  type: ActionTypes.GROUP_UPDATE__SUCCESS,
  payload: {
    group,
  },
});

updateGroup.failure = (id, error) => ({
  type: ActionTypes.GROUP_UPDATE__FAILURE,
  payload: {
    id,
    error,
  },
});

export const handleGroupUpdate = (group) => ({
  type: ActionTypes.GROUP_UPDATE_HANDLE,
  payload: {
    group,
  },
});

export const deleteGroup = (id) => ({
  type: ActionTypes.GROUP_DELETE,
  payload: {
    id,
  },
});

deleteGroup.success = (group) => ({
  type: ActionTypes.GROUP_DELETE__SUCCESS,
  payload: {
    group,
  },
});

deleteGroup.failure = (id, error) => ({
  type: ActionTypes.GROUP_DELETE__FAILURE,
  payload: {
    id,
    error,
  },
});

export const handleGroupDelete = (group) => ({
  type: ActionTypes.GROUP_DELETE_HANDLE,
  payload: {
    group,
  },
});
