import { call, put, select } from 'redux-saga/effects';

import { pathSelector } from '../../../selectors';
import {
  addStatusToGroupFilter,
  removeStatusFromGroupFilter,
  addShipmentStatusToGroupFilter,
  removeShipmentStatusFromGroupFilter,
  addShipProvinceToGroupFilter,
  removeShipProvinceFromGroupFilter,
} from '../../../actions';
import { fetchSellitemsInCurrentGroupService } from './sellitems';

export function* addStatusToGroupFilterService(key, groupId) {
  yield put(addStatusToGroupFilter(key, groupId));

  yield call(fetchSellitemsInCurrentGroupService, undefined);
}

export function* addStatusToFilterInCurrentGroupService(key) {
  const { groupId } = yield select(pathSelector);

  yield call(addStatusToGroupFilterService, key, groupId);
}

export function* removeStatusFromGroupFilterService(key, groupId) {
  yield put(removeStatusFromGroupFilter(key, groupId));

  yield call(fetchSellitemsInCurrentGroupService, undefined);
}

export function* removeStatusFromFilterInCurrentGroupService(key) {
  const { groupId } = yield select(pathSelector);

  yield call(removeStatusFromGroupFilterService, key, groupId);
}

export function* addShipmentStatusToGroupFilterService(key, groupId) {
  yield put(addShipmentStatusToGroupFilter(key, groupId));
}

export function* addShipmentStatusToFilterInCurrentGroupService(key) {
  const { groupId } = yield select(pathSelector);

  yield call(addShipmentStatusToGroupFilterService, key, groupId);
}

export function* removeShipmentStatusFromGroupFilterService(key, groupId) {
  yield put(removeShipmentStatusFromGroupFilter(key, groupId));
}

export function* removeShipmentStatusFromFilterInCurrentGroupService(key) {
  const { groupId } = yield select(pathSelector);

  yield call(removeShipmentStatusFromGroupFilterService, key, groupId);
}

export function* addShipProvinceToGroupFilterService(key, groupId) {
  yield put(addShipProvinceToGroupFilter(key, groupId));
}

export function* addShipProvinceToFilterInCurrentGroupService(key) {
  const { groupId } = yield select(pathSelector);

  yield call(addShipProvinceToGroupFilterService, key, groupId);
}

export function* removeShipProvinceFromGroupFilterService(key, groupId) {
  yield put(removeShipProvinceFromGroupFilter(key, groupId));
}

export function* removeShipProvinceFromFilterInCurrentGroupService(key) {
  const { groupId } = yield select(pathSelector);

  yield call(removeShipProvinceFromGroupFilterService, key, groupId);
}
