/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import React, { useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { HotTable, HotColumn } from '@handsontable/react';
import Handsontable from 'handsontable';
import { StatusesShipment } from '../../constants/Enums';

import styles from './ShiplineDetail.module.scss';

const ShiplineDetail = React.memo(
  ({ shipline, customer, sellitems, onUpdateShipline, onClose }) => {
    const [t] = useTranslation();

    const colHeaders = useMemo(
      () => [
        '',
        t('common.dateOrder'),
        `${t('common.country')} / ${t('common.brand')} / ${t('common.webCode')} <br /> ${t(
          'common.productName',
        )}`,
        t('common.image'),
        t('common.color'),
        t('common.size'),
        t('common.quantity'),
        t('common.sellPrice'),
        // t('common.weight'),
        t('common.weightAmount'),
        t('common.finalTotal'),
        t('common.payment'),
        t('common.remainTotal'),
        // t('common.note'),
      ],
      [t],
    );

    const dateRenderer = (instance, td, row, col, prop, value) => {
      if (value) {
        // eslint-disable-next-line no-param-reassign
        td.innerText = t('format:longDateTime', {
          postProcess: 'formatDate',
          value: new Date(value),
        });
        // eslint-disable-next-line no-param-reassign
        td.style.color = '#777';
      }
      return td;
    };

    const imageRenderer = (instance, td, row, col, prop, value) => {
      if (!value) return;
      const img = document.createElement('IMG');
      const product = instance.getDataAtRowProp(row, 'product');

      if (product && product.variation) {
        const color = instance.getDataAtRowProp(row, 'color');
        if (product.variation.colors) {
          img.src = product.variation.colors.find(({ name }) => name === color)?.imageUrl;
        }
      }
      if (!img.src) {
        img.src = value;
      }

      Handsontable.dom.empty(td);
      td.classList.add(styles.image);
      td.appendChild(img);
    };

    const productRenderer = (instance, td, row, col, prop, value, cellProperties) => {
      if (sellitems[row]) {
        const {
          country: { name },
          brandName,
          webCode,
        } = sellitems[row];
        Handsontable.renderers.TextRenderer.apply(this, [
          instance,
          td,
          row,
          col,
          prop,
          `${name} / ${brandName} / ${webCode || ''}\n${value}`,
          cellProperties,
        ]);
      }
    };

    const weightAmountRenderer = (instance, td, row, col, prop, value, cellProperties) => {
      const isWithWeightAmount = instance.getDataAtRowProp(row, 'isWithWeightAmount');
      const weightAmount = instance.getDataAtRowProp(row, 'weightAmount');
      // Handsontable.renderers.TextRenderer.apply(this, [
      //   instance,
      //   td,
      //   row,
      //   col,
      //   prop,
      //   value,
      //   cellProperties,
      // ]);
      /* eslint-disable no-param-reassign */
      if (isWithWeightAmount) {
        td.style.backgroundColor = '#ddd';
        td.innerText = t('common.withWeightAmount');
      } else if (value) {
        // td.innerText = parseInt(value, 10).toLocaleString('en-GB');
        td.innerText = `${parseFloat(value, 10).toLocaleString('en-GB')} kg\n${parseInt(
          weightAmount,
          10,
        ).toLocaleString('en-GB')}`;
      }
      return td;
    };

    const sumObj = sellitems
      .filter(({ isChecked }) => isChecked)
      .reduce(
        (sum, current) => ({
          quantity: sum.quantity + +current.quantity,
          weight: sum.weight + +current.weight,
          weightAmount: sum.weightAmount + +current.weightAmount,
          sellPrice: sum.sellPrice + +current.sellPrice,
          finalTotal: sum.finalTotal + +current.finalTotal,
          payment: sum.payment + +current.payment,
          remainTotal: sum.remainTotal + +current.remainTotal,
        }),
        {
          quantity: 0,
          weight: 0.0,
          weightAmount: 0,
          sellPrice: 0,
          finalTotal: 0,
          payment: 0,
          remainTotal: 0,
        },
      );
    const summary = {
      isChecked: null,
      createdAt: 0,
      product: { name: '' },
      color: '',
      size: t('common.sum'),
      ...sumObj,
      isWithWeightAmount: '',
    };

    const afterChange = (changes, source) => {
      const sourceChange = ['edit', 'CopyPaste.paste', 'Autofill.fill', 'undo', 'redo'];
      if (sourceChange.indexOf(source) > -1 && changes.length > 0) {
        changes.forEach(([row, prop, oldValue, newValue]) => {
          if ((oldValue === null || oldValue === undefined) && newValue === '') return;

          // eslint-disable-next-line eqeqeq
          if (oldValue != newValue && row < sellitems.length) {
            let checkedSellitems;
            switch (prop) {
              case 'isChecked':
                // eslint-disable-next-line no-param-reassign
                sellitems[row].isChecked = newValue;
                checkedSellitems = sellitems.filter(({ isChecked }) => isChecked);
                // console.log(checkedSellitems.map(({ id }) => id));
                // onUpdateShipline(shipline.id, {});
                onUpdateShipline(shipline.id, {
                  sellitemIds: checkedSellitems.map(({ id }) => id),
                  total: checkedSellitems.reduce((sum, { finalTotal }) => sum + finalTotal, 0),
                });
                break;
              default:
                break;
            }
          }
        });
      }
    };

    console.log('ShiplineDetail component');

    return (
      <Modal open closeIcon size="large" onClose={onClose} className={styles.shiplineDetailPopup}>
        <Modal.Content>
          <div className={styles.wrapper}>
            <div className={styles.customerInfo}>
              <h3 className={styles.customerInfoItem}>
                {t('common.customer')}: {customer.name} [{customer.code}]
              </h3>
              <span className={styles.customerInfoItem}>
                {t('common.phone')}: <b>{customer.phone}</b>
              </span>
              <span className={styles.customerInfoItem}>
                {t('common.address')}:
                <b>
                  {customer.address?.address &&
                    `${customer.address.address}, ${customer.address.ward}, ${customer.address.district}, ${customer.address.province}`}
                </b>
              </span>
            </div>
            <div
              id="hot-app-shipline-detail"
              className={styles.hotWrapper}
              style={{ height: (sellitems.length + 1) * 61 + 42 }}
            >
              <HotTable
                data={[...sellitems, summary]}
                colHeaders={colHeaders}
                height="100%"
                stretchH="all"
                autoColumnSize={false}
                autoRowSize={false}
                autoWrapRow={false}
                rowHeaders={false}
                rowHeights={60}
                columnSummary={[
                  {
                    sourceColumn: 2,
                    type: 'count',
                    destinationRow: sellitems.length,
                    destinationColumn: 0,
                  },
                ]}
                afterChange={afterChange}
                licenseKey="non-commercial-and-evaluation"
              >
                <HotColumn
                  width={22}
                  data="isChecked"
                  type="checkbox"
                  readOnly={shipline.status !== StatusesShipment.NOTSHIP.name}
                />
                <HotColumn width={85} data="createdAt" renderer={dateRenderer} readOnly />
                <HotColumn width={220} data="product.name" renderer={productRenderer} readOnly />
                <HotColumn width={30} data="imageUrl" renderer={imageRenderer} readOnly />
                <HotColumn width={100} data="color" readOnly />
                <HotColumn width={80} data="size" readOnly />
                <HotColumn width={30} data="quantity" readOnly />
                <HotColumn
                  width={80}
                  data="sellPrice"
                  type="numeric"
                  numericFormat={{ pattern: '0,0' }}
                  readOnly
                />
                {/* <HotColumn width={50} data="weight" readOnly /> */}
                {/* <HotColumn
                  width={70}
                  data="weight"
                  type="numeric"
                  numericFormat={{ pattern: '0,0' }}
                  renderer={weightAmountRenderer}
                  readOnly
                /> */}
                <HotColumn
                  width={70}
                  data="weight"
                  type="numeric"
                  numericFormat={{ pattern: '0,0' }}
                  renderer={weightAmountRenderer}
                  readOnly
                />
                <HotColumn
                  width={95}
                  data="finalTotal"
                  type="numeric"
                  numericFormat={{ pattern: '0,0' }}
                  readOnly
                />
                <HotColumn
                  width={80}
                  data="payment"
                  type="numeric"
                  numericFormat={{ pattern: '0,0' }}
                  readOnly
                />
                <HotColumn
                  width={95}
                  data="remainTotal"
                  type="numeric"
                  numericFormat={{ pattern: '0,0' }}
                  readOnly
                />
                {/* <HotColumn
                  width={90}
                  data="isWithWeightAmount"
                  renderer={isWithWeightAmountRenderer}
                  readOnly
                /> */}
              </HotTable>
            </div>
            <div className={styles.shiplineInfo}>
              <h3>{t('common.infoShipment')}:</h3>
              <div className={styles.shipInner}>
                <div className={styles.shipTo}>
                  {t('common.shipTo')}:{' '}
                  <b>
                    {shipline.isShipProvince
                      ? t('common.yesShipProvince')
                      : t('common.noShipProvince')}
                  </b>
                </div>
                <div className={styles.shipper}>
                  {t('common.shipper')}: <b>{shipline.shipper}</b>
                </div>
              </div>
              <div className={styles.customerAddress}>
                {t('common.infoDelivery')}:<br />
                <b>{shipline.address}</b>
              </div>
            </div>
          </div>
        </Modal.Content>
      </Modal>
    );
  },
);

ShiplineDetail.propTypes = {
  customer: PropTypes.objectOf(PropTypes.any).isRequired,
  sellitems: PropTypes.arrayOf(PropTypes.any).isRequired,
  shipline: PropTypes.objectOf(PropTypes.any).isRequired,
  onUpdateShipline: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ShiplineDetail;
