import ActionTypes from '../constants/ActionTypes';

export const createBrand = (brand) => ({
  type: ActionTypes.BRAND_CREATE,
  payload: {
    brand,
  },
});

createBrand.success = (localId, brand) => ({
  type: ActionTypes.BRAND_CREATE__SUCCESS,
  payload: {
    localId,
    brand,
  },
});

createBrand.failure = (localId, error) => ({
  type: ActionTypes.BRAND_CREATE__FAILURE,
  payload: {
    localId,
    error,
  },
});

export const handleBrandCreate = (brand) => ({
  type: ActionTypes.BRAND_CREATE_HANDLE,
  payload: {
    brand,
  },
});

export const updateBrand = (id, data) => ({
  type: ActionTypes.BRAND_UPDATE,
  payload: {
    id,
    data,
  },
});

updateBrand.success = (brand) => ({
  type: ActionTypes.BRAND_UPDATE__SUCCESS,
  payload: {
    brand,
  },
});

updateBrand.failure = (id, error) => ({
  type: ActionTypes.BRAND_UPDATE__FAILURE,
  payload: {
    id,
    error,
  },
});

export const handleBrandUpdate = (brand) => ({
  type: ActionTypes.BRAND_UPDATE_HANDLE,
  payload: {
    brand,
  },
});

export const deleteBrand = (id) => ({
  type: ActionTypes.BRAND_DELETE,
  payload: {
    id,
  },
});

deleteBrand.success = (brand) => ({
  type: ActionTypes.BRAND_DELETE__SUCCESS,
  payload: {
    brand,
  },
});

deleteBrand.failure = (id, error) => ({
  type: ActionTypes.BRAND_DELETE__FAILURE,
  payload: {
    id,
    error,
  },
});

export const handleBrandDelete = (brand) => ({
  type: ActionTypes.BRAND_DELETE_HANDLE,
  payload: {
    brand,
  },
});

export const addBrandToGroupFilter = (id, groupId) => ({
  type: ActionTypes.BRAND_TO_GROUP_FILTER_ADD,
  payload: {
    id,
    groupId,
  },
});

export const removeBrandFromGroupFilter = (id, groupId) => ({
  type: ActionTypes.BRAND_FROM_GROUP_FILTER_REMOVE,
  payload: {
    id,
    groupId,
  },
});
