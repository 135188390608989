import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Form } from 'semantic-ui-react';
import { Input, Popup } from '../../../lib/custom-ui';

import { useForm } from '../../../hooks';

import vndb from '../../../assets/vndb.json';

import styles from './EditStep.module.scss';

const EditStep = React.memo(({ defaultData, title, onCreate, onUpdate, onBack, onClose }) => {
  const [t] = useTranslation();

  const [data, handleFieldChange] = useForm({
    name: '',
    phone: '',
    province: '',
    district: '',
    ward: '',
    address: '',
    ...defaultData,
  });
  const [isDefault, setIsDefault] = useState(defaultData.isDefault);

  const [error, setError] = useState({
    name: false,
    phone: false,
    province: false,
    district: false,
    ward: false,
    address: false,
  });

  const provinceOptions = useMemo(
    () =>
      vndb.province.map((p) => ({
        key: p.idProvince,
        value: p.name,
        text: p.name,
      })),
    [],
  );

  const districtOptions = useMemo(() => {
    let province = vndb.province.find((p) => p.name === data.province);
    if (!province) province = { idProvince: 0 };
    return vndb.district
      .filter((d) => d.idProvince === province.idProvince)
      .map((d) => ({
        key: d.idDistrict,
        value: d.name,
        text: d.name,
      }));
  }, [data.province]);

  const wardOptions = useMemo(() => {
    let district = vndb.district.find((d) => d.name === data.district);
    if (!district) district = { idDistrict: 0 };
    return vndb.ward
      .filter((w) => w.idDistrict === district.idDistrict)
      .map((w) => ({
        key: w.idWard,
        value: w.name,
        text: w.name,
      }));
  }, [data.district]);

  const handleToggleChange = useCallback(() => {
    setIsDefault(!isDefault);
  }, [isDefault]);

  const handleSubmit = useCallback(() => {
    const cleanData = {
      ...data,
      name: data.name.trim(),
      phone: data.phone.trim(),
      province: data.province.trim(),
      district: data.district.trim(),
      ward: data.ward.trim(),
      address: data.address.trim(),
      isDefault,
    };

    const err = {
      name: !cleanData.name,
      phone: !cleanData.phone,
      province: !cleanData.province,
      district: !cleanData.district,
      ward: !cleanData.ward,
      address: !cleanData.address,
    };
    setError(err);

    // if (err.name || err.phone || err.province || err.district || err.ward || err.address) {
    if (Object.values(err).some((e) => e)) {
      return;
    }

    if (title === 'action.editAddress') {
      onUpdate(cleanData);
    } else {
      onCreate(cleanData);
    }

    onClose();
  }, [data, isDefault, title, onClose, onUpdate, onCreate]);

  // useEffect(() => {
  //   nameField.current.select();
  // }, []);

  return (
    <>
      <Popup.Header onBack={onBack}>
        {t(title, {
          context: 'title',
        })}
      </Popup.Header>
      <Popup.Content>
        <Form onSubmit={handleSubmit}>
          <Form.Field
            required
            error={error.name}
            onFocus={() => setError({ ...error, name: false })}
          >
            <Input
              fluid
              name="name"
              value={data.name}
              // label={t('common.name')}
              className={styles.field}
              placeholder={`${t('common.name')} *`}
              onChange={handleFieldChange}
            />
          </Form.Field>
          <Form.Field
            required
            error={error.phone}
            onFocus={() => setError({ ...error, phone: false })}
          >
            <Input
              fluid
              name="phone"
              value={data.phone}
              className={styles.field}
              placeholder={`${t('common.phone')} *`}
              onChange={handleFieldChange}
            />
          </Form.Field>
          <Form.Dropdown
            fluid
            required
            search
            selection
            error={error.province}
            name="province"
            value={data.province}
            options={provinceOptions}
            className={styles.field}
            placeholder={`${t('common.province')} *`}
            onFocus={() => setError({ ...error, province: false })}
            onChange={handleFieldChange}
          />
          <Form.Dropdown
            fluid
            required
            search
            selection
            error={error.district}
            name="district"
            value={data.district}
            options={districtOptions}
            className={styles.field}
            placeholder={`${t('common.district')} *`}
            onFocus={() => setError({ ...error, district: false })}
            onChange={handleFieldChange}
          />
          <Form.Dropdown
            fluid
            required
            search
            selection
            error={error.ward}
            name="ward"
            value={data.ward}
            options={wardOptions}
            className={styles.field}
            placeholder={`${t('common.ward')} *`}
            onFocus={() => setError({ ...error, ward: false })}
            onChange={handleFieldChange}
          />
          <Form.Field
            required
            error={error.address}
            onFocus={() => setError({ ...error, address: false })}
          >
            <Input
              fluid
              name="address"
              value={data.address}
              className={styles.field}
              placeholder={`${t('common.address')} *`}
              onChange={handleFieldChange}
            />
          </Form.Field>
          <Form.Group widths="equal">
            <Form.Checkbox
              name="isDefault"
              checked={isDefault}
              disabled={title === 'action.editAddress'}
              label={t('action.setDefault')}
              onChange={handleToggleChange}
            />
            <Button positive content={t('action.save')} />
          </Form.Group>
        </Form>
      </Popup.Content>
    </>
  );
});

EditStep.propTypes = {
  defaultData: PropTypes.objectOf(PropTypes.any),
  title: PropTypes.string,
  onCreate: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onBack: PropTypes.func,
  onClose: PropTypes.func.isRequired,
};

EditStep.defaultProps = {
  defaultData: undefined,
  title: 'common.createAddress',
  onBack: undefined,
};

export default EditStep;
