import ActionTypes from '../constants/ActionTypes';

export const createStock = (stock) => ({
  type: ActionTypes.STOCK_CREATE,
  payload: {
    stock,
  },
});

createStock.success = (localId, productId, stock) => ({
  type: ActionTypes.STOCK_CREATE__SUCCESS,
  payload: {
    localId,
    productId,
    stock,
  },
});

createStock.failure = (localId, error) => ({
  type: ActionTypes.STOCK_CREATE__FAILURE,
  payload: {
    localId,
    error,
  },
});

export const updateStock = (id, data) => ({
  type: ActionTypes.STOCK_UPDATE,
  payload: {
    id,
    data,
  },
});

updateStock.success = (stock) => ({
  type: ActionTypes.STOCK_UPDATE__SUCCESS,
  payload: {
    stock,
  },
});

updateStock.failure = (id, error) => ({
  type: ActionTypes.STOCK_UPDATE__FAILURE,
  payload: {
    id,
    error,
  },
});
