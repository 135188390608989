import EntryActionTypes from '../../constants/EntryActionTypes';

export const addStatusToFilterInCurrentGroup = (key) => ({
  type: EntryActionTypes.STATUS_TO_FILTER_IN_CURRENT_GROUP_ADD,
  payload: {
    key,
  },
});

export const removeStatusFromFilterInCurrentGroup = (key) => ({
  type: EntryActionTypes.STATUS_FROM_FILTER_IN_CURRENT_GROUP_REMOVE,
  payload: {
    key,
  },
});

export const addShipmentStatusToFilterInCurrentGroup = (key) => ({
  type: EntryActionTypes.SHIPMENT_STATUS_TO_FILTER_IN_CURRENT_GROUP_ADD,
  payload: {
    key,
  },
});

export const removeShipmentStatusFromFilterInCurrentGroup = (key) => ({
  type: EntryActionTypes.SHIPMENT_STATUS_FROM_FILTER_IN_CURRENT_GROUP_REMOVE,
  payload: {
    key,
  },
});

export const addShipProvinceToFilterInCurrentGroup = (key) => ({
  type: EntryActionTypes.SHIP_PROVINCE_TO_FILTER_IN_CURRENT_GROUP_ADD,
  payload: {
    key,
  },
});

export const removeShipProvinceFromFilterInCurrentGroup = (key) => ({
  type: EntryActionTypes.SHIP_PROVINCE_FROM_FILTER_IN_CURRENT_GROUP_REMOVE,
  payload: {
    key,
  },
});
