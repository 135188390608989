import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  currentBusinessSelector,
  currentUserSelector,
  isCurrentUserManagerForCurrentBusinessSelector,
  notificationsForCurrentUserSelector,
  pathsMatchSelector,
  usersSelector,
  isAdminSelector,
  isOwnerSelector,
  isShipperSelector,
} from '../selectors';
import {
  deleteNotification,
  logout,
  openBusinessSettingsModal,
  openUserSettingsModal,
  openUsersModal,
} from '../actions/entry';
import Header from '../components/Header';

const mapStateToProps = (state) => {
  const currentUser = currentUserSelector(state);
  const currentBusiness = currentBusinessSelector(state);
  const notifications = notificationsForCurrentUserSelector(state);
  const { path } = pathsMatchSelector(state);
  const isCurrentUserManager = isCurrentUserManagerForCurrentBusinessSelector(state);
  const onlineUsers = usersSelector(state).filter(
    ({ id, isOnline }) => id !== currentUser.id && isOnline,
  );

  const isAdmin = isAdminSelector(state);
  const isOwner = isOwnerSelector(state);
  const isShipper = isShipperSelector(state);

  return {
    notifications,
    business: currentBusiness,
    user: currentUser,
    path,
    onlineUsers,
    canEditBusiness: isCurrentUserManager,
    canEditUsers: currentUser.isAdmin,
    isAdmin,
    isOwner,
    isShipper,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onBusinessSettingsClick: openBusinessSettingsModal,
      onUsersClick: openUsersModal,
      onNotificationDelete: deleteNotification,
      onUserSettingsClick: openUserSettingsModal,
      onLogout: logout,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Header);
